import React from "react";
import {
	Card,
	CardBody,Container, Row,Col,CardTitle

} from 'reactstrap';

import { Toast } from 'primereact/toast';
import moment from 'moment';
import Select from 'react-select';
import MantenimientoService from  'controllers/mantenimiento/mantenimiento-service';
import UnidadService  from 'controllers/unidad/unidadService';
import OperadorServices  from 'controllers/operador/operador-service';
import {Calendar} from 'primereact/calendar';
import {MultiSelect} from 'primereact/multiselect';

const options = [];
const es = {
	firstDayOfWeek: 1,
	dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
	dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
	dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
	monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
	monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"]
};


class AgregarMantenimiento extends React.Component {
	constructor(props){
		super(props);
		this.state={

		}
		this.servicio = new MantenimientoService();
		this.servicioU = new UnidadService();
		this.servicioO = new OperadorServices();



		this.servicio.catalogoPredictivos().then(data =>  {
			let arrCartas = [];


			data.forEach(item=>{
				console.log("item",item)
				arrCartas.push({value:item.id,label:item.nombre});

			});
			this.setState({catalogo_predictivos: arrCartas});
		});

		this.servicioU.getUnidades().then(data =>  {
			let arrUnidades = [];
			data.forEach(item=>{
				arrUnidades.push({value:item.id,label:item.placas});

			});
			this.setState({unidades: arrUnidades});
		});
		this.servicioO.getOperadores().then(data =>  {
			let arrOperadores = [];
			data.forEach(item=>{
				arrOperadores.push({value:item.usuario_id,label:item.nombre});

			});
			this.setState({operadores: arrOperadores});
		});
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit(event) {
				event.preventDefault();

				 console.log(this.state);
				let that = this;

				let datos ={};
				const data = new FormData();
				datos.unidad = this.state.unidad;
				datos.operador = this.state.operador;
				datos.descripcion = this.state.descripcion;
				datos.fecha_entrega = this.state.fecha_entrega;
				datos.fecha_mantenimiento = this.state.fecha_mantenimiento;
				datos.predictivos = this.state.predictivos;

				data.append("ruta",JSON.stringify(datos));
				data.append("contrato",this.state.contrato);
				data.append("asignacion",this.state.asignacion);

				let mensajes = [];
				if(!this.state.unidad	){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Seleccione la unidad'});
				}
				if(!this.state.operador	){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Seleccione un operador'});
				}
				if(!this.state.descripcion	){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Ingrese la descripción de mantenimiento'});
				}
				if(!this.state.fecha_entrega	){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Ingrese la fecha de entrega'});
				}
				if(!this.state.fecha_mantenimiento	){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Ingrese la fecha de mantenimiento'});
				}

				if(mensajes.length){
					this.growl.show(mensajes);
				}else{
					this.servicio.guardarMantenimientoProgramado(datos).then(res =>  {
							if(res && res.result == 'OK'){

								that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Información guardada correctamente'});
								setTimeout(function(){
										 	that.props.history.push('/app/mantenimiento/predictivo/programado');
								 }, 500);

							}else if(res && res.result == 'EXISTE'){
								that.growl.show({severity: 'error', summary: 'El Id de ruta '+this.state.id_ruta+' ya se encuentra registrado', detail: 'Ingrese otro código o consulte su información para editarla.'});
							}
					});
				}
	}

	render() {

		return (
			<Card>
			<CardTitle  className="bg-light border-bottom p-3 mb-0">AGENDAR MANTENIMIENTO</CardTitle>
				<CardBody>
				<Toast ref={(el) => this.growl = el} />
				<form >
				<Row>
					<Col xs="6">
							<div className="form-group">
								<label>Unidadd</label><br/>
								<Select options={this.state.unidades}  onChange={e =>	this.setState({unidad: e.value}) }  placeholder="Elegir unidad"/>

							</div>

					</Col>
					<Col xs="6">
							<div className="form-group">
								<label>Operador</label>
								<Select options={this.state.operadores}  onChange={e =>	this.setState({operador: e.label}) } placeholder="Elegir operador"/>

							</div>
					</Col>
				</Row>
				<Row>
					<Col xs="6">
							<div className="form-group">
								<label>Descripción del mantenimiento</label><br/>
								<input type="text"  value={this.state.descripcion} onChange={e => this.setState({descripcion : e.target.value})}  className="form-control"/>
							</div>
					</Col>
					<Col xs="6" >
						<div className="form-group">
							<label>Predictivos</label><br/>
							<MultiSelect placeholder="Seleccionar mantenimiento" options={this.state.catalogo_predictivos}  style={{width:"100%"}} value={this.state.catalogo_predictivos?this.state.predictivos:[]} onChange={(e) => this.setState({predictivos: e.value})} filter={true}/>
						</div>
					</Col>
				</Row>
				<Row>


					<Col xs="6">
						<div className="form-group">
							<label>Fecha mantenimiento</label><br/>
							<Calendar   dateFormat="dd/mm/yy" onChange={(e) => { this.setState({fecha_mantenimiento:  moment(e.value).format('YYYY-MM-DD').toString()}); }} showIcon={true} placeholder="dd/mm/aaaa"/>

						</div>
					</Col>
					<Col xs="6">
						<div className="form-group">
							<label>Fecha entrega</label><br/>
							<Calendar   dateFormat="dd/mm/yy" onChange={(e) => { this.setState({fecha_entrega:  moment(e.value).format('YYYY-MM-DD').toString()}); }} showIcon={true} placeholder="dd/mm/aaaa"/>
						</div>
					</Col>
				</Row>

				<br/><br/><br/>


						<center> <button className="btn btn-success" onClick={this.handleSubmit} >Guardar</button></center>
					</form>
				</CardBody>
			</Card>
		);
	}
}

export default AgregarMantenimiento;
