import React from "react";
import {
	Card,
	CardBody,Col,Row,Container,CardTitle

} from 'reactstrap';
import {Button} from 'primereact/button';
import LlantasListado from "./lista-corta";
import {Dropdown} from "primereact/dropdown";
import Select from 'react-select';
import UnidadService  from 'controllers/unidad/unidadService';
import LlantaService from 'controllers/llanta/llantaService';
import { Panel } from 'primereact/panel';
import { Accordion, AccordionTab } from 'primereact/accordion';

const tipo_unidades = [
	{value:"na",label:"Sin asignar"},
	{value:"Tractocamión",label:"Tractocamión"},
	{value:"Remolque 53 pies",label:"Remolque 53 pies"},
    {value:"Remolque 48 pies",label:"Remolque 48 pies"},
    {value:"Camión Rabon",label:"Camión Rabon"},
    {value:"Camión Torton",label:"Camión Torton"},
    {value:"Camión 3.5 ton",label:"Camión 3.5 ton"},
    {value:"Pick up",label:"Pick up"},
    {value:"Vehículo utilitario",label:"Vehículo utilitario"},
    {value:"Motocicleta",label:"Motocicleta"},
    {value:"Dolly",label:"Dolly"}
]


class ConsultaLlantas extends React.Component {
	constructor(props){
		super(props);
		this.buscar  = this.buscar.bind(this);
		this.verTodo = this.verTodo.bind(this);
		this.state = {
			folio_llanta:"",
			tipo_unidades: []
		}

		this.servicioU = new UnidadService();
		this.servicio= new LlantaService()
		this.servicioLLanta = new LlantaService();
		this.handleLlantasema = this.handleLlantasema.bind(this);


		this.servicio.getLlantas().then(data =>  {
			let arrLlantas = [];
			data.forEach(item=>{
				arrLlantas.push({value:item.folio,label:item.folio,obj:item});

			});
			this.setState({tipo_unidades: arrLlantas});
		});
		// this.servicioU.getAllUnidades().then(data =>  {
		// 	let arrUnidades = [];
		// 	data.forEach(item=>{
		// 		arrUnidades.push({value:item.id,label:item.eco,obj:item});

		// 	});
		// 	this.setState({tipo_unidades: arrUnidades});
		// });
	}

	componentDidMount() {
       this.handleLlantasema();
    }

	handleLlantasema(params){
		this.servicioLLanta.getFiltroLlantasSemaforo(params).then(data => this.setState({llantas: data}));
	}

	buscar(){
	// 	this.props.history.push({
	// 	 pathname:'/app/unidades/llantas/resultado',
	// 	 state: { folio_llanta: this.state.folio_llanta}
	//  });

	this.handleLlantasema({ folio_llanta: this.state.folio_llanta});
	}

	verTodo(){
		this.setState({folio_llanta: ""})
		this.handleLlantasema();
	}


	render() {
		return (
			<div class="container-fluid">
			<Panel header="Consultar Llantas">
				<Accordion activeIndex={0} className="mb-3">
					<AccordionTab header="Filtrar">
						<Row>
							<Col xs="12" md="6">
							<div className="form-group">
							<label className="d-block"> Unidad:</label>
								<Dropdown editable={true} className="w-full" value={this.state.folio_llanta} options={this.state.tipo_unidades} onChange={e=>this.setState({folio_llanta:e.value})} placeholder="Elegir tipo de unidad"/>
							</div>
							</Col>
						</Row>
						<Row>
							<Col className="text-right">
								<Button label="Buscar" onClick={this.buscar} className="p-button-raised p-button-warning mr-2" />
								<Button label="Ver todo" onClick={this.verTodo} className="p-button-raised p-button-warning" />
								{/* <Button label="Ver todo" onClick={e => this.props.history.push('/app/unidades/llantas/resultado')} className="p-button-raised p-button-warning" /> */}
							</Col>
						</Row>
					</AccordionTab>
				</Accordion>			
				<Row>
					<LlantasListado {...this.state} history={this.props.history} handleLlantasema={this.handleLlantasema}/>
				</Row>
			</Panel>
			</div>
		);
	}
}

export default ConsultaLlantas;
