import React from "react";
import {
	Card,
	CardBody, Container, Row, Col, CardTitle

} from 'reactstrap';
import { Toast } from 'primereact/toast';
import moment from 'moment';
import Select from 'react-select';
import OperadorServices from 'controllers/operador/operador-service';
import Catalogos from 'controllers/catalogos/CatalogoService';

import { InputMask } from 'primereact/inputmask';

import PCalendar from '../../ui-components/calendar';
import {Button} from 'primereact/button';
import { url } from "../../../constants";

const estados_civiles = [
	{ value: 'Soltero', label: 'Soltero' },
	{ value: 'Casado', label: 'Casado' }
]
const sexo = [
	{ value: 'Masculino', label: 'Masculino' },
	{ value: 'Femenino', label: 'Femenino' }
]

const antecedentes = [
	{ value: 'Vigente', label: 'Vigente' },
	{ value: 'Vencido', label: 'Vencido' }
]

const estados = [
	{ value: 'Aguascalientes', label: 'Aguascalientes' },
	{ value: 'Baja California', label: 'Baja California' },
	{ value: 'Baja California Sur', label: 'Baja California Sur' },
	{ value: 'Campeche', label: 'Campeche' },
	{ value: 'Coahuila de Zaragoza', label: 'Coahuila de Zaragoza' },
	{ value: 'Colima', label: 'Colima' },
	{ value: 'Chiapas', label: 'Chiapas' },
	{ value: 'Chihuahua', label: 'Chihuahua' },
	{ value: 'Durango', label: 'Durango' },
	{ value: 'Ciudad de México', label: 'Ciudad de México' },
	{ value: 'Guanajuato', label: 'Guanajuato' },
	{ value: 'Guerrero', label: 'Guerrero' },
	{ value: 'Hidalgo', label: 'Hidalgo' },
	{ value: 'Jalisco', label: 'Jalisco' },
	{ value: 'México', label: 'México' },
	{ value: 'Michoacán de Ocampo', label: 'Michoacán de Ocampo' },
	{ value: 'Morelos', label: 'Morelos' },
	{ value: 'Nayarit', label: 'Nayarit' },
	{ value: 'Nuevo León', label: 'Nuevo León' },
	{ value: 'Oaxaca', label: 'Oaxaca' },
	{ value: 'Puebla', label: 'Puebla' },
	{ value: 'Querétaro', label: 'Querétaro' },
	{ value: 'Quintana Roo', label: 'Quintana Roo' },
	{ value: 'San Luis Potosí', label: 'San Luis Potosí' },
	{ value: 'Sinaloa', label: 'Sinaloa' },
	{ value: 'Sonora', label: 'Sonora' },
	{ value: 'Tabasco', label: 'Tabasco' },
	{ value: 'Tamaulipas', label: 'Tamaulipas' },
	{ value: 'Tlaxcala', label: 'Tlaxcala' },
	{ value: 'Veracruz de Ignacio de la Llave', label: 'Veracruz de Ignacio de la Llave' },
	{ value: 'Yucatán', label: 'Yucatán' },
	{ value: 'Zacatecas', label: 'Zacatecas' },
	{ value: 'Otro', label: 'Otro' },
]

let fileState;

class EditarOperador extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fecha_actualizacion: moment(new Date()).format('YYYY-MM-DD').toString()
		}
		this.servicio = new OperadorServices();
		this.servicioT = new Catalogos();

		this.servicioT.getUnidad().then(data => {
			let arrUnidades = [];
			data.forEach(item => {
				arrUnidades.push({ value: item.id, label: item.eco });

			});

			this.setState({ unidades: arrUnidades });

		});
		this.handleSubmit = this.handleSubmit.bind(this);
		this.getImageInput = this.getImageInput.bind(this);
		
		this.getCommonDataFromChilds = this.getCommonDataFromChilds.bind(this);

	}
	handleSubmit(event) {
		event.preventDefault();
		// console.log(this.state);
		let that = this;
		let datos = {};
		const data = new FormData();
		datos.usuario_id = this.state.usuario_id;
		datos.numero_empleado = this.state.numero_empleado;
		datos.fecha_ingreso = this.state.fecha_ingreso;
		datos.nombre = this.state.nombre;
		datos.apellido_materno = this.state.apellido_materno;
		datos.apellido_paterno = this.state.apellido_paterno;
		datos.fecha_nacimiento = this.state.fecha_nacimiento;
		datos.lugar_nacimiento = this.state.lugar_nacimiento;
		datos.estado_civil = this.state.estado_civil;
		datos.sexo = this.state.sexo;
		datos.rfc = this.state.rfc;
		datos.curp = this.state.curp;
		datos.nss = this.state.nss;
		datos.calle = this.state.calle;
		datos.numero = this.state.numero;
		datos.colonia = this.state.colonia;
		datos.codigo_postal = this.state.codigo_postal;
		datos.estado = this.state.estado;
		datos.numero_celular_asignado = this.state.numero_celular_asignado;
		datos.correo_electronico = this.state.correo_electronico;
		datos.nombre_contacto_emergencia = this.state.nombre_contacto_emergencia;
		datos.telefono_contacto = this.state.telefono_contacto;
		datos.numero_licencia = this.state.numero_licencia;
		datos.poliza = this.state.poliza;
		datos.vigencia = this.state.vigencia;
		datos.fecha_antidoping = this.state.fecha_antidoping;
		datos.vencimiento_examen_medico = this.state.vencimiento_examen_medico;
		datos.unidad_id = this.state.unidad_id;
		if(this.state.password && this.state.password != "")
			datos.password = this.state.password;

		data.append("operador", JSON.stringify(datos));
		data.append("antidoping", this.state.archivo_antidoping);
		data.append("comprobante_domicilio", this.state.archivo_comprobante_domicilio);
		data.append("curp", this.state.archivo_curp);
		data.append("examen_medico", this.state.archivo_examen_medico);
		data.append("ine", this.state.archivo_ine);
		data.append("licencia", this.state.archivo_licencia);
		data.append("incidencias", this.state.archivo_incidencias);
		data.append("nss", this.state.archivo_nss);
		data.append("rfc", this.state.archivo_rfc);


		let mensajes = [];
		if (!this.state.numero_empleado) {
			mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Ingrese el número de empleado' });
		}
		if (!this.state.sexo) {
			mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione el sexo' });
		}
		if (!this.state.estado_civil) {
			mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione el estado civil' });
		}
		if (!this.state.estado) {
			mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione el estado' });
		}

		if (!this.state.unidad_id) {
			mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione una unidad' });
		}


		// if (!this.state.archivo_antidoping) {
		// 	mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione un archivo de antidoping' });
		// }
		// if (!this.state.archivo_comprobante_domicilio) {
		// 	mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione un comprobante de domicilio' });
		// }
		// if (!this.state.archivo_curp) {
		// 	mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione un archivo curp' });
		// }
		// if (!this.state.archivo_examen_medico) {
		// 	mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione un archivo de exámen médico' });
		// }
		// if (!this.state.archivo_ine) {
		// 	mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione un archivo ine' });
		// }
		// if (!this.state.archivo_licencia) {
		// 	mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione un archivo licencia de connducir' });
		// }
		// if (!this.state.archivo_nss) {
		// 	mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione un archivo NSS' });
		// }
		// if (!this.state.archivo_rfc) {
		// 	mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione un archivo RFC' });
		// }

		// if (!this.state.password) {
		// 	mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Se requiere contraseña' });
		// }

		if (mensajes.length) {
			this.growl.show(mensajes);
		} else {
			this.servicio.actualizarOperador(data).then(res => {
				if (res && res.result == 'OK') {
					that.growl.show({ severity: 'success', summary: 'Operación completada', detail: 'Información guardada correctamente' });
					//that.props.onSaved();

					setTimeout(function(){
                       that.props.history.push('/app/operadores/consulta')
					},500)
				}
			});
		}
	}
	getCommonDataFromChilds(data)
	{
	  switch (data.label) {
	    case "fecha_ingreso":
			this.setState({fecha_ingreso: moment(data.value).format('YYYY-MM-DD').toString(),semana:moment(data.value).week()});
			break;
	    case "fecha_nacimiento":
            this.setState({fecha_nacimiento: moment(data.value).format('YYYY-MM-DD').toString(),semana:moment(data.value).week()});
			  break;
		case "vigencia":
			this.setState({vigencia: moment(data.value).format('YYYY-MM-DD').toString(),semana:moment(data.value).week()});
					break;
		case "fecha_antidoping":
			this.setState({fecha_antidoping: moment(data.value).format('YYYY-MM-DD').toString(),semana:moment(data.value).week()});
					break;
		case "vencimiento_examen_medico":
			this.setState({vencimiento_examen_medico: moment(data.value).format('YYYY-MM-DD').toString(),semana:moment(data.value).week()});
			break;
		}
	}
	componentDidUpdate(prevProps) {
		if (prevProps.values !== this.props.values) {
			fileState = {
				archivo_ine: {name:this.props.values?.file_ine},
				archivo_rfc: {name:this.props.values?.file_rfc},
				archivo_curp: {name:this.props.values?.file_curp},
				archivo_nss:{name:this.props.values?.file_nss},
				archivo_comprobante_domicilio:{name:this.props.values?.file_comprobante_domicilio},
				archivo_licencia:{name:this.props.values?.file_licencia},
				archivo_antidoping:{name:this.props.values?.file_antidoping},
				archivo_examen_medico:{name:this.props.values?.file_examen_medico},
				archivo_incidencias:{name:this.props.values?.file_incidencias}
		  }
			this.setState({
				usuario_id: this.props.values.usuario_id,
				numero_empleado: this.props.values.numero_empleado,
				fecha_ingreso: this.props.values.fecha_ingreso,
				nombre: this.props.values.nombre,
				lugar_nacimiento: this.props.values.lugar_nacimiento,
				fecha_nacimiento: this.props.values.fecha_nacimiento,
				estado_civil: this.props.values.estado_civil,
				sexo: this.props.values.sexo,
				edad: this.props.values.edad,
				domicilio_actual: this.props.values.domicilio_actual,
				nacionalidad: this.props.values.nacionalidad,
				rfc: this.props.values.rfc,
				curp: this.props.values.curp,
				nss: this.props.values.nss,
				carta_antecedentes_penales: this.props.values.carta_antecedentes_penales,
				categoria_puesto: this.props.values.categoria_puesto,
				correo_electronico: this.props.values.correo_electronico,
				numero_celular_asignado: this.props.values.numero_celular_asignado,
				numero_licencia: this.props.values.numero_licencia,
				vigencia: this.props.values.vigencia,
				vencimiento_licencia: this.props.values.vencimiento_licencia,
				vencimiento_examen_medico: this.props.values.vencimiento_examen_medico,
				fecha_antidoping: this.props.values.fecha_antidoping,
				nombre_contacto_emergencia: this.props.values.nombre_contacto_emergencia,
				telefono_contacto: this.props.values.telefono_contacto,
				poliza: this.props.values.poliza,
				beneficiario: this.props.values.beneficiario,
				parentesco: this.props.values.parentesco,
				porcentaje: this.props.values.porcentaje,
				fecha_baja: this.props.values.fecha_baja,
				fecha_actualizacion: this.props.values.fecha_actualizacion,
				apellido_materno: this.props.values.apellido_materno,
				apellido_paterno: this.props.values.apellido_paterno,
				numero: this.props.values.numero,
				codigo_postal: this.props.values.codigo_postal,
				comprobante_domicilio: this.props.values.comprobante_domicilio,
				calle: this.props.values.calle,
				colonia: this.props.values.colonia,
				estado: this.props.values.estado,
				unidad_id: this.props.values.unidad_id,
				...fileState
			});
		}
	}
	getImageInput(title,key)
	{
		return (
		<div className="image-group">
			<label className="title">{title}</label>
			<br />
			<div className="form-group">
			{!this.state[key]?.name && 
				<div className="custom-file-upload">
				<label htmlFor={`file-upload-${key}`} className="p-button-warning p-button m-0 w-100">
					<i className="pi pi-cloud-upload"></i> Seleccionar archivo
				</label>
				<input
					id={`file-upload-${key}`}
					type="file"
					style={{ display: "none" }}
					onChange={(e) =>
					this.setState({ [key]: e.target.files[0] })
					}
					chooseLabel="Seleccionar Archivo"
				/>
				</div>
			}
			{this.state[key]?.name && 
				<div className="file-info d-flex justify-content-between">
				  <div className="label-info">
						{this.state[key].name}
					</div>
			<div className="d-flex">
			{fileState?.[key]?.name && 
					<div className="action-info mr-3">
						{this.state[key].name.includes('.') && 
						<i
						onClick={() => {
							window.open(url+"altadeunidad/"+this.state[key].name,'_blank')
						}}
						className="pi pi-download"
						style={{ fontSize: "1rem", cursor: "pointer" }}
						></i>
						}
					</div>
	        }
				<div className="action-info">
                <i
                  onClick={() => {
                    this.setState({ [key]: null });
					fileState && fileState[key] && (fileState[key].name = null);
				}}
                  className="pi pi-trash"
                  style={{ fontSize: "1.5em", cursor: "pointer" }}
                ></i>
                </div>                                 
				</div>
			</div>
			}
			</div>
		</div>
		)
	}
	render() {
		const es = {
			firstDayOfWeek: 1,
			dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
			dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
			dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
			monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
			monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"]
		};
		return (<div>
			<Toast ref={(el) => this.growl = el} />
			<form onSubmit={this.handleSubmit}>
				<Row>
					<Col xs="12" md="4">
						<div className="form-group">
							<label>Número de empleado</label>
							<input type="text" required value={this.state.numero_empleado} onChange={(e) => this.setState({ numero_empleado: e.target.value })} className="form-control" />
						</div>
					</Col>
					<Col xs="12" md="4">
						<div className="form-group">
							<label>Fecha ingreso</label><br />
							<PCalendar
										label={"fecha_ingreso"}
										getCommonDataFromChilds={this.getCommonDataFromChilds}
										showIcon
										value={this.state.fecha_ingreso}
									/>

						</div>
					</Col>
					<Col xs="12" md="4">
						<div className="form-group">
							<label>Fecha de nacimiento</label><br />
							<PCalendar
										label={"fecha_nacimiento"}
										getCommonDataFromChilds={this.getCommonDataFromChilds}
										showIcon
										value={this.state.fecha_nacimiento}
									/>

						</div>
					</Col>
				</Row>
				<Row>
					<Col xs="12" md="4">
						<div className="form-group">
							<label>Nombre</label>
							<input type="text" required value={this.state.nombre} onChange={(e) => this.setState({ nombre: e.target.value })} className="form-control" />
						</div>
					</Col>
					<Col xs="12" md="4">
						<div className="form-group">
							<label>Apellido Paterno</label>
							<input type="text" required value={this.state.apellido_paterno} onChange={(e) => this.setState({ apellido_paterno: e.target.value })} className="form-control" />
						</div>
					</Col>
					<Col xs="12" md="4">
						<div className="form-group">
							<label>Apellido Materno</label>
							<input type="text" required value={this.state.apellido_materno} onChange={(e) => this.setState({ apellido_materno: e.target.value })} className="form-control" />
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs="12" md="6">
						<div className="form-group">
							<label>Lugar de nacimiento</label>
							<input type="text" required value={this.state.lugar_nacimiento} onChange={(e) => this.setState({ lugar_nacimiento: e.target.value })} className="form-control" />
						</div>
					</Col>
					<Col xs="12" md="6">
						<div className="form-group">
							<label>Estado civil</label>
							<Select options={estados_civiles} value={estados_civiles.filter(option => option.label === this.state.estado_civil)} onChange={e => this.setState({ estado_civil: e.value })} placeholder="Seleccione una opción" />
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs="12" md="6">
						<div className="form-group">
							<label>Sexo</label>
							<Select options={sexo} value={sexo.filter(option => option.label === this.state.sexo)} onChange={e => this.setState({ sexo: e.value })} placeholder="Seleccione una opción" />
						</div>
					</Col>
					<Col xs="12" md="6">
						{this.getImageInput("Cargar INE","archivo_ine")}
					</Col>

				</Row>
				<Row>
					<Col xs="12" md="6">
						<div className="form-group">
							<label>R.F.C.</label>
							<input type="text" required value={this.state.rfc} onChange={(e) => this.setState({ rfc: e.target.value })} className="form-control" />
						</div>
					</Col>
					<Col xs="12" md="6">
						{this.getImageInput("Cargar R.F.C","archivo_rfc")}
					</Col>
				</Row>

				<Row>
					<Col xs="12" md="6">
						<div className="form-group">
							<label>CURP</label>
							<input type="text" required value={this.state.curp} onChange={(e) => this.setState({ curp: e.target.value })} className="form-control" />
						</div>
					</Col>
					<Col xs="12" md="6">
						{this.getImageInput("Cargar CURP","archivo_curp")}
					</Col>
				</Row>
				<Row>
					<Col xs="12" md="6">
						<div className="form-group">
							<label>NSS</label>
							<input type="text" required value={this.state.nss} onChange={(e) => this.setState({ nss: e.target.value })} className="form-control" />
						</div>
					</Col>

					<Col xs="12" md="6">
						{this.getImageInput("Cargar NSS","archivo_nss")}
					</Col>
				</Row>
				<Row>
					<Col xs="12" md="6">
						<div className="form-group">
							<label>Calle</label>
							<input type="text" required value={this.state.calle} onChange={(e) => this.setState({ calle: e.target.value })} className="form-control" />
						</div>
					</Col>
					<Col xs="12" md="6">
						<div className="form-group">
							<label>Número</label>
							<input type="number" required value={this.state.numero} onChange={(e) => this.setState({ numero: e.target.value })} className="form-control" />
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs="12" md="6">
						<div className="form-group">
							<label>Colonia</label>
							<input type="text" required value={this.state.colonia} onChange={(e) => this.setState({ colonia: e.target.value })} className="form-control" />
						</div>
					</Col>
					<Col xs="12" md="6">
						<div className="form-group">
							<label>Código Postal</label>
							<input type="number" required value={this.state.codigo_postal} onChange={(e) => this.setState({ codigo_postal: e.target.value })} className="form-control" />
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs="12" md="6">
						<div className="form-group">
							<label>Estado</label>
							<Select options={estados} value={estados.filter(option => option.label === this.state.estado)} onChange={e => this.setState({ estado: e.value })} placeholder="Seleccione una opción" />
						</div>
					</Col>
					<Col xs="12" md="6">
						{this.getImageInput("Comprobante de domicilio","archivo_comprobante_domicilio")}
					</Col>
				</Row>
				<Row>
					<Col xs="12" md="6">
						<div className="form-group">
							<label>Teléfono</label>
							<InputMask mask="(99) 9999-9999" required value={this.state.numero_celular_asignado} onChange={(e) => this.setState({ numero_celular_asignado: e.target.value })} className="form-control" />
						</div>
					</Col>
					<Col xs="12" md="6">
						<div className="form-group">
							<label>Email</label>
							<input type="email" required value={this.state.correo_electronico} onChange={(e) => this.setState({ correo_electronico: e.target.value })} className="form-control" />
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs="12" md="6">
						<div className="form-group">
							<label>Nombre de contacto</label>
							<input type="text" required value={this.state.nombre_contacto_emergencia} onChange={(e) => this.setState({ nombre_contacto_emergencia: e.target.value })} className="form-control" />
						</div>
					</Col>
					<Col xs="12" md="6">
						<div className="form-group">
							<label>Teléfono de Contacto</label>
							<InputMask mask="(99) 9999-9999" required value={this.state.telefono_contacto} onChange={(e) => this.setState({ telefono_contacto: e.target.value })} className="form-control" />
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs="12" md="6">
						<div className="form-group">
							<label>Número de Licencia</label>
							<input type="text" required value={this.state.numero_licencia} onChange={(e) => this.setState({ numero_licencia: e.target.value })} className="form-control" />
						</div>
					</Col>
					<Col xs="12" md="6">
						<div className="form-group">
							<label>Póliza de Vida</label>
							<input type="text" required value={this.state.poliza} onChange={(e) => this.setState({ poliza: e.target.value })} className="form-control" />
						</div>

					</Col>
				</Row>
				<Row>
					<Col xs="12" md="6">
						{this.getImageInput("Cargar licencia","archivo_licencia")}
					</Col>
					<Col xs="12" md="6">
						<div className="form-group">
							<label>Vencimiento de Licencia</label><br />
							<PCalendar
										label={"vigencia"}
										getCommonDataFromChilds={this.getCommonDataFromChilds}
										showIcon
										value={this.state.vigencia}
									/>


						</div>
					</Col>

				</Row>
				<Row>
					<Col xs="12" md="6">
						{this.getImageInput("Resultados de Antidoping","archivo_antidoping")}
					</Col>
					<Col xs="12" md="6">
						<div className="form-group">
							<label>Fecha Antidoping</label><br />
							<PCalendar
										label={"fecha_antidoping"}
										getCommonDataFromChilds={this.getCommonDataFromChilds}
										showIcon
										value={this.state.fecha_antidoping}
									/>


						</div>
					</Col>

				</Row>


				<Row>

					<Col xs="12" md="6">
						{this.getImageInput("Resultados de examen médico","archivo_examen_medico")}
					</Col>
					<Col xs="12" md="6">
						<div className="form-group">
							<label>Vencimiento Exámen Médico</label><br />
							<PCalendar
										label={"vencimiento_examen_medico"}
										getCommonDataFromChilds={this.getCommonDataFromChilds}
										showIcon
										value={this.state.vencimiento_examen_medico}
									/>
						</div>
					</Col>
				</Row>

				<Row>
					<Col xs="12" md="6">
						{this.getImageInput("Incidencias","archivo_incidencias")}
					</Col>
					<Col xs="12" md="6">
						<div className="form-group">
							<label>Unidad</label>
							<Select options={this.state.unidades} value={this.state.unidades ? this.state.unidades.filter(option => option.value == this.state.unidad_id) : ""} onChange={e => this.setState({ unidad_id: e.value })} placeholder="Elegir unidad" />
						</div>
					</Col>
					<Col xs="12" md="6">
						<div className="form-group">
						<label>Clave</label>
						<input
							type="text"
							value={this.state.password}
							onChange={(e) => this.setState({ password: e.target.value })}
							className="form-control"
						/>
						</div>
					</Col>
				</Row>
				<center> <button className="btn btn-primary" >Guardar</button></center>
			</form>
		</div>
		);
	}
}

export default EditarOperador;
