import React from "react";
import { Card, CardBody, Container, Row, Col, CardTitle } from "reactstrap";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import moment from "moment";
import Select from "react-select";
import OperadorServices from "controllers/operador/operador-service";
import Catalogos from "controllers/catalogos/CatalogoService";
import { FileUpload } from "primereact/fileupload";
import { InputMask } from "primereact/inputmask";
import { Panel } from 'primereact/panel';
import PCalendar from '../../ui-components/calendar';
import {Button} from 'primereact/button';

const estados_civiles = [
  { value: "Soltero", label: "Soltero" },
  { value: "Casado", label: "Casado" },
];
const sexo = [
  { value: "Masculino", label: "Masculino" },
  { value: "Femenino", label: "Femenino" },
];

const antecedentes = [
  { value: "Vigente", label: "Vigente" },
  { value: "Vencido", label: "Vencido" },
];

const estados = [
  { value: "Aguascalientes", label: "Aguascalientes" },
  { value: "Baja California", label: "Baja California" },
  { value: "Baja California Sur", label: "Baja California Sur" },
  { value: "Campeche", label: "Campeche" },
  { value: "Coahuila de Zaragoza", label: "Coahuila de Zaragoza" },
  { value: "Colima", label: "Colima" },
  { value: "Chiapas", label: "Chiapas" },
  { value: "Chihuahua", label: "Chihuahua" },
  { value: "Durango", label: "Durango" },
  { value: "Ciudad de México", label: "Ciudad de México" },
  { value: "Guanajuato", label: "Guanajuato" },
  { value: "Guerrero", label: "Guerrero" },
  { value: "Hidalgo", label: "Hidalgo" },
  { value: "Jalisco", label: "Jalisco" },
  { value: "México", label: "México" },
  { value: "Michoacán de Ocampo", label: "Michoacán de Ocampo" },
  { value: "Morelos", label: "Morelos" },
  { value: "Nayarit", label: "Nayarit" },
  { value: "Nuevo León", label: "Nuevo León" },
  { value: "Oaxaca", label: "Oaxaca" },
  { value: "Puebla", label: "Puebla" },
  { value: "Querétaro", label: "Querétaro" },
  { value: "Quintana Roo", label: "Quintana Roo" },
  { value: "San Luis Potosí", label: "San Luis Potosí" },
  { value: "Sinaloa", label: "Sinaloa" },
  { value: "Sonora", label: "Sonora" },
  { value: "Tabasco", label: "Tabasco" },
  { value: "Tamaulipas", label: "Tamaulipas" },
  { value: "Tlaxcala", label: "Tlaxcala" },
  {
    value: "Veracruz de Ignacio de la Llave",
    label: "Veracruz de Ignacio de la Llave",
  },
  { value: "Yucatán", label: "Yucatán" },
  { value: "Zacatecas", label: "Zacatecas" },
  { value: "Otro", label: "Otro" },
];

class CrearOperadores extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numero_empleado: "",
      fecha_ingreso: null,
      nombre: "",
      apellido_materno: "",
      apellido_paterno: "",
      lugar_nacimiento: "",
      fecha_nacimiento: null,
      estado_civil: "",
      sexo: "",
      rfc: "",
      curp: "",
      nss: "",
      calle: "",
      numero: "",
      colonia: "",
      codigo_postal: "",
      estado: "",
      numero_celular_asignado: "",
      correo_electronico: "",
      nombre_contacto_emergencia: "",
      telefono_contacto: "",
      numero_licencia: "",
      poliza: "",
      cargar_tarjeta: "",
      cargar_photo: "",
      password: "",
      fecha_actualizacion: moment(new Date()).format("YYYY-MM-DD").toString(),
      submit_disabled:false
    };
    this.servicio = new OperadorServices();
    this.servicioT = new Catalogos();

    this.servicioT.getUnidad().then((data) => {
      let arrUnidades = [];
      data.forEach((item) => {
        arrUnidades.push({ value: item.id, label: item.eco });
      });

      this.setState({ unidades: arrUnidades });
    });
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getCommonDataFromChilds = this.getCommonDataFromChilds.bind(this);

  }
  handleSubmit(event) {
    event.preventDefault();
    console.log(this.state);
    let that = this;
    let datos = {};
    const data = new FormData();
    datos.numero_empleado = this.state.numero_empleado;
    datos.fecha_ingreso = this.state.fecha_ingreso;
    datos.nombre = this.state.nombre;
    datos.apellido_materno = this.state.apellido_materno;
    datos.apellido_paterno = this.state.apellido_paterno;
    datos.fecha_nacimiento = this.state.fecha_nacimiento;
    datos.lugar_nacimiento = this.state.lugar_nacimiento;
    datos.estado_civil = this.state.estado_civil;
    datos.sexo = this.state.sexo;
    datos.rfc = this.state.rfc;
    datos.curp = this.state.curp;
    datos.nss = this.state.nss;
    datos.calle = this.state.calle;
    datos.numero = this.state.numero;
    datos.colonia = this.state.colonia;
    datos.codigo_postal = this.state.codigo_postal;
    datos.estado = this.state.estado;
    datos.numero_celular_asignado = this.state.numero_celular_asignado;
    datos.correo_electronico = this.state.correo_electronico;
    datos.nombre_contacto_emergencia = this.state.nombre_contacto_emergencia;
    datos.telefono_contacto = this.state.telefono_contacto;
    datos.numero_licencia = this.state.numero_licencia;
    datos.poliza = this.state.poliza;
    datos.vigencia = this.state.vigencia;
    datos.fecha_antidoping = this.state.fecha_antidoping;
    datos.unidad_id = this.state.unidad_id;
    datos.password = this.state.password;
    datos.vencimiento_examen_medico = this.state.vencimiento_examen_medico;
    data.append("operador", JSON.stringify(datos));

    if (this.state.cargar_tarjeta)
      data.append("cargar_tarjeta", this.state.cargar_tarjeta);
    if (this.state.cargar_photo)
      data.append("cargar_photo", this.state.cargar_photo);
    if (this.state.archivo_antidoping)
      data.append("antidoping", this.state.archivo_antidoping);
    if (this.state.archivo_comprobante_domicilio)
      data.append(
        "comprobante_domicilio",
        this.state.archivo_comprobante_domicilio
      );
    if (this.state.archivo_curp) data.append("curp", this.state.archivo_curp);
    if (this.state.archivo_examen_medico)
      data.append("examen_medico", this.state.archivo_examen_medico);
    if (this.state.archivo_ine) data.append("ine", this.state.archivo_ine);
    if (this.state.archivo_licencia)
      data.append("licencia", this.state.archivo_licencia);
    if (this.state.archivo_nss) data.append("nss", this.state.archivo_nss);
    if (this.state.archivo_rfc) data.append("rfc", this.state.archivo_rfc);
    if(this.state.poliza_de_seguro_de_vida) data.append('poliza_de_seguro_de_vida', this.state.poliza_de_seguro_de_vida)

    for (var value of data.values()) {
      console.log("finalData appended values", value);
    }

    let mensajes = [];
    if (!this.state.numero_empleado) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Ingrese el número de empleado",
      });
    }
    if (!this.state.sexo) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione el sexo",
      });
    }
    if (!this.state.estado_civil) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione el estado civil",
      });
    }
    if (!this.state.estado) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione el estado",
      });
    }
    if (!this.state.estado_civil) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione el estado civil",
      });
    }
    if (!this.state.unidad_id) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione una unidad",
      });
    }
    
    if (!this.state.password) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Se requiere contraseña",
      });
    }

    if (!this.state.cargar_tarjeta) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione un Cargar tarjeta o estado de cuenta",
      });
    }
    if (!this.state.cargar_photo) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione un Cargar fotografía de operador",
      });
    }
    if (!this.state.archivo_antidoping) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione un archivo de antidoping",
      });
    }
    if (!this.state.archivo_comprobante_domicilio) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione un comprobante de domicilio",
      });
    }
    if (!this.state.archivo_curp) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione un archivo curp",
      });
    }
    if (!this.state.archivo_examen_medico) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione un archivo de exámen médico",
      });
    }
    if (!this.state.archivo_ine) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione un archivo ine",
      });
    }
    if (!this.state.archivo_licencia) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione un archivo licencia de connducir",
      });
    }
    if (!this.state.archivo_nss) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione un archivo NSS",
      });
    }
    if (!this.state.archivo_rfc) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione un archivo RFC",
      });
    }
    if (mensajes.length) {
      this.growl.show(mensajes);
    } else {
      this.setState({ submit_disabled: true });
      this.servicio.guardarOperador(data).then((res) => {
        this.setState({ submit_disabled: false });
        if (res && res.result == "OK") {
          that.growl.show({
            severity: "success",
            summary: "Operación completada",
            detail: "Información guardada correctamente",
          });
          setTimeout(function () {
            that.props.history.push("/app/operadores/consulta");
          }, 500);
        } else if (res && res.result == "EXISTE") {
          if (res.repetidos.numero_empleado) {
            that.growl.show({
              severity: "error",
              summary:
                "El Número empleado " +
                that.state.numero_empleado +
                " ya se encuentra registrado",
              detail:
                "Ingrese otro código o consulte su información para editarla.",
            });
          }
          if (res.repetidos.curp) {
            that.growl.show({
              severity: "error",
              summary:
                "El CURP " + that.state.curp + " ya se encuentra registrado",
              detail:
                "Ingrese otro código o consulte su información para editarla.",
            });
          }
          if (res.repetidos.rfc) {
            that.growl.show({
              severity: "error",
              summary:
                "El RFC " + that.state.rfc + " ya se encuentra registrado",
              detail:
                "Ingrese otro código o consulte su información para editarla.",
            });
          }
          if (res.repetidos.nss) {
            that.growl.show({
              severity: "error",
              summary:
                "El NSS " + that.state.nss + " ya se encuentra registrado",
              detail:
                "Ingrese otro código o consulte su información para editarla.",
            });
          }
        } else if (res && res.result == "ERROR") {
          that.growl.show({
            severity: "error",
            summary: "Información incompleta",
            detail: res.error,
          });
        }
      });
    }
  }
  getCommonDataFromChilds(data)
	{
		switch (data.label) {
			case "fecha_ingreso":
				this.setState({fecha_ingreso: moment(data.value).format('YYYY-MM-DD').toString(),semana:moment(data.value).week()});
				break;
			case "fecha_nacimiento":
				// this.setState({fecha_nacimiento: data.value});
        this.setState({fecha_nacimiento: moment(data.value).format('YYYY-MM-DD').toString(),semana:moment(data.value).week()});
				break;
      case "vigencia":
        this.setState({vigencia: moment(data.value).format('YYYY-MM-DD').toString(),semana:moment(data.value).week()});
				break;
      case "fecha_antidoping":
        this.setState({fecha_antidoping: moment(data.value).format('YYYY-MM-DD').toString(),semana:moment(data.value).week()});
				break;
      case "vencimiento_examen_medico":
        this.setState({vencimiento_examen_medico: moment(data.value).format('YYYY-MM-DD').toString(),semana:moment(data.value).week()});
        break;
		}
	}

  getImageInput(title,key)
  {
    return (
      <div className="image-group">
        <label className="title">{title}</label>
        <br />
        <div className="form-group">
          {!this.state[key]?.name && 
            <div className="custom-file-upload">
              <label htmlFor={`file-upload-${key}`} className="p-button-warning p-button m-0 w-100">
                <i className="pi pi-cloud-upload"></i> Seleccionar archivo
              </label>
              <input
                id={`file-upload-${key}`}
                type="file"
                style={{ display: "none" }}
                onChange={(e) =>
                  this.setState({ [key]: e.target.files[0] })
                }
                chooseLabel="Seleccionar Archivo"
              />
            </div>
          }
          {this.state[key]?.name && 
            <div className="file-info d-flex justify-content-between">
              <div className="label-info">
                {this.state[key].name}
              </div>
              <div className="action-info">
                <i
                  onClick={() => {
                    this.setState({ [key]: null });
                  }}
                  className="pi pi-trash"
                  style={{ fontSize: "1.5em", cursor: "pointer" }}
                ></i>
                </div>                            
            </div>
          }
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="container">
<Panel header="Alta Operador">
        <Toast ref={(el) => (this.growl = el)} />
          <form onSubmit={this.handleSubmit}>
            <Row>
              <Col xs="12" md="4">
                <div className="form-group">
                  <label>Número de empleado</label>
                  <input
                    type="text"
                    required
                    value={this.state.numero_empleado}
                    onChange={(e) =>
                      this.setState({ numero_empleado: e.target.value })
                    }
                    className="form-control"
                  />
                </div>
              </Col>
              <Col xs="12" md="4">
                <div className="form-group">
                  <label>Fecha ingreso</label>
                  <br />
                  <PCalendar
										label={"fecha_ingreso"}
										getCommonDataFromChilds={this.getCommonDataFromChilds}
										showIcon
                    value={this.state.fecha_ingreso}
									/>
                </div>
              </Col>
              <Col xs="12" md="4">
                <div className="form-group">
                  <label>Fecha de nacimiento</label>
                  <br />
                  <PCalendar
										label={"fecha_nacimiento"}
										getCommonDataFromChilds={this.getCommonDataFromChilds}
										showIcon
                    value={this.state.fecha_nacimiento}
									/>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="4">
                <div className="form-group">
                  <label>Nombre</label>
                  <input
                    type="text"
                    required
                    value={this.state.nombre}
                    onChange={(e) => this.setState({ nombre: e.target.value })}
                    className="form-control"
                  />
                </div>
              </Col>
              <Col xs="12" md="4">
                <div className="form-group">
                  <label>Apellido Paterno</label>
                  <input
                    type="text"
                    required
                    value={this.state.apellido_paterno}
                    onChange={(e) =>
                      this.setState({ apellido_paterno: e.target.value })
                    }
                    className="form-control"
                  />
                </div>
              </Col>
              <Col xs="12" md="4">
                <div className="form-group">
                  <label>Apellido Materno</label>
                  <input
                    type="text"
                    required
                    value={this.state.apellido_materno}
                    onChange={(e) =>
                      this.setState({ apellido_materno: e.target.value })
                    }
                    className="form-control"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Lugar de nacimiento</label>
                  <input
                    type="text"
                    required
                    value={this.state.lugar_nacimiento}
                    onChange={(e) =>
                      this.setState({ lugar_nacimiento: e.target.value })
                    }
                    className="form-control"
                  />
                </div>
              </Col>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Estado civil</label>
                  <Select
                    options={estados_civiles}
                    onChange={(e) => this.setState({ estado_civil: e.value })}
                    placeholder="Seleccione una opción"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Sexo</label>
                  <Select
                    options={sexo}
                    onChange={(e) => this.setState({ sexo: e.value })}
                    placeholder="Seleccione una opción"
                  />
                </div>
              </Col>
              <Col xs="12" md="6">
                {this.getImageInput("Cargar INE","archivo_ine")}
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>R.F.C.</label>
                  <input
                    type="text"
                    required
                    value={this.state.rfc}
                    onChange={(e) => this.setState({ rfc: e.target.value })}
                    className="form-control"
                  />
                </div>
              </Col>
              <Col xs="12" md="6">
                {this.getImageInput("Cargar R.F.C", "archivo_rfc")}
              </Col>
            </Row>

            <Row>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>CURP</label>
                  <input
                    type="text"
                    required
                    value={this.state.curp}
                    onChange={(e) => this.setState({ curp: e.target.value })}
                    className="form-control"
                  />
                </div>
              </Col>
              <Col xs="12" md="6">
                {this.getImageInput("Cargar CURP", "archivo_curp")}
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>NSS</label>
                  <input
                    type="text"
                    required
                    value={this.state.nss}
                    onChange={(e) => this.setState({ nss: e.target.value })}
                    className="form-control"
                  />
                </div>
              </Col>

              <Col xs="12" md="3">
                {this.getImageInput("Cargar NSS", "archivo_nss")}
              </Col>
              <Col xs="12" md="3">
                {this.getImageInput("Póliza de seguro de vida", "poliza_de_seguro_de_vida")}
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Calle</label>
                  <input
                    type="text"
                    required
                    value={this.state.calle}
                    onChange={(e) => this.setState({ calle: e.target.value })}
                    className="form-control"
                  />
                </div>
              </Col>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Número</label>
                  <input
                    type="text"
                    required
                    value={this.state.numero}
                    onChange={(e) => this.setState({ numero: e.target.value })}
                    className="form-control"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Colonia</label>
                  <input
                    type="text"
                    required
                    value={this.state.colonia}
                    onChange={(e) => this.setState({ colonia: e.target.value })}
                    className="form-control"
                  />
                </div>
              </Col>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Código Postal</label>
                  <input
                    type="text"
                    required
                    value={this.state.codigo_postal}
                    onChange={(e) =>
                      this.setState({ codigo_postal: e.target.value })
                    }
                    className="form-control"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Estado</label>
                  <Select
                    options={estados}
                    onChange={(e) => this.setState({ estado: e.value })}
                    placeholder="Seleccione una opción"
                  />
                </div>
              </Col>
              <Col xs="12" md="6">
                {this.getImageInput("Comprobante de domicilio","archivo_comprobante_domicilio")}
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Teléfono</label>
                  <InputMask
                    mask="(99) 9999-9999"
                    required
                    value={this.state.numero_celular_asignado}
                    onChange={(e) =>
                      this.setState({ numero_celular_asignado: e.target.value })
                    }
                    className="form-control"
                  />
                </div>
              </Col>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    required
                    value={this.state.correo_electronico}
                    onChange={(e) =>
                      this.setState({ correo_electronico: e.target.value })
                    }
                    className="form-control"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Nombre de contacto</label>
                  <input
                    type="text"
                    required
                    value={this.state.nombre_contacto_emergencia}
                    onChange={(e) =>
                      this.setState({
                        nombre_contacto_emergencia: e.target.value,
                      })
                    }
                    className="form-control"
                  />
                </div>
              </Col>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Teléfono de Contacto</label>
                  <InputMask
                    mask="(99) 9999-9999"
                    required
                    value={this.state.telefono_contacto}
                    onChange={(e) =>
                      this.setState({ telefono_contacto: e.target.value })
                    }
                    className="form-control"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Número de Licencia</label>
                  <input
                    type="text"
                    required
                    value={this.state.numero_licencia}
                    onChange={(e) =>
                      this.setState({ numero_licencia: e.target.value })
                    }
                    className="form-control"
                  />
                </div>
              </Col>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Póliza de Vida</label>
                  <input
                    type="text"
                    required
                    value={this.state.poliza}
                    onChange={(e) => this.setState({ poliza: e.target.value })}
                    className="form-control"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
              {this.getImageInput("Cargar tarjeta o estado de cuenta","cargar_tarjeta")}
              </Col>

              <Col xs="12" md="6">
              {this.getImageInput("Cargar fotografía de operador","cargar_photo")}
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                {this.getImageInput("Cargar licencia","archivo_licencia")}
              </Col>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Vencimiento de Licencia</label>
                  <br />
                  <PCalendar
										label={"vigencia"}
										getCommonDataFromChilds={this.getCommonDataFromChilds}
										showIcon
                    value={this.state.vigencia}
									/>
                 
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                {this.getImageInput("Resultados de Antidoping","archivo_antidoping")}
              </Col>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Fecha Antidoping</label>
                  <br />
                  <PCalendar
										label={"fecha_antidoping"}
										getCommonDataFromChilds={this.getCommonDataFromChilds}
										showIcon
                    value={this.state.fecha_antidoping}
									/>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs="12" md="6">
                {this.getImageInput("Resultados de examen médico","archivo_examen_medico")}
              </Col>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Vencimiento Exámen Médico</label>
                  <br />
                  <PCalendar
										label={"vencimiento_examen_medico"}
										getCommonDataFromChilds={this.getCommonDataFromChilds}
										showIcon
                    value={this.state.vencimiento_examen_medico}
									/>
                  
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Unidad</label>
                  <Select
                    options={this.state.unidades}
                    onChange={(e) => this.setState({ unidad_id: e.value })}
                    placeholder="Elegir unidad"
                  />
                </div>
              </Col>

              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Clave</label>
                  <input
                    type="text"
                    required
                    value={this.state.password}
                    onChange={(e) => this.setState({ password: e.target.value })}
                    className="form-control"
                  />
                </div>
              </Col>
            </Row>

            <center>
              {" "}
              <Button disabled={this.state.submit_disabled} className="p-button-warning">Guardar</Button>
              {(this.state.submit_disabled)?<p>Cargando... Espere por favor, procesando datos grandes</p>:<></>}
            </center>
          </form>
      </Panel>
      </div>
      
    );
  }
}

export default CrearOperadores;
