import React from "react";
import {
	Card,
	CardBody,CardTitle

} from 'reactstrap';
import NominaListado from './nomina-views';

class NominaOperadores extends React.Component {
	render() {
		return (
			<Card>
				<CardTitle  className="bg-light border-bottom p-3 mb-0">NÓMINA DE OPERADORES</CardTitle>
				<CardBody>
					<NominaListado></NominaListado>
				</CardBody>
			</Card>
		);
	}
}

export default NominaOperadores;
