import React from "react";
import {
	Card,
	CardBody,CardTitle

} from 'reactstrap';
import BitacoraListado from './bitacoras-views' ;

class BitacoraOperadores extends React.Component {
	render() {
		return (
			<Card>
				<CardTitle  className="bg-light border-bottom p-3 mb-0">BITÁCORA OPERADORES</CardTitle>
				<CardBody>
					<BitacoraListado></BitacoraListado>
				</CardBody>
			</Card>
		);
	}
}

export default BitacoraOperadores;
