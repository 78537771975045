import React, { Component } from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {MultiSelect} from 'primereact/multiselect';
import OperadorServices  from 'controllers/operador/operador-service';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import BitacoraEstadoListado from './bitacora-estados';
import {
	Card,
	CardBody

} from 'reactstrap';
import {urlPdf} from '../../../constants';

export default class BitacoraListado extends Component {

    constructor() {
        super();
        this.state = {
            globalFilter: null,
            visible:false,
            visible2:false
        };
        this.servicioOperador = new OperadorServices();
        this.editar = this.editar.bind(this);
        this.verBitacora = this.verBitacora.bind(this);
    }

    componentDidMount() {
      if(this.props.parametros){
        this.servicioOperador.getFiltroOperadores(this.props.parametros).then(data => this.setState({cars: data}));
      }else{
        this.servicioOperador.getOperadores().then(data => this.setState({cars: data}));
      }
    }


    editar(column){
      this.setState({visible:true,item:column.rowData});
    }

    verBitacora(column){
	     window.open(urlPdf + 'bitacora.php?id_usuario='+column.rowData.usuario_id,"width=200,height=100");
    }



    actionTemplate(rowData, column) {
      return (<div>

        <Button
          type="button" icon="pi pi-list" onClick={e=> this.editar(column)}
          className=""/>
      </div>);
			/*
			<Button
				type="button" icon="pi pi-file" onClick={e=> this.verBitacora(column)}
				className=""/>
			*/
    }

    render() {

        return (<div>
            <Toast ref={(el) => this.growl = el} />
            <Dialog header="Listado de cambios"  visible={this.state.visible} style={{width: '50vw',height:"90vh",overflow:'scroll',backgroundColor:"#fbfbfb"}} modal={true} onHide={() => this.setState({visible: false,visible2:false	})}>
              <Card>
                <CardBody>
                  <BitacoraEstadoListado idUsuario={this.state.item?this.state.item.usuario_id:""}/>
                </CardBody>
              </Card>
            </Dialog>
            <DataTable value={this.state.cars} paginator={true} rows={10}>
                <Column field="numero_empleado" header="Número de empleado"  />
                <Column field="fecha_ingreso" header="Fecha de ingreso"  />
                <Column field="nombre" header="Nombre"  />
                <Column field="lugar_nacimiento" header="Lugar de nacimiento"   />
                <Column field="fecha_nacimiento" header="Fecha de nacimiento"   />
                <Column field="estado_civil" header="Estado Civil"   />
                <Column field="sexo" header="Sexo"   />
                <Column field="edad" header="Edad"   />
                <Column field="domicilio_actual" header="Domicilio"   />
                <Column body={this.actionTemplate.bind(this)} header="Opciones" style={{ textAlign: 'center'}} />
            </DataTable>

      </div>);
    }
}
