import React from "react";
import {
	Col,Row
} from 'reactstrap';
import Select from 'react-select';
import UnidadService  from 'controllers/unidad/unidadService';
import PCalendar from '../../ui-components/calendar';
import moment from 'moment';
import { Toast } from 'primereact/toast';
import {Dropdown} from 'primereact/dropdown';
import {Button} from 'primereact/button';
import { url } from "../../../constants";

const options = [
]



const motivo_baja =[
	{value:"Venta",label:"Venta"},
	{value:"Robo",label:"Robo"},
	{value:"Acciedente",label:"Acciedente"}
];


const modelo =[
	{value:"x",label:"x"}

];
const tipoYear = [
		{value:"1990",label:"1990"},
		{value:"1991",label:"1991"},
		{value:"1992",label:"1992"},
		{value:"1993",label:"1993"},
		{value:"1994",label:"1994"},
		{value:"1995",label:"1995"},
		{value:"1996",label:"1996"},
		{value:"1997",label:"1997"},
		{value:"1998",label:"1998"},
		{value:"1999",label:"1999"},
		{value:"2000",label: "2000"},
		{value:"2001",label:"2001"},
		{value:"2002",label:"2002"},
		{value:"2003",label:"2003"},
		{value:"2004",label:"2004"},
		{value:"2005",label:"2005"},
		{value:"2006",label:"2006"},
		{value:"2007",label:"2007"},
		{value:"2008",label:"2008"},
		{value:"2009",label:"2009"},
		{value:"2010",label:"2010"},
		{value:"2011",label:"2011"},
		{value:"2012",label:"2012"},
		{value:"2013",label:"2013"},
		{value:"2014",label:"2014"},
		{value:"2015",label:"2015"},
		{value:"2016",label:"2016"},
		{value:"2017",label:"2017"},
		{value:"2018",label:"2018"},
		{value:"2019",label:"2019"},
		{value:"2020",label:"2020"},
		{value:"2021",label:"2021"},
		{value:"2022",label:"2022"},

]



const tipo_unidades = [
	{value:"Tractocamión",label:"Tractocamión"},
	{value:"Remolque 53 pies",label:"Remolque 53 pies"},
    {value:"Remolque 48 pies",label:"Remolque 48 pies"},
    {value:"Camión Rabon",label:"Camión Rabon"},
    {value:"Camión Torton",label:"Camión Torton"},
    {value:"Camión 3.5 ton",label:"Camión 3.5 ton"},
    {value:"Pick up",label:"Pick up"},
    {value:"Vehículo utilitario",label:"Vehículo utilitario"},
    {value:"Motocicleta",label:"Motocicleta"},
    {value:"Dolly",label:"Dolly"}
]


const causas_baja = [
	{value:"Venta", label:"Venta"},
	{value:"Accidente", label:"Accidente"},
	{value:"Robo", label:"Robo"},
]



const transmision=[
	{value:"Fuller",label:"Fuller"},
	{value:"Eaton",label:"Eaton"},
	{value:"Otra",label:"Otra"}
];

const rodada=[
	{value:"22.5",label:"22.5"},
	{value:"24.5",label:"24.5"},
    {value:"Otra",label:"Otra"},
];

const ratios=[
	{value:"4:30",label:"4:30"},
	{value:"4:10",label:"4:10"},
	{value:"3:91",label:"3:91"},
    {value:"3:90",label:"3:90"},
    {value:"3:73",label:"3:73"},
    {value:"Otra",label:"Otra"}
];

const marca = [
{value:"Kenworth",label:"Kenworth"},
{value:"Freightliner",label:"Freightliner"},
{value:"International",label:"International"},
{value:"Wabash",label:"Wabash"},
{value:"Great Dane",label:"Great Dabe"},
{value:"Utility",label:"Utility"},
{value:"Stoughton",label:"Stoughton"},
{value:"Hyundai",label:"Hyundai"},
{value:"VW",label:"VW"},
{value:"Audi",label:"Audi"},
{value:"Ford",label:"Ford"},
{value:"etc",label:"etc"}

];

let fileState;

class EditarUnidad extends React.Component {
	constructor(props){
		super(props);
		this.state = {};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.servicio = new UnidadService();
		this.getImageInput = this.getImageInput.bind(this);
		this.getCommonDataFromChilds = this.getCommonDataFromChilds.bind(this);
	}
	handleSubmit(event) {
		event.preventDefault();
		let params = {...this.state}
		const data = new FormData();
		params.factura = params.factura.name
		params.pedimento = params.pedimento.name
		params.tarjeta_circulacion = params.tarjeta_circulacion.name
		params.evidencia_baja = params.evidencia_baja.name
		data.append("unidad", JSON.stringify(params));
		
		if(this.state.factura?.lastModified){
		   data.append("factura", this.state.factura);
		}
		if(this.state.pedimento?.lastModified){
			data.append("pedimento", this.state.pedimento);
		 }
		if(this.state.tarjeta_circulacion?.lastModified){
			data.append("tarjeta_circulacion", this.state.tarjeta_circulacion);
		 }
		if(this.state.evidencia_baja?.lastModified){
			data.append("evidencia_baja", this.state.evidencia_baja);
		}

		let that = this;
		this.servicio.actualizarUnidad(data).then(res =>  {
			if(res && res.result == 'OK'){
				that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Información guardada correctamente'});
			}
			setTimeout(function(){
				that.props.history.push('/app/unidades/consulta');
			}, 500);
		});
	}

	componentDidUpdate(prevProps){
		if (prevProps.values !== this.props.values) {
			fileState = {
				factura:{
					name:this.props.values?.factura
				},
				pedimento: {
					name:this.props.values?.pedimento
				},
				tarjeta_circulacion: {
					name:this.props.values?.tarjeta_circulacion
				},
				evidencia_baja:{
					name:this.props.values?.evidencia_baja
				}
			}
				this.setState({
					id: this.props.values.id,
					tipo_unidad_id: this.props.values.tipo_unidad_id,
					fecha_adquisicion:  this.props.values.fecha_adquisicion?moment(this.props.values.fecha_adquisicion,'DD-MM-YYYY').toDate():null,
					numero_factura: this.props.values.numero_factura,
					numero_pedimento: this.props.values.numero_pedimento,
					placas: this.props.values.placas,
					eco: this.props.values.eco,
					marca_id: this.props.values.marca_id,
					serie_vin: this.props.values.serie_vin,
					numero_motor: this.props.values.numero_motor,
					fecha_baja: this.props.values.fecha_baja,
					numero_baja_placas: this.props.values.numero_baja_placas,
					causa_baja: this.props.values.causa_baja,
					tipo_unidad: this.props.values.tipo_unidad,
					marca: this.props.values.marca,
					transmision: this.props.values.transmision,
					eje_delantero: this.props.values.eje_delantero,
					eje_tracero: this.props.values.eje_tracero,
					modelo: this.props.values.modelo,
					year: this.props.values.year,
					rodada_rin: this.props.values.rodada_rin,
					ratio: this.props.values.ratio,
					motivo_baja: this.props.values.motivo_baja,
					...fileState
				});
		}
	}

	getCommonDataFromChilds(data)
	{
		switch (data.label) {
			case "fecha_adquisicion":
				this.setState({fecha_adquisicion: moment(data.value).format('YYYY-MM-DD').toString()});
				break;
		}
	}
	getImageInput(title,key)
	{
		return (
		<div className="image-group">
			<label className="title">{title}</label>
			<br />
			<div className="form-group">
			{!this.state[key]?.name && 
				<div className="custom-file-upload">
				<label htmlFor={`file-upload-${key}`} className="p-button-warning p-button m-0 w-100">
					<i className="pi pi-cloud-upload"></i> Seleccionar archivo
				</label>
				<input
					id={`file-upload-${key}`}
					type="file"
					style={{ display: "none" }}
					onChange={(e) =>
					this.setState({ [key]: e.target.files[0] })
					}
					chooseLabel="Seleccionar Archivo"
				/>
				</div>
			}
			{this.state[key]?.name && 
				<div className="file-info d-flex justify-content-between">
				  <div className="label-info">
						{this.state[key].name}
					</div>
			<div className="d-flex">
			{fileState?.[key]?.name && 
					<div className="action-info mr-3">
						{this.state[key].name.includes('.') && 
						<i
						onClick={() => {
							window.open(url+"altadeunidad/"+this.state[key].name,'_blank')
						}}
						className="pi pi-download"
						style={{ fontSize: "1rem", cursor: "pointer" }}
						></i>
						}
					</div>
	        }
				<div className="action-info">
                <i
                  onClick={() => {
                    this.setState({ [key]:{name:null}});
					fileState && fileState[key] && (fileState[key].name = null);
				}}
                  className="pi pi-trash"
                  style={{ fontSize: "1.5em", cursor: "pointer" }}
                ></i>
                </div>                                 
				</div>
			</div>
			}
			</div>
		</div>
		)
	}
	render() {
		return (
		<div>
			<Row>
				<Col>
					<Toast ref={(el) => this.growl = el} />
					<form onSubmit={this.handleSubmit}>
						<Row>
							<Col xs="12" md="4">
								<div className="form-group">
									<label> Número Económico:</label>
									<input type="text" name="eco" value={this.state.eco} onChange={(e) => this.setState({eco:e.target.value})} className="form-control" />
								</div>
							</Col>
							<Col xs="12" md="4">
								<div className="form-group">
									<label> Placas:	</label>
									<input type="text" name="placas" value={this.state.placas} onChange={(e) => this.setState({placas:e.target.value})} className="form-control" />
								</div>
							</Col>
							<Col xs="12" md="4">
								<div className="form-group">
									<label> Fecha de adquisición:	</label><br/>
									<PCalendar
										label={"fecha_adquisicion"}
										getCommonDataFromChilds={this.getCommonDataFromChilds}
										showIcon
										value={this.state.fecha_adquisicion}
									/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs="12" md="6">
								<div className="form-group">
									<label> Tipo de unidad:		</label>
									<Select options={tipo_unidades}  value={tipo_unidades.filter(option=> option.label === this.state.tipo_unidad)} onChange={e=> this.setState({tipo_unidad:e.value})} placeholder="Seleccione el tipo"  />
								</div>
							</Col>
							<Col xs="12" md="6">
								<div className="form-group">
									<label> Marca:	</label>
									<Select options={marca} value={marca.filter(option => option.value === this.state.marca)} onChange={(e) => this.setState({marca	: e.value})}  placeholder="Seleccione una marca"  />
								</div>
							</Col>							
						</Row>
						<Row>
							<Col xs="12" md="6">
								<div className="form-group">
									<label>Factura:</label>
										<input type="text" name="numero_factura" value={this.state.numero_factura} onChange={(e) => this.setState({numero_factura	: e.target.value})} className="form-control" />
								</div>
							</Col>
							<Col xs="12" md="6">
								<div className="form-group">
									<label> Número de pedimento:	</label>
									<input type="text" name="numero_pedimento" value={this.state.numero_pedimento} onChange={(e) => this.setState({numero_pedimento	: e.target.value})} className="form-control" />
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs="12" md="6">
								{this.getImageInput("Cargar factura","factura")}
							</Col>				
							<Col xs="12" md="6">
								{this.getImageInput("Cargar pedimento","pedimento")}
							</Col>
						</Row>
						<Row>
							<Col xs="12" md="6">
								{this.getImageInput("Tarjeta de circulación","tarjeta_circulacion")}
							</Col>				
							<Col xs="12" md="6">
								{this.getImageInput("Evidencia de motivo de baja","evidencia_baja")}
							</Col>
						</Row>
						<Row>
							<Col xs="12" md="6">
								<div className="form-group">
									<label> Descripción del catálogo:	</label>
									<input type="text" value={this.state.modelo} onChange={e=> this.setState({modelo:e.target.value})}  className="form-control"/>
								</div>
							</Col>
							<Col xs="12" md="6">
								<div className="form-group">
									<label> Serie (VIN):</label>
									<input type="text" name="serie_vin" value={this.state.serie_vin} onChange={(e) => this.setState({serie_vin	: e.target.value})} className="form-control" />
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs="12" md="6">
								<div className="form-group">
									<label> Año:	</label>
									<Select options={tipoYear} value={tipoYear.filter(option => option.label === this.state.year)} onChange={(e) => this.setState({year	: e.value})}  placeholder="Selecciona un año"  />
								</div>
							</Col>
							<Col xs="12" md="6">
								<div>
									<label> Motor:	</label>
									<input type="text" name="numero_motor" readOnly={(this.state.tipo_unidad === "Remolque")} value={this.state.numero_motor} onChange={(e) => this.setState({numero_motor	: e.target.value})}  className="form-control" />
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs="12" md="6">
								<div className="form-group">
									<label> Eje Delantero:</label>
									<input type="text" name="eje_delantero" value={this.state.eje_delantero} onChange={(e) => this.setState({eje_delantero	: e.target.value})} className="form-control" />
								</div>
							</Col>
							<Col xs="12" md="6">
								<div className="form-group">
									<label> Eje Trasero:</label>
									<input type="text" name="eje_tracero" value={this.state.eje_tracero} onChange={(e) => this.setState({eje_tracero	: e.target.value})} className="form-control" />
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs="12" md="6">
								<div className="form-group">
									<label> Transmisión:</label><br/>
									<Dropdown className="w-100" options={transmision} placeholder="Seleccione una transmisión" value={this.state.transmision} onChange={(e) => this.setState({transmision	: e.value})}/>
								</div>
							</Col>
							<Col xs="12" md="6">
								<div className="form-group">
									<label> Ratio:</label><br/>
									<Dropdown  className="w-100" options={ratios} value={this.state.ratio} placeholder="Seleccione un Ratio" onChange={(e) => this.setState({ratio	: e.value})}/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs="12" md="6">
								<div className="form-group">
									<label> Rodada de Rin:</label><br/>
									<Dropdown className="w-100" options={rodada} editable="true" placeholder="Seleccione una rodada" value={this.state.rodada_rin} onChange={(e) => this.setState({rodada_rin	: e.value})}/>
								</div>
							</Col>
							<Col xs="12" md="6">
								<div className="form-group">
									<label>Motivo de baja</label>
									<Select className="w-100" options={motivo_baja} value={motivo_baja.filter(option => option.value === this.state.motivo_baja)} onChange={e=> this.setState({motivo_baja:e.value})} placeholder="Elegir el motivo de baja..."/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs="12" className="text-center">
								<Button className="p-button-warning" >Guardar</Button>
							</Col>
						</Row>
					</form>
				</Col>
			</Row>
		</div>
		);
	}
}

export default EditarUnidad;
