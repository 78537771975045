import React from "react";
import {
	Card,
	CardBody,Container, Row,Col,CardTitle

} from 'reactstrap';

import { Toast } from 'primereact/toast';
import moment from 'moment';
import Select from 'react-select';
import { Panel } from 'primereact/panel';
import MantenimientoService from  'controllers/mantenimiento/mantenimiento-service';
const options = [];

class CrearReporteFalla extends React.Component {
	constructor(props){
		super(props);
		this.state={
			titulo:"Agregar"
		}
		this.servicio = new MantenimientoService();

		this.handleSubmit = this.handleSubmit.bind(this);
		this.headerTemplate = this.headerTemplate.bind(this);
	}


	componentDidMount() {
		let values = this.props.location.state;
		console.log(values);
		if(values){
			this.setState({
				nombre: values.name,
				descripcion: values.descripcion,

				id:values.id,
				titulo:"EDITAR",
				sobreescribir: true
			});
		}

	}



	handleSubmit(event) {
				event.preventDefault();

				 console.log(this.state);
				let that = this;

				let datos ={};
				const data = new FormData();
				datos.nombre = this.state.nombre;
				datos.descripcion = this.state.descripcion;


				data.append("ruta",JSON.stringify(datos));
				data.append("contrato",this.state.contrato);
				data.append("asignacion",this.state.asignacion);

				let mensajes = [];

				if(!this.state.nombre){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Ingrese un nombre'});
				}
				if(!this.state.descripcion){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Ingrese una descripción'});
				}

				if(mensajes.length){
					this.growl.show(mensajes);
				}else{

					let funcion = console.log;
					if(this.state.sobreescribir){
						funcion = this.servicio.actualizarCatalogo;
						datos.id = this.state.id;
					}else{
						funcion = this.servicio.guardarCatalogo;
					}

					funcion(datos).then(res =>  {
							if(res && res.result == 'OK'){

								that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Información guardada correctamente'});
								setTimeout(function(){
										 	that.props.history.push('/app/mantenimiento/catalogo');
								 }, 500);

							}else if(res && res.result == 'EXISTE'){
								that.growl.show({severity: 'error', summary: 'El Id de ruta '+this.state.id_ruta+' ya se encuentra registrado', detail: 'Ingrese otro código o consulte su información para editarla.'});
							}
					});
				}
	}
	headerTemplate()
	{
		return <div className="p-panel-header flex-wrap  d-flex">
			{this.state.titulo} Actividad
		</div>;
	}
	render() {

		return (
			<div className="container">
				<Panel headerTemplate={this.headerTemplate}>
					<Toast ref={(el) => this.growl = el} />
					<form >
						<Row>
							<Col xs="6">
									<div className="form-group">
										<label>Nombre</label><br/>
											<input type="text"  value={this.state.nombre}  onChange={e => this.setState({nombre : e.target.value})}  className="form-control"/>
									</div>

							</Col>
							<Col xs="12">
									<div className="form-group">
										<label>Descripción</label>
										<input type="text"  value={this.state.descripcion} onChange={e => this.setState({descripcion : e.target.value})}  className="form-control"/>
									</div>
							</Col>
						</Row>
						<center> <button className="btn btn-primary" onClick={this.handleSubmit} >Guardar</button></center>
					</form>
				</Panel>
			</div>
			
		);
	}
}

export default CrearReporteFalla;
