	import axios from 'axios';
import {url} from '../../constants';

export default class Service {

			/**
			Servicios para  los reportes
			*/

			guardarReporte(contenido){
				console.log(contenido)
					return axios.post(url + 'reporte-falla/guardar',{reporte:contenido },  {
						}).then(res => res.data);
			}

			listadoReporte(){
				return axios.get(url + 'reporte-falla/listado',{})
								.then(res => res.data);
			}

			eliminarReporte(id){
					return axios.post(url + 'reporte-fallas/eliminar',{
						id:id

					}).then(res => res.data);
			}


			actualizarReporte(objeto){

			  let objeto_nuevo =Object.assign({}, objeto);

			//	delete embarque.facturar_a;

					return axios.post(url + 'catalogo/actualizar',{
						contenido:objeto_nuevo

					}).then(res => res.data);
			}

			/**
			Servicios para  las ordenes de compra
			*/
			guardarOrdenCompra(contenido){
				console.log(contenido)
					return axios.post(url + 'orden-compra/guardar',{orden_compra:contenido },  {
						}).then(res => res.data);
			}

			listadoOrdenCompra(){
				return axios.get(url + 'orden-compra/listado',{})
								.then(res => res.data);
			}


			eliminarOrdenCompra(id){
					return axios.post(url + 'orden-compra/eliminar',{
						id:id

					}).then(res => res.data);
			}

			actualizarOrdenCompra(objeto){

			  let objeto_nuevo =Object.assign({}, objeto);

			//	delete embarque.facturar_a;

					return axios.post(url + 'orden-compra/actualizar',{
						contenido:objeto_nuevo

					}).then(res => res.data);
			}
			/**
			Servicios para  los ordenes de servici
			*/
			guardarOrdenServicio(contenido){
				console.log(contenido)
					return axios.post(url + 'orden-servicio/guardar',{orden_servicio:contenido },  {
						}).then(res => res.data);
			}

			listadoOrdenServicio(){
				return axios.get(url + 'orden-servicio/listado',{})
								.then(res => res.data);
			}

			eliminarOrdenServicio(id){
					return axios.post(url + 'orden-servicio/eliminar',{
						id:id

					}).then(res => res.data);
			}

			actualizarOrdenServicio(objeto){

				let objeto_nuevo =Object.assign({}, objeto);

			//	delete embarque.facturar_a;

					return axios.post(url + 'orden-servicio/actualizar',{
						contenido:objeto_nuevo

					}).then(res => res.data);
			}
			/**
			Servicios para  el tema de reparacion
			*/
			guardarReparacion(contenido){
				console.log(contenido)
					return axios.post(url + 'reparacion/guardar',{reparacion:contenido },  {
						}).then(res => res.data);
			}

			listadoReparacion(){
				return axios.get(url + 'reparacion/listado',{})
								.then(res => res.data);
			}

			eliminarReparacion(id){
					return axios.post(url + 'reparacion/eliminar',{
						id:id

					}).then(res => res.data);
			}


			actualizarReparacion(objeto){

			  let objeto_nuevo =Object.assign({}, objeto);

			//	delete embarque.facturar_a;

					return axios.post(url + 'reparacion/actualizar',{
						contenido:objeto_nuevo

					}).then(res => res.data);
			}


			/**
			Servicios para el tema de inventario
			*/
			guardarInventario(contenido){
				console.log(contenido)
					return axios.post(url + 'inventario/guardar',{inventario:contenido },  {
						}).then(res => res.data);
			}

			listadoInventario(){
				return axios.get(url + 'inventario/listado',{})
								.then(res => res.data);
			}
			eliminarInventario(id){
					return axios.post(url + 'inventario/eliminar',{
						id:id

					}).then(res => res.data);
			}

			actualizarInventario(objeto){

			  let objeto_nuevo =Object.assign({}, objeto);

			//	delete embarque.facturar_a;

					return axios.post(url + 'inventario/actualizar',{
						contenido:objeto_nuevo

					}).then(res => res.data);
			}


			/**
			Servicios para el tema de mantenimiento  programado
			*/
			guardarMantenimientoProgramado(contenido){
				console.log(contenido)
					return axios.post(url + 'mantenimiento-programado/guardar',{mantenimiento:contenido },  {
						}).then(res => res.data);
			}

			listadoMantenimientoProgramado(){
				return axios.get(url + 'mantenimiento-programado/listado',{})
								.then(res => res.data);
			}

			eliminarMantenimiento(id){
					return axios.post(url + 'mantenimiento-programado/eliminar',{
						id:id

					}).then(res => res.data);
			}
			actualizarMantenimientoProgramado(objeto){

			  let objeto_nuevo =Object.assign({}, objeto);

			//	delete embarque.facturar_a;

					return axios.post(url + 'mantenimiento-programado/actualizar',{
						contenido:objeto_nuevo

					}).then(res => res.data);
			}

			/**
			Servicios para el tema de mantenimiento  programado
			*/
			guardarCatalogo(contenido){
				console.log(contenido)
					return axios.post(url + 'catalogo/guardar',{mantenimiento:contenido },  {
						}).then(res => res.data);
			}

			listadoCatalogo(){
				return axios.get(url + 'catalogo/listado',{})
								.then(res => res.data);
			}

			listadoReporteAcceso(){
				return axios.get(url + 'reporte-acceso/listado',{})
								.then(res => res.data);
			}
			eliminarReporteAcceso(id){
					return axios.post(url + 'reporte-acceso/eliminar',{
						id:id
					}).then(res => res.data);
			}


			eliminarCatalogo(id){
					return axios.post(url + 'catalogo/eliminar',{
						id:id

					}).then(res => res.data);
			}


			actualizarCatalogo(objeto){

			  let objeto_nuevo =Object.assign({}, objeto);

			//	delete embarque.facturar_a;

					return axios.post(url + 'catalogo/actualizar',{
						contenido:objeto_nuevo

					}).then(res => res.data);
			}


			/*metodos para la modificación de los proveedores */

			guardarProveedor(contenido){
				console.log(contenido)
					return axios.post(url + 'proveedores/guardar',{orden_servicio:contenido },  {
						}).then(res => res.data);
			}

			listadoProveedor(){
				return axios.get(url + 'proveedores/listado',{})
								.then(res => res.data);
			}

			eliminarProveedor(id){
					return axios.post(url + 'proveedores/eliminar',{
						id:id

					}).then(res => res.data);
			}

			actualizarProveedor(objeto){

				let objeto_nuevo =Object.assign({}, objeto);

			//	delete embarque.facturar_a;

					return axios.post(url + 'proveedores/actualizar',{
						contenido:objeto_nuevo

					}).then(res => res.data);
			}

			/*metodos para la modificación de los predictivos */

			guardarPredictivo(contenido){
				console.log("contenido======>",contenido)
					return axios.post(url + 'predictivos/guardar',{orden_servicio:contenido },  {
						}).then(res => res.data);
			}

			listadoPredictivo(){
				return axios.get(url + 'predictivos/listado',{})
								.then(res => res.data);
			}

			eliminarPredictivo(id){
					return axios.post(url + 'predictivos/eliminar',{
						id:id

					}).then(res => res.data);
			}

			actualizarPredictivo(objeto){

				let objeto_nuevo =Object.assign({}, objeto);

			//	delete embarque.facturar_a;

					return axios.post(url + 'predictivos/actualizar',{
						contenido:objeto_nuevo

					}).then(res => res.data);
			}


			catalogoPredictivos(){

				return axios.get(url + 'predictivos/catalogo',{})
								.then(res => res.data.data);
			}


}
