import React,{Component} from "react";
import {
	Card,
	CardBody,

} from 'reactstrap';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';

import CarService from 'controllers/demo/carservice';
import 'primereact/resources/themes/nova/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import ListadoRoles from './RolesViews';

class ResultadoRoles extends Component {
	render() {
		return (
			<Card>
				<CardBody>
					<center><h4>PERMISOS Y PRIVILEGIOS</h4></center>
				<ListadoRoles> </ListadoRoles>
				</CardBody>
			</Card>
		);
	}
}

export default ResultadoRoles;
