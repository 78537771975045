let local = false;  // false
let node_version = "10.16.0";
export var url = local ? "http://localhost:3100/" : "https://exnac.gravipark.com/";
export var urlDocuments = local ? "http://localhost:3100/documents/" : "https://exnac.gravipark.com/documents/";
export var urlPdf = local
  ? "http://localhost/exnac_pdf/"
  : "https://exnac.gravipark.com/cp/pdf/";

export const operadorFiles = [
  {
    key:"file_cargar_tarjeta",
    value:"Tarjeta o estado de cuenta"
  },
  {
    key:"file_cargar_photo",
    value:"Fotografía de operador"
  },
  {
    key:"file_antidoping",
    value:"Resultados de Antidoping"
  },
  {
    key:"file_comprobante_domicilio",
    value:"Comprobante de domicilio"
  },
  {
    key:"file_curp",
    value:"CURP"
  },
  {
    key:"file_examen_medico",
    value:"Resultados de examen médico"
  },
  {
    key:"file_ine",
    value:"INE"
  },
  {
    key:"file_licencia",
    value:"Licencia"
  },
  {
    key:"file_nss",
    value:"NSS"
  },
  {
    key:"file_rfc",
    value:"R.F.C"
  },
  {
    key:"file_poliza_de_seguro_de_vida",
    value:"Póliza de seguro de vida"
  }
]