import React from "react";
import {
	Card,
	CardBody,CardTitle

} from 'reactstrap';
import LlantasListado from "./llantas-views";

class ResultadoLlantas extends React.Component {
	constructor(props){
		super(props);
	}
	render() {
		return (
			<Card>
				<CardTitle  className="bg-light border-bottom p-3 mb-0">CONSULTAR LLANTAS</CardTitle>
				<CardBody>
					<LlantasListado parametros={this.props.location.state}></LlantasListado>
				</CardBody>
			</Card>
		);
	}
}

export default ResultadoLlantas;
