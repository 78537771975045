import React from "react";
import {
	Card,
	CardBody,Col, Row, Container,CardTitle

} from 'reactstrap';

import { Toast } from 'primereact/toast';
import EditarOperador from './editar';
import { Panel } from 'primereact/panel';

class EditarPagina extends React.Component {
	constructor(props){
		super(props);
		this.state={};
	}

	componentDidMount(){
		this.setState({
			item : this.props.location.state
		});
	}

	render() {

		return (
			<div className="container">
				<Panel header="Editar Operador">
					<Toast ref={(el) => this.growl = el} />
					<Row>
						<Col>
							<EditarOperador {...this.props} values={this.state.item}  />
						</Col>
					</Row>
				</Panel>
			</div>
		);
	}
}

export default EditarPagina;
