import React from "react";
import {
	Card,
	CardBody,Container, Row,Col,CardTitle

} from 'reactstrap';

import Select from 'react-select';

import { Toast } from 'primereact/toast';
import { InputSwitch } from 'primereact/inputswitch';
import VigilanciaService from  'controllers/vigilancia/vigilancia-service';
import PCalendar from '../../ui-components/calendar';
import PTimer from '../../ui-components/timer';

import moment from 'moment';
import OperadorServices  from 'controllers/operador/operador-service';
import UnidadService  from 'controllers/unidad/unidadService';
import RemolqueServices from 'controllers/remolque/remolqueservice';

import { Checkbox } from "primereact/checkbox";
import { Panel } from 'primereact/panel';


class InspeccionVigilancia extends React.Component {
	constructor(){
		super();
		this.state= {
			checks: [],
			comentarios:[]
		};
		this.servicio = new VigilanciaService();
		this.servicioR = new RemolqueServices();
		this.getCommonDataFromChilds = this.getCommonDataFromChilds.bind(this);

		this.servicioO = new OperadorServices();
		this.servicioU = new UnidadService();

		this.servicioO.getOperadores().then(data =>  {
			let arrOperadores = [];
			data.forEach(item=>{
				arrOperadores.push({value:item.usuario_id,label:item.nombre});

			});
			this.setState({operadores: arrOperadores});
		});
		this.servicioU.getUnidades().then(data =>  {
			let arrUnidades = [];
			data.forEach(item=>{
				arrUnidades.push({value:item.id,label:item.eco});

			});
			this.setState({unidades: arrUnidades});
		});
		this.servicioR.getRemolques().then(data =>  {
			let arrRemolques= [];
			data.forEach(item=>{
				arrRemolques.push({value:item.id,label:item.eco});

			});
			this.setState({remolques: arrRemolques});
		});

		this.handleSubmit = this.handleSubmit.bind(this);

	}
	handleSubmit(event) {
				event.preventDefault();

				let datos ={};
				let that = this;

				datos.folio = this.state.folio;
				datos.fecha = moment(this.state.fecha).format('YYYY-MM-DD').toString();
				datos.hora = (this.state.hora)?this.state.hora:moment(this.state.fecha).format('hh:mm A').toString();
				datos.nombre = this.state.nombre;
				datos.num_unidad = this.state.num_unidad;
				datos.placas = this.state.placas;
				datos.num_remolque = this.state.num_remolque;
				datos.num_sello = this.state.num_sello;
				datos.placas_remolque = this.state.placas_remolque;
				datos.checks = this.state.checks;
				datos.comentarios = this.state.comentarios;
				datos.elaborado_por = this.state.elaborado_por || false;
				datos.especificacion = this.state.especificacion;
				this.servicio.guardarReporte17(datos).then(res =>  {
						if(res && res.result == 'OK'){
							that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Información guardada correctamente'});
							setTimeout(function(){
									that.props.history.push('/app/inspeccion/reportes');
							 }, 500);

						}else if(res && res.result == 'EXISTE'){
							that.growl.show({severity: 'error', summary: 'El Id de ruta '+this.state.id_ruta+' ya se encuentra registrado', detail: 'Ingrese otro código o consulte su información para editarla.'});
						}
				});
	}

	vista(elemento, i){
		const descripcion = [
			'Está libre de daño y libre de material extraño',
			'No compartimentos y libre de material extraños',
			'No agujeros o compartimentos',
			'Sólo un agujero para cargar, no soldaduras',
			'Muestran averías sobre uso o presión baja',
			'Tiene respiraciones',
			'Material extraño',
			'Tienen reparaciones físicas y soldadura',
			'Reparaciones o agujeros',
			'Especificar daños',
			'Revisar debajo de la misma',
			'No compartimentos o reparaciones',
			'Especificar daños',
			'Especificar daños',
			'Está libre de daño y libre de material extraño',
			'Especificar daños',
			'Especificar',
			'Está libre de contaminación'
		];

		let arreglo = Object.assign({}, this.state.checks);
		arreglo[i] = false
		const  objeto = (value) =>{
			arreglo[i] = value;
			return arreglo;
		};

		let arreglo2 = Object.assign({}, this.state.comentarios);
		arreglo2[i] = false
		const  objeto2 = (value) =>{
			arreglo2[i] = value;
			return arreglo2;
		};
		return (
				<Row>
					<Col xs="6" sm="4">
						<br/>
						<div Style="margin:5px;">
							<InputSwitch Style="margin-bottom: -7px" checked={this.state.checks[i]}  onChange={(e) => this.setState({ checks:objeto(e.value)})} />
							&nbsp;
							{elemento}
						</div>
					</Col>
					<Col xs="6" sm="5">
						<div Style="margin:5px;"><br/>
							{descripcion[i]}
						</div>
					</Col>
					<Col sm="3">
							<div className="form-group">
								<label>Comentario</label><br/>
									<input type="text"  value={this.state.comentarios[i]} onChange={e => this.setState({comentarios : objeto2(e.target.value)})} className="form-control"/>
							</div>

					</Col>
				</Row>
		);

		/*


		*/
	}


	listadoPuntos(){
		const elementos = [
			'Defensa',
			'Motor',
			'Piso de cabina',
			'Tanque de combustible',
			'Llantas',
			'Ejes',
			'Cabina-camarote',
			'Compresor-tanques de aire',
			'Escape',
			'Refrigeración',
			'Quinta rueda',
			'Chasis',
			'Puertas',
			'Paredes laterales',
			'Defensa',
			'Techo',
			'Sello/candado de seguridad',
			'Seguridad Agrícola'
    ];
    const listItems = elementos.map((elemento,i ) =>
      this.vista(elemento,i)
   );
   return (
		 	<div>
      	{listItems}
			</div>
   )
	}

	getCommonDataFromChilds(data)
	{
		switch (data.label) {
			case "fecha":
				this.setState({fecha: moment(data.value).format('YYYY-MM-DD').toString(),semana:moment(data.value).week()});
				break;
			case "hora":
				this.setState({hora: data.value});
				break;
		}
	}

	render() {
		return (
			<div className="container">
			<Panel header="Reporte De Inspección 18 Puntos">
			<Toast ref={(el) => this.growl = el} />
				<Row>
					<Col md="4">
							<div className="form-group">
								<label>Folio</label><br/>
								<input type="text" readOnly="true"  value={this.state.folio} onChange={e => this.setState({folio : e.target.value})} className="form-control" placeholder="Sin asignar"/>
							</div>

					</Col>
					<Col md="4">
							<div className="form-group">
								<label className="d-block">Fecha</label>
								<PCalendar
									label={"fecha"}
									getCommonDataFromChilds={this.getCommonDataFromChilds}
									showIcon
									value={this.state.fecha}
								/>
							</div>
					</Col>
					<Col md="4">
							<div className="form-group">
								<label className="d-block">Hora</label>
								<PTimer
									label={"hora"}
									getCommonDataFromChilds={this.getCommonDataFromChilds}
									showIcon
								/>
							</div>
					</Col>
				</Row>
				<Row>
					<Col md="4">
							<div className="form-group">
								<label>Nombre del operador</label><br/>
									<Select options={this.state.operadores}  onChange={e =>	this.setState({nombre: e.label}) } placeholder="Elegir operador"/>
							</div>

					</Col>
					<Col md="4">
							<div className="form-group">
								<label>Número de la unidad</label><br/>
									<Select options={this.state.unidades}  onChange={e =>	this.setState({num_unidad: e.value,placas:e.label}) }  placeholder="Elegir unidad"/>
							</div>

					</Col>
					<Col md="4">
							<div className="form-group">
								<label>Placas</label><br/>
									<input type="text"  value={this.state.placas} readOnly className="form-control"/>
							</div>

					</Col>
				</Row>
				<Row>
					<Col md="4">
							<div className="form-group">
								<label>Número del remolque</label><br/>
									<Select options={this.state.remolques}  onChange={e =>	this.setState({num_remolque: e.value,placas_remolque:e.label}) }  placeholder="Elegir remolque"/>

							</div>

					</Col>
					<Col md="4">
							<div className="form-group">
								<label>Número de sello</label><br/>
									<input type="text"  value={this.state.num_sello} onChange={e => this.setState({num_sello : e.target.value})} className="form-control"/>
							</div>

					</Col>
					<Col md="4">
							<div className="form-group">
								<label>Placas</label><br/>
									<input type="text"  value={this.state.placas_remolque} readOnly className="form-control"/>
							</div>

					</Col>
				</Row>


				{this.listadoPuntos.bind(this)()}

				<br/><br/>
				<Row>
					<Col xs="12">
						{/* <InputSwitch Style="margin-bottom: -7px" />&nbsp;
						¿Encuentra material sospechoso?
						<br/><br/> */}
						<div className="form-group">
								<div className="justify-content-start d-flex">
									<Checkbox
										inputId="cb1"
										checked={this.state.elaborado_por}
										onChange={(e) =>
											this.setState({ elaborado_por: e.checked })
										}
									/>
									<label htmlFor="cb1" className="p-checkbox-label mx-2">
										Firmar digitalmente
									</label>
								</div>
							</div>
						<div className="form-group">
							<label>Especifique</label><br/>
								<input type="text"  value={this.state.especificacion} onChange={e => this.setState({especificacion : e.target.value})} className="form-control"/>
								</div>
					</Col>
				</Row>
						<center> <button className="btn btn-primary" onClick={this.handleSubmit} >Guardar</button></center>
			</Panel>
			</div>
		);
	}

}

export default InspeccionVigilancia;
