import React from "react";
import {
	Card,
	CardBody,Container,Col,Row,CardTitle

} from 'reactstrap';
import {Calendar} from 'primereact/calendar';
import Select from 'react-select';
import ClienteService  from 'controllers/cliente/clienteservice';
import OperadorServices  from 'controllers/operador/operador-service';
import EmbarqueService  from 'controllers/embarque/embarque-service';
import { Toast } from 'primereact/toast';
import EmbarqueListado from './lista-corta';
import {TabView,TabPanel} from 'primereact/tabview';
import xlsx from "json-as-xlsx"
import { urlPdf } from "../../../constants";
import { map } from "lodash";
import { Panel } from 'primereact/panel';
import { Accordion, AccordionTab } from 'primereact/accordion';
import {Button} from 'primereact/button';
import CheckPermissions from "controllers/permissions/services";
import PCalendar from "views/ui-components/calendar";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { lab } from "chroma-js";


const options =[
	{value:"1", label:"No pagado"},
	{value:"2", label:"Pre-Nómina"},
	{value:"3", label:"Nómina"}
]

class ConsultaEmbarque extends React.Component {

	constructor(props){
		super(props);
		this.state={
			folio:"",
			cliente:"",
			estatus:"",
			selecteItem: [],
			search:{
				from:"",
				to:"",
				numero:"",
				operador_id:""
			},
			activeIndex:0,
			estadoStatus: 1,
		}


		this.servicio = new ClienteService();
		this.servicioO = new OperadorServices();
		this.embarqueService = new EmbarqueService();
		this.operadorArray = [];

		this.servicio.getClientes().then(data =>  {
			let arrClientes = [];
			data.forEach(item=>{
				arrClientes.push({value:item.id,label:item.razon_social,rfc:item.rfc});

			});
			this.setState({clientes: arrClientes});
		});

		this.servicioO.getOperadores().then((data) => {
			let arrOperadores = [];
			data.forEach((item) => {
			  arrOperadores.push({ value: item.usuario_id, label: item.nombre+ ' '+ item.apellido_paterno+ ' '+item.apellido_materno });
			  this.operadorArray.push({
				id: item.usuario_id,
				unidadId: item.unidad_id,
			  });
			});
			this.setState({ operadores: arrOperadores });
		  });


		this.reset_buscar = this.reset_buscar.bind(this);
		this.permissionService = new CheckPermissions(this);
		this.getCommonDataFromChilds = this.getCommonDataFromChilds.bind(this);
		this.handleEmbarquetable = this.handleEmbarquetable.bind(this);

	}

	handleEmbarquetable(estatus){	
		this.embarqueService.getFiltroEmbarquesEstado({...this.state.search, estatus: estatus})
		  .then((data) => {this.setState({embarques: data, estadoStatus:estatus})})
	}

	reset_buscar(){

		let getSearchReset = this.state.search || {};
		getSearchReset.from = '';
		getSearchReset.to = '';
		getSearchReset.numero = '';
		getSearchReset.operador_id = '';
		this.setState({search:getSearchReset})

		this.embarqueService.getFiltroEmbarquesEstado({estatus: this.state.estadoStatus})
		  .then((data) => {this.setState({embarques: data})})
	}

	exportClick = () => {
		this.servicioO.getReports(1, 'embarque').then((response) => {
			let settings = {
				fileName: response.data.filename,
			  }
			xlsx(response.data.sheet, settings)
		})
	}

	handleCheckbox = (data) => {
		let selected = this.state.selecteItem
		let index = selected.indexOf(data)
		if(index > -1){
			selected.splice(index, 1)
		} else {
			selected.push(data)
		}
	}
	groupBy = (xs, key) => {
		return xs.reduce(function(rv, x) {
			(rv[x[key]] = rv[x[key]] || []).push(x);
			return rv;
		}, {});
	};
	unCheckAll() {
		let  inputs = document.querySelectorAll('.all_checkbox');
		for (let i = 0; i < inputs.length; i++) {
			inputs[i].checked = false;
		}
	  }
	handlePasar = () => {
		if(this.state.selecteItem.length > 0){
			let id = map(this.state.selecteItem,'id')
			const params = {
				id : id.toString(),
				estatus:3
			}
			this.embarqueService.updateEstatus(params).then(res => {
				if (res && res.result == 'OK') {
					this.growl.show({ severity: 'success', summary: 'Operación completada', detail: 'Registros trasladados a Pre-Nómina con éxito' });
					this.setState({selecteItem:[]})
					this.buscar();
					this.unCheckAll();
				} else if (res && res.result == 'EXISTE') {
					this.growl.show({severity: 'error', summary: 'Operación fallida', detail: 'algo salió mal'});
				}
			});
		}
		else
		{
			this.growl.show({severity: 'error', summary: 'Operación fallida', detail: 'Por favor seleccione cualquiera'});
		}
	}
	handlePasarPre = () => {
		if(this.state.selecteItem.length > 0){
			let id = map(this.state.selecteItem,'id')
			const params = {
				id : id.toString(),
				estatus:2
			}
			this.embarqueService.updateEstatus(params).then(res => {
				if (res && res.result == 'OK') {
					this.growl.show({ severity: 'success', summary: 'Operación completada', detail: 'Registros trasladados a Pre-Nómina con éxito' });
					this.setState({selecteItem:[]})
					this.buscar();
					this.unCheckAll();
				} else if (res && res.result == 'EXISTE') {
					this.growl.show({severity: 'error', summary: 'Operación fallida', detail: 'algo salió mal'});
				}
			});
			// var grouped = this.groupBy(this.state.selecteItem,'semana')
			// Object.keys(grouped).forEach(key => {
			// 	let result = grouped[key].map(a => a.id);				
			// 	window.open(urlPdf + "IMPORTACION_CONCEPTOS_NOMINA?semena="+key+"&embarque=" + result.join(','));
			// });
			window.open(urlPdf + "IMPORTACION_CONCEPTOS_NOMINA?&embarque=" + params.id);

		}
		else
		{
			this.growl.show({severity: 'error', summary: 'Operación fallida', detail: 'Por favor seleccione cualquiera'});
		}
	}
	handleRegresar = () => {
		if(this.state.selecteItem.length > 0){
			let id = map(this.state.selecteItem,'id')
			const params = {
				id : id.toString(),
				estatus:1
			}
			this.embarqueService.updateEstatus(params).then(res => {
				if (res && res.result == 'OK') {
					this.growl.show({ severity: 'success', summary: 'Operación completada', detail: 'Registros trasladados a Pre-Nómina con éxito' });
					this.setState({selecteItem:[]})
					this.buscar();
					this.unCheckAll();
				} else if (res && res.result == 'EXISTE') {
					this.growl.show({severity: 'error', summary: 'Operación fallida', detail: 'algo salió mal'});
				}
			});
		}
		else
		{
			this.growl.show({severity: 'error', summary: 'Operación fallida', detail: 'Por favor seleccione cualquiera'});
		}
	}

	getCommonDataFromChilds(data)
	{
		switch (data.label) {
			case "fecha_inicio":
				this.setState({search:{...this.state.search, from: moment(data.value).format('YYYY-MM-DD').toString()}});
				break;
			case "fecha_final":
				this.setState({search:{...this.state.search, to: moment(data.value).format('YYYY-MM-DD').toString()}});
				break;
		}
	}

	render() {
		return (
			<div class="container-fluid">
			<Panel header="Consulta De Embarques">
				<Toast ref={(el) => this.growl = el} />
					{this.permissionService.checkPermission('embarque_descargar') && <Row>
						<Col className="text-right mb-3"> <button onClick={this.exportClick} className="p-button p-button-warning">Descargar embarques</button> </Col>
					</Row>}
					<>
						<Accordion activeIndex={0} className="mb-3">
					    	<AccordionTab header="Filtrar">
								<Row>
									<Col md="6">
										<p>Rango de fechas:</p>
										<Row>
											<Col md="6">
												<div className="form-group">
													<label>Fecha de inicio:</label>
													<PCalendar
														label={"fecha_inicio"}
														getCommonDataFromChilds={this.getCommonDataFromChilds}
														showIcon
														value={this.state.search.from}
													/>
												</div>
											</Col>
											<Col md="6">
												<div className="form-group">
													<label>Fecha final:</label>
													<PCalendar
														label={"fecha_final"}
														getCommonDataFromChilds={this.getCommonDataFromChilds}
														showIcon
														value={this.state.search.to}
													/>
												</div>
											</Col>
										</Row>
									</Col>
								</Row>
								<Row>
									<Col md="6">
										<div className="form-group">
											<label>Número de embarque:</label>
											<input 
												type="text" 
												value={this.state.search.numero}
												onChange={e=>this.setState({search:{...this.state.search, numero: e.target.value}})} 
												className="form-control" 
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col md="6">
										<div className="form-group">
											<label className="d-block">Operadores:</label>
											<Dropdown
											className="w-full"
											options={this.state.operadores}
											editable={true}
											value={this.state.search.operador_id}
											onChange={(e) =>
												this.setState({search:{...this.state.search, operador_id: e.value} })
											}
											placeholder="Elegir operador..."/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col className="text-right">
										<Button type="button" onClick={() => this.handleEmbarquetable(this.state.estadoStatus)} className="p-button p-button-warning mr-2" >Buscar</Button>
										<Button type="button" onClick={this.reset_buscar} className="p-button p-button-warning" >Ver todo</Button>
									</Col>
								</Row>
							</AccordionTab>
						</Accordion>

						<Row>
							<Col className="text-right">
							{!this.state.activeIndex || this.state.activeIndex===0?<button onClick={this.handlePasarPre} className="p-button p-button-warning mb-2">Pasar a pre-nómina</button>:<></>}
								{this.state.activeIndex===1?
									<>
									<Button onClick={this.handleRegresar} className="p-button p-button-warning mb-2 mr-sm-2">Regresar a No Pagado</Button>
									<Button onClick={this.handlePasar} className="p-button p-button-warning mb-2">Pasar a nómina</Button>
									</>
									:<></>}
								{this.state.activeIndex===2?<></>:<></>}
							</Col>
						</Row>
						<div class="main_table">
							<Row className="col">
								<TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}>
									<TabPanel header="No pagado">
										<EmbarqueListado estado="1" {...this.state} handleEmbarquetable={this.handleEmbarquetable} parametros={this.state.search} history={this.props.history} handleCheckbox={this.handleCheckbox}/>
									</TabPanel>
									<TabPanel header="Pre-Nómina">
											<EmbarqueListado estado="2" {...this.state} handleEmbarquetable={this.handleEmbarquetable} parametros={this.state.search} history={this.props.history} handleCheckbox={this.handleCheckbox}/>
									</TabPanel>
									<TabPanel header="Nómina">
											<EmbarqueListado estado="3" {...this.state} handleEmbarquetable={this.handleEmbarquetable} parametros={this.state.search} history={this.props.history} handleCheckbox={this.handleCheckbox}/>
									</TabPanel>
								</TabView>
							</Row>
						</div>
					</>
			</Panel>
			</div>
		);
	}
}

export default ConsultaEmbarque;
