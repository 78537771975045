import React from "react";
import CatalogoMantenimientoLista from './catalogo/listado';
import { Link } from "react-router-dom"; 
import { Panel } from 'primereact/panel';
import CheckPermissions from "controllers/permissions/services";


class CatalogoMantenimiento extends React.Component {

	constructor(props){
		super(props)
		this.headerTemplate = this.headerTemplate.bind(this)
		this.permissionService = new CheckPermissions();
	}

	agregar(){
		this.props.history.push({
		 pathname:''
	 });
	}
	headerTemplate()
	{
		return (<div className="p-panel-header flex-wrap  d-flex">
			<div>
				Catálogo De Actividades De Mantenimento
			</div>
			<div>
			 {this.permissionService.checkPermission('mantenimiento_catalogo_agregar') && <Link to="/app/mantenimiento/catalogo/agregar" className="btn btn-primary">
			  	<i className="pi pi-plus mr-2 v-middle" /> Agregar actividad
			  </Link>}
			</div>
		</div>);
	}
	render() {
		return (
			<Panel headerTemplate={this.headerTemplate}>
				<CatalogoMantenimientoLista history={this.props.history}/>
			</Panel>
		);
	}
}

export default CatalogoMantenimiento;
