import React from "react";
import {
	Card,
	CardBody,Container, Row,Col,CardTitle

} from 'reactstrap';


import { Toast } from 'primereact/toast';
import {Button} from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import {Fieldset} from 'primereact/fieldset';
import VigilanciaService from  'controllers/vigilancia/vigilancia-service';
import {Checkbox} from 'primereact/checkbox';
import {Calendar} from 'primereact/calendar';
import moment from 'moment';
import OperadorServices from "controllers/operador/operador-service";
import UnidadService  from 'controllers/unidad/unidadService';
import EmbarqueService from 'controllers/embarque/embarque-service';
import Select from 'react-select';
import {urlDocuments} from '../../../../constants';
import SignaturePopup from './signature-popup';
import { Panel } from "primereact/panel";
import { indexOf } from "lodash";
import PCalendar from "views/ui-components/calendar";


const sideBarHeaders = [
	"Detalles básicos",
	"Exterior","Interior","Motor","Carrocería de Rabones, thorton o camioneta 3.5","Documentos para la circulación de la Unidad"
]


class InspeccionVigilancia extends React.Component {
	constructor(){
		super();
		this.state= {
			dialogEvents : { openDialog: () => {} },
			folio:"",
			checks:[],
			comentarios:[],
			check_foto:[],
			foto_id:[],
			aplica :[],
			fecha: new Date(),
			operador_id:'',
			unidad_id:'',
			submit_id:'',
			selectedSidebarHeader: "Detalles básicos",
			basic_form: true,
			exterior_form: false,
			interior_form:false,
			motor_form:false,
			carroceria_form: false,
			documentos_form: false,
			exterior_currentIndex: 0,
			interior_currentIndex: 0,
			motor_currentIndex:0,
			carroceria_currentIndex: 0,
			documentos_currentIndex: 0,
			isHideNextButton: false,
			isFinalSubmit:false,
			isBasicNextButton:true,
		}
		this.servicio = new VigilanciaService();
		this.servicioU = new UnidadService();
		this.servicioE = new EmbarqueService();
		this.servicioO = new OperadorServices();
		this.operadorArray = [];
		this.operadorUnidad = "";
		this.servicioU.getUnidades().then(data =>  {
			let arrUnidades = [];
			data.forEach(item=>{
				arrUnidades.push({value:item.id,label:item.eco});

			});
			this.setState({unidades: arrUnidades});
		});
	  
		this.servicioO.getOperadores().then((data) => {
			let arrOperadores = [];
			data.forEach((item) => {
			  arrOperadores.push({ value: item.usuario_id, label: item.nombre+ ' '+ item.apellido_paterno+ ' '+item.apellido_materno });
			  this.operadorArray.push({
				id: item.usuario_id,
				unidadId: item.unidad_id,
			  });
			});
			this.setState({ operadores: arrOperadores });
		});
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleSideBarClick = this.handleSideBarClick.bind(this);
		this.getCommonDataFromChilds = this.getCommonDataFromChilds.bind(this);
		
		this.servicioE.inspeccionFolio().then(data => {
			this.setState({ folio: data[0].folio })
		});
	}

	getCommonDataFromChilds(data)
	{
		switch (data.label) {
			case "fecha":
				this.setState({fecha: moment(data.value).format('YYYY-MM-DD').toString(),semana:moment(data.value).week()});
				break;
		}
	}

	deleteFileEntrega(i,j)
	{
		let foto_id = Object.assign({}, this.state.foto_id);
		foto_id[i].splice(j,1);
		this.setState({foto_id:foto_id})
	}

	uploadFileEntrega(event)
	{
		var re = /(\.jpg|\.jpeg|\.png)$/i;
		let that = this;
		if(re.exec(event.target.files[0].name)) {
			const data = new FormData();
			let id = event.target.getAttribute('data-id');
			// document.getElementById(`file-upload-text-${id}`).innerHTML = event.target.files[0].name
			data.append("folio",this.state.folio);
			data.append("file",event.target.files[0]);
			this.servicioE.uploadFile(data).then((res) => {
				if (res && res.result == "OK") {
					let foto_id = Object.assign({}, this.state.foto_id);
					try {
						foto_id[id].push(res.data.file_name)
					} catch (error) {
						foto_id[id] = [];
						foto_id[id].push(res.data.file_name)	
					}
					this.setState({foto_id:foto_id})
				}
			})
		}
		else
		{
			that.growl.show({severity: 'error', summary: 'Subida fallida', detail: 'El formato del archivo debe ser .jpg | .jpeg | .png'});
		}
	}

	
	handleSubmit(event) {
		event.preventDefault();
		let datos ={};
		let that = this;
		datos.folio = this.state.folio;
		datos.unidad_id = this.state.unidad_id;
		datos.operador_id = this.state.operador_id;
		datos.kilometraje = this.state.kilometraje;
		datos.personal = this.state.personal;
		datos.checks = this.state.checks;
		datos.aplica = this.state.aplica;
		datos.comentarios = this.state.comentarios;
		datos.foto_id = this.state.foto_id;
		datos.fecha = moment( this.state.fecha).format('YYYY-MM-DD').toString();
		this.servicioU = new UnidadService();

		this.servicio.guardarReporteEntrega(datos).then(res =>  {
			if(res && res.result == 'OK'){
				this.state.submit_id = res.data.id;
				this.state.dialogEvents.openDialog();
			}else if(res && res.result == 'EXISTE'){
				that.growl.show({severity: 'error', summary: 'El Id de ruta '+this.state.id_ruta+' ya se encuentra registrado', detail: 'Ingrese otro código o consulte su información para editarla.'});
			}
		});
	}

	handleUpdate(data) {
		let that = this;
		if(this.state.submit_id)
		{
			data.id = this.state.submit_id;
			this.servicio.updateReporteEntrega(data).then(res =>  {
				if(res && res.result == 'OK'){
					that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Información guardada correctamente'});
					setTimeout(function(){
								that.props.history.push('/app/mantenimiento/entrega-inspeccion');
						}, 500);
	
				}else if(res && res.result == 'EXISTE'){
					that.growl.show({severity: 'error', summary: 'El Id de ruta '+this.state.id_ruta+' ya se encuentra registrado', detail: 'Ingrese otro código o consulte su información para editarla.'});
				}
			});
		}
		else
		{
			this.growl.show({severity: 'error', summary: 'Error', detail: 'Something wrong'});
		}
	}

	mostrarFoto (i,image_count){
		if(this.state.check_foto[i]){
			return(
				<Col xs="12">
					<div className="form-group entrega-file-upload mt-2">
						<Row>
							{
								this.state.foto_id[i] && this.state.foto_id[i].map((item,j)=>
									<Col xs="4" key={`adding-${j}`} className="pl-0 mb-3">
										<div className="custom-file-upload">
											<img src={urlDocuments+item}></img>
											<label className="image-title">{item}</label>
											<span className="delete-icon" onClick={e => this.deleteFileEntrega(i,j)}>
												<i className="pi pi-trash"></i>
											</span>
										</div>
									</Col>
								)
							}
							{
								(!this.state.foto_id[i] || (this.state.foto_id[i] && this.state.foto_id[i].length<image_count)) && 							
								<Col xs="4" className="pl-0  mb-3">
									<div className="custom-file-upload addsection">
										<label for={`file-upload-`+i} className="custom-file-upload1 btn btn-info">
											<div className="d-flex flex-column align-items-center justify-content-center h-100">
												<i className="pi pi-cloud-upload"></i>
												<span>Seleccionar archivo</span>
											</div>
										</label>
										<input id={`file-upload-`+i} type="file" className="btn btn-default" data-id={i} style={{display:"none"}} onChange={e => this.uploadFileEntrega(e)} chooseLabel="Seleccionar Archivo"/>
									</div>
								</Col>
							}							
						</Row>
					</div>
				</Col>
			)
		}else{
			return (<div/>)

		}

	}


	vista(elemento,i)
	{
		let arreglo = Object.assign({}, this.state.checks);
		let arreglo1 = Object.assign({}, this.state.aplica);
		let arreglo2 = Object.assign({}, this.state.comentarios);
		let arreglo3 = Object.assign({}, this.state.check_foto);

		arreglo[i] = false

		const  objeto = (value) =>{
			arreglo[i] = value;
			return arreglo;
		};
		const  objeto2 = (value) =>{
			arreglo1[i] = !arreglo1[i];
			return arreglo1;
		};
		const  objeto3 = (value) =>{
			arreglo2[i] = value;
			return arreglo2;
		};

		const  objeto4 = (value) =>{
			arreglo3[i] = !arreglo3[i];
			return arreglo3;
		};		

		return (
			<Col xs="12">
				<div class="card">
					<h5 class="card-header">
						{elemento.label}
						<InputSwitch className="float-right" checked={this.state.checks[i]}  onChange={(e) => this.setState({ checks:objeto(e.value)})} />
					</h5>
					<div class="card-body p-3">
						<label htmlFor={"cb"+i} className="p-checkbox-label pointer mr-3">
							<Checkbox inputId={"cb"+i} value="true" onChange={e => this.setState({aplica:objeto2(e.value)})} checked={this.state.aplica[i]}></Checkbox>No aplica
						</label>
						<label htmlFor={"cb2"+i} className="p-checkbox-label pointer mr-3">
							<Checkbox inputId={"cb2"+i} value="true" onChange={e => this.setState({check_foto:objeto4()})} checked={this.state.check_foto[i]}></Checkbox>Foto
						</label>
						{this.mostrarFoto.bind(this)(i,elemento.image_count)}
						<div className="form-group">
							<input type="text"  value={this.state.comentarios[i]} onChange={e => this.setState({comentarios:objeto3( e.target.value)})} className="form-control" placeholder="Comentario"/>
						</div>
					</div>
				</div>
			</Col>
		);
	}

	handleSideBarClick(val) {
		this.setState({ selectedSidebarHeader: val });
		switch (val) {
			case "Detalles básicos":
				this.setState({exterior_form:false, interior_form:false, motor_form:false, carroceria_form:false, documentos_form:false, basic_form:true, isBasicNextButton:true, isFinalSubmit:false});
				break;
			case "Exterior":
				this.setState({basic_form: false, interior_form:false, motor_form:false, carroceria_form:false, documentos_form:false, exterior_form:true, isBasicNextButton:false, isFinalSubmit:false});
				break;
			case "Interior":
				this.setState({basic_form: false, exterior_form:false, motor_form:false, carroceria_form:false, documentos_form:false,interior_form:true,isBasicNextButton:false, isFinalSubmit:false});
				break;
			case "Motor":
				this.setState({basic_form: false, exterior_form:false, interior_form:false, carroceria_form:false, documentos_form:false,motor_form:true, isBasicNextButton:false, isFinalSubmit:false});
				break;
			case "Carrocería de Rabones, thorton o camioneta 3.5":
				this.setState({basic_form: false, exterior_form:false, interior_form:false, motor_form:false, documentos_form:false,carroceria_form:true, isBasicNextButton:false, isFinalSubmit:false});
				break;
			case "Documentos para la circulación de la Unidad":
				this.setState({basic_form: false, exterior_form:false, interior_form:false, motor_form:false, carroceria_form:false,documentos_form:true, isBasicNextButton:false, isFinalSubmit:false});
				break;
			default:
				this.setState({ basic_form: true });
				break;
		}
	}
	
   
	// Function to display the previous item
		previousItem = (e, currentIndex) => {
			e.preventDefault();
			this.setState(prevState => ({
				[currentIndex]: Math.max(prevState[currentIndex] - 1, 0)
			}));
			if(this.state.isHideNextButton){
				this.setState({isHideNextButton: false})
			}
			if(this.state.isFinalSubmit){
				this.setState({isFinalSubmit: false})
			}
		}

	// Function to display the next item
		nextItem = (e, listItemsLength, currentIndex, nextList, hideNextButton) => {
			e.preventDefault();
			if(this.state[currentIndex] + 1 === listItemsLength){
				if(hideNextButton){
					return this.setState({isHideNextButton: true, isFinalSubmit:true})
				}
                this.handleSideBarClick(nextList)
			}
			this.setState(prevState => ({
				[currentIndex]: Math.min(prevState[currentIndex] + 1, listItemsLength - 1)
			}));
		}


	listadoExterior(){
		const elementos = [
			{
				label:'Parabrisas y cristales de puertas',
				image_count:2
			},
			{
				label:'Espejos Retrovisores y de cofre',
				image_count:4
			},
			{
				label:'Faros delanteros, neblineros y direccionales',
				image_count:1
			},
			{
				label:'Luces traseras (reverseros, de stop y direccionales)',
				image_count:1
			},
			{
				label:'Alarma de reversa',
				image_count:1
			},
			{
				label:'Cofre y ganchos sujetadores',
				image_count:1
			},
			{
				label:'Parrilla de cofre',
				image_count:1
			},
			{
				label:'Antenas de radio y CB',
				image_count:3
			},
			{
				label:'Defensa delantera',
				image_count:1
			},
			{
				label:'Defensa trasera',
				image_count:1
			},
			{
				label:'Portaloderas y loderas traseras',
				image_count:2
			},
			{
				label:'Escape',
				image_count:1
			},
			{
				label:'Salpicaderas de cofre',
				image_count:2
			},
			{
				label:'Spoiler laterales',
				image_count:1
			},
			{
				label:'Guardabarros',
				image_count:2
			},
			{
				label:'Alerones laterales',
				image_count:2
			},
			{
				label:'Quinta Rueda',
				image_count:1
			},
			{
				label:'Bolsas de aire',
				image_count:2
			},
			{
				label:'Amortiguadores',
				image_count:2
			},
			{
				label:'Tanques de combustible y tapones',
				image_count:1
			},
			{
				label:'Tanque de Urea y tapón',
				image_count:1
			},
			{
				label:'Plumas de limpiaparabrisas',
				image_count:1
			},
			{
				label:'Puertas y chapas',
				image_count:2
			},
			{
				label:'Líneas de aire (fuga de mangueras)',
				image_count:1
			},
			{
				label:'Líneas de luces (7 vías)',
				image_count:1
			},
			{
				label:'Rines',
				image_count:6
			},
			{
				label:'Llantas',
				image_count:10
			}
    ];
    const listItems = elementos.map((elemento,i) =>
      this.vista(elemento,i)
   );

   // Ensure that the current index is within the bounds of the list
   const currentIndex = Math.min(this.state.exterior_currentIndex, listItems.length - 1);
   const currentItem = listItems[currentIndex];

   return (
		<div>
			<Col xs="12">
				<div className="form-group">
					<label>Selecciona un artículo</label>
					<Select
						options={elementos.map((elemento, index) => ({ label: elemento.label, value: index }))}
						onChange={(e)=>this.setState({exterior_currentIndex:e.value})}
						placeholder="Seleccione un artículo"
                    />
				</div>
			</Col>
			<Row className="pt-3">
				{currentItem}
			</Row>
			<div className="d-flex justify-content-between mt-3">
				{this.state.exterior_currentIndex ? <button className="btn btn-primary mr-2" onClick={(e) => this.previousItem(e, "exterior_currentIndex")}>Previa</button>: <div></div>}
				<button className="btn btn-primary" onClick={(e) => this.nextItem(e, listItems.length, "exterior_currentIndex", "Interior")}>Próxima</button>
			</div>
	   </div>
   )
	}
	listadoInterior(){
		const elementos = [
			{
				label:'Estéreo',
				image_count:1
			},
			{
				label:'Radio CB',
				image_count:1
			},
			{
				label:'Bocinas',
				image_count:1
			},
			{
				label:'Luces de navegación de cabina',
				image_count:1
			},
			{
				label:'Tablero (controles, palancas y botones)',
				image_count:1
			},
			{
				label:'Encendedor',
				image_count:1
			},
			{
				label:'Claxon',
				image_count:1
			},
			{
				label:'Tapetes',
				image_count:2
			},
			{
				label:'Gato Hidráulico',
				image_count:1
			},
			{
				label:'Extintor',
				image_count:2
			},
			{
				label:'Reflejantes',
				image_count:1
			},
			{
				label:'Viseras de parabrisas y ventanillas',
				image_count:1
			},
			{
				label:'Volante',
				image_count:1
			},
			{
				label:'Palanca de velocidades',
				image_count:1
			},
			{
				label:'Asientos',
				image_count:1
			},
			{
				label:'Vestiduras de puertas, toldo, camarote',
				image_count:1
			},
			{
				label:'Litera-dormitorio (colchones)',
				image_count:2
			},
			{
				label:'Puertas (manijas y controles eléctricos)',
				image_count:1
			},
			{
				label:'Cinturones de seguridad',
				image_count:2
			},
			{
				label:'Gavetas y compartimentos de camarote, cabina,y tablero',
				image_count:6
			}
    ];
    const listItems = elementos.map((elemento,i) =>
      this.vista(elemento,i+30)
   );

   const currentIndex = Math.min(this.state.interior_currentIndex, listItems.length - 1);
   const currentItem = listItems[currentIndex];

   return (
	<div>
		 <Col xs="12">
			<div className="form-group">
				<label>Selecciona un artículo</label>
				<Select
					options={elementos.map((elemento, index) => ({ label: elemento.label, value: index }))}
					onChange={(e)=>this.setState({interior_currentIndex:e.value})}
					placeholder="Seleccione un artículo"
                />
			</div>
		</Col>
		<Row className="pt-3">
			{currentItem}
		</Row>
		<div className="d-flex justify-content-between mt-3">
			{this.state.interior_currentIndex ? <button className="btn btn-primary mr-2" onClick={(e) => this.previousItem(e, "interior_currentIndex")}>Previa</button>: <div></div>}
			<button className="btn btn-primary" onClick={(e) => this.nextItem(e, listItems.length, "interior_currentIndex", "Motor")}>Próxima</button>
		</div>
   </div>
   )
	}
	listadoMotor(){
		const elementos = [
			{
				label:'Nivel de Aceite',
				image_count:1
			},
			{
				label:'Nivel de Refrigerante',
				image_count:1
			},
			{
				label:'Interior del cofre',
				image_count:1
			},
			{
				label:'Carcasas y tapas de faros',
				image_count:1
			},
			{
				label:'Fugas o escurrimientos visibles de aceite, diésel, anticongelante',
				image_count:1
			},
			{
				label:'Guardabarros de cofre y salpicaderas',
				image_count:1
			},
			{
				label:'Tapones de radiador y depósitos de aceite, anticongelante y otros',
				image_count:1
			}
    ];
      const listItems = elementos.map((elemento,i) =>
      this.vista(elemento,i+60)
     );

      const currentIndex = Math.min(this.state.motor_currentIndex, listItems.length - 1);
      const currentItem = listItems[currentIndex];

   return (
	<div>
		<Col xs="12">
			<div className="form-group">
				<label>Selecciona un artículo</label>
				<Select
					options={elementos.map((elemento, index) => ({ label: elemento.label, value: index }))}
					onChange={(e)=>this.setState({motor_currentIndex:e.value})}
					placeholder="Seleccione un artículo"
                />
			</div>
		</Col>
		<Row className="pt-3">
			{currentItem}
		</Row>
		<div className="d-flex justify-content-between mt-3">
			{this.state.motor_currentIndex ? <button className="btn btn-primary mr-2" onClick={(e)=> this.previousItem(e, "motor_currentIndex")}>Previa</button>: <div></div>}
			<button className="btn btn-primary" onClick={(e) => this.nextItem(e, listItems.length, "motor_currentIndex", "Carrocería de Rabones, thorton o camioneta 3.5")}>Próxima</button>
		</div>
    </div>
   )
	}
	listadoCarroceria(){
		const elementos = [
			{
				label:'Puertas y herrajes',
				image_count:2
			},
			{
				label:'Paredes eternas (laterales, esquineros)',
				image_count:1
			},
			{
				label:'Bordas (inferior y superior)',
				image_count:1
			},
			{
				label:'Plafón trasero (tacones, estribo)',
				image_count:1
			},
			{
				label:'Luces',
				image_count:1
			},
			{
				label:'Piso interior',
				image_count:1
			},
			{
				label:'Toldo del interior',
				image_count:1
			},
			{
				label:'Zoclo del interior',
				image_count:1
			},
			{
				label:'Paredes interiores (postes logísticos y recubrimientos de lámina o madera)',
				image_count:1
			}
    ];
      const listItems = elementos.map((elemento,i) =>
      this.vista(elemento,i+90)
   );
      const currentIndex = Math.min(this.state.carroceria_currentIndex, listItems.length - 1);
      const currentItem = listItems[currentIndex];
   return (
	<div>
		<Col xs="12">
			<div className="form-group">
				<label>Selecciona un artículo</label>
				<Select
					options={elementos.map((elemento, index) => ({ label: elemento.label, value: index }))}
					onChange={(e)=>this.setState({carroceria_currentIndex:e.value})}
					placeholder="Seleccione un artículo"
                />
			</div>
		</Col>
		<Row className="pt-3">
			{currentItem}
		</Row>
		<div className="d-flex justify-content-between mt-3">
			{this.state.carroceria_currentIndex ? <button className="btn btn-primary mr-2" onClick={(e) => this.previousItem(e, "carroceria_currentIndex")}>Previa</button>: <div></div>}
			<button className="btn btn-primary" onClick={(e) => this.nextItem(e, listItems.length, "carroceria_currentIndex","Documentos para la circulación de la Unidad")}>Próxima</button>
		</div>
   </div>
   )
	}
	listadoDocumentos(){
		const elementos = [
			{
				label:'Póliza de Seguro',
				image_count:1
			},
			{
				label:'Tarjeta de Circulación',
				image_count:1
			},
			{
				label:'Placas y Engomado',
				image_count:1
			},
			{
				label:'Verificación Físico-mecánica (Boleta y Engomado)',
				image_count:1
			},
			{
				label:'Verificación Ecológica (Boleta y Engomado)',
				image_count:1
			},
			{
				label:'Calcomanía PASE (para peajes)',
				image_count:1
			}
      ];
		const listItems = elementos.map((elemento,i) =>
		this.vista(elemento,i+120)
		);
		const currentIndex = Math.min(this.state.documentos_currentIndex, listItems.length - 1);
		const currentItem = listItems[currentIndex];
   return (
	  <div>
			<Col xs="12">
			<div className="form-group">
				<label>Selecciona un artículo</label>
				<Select
					options={elementos.map((elemento, index) => ({ label: elemento.label, value: index }))}
					onChange={(e)=>this.setState({documentos_currentIndex:e.value})}
					placeholder="Seleccione un artículo"
                />
			</div>
		   </Col>
			<Row className="pt-3">
				{currentItem}
			</Row>
			<div className="d-flex justify-content-between mt-3">
				{this.state.documentos_currentIndex ? <button className="btn btn-primary mr-2" onClick={(e) => this.previousItem(e, "documentos_currentIndex")}>Previa</button>: <div></div>}
				{!this.state.isHideNextButton ? <button className="btn btn-primary" onClick={(e) => this.nextItem(e, listItems.length, "documentos_currentIndex", null, "hide-next-button")}>Próxima</button>: <div></div>}
			</div>
     </div>
   )
	}
	 

	render() {
		const es = {
				firstDayOfWeek: 1,
				dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
				dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
				dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
				monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
				monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"]
		};

		return (
			<div className="container">
				<Panel header="REPORTE DE ENTREGA-INSPECCIÓN UNIDAD">
				     <Toast ref={(el) => this.growl = el} />
							<Row>
							 <Col xs="4" className="d-none d-md-block">
								<Card>
									{sideBarHeaders.map((val)=>(
									<div key={val} 
									className={`p-3 ${val === this.state.selectedSidebarHeader ? 'text-primary font-bold' : ''}`}
									role="button" style={{cursor: "pointer"}} onClick={() => this.handleSideBarClick(val)}>{val}</div>
									))}
								</Card>
							</Col>
							<Col xs="12" className="d-block d-md-none pb-3">
								<div className="form-group">
									<label>Seleccione un encabezado</label>
									<Select
										options={sideBarHeaders.map((header)=>({label:header, value:header}))}
										onChange={(e) => this.handleSideBarClick(e.value)}
										placeholder="Seleccione un encabezado"
									/>
								</div>
		   					</Col>
							<Col xs="12" md="8">
								<form >
										{ this.state.basic_form && <div>
										<Row>
											<Col xs="12">
													<div className="form-group">
														<label>Folio</label><br/>
															<input type="text"   placeholder="Sin asignar"  readOnly value={this.state.folio} onChange={e => this.setState({folio : e.target.value})} className="form-control"/>
													</div>
											</Col>
											<Col xs="12">
													<div className="form-group">
														<label>Fecha</label><br/>
														<PCalendar
															label={"fecha"}
															getCommonDataFromChilds={this.getCommonDataFromChilds}
															showIcon
															value={this.state.fecha}
														/>
													</div>
											</Col>
										</Row>
										<Row>
											<Col xs="12">
												<div className="form-group">
												<label>Operador</label>
												<Select
													options={this.state.operadores}
													onChange={(e) =>
													this.setState({ operador_id: e.value }, () => {
														this.operadorUnidad = this.operadorArray.filter(
														(option) => option.id == this.state.operador_id
														);
														let uni = this.state.unidades.filter(
														(option) =>
															option.value == this.operadorUnidad[0].unidadId
														);
														this.setState({ unidad_id: uni });
													})
													}
													placeholder="Elegir operador"
												/>
												</div>
											</Col>
											<Col xs="12">
												<div className="form-group">
												<label>Unidad</label>
												<Select
													components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
													options={this.state.unidades}
													value={this.state.unidad_id}
													onChange={(e) => this.setState({ unidad_id: e.value })}
													placeholder=""
													isDisabled={true}
												/>
												</div>
											</Col>
										</Row>
										<Row>
											<Col xs="12">
													<div className="form-group">
														<label>Kilometraje</label><br/>
															<input type="text"  value={this.state.kilometraje} onChange={e => this.setState({kilometraje : e.target.value})} className="form-control"/>
													</div>

											</Col>
											<Col xs="12">
													<div className="form-group">
														<label>Personal que entrega unidad</label><br/>
															<input type="text"  value={this.state.personal} onChange={e => this.setState({personal : e.target.value})} className="form-control"/>
													</div>
											</Col>
										</Row>
										</div> }
										{this.state.exterior_form && 
											this.listadoExterior.bind(this)()
										}
										{this.state.interior_form && 
											this.listadoInterior.bind(this)()}
										
										{this.state.motor_form && 
											this.listadoMotor.bind(this)()}
										
										{this.state.carroceria_form && 
											this.listadoCarroceria.bind(this)()}
									
										{this.state.documentos_form && 
											this.listadoDocumentos.bind(this)()}

									{this.state.isBasicNextButton && <div className="d-flex justify-content-between pt-3">
										<div></div>
										<button className="btn btn-primary" onClick={() => this.handleSideBarClick('Exterior')}>Próxima</button>
									</div>}
									{this.state.isFinalSubmit && <center> <button className="btn btn-primary" onClick={this.handleSubmit} >Guardar</button></center>}
								</form>
						    </Col>
						   </Row>
					<SignaturePopup handleUpdate={(data)=>this.handleUpdate(data)} events={this.state.dialogEvents} />
				</Panel>
			</div>
		);
	}

}

export default InspeccionVigilancia;
