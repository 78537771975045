import React from "react";
import {
	Card,
	CardBody,Col, Row, Container,CardTitle


} from 'reactstrap';
import UnidadesListado from './unidades-views';
import {Button} from 'primereact/button';


class ResultadoUnidades extends React.Component {
	constructor(props){
		super(props);
	}

	render() {

		return (
			<Card>
				<CardTitle  className="bg-light border-bottom p-3 mb-0">CONSULTA DE UNIDADES</CardTitle>
				<CardBody>


					<UnidadesListado parametros={this.props.location.state} history={this.props.history} />

				</CardBody>
			</Card>
		);
	}
}

export default ResultadoUnidades;
