	import axios from 'axios';
import {url} from '../../constants';

export default class Service {

			/**
			Servicios para  los reportes
			*/

			guardarConcepto(contenido){
				console.log(contenido)
					return axios.post(url + 'nomina-concepto/guardar',{reporte:contenido },  {
						}).then(res => res.data);
			}

			listadoConcepto(){
				return axios.get(url + 'nomina-concepto/listado',{})
								.then(res => res.data);
			}

			eliminarConcepto(id){
					return axios.post(url + 'nomina-concepto/eliminar',{
						id:id

					}).then(res => res.data);
			}


			actualizarConcepto(objeto){

			  let objeto_nuevo =Object.assign({}, objeto);

			//	delete embarque.facturar_a;

					return axios.post(url + 'nomina-concepto/actualizar',{
						contenido:objeto_nuevo

					}).then(res => res.data);
			}



}
