import React, { Component } from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {MultiSelect} from 'primereact/multiselect';
import OperadorServices  from 'controllers/operador/operador-service';
import {Button} from 'primereact/button';
import EditarOperador from './editar';
import {Dialog} from 'primereact/dialog';
import { Toast } from 'primereact/toast';

export default class NominaListado extends Component {

    constructor() {
        super();
        this.state = {
            globalFilter: null,
            visible:false,
            visible2:false
        };
        this.servicioOperador = new OperadorServices();
    }

    componentDidMount() {
      if(this.props.parametros){
        this.servicioOperador.getFiltroOperadores(this.props.parametros).then(data => this.setState({cars: data}));
      }else{
        this.servicioOperador.getOperadores().then(data => this.setState({cars: data}));
      }
    }

    editar(column){
      this.setState({visible:true,item:column.rowData});
    }

    actionTemplate(rowData, column) {
      return (<div>
        <Button
          type="button" icon="pi pi-pencil" onClick={e=> this.editar(column)}
          className=""/>
      </div>);
    }

    render() {
        return (<div>
            <Toast ref={(el) => this.growl = el} />
            <Dialog header="Editar"  visible={this.state.visible} style={{width: '50vw',height:'90vh',overflow:'scroll'}} modal={true} onHide={() => this.setState({visible: false,visible2:false	})}>
              <EditarOperador values={this.state.item}/>
            </Dialog>
            <DataTable value={this.state.cars} paginator={true} rows={10} >
            <Column field="numero_empleado" header="Número de empleado"  />
            <Column field="nombre" header="Nombre"  />
            <Column field="domicilio_actual" header="Comisiones"   />
            <Column field="domicilio_actual" header="Gastos comprobados"   />
            <Column field="domicilio_actual" header="Adelantos"   />
            <Column field="domicilio_actual" header="Total"   />
            <Column field="domicilio_actual" header="Gastos sin comprobar"   />
            </DataTable>

      </div>);
    }
}

/*<Column body={this.actionTemplate.bind(this)} header="Opciones" style={{ textAlign: 'center'}} />*/
