import axios from 'axios';
import { url } from '../../constants';

export default class Service {

    guardarHuella(data) {
        console.log("Service -> guardarHuella -> data", data)
        return axios.post(url + 'huella-sig/guardar', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => res.data);
    }

    listHuella(){
        return axios.get(url + 'huella-sig/listhuella',{})
                        .then(res => res.data);
    }

    eliminarHeulla(id){
        return axios.post(url + 'huella-sig/eliminarheulla',{id})
        .then(res => res.data);
    }

}