import axios from "axios";
import { url } from "../../constants";

export default class Service {
  getClientes() {
    return axios.get(url + "clientes").then((res) => res.data);
  }

  getClientesEstado(estado) {
    return axios
      .get(url + "clientes/estado", { params: { estado: estado } })
      .then((res) => res.data);
  }

  getFiltroClientes(obj) {
    return axios
      .get(url + "clientes/filtro", { params: obj })
      .then((res) => res.data);
  }

  guardarCliente(cliente) {
    return axios
      .post(url + "clientes/guardar", cliente, {})
      .then((res) => res.data);
  }

  eliminarCliente(cliente_id) {
    return axios
      .post(url + "clientes/eliminar", {
        cliente_id: cliente_id,
      })
      .then((res) => res.data);
  }

  actualizarCliente(cliente) {
    return axios
      .post(url + "clientes/actualizar", {
        cliente: cliente,
      })
      .then((res) => res.data);
  }
}
