import React from "react";
import { Button } from 'primereact/button';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Panel } from 'primereact/panel';
import AdminUserService from "controllers/admin-user/service";
import { Link } from "react-router-dom";
import CheckPermissions from "controllers/permissions/services";


class AdminUser extends React.Component {
  constructor() {
    super();
    this.state = {
      users: [] // Initialize users state as an empty array
    };
    this.servicioClientes = new AdminUserService();
    this.permissionService = new CheckPermissions();
    this.headerTemplate = this.headerTemplate.bind(this);

  }

  componentDidMount() {
    this.fetchAdminUsers(); // Fetch admin users data when component mounts
  }

  // Function to fetch admin users data
  fetchAdminUsers() {
    this.servicioClientes.getAdminUsersAll().then((data) => {
      this.setState({ users: data });
    });
  }

  // Function to handle edit click
  handleEditClick = (rowData) => {
    this.props.history.push({
      pathname: "/app/usuario-administrador/editar",
      state: rowData
    });
  };

  // Function to handle delete click
  handleDeleteClick = (rowData) => {
    this.servicioClientes.deleteAdminUser(rowData.id).then((val) => {
      if (val) this.fetchAdminUsers();
    });
  };

  // Function to handle add user click
  handleAddUserClick = () => {
    this.props.history.push({
      pathname: "/app/usuario-administrador/crear"
    });
  };

  headerTemplate()
	{
		return (<div className="p-panel-header flex-wrap  d-flex">
			<div>
         Usuarios administradores
			</div>
			<div>
			{this.permissionService.checkPermission('usuario-administrador_crear') &&<Link to="/app/usuario-administrador/crear" className="btn btn-primary">
				<i className="pi pi-plus mr-2 v-middle" /> Agregar administrador
			</Link>}
			</div>
		</div>);
	}

  render() {

    return (
      <div class="container-fluid">
        <Panel headerTemplate={this.headerTemplate}>
              <DataTable value={this.state.users} paginator rows={10} 
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                <Column field="nombre" header="Nombre" />
                <Column field="nombre_de_usuario" header="Nombre De Usuario" />
                <Column field="correo_electronico" header="Correo Electronico" />
                <Column field="contrasena" header="Contrasena" />
                <Column body={this.actionTemplate} header="Opciones" style={{ textAlign: "center" }} />
              </DataTable>
        </Panel>
      </div>
    );
  }

  actionTemplate = (rowData) => {
    return (
      <div>
      {this.permissionService.checkPermission('usuario-administrador_editar') && <Button type="button" icon="pi pi-pencil" onClick={() => this.handleEditClick(rowData)} className="p-button-warning" />}
      {this.permissionService.checkPermission('usuario-administrador_borrar') &&<Button type="button" icon="pi pi-trash" onClick={() => this.handleDeleteClick(rowData)} className="p-button-warning" />}
     </div>
    );
  };
}

export default AdminUser;


