import React,{Component} from "react";
import {
	Card,
	CardBody,CardTitle

} from 'reactstrap';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';

import CarService from 'controllers/demo/carservice';
import 'primereact/resources/themes/nova/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import ClienteListado from './clienteViews';

class ResultadoClientes extends Component {
	constructor(props){
		super(props);
	}

	render() {
		return (
			<Card>
			<CardTitle  className="bg-light border-bottom p-3 mb-0">CONSULTAR CLIENTE</CardTitle>
				<CardBody>

				<ClienteListado parametros={this.props.location.state}  history={this.props.history}/>
				</CardBody>
			</Card>
		);
	}
}

export default ResultadoClientes;
