	import axios from 'axios';
import {url} from '../../constants';

export default class Service {

			/**
			Servicios para  los reportes
			*/

			guardarCategoria(contenido){
				console.log(contenido)
					return axios.post(url + 'nomina-categoria/guardar',{reporte:contenido },  {
						}).then(res => res.data);
			}

			listadoCategoria(){
				return axios.get(url + 'nomina-categoria/listado',{})
								.then(res => res.data);
			}

			eliminarCategoria(id){
					return axios.post(url + 'nomina-categoria/eliminar',{
						id:id

					}).then(res => res.data);
			}


		actualizarCategoria(objeto){

		  let objeto_nuevo =Object.assign({}, objeto);

		//	delete embarque.facturar_a;

				return axios.post(url + 'nomina-categoria/actualizar',{
					contenido:objeto_nuevo

				}).then(res => res.data);
		}

}
