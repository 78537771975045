	import axios from 'axios';
import {url,urlPdf} from '../../constants';

export default class Service {

			/**
			Servicios para  los calculos
			*/

			guardarCalculo(contenido){
				console.log(contenido)
					return axios.post(url + 'nomina-calculo/guardar',{reporte:contenido },  {
						}).then(res => res.data);
			}

			listadoCalculo(week_number){
				return axios.get(url + 'nomina-calculo/listados',{params:{week_number}})
								.then(res => res.data);
			}

			eliminarCalculo(id){
					return axios.post(url + 'nomina-calculo/eliminar',{
						id:id

					}).then(res => res.data);
			}

			cargarArchivo(archivo){
					return axios.post(urlPdf + 'IMPORTACION_CONCEPTOS_NOMINA/cargar-nomina.php',archivo,{}).then(res => res.data);
			}



}
