import React from "react";
import {
	Card,
	CardBody,Col,Row,Container,CardTitle

} from 'reactstrap';
import {Button} from 'primereact/button';
import LlantasListado from "./lista-corta";

import Select from 'react-select';

import OperadorServices  from 'controllers/operador/operador-service';
import xlsx from "json-as-xlsx"
import { Panel } from 'primereact/panel';
import { Accordion, AccordionTab } from 'primereact/accordion';
import CheckPermissions from "controllers/permissions/services";
import LlantaService  from 'controllers/llanta/llantaService';


const tipo_unidades = [
	{value:"na",label:"Sin asignar"},
	{value:"Tractocamión",label:"Tractocamión"},
	{value:"Remolque 53 pies",label:"Remolque 53 pies"},
    {value:"Remolque 48 pies",label:"Remolque 48 pies"},
    {value:"Camión Rabon",label:"Camión Rabon"},
    {value:"Camión Torton",label:"Camión Torton"},
    {value:"Camión 3.5 ton",label:"Camión 3.5 ton"},
    {value:"Pick up",label:"Pick up"},
    {value:"Vehículo utilitario",label:"Vehículo utilitario"},
    {value:"Motocicleta",label:"Motocicleta"},
    {value:"Dolly",label:"Dolly"}
]


class ConsultaLlantas extends React.Component {
	constructor(props){
		super(props);
		this.buscar  = this.buscar.bind(this);
		this.verTodo = this.verTodo.bind(this);
		this.handleLlantasUpdate = this.handleLlantasUpdate.bind(this);

		this.state = {
			folio_llanta:"",
        };

		this.servicioO = new OperadorServices();
		this.permissionService = new CheckPermissions();
		this.servicioLLanta = new LlantaService();
	}

	componentDidMount() {
		this.handleLlantasUpdate();
    }


	buscar(){
		// 	this.props.history.push({
		// 	 pathname:'/app/unidades/llantas/resultado',
		// 	 state: this.state
		//  });

	   if(this.state.folio_llanta){
		this.handleLlantasUpdate({folio_llanta: this.state.folio_llanta});
	   }
	}

	verTodo(){
		this.setState({folio_llanta:""});
		this.handleLlantasUpdate();
	}

	handleLlantasUpdate(params){
		this.servicioLLanta.getFiltroLlantas(params).then(data => this.setState({llantas: data}));
	}

	exportClick = () => {
		this.servicioO.getReports(4, 'llantas').then((response) => {
			let settings = {
				fileName: response.data.filename,
			  }
			xlsx(response.data.sheet, settings)
		})
	}


	render() {
		return (
			<div class="container-fluid">
			<Panel header="Consultar Llantas">
				{this.permissionService.checkPermission('llanta_descarga') && <Row>
					<Col className="text-right"> 
						<Button onClick={this.exportClick} className="p-button p-button-warning mb-3">Descarga inventario de llantas</Button> 
					</Col>
				</Row>}
				<Accordion activeIndex={0} className="mb-3">
					<AccordionTab header="Filtrar">
						<Row>
							<Col xs="12" md="6">
								<div className="form-group">
									<label>Folio EXNAC de llanta...</label>
									<input type="text" value={this.state.folio_llanta} onChange={e=>this.setState({folio_llanta:e.target.value})} className="form-control"/>
								</div>
							</Col>
							{/* <Col xs="12" md="6">
								<div className="form-group">
								<label> Consulta por Tipo de Unidad:</label>
									<Select options={tipo_unidades} onChange={e=>this.setState({tipo_unidad:e.value})} placeholder="Elegir tipo de unidad" />
								</div>
							</Col> */}
						</Row>
						<Row className="mb-4">
							<Col className="text-right">
								<Button label="Buscar" onClick={this.buscar} className="p-button-raised p-button-warning mr-3" />
								<Button label="Ver todo" onClick={this.verTodo} className="p-button-raised p-button-warning" />
							</Col>
						</Row>
					</AccordionTab>
				</Accordion>
				<Row>
					<LlantasListado {...this.state} history={this.props.history} handleLlantasUpdate={this.handleLlantasUpdate}/>
				</Row>
			</Panel>
			</div>
		);
	}
}

export default ConsultaLlantas;
