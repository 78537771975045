import React from "react";
import { Card, CardBody, Container, Col, Row, CardTitle } from "reactstrap";
import PCalendar from '../../ui-components/calendar';
import PTimer from '../../ui-components/timer';
import Select from "react-select";
import { urlPdf } from "../../../constants";
import ClienteService from "controllers/cliente/clienteservice";
import OperadorServices from "controllers/operador/operador-service";
import UnidadService from "controllers/unidad/unidadService";
import EmbarqueService from "controllers/embarque/embarque-service";
import CartaPorteService from "controllers/carta-porte/carta-porte-service.js";
import RemolqueServices from "controllers/remolque/remolqueservice";

import RutaService from "controllers/ruta/rutaservice";
import { Toast } from "primereact/toast";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { Panel } from 'primereact/panel';

const options = [
  { value: "1", label: "No pagado" },
  { value: "2", label: "Pre-Nómina" },
  { value: "3", label: "Nómina" },
];

const options2 = [
  { value: "1", label: "Falta de evidencias" },
  { value: "2", label: "Tender cancelado" },
  { value: "3", label: "Costo no autorizado" },
  { value: "4", label: "Tender pendiente" },
  { value: "5", label: "Otro" },
];

const tipoServicio = [
  { label: "OW", value: "OW" },
  { label: "RT", value: "RT" },
  { label: "Patio", value: "Patio" },
];

const tipoCategoria = [
  { label: "Transporte", value: "Transporte" },
  { label: "Renta", value: "Renta" },
];

class NuevoEmbarque extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fecha_embarque: "",
      fecha_liquidacion: "",
      fecha_cobro: "",
      hora_ventana: "",
      ch_drop: true,
      ch_origen: false,
      ch_destino: true,
      ch_stop1: true,
      ch_stop2: true,
      ch_stop3: true,
      ch_stop4: true,
      ch_stop5: true,
      ch_stop6: true,
      estatus: "1",
      unidad_id: [],
      stopsList: [1],
      ciudad_origen:'',
      comentarios_de_ruta:''
    };

    this.servicio = new ClienteService();
    this.servicioU = new UnidadService();
    this.servicioO = new OperadorServices();
    this.servicioE = new EmbarqueService();
    this.servicioR = new RemolqueServices();
    this.servicioRuta = new RutaService();
    this.servicioT = new CartaPorteService();

    this.operadorArray = [];
    this.operadorUnidad = "";

    this.servicioT.catalogo().then((data) => {
      let arrCartas = [];

      console.log("data->carta porte=======>", data);
      data.forEach((item) => {
        arrCartas.push({ value: item.id, label: item.folio });
      });
      this.setState({ catalogo_cartas: arrCartas });
    });

    this.servicio.getClientes().then((data) => {
      let arrClientes = [];
      data.forEach((item) => {
        if (item.estatus !== 0) {
          arrClientes.push({
            value: item.id,
            label: item.razon_social,
            rfc: item.rfc,
          });
        }
      });
      this.setState({ clientes: arrClientes });
    });

    this.servicioU.getUnidades().then((data) => {
      console.log("from embarques nuevo-embarque getUnidades", data);
      let arrUnidades = [];
      data.forEach((item) => {
        arrUnidades.push({ value: item.id, label: item.eco });
      });
      this.setState({ unidades: arrUnidades });
    });

    this.servicioO.getOperadores().then((data) => {
      console.log("from embarques nuevo-embarque getOperadores", data);
      let arrOperadores = [];
      data.forEach((item) => {
        // console.log("item", item)
        arrOperadores.push({ value: item.usuario_id, label: item.nombre+ ' '+ item.apellido_paterno+ ' '+item.apellido_materno });
        this.operadorArray.push({
          id: item.usuario_id,
          unidadId: item.unidad_id,
        });
      });
      this.setState({ operadores: arrOperadores });
    });
    this.handleSubmit = this.handleSubmit.bind(this);
    this.imprimirCartaPorte = this.imprimirCartaPorte.bind(this);

    this.servicioE.obtenerFolio().then((data) => {
      console.log("dataa========>", data);
      // let f=[]
      // data.forEach(i=>{
      // 	console.log('i',i)
      // 	f.push({folio:i.folio})
      // })
      this.setState({ folio: data[0].folio });
    });
    // TODO
    //
    this.servicioR.getRemolques().then((data) => {
      // console.log("dadadada=>>>",data)
      // debugger
      let arrRemolques = [];
      data.forEach((item) => {
        arrRemolques.push({ value: item.id, label: item.placas });
      });
      this.setState({ remolques: arrRemolques });
    });

    this.seleccionar_cliente = this.seleccionar_cliente.bind(this);
    this.seleccionar_ruta = this.seleccionar_ruta.bind(this);
    this.seleccionar_version = this.seleccionar_version.bind(this);
    this.getCommonDataFromChilds = this.getCommonDataFromChilds.bind(this);

  }
  seleccionar_cliente(event) {
    this.setState({ rutas: [], ruta_id: "" });

    this.setState({ facturar_a: event.label, cliente_id: event.value });
    this.servicioRuta.getRutas(event.value).then((data) => {
      let arrRutas = [];
      data.forEach((item) => {
        if (item.estatus != 0) {
          arrRutas.push({ value: item.id, label: item.id_ruta });
        }
      });
      this.setState({ rutas: arrRutas });
    });
  }

  seleccionar_ruta(event) {
    this.setState({ versiones: [], ruta_id: "" });
    this.setState({ ruta_id: event.value });
    // console.log(event.value);
    this.servicioRuta.getVersiones(event.value).then((data) => {
      let arrVersiones = [];
      data.forEach((item) => {
        arrVersiones.push({ value: item.id, label: item.version, obj: item });
      });
      this.setState({ versiones: arrVersiones });
    });
  }

  seleccionar_version(event) {
    console.log("embarques nuevo seleccionar_version event", event);
    let arrStops = [];
    // let newStopsArr = [];
    if (event.obj.direccion_origen) {
      arrStops[0] = {
        label: event.obj.direccion_origen,
        value: event.obj.direccion_origen,
      };
      this.setState({ planta_origen: event.obj.direccion_origen });
    }
    if (event.obj.direccion_final) {
      arrStops[11] = {
        label: event.obj.direccion_final,
        value: event.obj.direccion_final,
      };
      this.setState({ destino_final: event.obj.direccion_final });
    }
    if (event.obj.stop1)
    {
      const parseStop1 = JSON.parse(event.obj.stop1)
      if(parseStop1.localidad)
      {
        arrStops[1] = { label: event.obj.stop1, value: event.obj.stop1 };
      }
    }
    if (event.obj.stop2)
    {
      const parseStop2 = JSON.parse(event.obj.stop2)
      if(parseStop2.localidad)
      {
        arrStops[2] = { label: event.obj.stop2, value: event.obj.stop2 };
      }
    }
    if (event.obj.stop3)
    {
      const parseStop3 = JSON.parse(event.obj.stop3)
      if(parseStop3.localidad)
      {
        arrStops[3] = { label: event.obj.stop3, value: event.obj.stop3 };
      }
    }
    if (event.obj.stop4)
    {
      const parseStop4 = JSON.parse(event.obj.stop4)
      if(parseStop4.localidad)
      {
        arrStops[4] = { label: event.obj.stop4, value: event.obj.stop4 };
      }
    }
    if (event.obj.stop5)
    {
      const parseStop5 = JSON.parse(event.obj.stop5)
      if(parseStop5.localidad)
      {
        arrStops[5] = { label: event.obj.stop5, value: event.obj.stop5 };
      }
    }
    if (event.obj.stop6)
    {
      const parseStop6 = JSON.parse(event.obj.stop6)
      if(parseStop6.localidad)
      {
        arrStops[6] = { label: event.obj.stop6, value: event.obj.stop6 };
      }
    }
    if (event.obj.stop7)
    {
      const parseStop7 = JSON.parse(event.obj.stop7)
      if(parseStop7.localidad)
      {
        arrStops[7] = { label: event.obj.stop7, value: event.obj.stop7 };
      }
    }
    if (event.obj.stop8)
    {
      const parseStop8 = JSON.parse(event.obj.stop8)
      if(parseStop8.localidad)
      {
        arrStops[8] = { label: event.obj.stop8, value: event.obj.stop8 };
      }
    }
    if (event.obj.stop9)
    {
      const parseStop9 = JSON.parse(event.obj.stop9)
      if(parseStop9.localidad)
      {
        arrStops[9] = { label: event.obj.stop9, value: event.obj.stop9 };
      }
    }
    if (event.obj.stop10)
    {
      const parseStop0 = JSON.parse(event.obj.stop10)
      if(parseStop0.localidad)
      {
        arrStops[10] = { label: event.obj.stop10, value: event.obj.stop10 };
      }
    }

    this.setState({
      stops: arrStops.slice(1, 11),
      version_id: event.value,
      version_rutaObj: event.obj,
      _destinatarios: arrStops,
      _remitentes: arrStops,
    });
    console.log("from embarques nuevo-embarque seleccionar_version", arrStops);
  }

  imprimirCartaPorte() {
    window.open(
      urlPdf + "carta_porte.php?folio=" + this.state.folio,
      "width=200,height=100"
    );
    //this.setState({visible:false});
    this.props.history.push("/app/embarques/consulta");
  }

  seleccionar_remitente(e) {
    console.log("seleccionar_remitente event(data):", e);
    let opciones = this.state.stops.filter(
      (option) => option.label !== e.value
    );
    this.setState({
      _destinatarios: opciones, 
      planta_origen: e.value,
    });
  }

  seleccionar_destinatario(e) {
    let opciones = this.state.stops.filter(
      (option) => option.label !== e.value
    );
    this.setState({
      // _remitentes: opciones,
      destino_final: e.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    // console.log(this.state);
    let that = this;
    const data = new FormData();

    if (this.state.ch_destino_carta_porte_file) {
      data.append(
        "ch_destino_carta_porte_file",
        this.state.ch_destino_carta_porte_file
      );
      this.setState({
        ch_destino_carta_porte_file:
          this.state.ch_destino_carta_porte_file.name,
      });
    }
    for (let i = 1; i <= 6; i++) {
      let field_name = `ch_stop${i}_carta_porte_file`;
      if (this.state[field_name]) {
        data.append(`${field_name}`, this.state[field_name]);
        this.setState({ [`${field_name}`]: this.state[field_name].name });
      }
    }

    let remitentes = {};

    for (let i = 1; i < 7; i++) {
      if (!this.state[`ch_stop${i}`]) {
        remitentes[`stop${i}`] = this.state[`selStop${i}`];
      }
    }
    console.log("handleSubmit SELECTED stops", remitentes);
    let stopCommision = 0;
    for (var stop in remitentes) {
      let parsed = JSON.parse(remitentes[stop]);
      stopCommision += Number(parsed.comision_a_operador);
    }
    this.setState({
      total_commission:
        Number(this.state.version_rutaObj?.tarifa_servicio || 0) / 10 +
        stopCommision / 10,
      unidad_id: this.state.unidad_id && this.state.unidad_id.length >0 ? this.state.unidad_id[0].value: 0,
    });

    // this.state.hora_ventana = moment(this.state.hora_ventana).format('hh:mm A').toString();
    var folio = this.state.folio || 1;
    delete this.state.fecha_liquidacion;
    delete this.state.ch_drop;
    delete this.state.ch_origen;
    delete this.state.ch_destino;
    delete this.state.ch_stop1;
    delete this.state.ch_stop2;
    delete this.state.ch_stop3;
    delete this.state.ch_stop4;
    delete this.state.ch_stop5;
    delete this.state.ch_stop6;
    delete this.state.operadores;
    delete this.state.clientes;
    delete this.state.unidades;
    delete this.state.remolques;
    delete this.state.rutas;
    delete this.state.versiones;
    delete this.state.stops;
    delete this.state.catalogo_cartas;
    delete this.state._remitentes;
    delete this.state._destinatarios;
    delete this.state.version_rutaObj;
    delete this.state.selStop1;
    delete this.state.selStop2;
    delete this.state.selStop3;
    delete this.state.selStop4;
    delete this.state.selStop5;
    delete this.state.selStop6;

    if (!this.state.fecha_cobro) {
      delete this.state.fecha_cobro;
    }
    let finalData = {};
    this.setState(
      (prevState) => {
        return { total_commission: prevState.total_commission };
      },
      () => {
        finalData = { ...remitentes, ...that.state };
        data.append("embarque", JSON.stringify(finalData));
      }
    );

    let mensajes = [];
    if (!this.state.fecha_embarque) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione la fecha de embarque",
      });
    }

    this.setState(
      (prevState) => {
        return { total_commission: prevState.total_commission };
      },
      () => {
        if (mensajes.length) {
          this.growl.show(mensajes);
        }
        else {
          this.servicioE.guardarEmbarque(data).then((res) => {
            if (res && res.result == "OK") {
              that.setState({ visible: true, folio: folio });
              that.growl.show({
                severity: "success",
                summary: "Operación completada",
                detail: "Información guardada correctamente",
              });
              this.props.history.push("/app/embarques/consulta")
            } else if (res && res.result == "EXISTE") {
              if (res.repetidos.tender) {
                that.growl.show({
                  severity: "error",
                  summary:
                    "El cliente " +
                    that.state.tender +
                    " ya se encuentra registrado",
                  detail: "Número de embarque value already existe .",
                });
              }
            }
          });
        }
      }
    );
  }

  addButtonHandle = () => {
    if(this.state.stopsList.length < 6){
      let temp = this.state.stopsList
      temp.push(this.state.stopsList.length+1)
      this.setState( { stopsList: temp } )
    }
  }
  getCommonDataFromChilds(data)
	{
		switch (data.label) {
			case "fecha":
				this.setState({fecha_embarque: moment(data.value).format('YYYY-MM-DD').toString(),semana:moment(data.value).week()});
				break;
			case "hora":
				this.setState({hora_ventana: data.value});
				break;
		}
	}
  getImageInput(title,key)
  {
    return (
      <div className="image-group ml-1 w-full mb-4">
        <label className="title">{title}</label>
        <br />
        <div className="form-group">
          {!this.state[key]?.name && 
            <div className="custom-file-upload">
              <label htmlFor={`file-upload-${key}`} className="p-button-warning p-button m-0 w-100">
                <i className="pi pi-cloud-upload"></i> Seleccionar archivo
              </label>
              <input
                id={`file-upload-${key}`}
                type="file"
                style={{ display: "none" }}
                onChange={(e) =>
                  this.setState({ [key]: e.target.files[0] })
                }
                chooseLabel="Seleccionar Archivo"
              />
            </div>
          }
          {this.state[key]?.name && 
            <div className="file-info d-flex justify-content-between">
              <div className="label-info">
                {this.state[key].name}
              </div>
              <div className="action-info">
                <i
                  onClick={() => {
                    this.setState({ [key]: null });
                  }}
                  className="pi pi-trash"
                  style={{ fontSize: "1.5em", cursor: "pointer" }}
                ></i>
                </div>                            
            </div>
          }
        </div>
      </div>
    )
  }
  render() {
    const footer = (
      <div>
        <Button
          label="Sí"
          className='p-button-warning mr-2'
          icon="pi pi-check"
          onClick={this.imprimirCartaPorte}
        />
        <Button
          label="No"
          className='p-button-warning'
          icon="pi pi-times"
          onClick={(e) => this.props.history.push("/app/embarques/consulta")}
        />
      </div>
    );
    const opciones_motivo = (
      <Col xs="12" md="6">
        <div className="form-group">
          <label>Seleccione mótivo</label>
          <Select options={options2} placeholder="Seleccione un motivo" />
        </div>
      </Col>
    );
    const es = {
      firstDayOfWeek: 1,
      dayNames: [
        "domingo",
        "lunes",
        "martes",
        "miércoles",
        "jueves",
        "viernes",
        "sábado",
      ],
      dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
      dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
      monthNames: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      monthNamesShort: [
        "ene",
        "feb",
        "mar",
        "abr",
        "may",
        "jun",
        "jul",
        "ago",
        "sep",
        "oct",
        "nov",
        "dic",
      ],
    };
    return (
      <div className="container">
<Panel header="Nuevo Embarque">
        <Dialog
            header="Carta Porte"
            footer={footer}
            // visible={this.state.visible}
            visible={false}
            style={{ width: "50vw" }}
            modal={true}
            onHide={() => this.setState({ visible: false })}
          >
            ¿Desea imprimir su carta porte con folio {this.state.folio}?
          </Dialog>
          <Toast ref={(el) => (this.growl = el)} />
          <Container>
            <Row>
              <Col xs="12" md="3" sm="3">
                <div className="form-group">
                  <label>Folio </label>
                  <input
                    type="text"
                    value={this.state.folio}
                    placeholder="Sin asignar"
                    className="form-control"
                    readOnly
                  />
                </div>
              </Col>
              <Col xs="12" md="3" sm="4">
                <div className="form-group">
                  <label>Fecha de embarque</label>
                  <PCalendar
										label={"fecha"}
										getCommonDataFromChilds={this.getCommonDataFromChilds}
										showIcon
                    value={this.state.fecha_embarque}
									/>
                </div>
              </Col>
              <Col xs="12" md="2">
                <div className="form-group">
                  <label>Semana</label>
                  <input
                    type="text"
                    value={this.state.semana}
                    className="form-control"
                    readOnly
                  />
                </div>
              </Col>
              <Col xs="12" md="3" sm="3">
                <div className="form-group">
                  <label>Hora Ventana</label>
                  <PTimer
										label={"hora"}
										getCommonDataFromChilds={this.getCommonDataFromChilds}
										showIcon
									/>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Número de embarque</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => this.setState({ tender: e.target.value })}
                  />
                </div>
              </Col>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Categoría</label>
                  <Select
                    options={tipoCategoria}
                    onChange={(e) => this.setState({ categoria: e.value })}
                    placeholder="Elegir categoría de embarque"
                  />
                </div>
              </Col>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Tipo de Servicio</label>
                  <Select
                    options={tipoServicio}
                    onChange={(e) => this.setState({ tipo_servicio: e.value })}
                    placeholder="Elegir tipo de servicio"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Operador</label>
                  <Select
                    options={this.state.operadores}
                    onChange={(e) =>
                      this.setState({ operador_id: e.value }, () => {
                        this.operadorUnidad = this.operadorArray.filter(
                          (option) => option.id == this.state.operador_id
                        );
                        let uni = this.state.unidades.filter(
                          (option) =>
                            option.value == this.operadorUnidad[0].unidadId
                        );
                        this.setState({ unidad_id: uni });
                      })
                    }
                    placeholder="Elegir operador"
                  />
                </div>
              </Col>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Unidad</label>
                  <Select
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                    options={this.state.unidades}
                    value={this.state.unidad_id}
                    onChange={(e) => this.setState({ unidad_id: e.value })}
                    placeholder=""
                    isDisabled={true}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Remolque</label>
                  <Select
                    options={this.state.remolques}
                    onChange={(e) => this.setState({ remolque_id: e.value })}
                    placeholder="Elegir remolque"
                  />
                </div>
              </Col>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      value={this.state.ch_drop}
                      onChange={(e) =>
                        this.setState({ ch_drop: !this.state.ch_drop })
                      }
                    />
                    Caja Drop
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) =>
                      this.setState({ caja_drop: e.target.value })
                    }
                    readOnly={this.state.ch_drop}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Cliente</label>
                  <Select
                    options={this.state.clientes}
                    onChange={this.seleccionar_cliente}
                    placeholder="Elegir cliente"
                  />
                </div>
              </Col>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Ruta</label>
                  <Select
                    options={this.state.rutas}
                    onChange={this.seleccionar_ruta}
                    placeholder="Elegir ruta"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Versión de ruta</label>
                  <Select
                    options={this.state.versiones}
                    onChange={this.seleccionar_version}
                    placeholder="Elegir versión"
                  />
                </div>
              </Col>
              <Col xs="12" md="6" >
								<div className="form-group">
									<label>Ciudad Origen</label><br />
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) =>
                      this.setState({ ciudad_origen: e.target.value })
                    }
                  />
								</div>
							</Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Planta origen</label>
                  <Select
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                    options={this.state._remitentes}
                    getOptionLabel={(option) =>
                      `${JSON.parse(option.label).value_nombre ?? "nulo"},
                      ${ JSON.parse(option.label).estado.label ?? "nulo"},
                      ${JSON.parse(option.label).municipio ?? "nulo"} , 
                      ${JSON.parse(option.label).localidad ?? "nulo"},
                      ${JSON.parse(option.label).codigo_postal ?? "nulo"},
                      ${JSON.parse(option.label).colonia ?? "nulo"}`
                    }
                    value={
                      this.state._remitentes
                        ? this.state._remitentes.filter(
                            (option) =>
                              option.value === this.state.planta_origen
                          )
                        : ""
                    }
                    isDisabled={true}
                    onChange={this.seleccionar_remitente.bind(this)}
                    placeholder=""
                  />
                </div>
              </Col>
              <Col xs="12" md="6">
								<div className="form-group">
									<label>
                    {/* <input type="checkbox" onChange={e => this.setState({ ch_destino: !this.state.ch_destino })} /> */}
                    Planta destino
                  </label>
									<Select options={this.state._destinatarios} 
                    value={this.state._destinatarios ? this.state._destinatarios.filter(option => option.value === this.state.destino_final) : ""} 
                    // isDisabled={this.state.ch_destino} 
                    getOptionLabel={(option) =>
                      `${JSON.parse(option.label).value_nombre ?? "nulo"},
                      ${ JSON.parse(option.label).estado.label ?? "nulo"},
                      ${JSON.parse(option.label).municipio ?? "nulo"} , 
                      ${JSON.parse(option.label).localidad ?? "nulo"},
                      ${JSON.parse(option.label).codigo_postal ?? "nulo"},
                      ${JSON.parse(option.label).colonia ?? "nulo"}`
                    }
                    onChange={this.seleccionar_destinatario.bind(this)} 
                    placeholder="Seleccione una planta" 
                  />
								</div>
							</Col>
              <Col xs="12" md="6" className="d-flex">
                {/* <div className="d-flex"> */}
                  <div className="form-group mr-1 w-full">
                    <label>Carta Porte</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) =>
                        this.setState({
                          ch_destino_carta_porte: e.target.value,
                        })
                      }
                    />
                  </div>
                  {/* <div className="custom-file-upload ml-1 ">
                    <label
                      for="file-upload0"
                      className="custom-file-upload1 btn btn-info mt-4"
                    >
                      <i className="pi pi-cloud-upload"></i> upload Carta Porte
                    </label>
                    <input
                      id="file-upload0"
                      type="file"
                      className="btn btn-default"
                      multiple
                      style={{ display: "none" }}
                      onChange={(e) =>
                        this.setState(
                          {
                            ch_destino_carta_porte_file: e.target.files[0],
                          },
                          () => {
                            console.log(
                              "state.ch_destino_carta_porte_file",
                              this.state.ch_destino_carta_porte_file,
                              typeof this.state.ch_destino_carta_porte_file
                            );
                          }
                        )
                      }
                      chooseLabel="Seleccionar Carta Porte"
                    />
                  </div> */}
                  {this.getImageInput("Carta Porte", "ch_destino_carta_porte_file")}
                {/* </div> */}
              </Col>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Comentarios de ruta</label>
                  <textarea
                      className="form-control"
                      onChange={(e) =>
                        this.setState({ comentarios_de_ruta: e.target.value })
                      }
                    />
                </div>
              </Col>
            </Row>
            <Row>
              { this.state.stopsList.map( index => 
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      onChange={(e) =>
                        this.setState({ ['ch_stop'+index]: !this.state['ch_stop'+index] })
                      }
                    />
                    Agregar Stop {index}
                  </label>

                  {index == 1 && <span role="button" className="mx-4 h-2 pointer" onClick={this.addButtonHandle}>+</span>}
                  {/* {this.state.stops?.length && (
                    <input
                      type="text"
                      value={`${
                        JSON.parse(this.state.stops[1].label).municipio ??
                        "nulo"
                      }, ${
                        JSON.parse(this.state.stops[1].label).Estado ?? "nulo"
                      }, ${
                        JSON.parse(this.state.stops[1].label).codigo_postal ??
                        "nulo"
                      }`}
                      disabled={true}
                    />
                  )} */}
                  {/* {this.state.stops?.length
                    ? this.state.stops[1]
                    : null && ( */}
                  <Select
                    options={this.state.stops}
                    getOptionLabel={(option) =>
                      `${JSON.parse(option.label).value_nombre ?? "nulo"},
                      ${ JSON.parse(option.label).estado.label ?? "nulo"},
                      ${JSON.parse(option.label).municipio ?? "nulo"} , 
                      ${JSON.parse(option.label).localidad ?? "nulo"},
                      ${JSON.parse(option.label).codigo_postal ?? "nulo"},
                      ${JSON.parse(option.label).colonia ?? "nulo"}`
                    }
                    onChange={(e) => {
                      this.setState({
                        ['selStop'+index]: e.label,
                      });
                    }}
                    isDisabled={this.state['ch_stop'+index]}
                    placeholder="Seleccione un Stop"
                  />
                  {/* )} */}
                </div>
                {/* {this.state.stops?.length
                  ? this.state.stops[1]
                  : () =>
                false( */}
                {/* {!this.state.ch_stop1 && (
                  <div className="d-flex align-items-center">
                    <div className="form-group mr-1">
                      <label>Carta Porte</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) =>
                          this.setState({
                            ch_stop1_carta_porte: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="custom-file-upload ml-1 ">
                      <label
                        for="file-upload1"
                        className="custom-file-upload1 btn btn-info mt-4"
                      >
                        <i className="pi pi-cloud-upload"></i> upload Carta
                        Porte
                      </label>
                      <input
                        id="file-upload1"
                        type="file"
                        className="btn btn-default"
                        style={{ display: "none" }}
                        onChange={(e) =>
                          this.setState({
                            ch_stop1_carta_porte_file: e.target.files[0],
                          })
                        }
                        chooseLabel="Seleccionar Carta Porte"
                      />
                    </div>
                  </div>
                )} */}
              </Col>)}
              {/* <Col xs="12" md="6">
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      onChange={(e) =>
                        this.setState({ ch_stop2: !this.state.ch_stop2 })
                      }
                    />
                    Agregar Stop 2
                  </label>
                  <Select
                    options={this.state.stops}
                    getOptionLabel={(option) =>
                      `${JSON.parse(option.label).value_nombre ?? "nulo"},
                      ${ JSON.parse(option.label).estado.label ?? "nulo"},
                      ${JSON.parse(option.label).municipio ?? "nulo"} , 
                      ${JSON.parse(option.label).localidad ?? "nulo"},
                      ${JSON.parse(option.label).codigo_postal ?? "nulo"},
                      ${JSON.parse(option.label).colonia ?? "nulo"}`
                    }
                    onChange={(e) => {
                      this.setState({
                        selStop2: e.label,
                      });
                    }}
                    isDisabled={this.state.ch_stop2}
                    placeholder="Seleccione un Stop"
                  />
                </div>
              </Col> */}
            </Row>
            {/* <Row>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      onChange={(e) =>
                        this.setState({ ch_stop3: !this.state.ch_stop3 })
                      }
                    />
                    Agregar Stop 3
                  </label>
                  <Select
                    options={this.state.stops}
                    getOptionLabel={(option) =>
                      `${JSON.parse(option.label).value_nombre ?? "nulo"},
                      ${ JSON.parse(option.label).estado.label ?? "nulo"},
                      ${JSON.parse(option.label).municipio ?? "nulo"} , 
                      ${JSON.parse(option.label).localidad ?? "nulo"},
                      ${JSON.parse(option.label).codigo_postal ?? "nulo"},
                      ${JSON.parse(option.label).colonia ?? "nulo"}`
                    }
                    onChange={(e) => {
                      this.setState({
                        selStop3: e.label,
                      });
                    }}
                    isDisabled={this.state.ch_stop3}
                    placeholder="Seleccione un Stop"
                  />
                </div>
              </Col>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      onChange={(e) =>
                        this.setState({ ch_stop4: !this.state.ch_stop4 })
                      }
                    />
                    Agregar Stop 4
                  </label>
                  <Select
                    options={this.state.stops}
                    getOptionLabel={(option) =>
                      `${JSON.parse(option.label).value_nombre ?? "nulo"},
                      ${ JSON.parse(option.label).estado.label ?? "nulo"},
                      ${JSON.parse(option.label).municipio ?? "nulo"} , 
                      ${JSON.parse(option.label).localidad ?? "nulo"},
                      ${JSON.parse(option.label).codigo_postal ?? "nulo"},
                      ${JSON.parse(option.label).colonia ?? "nulo"}`
                    }
                    onChange={(e) => {
                      this.setState({
                        selStop4: e.label,
                      });
                    }}
                    isDisabled={this.state.ch_stop4}
                    placeholder="Seleccione un Stop"
                  />
                </div>
              </Col>
            </Row> */}
            {/* <Row>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      onChange={(e) =>
                        this.setState({ ch_stop5: !this.state.ch_stop5 })
                      }
                    />
                    Agregar Stop 5
                  </label>
                  <Select
                    options={this.state.stops}
                    getOptionLabel={(option) =>
                      `${JSON.parse(option.label).value_nombre ?? "nulo"},
                      ${ JSON.parse(option.label).estado.label ?? "nulo"},
                      ${JSON.parse(option.label).municipio ?? "nulo"} , 
                      ${JSON.parse(option.label).localidad ?? "nulo"},
                      ${JSON.parse(option.label).codigo_postal ?? "nulo"},
                      ${JSON.parse(option.label).colonia ?? "nulo"}`
                    }
                    onChange={(e) => {
                      this.setState({
                        selStop5: e.label,
                      });
                    }}
                    isDisabled={this.state.ch_stop5}
                    placeholder="Seleccione un Stop"
                  />
                </div>
              </Col>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      onChange={(e) =>
                        this.setState({ ch_stop6: !this.state.ch_stop6 })
                      }
                    />
                    Agregar Stop 6
                  </label>
                  <Select
                    options={this.state.stops}
                    getOptionLabel={(option) =>
                      `${JSON.parse(option.label).value_nombre ?? "nulo"},
                      ${ JSON.parse(option.label).estado.label ?? "nulo"},
                      ${JSON.parse(option.label).municipio ?? "nulo"} , 
                      ${JSON.parse(option.label).localidad ?? "nulo"},
                      ${JSON.parse(option.label).codigo_postal ?? "nulo"},
                      ${JSON.parse(option.label).colonia ?? "nulo"}`
                    }
                    onChange={(e) => {
                      this.setState({
                        selStop6: e.label,
                      });
                    }}
                    isDisabled={this.state.ch_stop6}
                    placeholder="Seleccione un Stop"
                  />
                </div>
              </Col>
            </Row> */}
            <Row>
              {/* <Col xs="12" md="6">
                <div className="form-group">
                  <label>Número de embarque</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => this.setState({ tender: e.target.value })}
                  />
                </div>
              </Col> */}
              {/* <Col xs="12" md="6">
								<div className="form-group">
									<label>Fecha estimada de liquidación</label><br />
									<Calendar   dateFormat="dd/mm/yy" value={this.state.fecha_liquidacion ? moment(this.state.fecha_liquidacion, 'YYYY-MM-DD').toDate() : null} onChange={(e) => { this.setState({ fecha_liquidacion: moment(e.value).format('YYYY-MM-DD').toString() }); }} showIcon={true} placeholder="dd/mm/aaaa" />
								</div>
							</Col> */}
              {/* <Col xs="12" md="6">
                <div className="form-group">
                  <label>Fecha de cobro</label>
                  <br />
                  <Calendar
                     
                    dateFormat="dd/mm/yy"
                    value={
                      this.state.fecha_cobro
                        ? moment(this.state.fecha_cobro, "YYYY-MM-DD").toDate()
                        : null
                    }
                    onChange={(e) => {
                      this.setState({
                        fecha_cobro: moment(e.value)
                          .format("YYYY-MM-DD")
                          .toString(),
                      });
                    }}
                    showIcon={true}
                    placeholder="dd/mm/aaaa"
                  />
                </div>
              </Col> */}
            </Row>
            <Row>
              {/* <Col xs="12" md="6">
                <div className="form-group">
                  <label>Cambiar estado</label>
                  <Select
                    options={options}
                    value={options.filter(
                      (option) => option.value == this.state.estatus
                    )}
                    onChange={(e) => this.setState({ estatus: e.value })}
                    placeholder="Seleccione un estado"
                  />
                </div>
              </Col> */}

              {this.state.estatus == 2 ? opciones_motivo : ""}
            </Row>
            <center>
              {" "}
              <Button
                type="submit"
                onClick={this.handleSubmit}
                className="p-button-warning"
              >
                Guardar
              </Button>
            </center>
          </Container>
      </Panel>
      </div>
    );
  }
}

export default NuevoEmbarque;
