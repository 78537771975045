import React from 'react'; 

import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Nav, Collapse } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { authenticationService } from '../../../jwt/_services';
import ControlledDemo from './newMenu';


import profilephoto from '../../../assets/images/users/user_icon.png';
import logodarktext from '../../../assets/images/_logo_text.png';
import bgtruck from '../../../assets/images/truck.png';
import logolighttext from '../../../assets/images/logo-light-text.png';
import CheckPermissions from 'controllers/permissions/services';

const mapStateToProps = state => ({
	...state
});

class Sidebar extends React.Component {

	constructor(props) {
		super(props);
		this.expandLogo = this.expandLogo.bind(this);
		this.activeRoute.bind(this);
		this.activeCollapse.bind(this);
		this.toggle = this.toggle.bind(this);
		this.logout = this.logout.bind(this);
		this.state = {
			uicomponents: this.activeRoute("/ui-components") !== "" ? true : false,
			dashboardpages: this.activeRoute("/dahboards") !== "" ? true : false,
			dropdownOpen: false,
            user:JSON.parse(localStorage.getItem('currentUser'))
		};
		this.permissionService = new CheckPermissions();
	}
	toggle() {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	}
	/*--------------------------------------------------------------------------------*/
	/*To Expand SITE_LOGO With Sidebar-Menu on Hover                                  */
	/*--------------------------------------------------------------------------------*/
	expandLogo() {
		document.getElementById("logobg").classList.toggle("expand-logo");
	}
	componentDidMount() {
	   let pathname = this.props.location.pathname;
	   let firstdd = {};
	   let secondadd = {};
	   this.props.routes.map((menu, i)=> {
		   if(pathname.includes(menu.pathName)){
			firstdd[menu["state"]] = true;
			this.setState(firstdd);
		   if(menu.child){
			menu.child.map((menu)=>{
				if(pathname.includes(menu.pathName)){
					secondadd[menu["state"]] = true;
					this.setState(secondadd);
					return
				}
			})
		   }
		   }
	   })
	}
	/*--------------------------------------------------------------------------------*/
	/*Verifies if routeName is the one active (in browser input)                      */
	/*--------------------------------------------------------------------------------*/
	activeRoute(routeName) { return this.props.location.pathname.indexOf(routeName) > -1 ? 'selected' : ''; }
	activeCollapse(routeName)
	{
		return true
	}
	logout(){
		authenticationService.logout();
		this.props.history.push('/');
	}
	render() {	
		return (
			<aside className="left-sidebar" id="sidebarbg" data-sidebarbg={this.props.settings.activeSidebarBg} onMouseEnter={this.expandLogo} onMouseLeave={this.expandLogo}>
				<div className='text-center'>
					<span className="logo-text new_logo position-relative">
						<img src={bgtruck} alt="homepage" className="dark-logo w-100" />
						<img src={logodarktext} alt="homepage" className="inside-logo" />
					</span>
				</div>
				<div className="scroll-sidebar">
					<PerfectScrollbar className="sidebar-nav">
						{/* <div className="user-profile">
							<div className="user-pro-body">
								<Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="text-center">
									<DropdownToggle tag="a" className="u-dropdown link hide-menu">
                                        {this.state.user.firstName} {this.state.user.lastName}<i className="fa fa-caret-down"></i>
									</DropdownToggle>
									<DropdownMenu>
										<DropdownItem divider />
										<DropdownItem onClick={this.logout}  ><i className="fa fa-power-off"></i> Logout</DropdownItem>
									</DropdownMenu>
								</Dropdown>
							</div>
						</div> */}
						{/*--------------------------------------------------------------------------------*/}
						{/* Sidebar Menus will go here                                                */}
						{/*--------------------------------------------------------------------------------*/}
						{/* <ControlledDemo></ControlledDemo> */}
						{true && 
						<Nav id="sidebarnav">
							{this.props.routes.map((prop, key) => {
								if (prop.redirect) {
									return null;
								}
								else if (prop.navlabel) {
									return (
										<li className="nav-small-cap" key={key}>
											<i className={prop.icon}></i>
											<span className="hide-menu">{prop.name}</span>
										</li>
									);
								}
								else if (prop.collapse) {
									let firstdd = {};
									firstdd[prop["state"]] = !this.state[prop.state];

									return (
										/*--------------------------------------------------------------------------------*/
										/* Menus wiil be goes here                                                        */
										/*--------------------------------------------------------------------------------*/
										<li className={this.activeRoute(prop.path) + ' sidebar-item'} key={key}>
											{this.permissionService.checkPermission(prop.module) && <span data-toggle="collapse" className="sidebar-link has-arrow" aria-expanded={this.state[prop.state]} onClick={() => this.setState(firstdd)} >
												<i className={prop.icon} />
												<span className="hide-menu">{prop.name}</span>
											</span>}
											{/*--------------------------------------------------------------------------------*/}
											{/* Sub-Menus wiil be goes here                                                    */}
											{/*--------------------------------------------------------------------------------*/}
											<Collapse defaultOpen={this.activeCollapse(prop.path)} isOpen={this.state[prop.state]}>
												<ul className="first-level">
													{prop.child.map((prop, key) => {
														if (prop.redirect) return null;
														if (prop.collapse) {
															let seconddd = {};

															seconddd[prop["state"]] = !this.state[prop.state];
															return (
																<li className={this.activeRoute(prop.path) + ' sidebar-item'} key={key} >
																	{this.permissionService.checkPermission(prop.module) && <span data-toggle="collapse" className="sidebar-link has-arrow" aria-expanded={this.state[prop.state]} onClick={() => this.setState(seconddd)} >
																		<i className={prop.icon} />
																		<span className="hide-menu">{prop.name}</span>
																	</span>}
																	{/*--------------------------------------------------------------------------------*/}
																	{/* Sub-Menus wiil be goes here                                                    */}
																	{/*--------------------------------------------------------------------------------*/}
																	<Collapse isOpen={this.state[prop.state]}>
																		<ul className="second-level">
																			{prop.subchild.map((prop, key) => {
																				if (prop.redirect) return null;
																				return (
																					<li className={this.activeRoute(prop.path) + ' sidebar-item'} key={key} >
																						{this.permissionService.checkPermission(prop.module) && <NavLink to={prop.path} activeClassName="active" className="sidebar-link">
																							<i className={prop.icon} />
																							<span className="hide-menu">{prop.name}</span>
																						</NavLink>}
																					</li>
																				);
																			})}
																		</ul>
																	</Collapse>
																</li>
															);
														}
														return (
															/*--------------------------------------------------------------------------------*/
															/* Adding Sidebar Item                                                            */
															/*--------------------------------------------------------------------------------*/
															<li className={this.activeRoute(prop.path) + (prop.pro ? ' active active-pro' : '') + ' sidebar-item'} key={key}>
																{this.permissionService.checkPermission(prop.module) && <NavLink to={prop.path} className="sidebar-link" activeClassName="active">
																	<i className={prop.icon} />
																	<span className="hide-menu">{prop.name}</span>
																</NavLink>}
															</li>
														);
													})}
												</ul>
											</Collapse>
										</li>
									);
								}
								else {
									return (
										/*--------------------------------------------------------------------------------*/
										/* Adding Sidebar Item                                                            */
										/*--------------------------------------------------------------------------------*/
										<li className={this.activeRoute(prop.path) + (prop.pro ? ' active active-pro' : '') + ' sidebar-item'} key={key}>
											<NavLink to={prop.path} className="sidebar-link" activeClassName="active">
												<i className={prop.icon} />
												<span className="hide-menu">{prop.name}</span>
											</NavLink>
										</li>
									);
								}
							})}
						</Nav> }
					</PerfectScrollbar>
				</div>
			</aside>
		);
	}
}
export default connect(mapStateToProps)(Sidebar);
