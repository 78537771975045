import React from "react";
import {
	Card,
	CardBody,Container, Row,Col,CardTitle

} from 'reactstrap';


import moment from 'moment';
import Select from 'react-select';

import { Toast } from 'primereact/toast';
import {Button} from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import {Fieldset} from 'primereact/fieldset';
import VigilanciaService from  'controllers/vigilancia/vigilancia-service';
import PCalendar from '../../ui-components/calendar';
import PTimer from '../../ui-components/timer';
import UnidadService  from 'controllers/unidad/unidadService';
import RemolqueServices from 'controllers/remolque/remolqueservice';
import { Checkbox } from "primereact/checkbox";
import { Panel } from 'primereact/panel';

class InspeccionVigilancia extends React.Component {
	constructor(){
		super();
		this.servicio = new VigilanciaService();
		this.servicioR = new RemolqueServices();
		this.servicioU = new UnidadService();
		this.getCommonDataFromChilds = this.getCommonDataFromChilds.bind(this);


		this.servicioU.getUnidades().then(data =>  {
			let arrUnidades = [];
			data.forEach(item=>{
				arrUnidades.push({value:item.id,label:item.placas});

			});
			this.setState({unidades: arrUnidades});
		});
		this.servicioR.getRemolques().then(data =>  {
			let arrRemolques= [];
			data.forEach(item=>{
				arrRemolques.push({value:item.id,label:item.placas});

			});
			this.setState({remolques: arrRemolques});
		});

		this.state= {checks:[]};
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	handleSubmit(event) {
				event.preventDefault();

				let datos ={};
				let that = this;

				datos.fecha = moment( this.state.fecha).format('YYYY-MM-DD').toString();
				datos.inspector = this.state.inspector;
				datos.hora = (this.state.hora)?this.state.hora:moment(this.state.fecha).format('hh:mm A').toString();
				datos.num_caja = this.state.num_caja;
				datos.checks = this.state.checks;
				datos.comentarios = this.state.comentarios;
				datos.num_unidad = this.state.num_unidad;
				datos.num_remolque = this.state.num_remolque;
				datos.elaborado_por = this.state.elaborado_por || false;

				this.servicio.guardarReporte7(datos).then(res =>  {
						if(res && res.result == 'OK'){

							that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Información guardada correctamente'});
							setTimeout(function(){
										that.props.history.push('/app/inspeccion/reportes');
							 }, 500);

						}else if(res && res.result == 'EXISTE'){
							that.growl.show({severity: 'error', summary: 'El Id de ruta '+this.state.id_ruta+' ya se encuentra registrado', detail: 'Ingrese otro código o consulte su información para editarla.'});
						}
				});
	}


		vista(elemento,i){
					let arreglo = Object.assign({}, this.state.checks);
					arreglo[i] = false
					const  objeto = (value) =>{
						arreglo[i] = value;
						return arreglo;
					};
					return (
						<Col sm="4">
							<div Style="margin:5px;">
								<InputSwitch Style="margin-bottom: -7px" checked={this.state.checks[i]}  onChange={(e) => this.setState({ checks:objeto(e.value)})} />
								&nbsp;
								{elemento}
							</div>
						</Col>
					)
		}


	listadoExterior(){
			const elementos = [
				'Inspeccionar equipo antes de entrar en la facilidad.',
 			 '¿Las vigas de apoyo están visibles?',
 			 '¿Alguna reparación reciente?'
	    ];
	    const listItems = elementos.map((elemento,i) =>
	      this.vista(elemento,i+10)
	   );
	   return (
	     <Row>
	      {listItems}
	     </Row>
	   )
	}
	listadoPuertas(){
		const elementos = [
			'¿Mecanismo de cerradura está funcionando y adecuado?',
			'¿Algunas reparaciones a las puertas? Varía uso de materiales',
			'¿Todas reparaciones están visibles de ambos lados de las puertas?',
			'Toca las puertas con herramienta metálica ¿suena sólido?'
		];
		const listItems = elementos.map((elemento,i) =>
			this.vista(elemento,i+20)
		);
		return (
			<Row>
			{listItems}
			</Row>
		)
	}
	listadoParedes(){
		const elementos = [
			'¿Reparaciones de estructura son visibles?',
			'¿Reparaciones al interior son visibles de ambos lados de las paredes?',
			'Toca las paredes, ¿suenan vacío las paredes?'
		];
		const listItems = elementos.map((elemento,i) =>
			this.vista(elemento,i+30)
		);
		return (
			<Row>
			{listItems}
			</Row>
		)
	}

	listadoPared(){
		const elementos = [
			'¿Los bloques y ventanas están visibles desde adentro y afuera?',
			'Toca la pared frontal. ¿Suena la pared vacía?',
			'Mide largo del interior y revise contra medida del exterior. ¿Cuadra?'
		];
		const listItems = elementos.map((elemento,i) =>
			this.vista(elemento,i+40)
		);
		return (
			<Row>
			{listItems}
			</Row>
		)
	}

	listadoTecho(){
		const elementos = [
			'El alto del techo a piso del contenedor. ¿Es igual en todas las áreas del equipo?',
			'Los bloques y ventanas. ¿Están completamente visibles?',
			'Reparaciones a techo. ¿Son visibles del interior y del exterior?',
			'Cuando tocas con herramienta metálica. ¿Suena el techo vacío?'
		];
		const listItems = elementos.map((elemento,i) =>
			this.vista(elemento,i+50)
		);
		return (
			<Row>
			{listItems}
			</Row>
		)
	}
	listadoPiso(){
		const elementos = [
			'¿El piso se ve nivel? ¿Tienes que subir para entrar?',
			'¿El piso varía en altura?',
			'¿Hay reparaciones no usuales o típicas al equipo?'
		];
		const listItems = elementos.map((elemento,i) =>
			this.vista(elemento,i+60)
		);
		return (
			<Row>
			{listItems}
			</Row>
		)
	}

	listadoSeguridad(){
		const elementos = [
			'¿El remolque se encuentra libre de presencia de contaminación o plagas visibles tanto al exterior como al interior?'
		];
		const listItems = elementos.map((elemento,i) =>
			this.vista(elemento,i+70)
		);
		return (
			<Row>
			{listItems}
			</Row>
		)
	}

	getCommonDataFromChilds(data)
	{
		switch (data.label) {
			case "fecha":
				this.setState({fecha: moment(data.value).format('YYYY-MM-DD').toString(),semana:moment(data.value).week()});
				break;
			case "hora":
				this.setState({hora: data.value});
				break;
		}
	}
	render() {
		const es = {
			firstDayOfWeek: 1,
			dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
			dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
			dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
			monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
			monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"]
	};

		return (
			<div className="container">
				<Panel header="Reporte De Inspección 7 Puntos">
					<Toast ref={(el) => this.growl = el} />
					<Row>
						<Col md="4">
								<div className="form-group">
									<label>Folio</label><br/>
									<input type="text" readOnly="true"  placeholder="Sin asignar" value={this.state.folio} onChange={e => this.setState({folio : e.target.value})} className="form-control"/>
								</div>

						</Col>
						<Col md="4">
							<div className="form-group">
								<label>Fecha de inspección</label><br/>
								<PCalendar
									label={"fecha"}
									getCommonDataFromChilds={this.getCommonDataFromChilds}
									showIcon
									value={this.state.fecha}
								/>
							</div>
						</Col>
						<Col md="4">
							<div className="form-group">
								<label>Hora de inspección</label><br/>
								<PTimer
									label={"hora"}
									getCommonDataFromChilds={this.getCommonDataFromChilds}
									showIcon
								/>
							</div>
						</Col>
						
					</Row>
					<Row>
						<Col xs="6">
							<div className="form-group">
								<label>Inspeccionado por</label>
								<input type="text"  value={this.state.inspector} onChange={e => this.setState({inspector : e.target.value})} className="form-control"/>
							</div>
						</Col>
						<Col xs="6">
							<div className="form-group">
								<label>Número de caja</label><br/>
									<input type="text"  value={this.state.num_caja} onChange={e => this.setState({num_caja : e.target.value})} className="form-control"/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col sm="6">
							<div className="form-group">
								<label>Número de la unidad</label><br/>
									<Select options={this.state.unidades}  onChange={e =>	this.setState({num_unidad: e.value,placas:e.label}) }  placeholder="Elegir unidad"/>
							</div>
						</Col>
						<Col sm="6">
							<div className="form-group">
								<label>Número del remolque</label><br/>
									<Select options={this.state.remolques}  onChange={e =>	this.setState({num_remolque: e.value,placas_remolque:e.label}) }  placeholder="Elegir remolque"/>
							</div>
						</Col>
					</Row>
					<div className="inspeccion-add">
						<Fieldset legend="Estructura exterior">
							{this.listadoExterior.bind(this)()}
						</Fieldset>
						<Fieldset legend="Puertas">
							{this.listadoPuertas.bind(this)()}
						</Fieldset>
						<Fieldset legend="Paredes Laterales">
							{this.listadoParedes.bind(this)()}
						</Fieldset>
						<Fieldset legend="Pared Frontal">
							{this.listadoPared.bind(this)()}
						</Fieldset>
						<Fieldset legend="Techo">
							{this.listadoTecho.bind(this)()}
						</Fieldset>
						<Fieldset legend="Piso">
							{this.listadoPiso.bind(this)()}
						</Fieldset>
						<Fieldset legend="Seguridad agrícola">
							{this.listadoSeguridad.bind(this)()}
						</Fieldset>
					</div>
					<Row>
						<Col xs="12">
							<div className="form-group">
								<div className="justify-content-start d-flex">
									<Checkbox
										inputId="cb1"
										checked={this.state.elaborado_por}
										onChange={(e) =>
											this.setState({ elaborado_por: e.checked })
										}
									/>
									<label htmlFor="cb1" className="p-checkbox-label mx-2">
										Firmar digitalmente
									</label>
								</div>
							</div>
							<div className="form-group">
									<label>Comentarios</label><br/>
										<input type="text"  value={this.state.comentarios}  onChange={e => this.setState({comentarios : e.target.value})} className="form-control"/>
							</div>
						</Col>
						<Col xs="12" className="text-center">
							<Button className="p-button-warning" onClick={this.handleSubmit} >Guardar</Button>
						</Col>
					</Row>
				</Panel>
			</div>
		);
	}

}

export default InspeccionVigilancia;
