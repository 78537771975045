import axios from 'axios';
import {url} from '../../constants';

export default class Service {

	    getCodigoPostal (value){
        return axios.post(url + 'fetch/codigo_postal',{CodigoPostal:value})
                .then(res => res.data);
			}

}
