import React from "react";
import { Container, Col, Row } from "reactstrap";
import PCalendar from '../../ui-components/calendar';
import PTimer from '../../ui-components/timer';
import Select from "react-select";
import { urlPdf } from "../../../constants";
import ClienteService from "controllers/cliente/clienteservice";
import OperadorServices from "controllers/operador/operador-service";
import UnidadService from "controllers/unidad/unidadService";
import EmbarqueService from "controllers/embarque/embarque-service";
import CartaPorteService from "controllers/carta-porte/carta-porte-service.js";
import RemolqueServices from "controllers/remolque/remolqueservice";
import RutaService from "controllers/ruta/rutaservice";
import { Toast } from "primereact/toast";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import {url} from "../../../constants";

const options = [
  { value: "1", label: "No pagado" },
  { value: "2", label: "Pre-Nómina" },
  { value: "3", label: "Nómina" },
];

const options2 = [
  { value: "1", label: "Falta de evidencias" },
  { value: "2", label: "Tender cancelado" },
  { value: "3", label: "Costo no autorizado" },
  { value: "4", label: "Tender pendiente" },
  { value: "5", label: "Otro" },
];

const tipoServicio = [
  { label: "OW", value: "OW" },
  { label: "RT", value: "RT" },
];

const tipoCategoria = [
  { label: "Transporte", value: "Transporte" },
  { label: "Renta", value: "Renta" },
];

let fileState;

class EditarEmbarque extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fecha_embarque: new Date(),
      fecha_liquidacion: new Date(),
      fecha_cobro: new Date(),
      hora_ventana: new Date(),
      ch_drop: true,
      ch_origen: true,
      ch_destino: true,
      ch_stop1: true,
      ch_stop2: true,
      ch_stop3: true,
      ch_stop4: true,
      ch_stop5: true,
      ch_stop6: true,
      cartas_porte: [],
      stopsList: [1],
      ciudad_origen:'',
      comentarios_de_ruta:''
    };

    this.servicio = new ClienteService();
    this.servicioU = new UnidadService();
    this.servicioO = new OperadorServices();
    this.servicioE = new EmbarqueService();
    this.servicioR = new RemolqueServices();
    this.servicioRuta = new RutaService();
    this.servicioT = new CartaPorteService();

    this.servicioT.catalogo().then((data) => {
      let arrCartas = [];

      data.forEach((item) => {
        arrCartas.push({ value: item.id, label: item.folio });
      });
      this.setState({ catalogo_cartas: arrCartas });
    });

    this.servicio.getClientes().then((data) => {
      let arrClientes = [];
      data.forEach((item) => {
        if (item.estatus !== 0) {
          arrClientes.push({
            value: item.id,
            label: item.razon_social,
            rfc: item.rfc,
          });
        }
      });
      this.setState({ clientes: arrClientes });
    });

    this.servicioU.getUnidades().then((data) => {
      let arrUnidades = [];
      data.forEach((item) => {
        arrUnidades.push({ value: item.id, label: item.eco });
      });
      this.setState({ unidades: arrUnidades });
    });

    this.servicioO.getOperadores().then((data) => {
      let arrOperadores = [];
      data.forEach((item) => {
        // arrOperadores.push({ value: item.usuario_id, label: item.nombre });
        arrOperadores.push({ value: item.usuario_id, label: item.nombre+ ' '+ item.apellido_paterno+ ' '+item.apellido_materno });
      });
      this.setState({ operadores: arrOperadores });
    });
    this.handleSubmit = this.handleSubmit.bind(this);
    this.imprimirCartaPorte = this.imprimirCartaPorte.bind(this);

    this.servicioCarta = new CartaPorteService();

    this.servicioR.getRemolques().then((data) => {
      let arrRemolques = [];
      data.forEach((item) => {
        arrRemolques.push({ value: item.id, label: item.placas });
      });
      this.setState({ remolques: arrRemolques });
    });

    this.seleccionar_cliente = this.seleccionar_cliente.bind(this);
    this.seleccionar_ruta = this.seleccionar_ruta.bind(this);
    this.seleccionar_version = this.seleccionar_version.bind(this);
    this.getCommonDataFromChilds = this.getCommonDataFromChilds.bind(this);
  }
  seleccionar_cliente(event) {
    this.setState({ rutas: [], ruta_id: "" });

    this.setState({ facturar_a: event.label, cliente_id: event.value });
    this.servicioRuta.getRutas(event.value).then((data) => {
      let arrRutas = [];
      data.forEach((item) => {
        arrRutas.push({ value: item.id, label: item.id_ruta });
      });
      this.setState({ rutas: arrRutas });
    });
  }

  seleccionar_ruta(event) {
    this.setState({ versiones: [], ruta_id: "" });
    this.setState({ ruta_id: event.value });
    // console.log(event.value);
    this.servicioRuta.getVersiones(event.value).then((data) => {
      let arrVersiones = [];
      data.forEach((item) => {
        arrVersiones.push({ value: item.id, label: item.version, obj: item });
        if (this.props.values.version_id === item.id) {
          this.seleccionar_version({
            value: item.id,
            label: item.version,
            obj: item,
          });
        }
      });
      this.setState({ versiones: arrVersiones });
    });
  }

  seleccionar_version(event) {
    let arrStops = [];
    if (event.obj.direccion_origen)
      arrStops[0] = {
        label: event.obj.direccion_origen,
        value: event.obj.direccion_origen,
      };
    if (event.obj.direccion_final)
      arrStops[11] = {
        label: event.obj.direccion_final,
        value: event.obj.direccion_final,
      };
    if (event.obj.stop1)
      arrStops[1] = { label: event.obj.stop1, value: event.obj.stop1 };
    if (event.obj.stop2)
      arrStops[2] = { label: event.obj.stop2, value: event.obj.stop2 };
    if (event.obj.stop3)
      arrStops[3] = { label: event.obj.stop3, value: event.obj.stop3 };
    if (event.obj.stop4)
      arrStops[4] = { label: event.obj.stop4, value: event.obj.stop4 };
    if (event.obj.stop5)
      arrStops[5] = { label: event.obj.stop5, value: event.obj.stop5 };
    if (event.obj.stop6)
      arrStops[6] = { label: event.obj.stop6, value: event.obj.stop6 };
    if (event.obj.stop7)
      arrStops[7] = { label: event.obj.stop7, value: event.obj.stop7 };
    if (event.obj.stop8)
      arrStops[8] = { label: event.obj.stop8, value: event.obj.stop8 };
    if (event.obj.stop9)
      arrStops[9] = { label: event.obj.stop9, value: event.obj.stop9 };
    if (event.obj.stop10)
      arrStops[10] = { label: event.obj.stop10, value: event.obj.stop10 };
    this.setState({
      stops: arrStops,
      version_id: event.value,
      _destinatarios: arrStops,
      _remitentes: arrStops,
    });
  }

  imprimirCartaPorte() {
    window.open(
      urlPdf + "carta_porte.php?folio=" + this.state.folio,
      "width=200,height=100"
    );
    //this.setState({visible:false});
  }

  seleccionar_remitente(e) {
    let opciones = this.state.stops.filter(
      (option) => option.label !== e.value
    );
    this.setState({
      _destinatarios: opciones,
      planta_origen: e.value,
    });
  }

  seleccionar_destinatario(e) {
    let opciones = this.state.stops.filter(
      (option) => option.label !== e.value
    );
    this.setState({
      // _remitentes: opciones,
      destino_final: e.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    // console.log(this.state);
    let that = this;
    const data = new FormData();
    if (this.state.ch_destino_carta_porte_file) {
      data.append(
        "ch_destino_carta_porte_file",
        this.state.ch_destino_carta_porte_file
      );
      delete this.state["ch_destino_carta_porte_file"];
    }
    for (let i = 1; i <= 6; i++) {
      let field_name = `ch_stop${i}_carta_porte_file`;
      if (this.state[field_name]) {
        data.append(`ch_stop${i}_carta_porte_file`, this.state[field_name]);
        delete this.state[field_name];
      }
    }

    delete this.state.fecha_liquidacion;
    delete this.state.ch_drop;
    delete this.state.ch_origen;
    delete this.state.ch_destino;
    delete this.state.ch_stop1;
    delete this.state.ch_stop2;
    delete this.state.ch_stop3;
    delete this.state.ch_stop4;
    delete this.state.ch_stop5;
    delete this.state.ch_stop6;
    delete this.state.operadores;
    delete this.state.clientes;
    delete this.state.unidades;
    delete this.state.remolques;
    delete this.state.rutas;
    delete this.state.versiones;
    delete this.state.stops;
    delete this.state.carta_porte_id;
    delete this.state._remitentes;
    delete this.state._destinatarios;
    delete this.state.catalogo_cartas;
    if (!this.state.fecha_cobro) {
      delete this.state.fecha_cobro;
    }
    data.append("embarque", JSON.stringify(that.state));
    // this.state.hora_ventana =  this.state.hora_ventana;
    // this.state.fecha_liquidacion= moment(this.state.fecha_liquidacion).format( 'YYYY-MM-DD').toString();
    // this.state.fecha_cobro= moment(this.state.fecha_cobro).format( 'YYYY-MM-DD').toString();
    // this.state.fecha_embarque= moment(this.state.fecha_embarque).format( 'YYYY-MM-DD').toString();
    var folio = this.state.folio;

    this.servicioE.actualizarEmbarque(data).then((res) => {
      if (res && res.result == "OK") {
        that.growl.show({
          severity: "success",
          summary: "Operación completada",
          detail: "Información guardada correctamente",
        });
        this.props.history.push("/app/embarques/consulta")
      } else if (res && res.result == "EXISTE") {
        if (res.repetidos.tender) {
          that.growl.show({
            severity: "error",
            summary:
              "El cliente " + that.state.tender + " ya se encuentra registrado",
            detail: "Número de embarque value already existe .",
          });
        }
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.values !== this.props.values) {
      fileState = {
        ch_destino_carta_porte_file: {name: this.props.values?.ch_destino_carta_porte_file}
      }
      this.setState({
        id: this.props.values.id,
        carta_porte_id: this.props.values.carta_porte_id,
        ruta_id: this.props.values.ruta_id,
        version_id: this.props.values.version_id,
        stop1: this.props.values.stop1,
        stop2: this.props.values.stop2,
        stop3: this.props.values.stop3,
        stop4: this.props.values.stop4,
        stop5: this.props.values.stop5,
        stop6: this.props.values.stop6,
        ch_stop1_carta_porte: this.props.values.ch_stop1_carta_porte,
        ch_stop2_carta_porte: this.props.values.ch_stop2_carta_porte,
        ch_stop3_carta_porte: this.props.values.ch_stop3_carta_porte,
        ch_stop4_carta_porte: this.props.values.ch_stop4_carta_porte,
        ch_stop5_carta_porte: this.props.values.ch_stop5_carta_porte,
        ch_stop6_carta_porte: this.props.values.ch_stop6_carta_porte,
        ch_destino_carta_porte: this.props.values.ch_destino_carta_porte,
        fecha_embarque: this.props.values.fecha_embarque,
        semana: this.props.values.semana,
        categoria: this.props.values.categoria,
        caja_numero: this.props.values.caja_numero,
        tipo_servicio: this.props.values.tipo_servicio,
        caja_drop: this.props.values.caja_drop,
        hora_ventana: this.props.values.hora_ventana,
        planta_origen: this.props.values.planta_origen,
        destino_final: this.props.values.destino_final,
        tender: this.props.values.tender,
        ajuste_tender: this.props.values.ajuste_tender,
        // fecha_liquidacion: this.props.values.fecha_liquidacion,
        fecha_cobro: this.props.values.fecha_cobro,
        fecha_creacion: this.props.values.fecha_creacion,
        folio: this.props.values.folio,
        operador_id: this.props.values.operador_id,
        unidad_id: this.props.values.unidad_id,
        remolque_id: this.props.values.remolque_id,
        cliente_id: this.props.values.cliente_id,
        ruta_id: this.props.values.ruta_id,
        version_id: this.props.values.version_id,
        facturar_a: this.props.values.facturar_a,
        estatus: this.props.values.estatus,
        cartas_porte: this.props.values.cartas_porte
          ? this.props.values.cartas_porte.split`,`.map((x) => +x)
          : [],
        motivo: this.props.values.motivo,
        ciudad_origen:this.props.values.ciudad_origen,
        comentarios_de_ruta:this.props.values.comentarios_de_ruta,
        ...fileState
      });
      if (this.props.values.facturar_a) {
        this.seleccionar_cliente({
          rfc: this.props.values.rfc,
          label: this.props.values.facturar_a,
          value: this.props.values.cliente_id,
        });
      }
      if (this.props.values.ruta_id) {
        this.seleccionar_ruta({ value: this.props.values.ruta_id });
      }
    }
  }
  
  addButtonHandle = () => {
    if(this.state.stopsList.length < 6){
      let temp = this.state.stopsList
      temp.push(this.state.stopsList.length+1)
      this.setState( { stopsList: temp } )
    }
  }
  getCommonDataFromChilds(data)
	{
		switch (data.label) {
			case "fecha_embarque":
				this.setState({fecha_embarque: moment(data.value).format('YYYY-MM-DD').toString(),semana:moment(data.value).week()});
				break;
			case "hora_ventana":
				this.setState({hora_ventana: data.value});
				break;
		}
	}
  getImageInput(title,key)
	{
		return (
		<div className="image-group w-50">
			<label className="title">{title}</label>
			<br />
			<div className="form-group">
			{!this.state[key]?.name && 
				<div className="custom-file-upload">
				<label htmlFor={`file-upload-${key}`} className="p-button-warning p-button m-0 w-100">
					<i className="pi pi-cloud-upload"></i> Seleccionar archivo
				</label>
				<input
					id={`file-upload-${key}`}
					type="file"
					style={{ display: "none" }}
					onChange={(e) =>
					this.setState({ [key]: e.target.files[0] })
					}
					chooseLabel="Seleccionar Archivo"
				/>
				</div>
			}
			{this.state[key]?.name && 
				<div className="file-info d-flex justify-content-between">
				  <div className="label-info">
						{this.state[key].name}
					</div>
			<div className="d-flex">
			{fileState?.[key]?.name && 
					<div className="action-info mr-3">
						{this.state[key].name.includes('.') && 
						<i
						onClick={() => {
							window.open(url+"altadeunidad/"+this.state[key].name,'_blank')
						}}
						className="pi pi-download"
						style={{ fontSize: "1rem", cursor: "pointer" }}
						></i>
						}
					</div>
	        }
				<div className="action-info">
                <i
                  onClick={() => {
                    this.setState({ [key]: null });
					fileState && fileState[key] && (fileState[key].name = null);
				}}
                  className="pi pi-trash"
                  style={{ fontSize: "1.5em", cursor: "pointer" }}
                ></i>
                </div>                                 
				</div>
			</div>
			}
			</div>
		</div>
		)
	}

  render() {
    const footer = (
      <div>
        <Button
          label="Sí"
          className='p-button-warning mr-2'
          icon="pi pi-check"
          onClick={this.imprimirCartaPorte}
        />
        <Button
          label="No"
          className='p-button-warning'
          icon="pi pi-times"
          onClick={(e) => this.setState({ visible: false })}
        />
      </div>
    );

    const opciones_motivo = (
      <Row>
        <Col xs="6">
          <div className="form-group">
            <label>Seleccione mótivo</label>
            <Select
              options={options2}
              value={options2.filter(
                (option) => option.value == this.state.motivo
              )}
              onChange={(e) => this.setState({ motivo: e.value })}
              placeholder="Seleccione un motivo"
            />
          </div>
        </Col>
      </Row>
    );
    const es = {
      firstDayOfWeek: 1,
      dayNames: [
        "domingo",
        "lunes",
        "martes",
        "miércoles",
        "jueves",
        "viernes",
        "sábado",
      ],
      dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
      dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
      monthNames: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      monthNamesShort: [
        "ene",
        "feb",
        "mar",
        "abr",
        "may",
        "jun",
        "jul",
        "ago",
        "sep",
        "oct",
        "nov",
        "dic",
      ],
    };
    return (
      <div>
        <Dialog
          header="Carta Porte"
          footer={footer}
          visible={this.state.visible}
          style={{ width: "50vw" }}
          modal={true}
          onHide={() => this.setState({ visible: false })}
        >
          ¿Desea imprimir su carta porte con folio {this.state.folio}?
        </Dialog>
        <Toast ref={(el) => (this.growl = el)} />
        <Container>
          <Row>
            <Col sm="2">
              <div className="form-group">
                <label>Folio </label>
                <input
                  type="text"
                  value={this.state.folio}
                  className="form-control"
                  readOnly
                />
              </div>
            </Col>
            <Col sm="4">
              <div className="form-group">
                <label>Fecha de embarque </label>
                <PCalendar
										label={"fecha_embarque"}
										getCommonDataFromChilds={this.getCommonDataFromChilds}
										showIcon
                    value={this.state.fecha_embarque}
									/>
              </div>
            </Col>
            <Col sm="2">
              <div className="form-group">
                <label>Semana</label>
                <input
                  type="text"
                  value={this.state.semana}
                  className="form-control"
                  readOnly
                />
              </div>
            </Col>
            <Col xs="12" md="4" sm="3">
              <div className="form-group">
                <label>Hora Ventana</label>
                <PTimer
										label={"hora_ventana"}
										getCommonDataFromChilds={this.getCommonDataFromChilds}
										showIcon
                    value={this.state.hora_ventana}
									/>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <div className="form-group">
                <label>Número de embarque</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.tender}
                  onChange={(e) => this.setState({ tender: e.target.value })}
                />
              </div>
            </Col>
            <Col sm="6">
              <div className="form-group">
                <label>Categoría</label>
                <Select
                  options={tipoCategoria}
                  value={
                    this.props.values
                      ? tipoCategoria.filter(
                          (option) => option.label === this.state.categoria
                        )
                      : ""
                  }
                  onChange={(e) => this.setState({ categoria: e.value })}
                  placeholder="Elegir categoría de embarque"
                />
              </div>
            </Col>
            <Col sm="6">
              <div className="form-group">
                <label>Tipo de Servicio</label>
                <Select
                  options={tipoServicio}
                  value={
                    this.props.values
                      ? tipoServicio.filter(
                          (option) => option.label === this.state.tipo_servicio
                        )
                      : ""
                  }
                  onChange={(e) => this.setState({ tipo_servicio: e.value })}
                  placeholder="Elegir tipo de servicio"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <div className="form-group">
                <label>Operador</label>
                <Select
                  options={this.state.operadores}
                  value={
                    this.state.operadores
                      ? this.state.operadores.filter(
                          (option) => option.value === this.state.operador_id
                        )
                      : ""
                  }
                  onChange={(e) => this.setState({ operador_id: e.value })}
                  placeholder="Elegir operador"
                />
              </div>
            </Col>
            <Col sm="6">
              <div className="form-group">
                <label>Unidad</label>
                <Select
                  options={this.state.unidades}
                  value={
                    this.state.unidades
                      ? this.state.unidades.filter(
                          (option) => option.value === this.state.unidad_id
                        )
                      : ""
                  }
                  onChange={(e) => this.setState({ unidad_id: e.value })}
                  placeholder="Elegir unidad"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <div className="form-group">
                <label>Remolque</label>
                <Select
                  options={this.state.remolques}
                  value={
                    this.state.remolques
                      ? this.state.remolques.filter(
                          (option) => option.value === this.state.remolque_id
                        )
                      : ""
                  }
                  onChange={(e) => this.setState({ remolque_id: e.value })}
                  placeholder="Elegir remolque"
                />
              </div>
            </Col>
            <Col sm="6">
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    value={this.state.ch_drop}
                    onChange={(e) =>
                      this.setState({ ch_drop: !this.state.ch_drop })
                    }
                  />
                  Caja Drop
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.caja_drop}
                  onChange={(e) => this.setState({ caja_drop: e.target.value })}
                  readOnly={this.state.ch_drop}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <div className="form-group">
                <label>Cliente</label>
                <Select
                  options={this.state.clientes}
                  value={
                    this.state.clientes
                      ? this.state.clientes.filter(
                          (option) => option.label == this.state.facturar_a
                        )
                      : ""
                  }
                  onChange={this.seleccionar_cliente}
                  placeholder="Elegir cliente"
                />
              </div>
            </Col>
            <Col sm="6">
              <div className="form-group">
                <label>Ruta</label>
                <Select
                  options={this.state.rutas}
                  value={
                    this.state.rutas
                      ? this.state.rutas.filter(
                          (option) => option.value == this.state.ruta_id
                        )
                      : ""
                  }
                  onChange={this.seleccionar_ruta}
                  placeholder="Elegir ruta"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <div className="form-group">
                <label>Versión de ruta</label>
                <Select
                  options={this.state.versiones}
                  value={
                    this.state.versiones
                      ? this.state.versiones.filter(
                          (option) => option.value === this.state.version_id
                        )
                      : ""
                  }
                  onChange={this.seleccionar_version}
                  placeholder="Elegir versión"
                />
              </div>
            </Col>
            <Col sm="6">
              <div className="form-group">
                <label>Ciudad Origen</label>
                <input
                    value={this.state.ciudad_origen}
                    type="text"
                    className="form-control"
                    onChange={(e) =>
                      this.setState({ ciudad_origen: e.target.value })
                    }
                  />
              </div>
            </Col>            
          </Row>
          <Row>
            <Col sm="6">
              <div className="form-group">
                <label>Planta origen</label>
                <Select
                  onChange={(e) => this.seleccionar_remitente(e)}
                  getOptionLabel={(option) =>
                    `${JSON.parse(option.label).value_nombre ?? "nulo"},
                    ${ JSON.parse(option.label).estado.label ?? "nulo"},
                    ${JSON.parse(option.label).municipio ?? "nulo"} , 
                    ${JSON.parse(option.label).localidad ?? "nulo"},
                    ${JSON.parse(option.label).codigo_postal ?? "nulo"},
                    ${JSON.parse(option.label).colonia ?? "nulo"}`
                  }
                  options={this.state._remitentes}
                  value={
                    this.state._remitentes
                      ? this.state._remitentes.filter(
                          (option) => option.label === this.state.planta_origen
                        )
                      : ""
                  }
                  placeholder="Seleccione una planta"
                />
              </div>
            </Col>
            <Col sm="6">
							<div className="form-group">
								<label>
                  {/* <input type="checkbox" onChange={e => this.setState({ch_destino: !this.state.ch_destino})}/> */}
                  Planta destino
                </label>
								<Select  onChange={e=> this.seleccionar_destinatario(e)} 
                  options={this.state._destinatarios} 
                  getOptionLabel={(option) =>
                    `${JSON.parse(option.label).value_nombre ?? "nulo"},
                    ${ JSON.parse(option.label).estado.label ?? "nulo"},
                    ${JSON.parse(option.label).municipio ?? "nulo"} , 
                    ${JSON.parse(option.label).localidad ?? "nulo"},
                    ${JSON.parse(option.label).codigo_postal ?? "nulo"},
                    ${JSON.parse(option.label).colonia ?? "nulo"}`
                  }
                  // isDisabled={this.state.ch_destino}    
                  value={this.state._destinatarios?this.state._destinatarios.filter(option => option.label === this.state.destino_final ):""} 
                  placeholder="Seleccione una planta"  
                />
							</div>
						</Col>
            <Col sm="6">
              <div className="d-flex">
                <div className="form-group mr-1 w-50">
                  <label>Carta Porte</label>
                  <input
                    value={this.state.ch_destino_carta_porte}
                    type="text"
                    className="form-control"
                    onChange={(e) =>
                      this.setState({ ch_destino_carta_porte: e.target.value })
                    }
                  />
                </div>
                {this.getImageInput("Carta Porte", "ch_destino_carta_porte_file")}
              </div>
            </Col>
            <Col sm="6">
              <div className="form-group">
                <label>Comentarios de ruta</label>
                <textarea
                    value={this.state.comentarios_de_ruta}
                    className="form-control"
                    onChange={(e) =>
                      this.setState({ comentarios_de_ruta: e.target.value })
                    }
                  />
              </div>
            </Col>
          </Row>
          <Row>
          { this.state.stopsList.map( index =>  <Col sm="6">
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      this.setState({ ['ch_stop'+index]: !this.state['ch_stop'+index] })
                    }
                  />
                  Agregar Stop {index}
                </label>
                {index == 1 && <span role="button" className="mx-4 h-2 pointer" onClick={this.addButtonHandle}>+</span>}

                <Select
                  options={this.state.stops}
                  getOptionLabel={(option) =>
                      `${JSON.parse(option.label).value_nombre ?? "nulo"},
                      ${ JSON.parse(option.label).estado.label ?? "nulo"},
                      ${JSON.parse(option.label).municipio ?? "nulo"} , 
                      ${JSON.parse(option.label).localidad ?? "nulo"},
                      ${JSON.parse(option.label).codigo_postal ?? "nulo"},
                      ${JSON.parse(option.label).colonia ?? "nulo"}`
                    }
                  isDisabled={this.state['ch_stop'+index]}
                  placeholder="Seleccione un Stop"
                  onChange={(e) => {
                    this.setState({
                      ['selStop'+index]: e.label,
                    });
                  }}
                />
              </div>
              {/* {!this.state.ch_stop1 && (
                <div className="d-flex align-items-center">
                  <div className="form-group mr-1">
                    <label>Carta Porte</label>
                    <input
                      value={this.state.ch_stop1_carta_porte}
                      type="text"
                      className="form-control"
                      onChange={(e) =>
                        this.setState({ ch_stop1_carta_porte: e.target.value })
                      }
                    />
                  </div>
                  <div className="custom-file-upload ml-1 ">
                    <label
                      for="file-upload1"
                      className="custom-file-upload1 btn btn-info mt-4"
                    >
                      <i className="pi pi-cloud-upload"></i> upload Carta Porte
                    </label>
                    <input
                      id="file-upload1"
                      type="file"
                      className="btn btn-default"
                      style={{ display: "none" }}
                      onChange={(e) =>
                        this.setState({
                          ch_stop1_carta_porte_file: e.target.files[0],
                        })
                      }
                      chooseLabel="Seleccionar Carta Porte"
                    />
                  </div>
                </div>
              )} */}
            </Col> )}
            {/* <Col xs="6">
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      this.setState({ ch_stop2: !this.state.ch_stop2 })
                    }
                  />
                  Agregar Stop 2
                </label>
                <Select
                  options={this.state.stops}
                  getOptionLabel={(option) =>
                      `${JSON.parse(option.label).value_nombre ?? "nulo"},
                      ${ JSON.parse(option.label).estado.label ?? "nulo"},
                      ${JSON.parse(option.label).municipio ?? "nulo"} , 
                      ${JSON.parse(option.label).localidad ?? "nulo"},
                      ${JSON.parse(option.label).codigo_postal ?? "nulo"},
                      ${JSON.parse(option.label).colonia ?? "nulo"}`
                    }
                  isDisabled={this.state.ch_stop2}
                  placeholder="Seleccione un Stop"
                />
              </div>
            </Col> */}
          </Row>
          {/* <Row>
            <Col xs="6">
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      this.setState({ ch_stop3: !this.state.ch_stop3 })
                    }
                  />
                  Agregar Stop 3
                </label>
                <Select
                  options={this.state.stops}
                  getOptionLabel={(option) =>
                      `${JSON.parse(option.label).value_nombre ?? "nulo"},
                      ${ JSON.parse(option.label).estado.label ?? "nulo"},
                      ${JSON.parse(option.label).municipio ?? "nulo"} , 
                      ${JSON.parse(option.label).localidad ?? "nulo"},
                      ${JSON.parse(option.label).codigo_postal ?? "nulo"},
                      ${JSON.parse(option.label).colonia ?? "nulo"}`
                    }
                  isDisabled={this.state.ch_stop3}
                  placeholder="Seleccione un Stop"
                />
              </div>
            </Col>
            <Col xs="6">
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      this.setState({ ch_stop4: !this.state.ch_stop4 })
                    }
                  />
                  Agregar Stop 4
                </label>
                <Select
                  options={this.state.stops}
                  getOptionLabel={(option) =>
                      `${JSON.parse(option.label).value_nombre ?? "nulo"},
                      ${ JSON.parse(option.label).estado.label ?? "nulo"},
                      ${JSON.parse(option.label).municipio ?? "nulo"} , 
                      ${JSON.parse(option.label).localidad ?? "nulo"},
                      ${JSON.parse(option.label).codigo_postal ?? "nulo"},
                      ${JSON.parse(option.label).colonia ?? "nulo"}`
                    }
                  isDisabled={this.state.ch_stop4}
                  placeholder="Seleccione un Stop"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      this.setState({ ch_stop5: !this.state.ch_stop5 })
                    }
                  />
                  Agregar Stop 5
                </label>
                <Select
                  options={this.state.stops}
                  getOptionLabel={(option) =>
                      `${JSON.parse(option.label).value_nombre ?? "nulo"},
                      ${ JSON.parse(option.label).estado.label ?? "nulo"},
                      ${JSON.parse(option.label).municipio ?? "nulo"} , 
                      ${JSON.parse(option.label).localidad ?? "nulo"},
                      ${JSON.parse(option.label).codigo_postal ?? "nulo"},
                      ${JSON.parse(option.label).colonia ?? "nulo"}`
                    }
                  isDisabled={this.state.ch_stop5}
                  placeholder="Seleccione un Stop"
                />
              </div>
            </Col>
            <Col xs="6">
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      this.setState({ ch_stop6: !this.state.ch_stop6 })
                    }
                  />
                  Agregar Stop 6
                </label>
                <Select
                  options={this.state.stops}
                  getOptionLabel={(option) =>
                      `${JSON.parse(option.label).value_nombre ?? "nulo"},
                      ${ JSON.parse(option.label).estado.label ?? "nulo"},
                      ${JSON.parse(option.label).municipio ?? "nulo"} , 
                      ${JSON.parse(option.label).localidad ?? "nulo"},
                      ${JSON.parse(option.label).codigo_postal ?? "nulo"},
                      ${JSON.parse(option.label).colonia ?? "nulo"}`
                    }
                  isDisabled={this.state.ch_stop6}
                  placeholder="Seleccione un Stop"
                />
              </div>
            </Col>
          </Row> */}
          <Row>
           
            {/* <Col xs="6">
						<div className="form-group">
							<label>Fecha estimada de liquidación</label><br />
							<Calendar   dateFormat="dd/mm/yy" value={this.state.fecha_liquidacion ? moment(this.state.fecha_liquidacion, 'YYYY-MM-DD').toDate() : null} onChange={(e) => { this.setState({ fecha_liquidacion: moment(e.value).format('YYYY-MM-DD').toString() }); }} showIcon={true} placeholder="dd/mm/aaaa" />

						</div>
					</Col> */}
            {/* <Col xs="6">
              <div className="form-group">
                <label>Fecha de cobro real</label>
                <br />
                <Calendar
                   
                  dateFormat="dd/mm/yy"
                  value={
                    this.state.fecha_cobro
                      ? moment(this.state.fecha_cobro, "YYYY-MM-DD").toDate()
                      : null
                  }
                  onChange={(e) => {
                    this.setState({
                      fecha_cobro: moment(e.value)
                        .format("YYYY-MM-DD")
                        .toString(),
                    });
                  }}
                  showIcon={true}
                  placeholder="dd/mm/aaaa"
                />
              </div>
            </Col> */}
          </Row>
          <Row>
            {/* <Col xs="6">
              <div className="form-group">
                <label>Cambiar estado</label>
                <Select
                  options={options}
                  value={options.filter(
                    (option) => option.value == this.state.estatus
                  )}
                  onChange={(e) => this.setState({ estatus: e.value })}
                  placeholder="Seleccione un estado"
                />
              </div>
            </Col> */}
          </Row>
          {this.state.estatus == 2 ? opciones_motivo : ""}

          <center>
            <Button
              type="submit"
              onClick={this.handleSubmit}
              className="p-button-warning"
            >
              Guardar
            </Button>
            &nbsp;
            {/* <button
              onClick={this.imprimirCartaPorte}
              className="btn btn-default"
            >
              Ver carta porte
            </button>
            &nbsp; */}
          </center>
        </Container>
      </div>
    );
  }
}

export default EditarEmbarque;
