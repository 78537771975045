import axios from 'axios';
import { url } from '../../constants';

export default class Service {

	getReports(id, name, params = '') {
		return axios.get(url + `report/${id}/${name}${params}`, { headers : {"Content-Type": "application/octet-stream"}})
			.then(res => res);
	}

	getOperadores() {
		return axios.get(url + 'operadores')
			.then(res => res.data);
	}

	getFiltroOperadores(obj) {
		return axios.get(url + 'operadores/filtro', { params: obj })
			.then(res => res.data);
	}


	getOperadorEstado(estado) {
		return axios.get(url + 'operadores/estado', { params: { estado: estado } })
			.then(res => res.data);
	}

	guardarOperador(operador) {
		return axios.post(url + 'operador/guardar', operador, {}).then(res => res.data);
	}

	eliminarOperador(operador) {
		return axios.post(url + 'operador/eliminar', {
			operador: operador

		}).then(res => res.data);
	}

	actualizarOperador(operador) {
		return axios.post(url + 'operador/actualizar', operador, {}).then(res => res.data);
	}


	DownloadService() {
		return axios.get(url + 'Reporte_de_operadores_download', "", {}).then(res => res.data);
	}

	get_designaciones(value) {
		return axios.post(url + 'operador/designaciones', { designaciones: value })
			.then(res => res.data);
	}
	get_ClaveUnidad(value) {
		return axios.post(url + 'operador/ClaveUnidad', { clave_unidad: value })
			.then(res => res.data);
	}

}
