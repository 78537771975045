import React from "react";
import {
	Card,
	CardBody,
	CardTitle
} from 'reactstrap';
import Listado from './listado';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { urlPdf } from '../../../../constants';
import ServiceEmpleado from 'controllers/empleado/service';
import Select from 'react-select';
import { Panel } from 'primereact/panel';
import CheckPermissions from "controllers/permissions/services";

const es = {
	firstDayOfWeek: 1,
	dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
	dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
	dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
	monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
	monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"]
};

function range(start, end) {
	return Array(end - start + 1).fill().map((_, idx) => { return { label: start + idx, value: start + idx } })
}
function current_week_number() {
	let currentDate = new Date();
	let startDate = new Date(currentDate.getFullYear(), 0, 1);
	var days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
	var weekNumber = Math.ceil((currentDate.getDay() + 1 + days) / 7);
	return weekNumber
}
class Inventario extends React.Component {
	constructor() {
		super();
		this.state = {
			empleados: [],
			weeks: range(1, 53),
			week_number: current_week_number()
		}

		this.servicioE = new ServiceEmpleado();

		this.servicioE.listadoEmpleado().then(data => {
			let arrEmpleado = [];
			data.forEach(item => {
				arrEmpleado.push({ value: item.id, label: item.num_empleado });
			});
			this.setState({ empleados: arrEmpleado });
		});


		this.descargarNomina = this.descargarNomina.bind(this);
		this.continuarDescarga = this.continuarDescarga.bind(this);
		this.permissionService = new CheckPermissions();
		this.headerTemplate = this.headerTemplate.bind(this);
	}

	descargarNomina() {
		this.setState({ visible2: true });
	}

	continuarDescarga() {
		window.open(`${urlPdf}DESGLOSE-EXCEL/index.php?week_number=${this.state.week_number}`);
	}
	change_week_number = (week_number) => {
		this.setState({ week_number })
	}
	headerTemplate()
	{
		return (<div className="p-panel-header flex-wrap  d-flex">
			<div>
				Desglose De Nómina
			</div>
			<div className="pull-right">
				{this.permissionService.checkPermission('nomina_desglose_descargar') && <Button
					type="button"
					icon="pi pi-file-pdf"
					onClick={() => this.descargarNomina()}
					className="p-button-warning"
				><span className="ml-2">Descargar</span></Button>}
			</div>
		</div>);
	}
	render() {

		return (
			<div class="container-fluid">
				<Panel headerTemplate={this.headerTemplate}>
					<Toast ref={(el) => this.growl = el} />
					<Dialog header="Descargar" visible={this.state.visible2} style={{ width: '50vw' }} modal={true} onHide={() => this.setState({ visible: false, visible2: false })}>
						<div style={{ height: '50vh' }}>
							<center>
								<div className="form-group">
									<label></label>
									<Select options={this.state.empleados} onChange={e => this.setState({ empleado_id: e.value })} placeholder="Seleccionar un empleado" />
								</div>
								{/* <div className="form-group">
								<label>Fecha de embarque </label><br/>
								<Calendar   dateFormat="dd/mm/yy" onChange={(e) => { this.setState({fecha_embarque:  moment(e.value).format('YYYY-MM-DD').toString(),semana:moment(e.value).week()}); }} showIcon={true} placeholder="dd/mm/aaaa"/>
							</div> */}
								<div>Seleccionar semana</div>
								<div className='w-25'>
									<Select value={{ value: this.state.week_number, label: this.state.week_number }} options={this.state.weeks} onChange={e => { this.change_week_number(e.value) }} placeholder="Seleccionar semana" >
									</Select>
								</div>
								<br /><br />
								<button className="p-button-warning" onClick={() => this.continuarDescarga()}>Continuar</button>
							</center>
						</div>
					</Dialog>
					<Listado />
				</Panel>
			</div>
		);
	}
}
/*

*/
export default Inventario;
