import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import EmbarqueService from "controllers/embarque/embarque-service";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import EditarEmbarque from "./editar";
import { Toast } from "primereact/toast";
import { urlPdf, url } from "../../../constants";

import {
	Card,
	CardBody,
	CardTitle,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Col
  } from 'reactstrap';
import CheckPermissions from "controllers/permissions/services";

export default class EmbarqueListado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalFilter: null,
      modalOpen: false,
      tempTable: []
    };
    this.service = new EmbarqueService();
    this.eliminar = this.eliminar.bind(this);
    this.editar = this.editar.bind(this);
    this.permissionService = new CheckPermissions();
  }

  componentDidMount() {
    this.props.handleEmbarquetable(this.props.estado)
  }

  eliminar(column, rowData) {
    this.setState({
      visible2: true,
      selectedItemIndex: column.rowIndex,
      selectedItem: rowData,
    });
  }

  editar(rowData) {
    //this.setState({visible:true,item:column.rowData});
    this.props.history.push({
      pathname: "/app/embarque/editar",
      state: rowData,
    });
  }
  
  verCartaPorte(column) {
    window.open(
      urlPdf + "carta_porte.php?folio=" + column.rowData.folio,
      "width=200,height=100"
    );
  }

  confirmar_borrado() {
    this.setState({ visible2: false });
    this.setState({
      embarques: this.props.embarques.filter(
        (val, i) => i !== this.state.selectedItemIndex
      ),
    });

    let that = this;

    this.service.eliminarEmbarque(this.state.selectedItem.id).then((res) => {
      if (res && res.result == "OK") {
        that.growl.show({
          severity: "success",
          summary: "Operación completada",
          detail: "Registro borrado",
        });
        this.handleEmbarquetable(this.props.estado);
      }
    });
  }

  checkboxTemplate = (rowData, column) => {
    if(rowData.cliente_id && rowData.operador_id && rowData.ruta_id)
    {
      return <input key={column} className="all_checkbox" type="checkbox" onClick={ () => {
        rowData.checked = true;
        this.props.handleCheckbox(rowData)
      }}></input>
    }
  }

  folioTemplate = (rowData, column) => {
    return <a className="pointer" onClick={ () => this.modalHandle(rowData)}>{rowData.tender}</a>
  }


  actionTemplate(rowData, column) {
    return (
      <div>
        {this.permissionService.checkPermission('embarque_editar') && <Button
          type="button"
          icon="pi pi-pencil"
          onClick={(e) => this.editar(rowData)}
          className="p-button-warning"
          tooltip="Editar"
          tooltipOptions={{ position: 'top' }}
        />}
        {this.permissionService.checkPermission('embarque_borrar') && <Button
          type="button"
          icon="pi pi-trash"
          onClick={(e) => this.eliminar(column, rowData)}
          className="p-button-warning"
          tooltip="Borrar"
          tooltipOptions={{ position: 'top' }}
        />}
        {/* <button
          type="button"
          className="btn"
          onClick={(e) => this.verCartaPorte(column)}
        >
          Ver carta porte
        </button> */}
      </div>
    );
  }
  origenTemplate(rowData) {
    let p = JSON.parse(rowData.planta_origen);
    return rowData.planta_origen
      ? `${p.municipio}, ${p.colonia}, ${p.codigo_postal}`
      : "nulo";
  }
  destinoTemplate(rowData) {
    let p = JSON.parse(rowData.destino_final);
    return rowData.destino_final
      ? `${p.municipio}, ${p.colonia}, ${p.codigo_postal}`
      : "nulo";
  }
  fechaEmbarqueTemplate(rowData) {
    return rowData.fecha_embarque ? rowData.fecha_embarque : "nulo";  
  }
  cartaPorteTemplate(rowData) {
    return (this.permissionService.checkPermission('embarque_abrir_documento') && rowData.ch_destino_carta_porte_file ? 
      <a href={url+"nuevo-embarque/"+rowData.ch_destino_carta_porte_file} target={"_blank"}> 
        <Button 
        type="button" 
        icon="pi pi-file-pdf"
        className="p-button-warning"
        tooltip="Abrir documento"
        tooltipOptions={{ position: 'top' }}
          />
      </a>: "nulo")                            //rowData.folios ? rowData.folios : "nulo";
  }



  modalHandle = (rowData) => {
    this.setState({
      'modalOpen': !this.state.modalOpen,
      tempTable: rowData?.id ? [rowData] : [] 
    });
  }

  render() {
    const footer = (
      <div>
        <Button
          label="Sí"
          icon="pi pi-check"
          className='p-button-warning mr-2'
          onClick={this.confirmar_borrado.bind(this)}
        />
        <Button
          label="No"
          icon="pi pi-times"
          className='p-button-warning'
          onClick={(e) => this.setState({ visible2: false })}
        />
      </div>
    );
    // const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
    // const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;
    return (
      <div>
        <Toast ref={(el) => (this.growl = el)} />
        <Dialog
          header="Editar"
          visible={this.state.visible}
          style={{ width: "70vw", height: "90vh", overflow: "scroll" }}
          modal={true}
          onHide={() => this.setState({ visible: false, visible2: false })}
        >
          <EditarEmbarque values={this.state.item} />
        </Dialog>
        <Dialog
          header="Eliminar"
          footer={footer}
          visible={this.state.visible2}
          style={{ width: "50vw" }}
          modal={true}
          onHide={() => this.setState({ visible: false, visible2: false })}
        >
          ¿Confirma eliminar?
        </Dialog>
        <DataTable value={this.props.embarques}
        paginator responsiveLayout="scroll"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10}
        // paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}
        >
          {this.props.estado<=2?
          <Column
            body={this.checkboxTemplate.bind(this)}
            header={(this.props.estado==1)?"Pasar a pre-nómina":"Pasar a nómina"}
            style={{ textAlign: "center" }}
          />:(null)
          }
          <Column field="semana" header="Semana"/>
          <Column field="fecha_embarque" header="Fecha de Embarque" />
          <Column field="operador_name" header="Operador" />
          <Column field="ruta" header="Ruta" />
          <Column body={this.folioTemplate.bind(this)} header="Folio de Embarque"  />
          <Column field="ciudad_origen" header="Ciudad Origen" />
          <Column body={this.origenTemplate.bind(this)} header="Origen"  />
          <Column body={this.destinoTemplate.bind(this)} header="Destino"  />
          <Column
            field="folios"
            header="Carta porte"
            body={this.cartaPorteTemplate.bind(this)}
          />
          <Column
            body={this.actionTemplate.bind(this)}
            header="Opciones"
            style={{ width:"10%",textAlign: "center" }}
            className="action_col"
          />
        </DataTable>
      </div>
    );
  }
}
