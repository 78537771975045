import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import EmbarqueService from "controllers/embarque/embarque-service";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import EditarEmbarque from "./editar";
import { Toast } from "primereact/toast";
import { urlPdf, url } from "../../../constants";

export default class EmbarqueListado extends Component {
  constructor() {
    super();
    this.state = {
      globalFilter: null,
    };
    this.service = new EmbarqueService();
    this.eliminar = this.eliminar.bind(this);
    this.editar = this.editar.bind(this);
  }

  componentDidMount() {
    if (this.props.parametros) {
      this.service
        .getFiltroEmbarques(this.props.parametros)
        .then((data) => this.setState({ embarques: data }));
    } else {
      this.service
        .getEmbarques()
        .then((data) => this.setState({ embarques: data }));
    }
  }

  eliminar(column) {
    this.setState({
      visible2: true,
      selectedItemIndex: column.rowIndex,
      selectedItem: column.rowData,
    });
  }

  editar(column) {
    //this.setState({visible:true,item:column.rowData});
    this.props.history.push({
      pathname: "/app/embarque/editar",
      state: column.rowData,
    });
  }

  verCartaPorte(column) {
    window.open(
      urlPdf + "carta_porte.php?folio=" + column.rowData.folio,
      "width=200,height=100"
    );
  }

  confirmar_borrado() {
    this.setState({ visible2: false });
    this.setState({
      embarques: this.state.embarques.filter(
        (val, i) => i !== this.state.selectedItemIndex
      ),
    });

    let that = this;

    this.service.eliminarEmbarque(this.state.selectedItem.id).then((res) => {
      if (res && res.result == "OK") {
        that.growl.show({
          severity: "success",
          summary: "Operación completada",
          detail: "Registro borrado",
        });
      }
    });
  }

  actionTemplate(rowData, column) {
    return (
      <div>
        <Button
          type="button"
          icon="pi pi-pencil"
          onClick={(e) => this.editar(column)}
          className=""
        />
        <Button
          type="button"
          icon="pi pi-trash"
          onClick={(e) => this.eliminar(column)}
          className="p-button-warning"
        />
        {/* <button
          type="button"
          className="btn"
          onClick={(e) => this.verCartaPorte(column)}
        >
          Ver carta porte
        </button> */}
      </div>
    );
  }
  // origenTemplate(rowData) {
  //   return rowData.planta_origen ? rowData.planta_origen : "nulo";
  // }
  // destinoTemplate(rowData) {
  //   return rowData.destino_final ? rowData.destino_final : "nulo";
  // }
  origenTemplate(rowData) {
    let p = JSON.parse(rowData.planta_origen);
    return rowData.planta_origen
      ? `${p.municipio}, ${p.colonia}, ${p.codigo_postal}`
      : "nulo";
  }
  destinoTemplate(rowData) {
    let p = JSON.parse(rowData.destino_final);
    return rowData.destino_final
      ? `${p.municipio}, ${p.colonia}, ${p.codigo_postal}`
      : "nulo";
  }
  fechaEmbarqueTemplate(rowData) {
    return rowData.fecha_embarque ? rowData.fecha_embarque : "nulo";
  }

  cartaPorteTemplate(rowData) {

    return rowData.ch_destino_carta_porte_file ? 
    <a icon="pi pi-file-pdf"  href={url+"nuevo-embarque/"+rowData.ch_destino_carta_porte_file} target={"_blank"}> 
      <Button type="button" icon="pi pi-file-pdf"/>
    </a>: "nulo"//rowData.folios ? rowData.folios : "nulo";
  }

  render() {
    const footer = (
      <div>
        <Button
          label="Sí"
          className='p-button-warning mr-2'
          icon="pi pi-check"
          onClick={this.confirmar_borrado.bind(this)}
        />
        <Button
          label="No"
          className='p-button-warning'
          icon="pi pi-times"
          onClick={(e) => this.setState({ visible2: false })}
        />
      </div>
    );
    let header = (
      <div style={{ textAlign: "left" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
        <InputText
          type="search"
          onInput={(e) => this.setState({ globalFilter: e.target.value })}
          placeholder="Global Search"
          size="50"
        />
      </div>
    );

    return (
      <div>
        <Toast ref={(el) => (this.growl = el)} />
        <Dialog
          header="Editar"
          visible={this.state.visible}
          style={{ width: "70vw", height: "90vh", overflow: "scroll" }}
          modal={true}
          onHide={() => this.setState({ visible: false, visible2: false })}
        >
          <EditarEmbarque
            values={this.state.item}
            growl={this.growl}
            onSaved={() => this.setState({ visible: false })}
          />
        </Dialog>
        <Dialog
          header="Eliminar"
          footer={footer}
          visible={this.state.visible2}
          style={{ width: "50vw" }}
          modal={true}
          onHide={() => this.setState({ visible: false, visible2: false })}
        >
          ¿Confirma eliminar?
        </Dialog>
        <DataTable
          value={this.state.embarques}
          paginator={true}
          rows={10}
          header={header}
          globalFilter={this.state.globalFilter}
        >
          <Column field="folio" header="Folio"  />
          <Column field="semana" header="Semana" />
          <Column field="operador_name" header="Operador" />
          <Column field="ruta" header="Ruta" />
          <Column
            field="planta_origen"
            header="Planta origen"
            body={this.origenTemplate}
          />
          <Column
            field="destino_final"
            header="Planta destino"
            body={this.destinoTemplate}
          />
          <Column field="facturar_a" header="Embarque del Cliente" />
          <Column
            field="fecha_embarque"
            header="Fecha embarque"
            body={this.fechaEmbarqueTemplate}
          />
          <Column
            field="folios"
            header="Carta porte"
            body={this.cartaPorteTemplate}
          />
          <Column
            body={this.actionTemplate.bind(this)}
            header="Opciones"
            style={{ textAlign: "center" }}
          />
        </DataTable>
      </div>
    );
  }
}
