import React , { Component } from "react";
import {
	Card,
	CardBody,Col, Row, Container,CardTitle


} from 'reactstrap';

import UnidadesListado from './lista-corta';
import {Button} from 'primereact/button';
import {Calendar} from 'primereact/calendar';
import Select from 'react-select';
import UnidadService  from 'controllers/unidad/unidadService';

import { Dropdown } from "primereact/dropdown";
import OperadorServices  from 'controllers/operador/operador-service';
import xlsx from "json-as-xlsx"
import { Panel } from 'primereact/panel';
import { Accordion, AccordionTab } from 'primereact/accordion';
import CheckPermissions from "controllers/permissions/services";

const tipo_unidades = [
	{value:"Tractocamión",label:"Tractocamión"},
	{value:"Remolque 53 pies",label:"Remolque 53 pies"},
    {value:"Remolque 48 pies",label:"Remolque 48 pies"},
    {value:"Camión Rabon",label:"Camión Rabon"},
    {value:"Camión Torton",label:"Camión Torton"},
    {value:"Camión 3.5 ton",label:"Camión 3.5 ton"},
    {value:"Pick up",label:"Pick up"},
    {value:"Vehículo utilitario",label:"Vehículo utilitario"},
    {value:"Motocicleta",label:"Motocicleta"},
    {value:"Dolly",label:"Dolly"}
]


const marca = [
{value:"Kenworth",label:"Kenworth"},
{value:"Freightliner",label:"Freightliner"},
{value:"International",label:"International"},
{value:"Wabash",label:"Wabash"},
{value:"Great Dane",label:"Great Dabe"},
{value:"Utility",label:"Utility"},
{value:"Stoughton",label:"Stoughton"},
{value:"Hyundai",label:"Hyundai"},
{value:"VW",label:"VW"},
{value:"Audi",label:"Audi"},
{value:"Ford",label:"Ford"},
{value:"etc",label:"etc"}

];

class ConsultaUnidades extends Component {
	constructor(props){
		super(props);

		this.state = {date: new Date(),
			eco:"",
			placas:"",
			tipo_unidad:"",
			marca:"",
			vin:[],
			cars:[],
			serie:""
		};
		this.buscar = this.buscar.bind(this);
		this.reset_buscar = this.reset_buscar.bind(this);
		this.unidadService = new UnidadService();
		this.servicioO = new OperadorServices();
		this.permissionService = new CheckPermissions();
		this.handleUnidadesTable = this.handleUnidadesTable.bind(this);
	}
	componentDidMount() {
		this.unidadService.getFiltroUnidades().then(data => this.setState({vin: data, cars:data}));
	}
	buscar(){
		let searchInput ={
			eco: this.state.eco,
			placas:this.state.placas,
			serie:this.state.serie
		}
		if(this.state.eco || this.state.placas || this.state.serie){
		this.handleUnidadesTable(searchInput);
		}

	}
	reset_buscar(){
		this.setState({eco:"", placas:"", serie:""});
		this.handleUnidadesTable();
	}

	handleUnidadesTable(params){
		this.unidadService.getFiltroUnidades(params).then(data => this.setState({cars:data}));
	}


	exportClick = () => {
		this.servicioO.getReports(2, 'unidades').then((response) => {
			let settings = {
				fileName: response.data.filename,
			  }
			xlsx(response.data.sheet, settings)
		})
	}

	render() {
		const es = {
		firstDayOfWeek: 1,
		dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
		dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
		dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
		monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
		monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"]
};
		return (
			<div class="container-fluid">
				<Panel header="Consulta De Unidades">
					<Row>
						<Col className="text-right mb-3"> 
							{this.permissionService.checkPermission('unidad_descargar_informacion') && <button onClick={this.exportClick} className="p-button p-button-warning">Descargar información de unidades</button>}
						</Col>
					</Row>
					<Accordion activeIndex={0} className="mb-3">
						<AccordionTab header="Filtrar">
							<Row>
								<Col xs="12" md="4">
									<div className="form-group">
										<label> Consultar por Número Económico:</label>
										<input type="text" value={this.state.eco} onChange={e => this.setState({eco:e.target.value})} className="form-control"/>
									</div>
								</Col>
								<Col xs="12" md="4">
									<div className="form-group">
										<label> Consultar por Número de Placas:</label>
										<input type="text" value={this.state.placas} onChange={e=>this.setState({placas:e.target.value})} className="form-control"/>
									</div>
								</Col>
								<Col xs="12" md="4">
									<div className="form-group">
										<label className="d-block"> Serie (VIN):</label>
										<Dropdown
											options={this.state.vin}
											editable={true}
											value={this.state.serie}
											onChange={(e) => this.setState({ serie: e.value.serie_vin })}
											placeholder="Seleccione un VIN"
											optionLabel="serie_vin" 
											/>
									</div>
								</Col>
							</Row>
							<Row>
								<Col className="text-right">
									<Button label="Buscar" onClick={this.buscar} className="p-button-raised p-button-warning mr-3 " /> 
									<Button label="Ver todo" onClick={this.reset_buscar} className="p-button-raised p-button-warning " />
								</Col>
							</Row>
						</AccordionTab>
					</Accordion>
					<div class="col-12 p-0 main_table">
						<Row className="col">
							<div class="w-100">
								<UnidadesListado {...this.state} parametros={this.state.search} history={this.props.history} handleUnidadesTable={this.handleUnidadesTable}/>
							</div>
						</Row>
					</div>
				</Panel>
			</div>
		);
	}
}

export default ConsultaUnidades;
