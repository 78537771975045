import React from "react";
import {
	Card,
	CardBody,Container, Row,Col,CardTitle

} from 'reactstrap';

import { Toast } from 'primereact/toast';
import moment from 'moment';
import Select from 'react-select';
import MantenimientoService from  'controllers/mantenimiento/mantenimiento-service';
const options = [];

class CrearReporteFalla extends React.Component {
	constructor(props){
		super(props);
		this.state={
			folio:"Sin asignar"
		}
		this.servicio = new MantenimientoService();

		this.handleSubmit = this.handleSubmit.bind(this);
	}


	componentDidMount() {
	  let values = this.props.location.state;
	  console.log(values);
	  if(values){
	    this.setState({
				folio:values.folio,
				marca:values.marca,
				orden:values.orden,

	      id:values.id,
	      titulo:"EDITAR",
	      sobreescribir: true
	    });
	  }

	}


	handleSubmit(event) {
				event.preventDefault();

				 console.log(this.state);
				let that = this;

				let datos ={};
				const data = new FormData();
				datos.folio = this.state.folio;
				datos.marca = this.state.marca;
				datos.orden = this.state.orden;


				data.append("ruta",JSON.stringify(datos));
				data.append("contrato",this.state.contrato);
				data.append("asignacion",this.state.asignacion);

				let mensajes = [];
				if(!this.state.marca	){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Ingrese la marca'});
				}
				if(!this.state.orden	){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Ingrese el número de orden de compra'});
				}

				if(mensajes.length){
					this.growl.show(mensajes);
				}else{

					let funcion = console.log;
					if(this.state.sobreescribir){
					  funcion = this.servicio.actualizarInventario;
					  datos.id = this.state.id;
					}else{
					  funcion = this.servicio.guardarInventario;
					}

					funcion(datos).then(res =>  {
							if(res && res.result == 'OK'){

								that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Información guardada correctamente'});
								setTimeout(function(){
										 	that.props.history.push('/app/mantenimiento/predictivo/inventario');
								 }, 500);

							}else if(res && res.result == 'EXISTE'){
								that.growl.show({severity: 'error', summary: 'El Id de ruta '+this.state.id_ruta+' ya se encuentra registrado', detail: 'Ingrese otro código o consulte su información para editarla.'});
							}
					});
				}
	}

	render() {

		return (
			<Card>
			<CardTitle  className="bg-light border-bottom p-3 mb-0">EDITAR A INVENTARIO</CardTitle>
				<CardBody>
				<Toast ref={(el) => this.growl = el} />
				<form >
				<Row>
					<Col xs="6">
							<div className="form-group">
								<label>Folio</label><br/>
									<input type="text" readOnly value={this.state.folio}  onChange={e => this.setState({folio : e.target.value})}  className="form-control"/>
							</div>

					</Col>
					<Col xs="6">
							<div className="form-group">
								<label>Marca</label>
								<input type="text"  value={this.state.marca} onChange={e => this.setState({marca : e.target.value})}  className="form-control"/>
							</div>
					</Col>
				</Row>
				<Row>
					<Col xs="6">
							<div className="form-group">
								<label>Número de orden de compra</label><br/>
									<input type="text"  value={this.state.orden} onChange={e => this.setState({orden : e.target.value})}  className="form-control"/>
							</div>

					</Col>
				</Row>




				<br/><br/><br/>


						<center> <button className="btn btn-success" onClick={this.handleSubmit} >Guardar</button></center>
					</form>
				</CardBody>
			</Card>
		);
	}
}

export default CrearReporteFalla;
