import React from "react";
import {
	Card,
	CardBody,Col,Row,Container,CardTitle

} from 'reactstrap';

import Select from 'react-select';

import Imagen from './tipos/camion3500';
import Imagen1 from './tipos/dolly';
import Imagen2 from './tipos/motocicleta';
import Imagen3 from './tipos/pickup';
import Imagen4 from './tipos/rabon';
import Imagen5 from './tipos/remolque_48';
import Imagen6 from './tipos/remolque_53';
import Imagen7 from './tipos/torton';
import Imagen8 from './tipos/tracto_camion';
import Imagen9 from './tipos/vehiculo_utiliario';


import LlantaService from 'controllers/llanta/llantaService';
import UnidadService  from 'controllers/unidad/unidadService';
import { Toast } from 'primereact/toast';
import {QRElement} from './qr-component';
import { Panel } from 'primereact/panel';


const tipo_unidades = [
	{value:"Tractocamión",label:"Tractocamión"},
	{value:"Remolque 53 pies",label:"Remolque 53 pies"},
    {value:"Remolque 48 pies",label:"Remolque 48 pies"},
    {value:"Camión Rabon",label:"Camión Rabon"},
    {value:"Camión Torton",label:"Camión Torton"},
    {value:"Camión 3.5 ton",label:"Camión 3.5 ton"},
    {value:"Pick up",label:"Pick up"},
    {value:"Vehículo utilitario",label:"Vehículo utilitario"},
    {value:"Motocicleta",label:"Motocicleta"},
    {value:"Dolly",label:"Dolly"}
]

const options = [
]

const tipo_posicion=[
	{value:"1",label:"1"},
	{value:"2",label:"2"},
	{value:"3",label:"3"},
	{value:"4",label:"4"},
	{value:"5",label:"5"},
	{value:"6",label:"6"},
	{value:"7",label:"7"},
	{value:"8",label:"8"},
	{value:"9",label:"9"},
	{value:"10",label:"10"},
];

class AsignacionLlantas extends React.Component {
	constructor(props){
		super(props);
		this.state= {
			visible:false,
			id_asignacion:"5",
			qr: 5
		}
		this.guardar = this.guardar.bind(this);
		this.servicio= new LlantaService()
		this.servicioU = new UnidadService();

		this.servicio.getLlantas().then(data =>  {
			let arrLlantas = [];
			data.forEach(item=>{
				arrLlantas.push({value:item.id,label:item.folio,obj:item});

			});
			this.setState({llantas: arrLlantas});
		});
		this.servicioU.getAllUnidades().then(data =>  {
			let arrUnidades = [];
			data.forEach(item=>{
				arrUnidades.push({value:item.id,label:item.eco,obj:item});

			});
			this.setState({unidades: arrUnidades});
		});
		this.seleccionar_llanta = this.seleccionar_llanta.bind(this);
		this.seleccionar_unidad = this.seleccionar_unidad.bind(this);
		this.cambiar = this.cambiar.bind(this);
		this.generarVista = this.generarVista.bind(this);
	}

	seleccionar_llanta(event){
		this.setState(
			{
				llanta_id : event.value,
				marca_llanta: event.obj.marca,
				medida:event.obj.medida,
				tipo_piso:event.obj.tipo_piso,
				profundidad:event.obj.profundidad,
				qr : event.label
			}
		);
	}

	seleccionar_unidad(event){
		this.setState({
			unidad_id : event.value,
			tipo_unidad:event.obj.tipo_unidad,
			placas:event.obj.placas,
			marca_unidad:event.obj.marca,
			modelo:event.obj.modelo
		});
	}

	guardar(e){
		e.preventDefault();

		let that  = this;
		this.servicio.asignarLlanta({llanta_id:this.state.llanta_id, unidad_id : this.state.unidad_id,posicion:this.state.posicion,km:this.state.km}).then(res =>  {
				if(res && res.result == 'OK'){
					that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Información guardada correctamente'});
					this.setState({visible:true});

					//id_asignacion:res.id_asignacion
				}

		});
	}

	generarVista(){
		console.log(this.state.tipo_unidad);
		 if(this.state.tipo_unidad =="Tractocamión"){
			 	return ( <Imagen8 cambiar={this.cambiar}/>);
		 }else if(this.state.tipo_unidad =="Remolque 53 pies"){
			 return ( <Imagen6 cambiar={this.cambiar}/>);
		 }else if(this.state.tipo_unidad =="Remolque 48 pies"){
			 return ( <Imagen5 cambiar={this.cambiar}/>);
		 }else if(this.state.tipo_unidad =="Camión Rabon"){
			 return ( <Imagen4 cambiar={this.cambiar}/>);
		 }else if(this.state.tipo_unidad =="Camión Torton"){
			 return ( <Imagen7 cambiar={this.cambiar}/>);
		 }else if(this.state.tipo_unidad =="Camión 3.5 ton"){
			 return ( <Imagen cambiar={this.cambiar}/>);
		 }else if(this.state.tipo_unidad =="Pick up"){
			 return ( <Imagen3 cambiar={this.cambiar}/>);
		 }else if(this.state.tipo_unidad =="Vehículo utilitario"){
			 return ( <Imagen9 cambiar={this.cambiar}/>);
		 }else if(this.state.tipo_unidad =="Motocicleta"){
			 return ( <Imagen2 cambiar={this.cambiar}/>);
		 }else if(this.state.tipo_unidad =="Dolly"){
			 return ( <Imagen1 cambiar={this.cambiar}/>);
		 }else {
			// return (<div/>)
			return (<Imagen9 cambiar={this.cambiar}/>);
		 }


	}

	cambiar(n){
		this.setState({posicion : n});
		this.growl.show({severity: 'info', summary: 'Información actualizada', detail: 'Se ha cambiado correctamente la posición'});

	}

	render() {
		return (
			<div class="container-fluid">
			<Panel header="Asignar Llanta">
				<Toast ref={(el) => this.growl = el} />

				<QRElement qr={this.state.qr} visible={this.state.visible} onClose={()=> this.props.history.push('/app/unidades/llantas/consultar')}/>

				<form>
				<Row>
					<Col md="4" sm="12" >
						<Row>
							<Col >
								<center>
									{this.generarVista()}
								</center>
							</Col>
						</Row>
					</Col>
					<Col md="8" sm="12" >
							<Row>
								<Col xs="12" md="4">
									<label>Folio EXNAC de llanta</label>
										<Select options={this.state.llantas} onChange={this.seleccionar_llanta} placeholder="Search" />
								</Col>
								<Col xs="12" md="4">
									<div className="form-group">
										<label>Marca</label>
										<input type="text" value={this.state.marca_llanta} className="form-control" readOnly/>
									</div>
								</Col>
								<Col xs="12" md="4">
									<div className="form-group">
										<label>Medida</label>
										<input type="text" value={this.state.medida} className="form-control" readOnly/>
									</div>
								</Col>
							</Row>

							<Row>
								<Col xs="12" md="4">

								</Col>
								<Col xs="12" md="4">
									<div className="form-group">
										<label>Tipo de piso</label>
										<input type="text" value={this.state.tipo_piso} className="form-control" readOnly/>
									</div>
								</Col>
								<Col xs="12" md="4">
									<div className="form-group">
										<label>Profundidad</label>
										<input type="text" value={this.state.profundidad} className="form-control" readOnly/>
									</div>
								</Col>
							</Row>

							<Row>
								<Col xs="12" md="4">
										<label>No. ECO de unidad</label>
										<Select options={this.state.unidades} onChange={this.seleccionar_unidad} placeholder="Search"/>
								</Col>
								<Col xs="12" md="4">
									<div className="form-group">
										<label>Tipo de unidad</label>
										<input type="text" value={this.state.tipo_unidad} className="form-control" readOnly/>
									</div>
								</Col>
								<Col xs="12" md="4">
									<div className="form-group">
										<label>Placas</label>
										<input type="text" value={this.state.placas} className="form-control" readOnly/>
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs="12" md="4">

								</Col>
								<Col xs="12" md="4">
									<div className="form-group">
										<label>Marca</label>
										<input type="text" value={this.state.marca_unidad} className="form-control" readOnly/>
									</div>
								</Col>
								<Col xs="12" md="4">
									<div className="form-group">
										<label>Modelo</label>
										<input type="text" value={this.state.modelo} className="form-control" readOnly/>
									</div>
								</Col>
							</Row>
							<Row>
									<Col xs="12" md="6">
										<div className="form-group">
											<label>Posición</label>
											<Select value={tipo_posicion.filter(option=> option.value == this.state.posicion)} options={tipo_posicion} onChange={e=> this.setState({posicion : e.value})} placeholder="Elegir posición"/>
										</div>
									</Col>
									<Col xs="12" md="6">
										<div className="form-group">
											<label>KM Unidad</label>
											<input type="number" className="form-control" value={this.state.km} onChange={e=> this.setState({km:e.target.value})}/>
										</div>
									</Col>
									<Col xs="12" className="d-flex justify-content-center">
										<button type="submit" onClick={this.guardar} className="btn btn-primary" >Guardar</button>
									</Col>
							</Row>
						</Col>
					</Row>
				</form>
			</Panel>
			</div>
		);
	}
}

export default AsignacionLlantas;
