import React, { Component } from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {MultiSelect} from 'primereact/multiselect';
import BitacoraService  from 'controllers/bitacora/bitacoraService';
import { Button } from 'reactstrap';
import {Dialog} from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import {urlPdf} from '../../../constants';

export default class BitacoraEstadoListado extends Component {

    constructor() {
        super();
        this.state = {
            globalFilter: null,
            visible:false,
            visible2:false
        };
        this.servicio = new BitacoraService();
        this.editar = this.editar.bind(this);
        this.verBitacora = this.verBitacora.bind(this);
    }

    componentDidUpdate(prevProps){
      if(prevProps.idUsuario !== this.props.idUsuario){
        this.servicio.getEstadosBitacora(this.props.idUsuario).then(data=>{
          console.log(data);
          this.setState({estados:data.data});
        });
      }
    }



    editar(column){
      this.setState({visible:true,item:column.rowData});
    }

    verBitacora(column){
      window.open( "https://www.google.com/maps/?q="+column.rowData.latitud+","+ column.rowData.longitud ,"width=200,height=100");

    }



    actionTemplate(rowData, column) {
      return (<div>
        {column.rowData.latitud&& column.rowData.longitud?

        <Button
          type="button" onClick={e=> this.verBitacora(column)}
          >Ver mapa
          </Button>:null}
        </div>);
    }

    render() {

        return (<div>
            <Toast ref={(el) => this.growl = el} />
            <Dialog header="Listado de cambios"  visible={this.state.visible} style={{width: '50vw',overflow:'scroll'}} modal={true} onHide={() => this.setState({visible: false,visible2:false	})}>
              <div>Listado de todos los pendientes</div>
            </Dialog>
            <DataTable value={this.state.estados} paginator={true} rows={10} >
                <Column field="estado" header="Estado"  />
                <Column field="fecha_creacion" header="Fecha"  />
                <Column field="latitud" header="latitud"  />
                <Column field="longitud" header="longitud"  />
                <Column body={this.actionTemplate.bind(this)} header="Opciones" style={{ textAlign: 'center'}} />
            </DataTable>

      </div>);
    }
}
