import React, { Component } from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {MultiSelect} from 'primereact/multiselect';
import OperadorServices  from 'controllers/operador/operador-service';
import {Button} from 'primereact/button';
import EditarOperador from './editar';
import {Dialog} from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import CheckPermissions from 'controllers/permissions/services';

export default class OperadorListado extends Component {
    constructor(props) {
        super(props);
        this.state = {
            globalFilter: null,
            visible:false,
            visible2:false
        };
        this.servicioOperador = new OperadorServices();
        this.eliminar = this.eliminar.bind(this);
        this.editar = this.editar.bind(this);
        this.view_documents = this.view_documents.bind(this);
        this.permissionService = new CheckPermissions();
    }

    componentDidMount() {
      this.props.handleOperadoresTable(this.props.estado);
    }

    eliminar(column,rowData){
      this.setState({visible2:true,selectedItemIndex:column.rowIndex,selectedItem:rowData});

    }

    editar(rowData){
      this.props.history.push({
         pathname:'/app/operador/editar',
         state: rowData
       });
    }
    view_documents(column){
      this.props.history.push({
         pathname:'/app/operador/documents',
         state: column.rowData
       });
    }

    confirmar_borrado(){

      this.setState({visible2:false});
      let that = this;
      this.servicioOperador.eliminarOperador(this.state.selectedItem.usuario_id).then(res =>  {
          if(res && res.result == 'OK'){
            this.setState({
              cars: this.props.cars.filter((val,i) => i !== this.state.selectedItemIndex)
            });
            that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Registro borrado'});
            this.props.handleOperadoresTable(this.props.estado);
          }else{
            that.growl.show({severity: 'error', summary: 'no puedes borrar este registro', detail: res.error});
          }
      });

    }

    numareTemplate(rowData, column) {
      return <p>{rowData.nombre+' '+rowData.apellido_paterno +' '+ rowData.apellido_materno}</p>
    }
    actionTemplate(rowData, column) {
      return (<div>
        {this.permissionService.checkPermission('operador_editar') && <Button
          type="button" 
          title='Editar' 
          icon="pi pi-pencil" 
          onClick={e=> this.editar(rowData)}
          className="p-button-warning mr-2"
          tooltip="Editar"
          tooltipOptions={{ position: 'top' }}
        />}
        {this.permissionService.checkPermission('operador_borrar') && <Button
          type="button" 
          icon="pi pi-trash" 
          onClick={e=> this.eliminar(column,rowData)}
          className="p-button-warning"
          tooltip="Borrar"
          tooltipOptions={{ position: 'top' }}
        />}
      </div>);
    }
    documentTemplate(rowData, column) {
      return (<div>
        {this.permissionService.checkPermission('operador_documents') && <Button
          type="button" 
          icon="pi pi-file" 
          onClick={e=> this.view_documents(column)}
          className="p-button-warning"
          tooltip="Ver documentos"
          tooltipOptions={{ position: 'top' }}
          />}
      </div>);
    }

    render() {
      // const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
      // const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;
        const footer = (
          <div>
                <Button label="Sí" className='p-button-warning mr-2' icon="pi pi-check" onClick={this.confirmar_borrado.bind(this)} />
                <Button label="No" className='p-button-warning' icon="pi pi-times" onClick={e=> this.setState({visible2:false})}/>
            </div>

        );

        return (<div>
            <Toast ref={(el) => this.growl = el} />
            <Dialog header="Editar"  visible={this.state.visible} style={{width: '50vw',height:'90vh',overflow:'scroll'}} modal={true} onHide={() => this.setState({visible: false,visible2:false	})}>
              <EditarOperador values={this.state.item}  growl={this.growl} onSaved={()=> this.setState({visible:false})}/>
            </Dialog>
            <Dialog header="Eliminar"  footer={footer} visible={this.state.visible2} style={{width: '50vw'}} modal={true} onHide={() => this.setState({visible: false,visible2:false	})}>
              ¿Confirma eliminar?
            </Dialog>
            <DataTable value={this.props.cars} paginator={true}
    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10}>
            <Column field="numero_empleado" header="Número de empleado"/>
            <Column field="fecha_ingreso" header="Fecha de ingreso" />

            <Column field="nombre" header="Nombre" />
            <Column field="apellido_paterno" header="Apellido paterno" />
            <Column field="apellido_materno" header="Apellido materno" />

            <Column field="fecha_nacimiento" header="Fecha de nacimiento"  />
            <Column field="estado_civil" header="Estado Civil"  />
            <Column body={this.documentTemplate.bind(this)} header="Documentos" style={{ textAlign: 'center'}} />

            <Column body={this.actionTemplate.bind(this)} header="Opciones" style={{ textAlign: 'center'}} />
            </DataTable>

      </div>);
    }
}
