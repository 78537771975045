import React, { Component } from "react";
import { Card, CardBody, Container, Col, Row, CardTitle } from "reactstrap";
import Select from "react-select";
import { Button } from "primereact/button";
import ClienteService from "controllers/cliente/clienteservice";
import { Toast } from "primereact/toast";
import { Fieldset } from "primereact/fieldset";
import { InputMask } from "primereact/inputmask";
import CustomAddress from "../CustomAddress";
import _ from "lodash";
const options = [
  { value: "MXN", label: "MXN" },
  { value: "USD", label: "USD" },
];

const paises = [
  { value: "México", label: "México" },
  { value: "Estados Unidos", label: "Estados Unidos" },
];

const status = [
  { value: "1", label: "Activo" },
  { value: "0", label: "Inactivo" },
];

const estados = [
  { value: "Aguascalientes", label: "Aguascalientes" },
  { value: "Baja California", label: "Baja California" },
  { value: "Baja California Sur", label: "Baja California Sur" },
  { value: "Campeche", label: "Campeche" },
  { value: "Coahuila de Zaragoza", label: "Coahuila de Zaragoza" },
  { value: "Colima", label: "Colima" },
  { value: "Chiapas", label: "Chiapas" },
  { value: "Chihuahua", label: "Chihuahua" },
  { value: "Durango", label: "Durango" },
  { value: "Ciudad de México", label: "Ciudad de México" },
  { value: "Guanajuato", label: "Guanajuato" },
  { value: "Guerrero", label: "Guerrero" },
  { value: "Hidalgo", label: "Hidalgo" },
  { value: "Jalisco", label: "Jalisco" },
  { value: "México", label: "México" },
  { value: "Michoacán de Ocampo", label: "Michoacán de Ocampo" },
  { value: "Morelos", label: "Morelos" },
  { value: "Nayarit", label: "Nayarit" },
  { value: "Nuevo León", label: "Nuevo León" },
  { value: "Oaxaca", label: "Oaxaca" },
  { value: "Puebla", label: "Puebla" },
  { value: "Querétaro", label: "Querétaro" },
  { value: "Quintana Roo", label: "Quintana Roo" },
  { value: "San Luis Potosí", label: "San Luis Potosí" },
  { value: "Sinaloa", label: "Sinaloa" },
  { value: "Sonora", label: "Sonora" },
  { value: "Tabasco", label: "Tabasco" },
  { value: "Tamaulipas", label: "Tamaulipas" },
  { value: "Tlaxcala", label: "Tlaxcala" },
  {
    value: "Veracruz de Ignacio de la Llave",
    label: "Veracruz de Ignacio de la Llave",
  },
  { value: "Yucatán", label: "Yucatán" },
  { value: "Zacatecas", label: "Zacatecas" },
  { value: "Otro", label: "Otro" },
];

class EditarCliente extends React.Component {
  constructor(props) {
    super(props);

    console.log('this.props- clientes', this.props)
    this.state = {
      razon_social: "",
      rfc: "",
      identidad_tributaria: "",
      moneda: "",
      calle: "",
      colonia: "",
      municipio: "",
      estado: "",
      numero: "",
      codigo_postal: "",
      nombre_contacto: "",
      telefono_contacto: "",
      email_contacto: "",
      codigo_cliente: "",
      // nacionalidad: "", pais in database
      localidad: "",
      address_values: {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.servicio = new ClienteService();
  }
  componentDidMount() {
    this.setState({
      id: this.props.values.id,
      razon_social: this.props.values.razon_social,
      rfc: this.props.values.rfc,
      identidad_tributaria: this.props.values.identidad_tributaria,
      moneda: this.props.values.moneda,
      calle: this.props.values.calle,
      numero: this.props.values.numero,
      codigo_postal: this.props.values.codigo_postal,
      colonia: this.props.values.colonia,
      municipio: this.props.values.municipio,
      estado: this.props.values.estado,
      pais: this.props.values.pais,
      codigo_cliente: this.props.values.codigo_cliente,
      nombre_contacto: this.props.values.nombre_contacto,
      telefono_contacto: this.props.values.telefono_contacto,
      email_contacto: this.props.values.email_contacto,
      nombre_contacto_cuentas: this.props.values.nombre_contacto_cuentas,
      telefono_contacto_cuentas: this.props.values.telefono_contacto_cuentas,
      email_contacto_cuentas: this.props.values.email_contacto_cuentas,
      pagina_web: this.props.values.pagina_web,
      estatus: this.props.values.estatus,
      certificado: this.props.values.certificado,
      address_values: this.props.values.address_values,
      localidad: this.props.values.localidad,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    // this.setState(
    // (prevState) => {
    //   return {
    //     estatus: +this.prevState.estatus,
    //   };
    // },
    //   { estatus: +this.prevState.estatus },
    //   () => console.log("editar estatus after handleSubmit", this.state)
    // );
    let data = { ...this.state };
    data.estatus = +data.estatus;
    data = { ...data, ...JSON.parse(data.address_values) };
    delete data.address_values;
    data.estado = data.estado.value;
    delete data.localidad;
    delete data.is_SAT_Localidad;
    delete data.is_non_location;
    delete data.value_nombre;
    delete data.is_nombre;
    delete data.value_costo_del_stop;
    delete data.is_costo_del_stop;
    console.log("editar state before submit ", this.state, "data", data);
    let that = this;
    this.servicio.actualizarCliente(data).then((res) => {
      if (res && res.result == "OK") {
        that.growl.show({
          severity: "success",
          summary: "Operación completada",
          detail: "Información guardada correctamente",
        });
        //that.props.onSaved();

        setTimeout(function(){
					that.props.history.push('/app/clientes/consulta');
				}, 500);
      }
    });
  }

  // seleccionar_nacionalidad = (event) => {
  //   if (event.value === "Nacional") {
  //     this.setState({
  //       identidad_tributaria: "",
  //       nacionalidad: event.value,
  //       rfc: "",
  //     });
  //   } else {
  //     this.setState({
  //       identidad_tributaria: "",
  //       nacionalidad: event.value,
  //       rfc: "XEXX010101001",
  //     });
  //   }
  // };

  ReceiveAddress = async (data) => {
    await this.setState({ address_values: JSON.stringify(data) });
  };

  render() {
    return (
      <div>
        <Toast ref={(el) => (this.growl = el)} />
        <Container>
          <Row>
            <Col>
              <form onSubmit={this.handleSubmit}>
                <Row>
                  <Col xs="6">
                    <div className="form-group">
                      <label>Razón social</label>
                      <input
                        type="text"
                        maxlength="200"
                        size="200"
                        required
                        value={this.state.razon_social}
                        onChange={(e) =>
                          this.setState({ razon_social: e.target.value })
                        }
                        name="razon_social"
                        className="form-control"
                      />
                    </div>
                  </Col>
                  <Col xs="6">
                    <div className="form-group">
                      <label>R.F.C.</label>
                      <input
                        type="text"
                        maxlength="45"
                        size="45"
                        required
                        value={this.state.rfc}
                        onChange={(e) => this.setState({ rfc: e.target.value })}
                        name="razon_social"
                        className="form-control"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <div className="form-group">
                      <label>Moneda</label>
                      <Select
                        options={options}
                        value={options.filter(
                          (option) => option.label === this.state.moneda
                        )}
                        required="true"
                        onChange={(e) => this.setState({ moneda: e.value })}
                        placeholder="Seleccione una opción"
                      />
                    </div>
                  </Col>
                  {/* <Col xs="6">
									<div className="form-group">
										<label>Identidad tributaria</label>
										<input type="text" required maxlength="45" size="45" value={this.state.identidad_tributaria} value={this.state.identidad_tributaria} onChange={(e) => this.setState({ identidad_tributaria: e.target.value })} name="identidad_tributaria" className="form-control" />
									</div>
								</Col> */}
                </Row>
                <Row>
                  <Col xs="6">
                    <div className="form-group">
                      <label>Calle</label>
                      <input
                        type="text"
                        required
                        maxlength="200"
                        size="200"
                        name="calle"
                        value={this.state.calle}
                        onChange={(e) =>
                          this.setState({ calle: e.target.value })
                        }
                        className="form-control"
                      />
                    </div>
                  </Col>
                  <Col xs="6">
                    <div className="form-group">
                      <label>Número</label>
                      <input
                        type="text"
                        required
                        maxlength="10"
                        size="10"
                        name="numero"
                        value={this.state.numero}
                        onChange={(e) =>
                          this.setState({ numero: e.target.value })
                        }
                        className="form-control"
                      />
                    </div>
                  </Col>
                </Row>
                <CustomAddress
                  values={this.props.values}
                  senderAdressToParent={this.ReceiveAddress}
                  isComisionAOperador={"false"}
                />
                <Row>
                  {/* <Col xs="6">
									<div className="form-group">
										<label>Código postal</label>
										<input type="text" name="codigo_postal" maxlength="10" size="10" required value={this.state.codigo_postal} onChange={(e) => this.setState({ codigo_postal: e.target.value })} className="form-control" />
									</div>
								</Col> */}
                  {/* <Col xs="6">
									<div className="form-group">
										<label>Código de cliente</label>
										<input type="text" name="codigo_cliente" maxlength="20" size="20" required value={this.state.codigo_cliente} onChange={(e) => this.setState({ codigo_cliente: e.target.value })} className="form-control" />
									</div>
								</Col> */}
                </Row>
                <Row>
                  {/* <Col xs="6">
									<div className="form-group">
										<label>Página Web</label>
										<input type="text" name="pagina_web" maxlength="100" size="100" required value={this.state.pagina_web} onChange={(e) => this.setState({ pagina_web: e.target.value })} className="form-control" />
									</div>
								</Col> */}
                  <Col xs="6">
                    <div className="form-group">
                      <label>Status</label>
                      <Select
                        options={status}
                        required="true"
                        value={status.filter(
                          (option) => option.value == this.state.estatus
                        )}
                        onChange={(e) => this.setState({ estatus: e.value })}
                        placeholder="Seleccione una opción"
                      />
                    </div>
                    -
                  </Col>
                </Row>
                {/* <Row>
								<Col xs="6">
									<div className="form-group">
										<label>Validación de certificación</label><br />
										<div className="form-group">
											<br />
											<div className="custom-file-upload">
												<label for="file-upload1" className="custom-file-upload1 btn btn-info">
													<i className="pi pi-cloud-upload"></i> Seleccionar archivo
												</label>
												<input id="file-upload1" type="file" className="btn btn-default" style={{ display: "none" }} onChange={e => this.setState({ archivo_certificacion: e.target.files[0] })} chooseLabel="Seleccionar Archivo" />
											</div>

										</div>
									</div>
								</Col>

								<Col xs="6">
									<div className="form-group">
										<label>Número de certificación</label>
										<input type="text" name="certificado" maxlength="100" size="100" required value={this.state.certificado} onChange={(e) => this.setState({ certificado: e.target.value })} className="form-control" />
									</div>
								</Col>

							</Row>
							<Fieldset legend="AREA CORPORATIVA">
								<Row>
									<Col xs="6">
										<div className="form-group">
											<label>Nombre de contacto</label>
											<input type="text" name="nombre_contacto" maxlength="100" size="100" required value={this.state.nombre_contacto} onChange={(e) => this.setState({ nombre_contacto: e.target.value })} className="form-control" />
										</div>
										<div className="form-group">
											<label>Correo de contacto</label>
											<input type="text" name="email_contacto" maxlength="100" size="100" required value={this.state.email_contacto} onChange={(e) => this.setState({ email_contacto: e.target.value })} className="form-control" />
										</div>
									</Col>
									<Col xs="6">
										<div className="form-group">
											<label>Teléfono de contacto</label>
											<InputMask mask="(99) 9999-9999" required value={this.state.telefono_contacto} onChange={(e) => this.setState({ telefono_contacto: e.target.value })} className="form-control" />
										</div>
									</Col>

								</Row>
							</Fieldset>



							<Fieldset legend="CUENTAS POR PAGAR">
								<Row>
									<Col xs="6">
										<div className="form-group">
											<label>Nombre de contacto</label>
											<input type="text" name="nombre_contacto_cuentas" maxlength="100" size="100" value={this.state.nombre_contacto_cuentas} required value={this.state.nombre_contacto_cuentas} onChange={(e) => this.setState({ nombre_contacto_cuentas: e.target.value })} className="form-control" />
										</div>
										<div className="form-group">
											<label>Correo de contacto</label>
											<input type="text" name="email_contacto_cuentas" maxlength="100" size="100" value={this.state.email_contacto_cuentas} required value={this.state.email_contacto_cuentas} onChange={(e) => this.setState({ email_contacto_cuentas: e.target.value })} className="form-control" />
										</div>
									</Col>
									<Col xs="6">
										<div className="form-group">
											<label>Teléfono de contacto</label>
											<InputMask mask="(99) 9999-9999" required value={this.state.telefono_contacto_cuentas} onChange={(e) => this.setState({ telefono_contacto_cuentas: e.target.value })} className="form-control" />

										</div>
									</Col>

								</Row>
							</Fieldset> */}

                <center>
                  {" "}
                  <Button
                    className="p-button-warning"
                    onClick={(e) => {
                      this.setState(
                        { estatus: parseInt(this.state.estatus) },
                        this.handleSubmit(e)
                      );
                    }}
                    // onClick={() => {
                    //   console.log("state", this.state);
                    // }}
                  >
                    Guardar
                  </Button>
                </center>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default EditarCliente;
