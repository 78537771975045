import React from "react";
import {
    Card,
    CardBody, Container, Col, Row

} from 'reactstrap';
import { Calendar } from 'primereact/calendar';
import Select from 'react-select';
import EmbarqueService from 'controllers/embarque/embarque-service';
import { Toast } from 'primereact/toast';
import moment from 'moment';
import { AutoComplete } from 'primereact/autocomplete';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import size from 'lodash/size'
const options = [
    { value: "1", label: "Por liquidar" },
    { value: "2", label: "Liquidado" },
]


export default class CobranzaAdd extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            globalFilter: null,
            embarques_data: [],
            embarques: "",
            is_update: false,
            update_data: {},
            importe_de_pago_1: '',
            importe_de_pago_2: '',
            importe_de_pago_3: '',
            numero_de_evidencia: ''
        };
        this.service = new EmbarqueService();

    }

    componentDidMount() {
        let state_data = this.props['location']['state'] || {}
        if (size(state_data) && state_data['id']) {
            this.setState({
                is_update: true,
                update_data: state_data,
                id: state_data['id'],
                embarques: JSON.parse(state_data['embarques']),
                numero_de_evidencia: state_data['numero_de_evidencia'] || '',
                fecha_de_pago_1: state_data['fecha_de_pago_1'] || '',
                importe_de_pago_1: state_data['importe_de_pago_1'] || '',
                importe_de_pago_2: state_data['importe_de_pago_2'] || '',
                importe_de_pago_3: state_data['importe_de_pago_3'] || '',
                fecha_de_pago_2: state_data['fecha_de_pago_2'] || '',
                fecha_de_pago_final: state_data['fecha_de_pago_final'] || '',
                estatus: state_data['estatus'] || ""
            })
        }

        this.service.getEmbarquesIDS("").then(data => {
            this.setState({ embarques_data: data })
        })
    }

    changeEmbarques = (e) => {
        console.log("CobranzaAdd -> changeEmbarques -> e", e)
        this.service.getEmbarquesIDS(e.query).then(data => {
            if (data) {
                this.setState({ embarques_data: data })
            } else {
                this.setState({ embarques_data: [] })
            }
        });
    }

    handleSubmit = () => {
        // console.log(this.state);
        let data = {}
        data['embarques'] = JSON.stringify(this.state['embarques'])
        data['numero_de_evidencia'] = this.state['numero_de_evidencia']
        data['fecha_de_pago_1'] = this.state['fecha_de_pago_1']
        data['fecha_de_pago_2'] = this.state['fecha_de_pago_2']
        data['fecha_de_pago_final'] = this.state['fecha_de_pago_final']
        data['estatus'] = this.state['estatus']
        data = {...data,...{importe_de_pago_1: this.state['importe_de_pago_1'] || '',
        importe_de_pago_2: this.state['importe_de_pago_2'] || '',
        importe_de_pago_3: this.state['importe_de_pago_3'] || ''}}
        let mensajes = [];
        if (!this.state.embarques) {
            mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione embarques' });
        }
        if (!this.state.fecha_de_pago_1) {
            mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione embarques' });
        }
        if (!this.state.fecha_de_pago_2) {
            mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione embarques' });
        }
        if (!this.state.fecha_de_pago_final) {
            mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione fecha de pago final' });
        }
        if (!this.state.estatus) {
            mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione estatus' });
        }
        if (mensajes.length) {
            this.growl.show(mensajes);
        } else {
            let that = this;
            if (this.state.is_update) {
                data['id'] = this.state.id
                this.service.editCobranza(data).then(res => {
                    if (res && res.result == 'OK') {
                        that.growl.show({ severity: 'success', summary: 'Operación completada', detail: 'Información corbanza correctamente' });
                    } else if (res && res.result == 'EXISTE') {
                        // if (res.repetidos.tender) {
                        //     that.growl.show({ severity: 'error', summary: 'El cliente ' + that.state.tender + ' ya se encuentra registrado', detail: 'Número de embarque value already existe .' });
                        // }
                    }
                });
            } else {
                this.service.guardarCobranza(data).then(res => {
                    if (res && res.result == 'OK') {
                        that.growl.show({ severity: 'success', summary: 'Operación completada', detail: 'Información corbanza correctamente' });
                    } else if (res && res.result == 'EXISTE') {
                        // if (res.repetidos.tender) {
                        //     that.growl.show({ severity: 'error', summary: 'El cliente ' + that.state.tender + ' ya se encuentra registrado', detail: 'Número de embarque value already existe .' });
                        // }
                    }
                });
            }
        }
    }
    actionTemplate = (rowData, column) => {
        return (<div>
            <Button
                type="button" icon="pi pi-pencil" onClick={e => this.editar(column)}
                className="" /><Button
                type="button" icon="pi pi-trash" onClick={e => this.eliminar(column)}
                className="p-button-warning"
            />
        </div>);
    }

    render() {
        console.log(this.state.embarques_data)
        const es = {
            firstDayOfWeek: 1,
            dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
            dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
            dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
            monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
            monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"]
        };
        const footer = (
            <div>
                <Button label="Sí" className='p-button-warning mr-2' icon="pi pi-check" onClick={this.imprimirCartaPorte} />
                <Button label="No" className='p-button-warning' icon="pi pi-times" onClick={e => this.setState({ visible: false })} />
            </div>
        );

        return (<div>
            {/* <Dialog header="Carta Porte" footer={footer} visible={this.state.visible} style={{ width: '50vw' }} modal={true} onHide={() => this.setState({ visible: false })}>
                ¿Desea imprimir su carta porte con folio {this.state.folio}?
            </Dialog> */}
            <Toast ref={(el) => this.growl = el} />
            <Container>
                <div className="bg-white p-4">
                    <Row>
                        <Col xs="6">
                            <div className="form-group">
                                <div>Embarque</div>
                                <AutoComplete
                                    dropdown
                                    className="w-100"
                                    value={this.state.embarques}
                                    suggestions={this.state.embarques_data}
                                    completeMethod={this.changeEmbarques}
                                    field="folio"
                                    onChange={(e) => this.setState({ embarques: e.value,numero_de_evidencia:  e.value.carta_porte_id|| ''})}
                                />
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="form-group">
                                <div >Carta porte </div>
                                <InputText className="w-100" value={this.state.numero_de_evidencia} onChange={(e) => this.setState({ numero_de_evidencia: e.target.value })} />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="6">
                            <div className="form-group">
                                <div>Fecha de pago 1</div>
                                <Calendar className="w-100"
                                     
                                    value={this.state.fecha_de_pago_1 ? moment(this.state.fecha_de_pago_1, 'YYYY-MM-DD').toDate() : null}
                                    onChange={(e) => { this.setState({ fecha_de_pago_1: moment(e.value).format('YYYY-MM-DD').toString(), semana: moment(e.value).week() }); }}
                                    showIcon={true} placeholder="dd/mm/aaaa" />
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="form-group">
                                <div>importe de pago 1</div>
                                <InputText className="w-100" value={this.state.importe_de_pago_1} onChange={(e) => this.setState({ importe_de_pago_1: e.target.value })} />
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="form-group">
                                <div>Fecha de pago 2 </div>
                                <Calendar className="w-100"
                                     
                                    value={this.state.fecha_de_pago_2 ? moment(this.state.fecha_de_pago_2, 'YYYY-MM-DD').toDate() : null}
                                    onChange={(e) => { this.setState({ fecha_de_pago_2: moment(e.value).format('YYYY-MM-DD').toString(), semana: moment(e.value).week() }); }}
                                    showIcon={true} placeholder="dd/mm/aaaa" />
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="form-group">
                                <div>importe de pago 2 </div>
                                <InputText className="w-100" value={this.state.importe_de_pago_2} onChange={(e) => this.setState({ importe_de_pago_2: e.target.value })} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <div className="form-group">
                                <div>Fecha de pago final </div>
                                <Calendar
                                    className="w-100"
                                     
                                    value={this.state.fecha_de_pago_final ? moment(this.state.fecha_de_pago_final, 'YYYY-MM-DD').toDate() : null}
                                    onChange={(e) => { this.setState({ fecha_de_pago_final: moment(e.value).format('YYYY-MM-DD').toString(), semana: moment(e.value).week() }); }}
                                    showIcon={true} placeholder="dd/mm/aaaa" />
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="form-group">
                                <div>importe de pago 3</div>
                                <InputText className="w-100" value={this.state.importe_de_pago_3} onChange={(e) => this.setState({ importe_de_pago_3: e.target.value })} />
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="form-group">
                                <div>Estatus </div>
                                <Select
                                    options={options}
                                    value={options.filter(option => option.value == this.state.estatus)}
                                    onChange={(e) => this.setState({ estatus: e.value })}
                                    placeholder="Seleccione un estado" />
                            </div>
                        </Col>
                    </Row>
                    <center>
                        <button type="submit" onClick={() => { this.handleSubmit() }} className="btn btn-success" >Guardar</button>
                    </center>
                </div>
            </Container>
        </div>);
    }
}
