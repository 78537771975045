import React from "react";
import {
	Card,
	CardBody, Col, Row, Container, CardTitle

} from 'reactstrap';
import { Button } from 'primereact/button';
import OperadorListado from "./lista-corta";
import { TabView, TabPanel } from 'primereact/tabview';
import saveExcel from './report';
import OperadorService from 'controllers/operador/operador-service';
import { Panel } from 'primereact/panel';
import { Accordion, AccordionTab } from 'primereact/accordion';
import CheckPermissions from "controllers/permissions/services";

class ConsultaOperadores extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			numero_empleado: "",
			nombre: "",
			apellido_paterno: "",
			apellido_materno: "",
			activeIndex:0,
			estadoStatus: 1,
		}
		this.servicio = new OperadorService()
		this.permissionService = new CheckPermissions()
		this.handleOperadoresTable = this.handleOperadoresTable.bind(this)
	}

	handleOperadoresTable(estatus){
		const parametros = {
			numero_empleado: this.state.numero_empleado,
			nombre: this.state.nombre,
			apellido_paterno: this.state.apellido_paterno,
			apellido_materno: this.state.apellido_materno,
			estatus: estatus
		}

		this.servicio.getFiltroOperadores(parametros)
		  .then((data) => {this.setState({cars: data, estadoStatus:estatus})})
	}

	verTodo = () =>{
        this.setState({
			numero_empleado: "",
			nombre: "",
			apellido_paterno: "",
			apellido_materno: "",
		})

		const parametros = {
			estatus: this.state.estadoStatus
		}

		this.servicio.getFiltroOperadores(parametros)
			.then((data) => this.setState({ cars: data }));
	}

	download_report = () => {
		this.servicio.DownloadService().then(res => {
			saveExcel(res)
		})
	}

	render() {
		return (
			<div class="container-fluid">
			<Panel header="Consultar Operadores">
				<Accordion activeIndex={0} className="mb-3">
					<AccordionTab header="Filtrar">
						<Row>
							<Col xs="12" md="6">
								<div className="form-group">
									<label>Consultar por número de empleado </label>
									<input type="text" value={this.state.numero_empleado} onChange={e => this.setState({ numero_empleado: e.target.value })} className="form-control" />
								</div>
							</Col>
							<Col xs="12" md="6">
								<div className="form-group">
									<label>Consultar por Nombre </label>
									<input type="text" value={this.state.nombre} onChange={e => this.setState({ nombre: e.target.value })} className="form-control" />
								</div>
							</Col>
							<Col xs="12" md="6">
								<div className="form-group">
									<label>Consultar por Apellido Paterno </label>
									<input type="text" value={this.state.apellido_paterno} onChange={e => this.setState({ apellido_paterno: e.target.value })} className="form-control" />
								</div>
							</Col>
							<Col xs="12" md="6">
								<div className="form-group">
									<label>Consultar por Apellido Materno</label>
									<input type="text" value={this.state.apellido_materno} onChange={e => this.setState({ apellido_materno: e.target.value })} className="form-control" />
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className="d-flex justify-content-end">
									<Button label="Buscar" onClick={() => this.handleOperadoresTable(this.state.estadoStatus)} className="p-button p-button-warning" />
									<Button label="Ver todo" onClick={this.verTodo} className="mx-3 p-button p-button-warning" />
									{this.permissionService.checkPermission('operador_descargar') && <Button onClick={() => { this.download_report() }} label="Reporte de operadores" className="p-button p-button-warning" />}
								</div>
							</Col>
						</Row>
					</AccordionTab>
				</Accordion>
				<div class="main_table">
					<Row className="col">
						<TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
							<TabPanel header="Activos">
								<OperadorListado estado="1" history={this.props.history} {...this.state} handleOperadoresTable={this.handleOperadoresTable}/>
							</TabPanel>
							<TabPanel header="Inactivos">
								<OperadorListado estado="0" history={this.props.history} {...this.state} handleOperadoresTable={this.handleOperadoresTable}/>
							</TabPanel>
						</TabView>
					</Row>
				</div>
			</Panel>
			</div>
		);
	}
}

export default ConsultaOperadores;
