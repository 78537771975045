import Excel from 'exceljs';
import { saveAs } from 'file-saver';
const saveExcel = async (data) => {
    try {

        const workSheetName = 'Sheet';
        const workBookName = 'Reporte de operadores';
        const columns = [
            { header: "usuario_id", key: "usuario_id", width: 25 },
            { header: "numero_empleado", key: "numero_empleado", width: 25 },
            { header: "fecha_ingreso", key: "fecha_ingreso", width: 25 },
            { header: "fecha_nacimiento", key: "fecha_nacimiento", width: 25 },
            { header: "lugar_nacimiento", key: "lugar_nacimiento", width: 25 },
            { header: "nombre", key: "nombre", width: 25 },
            { header: "apellido_paterno", key: "apellido_paterno", width: 25 },
            { header: "estado_civil", key: "estado_civil", width: 25 },
        ];

        const workbook = new Excel.Workbook();

        const fileName = workBookName;
        const worksheet = workbook.addWorksheet(workSheetName);
        worksheet.columns = columns;
        worksheet.getRow(1).font = { bold: true };
        data.forEach(singleData => {
            worksheet.addRow(singleData);
        });
        // write the content using writeBuffer
        const buf = await workbook.xlsx.writeBuffer();
        // download the processed file
        saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
        console.error('<<<ERRROR>>>', error);
        console.error('Something Went Wrong', error.message);
    } 
};

export default saveExcel;