import axios from 'axios';
import {url} from '../../constants';

export default class Service {

	    getRemolques(){
        return axios.get(url + 'remolques')
                .then(res => res.data);
			}

}
