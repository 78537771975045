import React, { TextInput } from "react";
import {
	Card,
	CardBody, Col, Row, Container, CardTitle,

} from 'reactstrap';
import Select from 'react-select';
import ClienteService from 'controllers/cliente/clienteservice';
import OperadorServices from 'controllers/operador/operador-service';
import UnidadService from 'controllers/unidad/unidadService';
import CartaPorteService from 'controllers/carta-porte/carta-porte-service.js';
import { Toast } from 'primereact/toast';
import RemolqueServices from 'controllers/remolque/remolqueservice';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import RutaService from 'controllers/ruta/rutaservice';
import { urlPdf } from '../../../constants';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import moment from 'moment';
import _ from 'lodash'
const options = [
	{ value: "1", label: "Flete nacional" },
	{ value: "2", label: "Flete exportación" },
	{ value: "3", label: "Renta" }
]

const opciones_remitente = [
]
const opciones_destinatario = [
	{ label: "Destinatario de prueba 1", value: "Destinatario de prueba 1" }
]



class CrearCartaPorte extends React.Component {

	constructor(props) {
		super(props)
		this.servicio = new ClienteService();
		this.servicioU = new UnidadService();
		this.servicioO = new OperadorServices();
		this.servicioR = new RemolqueServices;
		this.servicioRuta = new RutaService();

		this.state = {
			clientes: [], rfc: "", unidades: [], exportacion_ck: true,
			visible: false,
			facturar_a: "",
			folio: "",
			remitente: "",
			destinatario: "",
			exportacion: "",
			remision: "",
			material_peligroso: false,
			operador_id: 0,
			unidad_id: "",
			remolque_id: "",
			_remitentes: [],
			_destinatarios: [],
			designaciones: [],
			ClaveUnidad:[],
			ClaveUnidad_value:{},
			designaciones_value:{},
			editableImporte: false,
			mostrar: 'inherit'
		};


		this.servicio.getClientes().then(data => {
			let arrClientes = [];
			data.forEach(item => {
				arrClientes.push({ value: item.id, label: item.razon_social, rfc: item.rfc });

			});
			this.setState({ clientes: arrClientes });
		});


		this.servicioU.getUnidades().then(data => {
			let arrUnidades = [];
			data.forEach(item => {
				arrUnidades.push({ value: item.id, label: item.placas });

			});
			this.setState({ unidades: arrUnidades });
		});

		this.servicioR.getRemolques().then(data => {
			let arrRemolques = [];
			data.forEach(item => {
				arrRemolques.push({ value: item.id, label: item.placas });

			});
			this.setState({ remolques: arrRemolques });
		});


		this.servicioO.getOperadores().then(data => {
			let arrOperadores = [];
			data.forEach(item => {
				arrOperadores.push({ value: item.usuario_id, label: item.nombre, unidad_id: item.unidad_id });

			});
			this.setState({ operadores: arrOperadores });
		});
		this.toggleCheckboxValue = this.toggleCheckboxValue.bind(this);


		this.handleSubmit = this.handleSubmit.bind(this);
		this.imprimirCartaPorte = this.imprimirCartaPorte.bind(this);
		this.servicioCarta = new CartaPorteService();
		this.showConfirm = this.showConfirm.bind(this);


		this.seleccionar_cliente = this.seleccionar_cliente.bind(this);
		this.seleccionar_ruta = this.seleccionar_ruta.bind(this);
		this.seleccionar_version = this.seleccionar_version.bind(this);
		this.seleccionar_remitente = this.seleccionar_remitente.bind(this);
		this.seleccionar_emision = this.seleccionar_emision.bind(this);

	}


	seleccionar_emision(event) {
		if (event.value == 3) {
			this.setState({ editableImporte: true });
		} else {
			this.setState({ editableImporte: false });
		}
		this.setState({ emision: event.value });


		// this.servicioCarta.obtenerFolio(event.value).then(data=> {
		// 	this.setState({folio:data[0].folio})
		// });
	}

	seleccionar_cliente(event) {
		this.setState({ rutas: [], ruta_id: "" });

		this.setState({ facturar_a: event.label, rfc: event.rfc, cliente_id: event.value })
		this.servicioRuta.getRutas(event.value).then(data => {
			let arrRutas = [];
			data.forEach(item => {
				arrRutas.push({ value: item.id, label: item.id_ruta });

			});
			this.setState({ rutas: arrRutas });
		});


	}

	seleccionar_ruta(event) {
		this.setState({ versiones: [], ruta_id: "" });
		this.setState({ ruta_id: event.value });
		// console.log(event.value);
		this.servicioRuta.getVersiones(event.value).then(data => {
			let arrVersiones = [];
			data.forEach(item => {
				arrVersiones.push(
					{ value: item.id, label: item.version, obj: item });

			});
			this.setState({ versiones: arrVersiones });
		});



	}


	seleccionar_version(event) {
		let arrStops = [];
		if (event.obj.direccion_origen) {
			arrStops[0] = { label: event.obj.direccion_origen, value: event.obj.direccion_origen };
		}
		this.setState({ remitente: event.obj.direccion_origen });
		if (event.obj.direccion_final) {
			arrStops[11] = { label: event.obj.direccion_final, value: event.obj.direccion_final };
			this.setState({ destinatario: event.obj.direccion_origen });
		}
		if (event.obj.stop1)
			arrStops[1] = { label: event.obj.stop1, value: event.obj.stop1 };
		if (event.obj.stop2)
			arrStops[2] = { label: event.obj.stop2, value: event.obj.stop2 };
		if (event.obj.stop3)
			arrStops[3] = { label: event.obj.stop3, value: event.obj.stop3 };
		if (event.obj.stop4)
			arrStops[4] = { label: event.obj.stop4, value: event.obj.stop4 };
		if (event.obj.stop5)
			arrStops[5] = { label: event.obj.stop5, value: event.obj.stop5 };
		if (event.obj.stop6)
			arrStops[6] = { label: event.obj.stop6, value: event.obj.stop6 };
		if (event.obj.stop7)
			arrStops[7] = { label: event.obj.stop7, value: event.obj.stop7 };
		if (event.obj.stop8)
			arrStops[8] = { label: event.obj.stop8, value: event.obj.stop8 };
		if (event.obj.stop9)
			arrStops[9] = { label: event.obj.stop9, value: event.obj.stop9 };
		if (event.obj.stop10)
			arrStops[10] = { label: event.obj.stop10, value: event.obj.stop10 };
		this.setState({
			stops: arrStops, version_id: event.value,
			_destinatarios: arrStops,
			_remitentes: arrStops
		});
	}

	showConfirm(folio) {
		this.growl.show({
			severity: 'info', sticky: true, content: (
				<div className="p-flex p-flex-column" style={{ flex: '1' }}>
					<div className="p-text-center">
						<i className="fa fa-check" style={{ fontSize: '3rem' }}></i>
						<h4>Operación completada</h4>

						<p>Información guardada correctamente</p>
						<p>Folio asignado: <b>{folio}</b></p>
					</div>
					<div className="p-grid p-fluid">
						<div className="p-col-6">
							<Button type="button" onClick={e => this.props.history.push('/app/carta-porte/consulta')} label="OK" className="btn btn-success" />
						</div>

					</div>
				</div>
			)
		});
	}


	imprimirCartaPorte() {
		window.open(urlPdf + 'carta_porte.php?folio=' + this.state.folio, "width=200,height=100");
		this.setState({ visible: false });
	}

	handleSubmit(event) {
		event.preventDefault();
		// console.log(this.state);
		let that = this;

		let mensajes = []

		if (!this.state.facturar_a) {
			mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione 	un cliente' });
		}

		if (!this.state.rfc) {
			mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'No se encontró el RFC del cliente' });
		}
		if (!this.state.emision) {
			mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione el tipo de emisióm' });
		}

		if (!this.state.ruta_id) {
			mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione una ruta' });
		}
		if (!this.state.version_id) {
			mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione una versión de ruta' });
		}
		if (!this.state.operador_id) {
			mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione un operador' });
		}


		if (!this.state.unidad_id) {
			mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione una unidad' });
		}
		if (!this.state.remolque_id) {
			mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione un remolque' });
		}
		if (!_.size(this.state.designaciones_value)) {
			mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione un designaciones' });
		}
		if (!_.size(this.state.ClaveUnidad_value)) {
			mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione un ClaveUnidad' });
		}





		if (mensajes.length) {
			this.growl.show(mensajes);
		} else {

			delete this.state.ClaveUnidad;
			delete this.state.designaciones;
			var state_val  = this.state;
			state_val.designaciones_value = JSON.stringify(state_val.designaciones_value)
			state_val.ClaveUnidad_value = JSON.stringify(state_val.ClaveUnidad_value)
			this.servicioCarta.guardarCartaPorte(state_val).then(res => {
				if (res && res.result == 'OK') {
					this.setState({ mostrar: 'none' });
					this.showConfirm(res.folio);
				}
			});
		}
	}

	toggleCheckboxValue() {
		this.setState({ exportacion_ck: !this.state.exportacion_ck });
		if (this.state.exportacion_ck) {
			this.setState({ exportacion: "Sí" });
		} else {
			this.setState({ exportacion: "No" });
		}
	}

	seleccionar_remitente(e) {
		let opciones = this.state.stops.filter(option => option.label !== e.value);
		this.setState({
			_destinatarios: opciones,
			remitente: e.value
		});
	}

	seleccionar_destinatario(e) {
		let opciones = this.state.stops.filter(option => option.label !== e.value);
		this.setState({
			_remitentes: opciones,
			destinatario: e.value
		});
	}

	cerrar_ventana() {
		this.setState({ visible: false });
		let that = this;
		setTimeout(function () {
			that.props.history.push('/app/carta-porte/consulta');
		}, 500);
	}

	changeOperador = async (e) => {
		let data = {
			operador_id: e.value
		}
		if (e.unidad_id) {
			data['unidad_id'] = e.unidad_id
		}
		await this.setState({ unidad_id: e.unidad_id,operador_id:e.value })
	}

	SearchDesignaciones = (e) => {
		this.servicioO.get_designaciones(e).then(res => {
			this.setState({ designaciones: res })
		});
	}
	SearchClaveUnidad = (e) => {
		this.servicioO.get_ClaveUnidad(e).then(res => {
			this.setState({ ClaveUnidad: res })
		});
	}
	changeDesignaciones = (e) => {
        let option_postal_code = _.find(this.state.designaciones, ["value", e.value]);
        this.setState({ designaciones_value: option_postal_code})
	}
	changeClaveUnidad = (e) => {
        let option_postal_code = _.find(this.state.ClaveUnidad, ["value", e.value]);
        this.setState({ ClaveUnidad_value: option_postal_code})
	}

	render() {

		const footer = (
			<div>
				<Button label="Sí" className='p-button-warning mr-2' icon="pi pi-check" onClick={this.imprimirCartaPorte} />
				<Button label="No" className='p-button-warning' icon="pi pi-times" onClick={this.cerrar_ventana.bind(this)} />
			</div>
		);
		const es = {
			firstDayOfWeek: 1,
			dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
			dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
			dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
			monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
			monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"]
		};
		return (

			<Card>
				<CardTitle className="bg-light border-bottom p-3 mb-0">
					<i className="mdi mdi-file-document-box mr-2"> </i>CARGA DE CARGA PORTE

				</CardTitle>
				<CardBody>
					<Dialog header="Carta Porte" footer={footer} visible={this.state.visible} style={{ width: '50vw' }} modal={true} onHide={this.cerrar_ventana.bind(this)}>
						¿Desea imprimir su carta porte con folio {this.state.folio}?
					</Dialog>
					<Toast ref={(el) => this.growl = el} />

					<form onSubmit={this.handleSubmit}>
						<Container>
							<Row>
								<Col xs="6">

									<div className="form-group">
										<label> Facturar a:		</label>
										<Select options={this.state.clientes} required onChange={this.seleccionar_cliente} placeholder="Seleccione un cliente" required />
									</div>
								</Col>
								<Col xs="3">

									<div className="form-group">
										<label> Tipo de emisión:		</label>
										<Select options={options} onChange={this.seleccionar_emision} placeholder="Seleccione emisión" required />
									</div>
								</Col>
								<Col xs="3">
									<div className="form-group">
										<label> Folio
											<input type="text" name="folio" value={this.state.folio} className="form-control" placeholder="Sin asignar" readOnly />
										</label>
									</div>
								</Col>

							</Row>
							<Row>
								<Col xs="6">
									<div className="form-group">
										<label> R.F.C.:</label>
										<input type="text" name="rfc" value={this.state.rfc} className="form-control" readOnly />

									</div>
								</Col>
								<Col xs="6">
									<div className="form-group">
										<label> Importe:</label><br />
										<InputNumber readOnly={!this.state.editableImporte} value={this.state.importe} onChange={e => this.setState({ importe: e.value })} mode="currency" currency="MXN" locale="es-MX" />
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs="6">
									<div className="form-group">
										<label>Ruta</label>
										<Select options={this.state.rutas} onChange={this.seleccionar_ruta} placeholder="Elegir ruta" />
									</div>
								</Col>

								<Col xs="6" >
									<div className="form-group">
										<label>Versión de ruta</label>
										<Select options={this.state.versiones} onChange={this.seleccionar_version} placeholder="Elegir versión" />
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs="6">
									<div className="form-group">
										<label> Remitente:</label>
										<Select onChange={e => this.seleccionar_remitente(e)} value={this.state._remitentes ? this.state._remitentes.filter(option => option.value === this.state.remitente) : ""} options={this.state._remitentes} placeholder="Seleccione una planta" />
									</div>
								</Col>
								<Col xs="6">
									<div className="form-group">
										<label> Destinatario:

										</label>
										<Select onChange={e => this.seleccionar_destinatario(e)} value={this.state._destinatarios ? this.state._destinatarios.filter(option => option.value === this.state.destinatario) : ""} options={this.state._destinatarios} placeholder="Seleccione una planta" />
									</div>
								</Col>
							</Row>

							<Row>
								<Col>
									<div className="form-check" >
										<input type="checkbox" value={this.state.exportacion_ck} onChange={this.toggleCheckboxValue} className="form-check-input" id="exportacion" />
										<label className="form-check-label">Exportación</label>
									</div>
								</Col>
							</Row>
							<Row>
								<Col>
									<div className="form-group">
										<input className="form-control" name="direccion" readOnly={this.state.exportacion_ck} onChange={e => this.setState({ exportacion: e.target.value })} />
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs="6">
									<div className="form-group">
										<label>Remisión</label>
										<input className="form-control" name="remision" onChange={e => this.setState({ remision: e.target.value })} />
									</div>
								</Col>
								<Col xs="6">
									<div className="form-check" style={{ paddingTop: '30px' }} >
										<input type="checkbox" className="form-check-input" value={this.state.material_peligroso} onChange={e => this.setState({ material_peligroso: !this.state.material_peligroso })} />
										<label className="form-check-label">Material o Residuo Peligroso</label>
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs="6">
									<div className="form-group">
										<label> Operador:</label>
										<Select options={this.state.operadores} placeholder="Seleccione un operador" onChange={e => { this.changeOperador(e) }} />
									</div>
								</Col>
								<Col xs="6">
									<div className="form-group">
										<label> Unidad:</label>
										<Select value={this.state.unidades ? this.state.unidades.filter(option => option.value === this.state.unidad_id) : ""} options={this.state.unidades} placeholder="Seleccione una unidad" onChange={e => this.setState({ unidad_id: e.value })} />
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs="6">
									<div className="form-group">
										<label> Remolque:</label>
										<Select options={this.state.remolques} placeholder="Seleccione un remolque" onChange={e => this.setState({ remolque_id: e.value })} />
									</div>
								</Col>
								<Col xs="6" >
									<div className="form-group">
										<label> Caja Drop:</label>
										<Select options={this.state.remolques} placeholder="Seleccione una caja" onChange={e => this.setState({ caja_id: e.value })} />
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs="6">
									<div className="form-group">
										<label> Descripción de mercancías:</label>
										<Select options={this.state.designaciones}
											onInputChange={(e) => { this.SearchDesignaciones(e) }}
											required="true"
											onChange={(e) => { this.changeDesignaciones(e) }}
											placeholder="Seleccione Codigo postal" />

									</div>
								</Col>
								<Col xs="6">
									<div className="form-group">
										<label> Clave Producto:</label>
										<input type="text" name="rfc" value={this.state.designaciones_value['value']} className="form-control" readOnly />
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs="6">
									<div className="form-group">
										<label> Clave Unidad:</label>
										
										<Select options={this.state.ClaveUnidad}
											onInputChange={(e) => { this.SearchClaveUnidad(e) }}
											required="true"
											onChange={(e) => { this.changeClaveUnidad(e) }}
											placeholder="Seleccione Clave Unidad" />
									</div>
								</Col>
								<Col xs="6">
									<div className="form-group">
										<label> Nombre Unidad:</label>
										<input type="text" name="rfc" value={this.state.ClaveUnidad_value['value']} className="form-control" readOnly />
									</div>
								</Col>

							</Row>
							<Row>
								<Col xs="6" >
									<div className="form-group">
										<label>Plazo Previsto de Entrega</label><br />
										<Calendar   dateFormat="dd/mm/yy" value={this.state.fecha_entrega} onChange={(e) => { this.setState({ fecha_entrega: moment(e.value).format('YYYY-MM-DD').toString() }); }} showIcon={true} placeholder="dd/mm/aaaa" />
									</div>
								</Col>
							</Row>

						</Container>
						<center> <button className="btn btn-success" style={{ display: this.state.mostrar }}  >Guardar</button></center>
					</form>

				</CardBody>
			</Card>
		);
	}
}
/*

<Row>
	<Col xs="6">
		<div className="form-group">
			<label><input type="checkbox" onChange={e=> this.setState({ch_stop1: !this.state.ch_stop1})}/>Agregar Stop 1</label>
			<Select options={this.state.stops} isDisabled={!this.state.ch_stop1} onChange={e=> this.setState({stop1:e.value})}  placeholder="Seleccione un Stop"/>
		</div>
	</Col>
	<Col xs="6">
		<div className="form-group">
			<label><input type="checkbox" onChange={e=> this.setState({ch_stop2: !this.state.ch_stop2})}/>Agregar Stop 2</label>
			<Select options={this.state.stops} isDisabled={!this.state.ch_stop2} onChange={e=> this.setState({stop2:e.value})}   placeholder="Seleccione un Stop"/>
		</div>
	</Col>
</Row>
<Row>
	<Col xs="6">
		<div className="form-group">
			<label><input type="checkbox" onChange={ e=> this.setState({ch_stop3: !this.state.ch_stop3})}/>Agregar Stop 3</label>
			<Select options={this.state.stops} isDisabled={!this.state.ch_stop3} onChange={e=> this.setState({stop3:e.value})}  placeholder="Seleccione un Stop" />
		</div>
	</Col>
	<Col xs="6">
		<div className="form-group">
			<label><input type="checkbox" onChange={e=> this.setState({ch_stop4:!this.state.ch_stop4})}/>Agregar Stop 4</label>
			<Select options={this.state.stops} isDisabled={!this.state.ch_stop4} onChange={e=> this.setState({stop4:e.value})}  placeholder="Seleccione un Stop" />
		</div>
	</Col>
</Row>
<Row>
	<Col xs="6">
		<div className="form-group">
			<label><input type="checkbox" onChange={e=> this.setState({ch_stop5: !this.state.ch_stop5})}/>Agregar Stop 5</label>
			<Select options={this.state.stops} isDisabled={!this.state.ch_stop5} onChange={e=> this.setState({stop5:e.value})}  placeholder="Seleccione un Stop" />
		</div>
	</Col>
	<Col xs="6">
		<div className="form-group">
			<label><input type="checkbox" onChange={e=> this.setState({ch_stop6:!this.state.ch_stop6})}/>Agregar Stop 6</label>
			<Select options={this.state.stops} isDisabled={!this.state.ch_stop6} onChange={e=> this.setState({stop6:e.value})}  placeholder="Seleccione un Stop" />
		</div>
	</Col>
</Row>
*/
export default CrearCartaPorte;
