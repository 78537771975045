
	import axios from 'axios';
import {url} from '../../constants';

export default class Service {

			/**
			Servicios para reportes de accesos
			*/

			guardarReporteAcceso(contenido){
					return axios.post(url + 'reporte-acceso/guardar',contenido,  {
						}).then(res => res.data);
			}

			listadoReporteAccesos(){
				return axios.get(url + 'reporte-acceso/listado',{})
								.then(res => res.data);
			}
			uploadReportFile(data) {
				return axios.post(url + 'upload_file', data, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(res => res.data);
			}
			eliminarReporte(id){
					return axios.post(url + 'reporte-acceso/eliminar',{
						id:id

					}).then(res => res.data);
			}



			actualizarReporte(objeto){

			  let objeto_nuevo =Object.assign({}, objeto);

			//	delete embarque.facturar_a;

					return axios.post(url + 'reporte-acceso/actualizar',{
						contenido:objeto_nuevo

					}).then(res => res.data);
			}



			/**
				Servicios para reporte de 17 puntos
			*/

			guardarReporte17(contenido){
				console.log(contenido)
					return axios.post(url + 'reporte-17/guardar',{reporte:contenido },  {
						}).then(res => res.data);
			}


				/**
					Servicios para reporte de 7 puntos
				*/

				guardarReporte7(contenido){
					console.log(contenido)
						return axios.post(url + 'reporte-7/guardar',{reporte:contenido },  {
							}).then(res => res.data);
				}

				/**
					Servicios para reporte de 7 puntos
				*/

				guardarReporteDespacho(contenido){
						return axios.post(url + 'reporte-despacho/guardar',{reporte:contenido },  {
							}).then(res => res.data);
				}


				/**
					Servicios para reporte de 7 puntos
				*/

				guardarReporteEntrega(contenido){
					console.log(contenido)
						return axios.post(url + 'reporte-entrega/guardar',{reporte:contenido },  {
							}).then(res => res.data);
				}
				updateReporteEntrega(contenido){
					return axios.post(url + 'reporte-entrega/update',{reporte:contenido },  {
						}).then(res => res.data);
				}


				/**
				METODOS DE SERVICIO PARA LOS REPORTES
				*/

				listadoReportes(tipo){
					return axios.get(url + 'reportes/listado',{params:{tipo:tipo}})
									.then(res => res.data);
				}

				eliminarReporteG(id,id_tipo){
					return axios.post(url + 'reportes/eliminar',{
						id:id,
						id_tipo:id_tipo
					}).then(res => res.data);

				}




}
