import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authenticationService } from '../jwt/_services';


const PrivateRoute = ({ component: Component, checkPermission, ...rest }) => (
    <Route {...rest} render={props => {
        const currentUser = authenticationService.currentUserValue;
        if (!currentUser) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/authentication/login', state: { from: props.location } }} />
        }

        const currentURL = props.location.pathname;
        let loggedUser = JSON.parse(localStorage.getItem('currentUser'));
        let loggedUserPermissions = JSON.parse(loggedUser.permissions);
        const parts = currentURL.replace('/app/', '').split('/');
        let formattedPathname = parts.join('_').toLowerCase();
        if(loggedUserPermissions&&!loggedUserPermissions[formattedPathname] && currentURL !== '/authentication/login' && currentURL !== '/app/no-autorizado' && currentURL !== '/roles' && currentURL !== '/'){
            return <Redirect to={{ pathname: '/app/no-autorizado', state: { from: props.location } }} />
        }
        if(currentURL == '/'){
            return <Redirect to={{ pathname: '/app/tablero', state: { from: props.location } }} />
        }
        // authorised so return component
        return <Component {...props} checkPermission={checkPermission}/>
    }} />
);

export default PrivateRoute;
