import axios from 'axios';
import {url} from '../../constants';

export default class AdminUserService{
            loginVerification(user){
                return 	axios.post(url + `admin-user/login`, user)
                .then(res => res.data);
            }
			getAdminUsersAll(){
					return 	axios.get(url + 'admin-user')
	                .then(res => res.data.result);
			}
            deleteAdminUser(id){
                    return 	axios.delete(url + `admin-user/${id}`)
                    .then(res => res.data.status);
            }
            addAdminUser(adminUser){
                return 	axios.post(url + `admin-user`, adminUser)
                .then(res => res.data);
            }
            editAdminUser(updatedAdminUser){
                return 	axios.put(url + `admin-user/${updatedAdminUser.id}`, updatedAdminUser)
                .then(res => res.data);
            }
            getAdminUserPermissions(id){
                return axios.get(url + `admin-user/permissions/${id}`)
                .then(res => res.data)
            }
}
