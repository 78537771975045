import React from 'react';
import indexRoutes from './routes/';
import { Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import { history } from './jwt/_helpers';
import PrivateRoute from 'routes/PrivateRoutes';
import Blanklayout from './layouts/blanklayout';
import ResultadoRoles from './views/roles/resultado';
import AdminUserService from "controllers/admin-user/service";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: null,
        };
        this.servicioClientes = new AdminUserService();
    }

    componentDidMount(){
        // Retrieve username from local storage
        let loggedUser = JSON.parse(localStorage.getItem('currentUser'));
        if(loggedUser){
            // Set the username in the component's state
            this.setState({ currentUser: loggedUser }, () => {
                // Make API call to get permissions based on the retrieved user id
                this.servicioClientes.getAdminUserPermissions(this.state.currentUser.id)
                    .then((val) => {
                        if (val.status) {
                            loggedUser.permissions = val.result.permisos
                            localStorage.setItem('currentUser', JSON.stringify(loggedUser));
                        } else {
                            console.log('Facing issue on permissions get',val);
                        }
                    })
                    .catch((error) => {
                        console.error('Error fetching permissions:', error);
                    });
            });
        }
    }

    checkPermission = () => {

        console.log('currentuser', this.state.currentUser)

        // let loggedUser = JSON.parse(localStorage.getItem('currentUser'));
        // const parts = currentURL.replace('/app/', '').split('/');
        // let formattedPathname = parts.join('_');
        // let loggedUserPermissions = JSON.parse(loggedUser.permissions)
        // if(!loggedUserPermissions[formattedPathname] && currentURL !== '/authentication/login' && currentURL !== '/app/no-autorizado' && currentURL !== '/roles'){
        //     return <Redirect to={{ pathname: '/app/no-autorizado', state: { from: props.location } }} />
        // }

        // return permissions[key] === true;
    };

    render() {
        return (
            <Provider store={configureStore()}>
                <Router basename="/" history={history} checkPermission={this.checkPermission} >
                    <Switch>
                        <Route path="/authentication/login" component={Blanklayout} />;
                        <Route path="/roles" component={ResultadoRoles} />;
                        {indexRoutes.map((prop, key) => {
                            return <PrivateRoute path={prop.path} key={key} component={prop.component} checkPermission={this.checkPermission} />;
                        })
                      }
                    </Switch>
                </Router>
            </Provider>
        )
    }
}

export default App;
