import axios from 'axios';
import {url} from '../../constants';

export default class Service {

			getAllUnidades(obj={}){
				return axios.get(url + 'unidades/all',{params:obj})
								.then(res => res.data);
		    }

	       getUnidades(){
             return axios.get(url + 'unidades')
                .then(res => res.data);
			}

			getFiltroUnidades(obj){
				// return axios.get(url + 'unidades/all/filtro',{params:obj})
				// 				.then(res => res.data);
				
				return axios.get(url + 'unidades/filtro',{params:obj})
				.then(res => res.data);	
			}

			guardarUnidad(unidad){
					return axios.post(url + 'unidades/guardar',unidad,  {
			    		headers: {
					      'Content-Type': 'multipart/form-data'
					    }
					}).then(res => res.data);
			}


			actualizarUnidad(unidad){
					return axios.post(url + 'unidades/actualizar', unidad,{
						headers: {
							'Content-Type': 'multipart/form-data'
						  }
					}).then(res => res.data);
			}

			eliminarUnidad(unidad_id){
					return axios.post(url + 'unidades/eliminar',{
						id:unidad_id,

					}).then(res => res.data);
			}

}
