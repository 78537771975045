
export default class CheckPermissions {

    checkPermission (permissionKey){
    let loggedUser = JSON.parse(localStorage.getItem('currentUser'));
    let loggedUserPermissions = JSON.parse(loggedUser.permissions)
    if(loggedUserPermissions && loggedUserPermissions[permissionKey]){
        return true
    }else{
        return false
    }
  }
}
