import React from "react";
import {
	Card,
	CardBody,
	CardTitle
} from 'reactstrap';
import Listado from './reparacion/listado';
import {Button} from 'primereact/button';
import { Link } from "react-router-dom"; 
import { Panel } from 'primereact/panel';
import CheckPermissions from "controllers/permissions/services";
class ReportesFallas extends React.Component {
	constructor(){
		super()
		this.headerTemplate = this.headerTemplate.bind(this);
		this.permissionService = new CheckPermissions();
	}
	agregar(){
	  this.props.history.push({
	   pathname:'/app/mantenimiento/correctivo/reparacion/nueva'
	 });
	}
	headerTemplate()
	{
		return (<div className="p-panel-header flex-wrap  d-flex">
			<div>
				Listado De Repraciones
			</div>
			<div>
			{this.permissionService.checkPermission('mantenimiento_correctivo_reparacion_nueva') && <Link to="/app/mantenimiento/correctivo/reparacion/nueva" className="btn btn-primary">
				<i className="pi pi-plus mr-2 v-middle" /> Nueva reparación
			</Link>}
			</div>
		</div>);
	}
	render() {
		return (
			<div class="container-fluid">
				<Panel headerTemplate={this.headerTemplate}>
					<Listado history={this.props.history}/>
				</Panel>
			</div>
		);
	}
}

export default ReportesFallas;
