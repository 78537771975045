import React, { Component } from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {MultiSelect} from 'primereact/multiselect';
import RutaService  from 'controllers/ruta/rutaservice';
import {Button} from 'primereact/button';
import EditarID from './editar';
import {Dialog} from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import {Button as Button2 } from 'reactstrap';

export default class RutasListado extends Component {

    constructor() {
        super();
        this.state = {
            globalFilter: null
        };
        this.servicio_rutas = new RutaService();
    }

    componentDidMount() {
      if(this.props.parametros){
        this.servicio_rutas.getFiltroTodasLasRutas(this.props.parametros).then(data => this.setState({rutas: data}));
      }else{
        this.servicio_rutas.getTodasLasRutas().then(data => this.setState({rutas: data}));
      }
    }

    eliminar(column){
      this.setState({visible2:true,selectedItemIndex:column.rowIndex,selectedItem:column.rowData});

    }

    editar(column){
      this.props.history.push({
         pathname:'/app/ruta/editar',
         state: column.rowData
       });
    }

    confirmar_borrado(){

      this.setState({visible2:false});
      this.setState({
        rutas: this.state.rutas.filter((val,i) => i !== this.state.selectedItemIndex)
      });
      let that = this;
      // console.log(this.state.selectedItem);
      this.servicio_rutas.eliminarRuta(this.state.selectedItem.id).then(res =>  {
          if(res && res.result == 'OK'){
            that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Registro borrado'});
          }
      });

    }


    actionTemplate(rowData, column) {
      return (<div>
        <Button
          type="button" icon="pi pi-pencil" onClick={e=> this.editar(column)}
          className='p-button-warning'/><Button
          type="button" icon="pi pi-trash" onClick={e=> this.eliminar(column)}
           className="p-button-warning"
        />
        <br/>

      </div>);
    }
/*<Button2>Versiones</Button2>*/
    render() {
        const footer = (
          <div>
                <Button label="Sí" className='p-button-warning mr-2' icon="pi pi-check" onClick={this.confirmar_borrado.bind(this)} />
                <Button label="No" className='p-button-warning' icon="pi pi-times" onClick={e=> this.setState({visible2:false})}/>
            </div>

        );
        let header = (
            <div style={{'textAlign':'left'}}>
                <i className="pi pi-search" style={{margin:'4px 4px 0 0'}}></i>
                <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="Filtro" size="50"/>
            </div>
        );

        return (<div>
            <Toast ref={(el) => this.growl = el} />
            <Dialog header="Editar"  visible={this.state.visible} style={{width: '50vw',overflow:'scroll'}} modal={true} onHide={() => this.setState({visible: false,visible2:false	})}>
              <EditarID values={this.state.item} growl={this.growl} onSaved={()=> this.setState({visible:false})}/>
            </Dialog>
            <Dialog header="Eliminar"  footer={footer} visible={this.state.visible2} style={{width: '50vw'}} modal={true} onHide={() => this.setState({visible: false,visible2:false	})}>
              ¿Confirma eliminar?
            </Dialog>
            <DataTable value={this.state.rutas} paginator={true} rows={10} header={header} globalFilter={this.state.globalFilter}>
                <Column field="id_ruta" header="ID Ruta" />
                <Column field="broker" header="Broker" />
                <Column body={this.actionTemplate.bind(this)} header="Opciones" style={{ textAlign: 'center'}} />
            </DataTable></div>
        );
    }
}
