	import axios from 'axios';
import {url} from '../../constants';

export default class Service {

			/**
			Servicios para  los reportes
			*/

			guardarEmpleado(contenido){
				console.log(contenido)
					return axios.post(url + 'empleado/guardar',{reporte:contenido },  {
						}).then(res => res.data);
			}

			listadoEmpleado(){
				return axios.get(url + 'empleado/listado',{})
								.then(res => res.data);
			}

			eliminarEmpleado(id){
					return axios.post(url + 'empleado/eliminar',{
						id:id

					}).then(res => res.data);
			}

			actualizarEmpleado(objeto){

			  let objeto_nuevo =Object.assign({}, objeto);

			//	delete embarque.facturar_a;

					return axios.post(url + 'empleado/actualizar',{
						contenido:objeto_nuevo

					}).then(res => res.data);
			}


}
