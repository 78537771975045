import React from "react";
import {
	Card,
	CardBody,
	CardTitle,
	CardText,
} from 'reactstrap';

class NotAuthorized extends React.Component{
	constructor() {
		super();
	  }


	render() {
		return (
			<Card>
				<CardBody>
					<CardTitle>No autorizado</CardTitle>
					<CardText>No tienes autorización para esta página</CardText>
				</CardBody>
			</Card>
		);
	}
}

export default NotAuthorized;