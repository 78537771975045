import React from "react";
import {
	Row,
	Col
} from 'reactstrap';
import { Toast } from 'primereact/toast';
import { Panel } from 'primereact/panel';
import MantenimientoService from  'controllers/mantenimiento/mantenimiento-service';
const options = [];

class CrearProveedor extends React.Component {
	constructor(props){
		super(props);
		this.state={
			titulo:"Agregar"
		}
		this.servicio = new MantenimientoService();

		this.handleSubmit = this.handleSubmit.bind(this);
		this.headerTemplate = this.headerTemplate.bind(this);
	}


	componentDidMount() {
		let values = this.props.location.state;
		console.log(values);
		if(values){
			this.setState({
				nombre_comercial:values.nombre_comercial,
				nombre_contacto:values.nombre_contacto,
				razon_social:values.razon_social,
				telefono_contacto:values.telefono_contacto,
				domicilio_fiscal:values.domicilio_fiscal,
				correo_electronico:values.correo_electronico,

				id:values.id,
				titulo:"EDITAR",
				sobreescribir: true
			});
		}

	}



	handleSubmit(event) {
				event.preventDefault();

				 console.log(this.state);
				let that = this;

				let datos ={};
				const data = new FormData();
				datos.nombre_comercial = this.state.nombre_comercial;
				datos.nombre_contacto = this.state.nombre_contacto;
				datos.razon_social = this.state.razon_social;
				datos.telefono_contacto = this.state.telefono_contacto;
				datos.domicilio_fiscal = this.state.domicilio_fiscal;
				datos.correo_electronico = this.state.correo_electronico;

				data.append("ruta",JSON.stringify(datos));
				data.append("contrato",this.state.contrato);
				data.append("asignacion",this.state.asignacion);

				let mensajes = [];

				if(!this.state.nombre_comercial){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Ingrese un nombre'});
				}

				if(mensajes.length){
					this.growl.show(mensajes);
				}else{

					let funcion = console.log;
					if(this.state.sobreescribir){
						funcion = this.servicio.actualizarProveedor;
						datos.id = this.state.id;
					}else{
						funcion = this.servicio.guardarProveedor;
					}

					funcion(datos).then(res =>  {
							if(res && res.result == 'OK'){

								that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Información guardada correctamente'});
								setTimeout(function(){
										 	that.props.history.push('/app/mantenimiento/proveedores');
								 }, 500);

							}else if(res && res.result == 'EXISTE'){
								that.growl.show({severity: 'error', summary: 'El Id de ruta '+this.state.id_ruta+' ya se encuentra registrado', detail: 'Ingrese otro código o consulte su información para editarla.'});
							}
					});
				}
	}
	headerTemplate()
	{
		return <div className="p-panel-header flex-wrap  d-flex">
			{this.state.titulo} Proveedor
		</div>;
	}
	
	render() {

		return (
			<div className="container">
				<Panel headerTemplate={this.headerTemplate}>
					<Toast ref={(el) => this.growl = el} />
					<form >
						<Row>
							<Col xs="6">
									<div className="form-group">
										<label>Nombre comercial</label><br/>
											<input type="text"  value={this.state.nombre_comercial}  onChange={e => this.setState({nombre_comercial : e.target.value})}  className="form-control"/>
									</div>

							</Col>
							<Col xs="6">
									<div className="form-group">
										<label>Razón social</label><br/>
											<input type="text"  value={this.state.razon_social}  onChange={e => this.setState({razon_social : e.target.value})}  className="form-control"/>
									</div>
							</Col>
						</Row>
						<Row>
							<Col xs="12">
									<div className="form-group">
										<label>Domicilio fiscal</label>
										<input type="text"  value={this.state.domicilio_fiscal} onChange={e => this.setState({domicilio_fiscal : e.target.value})}  className="form-control"/>
									</div>
							</Col>
						</Row>
						<Row>
							<Col xs="6">
									<div className="form-group">
										<label>Teléfono contacto</label><br/>
											<input type="text"  value={this.state.telefono_contacto}  onChange={e => this.setState({telefono_contacto : e.target.value})}  className="form-control"/>
									</div>
							</Col>

							<Col xs="6">
									<div className="form-group">
										<label>Nombre de contacto</label><br/>
											<input type="text"  value={this.state.nombre_contacto}  onChange={e => this.setState({nombre_contacto : e.target.value})}  className="form-control"/>
									</div>

							</Col>
						</Row>
						<Row>
							<Col xs="6">
									<div className="form-group">
										<label>Correo electrónico</label><br/>
											<input type="text"  value={this.state.correo_electronico}  onChange={e => this.setState({correo_electronico : e.target.value})}  className="form-control"/>
									</div>

							</Col>
						</Row>
						<center> <button className="btn btn-primary" onClick={this.handleSubmit} >Guardar</button></center>
					</form>
				</Panel>
			</div>
		);
	}
}

export default CrearProveedor;
