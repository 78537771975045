import React, { Component } from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {MultiSelect} from 'primereact/multiselect';
import LlantaService  from 'controllers/llanta/llantaService';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import EvidenciaService from 'controllers/evidencias/evidencias-service';

export default class EvidenciaListado extends Component {

    constructor() {
        super();
        this.state = {
            globalFilter: null,
            visible:false,
            visible2:false,
            visible3:false,
            qr:5
        };
        this.servicio = new EvidenciaService();
        this.eliminar = this.eliminar.bind(this);
        this.editar = this.editar.bind(this);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps){
      console.log(this.props.cartaPorteId);
      if(prevProps.cartaPorteId !== this.props.cartaPorteId){
        this.servicio.getEvidencias(this.props.cartaPorteId).then(data => this.setState({evidencias: data}));
      }
    }

    eliminar(column){
      this.setState({visible2:true,selectedItemIndex:column.rowIndex,selectedItem:column.rowData});

    }

    editar(column){
      this.setState({visible:true,item:column.rowData});
    }

    confirmar_borrado(){

      this.setState({visible2:false});
      this.setState({
        llantas: this.state.llantas.filter((val,i) => i !== this.state.selectedItemIndex)
      });
      let that = this;
      this.servicioOperador.eliminarOperador(this.state.selectedItem.usuario_id).then(res =>  {
          if(res && res.result == 'OK'){
            that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Registro borrado'});
          }
      });

    }


    ev1Template(rowData, column) {
      return (<div>
        <center>{rowData.t1}</center><br/>
        <center>{rowData.ev1}</center><br/>
      </div>);
    }

    ev2Template(rowData, column) {
      return (<div>
        <center>{rowData.t2}</center><br/>
          <center>{rowData.ev2}</center><br/>
            </div>);
    }

    ev3Template(rowData, column) {
      return (<div>
        <center>{rowData.t3}</center><br/>
          <center>{rowData.ev3}</center><br/>

      </div>);
    }
    ev0Template(rowData, column) {
      return (<div>
        <center>{rowData.factura}</center><br/>
          <center>{rowData.ev0}</center><br/>

      </div>);
    }
/*  <button
   className="btn" style={{margin:10}}
>Descargar</button>*/
    ev4Template(rowData, column) {
      return (<div>
        <center>{rowData.t4}</center><br/>
          <center>{rowData.ev4}</center><br/>

      </div>);
    }

    render() {
      const footer = (
        <div>
              <Button label="Sí" className='p-button-warning mr-2' icon="pi pi-check" onClick={this.confirmar_borrado.bind(this)} />
              <Button label="No" className='p-button-warning' icon="pi pi-times" onClick={e=> this.setState({visible2:false})}/>
          </div>

      );

        return (<div>
            <Toast ref={(el) => this.growl = el} />

            <Dialog header="Eliminar"  footer={footer} visible={this.state.visible2} style={{width: '50vw'}} modal={true} onHide={() => this.setState({visible: false,visible2:false	})}>
              ¿Confirma eliminar?
            </Dialog>
            <DataTable value={this.state.evidencias} >
                <Column field="id" header="ID Carga" />
                <Column body={this.ev1Template.bind(this)} header="Evidencia 1" />
                <Column body={this.ev2Template.bind(this)} header="Evidencia 2" />
                <Column body={this.ev3Template.bind(this)} header="Evidencia 3" />
                <Column body={this.ev4Template.bind(this)} header="Evidencia 4" />
                <Column body={this.ev0Template.bind(this)} header="Factura" />
            </DataTable>

      </div>);
    }
}
