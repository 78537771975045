import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import Header from './layout-components/header/header';
import Sidebar from './layout-components/sidebar/sidebar';
import Footer from './layout-components/footer/footer';
//import Customizer from './layout-components/customizer/customizer';
import ThemeRoutes from '../routes/router';

import ResultadosCartaPorte  from '../views/exnac/carta-porte/resultado';
import ResultadoClientes  from '../views/exnac/clientes/resultado';
import CobranzaAdd  from '../views/exnac/embarques/cobranza-add';
import ResultadoOperadores  from '../views/exnac/operadores/resultado';
import ResultadoUnidades  from '../views/exnac/unidades/resultado';
import ResultadoRutas from '../views/exnac/rutas/resultado';
import ResultadoEmbarque from '../views/exnac/embarques/resultado';
import ResultadoLlantas from '../views/exnac/llantas/resultado';
import CartaPorteEditarPagina from '../views/exnac/carta-porte/EditarPagina';
import ClienteEditarPagina from '../views/exnac/clientes/EditarPagina';
import RutaEditarPagina from '../views/exnac/rutas/EditarPagina';
import UnidadEditarPagina from '../views/exnac/unidades/EditarPagina';
import LlantaEditarPagina from '../views/exnac/llantas/EditarPagina';
import OperadorEditarPagina from '../views/exnac/operadores/EditarPagina';
import OperadorDocuments from '../views/exnac/operadores/documents';
import RmbarqueEditarPagina from '../views/exnac/embarques/EditarPagina';

import CrearReporteFalla from '../views/exnac/mantenimiento/reporte-fallas/crear';
import CrearOrdenCompra from '../views/exnac/mantenimiento/ordenes-compra/crear';
import CrearSemaforo from '../views/exnac/mantenimiento/semaforo/crear';
import CrearOrdenServicio from '../views/exnac/mantenimiento/ordenes-servicio/crear';
import CrearReparacion from '../views/exnac/mantenimiento/reparacion/crear';
import CrearInventario from '../views/exnac/mantenimiento/inventario/crear';
import CrearPredictivo from '../views/exnac/mantenimiento/predictivos/crear';

import AgregarMantenimiento from '../views/exnac/mantenimiento/programado/crear';

import RegistrarAcceso  from '../views/exnac/vigilancia/accesos/crear';
import EditarAcceso  from '../views/exnac/vigilancia/accesos/editar';


import CrearSello from '../views/exnac/control-sellos/crear';
import EditarSello from '../views/exnac/control-sellos/editar';
import CambioSello from '../views/exnac/control-sellos-consulta/crear';
import CambioSelloEditar from '../views/exnac/control-sellos-consulta/editar';

import EditarReporteFalla from '../views/exnac/mantenimiento/reporte-fallas/editar';
import EditarOrdenCompra from '../views/exnac/mantenimiento/ordenes-compra/editar';
import EditarOrdenServicio from '../views/exnac/mantenimiento/ordenes-servicio/editar';
import EditarReparacion from '../views/exnac/mantenimiento/reparacion/editar';
import EditarInventario from '../views/exnac/mantenimiento/inventario/editar';
import EditarPredictivo from '../views/exnac/mantenimiento/predictivos/editar';
import EditarMantenimiento from '../views/exnac/mantenimiento/programado/editar';
import CalendarioProgramado from '../views/exnac/mantenimiento/calendario';

import CrearParadaAutorizada from '../views/exnac/paradas-autorizadas/crear';
import EditarParadaAutorizada from '../views/exnac/paradas-autorizadas/editar';

import CatalogoAgregarActividad from '../views/exnac/mantenimiento/catalogo/crear';
import CatalogoAgregarProveedor from '../views/exnac/mantenimiento/proveedores/crear';


import NominaEmpleados from '../views/exnac/empleado/crear';

import AgregarDepartamento from '../views/exnac/empleado/departamento/crear';
import AgregarPuesto from '../views/exnac/empleado/puesto/crear';
import EditarDepartamento from '../views/exnac/empleado/departamento/editar';
import EditarPuesto from '../views/exnac/empleado/puesto/editar';



import EditarEmpleado from '../views/exnac/empleado/editar';

import CategoriaNominaAgregar from '../views/exnac/nomina/calculo/categoria/crear';
import CategoriaNominaEditar from '../views/exnac/nomina/calculo/categoria/editar';
import ConceptoNominaAgregar from '../views/exnac/nomina/calculo/conceptos/crear';
import ConceptoNominaEditar from '../views/exnac/nomina/calculo/conceptos/editar';
import InspeccionVigilancia2 from "../views/exnac/mantenimiento/entrega-inspeccion/entrega-inspeccion";

import Firma from '../views/exnac/claves/huella';
import CrearUsuarioAdministrador from 'views/exnac/admin-user/crear';
import EditarUsuarioAdministrador from 'views/exnac/admin-user/editar';
import Dashboard from 'views/dashboard/dashboard';
import NotAuthorized from 'views/not-authorizated/not-authorized';




const mapStateToProps = state => ({
	...state
});

class Fulllayout extends React.Component {
	constructor(props) {
		super(props);
		this.updateDimensions = this.updateDimensions.bind(this);
		this.state = {
			isOpen: false,
			width: window.innerWidth
		};

		this.props.history.listen((location, action) => {
			if (window.innerWidth < 767 &&
				document.getElementById('main-wrapper').className.indexOf("show-sidebar") !== -1) {
				document.getElementById('main-wrapper').classList.toggle("show-sidebar");
			}
		});
	}
	/*--------------------------------------------------------------------------------*/
	/*Life Cycle Hook, Applies when loading or resizing App                           */
	/*--------------------------------------------------------------------------------*/
	componentDidMount() {
		window.addEventListener("load", this.updateDimensions);
		window.addEventListener("resize", this.updateDimensions);
	}
	/*--------------------------------------------------------------------------------*/
	/*Function that handles sidebar, changes when resizing App                        */
	/*--------------------------------------------------------------------------------*/
	updateDimensions() {
		let element = document.getElementById('main-wrapper');
		this.setState({
			width: window.innerWidth
		});
		switch (this.props.settings.activeSidebarType) {
			case 'full':
			case 'iconbar':
				if (this.state.width < 1170) {
					element.setAttribute("data-sidebartype", "mini-sidebar");
					element.classList.add("mini-sidebar");
				} else {
					element.setAttribute("data-sidebartype", this.props.settings.activeSidebarType);
					element.classList.remove("mini-sidebar");
				}
				break;

			case 'overlay':
				if (this.state.width < 767) {
					element.setAttribute("data-sidebartype", "mini-sidebar");
				} else {
					element.setAttribute("data-sidebartype", this.props.settings.activeSidebarType);
				}
				break;

			default:
		}
	}
	/*--------------------------------------------------------------------------------*/
	/*Life Cycle Hook                                                                 */
	/*--------------------------------------------------------------------------------*/
	componentWillUnmount() {
		window.removeEventListener("load", this.updateDimensions);
		window.removeEventListener("resize", this.updateDimensions);
	}
	render() {
		/*--------------------------------------------------------------------------------*/
		/* Theme Setting && Layout Options wiil be Change From Here                       */
		/*--------------------------------------------------------------------------------*/
		return (

			<div				id="main-wrapper"
				dir={this.props.settings.activeDir}
				data-theme={this.props.settings.activeTheme}
				data-layout={this.props.settings.activeThemeLayout}
				data-sidebartype={this.props.settings.activeSidebarType}
				data-sidebar-position={this.props.settings.activeSidebarPos}
				data-header-position={this.props.settings.activeHeaderPos}
				data-boxed-layout={this.props.settings.activeLayout}
			>
				{/*--------------------------------------------------------------------------------*/}
				{/* Header                                                                         */}
				{/*--------------------------------------------------------------------------------*/}
				<Header breadcrumb={this.props.location}/>
				{/*--------------------------------------------------------------------------------*/}
				{/* Sidebar                                                                        */}
				{/*--------------------------------------------------------------------------------*/}
				<Sidebar {...this.props} routes={ThemeRoutes} />
				{/*--------------------------------------------------------------------------------*/}
				{/* Page Main-Content                                                              */}
				{/*--------------------------------------------------------------------------------*/}
				<div className="page-wrapper d-block pt-5 mt-4">
					<div className="page-content">
						<Switch>
						<Route path="/app/panel" component={Dashboard} checkPermission={this.checkPermission}/>;
						<Route path="/app/no-autorizado" component={NotAuthorized} />
						<Route path="/app/usuario-administrador/crear" component={CrearUsuarioAdministrador} />;
						<Route path="/app/usuario-administrador/editar" component={EditarUsuarioAdministrador} />;
						<Route path="/app/carta-porte/resultado" component={ResultadosCartaPorte} />;
						<Route path="/app/clientes/resultado" component={ResultadoClientes} />;
						<Route path="/app/operadores/resultado" component={ResultadoOperadores} />;
						<Route path="/app/unidades/resultado" component={ResultadoUnidades} />;
						<Route path="/app/clientes/rutas/resultado" component={ResultadoRutas} />;
						<Route path="/app/embarque/resultado" component={ResultadoEmbarque} />;
						{/* <Route path="/app/Cobranza/add" component={CobranzaAdd} />;						 */}
						<Route path="/app/unidades/llantas/resultado" component={ResultadoLlantas} />;
						<Route path="/app/carta-porte/editar" component={CartaPorteEditarPagina} />;
						<Route path="/app/cliente/editar" component={ClienteEditarPagina} />;
						<Route path="/app/ruta/editar" component={RutaEditarPagina} />;
						<Route path="/app/unidad/editar" component={UnidadEditarPagina} />;
						<Route path="/app/llanta/editar" component={LlantaEditarPagina} />;
						<Route path="/app/operador/editar" component={OperadorEditarPagina} />;
						<Route path="/app/operador/documents" component={OperadorDocuments} />;
						<Route path="/app/embarque/editar" component={RmbarqueEditarPagina} />;

						<Route path="/app/entrega-inspeccion/nuevo" component={InspeccionVigilancia2} />;
						<Route path="/app/mantenimiento/preventivo/fallas/nueva" component={CrearReporteFalla} />;
						<Route path="/app/mantenimiento/preventivo/ordenes/nueva" component={CrearOrdenCompra} />;
						<Route path="/app/mantenimiento/preventivo/semaforo/nuevo" component={CrearSemaforo} />;
						<Route path="/app/mantenimiento/correctivo/ordenes-servicio/nueva" component={CrearOrdenServicio} />;
						<Route path="/app/mantenimiento/correctivo/reparacion/nueva" component={CrearReparacion} />;
						<Route path="/app/mantenimiento/predictivo/inventario/agregar" component={CrearInventario} />;
						<Route path="/app/mantenimiento/predictivo/programado/agregar" component={AgregarMantenimiento} />;
						<Route path="/app/mantenimiento/predictivo/predictivos/agregar" component={CrearPredictivo} />;

						<Route path="/app/mantenimiento/preventivo/fallas/Editar" component={EditarReporteFalla} />;
						<Route path="/app/mantenimiento/preventivo/ordenes/Editar" component={EditarOrdenCompra} />;
						<Route path="/app/mantenimiento/correctivo/ordenes-servicio/Editar" component={EditarOrdenServicio} />;
						<Route path="/app/mantenimiento/correctivo/reparacion/Editar" component={EditarReparacion} />;
						<Route path="/app/mantenimiento/predictivo/inventario/Editar" component={EditarInventario} />;
						<Route path="/app/mantenimiento/predictivo/predictivos/Editar" component={EditarPredictivo} />;
						<Route path="/app/mantenimiento/predictivo/programado/Editar" component={EditarMantenimiento} />;


						<Route path="/app/vigilancia/accesos/agregar" component={RegistrarAcceso} />;
						<Route path="/app/vigilancia/accesos/editar" component={EditarAcceso} />;

						<Route path="/app/control-sellos/catalogo/agregar" component={CrearSello} />;
						<Route path="/app/control-sellos/catalogo/editar" component={EditarSello} />;
						<Route path="/app/control-sellos/consulta/cambio" component={CambioSello} />;
						<Route path="/app/control-sellos/consulta/editar" component={CambioSelloEditar} />;



						<Route path="/app/paradas-autorizadas/catalogo/agregar" component={CrearParadaAutorizada} />;
						<Route path="/app/paradas-autorizadas/catalogo/Editar" component={EditarParadaAutorizada} />;
						<Route path="/app/mantenimiento/catalogo/agregar" component={CatalogoAgregarActividad} />;
						<Route path="/app/mantenimiento/proveedores/agregar" component={CatalogoAgregarProveedor} />;

						<Route path="/app/nomina/calculo/empleado/agregar" component={NominaEmpleados} />;
						<Route path="/app/nomina/calculo/empleado/editar" component={EditarEmpleado} />;

						<Route path="/app/empleados/departamentos/agregar" component={AgregarDepartamento} />;
						<Route path="/app/empleados/puestos/agregar" component={AgregarPuesto} />;
						<Route path="/app/empleados/departamentos/editar" component={EditarDepartamento} />;
						<Route path="/app/empleados/puestos/editar" component={EditarPuesto} />;



						<Route path="/app/nomina/calculo/categoria/agregar" component={CategoriaNominaAgregar} />;
						<Route path="/app/nomina/calculo/categoria/editar" component={CategoriaNominaEditar} />;

						<Route path="/app/nomina/calculo/concepto/agregar" component={ConceptoNominaAgregar} />;

						<Route path="/app/nomina/calculo/concepto/editar" component={ConceptoNominaEditar} />;

						<Route path="/app/mantenimiento/predictivo/programado/calendario" component={CalendarioProgramado} />;
						<Route path="/app/clave-huella" component={Firma} />;


							{ThemeRoutes.map((prop, key) => {
								if (prop.navlabel) {
									return null;
								}
								else if (prop.collapse) {
									return prop.child.map((prop2, key2) => {
										if (prop2.collapse) {
											return prop2.subchild.map((prop3, key3) => {
												return (
													<Route path={prop3.path} component={prop3.component} key={key3} />
												);
											});
										}
										return (
											<Route path={prop2.path} component={prop2.component} key={key2} />
										);
									});
								}
								else if (prop.redirect) {
									return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
								}
								else {
									return (
										<Route path={prop.path} component={prop.component} key={key} />
									);
								}
							})}

						</Switch>
					</div>
					<Footer />
				</div>
				{/*--------------------------------------------------------------------------------*/}
				{/* Customizer from which you can set all the Layout Settings                      */}
				{/*--------------------------------------------------------------------------------*/}
				</div>
		);
	}
}
export default connect(mapStateToProps)(Fulllayout);
