import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import ClienteService from "controllers/cliente/clienteservice";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import EditarCliente from "./editar";
import { Toast } from "primereact/toast";
import CheckPermissions from "controllers/permissions/services";

export default class ClienteListado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalFilter: null,
    };
    this.servicioClientes = new ClienteService();
    this.eliminar = this.eliminar.bind(this);
    this.editar = this.editar.bind(this);
    this.permissionService = new CheckPermissions();
  }

  componentDidMount() {
    this.props.updateCustomerData(this.props.estado);
  }

  eliminar(column, rowData) {
    this.setState({
      visible2: true,
      selectedItemIndex: column.rowIndex,
      selectedItem: rowData,
    });
  }

  editar(rowData) {
    //  this.setState({visible:true,item:column.rowData});
    this.props.history.push({
      pathname: "/app/cliente/editar",
      state: rowData,
    });
  }

  confirmar_borrado() {
    this.setState({ visible2: false });
    this.setState({
      cars: this.props.cars.filter(
        (val, i) => i !== this.state.selectedItemIndex
      ),
    });
    let that = this;
    this.servicioClientes
      .eliminarCliente(this.state.selectedItem.id)
      .then((res) => {
        if (res && res.result == "OK") {
          that.growl.show({
            severity: "success",
            summary: "Operación completada",
            detail: "Registro borrado",
          });
          this.props.updateCustomerData(this.props.estado);
        }
      });
  }

  actionTemplate(rowData, column) {
    return (
      <div>
        {this.permissionService.checkPermission('cliente_editar') && <Button
          type="button"
          icon="pi pi-pencil"
          onClick={(e) => this.editar(rowData)}
          className="p-button-warning"
          tooltip="Editar"
          tooltipOptions={{ position: 'top' }}
        />}
        {this.permissionService.checkPermission('cliente_borrar') && <Button
          type="button"
          icon="pi pi-trash"
          onClick={(e) => this.eliminar(column, rowData)}
          className="p-button-warning"
          tooltip="Borrar"
          tooltipOptions={{ position: 'top' }}
        />}
      </div>
    );
  }
  razon_socialTemplate(rowData) {
    return rowData.razon_social ? rowData.razon_social : "nulo";
  }
  rfcTemplate(rowData) {
    return rowData.rfc ? rowData.rfc : "nulo";
  }
  calleTemplate(rowData) {
    return rowData.calle ? rowData.calle : "nulo";
  }
  numeroTemplate(rowData) {
    return rowData.numero ? rowData.numero : "nulo";
  }
  coloniaTemplate(rowData) {
    return rowData.colonia ? rowData.colonia : "nulo";
  }
  municipioTemplate(rowData) {
    return rowData.municipio ? rowData.municipio : "nulo";
  }
  monedaTemplate(rowData) {
    return rowData.moneda ? rowData.moneda : "nulo";
  }

  render() {

    const footer = (
      <div>
        <Button
          label="Sí"
          className='p-button-warning mr-2'
          icon="pi pi-check"
          onClick={this.confirmar_borrado.bind(this)}
        />
        <Button
          label="No"
          className='p-button-warning'
          icon="pi pi-times"
          onClick={(e) => this.setState({ visible2: false })}
        />
      </div>
    );

    return (

      <div>
        <Toast ref={(el) => (this.growl = el)} />
        <Dialog
          header="Editar"
          visible={this.state.visible}
          style={{ width: "50vw", height: "90vh", overflow: "scroll" }}
          modal={true}
          onHide={() => this.setState({ visible: false, visible2: false })}
        >
          <EditarCliente
            values={this.state.item}
            growl={this.growl}
            onSaved={() => this.setState({ visible: false })}
          />
        </Dialog>
        <Dialog
          header="Eliminar"
          footer={footer}
          visible={this.state.visible2}
          style={{ width: "50vw" }}
          modal={true}
          onHide={() => this.setState({ visible: false, visible2: false })}
        >
          ¿Confirma eliminar?
        </Dialog>
        <DataTable value={this.props.cars}
          paginator={true}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10}>
          <Column
            field="razon_social"
            header="Cliente"
            body={this.razon_socialTemplate}
          />
          <Column field="rfc" header="R.F.C." body={this.rfcTemplate} />       
          <Column field="moneda" header="Moneda" body={this.monedaTemplate}/>
          <Column field="calle" header="Calle" body={this.calleTemplate}/>
          <Column field="colonia" header="Colonia" body={this.coloniaTemplate} />
          <Column field="municipio" header="Municipio" body={this.municipioTemplate} />
          {/* <Column field="identidad_tributaria" header="Identidad tributaria" /> */}
          <Column field="numero" header="Número" body={this.numeroTemplate} />
          <Column
            body={this.actionTemplate.bind(this)}
            header="Opciones"
            style={{ textAlign: "center" }}
          />
        </DataTable>
      </div>
    );
  }
}
