import React, { Component } from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {MultiSelect} from 'primereact/multiselect';
import ClienteService  from 'controllers/cliente/clienteservice';
import {Button} from 'primereact/button';
import EditarRol from "./editar";
import Usuarios from "./usuarios";
import ListadoPermisos from "./permisos";
import {Dialog} from 'primereact/dialog';
import { Toast } from 'primereact/toast';

export default class ListadoRoles extends Component {

    constructor() {
        super();
        this.state = {
            globalFilter: null
        };
        this.carservice = new ClienteService();
    }

    componentDidMount() {
        this.carservice.getClientes().then(data => this.setState({cars: data}));
    }


    permisos(column){
      this.setState({visible:true,item:column.rowData});
    }

    usuarios(column){
      this.setState({visible2:true,item:column.rowData});
    }

    editar(column){
      this.setState({visible3:true,item:column.rowData});
    }

    actionTemplate(rowData, column) {
      return (<div>
        <Button
          type="button" icon="pi pi-list" onClick={e=> this.permisos(column)}
          className=""/>
        <Button
          type="button" icon="pi pi-users" onClick={e=> this.usuarios(column)}
          className="p-button-warning"
        />
        <Button
          type="button" icon="pi pi-pencil" onClick={e=> this.editar(column)}
          className=""/>
      </div>);
    }

    render() {
        let header = (
            <div style={{'textAlign':'left'}}>
                <i className="pi pi-search" style={{margin:'4px 4px 0 0'}}></i>
                <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="Global Search" size="50"/>
            </div>
        );

        return (<div>
          <Toast ref={(el) => this.growl = el} />
          <Dialog header="Permisos"  visible={this.state.visible} style={{width: '50vw',height:'90vh',overflow:'scroll'}} modal={true} onHide={() => this.setState({visible: false})}>
            <ListadoPermisos  growl={this.growl} onSaved={()=> this.setState({visible:false})}/>
          </Dialog>
          <Dialog header="Usuarios"  visible={this.state.visible2} style={{width: '50vw',height:'90vh',overflow:'scroll'}} modal={true} onHide={() => this.setState({visible2: false	})}>
            <Usuarios  growl={this.growl} onSaved={()=> this.setState({visible2:false})}/>
          </Dialog>
          <Dialog header="Editar"  visible={this.state.visible3} style={{width: '50vw',height:'90vh',overflow:'scroll'}} modal={true} onHide={() => this.setState({visible3: false})}>
            <EditarRol  growl={this.growl} onSaved={()=> this.setState({visible3:false})}/>
          </Dialog>
            <DataTable value={this.state.cars} paginator={true} rows={10} header={header} globalFilter={this.state.globalFilter}>
                <Column field="razon_social" header="Rol" />
                <Column field="rfc" header="Fecha de creación" />
                <Column field="identidad_tributaria" header="Fecha de último cambio" />
                <Column body={this.actionTemplate.bind(this)} header="Opciones" style={{ textAlign: 'center'}} />
            </DataTable>
            </div>
        );
    }
}
