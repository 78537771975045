import React from "react";
import { Card, CardBody, Container, Row, Col, CardTitle, InputGroupText, InputGroup, Input } from "reactstrap";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import moment from "moment";
import Select from "react-select";
import { Panel } from 'primereact/panel';

import OperadorServices from "controllers/operador/operador-service";
import RutaService from "controllers/ruta/rutaservice";
import ClienteService from "controllers/cliente/clienteservice";
import { InputNumber } from "primereact/inputnumber";
import { InputMask } from "primereact/inputmask";
import CustomAddress from "../CustomAddress";
const options = [];
const options_si_no = [
  { label: "Sí", value: "Sí" },
  { label: "No", value: "No" },
];
const stops_data = [
  {
    key: "chk_s1",
    chk_s1: true,
  },
  {
    key: "chk_s2",
    chk_s2: true,
  },
  {
    key: "chk_s3",
    chk_s3: true,
  },
  {
    key: "chk_s4",
    chk_s4: true,
  },
  {
    key: "chk_s5",
    chk_s5: true,
  },
  {
    key: "chk_s6",
    chk_s6: true,
  },
  {
    key: "chk_s7",
    chk_s7: true,
  },
  {
    key: "chk_s8",
    chk_s8: true,
  },
  {
    key: "chk_s9",
    chk_s9: true,
  },
  {
    key: "chk_s10",
    chk_s10: true,
  },
];
const frecuencias = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
  { label: "19", value: "19" },
  { label: "20", value: "20" },
];
const tipo_unidades = [
  { value: "Rabón", label: "Rabón" },
  { value: "3.5 Toneladas", label: "3.5 Toneladas" },
  { value: "48 pies", label: "48 pies" },
  { value: "53 pies", label: "53 pies" },
  { value: "Torton", label: "Torton" },
  { value: "Vehículo", label: "Vehículo" },
];

const categorias = [
  { label: "OW", value: "OW" },
  { label: "RT", value: "RT" },
];
class CrearVersion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rutas: [],
      chk_s1: true,
      chk_s2: true,
      chk_s3: true,
      chk_s4: true,
      chk_s5: true,
      chk_s6: true,
      chk_s7: true,
      chk_s8: true,
      chk_s9: true,
      chk_s10: true,
      ruta_id: null,
      tarifa_servicio: 0,
      tarifa_stop: 0,
      comision_stop: 0,
      comision_servicio: 0,
      total_comision_servicio: 0,
      submit_disabled:false
    };
    this.servicio = new OperadorServices();
    this.servicioR = new RutaService();
    this.servicio = new ClienteService();

    this.servicio.getClientes().then((data) => {
      console.log("data from crear-version.js", data);
      let arrClientes = [];
      data.forEach((item) => {
        if (item.estatus !== 0) {
          arrClientes.push({
            value: item.id,
            label: item.razon_social,
            rfc: item.rfc,
          });
        }
      });
      this.setState({ clientes: arrClientes });
    });

    this.handleSubmit = this.handleSubmit.bind(this);
    this.seleccionar_cliente = this.seleccionar_cliente.bind(this);
    this.conteoDeStops = this.conteoDeStops.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    let that = this;

    let mensajes = [];
    if (!this.state.cliente_id) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione un cliente",
      });
    }
    if (!this.state.ruta_id) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione una ruta",
      });
    }
    if (!this.state.version) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Ingrese una versión",
      });
    }
    if (!this.state.leg) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Ingrese un Leg",
      });
    }
    if (!this.state.categoria) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione una categoría",
      });
    }
    if (!this.state.tipo_unidad) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione el tipo de unidad",
      });
    }
    if (!this.state.tipo_unidad) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione el tipo de unidad",
      });
    }
    // if (!this.state.caja_drop) {
    // 	mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione el uso de caja drop' });
    // }
    // if (!this.state.frecuencia_semanal) {
    // 	mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione la frecuencia semanal' });
    // }
    if (!this.state.direccion_origen) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Ingrese la Planta de origen",
      });
    }
    if (!this.state.direccion_final) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Ingrese la Planta final",
      });
    }
    if (!this.state.tarifa_servicio) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Ingrese la tárifa de servicio",
      });
    }
    // if (!this.state.comision_servicio) {
    //   mensajes.push({
    //     severity: "error",
    //     summary: "Información incompleta",
    //     detail: "Ingrese la comisión por servicio",
    //   });
    // }
    // if (!this.state.total_comision_servicio) {
    //   mensajes.push({
    //     severity: "error",
    //     summary: "Información incompleta",
    //     detail: "Ingrese la total comisión por servicio",
    //   });
    // }
    // if (!this.state.tarifa_stop) {
    // 	mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Ingrese la tárifa por stop' });
    // }
    // if (!this.state.comision_stop) {
    // 	mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Ingrese la comisión por stop' });
    // }
    // if (!this.state.kilometraje) {
    // 	mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Ingrese la kilometraje' });
    // }
    let suData = this.state;
    delete suData.submit_disabled;
    suData["tarifa_total"] =
      parseInt(this.state.tarifa_servicio) + this.conteoDeStops().sum;
    // suData[]
    if (mensajes.length) {
      this.growl.show(mensajes);
    } else {
      this.setState({ submit_disabled: true });
      this.servicioR.guardarVersionRuta(suData).then((res) => {
        this.setState({ submit_disabled: false });
        if (res && res.result == "OK") {
          that.growl.show({
            severity: "success",
            summary: "Operación completada",
            detail: "Información guardada correctamente",
          });
          setTimeout(function () {
            that.props.history.push("/app/clientes/rutas/consulta");
          }, 500);
        }
      });
    }
  }

  seleccionar_cliente(event) {
    this.setState({ rutas: [], ruta_id: "" });

    this.setState({ cliente_id: event.value });
    this.servicioR.getRutas(event.value).then((data) => {
      let arrRutas = [];
      data.forEach((item) => {
        arrRutas.push({ value: item.id, label: item.id_ruta });
      });
      this.setState({ rutas: arrRutas });
    });
  }

  conteoDeStops() {
    let sum = 0,
      comision = 0;
    for (let i = 1; i <= 10; i++) {
      if (this.state[`stop${i}`]) {
        let record = JSON.parse(this.state[`stop${i}`]);
        if (
          record["value_costo_del_stop"] &&
          Number(record["value_costo_del_stop"])
        ) {
          sum += Number(record["value_costo_del_stop"]);
        }
        if (
          record["comision_a_operador"] &&
          Number(record["comision_a_operador"])
        ) {
          comision += Number(record["comision_a_operador"]);
        }
      }
    }
    console.log("CrearVersion -> sum, comision", sum, comision);
    return { sum, comision };
  }

  inputChangeHandler(data) {
    this.setState({ [data.key]: !this.state[data.key] });
  }

  ReceiveAddress = (data) => {
    if (data.step === 100) {
      this.setState({ [`direccion_origen`]: JSON.stringify(data) });
    } else if (data.step === 101) {
      this.setState({ [`direccion_final`]: JSON.stringify(data) });
    } else if (data.step) {
      this.setState({ [`stop${data.step}`]: JSON.stringify(data) });
      this.conteoDeStops();
    }
  };

  commisionCalcu = (val) => {
    let rota_commision = 0
    this.setState({ comision_servicio: (val? val: this.state.tarifa_servicio/10)+ rota_commision })
  }

  render() {
    const es = {
      firstDayOfWeek: 1,
      dayNames: [
        "domingo",
        "lunes",
        "martes",
        "miércoles",
        "jueves",
        "viernes",
        "sábado",
      ],
      dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
      dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
      monthNames: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      monthNamesShort: [
        "ene",
        "feb",
        "mar",
        "abr",
        "may",
        "jun",
        "jul",
        "ago",
        "sep",
        "oct",
        "nov",
        "dic",
      ],
    };
    return (
      <div className="container">
<Panel header="Agregar Versión De Ruta">
        <Toast ref={(el) => (this.growl = el)} />
          <form onSubmit={this.handleSubmit}>
            <Row>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Cliente</label>
                  <Select
                    options={this.state.clientes}
                    onChange={this.seleccionar_cliente}
                    placeholder="Elegir Cliente"
                  />
                </div>
              </Col>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>ID de Ruta</label>
                  <br />
                  <Select
                    options={this.state.rutas}
                    onChange={(e) => this.setState({ ruta_id: e.value })}
                    placeholder="Elegir Ruta"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Versión</label>
                  <input
                    type="text"
                    value={this.state.version}
                    onChange={(e) => this.setState({ version: e.target.value })}
                    className="form-control"
                  />
                </div>
              </Col>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Leg</label>
                  <input
                    type="text"
                    value={this.state.leg}
                    onChange={(e) => this.setState({ leg: e.target.value })}
                    className="form-control"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Categoría</label>
                  <Select
                    options={categorias}
                    onChange={(e) => this.setState({ categoria: e.value })}
                    placeholder="Elegir Categoría..."
                  />
                </div>
              </Col>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label>Tipo de Unidad</label>
                  <Select
                    options={tipo_unidades}
                    onChange={(e) => this.setState({ tipo_unidad: e.value })}
                    placeholder="Elegir tipo de unidad..."
                  />
                </div>
              </Col>
            </Row>
            {/* <Row>
							<Col xs="12" md="6">
								<div className="form-group">
									<label>Caja drop:</label>
									<Select options={options_si_no} onChange={e => this.setState({ caja_drop: e.value })} placeholder="Seleccione una opción" />
								</div>
							</Col>
							<Col xs="12" md="6">
								<div className="form-group">
									<label>Frecuencia semanal</label>
									<Select options={frecuencias} onChange={e => this.setState({ frecuencia_semanal: e.value })} placeholder="Elegir Frecuencia..." />
								</div>
							</Col>
						</Row> */}
            <Row>
              <Col xs="12">
                <div className="form-group">
                  <label>Planta de origen</label>
                  {/* direccion_origen */}
                  {/* <Address is_nombre={"true"} step={100} senderAdressToParent={this.ReceiveAddress} /> */}
                  <CustomAddress
                    is_nombre={"true"}
                    step={100}
                    senderAdressToParent={this.ReceiveAddress}
                    isLocalidadRequired={"false"}
                    isComisionAOperador={"false"}
                  />
                </div>
              </Col>
            </Row>
            {stops_data.map((data, i) => {
              return (
                <Row key={`multiple_${i}`}>
                  <Col xs="12">
                    <div className="form-group">
                      <label>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            this.inputChangeHandler(data);
                          }}
                        />{" "}
                        Agregar Stop {i + 1}
                      </label>
                      <div className={this.state[data.key] ? "d-none" : ""}>
                        {/* <Address is_costo_del_stop={"true"} is_nombre={"true"} step={i + 1} senderAdressToParent={this.ReceiveAddress} /> */}
                        <CustomAddress
                          requiredField={"false"}
                          is_costo_del_stop={"false"}
                          is_nombre={"true"}
                          step={i + 1}
                          senderAdressToParent={this.ReceiveAddress}
                          isLocalidadRequired={"false"}
                          isComisionAOperador={"true"}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              );
            })}

            <Row>
              <Col xs="12">
                <div className="form-group">
                  <label>Planta final </label>
                  <CustomAddress
                    is_nombre={"true"}
                    step={101}
                    senderAdressToParent={this.ReceiveAddress}
                    isLocalidadRequired={"false"}
                    isComisionAOperador={"false"}
                  />
                </div>
              </Col>
            </Row>
            {/* <Row>
							<Col xs="12" md="6">
								<div className="form-group">
									<label>Kilometraje</label><br />
									<InputNumber value={this.state.kilometraje} onChange={e => this.setState({ kilometraje: e.value })} mode="decimal" minFractionDigits={2} maxFracionDigits={2} />
								</div>
							</Col>
						</Row> */}
            <Row>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label for="tarifa_servicio">Tarifa por servicio</label>
                  <br />
                  {/* <InputNumber
                    onValuChange={(e) =>
                     { 
                      this.setState({ tarifa_servicio: e.value });
                      this.commisionCalcu(e.value)
                      }
                    }
                    inputId="new"
                    mode="currency"
                    currency="MXN"
                    locale="es-MX"
                  /> */}
                    <InputGroup>
                    <InputGroupText>
                      $
                    </InputGroupText>
                    <Input placeholder="0.00" id="tarifa_servicio" type="number" onChange={(e) =>
                       { 
                          this.setState({ tarifa_servicio: e.target.value });
                          this.commisionCalcu(e.target.value)
                       }
                     }/>
                    </InputGroup>
                </div>
              </Col>
              <Col xs="12" md="6">
                <div className="form-group">
                  <label for="comision_servicio">Comisión a operador por servicio</label>
                  <br />
                  {/* <InputNumber
                    onChange={(e) =>
                      this.setState({ comision_servicio: e.value })
                    }
                    // value={
                    //   this.state.comision_servicio +
                    //   this.state.tarifa_servicio / 10
                    // }
                    value={
                      this.state.comision_servicio ?  this.state.comision_servicio :
                      this.state.tarifa_servicio / 10
                    }
                    mode="currency"
                    currency="MXN"
                    locale="es-MX"
                  /> */}
                   <InputGroup>
                    <InputGroupText>
                      $
                    </InputGroupText>
                    <Input placeholder="0.00"
                    id ="comision_servicio"
                    type="number"   
                    value={
                      this.state.comision_servicio ?  this.state.comision_servicio :
                      this.state.tarifa_servicio / 10
                    }
                    onChange={(e) =>
                      this.setState({ comision_servicio: e.target.value })
                    }
                    />
                    </InputGroup>
                </div>
              </Col>
            </Row>
            {/* <Row>
							<Col xs="12" md="6">
								<div className="form-group">
									<label>Tarifa por stop</label><br />
									<InputNumber onChange={e => this.setState({ tarifa_stop: e.value })} mode="currency" currency="MXN" locale="es-MX" />

								</div>
							</Col>
							<Col xs="12" md="6">
								<div className="form-group">
									<label>Comisión  a operador por stop</label><br />
									<InputNumber onChange={e => this.setState({ comision_stop: e.value })} mode="currency" currency="MXN" locale="es-MX" />

								</div>
							</Col>
						</Row> */}
            <Row>
              {/* <Col xs="12" md="6">
                <div className="form-group">
                  <label>Tarifa Total</label>
                  <br />
                  <InputNumber
                    value={
                      parseInt(this.state.tarifa_servicio) +
                      this.conteoDeStops().sum
                    }
                    mode="currency"
                    currency="MXN"
                    locale="es-MX"
                    readonly
                  />
                </div>
              </Col> */}
              <Col xs="12" md="6">
                <div className="form-group">
                  <label for="total_comision_servicio">Comisión total de Stops</label>
                  <br />
                  {/* <InputNumber
                    onChange={(e) =>
                      this.setState({ total_comision_servicio: e.value })
                    }
                    // value={
                    //   this.state.total_comision_servicio +
                    //   this.conteoDeStops().comision / 10 +
                    //   this.state.tarifa_servicio / 10
                    // }
                    value={
                      this.state.total_comision_servicio? this.state.total_comision_servicio: this.conteoDeStops().comision 
                    }
                    mode="currency"
                    currency="MXN"
                    locale="es-MX"
                  /> */}         
                  <InputGroup>
                    <InputGroupText>
                      $
                    </InputGroupText>
                    <Input
                      placeholder="0.00"
                      id="total_comision_servicio"
                      type="number"
                      value={
                          this.state.total_comision_servicio? this.state.total_comision_servicio: this.conteoDeStops().comision 
                      }
                      onChange={(e) =>
                        this.setState({ total_comision_servicio: e.target.value })
                      }
                      />
                    </InputGroup>
                </div> 
              </Col>
            </Row>

            <center>
              {" "}
              <button disabled={this.state.submit_disabled} className="btn btn-primary">Guardar</button>
              {(this.state.submit_disabled)?<p>Cargando...</p>:<></>}

            </center>
          </form>
      </Panel>
      </div>
      
    );
  }
}

export default CrearVersion;
