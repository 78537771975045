	import axios from 'axios';
import {url} from '../../constants';

export default class Service {

			/**
			Servicios para  las ParadasAutorizadas
			*/

			guardarParada(contenido){
				console.log(contenido)
					return axios.post(url + 'parada-autorizada/guardar',{reporte:contenido },  {
						}).then(res => res.data);
			}

			listadoParada(){
				return axios.get(url + 'parada-autorizada/listado',{})
								.then(res => res.data);
			}

			eliminarParada(id){
					return axios.post(url + 'parada-autorizada/eliminar',{
						id:id

					}).then(res => res.data);
			}



			actualizarParada(objeto){

			  let objeto_nuevo =Object.assign({}, objeto);

			//	delete embarque.facturar_a;

					return axios.post(url + 'parada-autorizada/actualizar',{
						contenido:objeto_nuevo

					}).then(res => res.data);
			}




}
