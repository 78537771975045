// import Dashboard from '../views/exnac/dashboard';

//Ui-components Dropdown
import MailBox from "../views/exnac/mailbox";
import CrearCartaPorte  from "../views/exnac/carta-porte/crear";
import ConsultaCartaPorte from "../views/exnac/carta-porte/consulta"
import CrearClientes from "../views/exnac/clientes/crear";
import ConsultaClientes from "../views/exnac/clientes/consulta";
import Dashboard from '../views/dashboard/dashboard';

import CrearUnidades from "../views/exnac/unidades/crear";
import ConsultaUnidades from "../views/exnac/unidades/consulta";

import MantenimientoUnidades from "../views/exnac/mantenimiento/mantenimiento.js";
import OrdenesCompraMantenimiento from "../views/exnac/mantenimiento/ordenes.js";
import ReportesFallas from "../views/exnac/mantenimiento/reportes.js";
import Semaforos from "../views/exnac/mantenimiento/semaforos.js";
import CatalogoMantenimiento from "../views/exnac/mantenimiento/catalogo.js";
import CatalogoProveedores from "../views/exnac/mantenimiento/proveedores.js";


import Reparaciones from "../views/exnac/mantenimiento/reparaciones.js";
import Inventario from "../views/exnac/mantenimiento/inventario.js";
import Predictivos from "../views/exnac/mantenimiento/predictivos.js";
import Programado from "../views/exnac/mantenimiento/programado.js";

import AccesosVigilancia from "../views/exnac/vigilancia/accesos.js";
import InspeccionVigilancia from "../views/exnac/vigilancia/inspeccion-17.js";
import InspeccionVigilancia2 from "../views/exnac/vigilancia/inspeccion-7.js";
import InspeccionVigilancia3 from "../views/exnac/mantenimiento/entrega-inspeccion/listado";
import InspeccionVigilancia4 from "../views/exnac/vigilancia/checklist-despacho.js";

import ReportesVigilancia from "../views/exnac/vigilancia/reportes-vigilancia.js";


import LlantasUnidades from "../views/exnac/llantas/llantas";
import AsignacionLlantas from "../views/exnac/llantas/asignacion";
import ConsultaLlantas from "../views/exnac/llantas/consulta";

import ConsultaLlantasSema from "../views/exnac/llantas/semaforo/consulta";

import ResultadosCartaPorte from  '../views/exnac/carta-porte/resultado.js';

import ConsultaOperadores from "../views/exnac/operadores/consulta";
import CrearOperadores from "../views/exnac/operadores/crear";
import BitacoraOperadores from  "../views/exnac/operadores/bitacora";
import NominaOperadores from "../views/exnac/operadores/nomina";

import RutasPorLiquidar from "../views/exnac/embarques/porliquidar";
import RutasTransito from "../views/exnac/embarques/transito";
import RutasEvidencias from "../views/exnac/embarques/evidencias";
import NuevoEmbarque from "../views/exnac/embarques/nuevo-embarque";
import ConsultaEmbarque from "../views/exnac/embarques/consulta-embarque";
import CobranzaEmbarque from "../views/exnac/embarques/cobranza-embarque";

import CrearID from '../views/exnac/rutas/crear';
import CrearVersion from '../views/exnac/rutas/crear-version';
import ConsultaRuta from '../views/exnac/rutas/consulta';
import ParadasAutorizadas from '../views/exnac/paradas-autorizadas/index';

import ControlSellos from '../views/exnac/control-sellos/index';
import ControlSellosConsulta from '../views/exnac/control-sellos-consulta/index';
import ParadasAutorizadasConsulta from '../views/exnac/paradas-autorizadas/consulta';
import CalculoNomina  from '../views/exnac/nomina/calculo/index';
import DesgloseNomina from '../views/exnac/nomina/desglose/index';
import CargaNomina  from '../views/exnac/nomina/carga/index';
import CategoriasNomina  from '../views/exnac/nomina/calculo/categoria/index';
import EmpleadosNomina  from '../views/exnac/empleado/index';
import Departamento from '../views/exnac/empleado/departamento/index';
import Puesto from '../views/exnac/empleado/puesto/index';
import ConceptosNomina  from '../views/exnac/nomina/calculo/conceptos/index';


import ClaveCatalogo from '../views/exnac/claves/index';
import ClaveConsulta from '../views/exnac/transacciones/index';
import { components } from 'react-select';
import AdminUser from "views/exnac/admin-user/admin-user";
import CrearUsuarioAdministrador from "views/exnac/admin-user/crear";
import EditarUsuarioAdministrador from "views/exnac/admin-user/editar";


var ThemeRoutes = [
	{
		name: 'Tablero',
		path:"/app/tablero",
		module: 'tablero',
		pathName:'tablero',
		collapse:true,
		component: Dashboard,
		state:0,
		child:[
			{
				name:"Tablero base",
				path:'/app/tablero',
				module: 'tablero',
				component: Dashboard,
				state:0.1,
			},
		],
		icon: 'pi pi-table',
	},
	{
		name: 'Usuarios administradores',
		path:"/app/usuarios-administradores",
		module: 'usuarios-administradores',
		pathName:'usuarios-administradores',
		collapse:true,
		state:1,
		child:[
			{
				name:"Alta Cliente Nuevo",
				path:'/app/usuario-administrador/crear',
				module: 'usuario-administrador_crear',
				component: CrearUsuarioAdministrador,
				state:111
			},{
				name:"Consulta",
				path:'/app/usuarios-administradores/consulta',
				module: 'usuarios-administradores_consulta',
				component: AdminUser,
				state:112
			},
		],
		icon: 'pi pi-wrench',
	},
	{
		name: 'Clientes',
		path:"/app/clientes",
		pathName:'clientes',
		module: 'clientes',
		collapse:true,
		state:2,
		child:[
			{
				name:"Alta Cliente Nuevo",
				module: 'clientes_crear',
				path:'/app/clientes/crear',
				component: CrearClientes,
				state:21
			},{
				name:"Consulta",
				path:'/app/clientes/consulta',
				module: 'clientes_consulta',
				component: ConsultaClientes,
				state:22
			},{
				name:"Rutas",
				collapse:true,
				pathName:'rutas',
				module: 'rutas_clientes',
				state:23,
				subchild:[
					{name:"Alta de ID",path:"/app/clientes/rutas/id", module: 'clientes_rutas_id',
					component:CrearID ,state:231},
					{name:"Alta de Versiones",path:"/app/clientes/rutas/version", module: 'clientes_rutas_version',
					component:CrearVersion,state:232},
					{name:"Consulta ID",path:"/app/clientes/rutas/consulta", module: 'clientes_rutas_consulta',
					component:ConsultaRuta,state:233}
				]
			}

		],
		icon: 'pi pi-users',
	},	{
		name: 'Unidades',
		collapse:true,
		path:"/app/unidades",
		module: 'unidades',
		pathName:'unidades',
		state:3,
		child:[
			{
				name:"Alta de Unidad",
				path:'/app/unidades/crear',
				module: 'unidades_crear',
				component: CrearUnidades,
				state:31
			},
			{
				name:"Consulta",
				path:'/app/unidades/consulta',
				module: 'unidades_consulta',
				component: ConsultaUnidades,
				state:32
			},
			{
				name:"Llantas",
				collapse:true,
				pathName:'llantas',
				module: 'llantas_unidades',
				state:33,
				subchild:[
						{
							name: "Alta",
							path:'/app/unidades/llantas/alta',
							module: 'unidades_llantas_alta',
							component: LlantasUnidades,
							state:331
						},
						{
							name: "Asignar",
							path:'/app/unidades/llantas/asignar',
							module: 'unidades_llantas_asignar',
							component: AsignacionLlantas,
							state:332
						}
						,
						{
							name: "Inventario",
							path:'/app/unidades/llantas/consultar',
							module: 'unidades_llantas_consultar',
							component: ConsultaLlantas,
							state:333
						},
						{
							name: "Semáforo",
							path:'/app/unidades/llantas/semaforo/consultar',
							module: 'unidades_llantas_semaforo_consultar',
							component: ConsultaLlantasSema,
							state:333
						}

					]
			}
		],
		icon: 'pi pi-briefcase',
	},	{
		name: 'Operadores',
		collapse:true,
		state:4,
		path:"/app/operadores",
		module: 'operadores',
		pathName:'operadores',
		child:[
			{
				name:"Alta de operador",
				path:'/app/operadores/crear',
				module: "operadores_crear",
				component:CrearOperadores,
				state:41
			},
			{
				name:"Consulta",
				path:'/app/operadores/consulta',
				module: "operadores_consulta",
				component:ConsultaOperadores,
				state:42
			}
		],
		icon: 'pi pi-user'

		},
		{
			path:"/app/embarque",
			pathName:'embarque',
			name: 'Embarques',
			module: 'embarques',
			collapse:true,
			state:5,
			child:[
				{
					name:"Nuevo Embarque",
					path:'/app/embarque/nuevo',
					module: "embarque_nuevo",
					component: NuevoEmbarque,
					state:51
				},
				{
					name:"Consulta",
					path:'/app/embarques/consulta',
					module: "embarques_consulta",
					component: ConsultaEmbarque,
					state:52
				},
		],
		icon: 'pi pi-truck'

	},
	{
		name:"Entrega inspección",
		path:'/app/entrega-inspeccion',
		pathName:'entrega-inspeccion',
		module: "entrega-inspeccion",
		collapse:true,
		component: InspeccionVigilancia3,
		state:64,
		child:[
			{
				name:"Nuevo Reporte Entrega",
				path:'/app/entrega-inspeccion/nuevo',
				module: 'entrega-inspeccion_nuevo',
				component: InspeccionVigilancia,
				state:641
			},
			{
				name:"Consulta",
				path:'/app/entrega-inspeccion/consulta',
				module: 'entrega-inspeccion_consulta',
				component: InspeccionVigilancia3,
				state:642
			},
	],
		icon: 'pi pi-book'
	},
	{
		path:"/app/mantenimiento",
		pathName:'mantenimiento',
		name: 'Mantenimiento',
		module: 'mantenimiento',
		collapse:true,
		state:6,
		child:[
			{
				name:"Catálogo de actividades",
				path:'/app/mantenimiento/catalogo',
				module: "mantenimiento_catalogo",
				component: CatalogoMantenimiento,
				state:61
			},
			{
				name:"Catálogo de proveedores",
				module: "mantenimiento_proveedores",
				path:'/app/mantenimiento/proveedores',
				component: CatalogoProveedores,
				state:62
			},
			{
				name:"Catálogo de componentes",
				module: "mantenimiento_componentes",
				path:'/app/mantenimiento/componentes',
				component: CatalogoProveedores,
				state:63
			},
	
			{
				name:"Preventivo",
				path:'/app/mantenimiento/preventivo',
				pathName:'preventivo',
				module: "preventivo_mantenimiento",
				collapse:true,
				state:65,
				subchild:[
					{
						name:"Reporte de fallas",
						path:'/app/mantenimiento/preventivo/fallas',
						module: "mantenimiento_preventivo_fallas",
						component: ReportesFallas,
						state:651
					},
					{
						name:"Ordenes de compra",
						path:'/app/mantenimiento/preventivo/ordenes',
						module: "mantenimiento_preventivo_ordenes",
						component: OrdenesCompraMantenimiento,
						state:652
					}
					// ,
					// {
					// 	name:"Semáforos",
					// 	path:'/app/mantenimiento/preventivo/semaforos',
					// 	component: Semaforos
					// }
				]
			},
			{
				name:"Correctivo",
				path:'/app/mantenimiento/correctivo',
				pathName:'correctivo',
				module: "correctivo_mantenimiento",
				collapse:true,
				state:7,
				subchild:[
					{
						name:"Ordenes de servicio",
						path:'/app/mantenimiento/correctivo/ordenes-servicio',
						module: "mantenimiento_correctivo_ordenes-servicio",
						component: MantenimientoUnidades,
						state:71
					},
					{
						name:"Reparaciones",
						path:'/app/mantenimiento/correctivo/reparaciones',
						module: "mantenimiento_correctivo_reparaciones",
						component: Reparaciones,
						state:72
					}
				]
			}
	],
		icon: 'pi pi-wrench'

	},
	{
		path: "/app/nomina",
		pathName:'nomina',
		name: 'Nómina',
		icon: "pi pi-wallet",
		module: 'nomina',
		component: MailBox,
		collapse:true,
		state:10,
		child:[
				{
					name:"Carga",
					path:'/app/nomina/carga',
					module: "nomina_carga",
					component: CargaNomina,
					state:103
				},{
					name:"Desglose",
					path:'/app/nomina/desglose',
					module: "nomina_desglose",
					component: DesgloseNomina,
					state:104
				}
		]
	}
	,{
		path:"/app/vigilancia",
		pathName:'vigilancia',
		module: 'vigilancia',
		name: 'Vigilancia',
		collapse:true,
		state:11,
		child:[
				{
					name:"Acceso",
					path:'/app/vigilancia/accesos',
					module: "vigilancia_accesos",
					component: AccesosVigilancia,
					state:112
				},
			

		],
		icon: 'pi pi-verified'

	},
	{
		path: "/app/inspeccion/",
		pathName:'/app/inspeccion',
		name: 'Inspección',
		module: 'inspeccion',
		state:12,
		collapse:true,
		icon: 'pi pi-ticket',
		child:[
			{
				name:"Inspección Agrícola",
				path:'/app/inspeccion/inspeccion-17',
				module: "inspeccion_inspeccion-17",
				component: InspeccionVigilancia,
				state:113
			},
			{
				name:"Inspección 7 puntos",
				path:'/app/inspeccion/inspeccion-7',
				module: "inspeccion_inspeccion-7",
				component: InspeccionVigilancia2,
				state:114
			},
			{
				name:"CheckList Despacho",
				path:'/app/inspeccion/checklist-despacho',
				module: "inspeccion_checklist-despacho",
				component: InspeccionVigilancia4,
				state:115
			},
			{
				name:"Reportes",
				path:'/app/inspeccion/reportes',
				module: "inspeccion_reportes",
				component: ReportesVigilancia,
				state:116
			}
		]
	},
	// {
	// 	path: "/app/paradas-autorizadas",
	// 	name: 'Paradas autorizadas',
	// 	state:12,
	// 	collapse:true,
	// 	icon: 'mdi mdi-car-multiple',
	// 	child:[
	// 		{
	// 			path: "/app/paradas-autorizadas/catalogo",
	// 			name: 'Catalogo',
	// 			state:121,
	// 			component: ParadasAutorizadas
	// 		}
	// 	]
	// }
	// ,{
	// 	path: "/app/control-sellos",
	// 	name: 'Control de sellos',
	// 	state:13,
	// 	collapse:true,
	// 	icon: 'mdi mdi-lock',
	// 	child:[
	// 		{
	// 			path: "/app/control-sellos/listado",
	// 				name: 'Catálogo',
	// 			state:131,
	// 			component: ControlSellos
	// 		},
	// 		{
	// 			path: "/app/control-sellos/consulta",
	// 			name: 'Consulta',
	// 			state:132,
	// 			component: ControlSellosConsulta
	// 		},
	// 	]
	// },{
	// 	path: "/app/claves",
	// 	name: 'Clave-Huella',
	// 	state:14,
	// 	collapse:true,
	// 	icon: 'mdi mdi-draw',
	// 	child:[
	// 		{
	// 			path: "/app/claves/catalogo",
	// 			name: 'Catálogo',
	// 			state:141,
	// 			component: ClaveCatalogo
	// 		},
	// 		{
	// 			path: "/app/claves/consulta",
	// 			name: 'Transacciones',
	// 			state:142,
	// 			component: ClaveConsulta
	// 		},
	// 	]
	// },
];
export default ThemeRoutes;
