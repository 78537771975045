import React, { Component } from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import VigilanciaService from  'controllers/vigilancia/vigilancia-service';
import { url } from "../../../../constants";
import CheckPermissions from 'controllers/permissions/services';

export default class Listado extends Component {

    constructor() {
        super();
        this.state = {
          cars:[],
            globalFilter: null,
            visible:false,
            visible2:false
        };
        this.servicio = new VigilanciaService();
        this.eliminar = this.eliminar.bind(this);
        this.editar = this.editar.bind(this);
        this.permissionService = new CheckPermissions();
    }

    componentDidMount() {
        this.servicio.listadoReporteAccesos().then(data => this.setState({cars: data}));
    }

    eliminar(column,rowData){
      this.setState({visible2:true,selectedItemIndex:column.rowIndex,selectedItem:rowData});

    }

    editar(column,rowData){
      //this.setState({visible:true,item:column.rowData});
      this.props.history.push({
         pathname:'/app/vigilancia/accesos/editar',
         state: rowData
       });
    }

    confirmar_borrado(){

      this.setState({visible2:false});
      this.setState({
        cars: this.state.cars.filter((val,i) => i !== this.state.selectedItemIndex)
      });
      let that = this;
      this.servicio.eliminarReporte(this.state.selectedItem.id).then(res =>  {
          if(res && res.result == 'OK'){
            that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Registro borrado'});
          }
      });

    }

    fechaHoraTemplate(rowData,column)
    {
      return (
        <>{rowData.fecha} {rowData.hora}</>
      )
    }
    actionTemplate(rowData, column) {
      return (<div>
        {this.permissionService.checkPermission('vigilancia_accesos_editar') && <Button
          type="button" 
          icon="pi pi-pencil" 
          onClick={e=> this.editar(column,rowData)}
          className="mr-2 p-button-warning"
          tooltip="Editar"
          tooltipOptions={{ position: 'top' }}
        />}
        {this.permissionService.checkPermission('vigilancia_accesos_abrir_documento') && (rowData.evidencia)?
                    <a  href={url+"vigilancia-accesos/"+rowData.evidencia} target={"_blank"}>
                      <Button 
                        icon="pi pi-images" 
                        className="p-button-warning mr-2"
                        tooltip="Abrir documento"
                        tooltipOptions={{ position: 'top' }}
                      />
                    </a>
          :<></>}
          {this.permissionService.checkPermission('vigilancia_accesos_borrar') && <Button
            type="button" 
            icon="pi pi-trash" 
            onClick={e=> this.eliminar(column,rowData)}
            className="p-button-warning mr-2"
            tooltip="Borrar"
            tooltipOptions={{ position: 'top' }}
          />}
      </div>);
    }

    render() {
        const footer = (
          <div>
                <Button label="Sí" className='p-button-warning mr-2' icon="pi pi-check" onClick={this.confirmar_borrado.bind(this)} />
                <Button label="No" className='p-button-warning' icon="pi pi-times" onClick={e=> this.setState({visible2:false})}/>
            </div>

        );

        return (<div>
            <Toast ref={(el) => this.growl = el} />
            <Dialog header="Eliminar"  footer={footer} visible={this.state.visible2} style={{width: '50vw'}} modal={true} onHide={() => this.setState({visible: false,visible2:false	})}>
              ¿Confirma eliminar?
            </Dialog>
            <DataTable value={this.state.cars}  rows={10} paginator={true}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
            <Column field="nombre" header="Nombre" />
            <Column field="tipo" header="Tipo" />
            <Column body={this.fechaHoraTemplate.bind(this)} header="Fecha/Hora" />
            <Column body={this.actionTemplate.bind(this)} header="Opciones" style={{ textAlign: 'center'}} />
            </DataTable>

      </div>);
    }
}
