import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Galleria } from 'primereact/galleria';

export default function DocumentSlider(props) {
	const [activeIndex, setActiveIndex] = useState(0);    
    const [images, setImages] = useState(null);
    const galleria = useRef(null);

    const responsiveOptions = [
        {
            breakpoint: '1500px',
            numVisible: 5
        },
        {
            breakpoint: '1024px',
            numVisible: 3
        },
        {
            breakpoint: '768px',
            numVisible: 2
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];

    useEffect(() => {
		if (props.events) {
			props.events.onOpenSlider = onOpenSlider;
		}
		setImages(props.data)
    }, [props.data]);
	const onOpenSlider = (slide = 0) => {
		setActiveIndex(slide); 
		galleria.current.show()
	}

    const itemTemplate = (item) => {
        return <img src={item.itemImageSrc} alt={item.alt} style={{ width: '100%', display: 'block' }} />;
    }

    const thumbnailTemplate = (item) => {
        return <img src={item.thumbnailImageSrc} alt={item.alt} style={{ display: 'block' }} />;
    }
	const captionTemplate = (item) => {
        return (
            <React.Fragment>
                <div className="text-xl mb-2 font-bold">{item.title}</div>
                <p className="text-white">{item.alt}</p>
            </React.Fragment>
        );
    }
    return (
        <div className="card flex justify-content-center">
            <Galleria 
				ref={galleria} 
				value={images} 
				responsiveOptions={responsiveOptions} 
				numVisible={9} 
				activeIndex={activeIndex} 
				onItemChange={(e) => setActiveIndex(e.index)}
				style={{ maxWidth: '50%' }} 
				item={itemTemplate} 
				thumbnail={thumbnailTemplate}
				caption={captionTemplate}
				showThumbnails={false}
                circular fullScreen showItemNavigators  />
        </div>
    )
}