import React from "react";
import {
	Card,
	CardBody,Container, Row,Col,CardTitle

} from 'reactstrap';

import { Toast } from 'primereact/toast';
import moment from 'moment';
import Select from 'react-select';
import MantenimientoService from  'controllers/mantenimiento/mantenimiento-service';
import UnidadService  from 'controllers/unidad/unidadService';
import OperadorServices  from 'controllers/operador/operador-service';
import {Button} from 'primereact/button';
import PCalendar from '../../../ui-components/calendar';
import { Panel } from 'primereact/panel';

class CrearReporteFalla extends React.Component {
	constructor(props){
		super(props);
		this.state={
			folio:"Sin asignar",
			fecha: new Date(),
			lugares:[
				{value:"Externo",label:"Externo"},
				{value:"Interno",label:"Interno"}
			]
		}
		this.servicio = new MantenimientoService();
		this.servicioU = new UnidadService();
		this.servicioO = new OperadorServices();
		this.getCommonDataFromChilds = this.getCommonDataFromChilds.bind(this);

		this.servicioU.getUnidades().then(data =>  {
			let arrUnidades = [];
			data.forEach(item=>{
				arrUnidades.push({value:item.id,label:item.placas});

			});
			this.setState({unidades: arrUnidades});
		});
		this.servicioO.getOperadores().then(data =>  {
			let arrOperadores = [];
			data.forEach(item=>{
				arrOperadores.push({value:item.usuario_id,label:item.nombre});

			});
			this.setState({operadores: arrOperadores});
		});

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit(event) {
				event.preventDefault();

				 console.log(this.state);
				let that = this;

				let datos ={};
				const data = new FormData();
				datos.folio = this.state.folio;
				datos.unidad = this.state.unidad;
				datos.km = this.state.km;
				datos.operador = this.state.operador;
				datos.fecha =  moment(this.state.fecha).format('YYYY-MM-DD HH:mm').toString() ;
				datos.lugar = this.state.lugar;
				datos.descripcion = this.state.descripcion;
				datos.actividad = this.state.actividad;
				datos.personal = this.state.personal;


				data.append("ruta",JSON.stringify(datos));

				let mensajes = [];

				if(!this.state.unidad){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Seleccione una unidad'});
				}
				if(!this.state.km){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Ingrese el KM'});
				}
				if(!this.state.operador){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Seleccione el operador'});
				}
				if(!this.state.fecha){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Ingrese la fecha '});
				}
				if(!this.state.lugar){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Ingrese el lugar de reparación'});
				}
				if(!this.state.descripcion){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Ingrese la descripción de falla'});
				}
				if(!this.state.evidencia){
						data.append("evidencia",this.state.evidencia);
						//mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Seleccione la evidencia'});
				}
				if(!this.state.actividad){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Ingrese las actividades a realizar'});
				}
				if(!this.state.personal){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Ingrese el personal que autoriza'});
				}

				// if(!this.state.evidencia){
				// 		mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Seleccione la unidad'});
				// }

				if(mensajes.length){
					this.growl.show(mensajes);
				}else{
					this.servicio.guardarOrdenServicio(datos).then(res =>  {
							if(res && res.result == 'OK'){

								that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Información guardada correctamente'});
								setTimeout(function(){
										 	that.props.history.push('/app/mantenimiento/correctivo/ordenes-servicio');
								 }, 500);

							}else if(res && res.result == 'EXISTE'){
								that.growl.show({severity: 'error', summary: 'El Id de ruta '+this.state.id_ruta+' ya se encuentra registrado', detail: 'Ingrese otro código o consulte su información para editarla.'});
							}
					});
				}
	}
	getCommonDataFromChilds(data)
	{
		switch (data.label) {
			case "fecha":
				this.setState({fecha: moment(data.value).format('YYYY-MM-DD').toString(),semana:moment(data.value).week()});
				break;
			case "hora":
				this.setState({hora: data.value});
				break;
		}
	}
	getImageInput(title,key)
	{
	  return (
		<div className="image-group">
		  <label className="title">{title}</label>
		  <br />
		  <div className="form-group">
			{!this.state[key]?.name && 
			  <div className="custom-file-upload">
				<label htmlFor={`file-upload-${key}`} className="p-button-warning p-button m-0 w-100">
				  <i className="pi pi-cloud-upload"></i> Seleccionar archivo
				</label>
				<input
				  id={`file-upload-${key}`}
				  type="file"
				  style={{ display: "none" }}
				  onChange={(e) =>
					this.setState({ [key]: e.target.files[0] })
				  }
				  chooseLabel="Seleccionar Archivo"
				/>
			  </div>
			}
			{this.state[key]?.name && 
			  <div className="file-info d-flex justify-content-between">
				<div className="label-info">
				  {this.state[key].name}
				</div>
				<div className="action-info">
				  <i
					onClick={() => {
					  this.setState({ [key]: null });
					}}
					className="pi pi-trash"
					style={{ fontSize: "1.5em", cursor: "pointer" }}
				  ></i>
				  </div>                            
			  </div>
			}
		  </div>
		</div>
	  )
	}

	render() {

		return (
			<div class="container-fluid">
			<Panel header="Alta Orden De Servicio">
<Toast ref={(el) => this.growl = el} />
				<form >
				<Row>
					<Col sm="6">
							<div className="form-group">
								<label>Folio</label><br/>
									<input type="text" readOnly  value={this.state.folio} onChange={e => this.setState({folio:e.target.value})} className="form-control"/>
							</div>

					</Col>
					<Col sm="6">
							<div className="form-group">
								<label>Núm. unidad</label>
								<Select options={this.state.unidades}  onChange={e =>	this.setState({unidad: e.value}) }  placeholder="Elegir unidad"/>
							</div>
					</Col>
				</Row>
				<Row>
					<Col sm="6">
							<div className="form-group">
								<label>Km actual</label><br/>
								<input type="text"  value={this.state.km} onChange={e => this.setState({km:e.target.value})}  className="form-control"/>
							</div>
					</Col>
					<Col sm="6">
						<div className="form-group">
							<label>Nombre del operador</label><br/>
							<Select options={this.state.operadores}  onChange={e =>	this.setState({operador: e.value}) } placeholder="Elegir operador"/>

						</div>
					</Col>
				</Row>
					<Row>
						<Col sm="6">
								<div className="form-group">
									<label>Fecha de reporte</label><br/>
									<PCalendar
										label={"fecha"}
										getCommonDataFromChilds={this.getCommonDataFromChilds}
										showIcon
										value={this.state.fecha}
									/>							
								</div>
						</Col>
						<Col sm="6">
							<div className="form-group">
								<label>Lugar de la reparación (taller interno o externo)</label><br/>
								<Select options={this.state.lugares} value={this.state.lugares?this.state.lugares.filter(option  => option.value == this.state.lugar):""}  onChange={e =>	this.setState({lugar: e.value}) }  placeholder="Elegir lugar"/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col sm="6">
								<div className="form-group">
									<label>Descripción de falla</label><br/>
									<input type="text"  value={this.state.descripcion} onChange={e => this.setState({descripcion:e.target.value})}  className="form-control"/>
								</div>
						</Col>
						<Col sm="6">
							{this.getImageInput('Evidencia (foto-video)', 'evidencia')}
						</Col>
					</Row>
					<Row>
						<Col sm="6">
								<div className="form-group">
									<label>Descripción de la actividad a realizar</label><br/>
									<input type="text"  value={this.state.actividad} onChange={e => this.setState({actividad:e.target.value})}  className="form-control"/>
								</div>
						</Col>
						<Col sm="6">
							<div className="form-group">
								<label>Personal que autoriza la reparación</label><br/>
								<input type="text"  value={this.state.personal} onChange={e => this.setState({personal:e.target.value})}  className="form-control"/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col sm="12" className="text-center">
							<Button className="p-button-warning" onClick={this.handleSubmit} >Guardar</Button>
						</Col>
					</Row>
					</form>
				</Panel>
			</div>
		);
	}
}

export default CrearReporteFalla;
