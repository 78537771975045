import axios from 'axios';
import {url} from '../../constants';

export default class Service {

	    getLlantas(){
        return axios.get(url + 'llantas/')
                .then(res => res.data);
			}

			getLlantasSema(){
				return axios.get(url + 'llantas/assign')
						.then(res => res.data);
					}

			getLlantaFolio(){
				return axios.get(url + 'llantas/folio')
								.then(res => res.data);
			}
			getFiltroLlantas(obj){
					return axios.get(url + 'llantas/inventario/filtro',{params:obj})  
					.then(res => res.data);
			}

			getFiltroLlantasSemaforo(obj){
				return axios.get(url + 'llantas/semaforo/filtro',{params:obj}) 
				.then(res => res.data);
		}


			// getFiltroLlantas(obj){
        	// 	return axios.get(url + 'llantas/filtro',{params:obj})
            //     .then(res => res.data);
			// }

			guardarLlanta(llanta){
				  delete llanta.ch_existente; // agregar el campo al select
					delete llanta.ch_drop;
					delete llanta.medidas;
					delete llanta.marcas;
					delete llanta.tipo_pisos;
					return axios.post(url + 'llantas/guardar',{
						llanta:llanta
					}).then(res => res.data);
			}

			asignarLlanta(datos){
				return axios.post(url + 'llantas/asignar',{
					datos:datos
				}).then(res => res.data);
			}

			eliminaLlanta(llanta_id){
					return axios.post(url + 'llantas/eliminar',{
						llanta_id:llanta_id

					}).then(res => res.data);
			}

			actualizarLlanta(llanta){
				delete llanta.ch_existente; // agregar el campo al select
				delete llanta.ch_drop;
				delete llanta.medidas;
				delete llanta.marcas;
					return axios.post(url + 'llantas/actualizar',{
						llanta:llanta
					}).then(res => res.data);
			}


}
