import React, { useState, useEffect, useRef } from "react";
import {
	Col,
	Row
} from 'reactstrap';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Fieldset } from 'primereact/fieldset';
import SignatureCanvas from 'react-signature-canvas'
import EmbarqueService from 'controllers/embarque/embarque-service';

export default function SignaturePopup(props) {
    const entregaSignatureRef = useRef();
    const recibeSignatureRef = useRef();
    const [visible, setVisible] = useState(false);
    useEffect(() => {
		if (props.events) {
			props.events.openDialog = openDialog;
		}
    }, [props.data]);
    const embarqueService = new EmbarqueService();
	const openDialog = () => {
		setVisible(true); 
	}
    const entregaClearPad = () => {
		entregaSignatureRef.current.clear();
	}
    const recibeClearPad = () => {
		recibeSignatureRef.current.clear();
	}
    const uploadFileSignature = async(file,tag) => 
	{
		const data = new FormData();
		data.append("file",file);
		data.append("tag",tag);
		return await embarqueService.uploadBase64File(data)
	}
    const handleSubmit = () => {
        const promiseEntregaSign = new Promise(async(resolve,reject)=>{
            resolve(uploadFileSignature(entregaSignatureRef.current.getTrimmedCanvas().toDataURL('image/png'),'entrega_sign_'));
        })    
        const promiseRecibeSign = new Promise(async(resolve,reject)=>{
            resolve(uploadFileSignature(recibeSignatureRef.current.getTrimmedCanvas().toDataURL('image/png'),'recibe_sign_'));
        })
        Promise.allSettled([promiseEntregaSign,promiseRecibeSign]).then((results) => {
            const finalOut = {
                entrega_sign:"",
                recibe_sign:""
            }
            for(let i =0; i<results.length;i++)
            {
                if(results[i].value.result==='OK')
                {
                    switch (results[i].value.data.tag) {
                        case "entrega_sign_":
                            finalOut.entrega_sign = results[i].value.data.file_name                            
                            break;
                        case "recibe_sign_":
                            finalOut.recibe_sign = results[i].value.data.file_name
                        break;
                        default:
                            break;
                    }
                }
            }
            props.handleUpdate(finalOut)
        });
    }
    const footerTemplate = (
        <div className="text-center">
            <Button severity="success" label="Enviar Reporte" onClick={() => handleSubmit()}  />
        </div>
    )
    return (
        <div className="card flex justify-content-center">
            <Dialog header="Firma digital" footer={footerTemplate} visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                <Row className="signature-body">
                    <Col>
                        <Fieldset legend="Entrega">
                            <div className="text-center">
                                <button className="signpad-clear" onClick={entregaClearPad}>Clear Pad</button>
                                <SignatureCanvas 
                                    ref={entregaSignatureRef}
                                    penColor='black' 
                                    canvasProps={{width: 400, height: 200, className: 'sigCanvas sigBorder'}} />
                            </div>
                        </Fieldset>
                    </Col>
                    <Col>
                        <Fieldset legend="Recibe">
                            <div className="text-center">
                                <button className="signpad-clear" onClick={recibeClearPad}>Clear Pad</button>
                                <SignatureCanvas 
                                    ref={recibeSignatureRef}
                                    penColor='black' 
                                    canvasProps={{width: 400, height: 200, className: 'sigCanvas sigBorder'}} />
                            </div>
                        </Fieldset>
                    </Col>
                </Row>
            </Dialog>
        </div>
    )
}