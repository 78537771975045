import React from "react";
import {
	Card,
	CardBody,Container,Col,Row,CardTitle

} from 'reactstrap';
import Select from 'react-select';
import {FileUpload} from 'primereact/fileupload';
import {InputMask} from 'primereact/inputmask';
import EmbarqueService from 'controllers/embarque/embarque-service';
import { Toast } from 'primereact/toast';
import EvidenciaService from 'controllers/evidencias/evidencias-service';
import {ProgressBar} from 'primereact/progressbar';
import { OrderList } from 'primereact/orderlist';
import { Fieldset } from 'primereact/fieldset';
import {url} from '../../../constants';


import { InputNumber } from 'primereact/inputnumber';
import {Calendar} from 'primereact/calendar';
import moment from 'moment';



const es = {
	firstDayOfWeek: 1,
	dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
	dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
	dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
	monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
	monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"]
};

const options= [
	{value:"Num Shipper" ,label:"Num Shipper "},
	{value:"Num Packing list",label:"Num Packing list"},
	{value:"Num remision" , label:"Num remision"},
	{value:"Otros" ,label:"Otros"}

];

class RutasEvidencias extends React.Component {
	constructor(props){
		super(props);
		this.state={folio:"",item:{
			facturar_a:"",
			ruta_id:"",
			fecha_embarque:""
		},
			evidenciasCargadas: [],
			panelCollapsed:true,
			panelCollapsed0:true,
			mostrarOpciones:false,
			evidencias:[{}]
		};

		this.servicio = new EvidenciaService();

		this.servicioEmbarque = new EmbarqueService();
		this.buscar = this.buscar.bind(this);
		this.cargar = this.cargar.bind(this);
		this.guardar = this.guardar.bind(this);
		this.itemTemplate = this.itemTemplate.bind(this);
		this.listadoEvidencia = this.listadoEvidencia.bind(this);
		this.vista = this.vista.bind(this);
		this.agregar = this.agregar.bind(this);

	}

	buscar(){
		let that = this;
		if(this.state.folio){
			this.servicioEmbarque.buscar(this.state.folio).then(res =>  {
					if(res && res.result == 'OK'){
						that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Mostrando coincidencia'});
						that.setState({item:res.data[0],mostrarOpciones:true});

						this.servicio.getEvidencias2(res.data[0].id).then(data =>  {

							console.log(JSON.parse(data[0].evidencias));
							this.setState({evidenciasCargadas: JSON.parse(data[0].evidencias)});
						})
					}
			});
		}else{
			this.setState({mostrarOpciones:false})
		}
	}

	cargar(n,archivo){
		let data = {};
		data["evidencia" + n] = archivo;
		this.setState(data);

	}


	listadoEvidencia(){
		const listItems = this.state.evidencias.map((elemento,i) =>
			this.vista(elemento,i)
	 );
	 return (
		 <div>
					{listItems}
		 </div>
	 )
	}
	eliminar(t,i){

		const elementos = t.state.elementos;
		elementos.splice(i, 1);
		t.setState({elementos:elementos});
	}

	vista (elemento,i = 0){
		let elementos = this.state.evidencias;
		if(elemento[i])
		elementos[i] = {};
		const  objeto = (value,tipo) =>{
			elementos[i][tipo]= value;
			return elementos;
		};
		const index = i;

		return(
			<Row>
				<Col xs="6">
					<div className="form-group">
						<label>Tipo de Evidencia</label>
						<Select options={options}  ref={(el) => this.ultimoSelect = el}  onChange={(e) => this.setState({evidencias:objeto(e.label,'tipo')})} placeholder="Elige el tipo de Evidencia"/>
					</div>
				</Col>
				<Col xs="3">
				<div className="form-group">
					<br/>
					<div className="custom-file-upload">
						<label for={"file-upload"+i} className="custom-file-upload1 btn btn-info">
								<i className="pi pi-cloud-upload"></i> Seleccionar archivo
						</label>
						<input id={"file-upload"+i} type="file" style={{display:"none"}} onChange={e=> this.setState({evidencias:objeto(e.target.files[0],'archivo')}) }/>
					</div>

				</div>
				</Col>
				<Col xs="1">
						<div className="form-group">
							<br/>
							{this.state.evidencias.length>1?(<center> <button type="button" className="btn btn-danger" onClick={()=>  this.eliminar(this,index)}  ><i className="pi pi-minus"></i></button></center>):<div/>	}
						</div>
				</Col>
			</Row>
		)

	}
    componentDidMount() {
			let data = {
	"data": [
		{
			"id": "1000",
			"code": "f230fh0g3",
			"name": "Bamboo Watch",
			"description": "Product Description",
			"image": "bamboo-watch.jpg",
			"price": 65,
			"category": "Accessories",
			"quantity": 24,
			"inventoryStatus": "INSTOCK",
			"rating": 5
		},
		{
			"id": "1001",
			"code": "nvklal433",
			"name": "Black Watch",
			"description": "Product Description",
			"image": "black-watch.jpg",
			"price": 72,
			"category": "Accessories",
			"quantity": 61,
			"inventoryStatus": "INSTOCK",
			"rating": 4
		},
		{
			"id": "1002",
			"code": "zz21cz3c1",
			"name": "Blue Band",
			"description": "Product Description",
			"image": "blue-band.jpg",
			"price": 79,
			"category": "Fitness",
			"quantity": 2,
			"inventoryStatus": "LOWSTOCK",
			"rating": 3
		},
		{
			"id": "1003",
			"code": "244wgerg2",
			"name": "Blue T-Shirt",
			"description": "Product Description",
			"image": "blue-t-shirt.jpg",
			"price": 29,
			"category": "Clothing",
			"quantity": 25,
			"inventoryStatus": "INSTOCK",
			"rating": 5
		},
		{
			"id": "1004",
			"code": "h456wer53",
			"name": "Bracelet",
			"description": "Product Description",
			"image": "bracelet.jpg",
			"price": 15,
			"category": "Accessories",
			"quantity": 73,
			"inventoryStatus": "INSTOCK",
			"rating": 4
		},
		{
			"id": "1005",
			"code": "av2231fwg",
			"name": "Brown Purse",
			"description": "Product Description",
			"image": "brown-purse.jpg",
			"price": 120,
			"category": "Accessories",
			"quantity": 0,
			"inventoryStatus": "OUTOFSTOCK",
			"rating": 4
		},
		{
			"id": "1006",
			"code": "bib36pfvm",
			"name": "Chakra Bracelet",
			"description": "Product Description",
			"image": "chakra-bracelet.jpg",
			"price": 32,
			"category": "Accessories",
			"quantity": 5,
			"inventoryStatus": "LOWSTOCK",
			"rating": 3
		},
		{
			"id": "1007",
			"code": "mbvjkgip5",
			"name": "Galaxy Earrings",
			"description": "Product Description",
			"image": "galaxy-earrings.jpg",
			"price": 34,
			"category": "Accessories",
			"quantity": 23,
			"inventoryStatus": "INSTOCK",
			"rating": 5
		},
		{
			"id": "1008",
			"code": "vbb124btr",
			"name": "Game Controller",
			"description": "Product Description",
			"image": "game-controller.jpg",
			"price": 99,
			"category": "Electronics",
			"quantity": 2,
			"inventoryStatus": "LOWSTOCK",
			"rating": 4
		},
		{
			"id": "1009",
			"code": "cm230f032",
			"name": "Gaming Set",
			"description": "Product Description",
			"image": "gaming-set.jpg",
			"price": 299,
			"category": "Electronics",
			"quantity": 63,
			"inventoryStatus": "INSTOCK",
			"rating": 3
		}
	]
};


			 this.setState({ products: data });
    }



	itemTemplate(item) {
			 return (
					 <div className="product-item">
							 <div className="image-container">
									 <img src={`showcase/demo/images/product/${item.image}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={item.name} />
							 </div>
							 <div className="product-list-detail">
									 <h5 className="p-mb-2">{item.name}</h5>
									 <i className="pi pi-tag product-category-icon"></i>
									 <span className="product-category">{item.category}</span>
							 </div>
							 <div className="product-list-action">
									 <h6 className="p-mb-2">${item.price}</h6>
									 <span className={`product-badge status-${item.inventoryStatus.toLowerCase()}`}>{item.inventoryStatus}</span>
							 </div>
					 </div>
			 );
	 }

	 itemTemplate2(item) {
		 	console.log(item);
 			 return (
 					 <div className="product-item">
 							 <div className="product-list-detail">
 									 <h5 className="p-mb-2">{item["t"+item["index"]]}</h5>

 							 </div>
							 <div className="product-list-action">
									 <h6 className="p-mb-2">{item["ev"+item["index"]]}</h6>
									 <button onClick={e => window.open(url+"descargarEvidencia?q=/"+item["ev"+item["index"]] )} className="btn btn-default">Descargar</button>
							 </div>
 					 </div>
 			 );
 	 }

	 async guardar(e){
		e.preventDefault();
		const data = new FormData();
		let error = false;
		console.log("guardando")


		if(this.state.evidencia0){
			if(this.state.factura === ""){
				this.growl.show({severity: 'error', summary: 'Seleccione el tipo de evidencia', detail: 'Para la evidencia número 1'});
				error=true;
			}else{
				data.append('evidencia0',this.state.evidencia0);
				data.append('t0',this.state.factura);
			}
		}

		if(this.state.evidencias){
			 this.state.evidencias.forEach((item, i) => {

					data.append('evidencia'+(i+1),item.archivo);
					data.append("t"+( i +1) , item.tipo );

			});


		}

		if(!error)
			if(this.state.item && this.state.item.id ){
				this.setState({visible:true});
				data.append('id', this.state.item.id);
				this.servicio.enviarEvidencia(data,(ProgressEvent)=>{
					let avance = (ProgressEvent.loaded / ProgressEvent.total*100);
					this.setState({
						loaded: avance,
					});

					if(avance == 100){
						this.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Información guardada'});
						this.setState({
							item:{
								facturar_a:"",
								ruta_id:"",
								fecha_embarque:""
							},
							folio:"",
							factura:"",
							evidencias:[{}]
						});
					}

				});
			}else{
				this.growl.show({severity: 'error', summary: 'Operación incompletada', detail: 'Por favor ingrese un folio de búsqueda'});
			}

	}

	seccionCarga(){
		return (
			<div>
			<Fieldset legend="Carga de evidencias" toggleable  collapsed={this.state.panelCollapsed0} onToggle={(e) => this.setState({panelCollapsed0: e.value})}  >

					<Row>
						<Col xs="6">
							<div className="form-group">
								<label>Número de factura</label>
								<input type="text" className="form-control" onChange={e=> this.setState({factura:e.target.value})}/>
							</div>
						</Col>
						<Col xs="6">
							<div className="form-group">
								<br/>
								<div className="custom-file-upload">
									<label for="file-upload" className="custom-file-upload1 btn btn-info">
											<i className="fa fa-cloud-upload"></i> Carga de Factura
									</label>
									<input id="file-upload" type="file" style={{display:"none"}} onChange={e=> this.cargar("0",e.target.files[0])}/>
								</div>

							</div>
						</Col>
					</Row>
					{this.listadoEvidencia()}
					<center> <button className="btn btn-success" onClick={this.agregar} ><i className="pi pi-plus"></i></button></center>
					<br/><br/><br/>

					<center> <button className="btn btn-success" onClick={e=>this.guardar(e)}>Guardar</button></center>
					<br/>
					{this.state.visible?(<ProgressBar value={this.state.loaded} />):null}
			</Fieldset>
			</div>

		);
	}

	seccionGuardada(){
		return(
			<Fieldset legend="Evidencias guardadas" toggleable  collapsed={this.state.panelCollapsed} onToggle={(e) => this.setState({panelCollapsed: e.value})} >
			<center>
				<div className="btn btn-info " onClick={()=>this.descargarNomina()}>
				<i className="pi pi-folder"/>
				 Descargar</div>&nbsp;&nbsp;
				 <div className="btn btn-info" onClick={()=>this.descargarNomina()}>
				 <i className="fa fa-file-pdf"/>
 			 	Descargar</div>
			 </center>
			 <br/><br/>
			 <div className="orderlist-demo" >
					 <div className="card">
							 <OrderList value={this.state.evidenciasCargadas} header="Orden de evidencias" dragdrop listStyle={{height:'auto'}} dataKey="id"
									 itemTemplate={this.itemTemplate2} onChange={(e) => this.setState({ products: e.value })}></OrderList>
					 </div>
			 </div>
			</Fieldset>
		);
	}

	apartadoEvidencias(){
		return (
			<div>
				{this.seccionCarga.bind(this)()}
				{this.seccionGuardada.bind(this)()}
			</div>
		);
	}

	agregar(event){
		event.preventDefault();
		const elementos = this.state.evidencias;
		elementos[elementos.length ] = {};
		this.setState({elementos:elementos});
		setTimeout(()=>{
			if(this.ultimoSelect)
				this.ultimoSelect.focus();
		},500);

	}

	render() {
		return (
			<Card>
			<CardTitle className="bg-light border-bottom p-3 mb-0">
				Carga de Evidencias
			</CardTitle>
				<CardBody>
					<Toast ref={(el) => this.growl = el} />

					<Container>
						{this.state.mostrarOpciones?<div/>:<div><br/><br/><br/></div>}
						<Row>
							{this.state.mostrarOpciones?<div/>:<Col xs="3"/>}
							<Col xs={this.state.mostrarOpciones?3:6}>
								<div className="form-group">
									<label>Folio de Embarque</label>
									<div class="input-group mb-3">

										<InputMask value={this.state.folio} mask="E99999" className="form-control" placeholder="Buscar" onChange={(e) => this.setState({folio: e.value})}></InputMask>
										<div class="input-group-append">
											<button class="btn btn-outline-secondary" style={{backgroundColor:'#29a9f3',color:'#fff'}} onClick={this.buscar} type="button">
											<svg style={{width:"17px",height:"17px"}} viewBox="0 0 24 24">
												<path fill="currentColor" d="M11.9 10.9C14.41 8.4 18.45 8.37 21 10.82V5C21 3.9 20.11 3 19 3H14.82C14.4 1.84 13.3 1 12 1S9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.11 3.9 21 5 21H13.06C12.65 20.74 12.26 20.45 11.9 20.1C9.37 17.56 9.37 13.44 11.9 10.9M12 3C12.55 3 13 3.45 13 4S12.55 5 12 5 11 4.55 11 4 11.45 3 12 3M20.31 17.9C20.75 17.21 21 16.38 21 15.5C21 13 19 11 16.5 11S12 13 12 15.5 14 20 16.5 20C17.37 20 18.19 19.75 18.88 19.32L22 22.39L23.39 21L20.31 17.9M16.5 18C15.12 18 14 16.88 14 15.5S15.12 13 16.5 13 19 14.12 19 15.5 17.88 18 16.5 18Z" />
											</svg>
											</button>
										</div>
									</div>
								</div>
							</Col>
							{this.state.mostrarOpciones?
							<Col xs="9">
							<Row>
							<Col xs="4">
								<div className="form-group">
									<label>Cliente</label>
									<input type="text"  value={this.state.item.facturar_a} className="form-control" readOnly/>
								</div>
							</Col>
							<Col xs="4">
								<div className="form-group">
									<label>Ruta</label>
									<input type="text" value={this.state.item.ruta_id}  className="form-control" readOnly/>
								</div>
							</Col>
							<Col xs="4">
								<div className="form-group">
									<label>Fecha de embarque</label>
									<input type="text" value={this.state.item.fecha_embarque}  className="form-control" readOnly/>
								</div>
							</Col>
							</Row>
							</Col>:
							<div/>}

						</Row>
						{this.state.mostrarOpciones?this.apartadoEvidencias.bind(this)():<div><br/><br/><br/><br/></div>}

					</Container>

				</CardBody>
			</Card>
		);
	}
}

export default RutasEvidencias;
