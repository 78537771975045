import React, { Component } from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {MultiSelect} from 'primereact/multiselect';
import UnidadService  from 'controllers/unidad/unidadService';
import {Button} from 'primereact/button';
import EditarUnidad from './editar';
import {Dialog} from 'primereact/dialog';
import { Toast } from 'primereact/toast';

export default class UnidadesListado extends Component {

    constructor() {
        super();
        this.state = {
            globalFilter: null
        };
        this.unidadService = new UnidadService();
    }

    componentDidMount() {
      if(this.props.parametros){
        this.unidadService.getFiltroUnidades(this.props.parametros).then(data => this.setState({cars: data}));
      }else{
        this.unidadService.getAllUnidades().then(data => this.setState({cars: data}));
      }
    }

    eliminar(column, rowData){
      this.setState({visible2:true,selectedItemIndex:column.rowIndex,selectedItem:rowData});

    }

    editar(rowData){
    //  this.setState({visible:true,item:column.rowData});
      this.props.history.push({
       pathname:'/app/unidad/editar',
       state:rowData
     });

    }

    confirmar_borrado(){

      this.setState({visible2:false});
      this.setState({
        cars: this.state.cars.filter((val,i) => i !== this.state.selectedItemIndex)
      });
      let that = this;
      this.unidadService.eliminarUnidad(this.state.selectedItem.id).then(res =>  {
          if(res && res.result == 'OK'){
            that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Registro borrado'});
          }
      });


    }

    actionTemplate(rowData, column) {
      return (<div>
        <Button
          type="button" icon="pi pi-pencil" onClick={e=> this.editar(rowData)}
          className=""/><Button
          type="button" icon="pi pi-trash" onClick={e=> this.eliminar(column, rowData)}
           className="p-button-warning"
        />
      </div>);
    }

    render() {
        const footer = (
          <div>
                <Button label="Sí" className='p-button-warning mr-2' icon="pi pi-check" onClick={this.confirmar_borrado.bind(this)} />
                <Button label="No" className='p-button-warning' icon="pi pi-times" onClick={e=> this.setState({visible2:false})}/>
            </div>

        );
        let header = (
            <div style={{'textAlign':'left'}}>
                <i className="pi pi-search" style={{margin:'4px 4px 0 0'}}></i>
                <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="Buscar en filas" size="50"/>
            </div>
        );

        return (<div>
          <Toast ref={(el) => this.growl = el} />
            <Dialog header="Editar"  visible={this.state.visible} style={{width: '50vw',height:'90vh',overflow:'scroll'}} modal={true} onHide={() => this.setState({visible: false,visible2:false	})}>
              <EditarUnidad values={this.state.item} growl={this.growl} onSaved={()=> this.setState({visible:false})}/>

            </Dialog>
            <Dialog header="Eliminar"  footer={footer} visible={this.state.visible2} style={{width: '50vw'}} modal={true} onHide={() => this.setState({visible: false,visible2:false	})}>
              ¿Confirma eliminar?
            </Dialog>
            <DataTable value={this.state.cars} paginator={true} rows={10} header={header} globalFilter={this.state.globalFilter}>
                <Column field="tipo_unidad" header="Tipo de unidad" />
                <Column field="fecha_adquisicion" header="Fecha de adquisición" />
                <Column field="numero_factura" header="Número de factura" />
                <Column field="numero_pedimento" header="Número de pedimento"  />
                <Column field="placas" header="Placas"  />
                <Column field="eco" header="Eco"  />
                <Column field="marca" header="Marca"  />
                <Column field="serie_vin" header="Serie"  />
                <Column field="numero_motor" header="Número de motor"  />
                <Column body={this.actionTemplate.bind(this)} header="Opciones" style={{ textAlign: 'center'}} />
            </DataTable>
        </div>);
    }
}
