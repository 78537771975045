import React from "react";
import {
	Card,
	CardBody, Container, Col, Row, CardTitle

} from 'reactstrap';
import { Calendar } from 'primereact/calendar';
import Select from 'react-select';
import ClienteService from 'controllers/cliente/clienteservice';
import OperadorServices from 'controllers/operador/operador-service';
import UnidadService from 'controllers/unidad/unidadService';
import { Toast } from 'primereact/toast';
import CobranzaListado from './lista-cobranza';
import { TabView, TabPanel } from 'primereact/tabview';

const options = [
	{ value: "1", label: "No pagado" },
	{ value: "2", label: "Pre-Nómina" },
	{ value: "3", label: "Nómina" }
]

class CobranzaEmbarque extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			folio: "",
			cliente: "",
			estatus: ""
		}

	}


	buscar = () => {
		this.props.history.push({
			pathname: '/app/Cobranza/add',
			state: this.state
		});
	}

	render() {

		return (
			<Card>
				<CardTitle className="bg-light border-bottom p-3 mb-0">CONSULTA DE COBRANZA</CardTitle>
				<CardBody>
					<Container>
						<div className="d-flex justify-content-end">
							<button type="submit" onClick={this.buscar} className="btn btn-default" >Agregar seguimiento</button>
						</div>

						<Row>
							<TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
								<TabPanel header="Cobranza">

									<CobranzaListado estado="1" history={this.props.history} />
								</TabPanel>
								{/* <TabPanel header="En Proceso">
											<EmbarqueListado estado="2" history={this.props.history}/>
									</TabPanel>
									<TabPanel header="Facturado">
											<EmbarqueListado estado="3" history={this.props.history}/>
									</TabPanel> */}

							</TabView>
						</Row>
					</Container>

				</CardBody>
			</Card>
		);
	}
}

export default CobranzaEmbarque;
