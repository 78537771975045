import React, { Component } from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {MultiSelect} from 'primereact/multiselect';
import LlantaService  from 'controllers/llanta/llantaService';
import {Button} from 'primereact/button';
import EditarLlanta from './editar';
import {Dialog} from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import {QRElement} from './qr-component';

export default class LlantasListado extends Component {

    constructor() {
        super();
        this.state = {
            globalFilter: null,
            visible:false,
            visible2:false,
            visible3:false,
            qr:5
        };
        this.servicioLLanta = new LlantaService();
        this.eliminar = this.eliminar.bind(this);
        this.editar = this.editar.bind(this);
    }

    componentDidMount() {
        if(this.props.parametros){
          this.servicioLLanta.getFiltroLlantas(this.props.parametros).then(data => this.setState({llantas: data}));
        }else{
          this.servicioLLanta.getLlantas().then(data => this.setState({llantas: data}));
        }
    }

    eliminar(column){
      this.setState({visible2:true,selectedItemIndex:column.rowIndex,selectedItem:column.rowData});

    }

    editar(column){
      this.setState({visible:true,item:column.rowData});
    }

    confirmar_borrado(){
      this.setState({visible2:false});
      this.setState({
        llantas: this.state.llantas.filter((val,i) => i !== this.state.selectedItemIndex)
      });
      let that = this;
      this.servicioLLanta.eliminaLlanta(this.state.selectedItem.id).then(res =>  {
          if(res && res.result == 'OK'){
            that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Registro borrado'});
          }
      });

    }


    actionTemplate(rowData, column) {
      return (<div>
        <Button
          type="button" icon="pi pi-pencil" onClick={e=> this.editar(column)}
          className='p-button-warning'/>
          <Button
          type="button" icon="pi pi-trash" onClick={e=> this.eliminar(column)}
           className="p-button-warning"
        />
        <button
         className="btn" style={{margin:10}} onClick={e=> this.setState({qr:column.rowData.folio,visible3:true})}
      >Ver QR</button>
      </div>);
    }

    render() {
      const footer = (
        <div>
              <Button label="Sí" className='p-button-warning mr-2' icon="pi pi-check" onClick={this.confirmar_borrado.bind(this)} />
              <Button label="No" className='p-button-warning' icon="pi pi-times" onClick={e=> this.setState({visible2:false})}/>
          </div>

      );
        let header = (
            <div style={{'textAlign':'left'}}>
                <i className="pi pi-search" style={{margin:'4px 4px 0 0'}}></i>
                <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="Global Search" size="50"/>
            </div>
        );

        return (<div>
            <Toast ref={(el) => this.growl = el} />
            <QRElement qr={this.state.qr} directo={this.state.visible3} onClose={()=> this.setState({visible3:false}) }/>
            <Dialog header="Editar"  visible={this.state.visible} style={{width: '50vw',height:'90vh',overflow:'scroll'}} modal={true} onHide={() => this.setState({visible: false,visible2:false	})}>
              <EditarLlanta values={this.state.item}    growl={this.growl} onSaved={()=> this.setState({visible:false})}/>
            </Dialog>
            <Dialog header="Eliminar"  footer={footer} visible={this.state.visible2} style={{width: '50vw'}} modal={true} onHide={() => this.setState({visible: false,visible2:false	})}>
              ¿Confirma eliminar?
            </Dialog>
            <DataTable value={this.state.llantas} paginator={true} rows={10} header={header} globalFilter={this.state.globalFilter}>
                <Column field="folio" header="Folio Exnac" />
                <Column field="marca" header="Marca" />
                <Column field="medida" header="Medida" />
                <Column field="year" header="Año" />
                <Column field="orden_compra" header="Orden de compra" />

                <Column body={this.actionTemplate.bind(this)} header="Opciones" style={{ textAlign: 'center'}} />
            </DataTable>

      </div>);
    }
}
