import React from "react";
import {
	Card,
	CardBody,Container, Row,Col,CardTitle

} from 'reactstrap';

import { Toast } from 'primereact/toast';
import moment from 'moment';
import Select from 'react-select';
import Service from  'controllers/empleado/service';
const options = [];

class Editar extends React.Component {
	constructor(props){
		super(props);
		this.state={

		}
		this.servicio = new Service();

		this.handleSubmit = this.handleSubmit.bind(this);
	}


	componentDidMount() {
	  let values = this.props.location.state;
	  console.log(values);
	  if(values){
	    this.setState({
				num_empleado:values.num_empleado,
				nombre:values.nombre,
				cuenta:values.cuenta,
				tarjeta:values.tarjeta,
				clabe:values.clabe,
				banco:values.banco,
				departamento:values.departamento,
				puesto:values.puesto,
	      id:values.id,
	      titulo:"EDITAR",
	      sobreescribir: true
	    });
	  }

	}

	handleSubmit(event) {
				event.preventDefault();

				 console.log(this.state);
				let that = this;

				let datos ={};
				const data = new FormData();
				datos.num_empleado = this.state.num_empleado;
				datos.nombre = this.state.nombre;
				datos.cuenta = this.state.cuenta;
				datos.tarjeta = this.state.tarjeta;
				datos.clabe = this.state.clabe;
				datos.banco = this.state.banco;
				datos.departamento = this.state.departamento;
				datos.puesto = this.state.puesto;

				if(this.state.foto)
					data.append("Fotografía",this.state.foto);


				let mensajes = [];
				if(!this.state.num_empleado	){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Ingrese el número de empleado '});
				}
				if(!this.state.nombre	){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Ingrese el nombre  de empleado'});
				}
				if(!this.state.cuenta	){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Ingrese el número de cuenta'});
				}
				if(!this.state.tarjeta	){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Ingrese el número de tarjeta'});
				}
				if(!this.state.clabe	){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Ingrese la CLABE'});
				}
				if(!this.state.banco	){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Ingrese el nombre del banco'});
				}

				if(mensajes.length){
					this.growl.show(mensajes);
				}else{

					let funcion = console.log;
					if(this.state.sobreescribir){
					  funcion = this.servicio.actualizarEmpleado;
					  datos.id = this.state.id;
					}else{
					  funcion = this.servicio.guardarEmpleado;
					}

					funcion(datos).then(res =>  {
							if(res && res.result == 'OK'){

								that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Información guardada correctamente'});
								setTimeout(function(){
										 	that.props.history.push('/app/nomina/catalogo/empleados');
								 }, 500);

							}else if(res && res.result == 'EXISTE'){
								that.growl.show({severity: 'error', summary: 'El Id de ruta '+this.state.id_ruta+' ya se encuentra registrado', detail: 'Ingrese otro código o consulte su información para editarla.'});
							}
					});
				}
	}

	render() {

		return (
			<Card>
			<CardTitle  className="bg-light border-bottom p-3 mb-0">EDITAR A EMPLEADO</CardTitle>
				<CardBody>
				<Toast ref={(el) => this.growl = el} />
				<form >
				<Row>
					<Col xs="6">
							<div className="form-group">
								<label>Nombre del departamento</label><br/>
									<input type="text"  value={this.state.num_empleado}  onChange={e => this.setState({num_empleado : e.target.value})}  className="form-control"/>
							</div>
						</Col>
				</Row>



				<br/><br/><br/>


						<center> <button className="btn btn-success" onClick={this.handleSubmit} >Guardar</button></center>
					</form>
				</CardBody>
			</Card>
		);
	}
}

export default Editar;
