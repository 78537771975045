import React from "react";
import SignatureCanvas from 'react-signature-canvas';
import {
  Card,
  CardBody, Container, Col, Row, CardTitle

} from 'reactstrap';
import { Toast } from 'primereact/toast';
import HuellaService from 'controllers/huella/huella';

export default class Huella extends React.Component {
  constructor() {
    super();
    this.state = {
    }
    this.guardar = this.guardar.bind(this);
    this.servicioT = new HuellaService();
  }

  dataURLtoFile = (dataurl, filename = "test.png") => {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime, originalname: filename });
  }

  guardar(event) {
    console.log("work")
    event.preventDefault();

    console.log(this.state);
    let that = this;

    let datos = {};
    let mensajes = [];
    //  this.setState({sig_file: })
    const data = new FormData();
    let dataop = this.dataURLtoFile(this.sigCanvas.toDataURL('image/png'))
    data.append("file", dataop);
    if (this.sigCanvas.isEmpty()) {
      mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione un archivo clave-huella' });
    }
    if (mensajes.length) {
      this.growl.show(mensajes);
    } else {
      this.servicioT.guardarHuella(data).then(res => {
        if (res && res.result == 'OK') {
          that.growl.show({ severity: 'success', summary: 'Operación completada', detail: 'Información guardada correctamente' });
          setTimeout(function () {
            that.props.history.push('/app/claves/catalogo');
          }, 500);

        } else if (res && res.result == 'EXISTE') {
          that.growl.show({ severity: 'error', summary: 'El número de sello  ' + this.state.numero_sello + ' ya se encuentra registrado', detail: 'Ingrese otro número o consulte su información para editarla.' });
        }
      });
    }

  }

  clear_image=()=>{
    this.sigCanvas.clear()
  }
  render() {
    return (
      <Card>

        <CardTitle className="bg-light border-bottom p-3 mb-0">
          Clave huella
        </CardTitle>

        <Toast ref={(el) => this.growl = el} />
        <CardBody>
          <center>
            <div >
              <SignatureCanvas ref={(ref) => { this.sigCanvas = ref }} penColor='black' canvasProps={{ width: 300, height: 300, className: 'sigCanvas', style: { border: '1px solid black', borderRadius: "10px" } }} />
            </div>
          </center>
          <br /><br /><br />
          <center>
            <button className="btn btn-success" onClick={this.guardar}>Guardar</button>
            &nbsp;&nbsp;&nbsp;
            <button className="btn btn-danger"  onClick={this.clear_image}>Limpiar</button>
          </center> 

        </CardBody>
      </Card>
    )

  }

}
