import React, { Component } from 'react';
import HuellaService from 'controllers/huella/huella';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import { url } from '../../../constants';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

export default class Listhuella extends Component {

    constructor() {
        super();
        this.state = {
            globalFilter: null,
            visible: false,
            visible2: false,
            mostrarOpciones: false,
            item: [],
        };

        this.servicio = new HuellaService();
        this.eliminar = this.eliminar.bind(this);
        var that = this;
    }

    componentDidMount() {
        this.servicio.listHuella().then(data => this.setState({ item: data }));
    }


    eliminar(item) {
        this.setState({ visible2: true, selectedItem: item });
    }

    confirmar_borrado() {

        this.setState({ visible2: false });
        this.setState({
            item: this.state.item.filter((val, i) => val.id !== this.state.selectedItem['id'])
        });
        let that = this;
        this.servicio.eliminarHeulla(this.state.selectedItem.id).then(res => {
            if (res && res.result == 'OK') {
                that.growl.show({ severity: 'success', summary: 'Operación completada', detail: 'Registro borrado' });
            }
        });

    }

    actionTemplate(rowData, item) {
        return (<div>
           <Button
                type="button" icon="pi pi-trash" onClick={e => this.eliminar(rowData)}
                className="p-button-warning"
            /><button
                type="button" className="btn" onClick={e => this.verCartaPorte(rowData)}

            >Ver carta porte</button>
        </div>);
    }

    verCartaPorte(item) {
        console.log("Listhuella -> verCartaPorte -> item", item)
        let urldata = `${url}descargarEvidencia?q=/huella/${item.path}`
        window.open(urldata)
    }
    render() {
        const footer = (
            <div>
                <Button label="Sí" className='p-button-warning mr-2' icon="pi pi-check" onClick={this.confirmar_borrado.bind(this)} />
                <Button label="No" className='p-button-warning' icon="pi pi-times" onClick={e => this.setState({ visible2: false })} />
            </div>

        );
        return (
            <>
                <Dialog header="Eliminar" footer={footer} visible={this.state.visible2} style={{ width: '50vw' }} modal={true} onHide={() => this.setState({ visible: false, visible2: false })}>
                    ¿Confirma eliminar?
                </Dialog>
                <DataTable value={this.state.item} rows={10}>
                    <item field="id" header="ID" />
                    <item field="folio" header="Folio" />
                    <item field="path" header="Path" />
                    <item body={this.actionTemplate.bind(this)} header="Opciones" style={{ textAlign: 'center' }} />
                </DataTable>
            </>);
    }
}
