import React, { Component } from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {MultiSelect} from 'primereact/multiselect';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import VigilanciaService from  'controllers/vigilancia/vigilancia-service';
import {urlPdf, urlDocuments} from '../../../constants';
import CheckPermissions from 'controllers/permissions/services';

export default class Listado extends Component {

    constructor(props) {
        super(props);
        this.state = {
          cars:[],
            globalFilter: null,
            visible:false,
            visible2:false
        };
        this.servicio = new VigilanciaService();
        this.eliminar = this.eliminar.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.editar = this.editar.bind(this);
        this.permissionService = new CheckPermissions();
    }

    componentDidMount() {
       let tipo = this.props.tipo ;
       tipo = tipo?tipo:0;
        this.servicio.listadoReportes(tipo).then(data => this.setState({cars: data}));
    }

    eliminar(column, rowData){
      this.setState({visible2:true,selectedItemIndex:column.rowIndex,selectedItem:rowData});
    }


    openImage(img)
    {
      console.log(img)
      window.open(urlDocuments+img);
    }
    editar(column){
      //this.setState({visible:true,item:column.rowData});
      let id_tipo = column.rowData.id_tipo;

      if(id_tipo == '1' ){
          window.open(urlPdf+'17-C-TPAT/reporte.php?id='+column.rowData.id,"width=200,height=100");
      }else if(id_tipo  == '2' ){
          window.open(urlPdf+'7-POINTS/reporte.php?id='+column.rowData.id,"width=200,height=100");
      }else if(id_tipo == '3'){
          window.open(urlPdf+'CHECKLIST-DESPACHO/reporte.php?id='+column.rowData.id,"width=200,height=100");
      }else if(id_tipo == '4'){
          window.open(urlPdf+'CHECKLIST-ENTREGA/reporte.php?id='+column.rowData.id,"width=200,height=100");
      }
    }

    confirmar_borrado(){

      this.setState({visible2:false});
      this.setState({
        cars: this.state.cars.filter((val,i) => i !== this.state.selectedItemIndex)
      });
      let that = this;

      this.servicio.eliminarReporteG(this.state.selectedItem.id,this.state.selectedItem.id_tipo).then(res =>  {
          if(res && res.result == 'OK'){
            that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Registro borrado'});
          }
      });

    }

    template_repositorio(id_tipo,foto_id){
      if(id_tipo == '1' ){
        return (<div/>);
      }else if(id_tipo  == '2' ){
        return (<div/>);
      }else if(id_tipo == '3' && foto_id){
        return ( this.permissionService.checkPermission('inspeccion_reportes_abrir_documento') && <Button icon="pi pi-images" onClick={e=> this.openImage(foto_id)}  className="p-button-warning mr-2"
        tooltip="Abrir documento"
        tooltipOptions={{ position: 'top' }}/>);
      }else if(id_tipo == '4'){
        return (<div/>);
      }
    }

    actionTemplate(rowData, column) {
   
      return (
      <div className='d-flex justify-content-center'>
        {this.permissionService.checkPermission('inspeccion_reportes_abrir_documento') && <Button
          type="button" 
          icon="pi pi-file-pdf" 
          onClick={e=> this.editar(column)}
          className="mr-2 p-button-warning"
          tooltip="Abrir documento"
          tooltipOptions={{ position: 'top' }}
        />}
        
        {this.template_repositorio(rowData.id_tipo,rowData.foto_id)}
        
        {this.permissionService.checkPermission('inspeccion_reportes_borrar') && <Button
          type="button" 
          icon="pi pi-trash" 
          onClick={e=> this.eliminar(column, rowData)}
          className="mr-2 p-button-warning"
          tooltip="Borrar"
          tooltipOptions={{ position: 'top' }}
        />}
      </div>);
    }

    render() {
        const footer = (
          <div>
                <Button label="Sí" className='p-button-warning mr-2' icon="pi pi-check" onClick={this.confirmar_borrado.bind(this)} />
                <Button label="No" className='p-button-warning' icon="pi pi-times" onClick={e=> this.setState({visible2:false})}/>
            </div>

        );
        return (<div>
          <Toast ref={(el) => this.growl = el} />
          <Dialog header="Eliminar"  footer={footer} visible={this.state.visible2} style={{width: '50vw'}} modal={true} onHide={() => this.setState({visible: false,visible2:false	})}>
            ¿Confirma eliminar?
          </Dialog>
            <DataTable value={this.state.cars}   paginator={true} 
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} >
            <Column field="folio" header="Folio" />
            <Column field="tipo" header="Tipo" />
            <Column field="fecha" header="Fecha" />

            <Column body={this.actionTemplate.bind(this)} header="Opciones" style={{ textAlign: 'center',width:'10%'}} />
            </DataTable>
      </div>);
    }
}
