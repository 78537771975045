import axios from 'axios';
import {url} from '../../constants';

export default class Service {

			getTodasLasRutas(){
				return axios.get(url + 'rutas/all')
								.then(res => res.data);
			}

			getFiltroTodasLasRutas(obj){
				delete obj.clientes;
				return axios.get(url + 'rutas/all/filtro',{params:obj})
	
			}

			filterTodasLasRutas(obj){
				return axios.get(url + 'rutas/filtro', {params:obj})
				.then(res => res.data);
			}

	    getRutas(idCliente){

        return axios.get(url + 'rutas',{params:{cliente:idCliente}})
                .then(res => res.data);
			}


			getVersiones(idRuta){

				return axios.get(url + 'rutas/versiones',{params:{version:idRuta}})
								.then(res => res.data);
			}


			guardarRuta(ruta){
					return axios.post(url + 'rutas/guardar',ruta,  {
			    		headers: {
					      'Content-Type': 'multipart/form-data'
					    }
						}).then(res => res.data);
			}

			eliminarRuta(ruta_id){
					return axios.post(url + 'rutas/eliminar',{
						ruta_id:ruta_id

					}).then(res => res.data);
			}

			actualizarRuta(ruta){
					delete ruta.clientes;

					return axios.post(url + 'rutas/actualizar',{
						ruta:ruta

					}).then(res => res.data);
			}

			guardarVersionRuta(version){
					delete version.rutas;
					delete version.clientes;
					delete version.chk_s1;
					delete version.chk_s2;
					delete version.chk_s3;
					delete version.chk_s4;
					delete version.chk_s5;
					delete version.chk_s6;
					delete version.chk_s7;
					delete version.chk_s8;
					delete version.chk_s9;
					delete version.chk_s10;
					delete version.remolques;
					return axios.post(url + 'rutas/version/guardar',{
						version:version

					}).then(res => res.data);
				}

}
