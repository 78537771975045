import React from "react";
import {
	Card,
	CardBody,
	CardTitle
} from 'reactstrap';
import CatalogoOrdenesPago from './lista-ordenes-pago';
import {Button} from 'primereact/button';

class Semaforos extends React.Component {

	agregar(){
		this.props.history.push({
		 pathname:'/app/mantenimiento/preventivo/semaforo/nuevo'
	 });
	}

	render() {
		return (
			<Card>
				<CardTitle  className="bg-light border-bottom p-3 mb-0">LISTADO DE SEMÁFOROS</CardTitle>
				<CardBody>
					<Button label='Nuevo semáforo' icon="fa fa-plus" className="pull-right" onClick={this.agregar.bind(this)}/>
					<br/>
					<CatalogoOrdenesPago />
				</CardBody>
			</Card>
		);
	}
}

export default Semaforos;
