
import React, { useState, useEffect } from "react";
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';

export default function PCalendar(props) {
  const [value, setValue] = useState(null);
  addLocale('es', {
      firstDayOfWeek: 1,
      showMonthAfterYear: true,
      dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
      dayNamesMin: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
      monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
      today: 'Hoy',
      clear: 'Limpiar'
  });
  useEffect(() => {
    if(props.value)
    {
      setValue(new Date(props.value))
    }
    else
    {
      setValue()
    }
  },[props]);
  const setDate = (value) =>
  {
    setValue(value, props.showIcon);
    props.getCommonDataFromChilds({label:props.label,value:value})
  }
  return (
      <div className="">
          <Calendar
            className="own-calendar w-100" 
            dateFormat={"dd/mm/yy"} 
            placeholder={"dd/mm/aaaa"}
            value={value} 
            inputId={props.label}           
            onChange={(e) => setDate(e.value)}
            locale={(props.locale)?props.locale:"es"}
            touchUI
            showButtonBar
            showIcon={(props.showIcon)?true:false}
            icon={() => (props.icon)?<i className={props.icon} />:<i className="pi pi-calendar" />}
          />
      </div>
  )
}