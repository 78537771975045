import axios from 'axios';
import {url} from '../../constants';

export default class  Service {

	    getCartasPorte(){
        return axios.get(url+'cartas-porte')
                .then(res => res.data);
			}

			getCartasPortes(obj){
				return axios.get(url+'carta-porte/filtro',{
					params:obj
				})
								.then(res => res.data );
			}


			getCartaPorte(id){
				return axios.get(url+'carta-porte/byId',{
					params:{id:id}
				})
								.then(res => res.data );
			}
			obtenerFolio(tipo){
        return axios.get(url+'carta-porte/obtenerFolio',{params:{tipo : tipo}})
                .then(res => res.data);
			}


			guardarCartaPorte(carta_porte){
				delete carta_porte.embarques;
				delete carta_porte.clientes;
				delete carta_porte.unidades;
				delete carta_porte.operadores;
				delete carta_porte.remolques;
				delete carta_porte.visible;
				delete carta_porte.versiones;
				delete carta_porte.rutas;
				delete carta_porte.stops
				delete carta_porte._remitentes;
				delete carta_porte._destinatarios;
				delete carta_porte.ch_stop1;
				delete carta_porte.ch_stop2;
				delete carta_porte.ch_stop3;
				delete carta_porte.ch_stop4;
				delete carta_porte.ch_stop5;
				delete carta_porte.ch_stop6;
				delete carta_porte.editableImporte;
				delete carta_porte.mostrar;
				console.log(carta_porte);


					return axios.post(url + 'carta_porte/guardar',{
						carta_porte:carta_porte

					}).then(res => res.data);
			}

			buscar(folio){

				return axios.get(url + 'carta-porte/buscar',{params:{folio:folio}})
								.then(res => res.data);
			}


			verAsignacion(id){

				return axios.get(url + 'carta-porte/asignacion',{params:{id:id}})
								.then(res => res.data);
			}


			catalogo(){

				return axios.get(url + 'carta-porte/catalogo',{})
								.then(res => res.data.data);
			}

			eliminarCartaPorte(id){
				return axios.post(url + 'carta_porte/eliminar',{id:id})
								.then(res => res.data);
			}


			asignarCartaPorte(id,embarque){
				return axios.post(url + 'carta_porte/asignar',{id:id,embarque:embarque})
								.then(res => res.data);
			}


			actualizarCartaPorte(carta_porte_original){
				let carta_porte =Object.assign({}, carta_porte_original);
				delete carta_porte.embarques;
				delete carta_porte.clientes;
				delete carta_porte.unidades;
				delete carta_porte.operadores;
				delete carta_porte.remolques;
				delete carta_porte.visible;
				delete carta_porte.versiones;
				delete carta_porte.rutas;
				delete carta_porte.stops
				delete carta_porte._remitentes;
				delete carta_porte._destinatarios;
				delete carta_porte.ch_stop1;
				delete carta_porte.ch_stop2;
				delete carta_porte.ch_stop3;
				delete carta_porte.ch_stop4;
				delete carta_porte.ch_stop5;
				delete carta_porte.ch_stop6;
				delete carta_porte.editableImporte;
				delete carta_porte.mostrar;
				delete carta_porte.embarque;
				delete carta_porte.emision;

					return axios.post(url + 'carta_porte/actualizar',{
						carta_porte:carta_porte

					}).then(res => res.data);
			}


}
