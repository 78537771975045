import React from "react";
import {
	Card,
	CardBody,
	CardTitle
} from 'reactstrap';
import EmbarqueListado from './embarqueViews';

class RutasTransito extends React.Component {
	render() {
		return (
			<Card>
				<CardTitle className="bg-light border-bottom p-3 mb-0">
					Embarques en No pagado
				</CardTitle>
				<CardBody>
					<EmbarqueListado parametros={{estatus:"1"}} history={this.props.history}> </EmbarqueListado>
				</CardBody>
			</Card>
		);
	}
}

export default RutasTransito;
