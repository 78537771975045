import React from "react";
import { Link } from "react-router-dom"; 
import VigilanciaListado from './accesos/listado';
import { Panel } from 'primereact/panel';
import CheckPermissions from "controllers/permissions/services";

class AccesosVigilancia extends React.Component {
	constructor(){
		super()
		this.headerTemplate = this.headerTemplate.bind(this);
		this.permissionService = new CheckPermissions();
	}
	agregar(){
	  this.props.history.push({
	   pathname:'/app/vigilancia/accesos/agregar'
	 });
	}
	headerTemplate()
	{
		return (<div className="p-panel-header flex-wrap  d-flex">
			<div>
			Listado de accesos
			</div>
			<div>
			{this.permissionService.checkPermission('vigilancia_accesos_agregar') && <Link to="/app/vigilancia/accesos/agregar" className="btn btn-primary">
				<i className="pi pi-plus mr-2 v-middle" /> Registrar acceso
			</Link>}
			</div>
		</div>);
	}

	render() {
		return (
			<div class="container-fluid">
				<Panel headerTemplate={this.headerTemplate}>
					<VigilanciaListado history = {this.props.history} />
				</Panel>
			</div>
		);
	}
}

export default AccesosVigilancia;
