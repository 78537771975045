import React from "react";
import { Calendar } from 'primereact/calendar';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import moment from 'moment';
import MantenimientoService from  'controllers/mantenimiento/mantenimiento-service';

 export default class FullCalendarDemo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            events: []
        };
        this.servicio = new MantenimientoService();
        let fecha_actual = new Date();
        this.options = {
           plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
           defaultView: 'dayGridMonth',
           defaultDate: fecha_actual,
           header: {
               left: 'prev,next',
               center: 'title',
               right: 'dayGridMonth,timeGridWeek,timeGridDay'
           },
           editable: false,
            locale: esLocale
       };
    }

    componentDidMount() {
        let data = [
        {"id": 1,"title": "All Day Event","start": "2019-11-27"},
        {"id": 2,"title": "Long Event","start": "2017-02-07","end": "2017-02-10"},
        {"id": 3,"title": "Repeating Event","start": "2017-02-09T16:00:00"},
        {"id": 4,"title": "Repeating Event","start": "2017-02-16T16:00:00"},
        {"id": 5,"title": "Conference","start": "2017-02-11","end": "2017-02-13"},
        {"id": 6,"title": "Meeting","start": "2017-02-12T10:30:00","end": "2017-02-12T12:30:00"},
        {"id": 7,"title": "Lunch","start": "2017-02-12T12:00:00"},
        {"id": 8,"title": "Meeting","start": "2017-02-12T14:30:00"},
        {"id": 9,"title": "Happy Hour","start": "2017-02-12T17:30:00"},
        {"id": 10,"title": "Dinner","start": "2017-02-12T20:00:00"},
        {"id": 11,"title": "Birthday Party","start": "2017-02-13T07:00:00"},
        {"id": 12,"title": "Click for Google","url": "https://www.google.com/","start": "2017-02-28"}
        ];
         this.setState({events: data});
    }

	render() {
		return (
    <div>
          <div className="card">
              <Calendar events={this.state.events} options={this.options} />
          </div>
      </div>
		);
	}
}
