import axios from 'axios';
import { url } from '../../constants';

export default class Service {

	getEmbarques() {
		return axios.get(url + 'embarques')
			.then(res => res.data);
	}
	
	getEmbarquesIDS(data) {
		return axios.get(url + 'embarque/searchfolio', { params: { folio: data } })
			.then(res => res.data);
	}
	getCobranza() {
		return axios.get(url + 'cobranza/select')
			.then(res => res.data);
	}
	guardarCobranza(data) {
		return axios.post(url + 'cobranza/add', data).then(res => res.data);
	}
	updateEstatus(data) {
		return axios.post(url + 'embarque/update_estatus', data).then(res => res.data);
	}
	editCobranza(data) {
		return axios.post(url + 'cobranza/edit', data).then(res => res.data);
	}
	deleteCobranza(data) {
		return axios.post(url + 'cobranza/delete', data).then(res => res.data);
	}

	listadoFolios() {
		return axios.get(url + 'embarque/listadoFolio').then(res => res.data);
	}

	getFiltroEmbarques(obj) {
		delete obj.clientes;

		return axios.get(url + 'embarques/filtro', { params: obj })
			.then(res => res.data);
	}


	obtenerFolio() {
		return axios.get(url + 'embarque/obtenerFolio')
			.then(res => res.data);
	}


	inspeccionFolio() {
		return axios.get(url + 'reportes-inspeccion/InspeccionFolio')
			.then(res => res.data);
	}
	uploadFile(data) {
		return axios.post(url + 'upload_file', data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}).then(res => res.data);
	}
	uploadBase64File(data) {
		return axios.post(url + 'upload_base64_file', data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}).then(res => res.data);
	}
	

	getFiltroEmbarquesEstado(params) {

		return axios.get(url + 'embarques/estado', { params: params })
			.then(res => res.data);
	}

	guardarEmbarque(data) {
		// let embarque = Object.assign({}, embarque_original);

		/*var carta_porte = {};


		carta_porte.folio =  embarque.folio;
		carta_porte.operador_id =  embarque.operador_id;
		carta_porte.remolque_id =  embarque.remolque_id;
		carta_porte.unidad_id =  embarque.unidad_id;
		carta_porte.facturar_a =  embarque.facturar_a;
		carta_porte.ruta_id = embarque.ruta_id;
		carta_porte.version_id = embarque.version_id;
		carta_porte.cliente_id = embarque.cliente_id;

		if(embarque.ch_origen){
			carta_porte.remitente = embarque.planta_origen;
		}
		if(embarque.ch_destino){
			carta_porte.destinatario = embarque.destino_final;
		}
		*/
		//delete embarque.folio;

		//delete embarque.operador_id;
		//delete embarque.remolque_id;
		//	delete embarque.unidad_id;
		//delete embarque.facturar_a;
		//delete embarque.cliente_id;



		return axios.post(url + 'embarque/guardar', data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}).then(res => res.data);
	}


	eliminarEmbarque(embarque_id) {
		return axios.post(url + 'embarque/eliminar', {
			embarque_id: embarque_id

		}).then(res => res.data);
	}


	actualizarEmbarque(data) {

		// let embarque = Object.assign({}, embarque_original);

		/*
							var carta_porte = {};
							carta_porte.carta_porte_id = embarque.carta_porte_id;
							carta_porte.operador_id =  embarque.operador_id;
							carta_porte.remolque_id =  embarque.remolque_id;
							carta_porte.unidad_id =  embarque.unidad_id;
							carta_porte.facturar_a =  embarque.facturar_a;
							carta_porte.ruta_id = embarque.ruta_id;
							carta_porte.version_id = embarque.version_id;
							if(!embarque_original.ch_origen){
								carta_porte.remitente = embarque.planta_origen;
							}
							if(!embarque_original.ch_destino){
								carta_porte.destinatario = embarque.destino_final;
							}
		*/
		//	delete embarque.folio;
		//	delete embarque.operador_id;
		//	delete embarque.remolque_id;
		//	delete embarque.unidad_id;
		//	delete embarque.facturar_a;
		//delete embarque.cliente_id;

		return axios.post(url + 'embarque/actualizar', data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}).then(res => res.data);
	}



	buscar(folio) {

		return axios.get(url + 'embarque/buscar', { params: { folio: folio } })
			.then(res => res.data);
	}
}
