import React, { Component } from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {MultiSelect} from 'primereact/multiselect';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import { Toast } from 'primereact/toast';

import ParadasAutorizadasService from  'controllers/paradas-autorizadas/service';

export default class Listado extends Component {

    constructor() {
        super();
        this.state = {
            globalFilter: null,
            visible:false,
            visible2:false
        };
          this.servicio = new ParadasAutorizadasService();
        this.eliminar = this.eliminar.bind(this);
        this.editar = this.editar.bind(this);
    }

    componentDidMount() {

this.servicio.listadoParada().then(data => this.setState({cars: data}));
    }

    eliminar(column){
      this.setState({visible2:true,selectedItemIndex:column.rowIndex,selectedItem:column.rowData});

    }

    editar(column){
      //this.setState({visible:true,item:column.rowData});
      this.props.history.push({
         pathname:'/app/paradas-autorizadas/catalogo/Editar',
         state: column.rowData
       });
    }

    confirmar_borrado(){

      this.setState({visible2:false});
      this.setState({
        cars: this.state.cars.filter((val,i) => i !== this.state.selectedItemIndex)
      });
      let that = this;
      this.servicio.eliminarParada(this.state.selectedItem.id).then(res =>  {
          if(res && res.result == 'OK'){
            that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Registro borrado'});
          }
      });

    }


    actionTemplate(rowData, column) {
      /*

        <Button
          type="button" icon="pi pi-pencil" onClick={e=> this.editar(column)}
          className=""/>
      */
      return (<div>

        <Button
          type="button" icon="pi pi-pencil" onClick={e=> this.editar(column)}
          className=""/>
          <Button
          type="button" icon="pi pi-trash" onClick={e=> this.eliminar(column)}
           className="p-button-warning"
        />
      </div>);
    }

    render() {
        const footer = (
          <div>
                <Button label="Sí" className='p-button-warning mr-2' icon="pi pi-check" onClick={this.confirmar_borrado.bind(this)} />
                <Button label="No" className='p-button-warning' icon="pi pi-times" onClick={e=> this.setState({visible2:false})}/>
            </div>

        );

        return (<div>
          <Toast ref={(el) => this.growl = el} />
          <Dialog header="Eliminar"  footer={footer} visible={this.state.visible2} style={{width: '50vw'}} modal={true} onHide={() => this.setState({visible: false,visible2:false	})}>
            ¿Confirma eliminar?
          </Dialog>
            <DataTable value={this.state.cars}  rows={10} >
            <Column field="nombre" header="Nombre" />
            <Column field="direccion" header="Dirección" />
            <Column field="descripcion" header="Descripción" />
            <Column body={this.actionTemplate.bind(this)} header="Opciones" style={{ textAlign: 'center'}} />
            </DataTable>

      </div>);
    }
}
