import React from "react";
import {
	Card,
	CardBody,Container, Row,Col,CardTitle

} from 'reactstrap';

import { Toast } from 'primereact/toast';
import moment from 'moment';
import Select from 'react-select';


import Service from  'controllers/nomina/calculo';
import ServiceEmpleado from  'controllers/empleado/service';
import ServiceConcepto from  'controllers/nomina/concepto';
import EmbarqueService from 'controllers/embarque/embarque-service';


import { InputNumber } from 'primereact/inputnumber';
import {Calendar} from 'primereact/calendar';
import { Fieldset } from 'primereact/fieldset';

const options = [];

const es = {
	firstDayOfWeek: 1,
	dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
	dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
	dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
	monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
	monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"]
};

class CrearReporteFalla extends React.Component {
	constructor(props){
		super(props);
		this.state={
			empleados:[],
			conceptos:[],
			calculo:[{}],
			ultimoSelect:null,
			elementos:[]
		}
		this.servicio =new Service();


		this.servicioE = new ServiceEmpleado();
		this.servicioEmbarque = new EmbarqueService();

		this.servicioE.listadoEmpleado().then(data =>  {
			let arrEmpleado = [];
			data.forEach(item=>{
				arrEmpleado.push({value:item.id,label:item.num_empleado});
			});
			this.setState({empleados: arrEmpleado});
		});


		this.servicioC = new ServiceConcepto();

		this.servicioEmbarque.listadoFolios().then(data =>{
			let arrFolios = [];
			data.forEach((item) => {
				arrFolios.push({value:item.id,label:item.folio});
			});
			this.setState({embarques:arrFolios});

		});

		this.servicioC.listadoConcepto().then(data =>  {
			let arrConcepto = [];
			data.forEach(item=>{
				arrConcepto.push({value:item.id,label:item.nombre});
			});
			this.setState({conceptos: arrConcepto});
		});
		this.vista = this.vista.bind(this);

		this.handleSubmit = this.handleSubmit.bind(this);
		this.agregar = this.agregar.bind(this);

	}

	handleSubmit(event) {
				event.preventDefault();

				 console.log(this.state);


				let that = this;

				let datos ={};
				const data = new FormData();
				datos.id_empleado = this.state.num_empleado;
				datos.calculo = this.state.calculo;
				datos.semana = this.state.semana;
				datos.fecha = this.state.fecha;
				/*
				 de momento esta completa la infomración
				*/

				let mensajes = [];


				if(!this.state.num_empleado	){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Seleccione un empleado'});
				}
				if(!this.state.fecha	){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Seleccione una fecha'});
				}

				if(mensajes.length){
					this.growl.show(mensajes);
				}else{
					this.servicio.guardarCalculo(datos).then(res =>  {
							if(res && res.result == 'OK'){

								that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Información guardada correctamente'});
								setTimeout(function(){
											that.props.history.push('/app/nomina/desglose');
								 }, 500);

							}else if(res && res.result == 'EXISTE'){
								that.growl.show({severity: 'error', summary: 'El Id de ruta '+this.state.id_ruta+' ya se encuentra registrado', detail: 'Ingrese otro código o consulte su información para editarla.'});
							}
					});
				}
	}

	agregar(event){
		event.preventDefault();
		const elementos = this.state.calculo;
		elementos[elementos.length ] = {};
		this.setState({elementos:elementos});
		setTimeout(()=>{
			if(this.ultimoSelect)
				this.ultimoSelect.focus();
		},500);

	}

	listadoCalculos(){
		const listItems = this.state.calculo.map((elemento,i) =>
			this.vista(elemento,i)
	 );
	 return (
		 <Row>
			{listItems}
		 </Row>
	 )
	}

	vista (elemento,i = 0){
		let elementos = this.state.calculo;
		if(elemento[i])
		elementos[i] = {};
		const  objeto = (value,tipo) =>{
			elementos[i][tipo] = value;
			return elementos;
		};
		const index = i;

		return(
			<Row>
				<Col xs="3">
						<div className="form-group">
							<label>Concepto</label><br/>
							<Select  ref={(el) => this.ultimoSelect = el} options={this.state.conceptos}  onChange={e =>	this.setState({elementos: objeto(e.value,"concepto")}) } placeholder="Concepto"/>

						</div>
				</Col>
				<Col xs="3">
						<div className="form-group">
							<label>Monto</label><br/>
								<InputNumber value={this.state.calculo[i].monto} onValueChange={(e) => this.setState({elementos: objeto(e.value,"monto")})}  mode="currency" currency="MXN" locale="es-MX"  suffix="MXN" />
						</div>
				</Col>
				<Col xs="3">
						<div className="form-group">
							<label>Alias</label><br/>
								<input type="text"  value={this.state.calculo[i].alias} onChange={e => this.setState({elementos : objeto(e.target.value,"alias")})}  className="form-control" placeholder="(opcional)"/>
						</div>
				</Col>
				<Col xs="2">
						<div className="form-group">
							<label>Fecha</label><br/>
							<Calendar   dateFormat="dd/mm/yy"  onChange={(e) => { this.setState({elementos: objeto(moment(e.value).format('YYYY-MM-DD').toString(),"fecha") }); }} showIcon={true} placeholder="(opcional)"/>

						</div>
				</Col>
				<Col xs="1">
						<div className="form-group">
							<br/>
							{this.state.elementos.length>1?(<center> <button type="button" className="btn btn-danger" onClick={()=>  this.eliminar(this,index)}  ><i className="pi pi-minus"></i></button></center>):<div/>	}
						</div>
				</Col>
			</Row>

		)

	}
	eliminar(t,i){

		const elementos = t.state.elementos;
		elementos.splice(i, 1);
		t.setState({elementos:elementos});
	}

	listadoDeEmbarques(){
		if(this.state.num_empleado){
			//TODO agregar un if si no existen embaques mostrar leyenda en rojo
			return (
				<div className="form-group">
					<label>Embarque</label><br/>
					<Select options={this.state.embarques} value={this.state.embarques.filter(option => option.value === this.state.embarque_id)} onChange={(e)=> this.setState({embarque_id:e.value})}  placeholder="Seleccione embarque"  required/><br/>



				</div>

			);
		}	else return (
			<div className="form-group"	><br/><center>Por favor seleccione un empleado</center></div>

		)	;
	}

	render() {

		return (
			<Card>
			<CardTitle  className="bg-light border-bottom p-3 mb-0">CÁLCULO DE NÓMINA</CardTitle>
				<CardBody>
				<Toast ref={(el) => this.growl = el} />
				<form >
				<Row>
					<Col xs="3">
							<div className="form-group">
								<label>Número de empleado</label><br/>
									<Select options={this.state.empleados}   onChange={e =>	this.setState({num_empleado: e.value}) }  placeholder="Elegir el empleado"/>
							</div>

					</Col>
					<Col xs="3">
						{this.listadoDeEmbarques.bind(this)()}
					</Col>
					<Col xs="3">
							<div className="form-group">
								<label>Fecha</label><br/>
								<Calendar   dateFormat="dd/mm/yy"  onChange={(e) => { this.setState({fecha: moment(e.value).format('YYYY-MM-DD').toString(),semana:moment(e.value).week() }); }} showIcon={true} placeholder="dd/mm/yyyy"/>
							</div>

					</Col>
					<Col xs="3">
							<div className="form-group">
								<label>Número de Semana</label><br/>
								<input type="text"  value={this.state.semana}  className="form-control" readOnly/>

							</div>

					</Col>
				</Row>
				<br/>
				<br/>
				<Fieldset legend="Conceptos">
			 		{this.listadoCalculos.bind(this)()}

				</Fieldset>


				<center> <button className="btn btn-success" onClick={this.agregar} ><i className="pi pi-plus"></i></button></center>
				<br/><br/><br/>


						<center> <button className="btn btn-success" onClick={this.handleSubmit} >Guardar</button></center>
					</form>
				</CardBody>
			</Card>
		);
	}
}

export default CrearReporteFalla;
