import React from "react";
import ListadoReporte from './reporte-fallas/listado';
import { Panel } from 'primereact/panel';
import { Link } from "react-router-dom"; 
import CheckPermissions from "controllers/permissions/services";



class ReportesFallas extends React.Component {
	constructor(props){
		super(props);
		this.permissionService = new CheckPermissions();
		this.headerTemplate = this.headerTemplate.bind(this);
	}

	agregaReporte(){
		this.props.history.push({
		 pathname:'/app/mantenimiento/preventivo/fallas/nueva'
	 });

	}
	headerTemplate()
	{
		return (<div className="p-panel-header flex-wrap  d-flex">
			<div>
				Listado De Reporte De Fallas
			</div>
			<div>
			{this.permissionService.checkPermission('mantenimiento_preventivo_fallas_nueva') && <Link to="/app/mantenimiento/preventivo/fallas/nueva" className="btn btn-primary">
				<i className="pi pi-plus mr-2 v-middle" /> Nuevo reporte
			</Link>}
			</div>
		</div>);
	}

	render() {
		return (
			<div class="container-fluid">
				<Panel headerTemplate={this.headerTemplate}>
						<ListadoReporte history={this.props.history} />
				</Panel>
			</div>
		);
	}
}

export default ReportesFallas;
