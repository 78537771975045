import React, { Component } from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {MultiSelect} from 'primereact/multiselect';
import UnidadService  from 'controllers/unidad/unidadService';
import {Button} from 'primereact/button';
import EditarUnidad from './editar';
import {Dialog} from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { urlPdf, url } from "../../../constants";
import CheckPermissions from 'controllers/permissions/services';

export default class UnidadesListado extends Component {

    constructor() {
        super();
        this.state = {
            globalFilter: null
        };
        this.unidadService = new UnidadService();
        this.permissionService = new CheckPermissions();

    }

    // componentDidMount() {
    //   if(this.props.parametros){
    //     this.unidadService.getAllUnidades(this.props.parametros).then(data => this.setState({cars: data}));
    //   }else{
    //     this.unidadService.getAllUnidades().then(data => this.setState({cars: data}));
    //   }
    // }
    // componentDidUpdate(prevProps) {
    //   if (prevProps !== this.props) {
    //     if(this.props.parametros){
    //       this.unidadService.getAllUnidades(this.props.parametros).then(data => this.setState({cars: data}));
    //     }
    //   }
    // }
    eliminar(column,rowData){
      this.setState({visible2:true,selectedItemIndex:column.rowIndex,selectedItem:rowData});
    }

    editar(rowData){
      this.props.history.push({
       pathname:'/app/unidad/editar',
       state: rowData
     });
    }

    confirmar_borrado(){

      this.setState({visible2:false});
      this.setState({
        cars: this.props.cars.filter((val,i) => i !== this.state.selectedItemIndex)
      });
      let that = this;
      this.unidadService.eliminarUnidad(this.state.selectedItem.id).then(res =>  {
          if(res && res.result == 'OK'){
            that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Registro borrado'});
            this.props.handleUnidadesTable();
          }
      });


    }

    actionTemplate(rowData, column) {
      return (<div>
        {this.permissionService.checkPermission('unidad_editar') && <Button
          type="button" 
          icon="pi pi-pencil" 
          onClick={e=> this.editar(rowData)}
          className="p-button-warning"
          tooltip="Editar"
          tooltipOptions={{ position: 'top' }}
        />}
        {this.permissionService.checkPermission('unidad_borrar') && <Button
          type="button" 
          icon="pi pi-trash" 
          onClick={e=> this.eliminar(column,rowData)}
          className="p-button-warning"
          tooltip="Borrar"
          tooltipOptions={{ position: 'top' }}
        />}
      </div>);
    }
    facturaTemplate(rowData, column) {
      return (
      <div>
        {rowData.numero_factura}
        {(rowData.factura) ? 
          <a  href={url+"altadeunidad/"+rowData.factura} target={"_blank"}> 
            <Button type="button" icon="pi pi-file-pdf" 
            className="p-button-warning"
            tooltip="Abrir documento"
            tooltipOptions={{ position: 'top' }}
            />
          </a>: <></>}
      </div>);
    }
    llantaTemplate(rowData, column) {
      const llantas = rowData.llanta_ids
      let llantas_array = [];
      if(llantas)
      {
        llantas_array = llantas.split(',');
      }
      return (
      <ul className='list-style-none'>
        {
          llantas_array.map((item, key) => 
            <li>{item}</li>
          )
        }
      </ul>);
    }
    pedimentoTemplate(rowData, column) {
      return (
      <div>
        {rowData.numero_pedimento}
        {(rowData.pedimento) ? 
          <a icon="pi pi-file-pdf"  href={url+"altadeunidad/"+rowData.pedimento} target={"_blank"}> 
            <Button type="button" icon="pi pi-file-pdf" 
            className="p-button-warning"
            tooltip="Abrir documento"
            tooltipOptions={{ position: 'top' }}
            />
          </a>: <></>}
      </div>);
    }
    tarjetaCirculacionTemplate(rowData, column) {
      return (
      <div>
        {rowData.numero_tarjeta_circulacion}
        {(rowData.tarjeta_circulacion) ? 
          <a icon="pi pi-file-pdf"  href={url+"altadeunidad/"+rowData.tarjeta_circulacion} target={"_blank"}> 
            <Button type="button" icon="pi pi-download"/>
          </a>: <></>}
      </div>);
    }

    checkboxTemplate = (rowData, column) => {
      return <input key={column} type="checkbox"></input>
    }

    render() {
        const footer = (
          <div>
                <Button label="Sí" className='p-button-warning mr-2' icon="pi pi-check" onClick={this.confirmar_borrado.bind(this)} />
                <Button label="No" className='p-button-warning' icon="pi pi-times" onClick={e=> this.setState({visible2:false})}/>
            </div>

        );

        return (<div>
          <Toast ref={(el) => this.growl = el} />
            <Dialog header="Editar"  visible={this.state.visible} style={{width: '50vw',height:'90vh',overflow:'scroll'}} modal={true} onHide={() => this.setState({visible: false,visible2:false	})}>
              <EditarUnidad values={this.state.item} growl={this.growl} onSaved={()=> this.setState({visible:false})}/>

            </Dialog>
            <Dialog header="Eliminar"  footer={footer} visible={this.state.visible2} style={{width: '50vw'}} modal={true} onHide={() => this.setState({visible: false,visible2:false	})}>
              ¿Confirma eliminar?
            </Dialog>
            <DataTable value={this.props.cars} paginator={true} 
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10}>
                <Column field="tipo_unidad" header="Tipo de unidad" style={{width:100}}/>
                <Column field="fecha_adquisicion" header="Fecha de adquisición" style={{width:115}} />
                <Column body={this.facturaTemplate.bind(this)} header="Número de factura" style={{width:110}}/>
                <Column body={this.pedimentoTemplate.bind(this)} header="Número de pedimento" style={{width:115}} />
                <Column field="placas" header="Placas"  />
                <Column field="eco" header="Eco"  />
                <Column field="marca" header="Marca"  />
                <Column field="serie_vin" header="Serie"  />
                <Column field="numero_motor" header="Número de motor"  style={{width:115}} />
                <Column body={this.llantaTemplate.bind(this)} header="Llantas"  />
                <Column body={this.actionTemplate.bind(this)} header="Opciones" style={{ textAlign: 'center'}} />
            </DataTable>
        </div>);
    }
}
