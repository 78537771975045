import React from "react";
export default function remolque_48({cambiar}){
return(

  <svg
  height="1310"


  viewBox="40 20 550 650">

    <g
       id="layer1">
      <rect
         style={{opacity:"1",vectorEffect:"none",fill:"#ffffff",fillOpacity:"1",strokeWidth:"0.26458332",strokeLinecap:"butt",strokeLinejoin:"miter",strokeMiterlimit:"4",strokeDasharray:"none",strokeDashoffset:"0",strokeOpacity:"1"}}
         id="rect10"
         width="169.33333"
         height="1.5"
         x="21.544643"
         y="20.699404" />
      <rect
         style={{opacity:"1",vectorEffect:"none",fill:"#000000",fillOpacity:"1",strokeWidth:"0.26458332",strokeLinecap:"butt",strokeLinejoin:"miter",strokeMiterlimit:"4",strokeDasharray:"none",strokeDashoffset:"0",strokeOpacity:"1"}}
         id="rect12"
         width="119.81845"
         height="1.8"
         x="43.467262"
         y="20.699404" />
      <rect
         style={{opacity:"1",vectorEffect:"none",fill:"#000000",fillOpacity:"1",strokeWidth:"0.12959711",strokeLinecap:"butt",strokeLinejoin:"miter",strokeMiterlimit:"4",strokeDasharray:"none",strokeDashoffset:"0",strokeOpacity:"1"}}
         id="rect14"
         width="1.8"
         height="250.59822"
         x="161.4857"
         y="20.699404" />
      <rect
         style={{opacity:"1",vectorEffect:"none",fill:"#000000",fillOpacity:"1",strokeWidth:"0.12959711",strokeLinecap:"butt",strokeLinejoin:"miter",strokeMiterlimit:"4",strokeDasharray:"none",strokeDashoffset:"0",strokeOpacity:"1"}}
         id="rect14-3"
         width="1.8000001"
         height="250.59822"
         x="43.467262"
         y="20.699404" />
      <rect
         style={{opacity:"1",vectorEffect:"none",fill:"#000000",fillOpacity:"1",strokeWidth:"0.26458332",strokeLinecap:"butt",strokeLinejoin:"miter",strokeMiterlimit:"4",strokeDasharray:"none",strokeDashoffset:"0",strokeOpacity:"1"}}
         id="rect12-6"
         width="119.81845"
         height="1.8000001"
         x="43.467262"
         y="269.49765" />
      <g
         id="layer1-5"

         transform="translate(177.44693,-4.7653303)">
        <path

           id="path4-2"
           style={{strokeWidth:"0.14209914",fill:"#ffffff"}}
           d="m -105.09399,230.9132 c 0,-2.23906 -1.82086,-4.05992 -4.05991,-4.05992 h -3.38339 c -2.23905,0 -4.05991,1.82086 -4.05991,4.05992 v 22.32989 c 0.26896,1.97275 1.23825,3.73477 3.26501,3.7708 2.04079,-0.002 3.56214,0.0232 5.38384,-0.0409 1.73172,0.17288 2.73638,-2.39613 2.8545,-3.72989 -2e-5,-7.94436 -1.4e-4,-14.38585 -1.4e-4,-22.32989 z m -10.14986,0 c 0,-1.49261 1.21396,-2.70656 2.70656,-2.70656 v 3.77984 l -2.70656,2.70655 z m 0,5.69349 2.70656,-2.70657 v 5.52965 l -2.70656,2.70656 z m 2.7067,19.3431 c -1.49274,0 -2.7067,-1.21396 -2.7067,-2.7067 v -1.74981 l 2.7067,-2.70671 z m 0,-9.07687 -2.7067,2.70671 v -5.52965 l 2.7067,-2.70671 z m 6.08995,6.37017 c 0,1.49204 -1.35335,2.70599 -2.7067,2.7067 v -7.16251 l 2.7067,2.706 z m 0,-3.66346 -2.7067,-2.70585 v -5.52979 l 2.7067,2.70599 z m 0,-7.4433 -2.7067,-2.70585 v -1.07384 h -1.35336 v 17.59329 h -0.67667 v -27.74329 h 0.67667 v 6.08994 h 1.35336 v -0.39589 l 2.7067,2.706 z m 0,-7.4433 -2.7067,-2.70585 v -3.78054 c 1.35335,5.3e-4 2.7067,1.21452 2.7067,2.70656 z"
            />
        <path

           style={{strokeWidth:"0.14209914"}}
           id="path4-7"
           d="m -119.11689,189.10904 c 0,-2.23906 -1.82086,-4.05992 -4.05991,-4.05992 h -3.38339 c -2.23905,0 -4.05991,1.82086 -4.05991,4.05992 v 22.32989 c 0.26896,1.97275 1.23825,3.73477 3.26501,3.7708 2.04079,-0.002 3.56214,0.0232 5.38384,-0.0409 1.73172,0.17288 2.73638,-2.39613 2.8545,-3.72989 -2e-5,-7.94436 -1.4e-4,-14.38585 -1.4e-4,-22.32989 z m -10.14986,0 c 0,-1.49261 1.21396,-2.70656 2.70656,-2.70656 v 3.77984 l -2.70656,2.70655 z m 0,5.69349 2.70656,-2.70657 v 5.52965 l -2.70656,2.70656 z m 2.7067,19.3431 c -1.49274,0 -2.7067,-1.21396 -2.7067,-2.7067 v -1.74981 l 2.7067,-2.70671 z m 0,-9.07687 -2.7067,2.70671 v -5.52965 l 2.7067,-2.70671 z m 6.08995,6.37017 c 0,1.49204 -1.35335,2.70599 -2.7067,2.7067 v -7.16251 l 2.7067,2.706 z m 0,-3.66346 -2.7067,-2.70585 v -5.52979 l 2.7067,2.70599 z m 0,-7.4433 -2.7067,-2.70585 v -1.07384 h -1.35336 v 17.59329 h -0.67667 v -27.74329 h 0.67667 v 6.08994 h 1.35336 v -0.39589 l 2.7067,2.706 z m 0,-7.4433 -2.7067,-2.70585 v -3.78054 c 1.35335,5.3e-4 2.7067,1.21452 2.7067,2.70656 z"
            />
        <path

           style={{strokeWidth:"0.14109492"}}
           id="path2489-6-9-9"
           d="m -33.385306,215.43412 c 1.087559,-0.72298 1.807139,-2.24003 1.807139,-3.64167 v -22.17208 c 0,-2.22323 -1.807991,-4.03122 -4.031221,-4.03122 h -3.359468 c -2.223222,0 -4.031202,1.80799 -4.031202,4.03122 v 8.73448 c 1.4e-4,4.70312 0,0 1.4e-4,4.70312 v 8.73448 c 0,1.4015 0.719582,2.91869 1.807422,3.64167 3.16684,0.20757 4.012398,0.20584 7.80719,0 z m 0.463489,-7.27923 -2.687571,-2.68687 v -5.49057 l 2.687571,2.68687 z m -2.687709,-21.22195 c 1.34379,5.3e-4 2.687579,1.20594 2.687579,2.68743 v 3.75313 l -2.687579,-2.68687 z m -6.04675,2.68743 c 0,-1.48206 1.20523,-2.68743 2.68742,-2.68743 v 3.75312 l -2.68742,2.68744 z m 2.687568,24.85966 c -1.482198,0 -2.687568,-1.20537 -2.687568,-2.68758 v -1.73744 l 2.687568,-2.68757 z m 0,-9.01272 -2.687568,2.68758 v -5.49057 l 2.687568,-2.68758 z m 0,-7.39069 -2.687568,2.68757 v -5.49057 l 2.687568,-2.68757 z m 2.01553,16.40341 h -0.671888 v -27.54709 h 0.671888 v 8.73448 h 1.34379 v -3.08067 l 2.687571,2.68687 v 5.49057 l -2.687571,-2.68687 v -1.06625 h -1.34379 z m 1.34379,0 v -7.11189 l 2.687571,2.68687 v 1.73744 c -1.3e-4,1.4815 -1.343919,2.68688 -2.687571,2.68758 z"
            />
        <path

           style={{strokeWidth:"0.14209914"}}
           id="path4-2-3"
           d="m -105.09389,189.10904 c 0,-2.23906 -1.82086,-4.05992 -4.05991,-4.05992 h -3.38339 c -2.23905,0 -4.05991,1.82086 -4.05991,4.05992 v 22.32989 c 0.26896,1.97275 1.23825,3.73477 3.26501,3.7708 2.04079,-0.002 3.56214,0.0232 5.38384,-0.0409 1.73172,0.17288 2.73638,-2.39613 2.8545,-3.72989 -2e-5,-7.94436 -1.4e-4,-14.38585 -1.4e-4,-22.32989 z m -10.14986,0 c 0,-1.49261 1.21396,-2.70656 2.70656,-2.70656 v 3.77984 l -2.70656,2.70655 z m 0,5.69349 2.70656,-2.70657 v 5.52965 l -2.70656,2.70656 z m 2.7067,19.3431 c -1.49274,0 -2.7067,-1.21396 -2.7067,-2.7067 v -1.74981 l 2.7067,-2.70671 z m 0,-9.07687 -2.7067,2.70671 v -5.52965 l 2.7067,-2.70671 z m 6.08995,6.37017 c 0,1.49204 -1.35335,2.70599 -2.7067,2.7067 v -7.16251 l 2.7067,2.706 z m 0,-3.66346 -2.7067,-2.70585 v -5.52979 l 2.7067,2.70599 z m 0,-7.4433 -2.7067,-2.70585 v -1.07384 h -1.35336 v 17.59329 h -0.67667 v -27.74329 h 0.67667 v 6.08994 h 1.35336 v -0.39589 l 2.7067,2.706 z m 0,-7.4433 -2.7067,-2.70585 v -3.78054 c 1.35335,5.3e-4 2.7067,1.21452 2.7067,2.70656 z"
            />
        <text

           style={{fontStyle:"normal",fontWeight:"normal",fontSize:"10.58333302px",lineHeight:"1.25",fontFamily:"sans-serif",textAlign:"center",letterSpacing:"0px",wordSpacing:"0px",textAnchor:"middle",fill:"#000000",fillOpacity:"1",stroke:"none",strokeWidth:"0.26458332"}}
           x="-74.920937"
           y="95.390327"
           id="text206"><tspan

             id="tspan204"
             x="-73.508072"
             y="95.390327"

             style={{fontStyle:"normal",fontVariant:"normal",fontWeight:"normal",fontStretch:"normal",fontFamily:"Poppins",textAlign:"center",textAnchor:"middle",strokeWidth:"0.26458332"}}>REMOLQUE </tspan><tspan
             x="-74.920937"
             y="108.98859"
             style={{fontStyle:"normal",fontVariant:"normal",fontWeight:"normal",fontStretch:"normal",fontFamily:"Poppins",textAlign:"center",textAnchor:"middle",strokeWidth:"0.26458332"}}
             id="tspan210">48 PIES</tspan></text>
        <rect
           style={{opacity:"1",vectorEffect:"none",fill:"#000000",fillOpacity:"1",strokeWidth:"0.26458332",strokeLinecap:"butt",strokeLinejoin:"miter",strokeMiterlimit:"4",strokeDasharray:"none",strokeDashoffset:"0",strokeOpacity:"1"}}
           id="rect214"
           width="13.229163"
           height="11.906248"
           x="-131.67401"
           y="194.60909" />
        <rect
           style={{opacity:"1",vectorEffect:"none",fill:"#008080",fillOpacity:"1",strokeWidth:"0.26458332",strokeLinecap:"butt",strokeLinejoin:"miter",strokeMiterlimit:"4",strokeDasharray:"none",strokeDashoffset:"0",strokeOpacity:"1"}}
           id="rect231"
           cursor="pointer"
           onClick={()=>cambiar(1)}
           width="10.583333"
           height="9.260417"
           x="-130.35109"
           y="195.93199" />
        <rect
           style={{opacity:"1",vectorEffect:"none",fill:"#000000",fillOpacity:"1",strokeWidth:"0.26458332",strokeLinecap:"butt",strokeLinejoin:"miter",strokeMiterlimit:"4",strokeDasharray:"none",strokeDashoffset:"0",strokeOpacity:"1"}}
           id="rect214-6"
           width="13.229163"
           height="11.906248"
           x="-117.38651"
           y="194.60909" />
        <rect
           style={{opacity:"1",vectorEffect:"none",fill:"#008080",fillOpacity:"1",strokeWidth:"0.26458332",strokeLinecap:"butt",strokeLinejoin:"miter",strokeMiterlimit:"4",strokeDasharray:"none",strokeDashoffset:"0",strokeOpacity:"1"}}
           id="rect231-2"
           cursor="pointer"
           onClick={()=>cambiar(2)}
           width="10.583333"
           height="9.260417"
           x="-116.06361"
           y="195.93199" />
        <path

           style={{strokeWidth:"0.14209914"}}
           id="path4-7-0"
           d="m -119.11687,231.70695 c 0,-2.23906 -1.82086,-4.05992 -4.05991,-4.05992 h -3.38339 c -2.23905,0 -4.05991,1.82086 -4.05991,4.05992 v 22.32989 c 0.26896,1.97275 1.23825,3.73477 3.26501,3.7708 2.04079,-0.002 3.56214,0.0232 5.38384,-0.0409 1.73172,0.17288 2.73638,-2.39613 2.8545,-3.72989 -2e-5,-7.94436 -1.4e-4,-14.38585 -1.4e-4,-22.32989 z m -10.14986,0 c 0,-1.49261 1.21396,-2.70656 2.70656,-2.70656 v 3.77984 l -2.70656,2.70655 z m 0,5.69349 2.70656,-2.70657 v 5.52965 l -2.70656,2.70656 z m 2.7067,19.3431 c -1.49274,0 -2.7067,-1.21396 -2.7067,-2.7067 v -1.74981 l 2.7067,-2.70671 z m 0,-9.07687 -2.7067,2.70671 v -5.52965 l 2.7067,-2.70671 z m 6.08995,6.37017 c 0,1.49204 -1.35335,2.70599 -2.7067,2.7067 v -7.16251 l 2.7067,2.706 z m 0,-3.66346 -2.7067,-2.70585 v -5.52979 l 2.7067,2.70599 z m 0,-7.4433 -2.7067,-2.70585 v -1.07384 h -1.35336 v 17.59329 h -0.67667 v -27.74329 h 0.67667 v 6.08994 h 1.35336 v -0.39589 l 2.7067,2.706 z m 0,-7.4433 -2.7067,-2.70585 v -3.78054 c 1.35335,5.3e-4 2.7067,1.21452 2.7067,2.70656 z"
            />
        <path

           style={{strokeWidth:"0.14209914"}}
           id="path4-2-3-2"
           d="m -105.09387,231.70695 c 0,-2.23906 -1.82086,-4.05992 -4.05991,-4.05992 h -3.38339 c -2.23905,0 -4.05991,1.82086 -4.05991,4.05992 v 22.32989 c 0.26896,1.97275 1.23825,3.73477 3.26501,3.7708 2.04079,-0.002 3.56214,0.0232 5.38384,-0.0409 1.73172,0.17288 2.73638,-2.39613 2.8545,-3.72989 -2e-5,-7.94436 -1.4e-4,-14.38585 -1.4e-4,-22.32989 z m -10.14986,0 c 0,-1.49261 1.21396,-2.70656 2.70656,-2.70656 v 3.77984 l -2.70656,2.70655 z m 0,5.69349 2.70656,-2.70657 v 5.52965 l -2.70656,2.70656 z m 2.7067,19.3431 c -1.49274,0 -2.7067,-1.21396 -2.7067,-2.7067 v -1.74981 l 2.7067,-2.70671 z m 0,-9.07687 -2.7067,2.70671 v -5.52965 l 2.7067,-2.70671 z m 6.08995,6.37017 c 0,1.49204 -1.35335,2.70599 -2.7067,2.7067 v -7.16251 l 2.7067,2.706 z m 0,-3.66346 -2.7067,-2.70585 v -5.52979 l 2.7067,2.70599 z m 0,-7.4433 -2.7067,-2.70585 v -1.07384 h -1.35336 v 17.59329 h -0.67667 v -27.74329 h 0.67667 v 6.08994 h 1.35336 v -0.39589 l 2.7067,2.706 z m 0,-7.4433 -2.7067,-2.70585 v -3.78054 c 1.35335,5.3e-4 2.7067,1.21452 2.7067,2.70656 z"
            />
        <rect
           style={{opacity:"1",vectorEffect:"none",fill:"#000000",fillOpacity:"1",strokeWidth:"0.26458332",strokeLinecap:"butt",strokeLinejoin:"miter",strokeMiterlimit:"4",strokeDasharray:"none",strokeDashoffset:"0",strokeOpacity:"1"}}
           id="rect214-3"
           width="13.229163"
           height="11.906248"
           x="-131.67404"
           y="237.20702" />
        <rect
           style={{opacity:"1",vectorEffect:"none",fill:"#008080",fillOpacity:"1",strokeWidth:"0.26458332",strokeLinecap:"butt",strokeLinejoin:"miter",strokeMiterlimit:"4",strokeDasharray:"none",strokeDashoffset:"0",strokeOpacity:"1"}}
           id="rect231-75"
           cursor="pointer"
           onClick={()=>cambiar(5)}
           width="10.583333"
           height="9.260417"
           x="-130.35112"
           y="238.52992" />
        <rect
           style={{opacity:"1",vectorEffect:"none",fill:"#000000",fillOpacity:"1",strokeWidth:"0.26458332",strokeLinecap:"butt",strokeLinejoin:"miter",strokeMiterlimit:"4",strokeDasharray:"none",strokeDashoffset:"0",strokeOpacity:"1"}}
           id="rect214-6-92"
           width="13.229163"
           height="11.906248"
           x="-117.38649"
           y="237.20702" />
        <rect
           style={{opacity:"1",vectorEffect:"none",fill:"#008080",fillOpacity:"1",strokeWidth:"0.26458332",strokeLinecap:"butt",strokeLinejoin:"miter",strokeMiterlimit:"4",strokeDasharray:"none",strokeDashoffset:"0",strokeOpacity:"1"}}
           id="rect231-2-28"
           cursor="pointer"
           onClick={()=>cambiar(6)}
           width="10.583333"
           height="9.260417"
           x="-116.06361"
           y="238.52992" />
        <rect
           style={{opacity:"1",vectorEffect:"none",fill:"#000000",fillOpacity:"1",strokeWidth:"0.26458332",strokeLinecap:"butt",strokeLinejoin:"miter",strokeMiterlimit:"4",strokeDasharray:"none",strokeDashoffset:"0",strokeOpacity:"1"}}
           id="rect214-6-3"
           width="13.229163"
           height="11.906248"
           x="-44.09692"
           y="194.60909" />
        <rect
           style={{opacity:"1",vectorEffect:"none",fill:"#008080",fillOpacity:"1",strokeWidth:"0.26458332",strokeLinecap:"butt",strokeLinejoin:"miter",strokeMiterlimit:"4",strokeDasharray:"none",strokeDashoffset:"0",strokeOpacity:"1"}}
           id="rect231-2-6"
           cursor="pointer"
           onClick={()=>cambiar(3)}
           width="10.583333"
           height="9.260417"
           x="-42.774014"
           y="195.93199" />
        <path

           style={{strokeWidth:"0.14109492"}}
           id="path2489-6-9-9-1"
           d="m -19.362391,215.434 c 1.08756,-0.72298 1.80714,-2.24003 1.80714,-3.64167 v -22.17208 c 0,-2.22323 -1.80799,-4.03122 -4.03122,-4.03122 h -3.35947 c -2.22322,0 -4.0312,1.80799 -4.0312,4.03122 v 8.73448 c 1.4e-4,4.70312 0,0 1.4e-4,4.70312 v 8.73448 c 0,1.4015 0.71958,2.91869 1.80742,3.64167 3.16684,0.20757 4.0124,0.20584 7.80719,0 z m 0.46349,-7.27923 -2.68757,-2.68687 v -5.49057 l 2.68757,2.68687 z m -2.68771,-21.22195 c 1.34379,5.3e-4 2.68758,1.20594 2.68758,2.68743 v 3.75313 l -2.68758,-2.68687 z m -6.04675,2.68743 c 0,-1.48206 1.20523,-2.68743 2.68742,-2.68743 v 3.75312 l -2.68742,2.68744 z m 2.68757,24.85966 c -1.4822,0 -2.68757,-1.20537 -2.68757,-2.68758 v -1.73744 l 2.68757,-2.68757 z m 0,-9.01272 -2.68757,2.68758 v -5.49057 l 2.68757,-2.68758 z m 0,-7.39069 -2.68757,2.68757 v -5.49057 l 2.68757,-2.68757 z m 2.01553,16.40341 h -0.67189 v -27.54709 h 0.67189 v 8.73448 h 1.34379 v -3.08067 l 2.68757,2.68687 v 5.49057 l -2.68757,-2.68687 v -1.06625 h -1.34379 z m 1.34379,0 v -7.11189 l 2.68757,2.68687 v 1.73744 c -1.3e-4,1.4815 -1.34392,2.68688 -2.68757,2.68758 z"
            />
        <rect
           style={{opacity:"1",vectorEffect:"none",fill:"#000000",fillOpacity:"1",strokeWidth:"0.26458332",strokeLinecap:"butt",strokeLinejoin:"miter",strokeMiterlimit:"4",strokeDasharray:"none",strokeDashoffset:"0",strokeOpacity:"1"}}
           id="rect214-6-3-2"
           width="13.229163"
           height="11.906248"
           x="-30.074001"
           y="194.60933" />
        <rect
           style={{opacity:"1",vectorEffect:"none",fill:"#008080",fillOpacity:"1",strokeWidth:"0.26458332",strokeLinecap:"butt",strokeLinejoin:"miter",strokeMiterlimit:"4",strokeDasharray:"none",strokeDashoffset:"0",strokeOpacity:"1"}}
           id="rect231-2-6-9"
           cursor="pointer"
           onClick={()=>cambiar(4)}
           width="10.583333"
           height="9.260417"
           x="-28.751095"
           y="195.93224" />
        <path

           style={{strokeWidth:"0.14109492"}}
           id="path2489-6-9-9-5"
           d="m -33.120732,257.76751 c 1.08756,-0.72298 1.80714,-2.24003 1.80714,-3.64167 v -22.17208 c 0,-2.22323 -1.80799,-4.03122 -4.03122,-4.03122 h -3.35947 c -2.22322,0 -4.0312,1.80799 -4.0312,4.03122 v 8.73448 c 1.4e-4,4.70312 0,0 1.4e-4,4.70312 v 8.73448 c 0,1.4015 0.71958,2.91869 1.80742,3.64167 3.16684,0.20757 4.0124,0.20584 7.80719,0 z m 0.46349,-7.27923 -2.68757,-2.68687 v -5.49057 l 2.68757,2.68687 z m -2.68771,-21.22195 c 1.34379,5.3e-4 2.68758,1.20594 2.68758,2.68743 v 3.75313 l -2.68758,-2.68687 z m -6.04675,2.68743 c 0,-1.48206 1.20523,-2.68743 2.68742,-2.68743 v 3.75312 l -2.68742,2.68744 z m 2.68757,24.85966 c -1.4822,0 -2.68757,-1.20537 -2.68757,-2.68758 v -1.73744 l 2.68757,-2.68757 z m 0,-9.01272 -2.68757,2.68758 v -5.49057 l 2.68757,-2.68758 z m 0,-7.39069 -2.68757,2.68757 v -5.49057 l 2.68757,-2.68757 z m 2.01553,16.40341 h -0.67189 v -27.54709 h 0.67189 v 8.73448 h 1.34379 v -3.08067 l 2.68757,2.68687 v 5.49057 l -2.68757,-2.68687 v -1.06625 h -1.34379 z m 1.34379,0 v -7.11189 l 2.68757,2.68687 v 1.73744 c -1.3e-4,1.4815 -1.34392,2.68688 -2.68757,2.68758 z"
            />
        <rect
           style={{opacity:"1",vectorEffect:"none",fill:"#000000",fillOpacity:"1",strokeWidth:"0.26458332",strokeLinecap:"butt",strokeLinejoin:"miter",strokeMiterlimit:"4",strokeDasharray:"none",strokeDashoffset:"0",strokeOpacity:"1"}}
           id="rect214-6-3-0"
           width="13.229163"
           height="11.906248"
           x="-43.832336"
           y="236.94243" />
        <rect
           style={{opacity:"1",vectorEffect:"none",fill:"#008080",fillOpacity:"1",strokeWidth:"0.26458332",strokeLinecap:"butt",strokeLinejoin:"miter",strokeMiterlimit:"4",strokeDasharray:"none",strokeDashoffset:"0",strokeOpacity:"1"}}
           id="rect231-2-6-3"
           cursor="pointer"
           onClick={()=>cambiar(7)}
           width="10.583333"
           height="9.260417"
           x="-42.50943"
           y="238.26534" />
        <path

           style={{strokeWidth:"0.14109492"}}
           id="path2489-6-9-9-1-6"
           d="m -19.097812,257.76739 c 1.08756,-0.72298 1.80714,-2.24003 1.80714,-3.64167 v -22.17208 c 0,-2.22323 -1.80799,-4.03122 -4.03122,-4.03122 h -3.35947 c -2.22322,0 -4.0312,1.80799 -4.0312,4.03122 v 8.73448 c 1.4e-4,4.70312 0,0 1.4e-4,4.70312 v 8.73448 c 0,1.4015 0.71958,2.91869 1.80742,3.64167 3.16684,0.20757 4.0124,0.20584 7.80719,0 z m 0.46349,-7.27923 -2.68757,-2.68687 v -5.49057 l 2.68757,2.68687 z m -2.68771,-21.22195 c 1.34379,5.3e-4 2.68758,1.20594 2.68758,2.68743 v 3.75313 l -2.68758,-2.68687 z m -6.04675,2.68743 c 0,-1.48206 1.20523,-2.68743 2.68742,-2.68743 v 3.75312 l -2.68742,2.68744 z m 2.68757,24.85966 c -1.4822,0 -2.68757,-1.20537 -2.68757,-2.68758 v -1.73744 l 2.68757,-2.68757 z m 0,-9.01272 -2.68757,2.68758 v -5.49057 l 2.68757,-2.68758 z m 0,-7.39069 -2.68757,2.68757 v -5.49057 l 2.68757,-2.68757 z m 2.01553,16.40341 h -0.67189 v -27.54709 h 0.67189 v 8.73448 h 1.34379 v -3.08067 l 2.68757,2.68687 v 5.49057 l -2.68757,-2.68687 v -1.06625 h -1.34379 z m 1.34379,0 v -7.11189 l 2.68757,2.68687 v 1.73744 c -1.3e-4,1.4815 -1.34392,2.68688 -2.68757,2.68758 z"
            />
        <rect
           style={{opacity:"1",vectorEffect:"none",fill:"#000000",fillOpacity:"1",strokeWidth:"0.26458332",strokeLinecap:"butt",strokeLinejoin:"miter",strokeMiterlimit:"4",strokeDasharray:"none",strokeDashoffset:"0",strokeOpacity:"1"}}
           id="rect214-6-3-2-1"
           width="13.229163"
           height="11.906248"
           x="-29.809422"
           y="236.94267" />
        <rect
           style={{opacity:"1",vectorEffect:"none",fill:"#008080",fillOpacity:"1",strokeWidth:"0.26458332",strokeLinecap:"butt",strokeLinejoin:"miter",strokeMiterlimit:"4",strokeDasharray:"none",strokeDashoffset:"0",strokeOpacity:"1"}}
           id="rect231-2-6-9-0"
           width="10.583333"
           cursor="pointer"
           onClick={()=>cambiar(8)}
           height="9.260417"
           x="-28.486515"
           y="238.26558" />
        <path

           style={{strokeWidth:"0.26458332"}}
           id="path2489-5-6"
           d="M -55.738597,197.25491 H -66.32193 c 0,-2.08386 -1.821912,-3.33904 -3.905772,-3.33904 h -3.77984 c -2.08386,0 -3.691471,1.25518 -3.691471,3.33904 h -26.987497 c 0,2.51989 -2.6e-4,6.74079 0,9.26042 l 26.899398,-2.6e-4 c 0,2.08386 1.69598,3.77984 3.77984,3.77984 h 3.77983 c 2.08386,0 3.77984,-1.69598 3.77984,-3.77984 l 22.615255,2.6e-4 c -2.6e-4,-2.51963 0,-6.74053 0,-9.26042 z m -22.048785,6.60021 -26.899128,0.0144 v -3.96875 l 26.899128,0.17453 z m 8.81962,2.66021 c 0,0.69427 -0.56435,1.25995 -1.25994,1.25995 h -3.77984 c -0.69559,0 -1.25995,-0.56568 -1.25995,-1.25995 v -8.81935 c 0,-0.69427 0.56436,-1.25995 1.25995,-1.25995 h 3.77984 c 0.69559,0 1.25994,0.56568 1.25994,1.25995 z m 25.135415,-2.64583 H -66.32193 v -3.96875 h 22.489583 z"
            />
        <rect
           style={{strokeWidth:"0.26458332"}}
           id="rect2491-3"
           height="2.5198917"
           width="2.5198917"
           y="204.0179"
           x="-74.065475" />
        <path

           style={{strokeWidth:0.26458332}}
           id="path2489-5-6-6"
           d="m -55.474013,239.32363 h -10.58333 c 0,-2.08386 -1.82192,-3.33904 -3.90578,-3.33904 h -3.77984 c -2.08386,0 -3.69147,1.25518 -3.69147,3.33904 H -104.4219 c 0,2.51989 -2.6e-4,6.74079 0,9.26042 l 26.899367,-2.6e-4 c 0,2.08386 1.69598,3.77984 3.77984,3.77984 h 3.77984 c 2.08385,0 3.77983,-1.69598 3.77983,-3.77984 l 22.61526,2.6e-4 c -2.6e-4,-2.51963 0,-6.74053 0,-9.26042 z m -22.04879,6.60021 -26.899097,0.0144 v -3.96875 l 26.899097,0.17453 z m 8.81962,2.66021 c 0,0.69427 -0.56435,1.25995 -1.25994,1.25995 h -3.77984 c -0.69559,0 -1.25995,-0.56568 -1.25995,-1.25995 v -8.81935 c 0,-0.69427 0.56436,-1.25995 1.25995,-1.25995 h 3.77984 c 0.69559,0 1.25994,0.56568 1.25994,1.25995 z m 25.13542,-2.64583 h -22.48958 v -3.96875 h 22.48958 z"
            />
        <rect
           style={{strokeWidth:0.26458332}}
           id="rect2491-3-9"
           height="2.5198917"
           width="2.5198917"
           y="246.0867"
           x="-73.800888" />
        <text

           style={{fontStyle:"normal",fontWeight:"normal",fontSize:"10.58333302px",lineHeight:"1.25",fontFamily:"sans-serif",letterSpacing:"0px",wordSpacing:"0px",fill:"#000000",fillOpacity:"1",stroke:"none",strokeWidth:"0.26458332"}}
           x="-128.49901"
           y="203.86951"
           id="text608"><tspan

             id="tspan606"
             x="-128.49901"
             y="203.86951"
             style={{strokeWidth:"0.26458332",fontFamily:"sans-serif",fontWeight:"bold",fontStyle:"normal",fontStretch:"normal",fontVariant:"normal"}} cursor="pointer"
              onClick={()=>cambiar(1)}>1</tspan></text>
        <text

           style={{fontStyle:"normal",fontWeight:"normal",fontSize:"10.58333302px",lineHeight:"1.25",fontFamily:"sans-serif",letterSpacing:"0px",wordSpacing:"0px",fill:"#000000",fillOpacity:"1",stroke:"none",strokeWidth:"0.26458332"}}
           x="-113.94693"
           y="203.86951"
           id="text612"><tspan

             id="tspan610"
             x="-113.94693"
             y="203.86951"
             style={{strokeWidth:"0.26458332",fontFamily:"sans-serif",fontWeight:"bold",fontStyle:"normal",fontStretch:"normal",fontVariant:"normal"}} cursor="pointer"
              onClick={()=>cambiar(2)}>2</tspan></text>
        <text

           style={{fontStyle:"normal",fontWeight:"normal",fontSize:"10.58333302px",lineHeight:"1.25",fontFamily:"sans-serif",letterSpacing:"0px",wordSpacing:"0px",fill:"#000000",fillOpacity:"1",stroke:"none",strokeWidth:"0.26458332"}}
           x="-41.186516"
           y="203.86951"
           id="text616"><tspan

             id="tspan614"
             x="-41.186516"
             y="203.86951"
             style={{strokeWidth:"0.26458332",fontFamily:"sans-serif",fontWeight:"bold",fontStyle:"normal",fontStretch:"normal",fontVariant:"normal"}} cursor="pointer"
              onClick={()=>cambiar(3)}>3</tspan></text>
        <text

           style={{fontStyle:"normal",fontWeight:"normal",fontSize:"10.58333302px",lineHeight:"1.25",fontFamily:"sans-serif",letterSpacing:"0px",wordSpacing:"0px",fill:"#000000",fillOpacity:"1",stroke:"none",strokeWidth:"0.26458332"}}
           x="-26.634432"
           y="203.86951"
           id="text620"><tspan

             id="tspan618"
             x="-26.634432"
             y="203.86951"
             style={{strokeWidth:"0.26458332",fontFamily:"sans-serif",fontWeight:"bold",fontStyle:"normal",fontStretch:"normal",fontVariant:"normal"}} cursor="pointer"
              onClick={()=>cambiar(4)}>4</tspan></text>
        <text

           style={{fontStyle:"normal",fontWeight:"normal",fontSize:"10.58333302px",lineHeight:"1.25",fontFamily:"sans-serif",letterSpacing:"0px",wordSpacing:"0px",fill:"#000000",fillOpacity:"1",stroke:"none",strokeWidth:"0.26458332"}}
           x="-128.49901"
           y="246.73199"
           id="text624"><tspan

             id="tspan622"
             x="-128.49901"
             y="246.73199"
             style={{fontStyle:"normal",fontVariant:"normal",fontWeight:"bold",fontStretch:"normal",fontFamily:"sans-serif",strokeWidth:"0.26458332"}} cursor="pointer"
              onClick={()=>cambiar(5)}>5</tspan></text>
        <text

           style={{fontStyle:"normal",fontWeight:"normal",fontSize:"10.58333302px",lineHeight:"1.25",fontFamily:"sans-serif",letterSpacing:"0px",wordSpacing:"0px",fill:"#000000",fillOpacity:"1",stroke:"none",strokeWidth:"0.26458332"}}
           x="-113.94693"
           y="246.73199"
           id="text628"><tspan

             id="tspan626"
             x="-113.94693"
             y="246.73199"
             style={{fontStyle:"normal",fontVariant:"normal",fontWeight:"bold",fontStretch:"normal",fontFamily:"sans-serif",strokeWidth:"0.26458332"}} cursor="pointer"
              onClick={()=>cambiar(6)}>6</tspan></text>
        <text

           style={{fontStyle:"normal",fontWeight:"normal",fontSize:"10.58333302px",lineHeight:"1.25",fontFamily:"sans-serif",letterSpacing:"0px",wordSpacing:"0px",fill:"#000000",fillOpacity:"1",stroke:"none",strokeWidth:"0.26458332"}}
           x="-39.863598"
           y="246.20284"
           id="text632"><tspan

             id="tspan630"
             x="-39.863598"
             y="246.20284"
             style={{strokeWidth:"0.26458332",fontFamily:"sans-serif",fontWeight:"bold",fontStyle:"normal",fontStretch:"normal",fontVariant:"normal"}} cursor="pointer"
              onClick={()=>cambiar(7)}>7</tspan></text>
        <text

           style={{fontStyle:"normal",fontWeight:"normal",fontSize:"10.58333302px",lineHeight:"1.25",fontFamily:"sans-serif",letterSpacing:"0px",wordSpacing:"0px",fill:"#000000",fillOpacity:"1",stroke:"none",strokeWidth:"0.26458332"}}
           x="-26.634432"
           y="246.20284"
           id="text636"><tspan

             id="tspan634"
             x="-26.634432"
             y="246.20284"
             style={{strokeWidth:"0.26458332",fontFamily:"sans-serif",fontWeight:"bold",fontStyle:"normal",fontStretch:"normal",fontVariant:"normal"}} cursor="pointer"
              onClick={()=>cambiar(8)}>8</tspan></text>
      </g>
    </g>
  </svg>

)

}
