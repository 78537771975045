import React from "react";
import {
	Card,
	CardBody,Col, Row, Container,CardTitle

} from 'reactstrap';
import {Calendar} from 'primereact/calendar';
import {Button} from 'primereact/button';
import ClienteService  from 'controllers/cliente/clienteservice';
import Select from 'react-select';
import  CartaPorteListado from './lista-corta';
import { Toast } from 'primereact/toast';

class ConsultarCartaPorte extends React.Component {
	constructor(props){
		super(props);
		this.servicio = new ClienteService();
		this.state = {date: new Date(),folio:"",rfc:""};
		this.servicio.getClientes().then(data =>  {
			let arrClientes = [];
			data.forEach(item=>{
				arrClientes.push({value:item.id,label:item.razon_social,rfc:item.rfc});

			});
			this.setState({clientes: arrClientes});
		});

		this.buscar  = this.buscar.bind(this);

	}

	buscar(){
		// console.log(this.state);
		this.props.history.push({
			pathname:'/app/carta-porte/resultado',
			state: {
				folio:this.state.folio,
				rfc:this.state.rfc,
				date:this.state.date[0]
			}
		});
	}

	render() {
		const es = {
		firstDayOfWeek: 1,
		dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
		dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
		dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
		monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
		monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"]
};

		return (
			<Card>
				<CardTitle  className="bg-light border-bottom p-3 mb-0">CONSULTAR CARTA PORTE</CardTitle>
				<CardBody>
				<Toast ref={(el) => this.toast = el} />
					<Row>
					<Col xs="6">
					<div className="form-group">
					<label> Consultar por folio:
						<input type="text" value={this.state.folio} onChange={e => this.setState({folio:e.target.value})} className="form-control"/>
					</label>
					</div>
					</Col>
					</Row>
					<Row>
					<Col xs="6">
					<div className="form-group">
					<label> Consulta por cliente:</label>
					<Select options={this.state.clientes} onChange={e =>	this.setState({rfc: e.rfc}) }  placeholder="Seleccione un cliente"  />
					</div>
					</Col>
					</Row>
					<Row>
					<Col xs="6">
					<div className="form-group">
						<label> Consulta por fecha:</label><br/>

						<Calendar value={this.state.date} placeholder="dd/mm/yyyy" dateFormat="dd/mm/yyyy"   onChange={(e) => this.setState({date: e.value})} showIcon={true}  selectionMode="range" readonlyInput={true} />
					</div>
					</Col>
					</Row>
					<Row>
						<Col>
						<center> <Button label="Buscar" onClick={this.buscar} className="p-button-raised p-button-secondary" />	&nbsp;&nbsp;&nbsp;<Button label="Ver todos"  onClick={e=> this.props.history.push('/app/carta-porte/resultado')} className="p-button-raised p-button-secondary" /></center>

							<br/>
							<br/>
						</Col>
					</Row>
					<Row>
						<CartaPorteListado growl={this.toast} history={this.props.history}/>
					</Row>



				</CardBody>
			</Card>
		);
	}
}

export default ConsultarCartaPorte;
