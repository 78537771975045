import React from "react";
import {Button} from 'primereact/button';
import {Panel} from 'primereact/panel';
import EditarEmbarque from './editar';


class EditarPagina extends React.Component {
	constructor(props){
		super(props);
		this.state={};
	}

	componentDidMount(){

		this.setState({
			item : this.props.location.state
		});
	}
	headerTemplate()
	{
		return (<div className="p-panel-header flex-wrap  d-flex">
			<div>
				Editar
			</div>
			<div>
			<Button style={{float:'right'}} className="p-button-warning" onClick={e=> this.props.history.goBack()}  label="Regresar"/>
			</div>
		</div>);
	}

	render() {
		return (
			<div className="container">
				<Panel headerTemplate={this.headerTemplate}>
					<EditarEmbarque values={this.state.item}  history={this.props.history}/>
				</Panel>
			</div>
		);
	}
}

export default EditarPagina;
