import AdminUserService from "controllers/admin-user/service";

const adminUserService = new AdminUserService();

export function configureFakeBackend() {
    let users = [
        { id: 1, username: 'david', password: 'd@vid', firstName: 'David', lastName: 'Torres' },
        { id: 2, username: 'logistica@exnac.com', password: 'Logistica123', firstName: 'Griselda', lastName: 'García' },
        { id: 3, username: 'ycedeno@exnac.com', password: 'Ycedeno123', firstName: 'Ycedeno', lastName: '' },
        { id: 4, username: 'monitoreo@exnac.com', password: 'Monitoreo123', firstName: 'Monitoreo', lastName: '' },
        { id: 5, username: 'suministros@exnac.com', password: 'Suministros123', firstName: 'Suministros', lastName: '' },
        { id: 6, username: 'contabilidad@exnac.com', password: 'Contabilidad123', firstName: 'Contabilidad', lastName: '' },
        { id: 7, username: 'omanzano@exnac.com', password: 'Omanzano123', firstName: 'Oscar', lastName: 'Manzano' },
        { id: 8, username: 'recursoshumanos@exnac.com', password: 'Recursoshumanos123', firstName: 'Recursos', lastName: 'Humanos' }
    ];
    let realFetch = window.fetch;
    window.fetch = function (url, opts) {
        const isLoggedIn = opts.headers['Authorization'] === 'Bearer fake-jwt-token';

        return new Promise((resolve, reject) => {
            // wrap in timeout to simulate server api call
            setTimeout(() => {
                // authenticate - public
                if (url.endsWith('/users/authenticate') && opts.method === 'POST') {
                    const params = JSON.parse(opts.body);
                    adminUserService.loginVerification(params).then((result) => {
                        if(!result.status) return error(result?.message);
                        return ok({
                            id: result.user.id,
                            username: result.user.nombre_de_usuario,
                            name: result.user.nombre,
                            email: result.user.correo_electronico,
                            permissions: result.user.permisos,
                            token: 'fake-jwt-token'
                        });
                    })
                    return
                }

                // get users - secure
                if (url.endsWith('/users') && opts.method === 'GET') {
                    if (!isLoggedIn) return unauthorised();
                    return ok(users);
                }

                // pass through any requests not handled above
                realFetch(url, opts).then(response => resolve(response));

                // private helper functions

                function ok(body) {
                    resolve({ ok: true, text: () => Promise.resolve(JSON.stringify(body)) })
                }

                function unauthorised() {
                    resolve({ status: 401, text: () => Promise.resolve(JSON.stringify({ message: 'Unauthorised' })) })
                }

                function error(message) {
                    resolve({ status: 400, text: () => Promise.resolve(JSON.stringify({ message })) })
                }
            }, 500);
        });
    }
}
