
import React, { useState, useEffect } from "react";
import { TimePicker } from "antd";
import dayjs from 'dayjs';
import moment from "moment";


export default function PTimer(props) {
  const [value, setValue] = useState("12:00 AM");
  const format = "h:mm A";
  useEffect(() => {
    if(props.value)
    {
      if(props.value.length >= 9)
      {
        setValue(moment(props.value).format(format))
      }
      else
      {
        setValue(props.value)
      }
    }
  },[props]);
  const setDate = (time, timeString) =>
  {
    setValue(timeString)
    props.getCommonDataFromChilds({label:props.label,value:timeString})
  }
  return (
      <div className="card flex justify-content-center">
        <TimePicker 
          suffixIcon={<i className="pi pi-clock text-inverse"></i>} 
          className="form-control d-flex" 
          use12Hours 
          format= {format}
          value={dayjs(value,format)}
          size="large" 
          placeholder="Seleccione una hora"
          needConfirm={false}
          onChange={setDate}
          />
      </div>
  )
}