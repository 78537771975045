import React from "react";
import {
	Card,
	CardBody,
	CardTitle
} from 'reactstrap';
import EmbarquePorLiquidarListado from './embarqueViewsLiquidar';

class RutasPorLiquidar extends React.Component {
	render() {
		return (
			<Card>
				<CardTitle className="bg-light border-bottom p-3 mb-0">Embarques por liquidar
				</CardTitle>
				<CardBody>
					<EmbarquePorLiquidarListado parametros={{estatus:"3"}}> </EmbarquePorLiquidarListado>
				</CardBody>
			</Card>
		);
	}
}

export default RutasPorLiquidar;
