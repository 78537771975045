import React from "react";
import { Row, Col } from "reactstrap";
import { Toast } from "primereact/toast";
import Service from "controllers/nomina/calculo";
import { urlPdf } from "../../../../constants";
import { Panel } from 'primereact/panel';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import CheckPermissions from "controllers/permissions/services";

class CrearReporteFalla extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      empleados: [],
      conceptos: [],
      calculo: [{}],
      ultimoSelect: null,
      elementos: [],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.permissionService = new CheckPermissions();
  }

  handleSubmit(event) {
    event.preventDefault();
    if(this.state.archivo)
    {
      this.servicio = new Service();
      let that = this;
      const data = new FormData();
      data.append("archivo", this.state.archivo);
      this.servicio.cargarArchivo(data).then((res) => {
        if (res && res == "OK") {
          that.growl.show({
            severity: "success",
            summary: "Operación completada",
            detail: "Información guardada correctamente",
          });
          setTimeout(function () {
            that.props.history.push("/app/nomina/desglose");
          }, 500);
        } else if (res && res == "error") {
          that.growl.show({
            severity: "error",
            summary: "Error no se pudo completar la carga",
          });
        }
      });
    }
    else
    {
      this.growl.show({
        severity: "error",
        summary: "Por favor seleccione el archivo. Los tipos de archivos válidos son xlsx, csv, xls.",
      });
    }
  }
  getImageInput(title,key)
  {
    return (
      <div className="image-group">
        <label className="title">{title}</label>
        <br />
        <div className="form-group">
          {!this.state[key]?.name && 
            <div className="custom-file-upload">
              <label htmlFor={`file-upload-${key}`} className="p-button-warning p-button m-0 w-100">
                <i className="pi pi-cloud-upload"></i> Seleccionar archivo
              </label>
              <input
                id={`file-upload-${key}`}
                type="file"
                accept=".xlsx,.ods,.csv,.xls"
                style={{ display: "none" }}
                onChange={(e) =>
                  {
                    let arr = e.target.files[0].name.split("."),
                    ext = "";
                    ext = arr[arr.length - 1];
                    if (
                      ext === "xlsx" ||
                      ext === "ods" ||
                      ext === "csv" ||
                      ext === "xls"
                    ) {
                      this.setState({ [key]: e.target.files[0] })
                    } else {
                      this.setState({ [key]: null })
                    }
                  }
                }
                chooseLabel="Seleccionar Archivo"
              />
            </div>
          }
          {this.state[key]?.name && 
            <div className="file-info d-flex justify-content-between">
              <div className="label-info">
                {this.state[key].name}
              </div>
              <div className="action-info">
                <i
                  onClick={() => {
                    this.setState({ [key]: null });
                  }}
                  className="pi pi-trash"
                  style={{ fontSize: "1.5em", cursor: "pointer" }}
                ></i>
                </div>                            
            </div>
          }
        </div>
      </div>
    )
  }
  render() {
    const content = (
      <div className="d-flex align-items-center justify-content-between w-100">
          <div className="ml-2 mr-2">Descargue el archivo de nómina de muestra aquí</div>
          <div className="">
            <a
              href={urlPdf + "IMPORTACION_CONCEPTOS_NOMINA/"}
              target="_blank"
              >
              <Button className="p-button-warning" type="button">Descarga plantilla</Button>
              </a>
          </div>
      </div>
    );
    return (
      <div className="container">
        <Panel header="Carga De Nómina">
          <Toast ref={(el) => (this.growl = el)} />
          <form>
            <Row className="d-flex align-items-center">
              <Col xs="12" sm="6">
                {this.permissionService.checkPermission('nomina_carga_descargar_plantilla') && <Message
                    style={{
                        border: 'solid #bc000e',
                        borderWidth: '0 0 0 6px',
                        color: '#bc000e',
                        background: 'rgb(254 219 243 / 70%)'
                    }}
                    className=" w-full justify-content-start"
                    severity="info"
                    content={content}
                />}
              </Col>
              <Col xs="12" sm="6">
                {this.permissionService.checkPermission('nomina_carga_subir_nomina') && this.getImageInput("Seleccionar archivo","archivo")}
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="text-center">
                <Button className="p-button-warning" onClick={this.handleSubmit}>
                  Cargar
                </Button>
              </Col>
            </Row>
          </form>
        </Panel>
      </div>
      
    );
  }
}

export default CrearReporteFalla;
