import Fulllayout from '../layouts/fulllayout.js';



var indexRoutes = [
    { path: '/', name: 'Dashboard', component: Fulllayout },

];

export default indexRoutes;
