import React from "react";
import {
	Card,
	CardBody,Container, Row,Col,CardTitle

} from 'reactstrap';

import { Toast } from 'primereact/toast';
import moment from 'moment';
import Select from 'react-select';
import RutaService  from 'controllers/ruta/rutaservice';
import ClienteService  from 'controllers/cliente/clienteservice';
import { Panel } from 'primereact/panel';
import PCalendar from '../../ui-components/calendar';
import {Button} from 'primereact/button';

const options = [];

class CrearID extends React.Component {
	constructor(props){
		super(props);
		this.state={
			last_update:""
		}
		this.servicioR = new RutaService();;
		this.servicio = new ClienteService();
		this.servicio.getClientes().then(data =>  {
			let arrClientes = [];
			data.forEach(item=>{
				arrClientes.push({value:item.id,label:item.razon_social,rfc:item.rfc,id:item.id});

			});
			this.setState({clientes: arrClientes});
		});

		this.handleSubmit = this.handleSubmit.bind(this);
		this.getCommonDataFromChilds = this.getCommonDataFromChilds.bind(this);
	}

	handleSubmit(event) {
				event.preventDefault();
				 console.log(this.state);
				let that = this;

				let datos ={};
				const data = new FormData();
				datos.broker = this.state.broker;
				datos.cliente_id = this.state.cliente_id;
				// datos.contacto1 = this.state.contacto1;
				// datos.contacto2 = this.state.contacto2;
				// datos.operativo = this.state.operativo;
				datos.id_ruta = this.state.id_ruta;
				datos.planta_filial = this.state.planta_filial;
				datos.rfc = this.state.rfc;
				datos.last_update = this.state.last_update

				data.append("ruta",JSON.stringify(datos));
				// if(this.state.contrato)
				// 	data.append("contrato",this.state.contrato);
				// if(this.state.asignacion)
				// 	data.append("asignacion",this.state.asignacion);

				let mensajes = [];

				if(!this.state.last_update){
					mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Seleccione un Fecha de última actualización'});
				}
				if(!this.state.cliente_id ){
					mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Seleccione un cliente'});
				}

				if(!this.state.rfc ){
					mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'El cliente no cuenta con RFC'});
				}

				if(!this.state.id_ruta ){
					mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Ingrese el ID de la ruta'});
				}

				if(mensajes.length){
					this.growl.show(mensajes);
				}else{
					this.servicioR.guardarRuta(data).then(res =>  {
							if(res && res.result == 'OK'){

								that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Información guardada correctamente'});
								setTimeout(function(){
										 	that.props.history.push('/app/clientes/rutas/consulta');
								 }, 500);

							}else if(res && res.result == 'EXISTE'){
								that.growl.show({severity: 'error', summary: 'El Id de ruta '+this.state.id_ruta+' ya se encuentra registrado', detail: 'Ingrese otro código o consulte su información para editarla.'});
							}
					});
				}
	}
	getCommonDataFromChilds(data)
	{
		switch (data.label) {
			case "last_update":
				this.setState({last_update: moment(data.value).format('YYYY-MM-DD').toString()});
				break;
		}
	}
	render() {
		return (
			<div className="container">
				<Panel header="Alte De Ruta">
					<Toast ref={(el) => this.growl = el} />
					<form >
					<Row>
						<Col xs="12" md="6">
								<div className="form-group">
									<label>Cliente</label><br/>
									<Select options={this.state.clientes}  onChange={e =>	this.setState({rfc: e.rfc,cliente_id:e.id}) }   placeholder="Elegir cliente"/>
								</div>

						</Col>
						<Col xs="12" md="6">
								<div className="form-group">
									<label>R.F.C</label>
									<input type="text" readOnly="true" value={this.state.rfc} className="form-control"/>
								</div>
						</Col>
					</Row>
					<Row>
						<Col xs="12" md="6">
								<div className="form-group">
									<label>Broker</label><br/>
									<input type="text" className="form-control" onChange={e => this.setState({broker:e.target.value})}/>
								</div>
						</Col>
						<Col xs="12" md="6">
								<div className="form-group">
									<label>ID de Ruta</label>
									<input type="text" className="form-control"  onChange={e => this.setState({id_ruta:e.target.value})}/>
								</div>
						</Col>
					</Row>
					<Row>
						<Col xs="12" md="6">
							<div className="form-group">
								<label>Planta filial</label><br/>
								<input type="text" className="form-control" onChange={e => this.setState({planta_filial:e.target.value})}/>
							</div>
						</Col>
						<Col xs="12" md="6">
							<div className="form-group">
								<label>Fecha de última actualización</label><br/>
								<PCalendar
									label={"last_update"}
									getCommonDataFromChilds={this.getCommonDataFromChilds}
									showIcon
									value={this.state.last_update}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col className="text-right" xs="12"> 
							<Button className="p-button-warning" onClick={this.handleSubmit} >Guardar</Button>
						</Col>
					</Row>
						</form>
				</Panel>
			</div>
			
		);
	}
}

export default CrearID;
