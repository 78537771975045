import React from "react";
import {
	Card,
	CardBody,
	CardTitle
} from 'reactstrap';
import VigilanciaListado from './../../vigilancia/lista-corta';

import { TabView,TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';


class ReportesVigilancia extends React.Component {
	constructor(){
		super();
		this.state = {
			activeIndex : 0
		}
		this.agregaReporte = this.agregaReporte.bind(this);
		this.headerTemplate = this.headerTemplate.bind(this);

	}
	agregaReporte(){
		this.props.history.push({
		 pathname:'/app/entrega-inspeccion/nuevo'
	 });

	}

	headerTemplate()
	{
		return <div className="p-panel-header flex-wrap  d-flex">
			<p className="m-0">Reportes de entrega inspección</p>
			<Button label='Nuevo reporte' icon="fa fa-plus" className="pull-right p-button p-button-warning" onClick={()=>{this.agregaReporte()}}/>
		</div>; 
	}

	render() {
		return (
			<div class="container-fluid">
				<Panel headerTemplate={this.headerTemplate}>
					<VigilanciaListado tipo="4"/>
				</Panel>
			</div>
		);
	}
}

export default ReportesVigilancia;
