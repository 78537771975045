import React from "react";
import {Button} from 'primereact/button';
import {Panel} from 'primereact/panel';
import EditarUnidad from './editar';
import UnidadService  from 'controllers/unidad/unidadService';

class EditarPagina extends React.Component {
	constructor(props){
		super(props);
		this.state={};
		this.servicio = new UnidadService();
		this.headerTemplate = this.headerTemplate.bind(this);

	}

	componentDidMount(){
		this.setState({
			item : this.props.location.state
		});
	}
	headerTemplate()
	{
		return (<div className="p-panel-header flex-wrap  d-flex">
			<div>
				Editar
			</div>
			<div>
			<Button style={{float:'right'}} className="p-button-warning" onClick={e=> this.props.history.goBack()}  label="Regresar"/>
			</div>
		</div>);
	}

	render() {

		return (
			<div className="container">
				<Panel headerTemplate={this.headerTemplate}>
					<EditarUnidad {...this.props} values={this.state.item} />
				</Panel>
			</div>
		);
	}
}

export default EditarPagina;
