import React from "react";
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import QRCode  from 'qrcode.react';
import jsPDF from 'jspdf';
import {Sidebar} from 'primereact/sidebar';


const downloadQR = () => {
  const canvas = document.getElementById("123456");
  const imgData = canvas
    .toDataURL("image/png")

		var pdf = new jsPDF();

 pdf.addImage(imgData, 'JPEG', 40, 50);
 pdf.save("qr.pdf");
};

const downloadQRImage = () => {
  const canvas = document.getElementById("123456");
  const pngUrl = canvas
    .toDataURL("image/png")
    .replace("image/png", "image/octet-stream");
  let downloadLink = document.createElement("a");
  downloadLink.href = pngUrl;
  downloadLink.download = "qr.png";
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export class QRElement extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      visible:false,
      visible2:false,
      qr: 5
    };

  }

  componentDidUpdate(prevProps){
		if(prevProps !== this.props){
			this.setState({
				visible:this.props.visible,
        visible2:this.props.directo?this.props.directo:false,
        qr:this.props.qr
			});
		}
	}

  cerrar_ventana(){
    this.setState({visible:false});
    let that = this;
    setTimeout(function(){
      if(this.props.onClose)this.props.onClose();
      }, 500);
  }



  render(){
		const footer = (
				<div>
						<Button label="Sí" className='p-button-warning mr-2' icon="pi pi-check" onClick={e=> this.setState({visible2:true})} />
						<Button label="No" className='p-button-warning' icon="pi pi-times" onClick={this.cerrar_ventana.bind(this)}/>
				</div>
		);
    return (<div>
      <Dialog header="Código QR"  footer={footer} visible={this.state.visible} style={{width: '50vw'}} modal={true} onHide={this.cerrar_ventana.bind(this)}>
        ¿Desea generar el código QR?
        </Dialog>
      <Sidebar visible={this.state.visible2} fullScreen={true} onHide={(e) => {this.setState({visible2:false}); if(this.props.onClose)this.props.onClose() }}>
          <center>
            <QRCode size='512' id='123456' value={this.state.qr} ref={el => (this.componentRef = el)}/>
            <br/><br/>
            <a className="btn btn-default" onClick={downloadQRImage}> Descargar QR como imagen </a>
            <a className="btn btn-default" onClick={downloadQR}> Descargar QR como PDF </a>
          </center>

      </Sidebar></div>
    );
  }
}
