	import axios from 'axios';
import {url} from '../../constants';

export default class Service {

			/**
			Servicios para  los sellos
			*/

			guardarSello(contenido){
				console.log(contenido)
					return axios.post(url + 'control-sello/guardar',{control:contenido },  {
						}).then(res => res.data);
			}

			listadoSello(){
				return axios.get(url + 'control-sello/listado',{})
								.then(res => res.data);
			}

			eliminarSello(id){
					return axios.post(url + 'control-sello/eliminar',{
						id:id

					}).then(res => res.data);
			}



			actualizarSello(objeto){

			  let objeto_nuevo =Object.assign({}, objeto);

			//	delete embarque.facturar_a;

					return axios.post(url + 'control-sello/actualizar',{
						contenido:objeto_nuevo

					}).then(res => res.data);
			}


			/**
			Servicios para  el cambio de los sellos
			*/

			guardarCambioSello(contenido){
				console.log(contenido)
					return axios.post(url + 'control-sello-cambio/guardar',{control:contenido },  {
						}).then(res => res.data);
			}

			listadoCambioSello(){
				return axios.get(url + 'control-sello-cambio/listado',{})
								.then(res => res.data);
			}

			eliminarCambioSello(id){
					return axios.post(url + 'control-sello-cambio/eliminar',{
						id:id

					}).then(res => res.data);
			}



			actualizarCambioSello(objeto){

				let objeto_nuevo =Object.assign({}, objeto);

			//	delete embarque.facturar_a;

					return axios.post(url + 'control-sello-cambio/actualizar',{
						contenido:objeto_nuevo

					}).then(res => res.data);
			}




}
