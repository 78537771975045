import React from 'react';
import ReactDOM from 'react-dom';
import {ThroughProvider} from 'react-through'
import './assets/scss/style.scss';
// setup fake backend
import { configureFakeBackend } from './jwt/_helpers';
configureFakeBackend();

const App = require('./app').default;
const theApp = (
    <ThroughProvider>
      <App />
    </ThroughProvider>
  )
ReactDOM.render( theApp , document.getElementById('root'));
