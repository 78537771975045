import React from "react";
import {
	Card,
	CardBody,CardTitle

} from 'reactstrap';
import OperadorListado from "./operadores-views";

class ResultadoOperadores extends React.Component {
	constructor(props){
		super(props);
	}
	render() {
		return (
			<Card>
				<CardTitle  className="bg-light border-bottom p-3 mb-0">CONSULTAR OPERADORES</CardTitle>
				<CardBody>
					<OperadorListado parametros={this.props.location.state} history={this.props.history}></OperadorListado>
				</CardBody>
			</Card>
		);
	}
}

export default ResultadoOperadores;
