import React from "react";
import {
	Card,
	CardBody,
	Col,
	Row,
	CardTitle
} from 'reactstrap';
import { Toast } from 'primereact/toast';
import DocumentsTableOperadores from './documents_table';
import DocumentSlider from './document_slider';
import {url,operadorFiles} from '../../../constants';

class OperadorDocuments extends React.Component {
	
	constructor(props){
		super(props);
		this.state={
			slideData:[]
		};
		this.editar = this.editar.bind(this);	
	}

	componentDidMount(){
		this.setState({
			item : this.props.location.state
		});
	}
	editar(){
		this.props.history.push({
		   pathname:'/app/operador/editar',
		   state: this.state.item
		});
	}
	getImageTitle(key)
	{
		const fileIndexForTitle = operadorFiles.findIndex((e)=>e.key===key)
		return operadorFiles[fileIndexForTitle].value;
	}
	prepareSlideData()
	{
		if(this.state.item)
		{
			const state_item = this.state.item;
			const files_from_state_item = Object.keys(state_item)
	
			//Filter Object with key contanis "file_"
			.filter((key) => key.includes("file_"))
			.reduce((obj, key) => {
			return Object.assign(obj, {
			  [key]: state_item[key]
			});
			}, {});
			const slideData = [];
			for (var key of Object.keys(files_from_state_item)) {
				if(files_from_state_item[key])
				{
					const file_type = files_from_state_item[key].split(".").pop();
					if(['png','jpg','jpeg'].includes(file_type))
					{
						slideData.push({
							itemImageSrc: url+'altadeoperador/'+files_from_state_item[key],
							thumbnailImageSrc: url+'altadeoperador/'+files_from_state_item[key],
							alt: files_from_state_item[key],
							title: this.getImageTitle(key),
							key:key
						})
					}
				}
			}
			this.state.slideData = slideData;
		}
	}
	render() {
		this.prepareSlideData();
		const childEvents = { onOpenSlider: () => {} };
		
		return (
			<Card>
				<CardTitle  className="d-flex align-items-baseline justify-content-between bg-light border-bottom p-3 mb-0">
					<div>Operadores Documentos</div>
					<div>
						<button className="mr-2 btn btn-info" onClick={()=> childEvents.onOpenSlider()}>Diapositivas</button>
						<button className="mr-2 btn btn-warning" onClick={e=> this.editar()}  label="Editar">Editar</button>
						<button className="btn btn-secondary" onClick={e=> this.props.history.goBack()}  label="Regresar">Regresar</button>
					</div>
				</CardTitle>
				<CardBody  >
				<Toast ref={(el) => this.growl = el} />
					<Row>
						<Col>
							<DocumentsTableOperadores  events={childEvents} slideData={this.state.slideData} values={this.state.item}  />
						</Col>
					</Row>
				</CardBody>
				<DocumentSlider events={childEvents} data={this.state.slideData} />
			</Card>
		);
	}
}

export default OperadorDocuments;
