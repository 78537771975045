import React, { Component } from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {MultiSelect} from 'primereact/multiselect';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import MantenimientoService from  'controllers/mantenimiento/mantenimiento-service';
import {url} from '../../../../constants';
import CheckPermissions from 'controllers/permissions/services';

export default class ListadoReporte extends Component {

    constructor() {
        super();
        this.state = {
            globalFilter: null,
            visible:false,
            visible2:false,
            selectedItem:[]
        };
        this.eliminar = this.eliminar.bind(this);
        this.editar = this.editar.bind(this);
        this.servicio = new MantenimientoService();
        this.permissionService = new CheckPermissions();
    }

    componentDidMount() {
        this.servicio.listadoReporte().then(data => this.setState({cars: data}));

    }

    eliminar(column, rowData){
      this.setState({visible2:true,selectedItemIndex:column.rowIndex,selectedItem:rowData});
    }

    editar(rowData){
      //this.setState({visible:true,item:column.rowData});
      this.props.history.push({
         pathname:'/app/mantenimiento/preventivo/fallas/editar',
         state: rowData
       });
    }



    confirmar_borrado(){
      this.setState({visible2:false});
      this.setState({
        cars: this.state.cars.filter((val,i) => i !== this.state.selectedItemIndex)
      });
      let that = this;
      this.servicio.eliminarReporte(this.state.selectedItem.id).then(res =>  {
          if(res && res.result == 'OK'){
            that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Registro borrado'});
          }
      });

    }


    actionTemplate(rowData, column) {
      return (<div>
        {this.permissionService.checkPermission('mantenimiento_preventivo_fallas_editar') && <Button
          type="button" 
          icon="pi pi-pencil" 
          onClick={e=> this.editar(rowData)}
          className="p-button-warning"
          tooltip="Editar"
          tooltipOptions={{ position: 'top' }}
          />}
        {this.permissionService.checkPermission('mantenimiento_preventivo_fallas_abrir_documento') && <Button 
          icon="pi pi-images" 
          onClick={e=> this.setState({visible:true,selectedItem:rowData})} 
          className="p-button-warning"
          tooltip="Abrir documento"
          tooltipOptions={{ position: 'top' }}
        />}
        {this.permissionService.checkPermission('mantenimiento_preventivo_fallas_borrar') && <Button
          type="button" 
          icon="pi pi-trash" 
          onClick={e=> this.eliminar(column, rowData)}
          className="p-button-warning"
          tooltip="Borrar"
          tooltipOptions={{ position: 'top' }}
        />}
        </div>);
    }

    actionTemplate2(rowData, column) {
      return (<div>
      <Button icon="pi pi-image" onClick={e=>  window.open(url+"descargarEvidencia?q="+rowData.path)} className="btn btn-default"/>

        </div>);
    }

    render() {
        const footer = (
          <div>
                <Button label="Sí" className='p-button-warning mr-2' icon="pi pi-check" onClick={this.confirmar_borrado.bind(this)} />
                <Button label="No" className='p-button-warning' icon="pi pi-times" onClick={e=> this.setState({visible2:false})}/>
            </div>

        );

        return (<div>
          <Toast ref={(el) => this.growl = el} />
          <Dialog header="Eliminar"  footer={footer} visible={this.state.visible2} style={{width: '50vw'}} modal={true} onHide={() => this.setState({visible: false,visible2:false	})}>
            ¿Confirma eliminar?
          </Dialog>

					<Dialog header="Evidencias"  visible={this.state.visible} style={{width: '50vw',height:'90vh',overflow:'scroll',backgroundColor:"#fff"}} modal={true} onHide={() => this.setState({visible: false	})}>
            <DataTable value={this.state.selectedItem.evidencias}  rows={10} paginator={true}>
            <Column field="nombre" header="Nombre" />
            <Column body={this.actionTemplate2.bind(this)} header="Opciones" style={{ textAlign: 'center'}} />
            </DataTable>
					</Dialog>
            <DataTable value={this.state.cars} paginator={true}
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10}>
            <Column field="unidad" header="Unidad" />
            <Column field="operador" header="Operador" />
            <Column field="descripcion" header="Descripción" />
            <Column body={this.actionTemplate.bind(this)} header="Opciones" style={{ textAlign: 'center'}} />
            </DataTable>
      </div>);
    }
}
