import React,{Component} from "react";
import {
	Card,
	CardBody,Container,Col,Row,CardTitle

} from 'reactstrap';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';

import CarService from 'controllers/demo/carservice';
import 'primereact/resources/themes/nova/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {Button} from 'primereact/button';
import ClienteService from "controllers/cliente/clienteservice";
import {TabView,TabPanel} from 'primereact/tabview';
import { Panel } from 'primereact/panel';
import { Accordion, AccordionTab } from 'primereact/accordion';
import ClienteListado from "./lista-corta";

class ConsultaClientes extends Component {
	constructor(props){
		super(props);
		this.state={
			nombre:"",
			rfc:"",
			numero:"",
			activeIndex:0,
			estadoStatus: 1,
		}
		this.servicioClientes = new ClienteService();
		this.updateCustomerData = this.updateCustomerData.bind(this);
	}

	updateCustomerData = (estatus) =>{
		const parametros = {
			nombre: this.state.nombre,
			rfc: this.state.rfc,
			numero: this.state.numero,
			estatus: estatus
		}

		this.servicioClientes
			.getFiltroClientes(parametros)
			.then((data) => this.setState({ cars: data, estadoStatus:estatus }));
	}

	verTodo = () =>{
        this.setState({
			nombre:"",
			rfc:"",
			numero:"",
		})

		const parametros = {
			estatus: this.state.estadoStatus
		}

		this.servicioClientes
		.getFiltroClientes(parametros)
			.then((data) => this.setState({ cars: data }));
	}

	render() {
		return (
			<div className="container-fluid">
				<Panel header="Consultar Cliente">
					<>
						<Accordion activeIndex={0} className="mb-3">
					    	<AccordionTab header="Filtrar">
								<Row >
									<Col xs="12" md="4">
										<div className="form-group">
											<label>Consultar por nombre</label>
											<input type="text" value={this.state.nombre} onChange={e => this.setState({nombre:e.target.value})} className="form-control"/>
										</div>
									</Col>
									<Col xs="12" md="4">
										<div className="form-group">
											<label>Consultar por R.F.C. ...</label>
											<input type="text" value={this.state.rfc} onChange={e=> this.setState({rfc:e.target.value})} className="form-control"/>
										</div>
									</Col>
									<Col xs="12" md="4">
										<div className="form-group">
											<label>Consultar por número de cliente ...</label>
											<input type="text" value={this.state.numero} onChange={e=>this.setState({numero:e.target.value})} className="form-control"/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col className="text-right">
										<Button label="Buscar" onClick={() => this.updateCustomerData(this.state.estadoStatus)} className="p-button-raised p-button-warning mr-3" />
										<Button label="Ver todo" onClick={this.verTodo} className="p-button-warning p-button-raised" />
									</Col>
								</Row>
							</AccordionTab>
						</Accordion>
						<div class="main_table">
						<Row className="col">
							<TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}>
								<TabPanel header="Activos">
									<ClienteListado estado="1" history={this.props.history} {...this.state} updateCustomerData={this.updateCustomerData}/>
								</TabPanel>
								<TabPanel header="Inactivos">
									<ClienteListado estado="0" history={this.props.history} {...this.state} updateCustomerData={this.updateCustomerData}/>
								</TabPanel>
							</TabView>
						</Row>
						</div>
					</>
				</Panel>
			</div>
		);
	}
}

export default ConsultaClientes;
