import React from "react";
import {
	Row, 
	Col
} from 'reactstrap';
import { Toast } from 'primereact/toast';
import moment from 'moment';
import {Button} from 'primereact/button';
import {url,operadorFiles} from '../../../constants';


class DocumentsTableOperadores extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fecha_actualizacion: moment(new Date()).format('YYYY-MM-DD').toString()
		}
		this.get_image = this.get_image.bind(this);
		this.get_actions = this.get_actions.bind(this);
		this.get_image_extention = this.get_image_extention.bind(this);
		this.open_image_slider = this.open_image_slider.bind(this);
		this.download_file = this.download_file.bind(this);
	}


	componentDidUpdate(prevProps) {
		if (prevProps.values !== this.props.values) {
			this.setState({...this.props.values});
		}
	}
	open_image_slider(key)
	{
		const fileIndexForTitle = this.props.slideData.findIndex((e)=>e.key===key)
		this.props.events.onOpenSlider(fileIndexForTitle)
	}
	download_file(table_field_value)
	{
		window.open(url+'altadeoperador/'+table_field_value,"width=200,height=100");
	}
	get_actions(table_field)
	{
		const table_field_value = this.state[table_field];
		if(table_field_value)
		{
			const file_type = table_field_value.split(".").pop();
			return (
				<div className="text-center">
					{file_type && ['png','jpg','jpeg'].includes(file_type) && <Button type="button" title='Editar' icon="pi pi-eye" onClick={e=> this.open_image_slider(table_field)} className="mr-2"/>}
					<Button type="button" title='Eliminar' icon="pi pi-download" onClick={e=> this.download_file(table_field_value)} className="p-button-warning"/>
				</div>
			)
		}
	}
	get_image(table_field){
		const table_field_value = this.state[table_field]
		if(table_field_value)
		{
			return table_field_value;
		}
		else
		{
			return 'Ningún archivo';
		}
	}
	get_image_extention(table_field){
		const table_field_value = this.state[table_field]
		if(table_field_value)
		{
			const file_type = table_field_value.split(".").pop();
			return file_type.toUpperCase();
		}
		else
		{
			return 'Ningún archivo';
		}
	}
	render() {
		return (<div>
			<Toast ref={(el) => this.growl = el} />
			<Row>
					<Col xs="6">
						<div className="form-group">
							<label className="font-bold">Número de empleado</label>
							<p>{this.state.numero_empleado}</p>
						</div>
					</Col>
					<Col xs="6">
						<div className="form-group">
							<label className="font-bold">Fecha ingreso</label>
							<p>{this.state.fecha_ingreso}</p>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs="6">
						<div className="form-group">
							<label className="font-bold">Nombre</label>
							<p>{this.state.nombre}</p>
						</div>
					</Col>
					<Col xs="6">
						<div className="form-group">
							<label className="font-bold">Apellido Materno</label>
							<p>{this.state.apellido_materno}</p>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs="6">
						<div className="form-group">
							<label className="font-bold">Apellido Paterno</label>
							<p>{this.state.apellido_paterno}</p>
						</div>
					</Col>
					<Col xs="6">
						<div className="form-group">
							<label className="font-bold">Fecha de nacimiento</label>
							<p>{this.state.fecha_nacimiento}</p>
						</div>
					</Col>
				</Row>
			<table border="1" cellPadding="5" width="100%">
				<thead>
					<tr>
						<th width="30%">Nombre del documento</th>
						<th>Nombre del archivo</th>
						<th width="10%">Tipo de archivo</th>
						<th width="10%">Opciones</th>
					</tr>
				</thead>
				<tbody>
					{
						operadorFiles && operadorFiles.map((item,j)=>
						<tr key={j}>
							<td>{item.value}</td>
							<td>{this.get_image(item.key)}</td>
							<td>{this.get_image_extention(item.key)}</td>
							<td>{this.get_actions(item.key)}</td>
						</tr>
						)
					}					
				</tbody>	
			</table>	
		</div>
		);
	}
}

export default DocumentsTableOperadores;
