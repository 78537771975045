
const municipio_data = [
    { value: "001", c_Estado: "AGU", label: "Aguascalientes" },
    { value: "001", c_Estado: "BCN", label: "Ensenada" },
    { value: "001", c_Estado: "BCS", label: "Comondú" },
    { value: "001", c_Estado: "CAM", label: "Calkiní" },
    { value: "001", c_Estado: "COA", label: "Abasolo" },
    { value: "001", c_Estado: "COL", label: "Armería" },
    { value: "001", c_Estado: "CHH", label: "Ahumada" },
    { value: "001", c_Estado: "CHP", label: "Acacoyagua" },
    { value: "001", c_Estado: "DUR", label: "Canatlán" },
    { value: "001", c_Estado: "GRO", label: "Acapulco de Juárez" },
    { value: "001", c_Estado: "GUA", label: "Abasolo" },
    { value: "001", c_Estado: "HID", label: "Acatlán" },
    { value: "001", c_Estado: "JAL", label: "Acatic" },
    { value: "001", c_Estado: "MEX", label: "Acambay de Ruíz Castañeda" },
    { value: "001", c_Estado: "MIC", label: "Acuitzio" },
    { value: "001", c_Estado: "MOR", label: "Amacuzac" },
    { value: "001", c_Estado: "NAY", label: "Acaponeta" },
    { value: "001", c_Estado: "NLE", label: "Abasolo" },
    { value: "001", c_Estado: "OAX", label: "Abejones" },
    { value: "001", c_Estado: "PUE", label: "Acajete" },
    { value: "001", c_Estado: "QUE", label: "Amealco de Bonfil" },
    { value: "001", c_Estado: "ROO", label: "Cozumel" },
    { value: "001", c_Estado: "SIN", label: "Ahome" },
    { value: "001", c_Estado: "SLP", label: "Ahualulco" },
    { value: "001", c_Estado: "SON", label: "Aconchi" },
    { value: "001", c_Estado: "TAB", label: "Balancán" },
    { value: "001", c_Estado: "TAM", label: "Abasolo" },
    { value: "001", c_Estado: "TLA", label: "Amaxac de Guerrero" },
    { value: "001", c_Estado: "VER", label: "Acajete" },
    { value: "001", c_Estado: "YUC", label: "Abalá" },
    { value: "001", c_Estado: "ZAC", label: "Apozol" },
    { value: "002", c_Estado: "AGU", label: "Asientos" },
    { value: "002", c_Estado: "BCN", label: "Mexicali" },
    { value: "002", c_Estado: "BCS", label: "Mulegé" },
    { value: "002", c_Estado: "CAM", label: "Campeche" },
    { value: "002", c_Estado: "COA", label: "Acuña" },
    { value: "002", c_Estado: "COL", label: "Colima" },
    { value: "002", c_Estado: "CHH", label: "Aldama" },
    { value: "002", c_Estado: "CHP", label: "Acala" },
    { value: "002", c_Estado: "DIF", label: "Azcapotzalco" },
    { value: "002", c_Estado: "DUR", label: "Canelas" },
    { value: "002", c_Estado: "GRO", label: "Ahuacuotzingo" },
    { value: "002", c_Estado: "GUA", label: "Acámbaro" },
    { value: "002", c_Estado: "HID", label: "Acaxochitlán" },
    { value: "002", c_Estado: "JAL", label: "Acatlán de Juárez" },
    { value: "002", c_Estado: "MEX", label: "Acolman" },
    { value: "002", c_Estado: "MIC", label: "Aguililla" },
    { value: "002", c_Estado: "MOR", label: "Atlatlahucan" },
    { value: "002", c_Estado: "NAY", label: "Ahuacatlán" },
    { value: "002", c_Estado: "NLE", label: "Agualeguas" },
    { value: "002", c_Estado: "OAX", label: "Acatlán de Pérez Figueroa" },
    { value: "002", c_Estado: "PUE", label: "Acateno" },
    { value: "002", c_Estado: "QUE", label: "Pinal de Amoles" },
    { value: "002", c_Estado: "ROO", label: "Felipe Carrillo Puerto" },
    { value: "002", c_Estado: "SIN", label: "Angostura" },
    { value: "002", c_Estado: "SLP", label: "Alaquines" },
    { value: "002", c_Estado: "SON", label: "Agua Prieta" },
    { value: "002", c_Estado: "TAB", label: "Cárdenas" },
    { value: "002", c_Estado: "TAM", label: "Aldama" },
    { value: "002", c_Estado: "TLA", label: "Apetatitlán de Antonio Carvajal" },
    { value: "002", c_Estado: "VER", label: "Acatlán" },
    { value: "002", c_Estado: "YUC", label: "Acanceh" },
    { value: "002", c_Estado: "ZAC", label: "Apulco" },
    { value: "003", c_Estado: "AGU", label: "Calvillo" },
    { value: "003", c_Estado: "BCN", label: "Tecate" },
    { value: "003", c_Estado: "BCS", label: "La Paz" },
    { value: "003", c_Estado: "CAM", label: "Carmen" },
    { value: "003", c_Estado: "COA", label: "Allende" },
    { value: "003", c_Estado: "COL", label: "Comala" },
    { value: "003", c_Estado: "CHH", label: "Allende" },
    { value: "003", c_Estado: "CHP", label: "Acapetahua" },
    { value: "003", c_Estado: "DIF", label: "Coyoacán" },
    { value: "003", c_Estado: "DUR", label: "Coneto de Comonfort" },
    { value: "003", c_Estado: "GRO", label: "Ajuchitlán del Progreso" },
    { value: "003", c_Estado: "GUA", label: "San Miguel de Allende" },
    { value: "003", c_Estado: "HID", label: "Actopan" },
    { value: "003", c_Estado: "JAL", label: "Ahualulco de Mercado" },
    { value: "003", c_Estado: "MEX", label: "Aculco" },
    { value: "003", c_Estado: "MIC", label: "Álvaro Obregón" },
    { value: "003", c_Estado: "MOR", label: "Axochiapan" },
    { value: "003", c_Estado: "NAY", label: "Amatlán de Cañas" },
    { value: "003", c_Estado: "NLE", label: "Los Aldamas" },
    { value: "003", c_Estado: "OAX", label: "Asunción Cacalotepec" },
    { value: "003", c_Estado: "PUE", label: "Acatlán" },
    { value: "003", c_Estado: "QUE", label: "Arroyo Seco" },
    { value: "003", c_Estado: "ROO", label: "Isla Mujeres" },
    { value: "003", c_Estado: "SIN", label: "Badiraguato" },
    { value: "003", c_Estado: "SLP", label: "Aquismón" },
    { value: "003", c_Estado: "SON", label: "Alamos" },
    { value: "003", c_Estado: "TAB", label: "Centla" },
    { value: "003", c_Estado: "TAM", label: "Altamira" },
    { value: "003", c_Estado: "TLA", label: "Atlangatepec" },
    { value: "003", c_Estado: "VER", label: "Acayucan" },
    { value: "003", c_Estado: "YUC", label: "Akil" },
    { value: "003", c_Estado: "ZAC", label: "Atolinga" },
    { value: "004", c_Estado: "AGU", label: "Cosío" },
    { value: "004", c_Estado: "BCN", label: "Tijuana" },
    { value: "004", c_Estado: "CAM", label: "Champotón" },
    { value: "004", c_Estado: "COA", label: "Arteaga" },
    { value: "004", c_Estado: "COL", label: "Coquimatlán" },
    { value: "004", c_Estado: "CHH", label: "Aquiles Serdán" },
    { value: "004", c_Estado: "CHP", label: "Altamirano" },
    { value: "004", c_Estado: "DIF", label: "Cuajimalpa de Morelos" },
    { value: "004", c_Estado: "DUR", label: "Cuencamé" },
    { value: "004", c_Estado: "GRO", label: "Alcozauca de Guerrero" },
    { value: "004", c_Estado: "GUA", label: "Apaseo el Alto" },
    { value: "004", c_Estado: "HID", label: "Agua Blanca de Iturbide" },
    { value: "004", c_Estado: "JAL", label: "Amacueca" },
    { value: "004", c_Estado: "MEX", label: "Almoloya de Alquisiras" },
    { value: "004", c_Estado: "MIC", label: "Angamacutiro" },
    { value: "004", c_Estado: "MOR", label: "Ayala" },
    { value: "004", c_Estado: "NAY", label: "Compostela" },
    { value: "004", c_Estado: "NLE", label: "Allende" },
    { value: "004", c_Estado: "OAX", label: "Asunción Cuyotepeji" },
    { value: "004", c_Estado: "PUE", label: "Acatzingo" },
    { value: "004", c_Estado: "QUE", label: "Cadereyta de Montes" },
    { value: "004", c_Estado: "ROO", label: "Othón P. Blanco" },
    { value: "004", c_Estado: "SIN", label: "Concordia" },
    { value: "004", c_Estado: "SLP", label: "Armadillo de los Infante" },
    { value: "004", c_Estado: "SON", label: "Altar" },
    { value: "004", c_Estado: "TAB", label: "Centro" },
    { value: "004", c_Estado: "TAM", label: "Antiguo Morelos" },
    { value: "004", c_Estado: "TLA", label: "Atltzayanca" },
    { value: "004", c_Estado: "VER", label: "Actopan" },
    { value: "004", c_Estado: "YUC", label: "Baca" },
    { value: "004", c_Estado: "ZAC", label: "Benito Juárez" },
    { value: "005", c_Estado: "AGU", label: "Jesús María" },
    { value: "005", c_Estado: "BCN", label: "Playas de Rosarito" },
    { value: "005", c_Estado: "CAM", label: "Hecelchakán" },
    { value: "005", c_Estado: "COA", label: "Candela" },
    { value: "005", c_Estado: "COL", label: "Cuauhtémoc" },
    { value: "005", c_Estado: "CHH", label: "Ascensión" },
    { value: "005", c_Estado: "CHP", label: "Amatán" },
    { value: "005", c_Estado: "DIF", label: "Gustavo A. Madero" },
    { value: "005", c_Estado: "DUR", label: "Durango" },
    { value: "005", c_Estado: "GRO", label: "Alpoyeca" },
    { value: "005", c_Estado: "GUA", label: "Apaseo el Grande" },
    { value: "005", c_Estado: "HID", label: "Ajacuba" },
    { value: "005", c_Estado: "JAL", label: "Amatitán" },
    { value: "005", c_Estado: "MEX", label: "Almoloya de Juárez" },
    { value: "005", c_Estado: "MIC", label: "Angangueo" },
    { value: "005", c_Estado: "MOR", label: "Coatlán del Río" },
    { value: "005", c_Estado: "NAY", label: "Huajicori" },
    { value: "005", c_Estado: "NLE", label: "Anáhuac" },
    { value: "005", c_Estado: "OAX", label: "Asunción Ixtaltepec" },
    { value: "005", c_Estado: "PUE", label: "Acteopan" },
    { value: "005", c_Estado: "QUE", label: "Colón" },
    { value: "005", c_Estado: "ROO", label: "Benito Juárez" },
    { value: "005", c_Estado: "SIN", label: "Cosalá" },
    { value: "005", c_Estado: "SLP", label: "Cárdenas" },
    { value: "005", c_Estado: "SON", label: "Arivechi" },
    { value: "005", c_Estado: "TAB", label: "Comalcalco" },
    { value: "005", c_Estado: "TAM", label: "Burgos" },
    { value: "005", c_Estado: "TLA", label: "Apizaco" },
    { value: "005", c_Estado: "VER", label: "Acula" },
    { value: "005", c_Estado: "YUC", label: "Bokobá" },
    { value: "005", c_Estado: "ZAC", label: "Calera" },
    { value: "006", c_Estado: "AGU", label: "Pabellón de Arteaga" },
    { value: "006", c_Estado: "CAM", label: "Hopelchén" },
    { value: "006", c_Estado: "COA", label: "Castaños" },
    { value: "006", c_Estado: "COL", label: "Ixtlahuacán" },
    { value: "006", c_Estado: "CHH", label: "Bachíniva" },
    { value: "006", c_Estado: "CHP", label: "Amatenango de la Frontera" },
    { value: "006", c_Estado: "DIF", label: "Iztacalco" },
    { value: "006", c_Estado: "DUR", label: "General Simón Bolívar" },
    { value: "006", c_Estado: "GRO", label: "Apaxtla" },
    { value: "006", c_Estado: "GUA", label: "Atarjea" },
    { value: "006", c_Estado: "HID", label: "Alfajayucan" },
    { value: "006", c_Estado: "JAL", label: "Ameca" },
    { value: "006", c_Estado: "MEX", label: "Almoloya del Río" },
    { value: "006", c_Estado: "MIC", label: "Apatzingán" },
    { value: "006", c_Estado: "MOR", label: "Cuautla" },
    { value: "006", c_Estado: "NAY", label: "Ixtlán del Río" },
    { value: "006", c_Estado: "NLE", label: "Apodaca" },
    { value: "006", c_Estado: "OAX", label: "Asunción Nochixtlán" },
    { value: "006", c_Estado: "PUE", label: "Ahuacatlán" },
    { value: "006", c_Estado: "QUE", label: "Corregidora" },
    { value: "006", c_Estado: "ROO", label: "José María Morelos" },
    { value: "006", c_Estado: "SIN", label: "Culiacán" },
    { value: "006", c_Estado: "SLP", label: "Catorce" },
    { value: "006", c_Estado: "SON", label: "Arizpe" },
    { value: "006", c_Estado: "TAB", label: "Cunduacán" },
    { value: "006", c_Estado: "TAM", label: "Bustamante" },
    { value: "006", c_Estado: "TLA", label: "Calpulalpan" },
    { value: "006", c_Estado: "VER", label: "Acultzingo" },
    { value: "006", c_Estado: "YUC", label: "Buctzotz" },
    { value: "006", c_Estado: "ZAC", label: "Cañitas de Felipe Pescador" },
    { value: "007", c_Estado: "AGU", label: "Rincón de Romos" },
    { value: "007", c_Estado: "CAM", label: "Palizada" },
    { value: "007", c_Estado: "COA", label: "Cuatro Ciénegas" },
    { value: "007", c_Estado: "COL", label: "Manzanillo" },
    { value: "007", c_Estado: "CHH", label: "Balleza" },
    { value: "007", c_Estado: "CHP", label: "Amatenango del Valle" },
    { value: "007", c_Estado: "DIF", label: "Iztapalapa" },
    { value: "007", c_Estado: "DUR", label: "Gómez Palacio" },
    { value: "007", c_Estado: "GRO", label: "Arcelia" },
    { value: "007", c_Estado: "GUA", label: "Celaya" },
    { value: "007", c_Estado: "HID", label: "Almoloya" },
    { value: "007", c_Estado: "JAL", label: "San Juanito de Escobedo" },
    { value: "007", c_Estado: "MEX", label: "Amanalco" },
    { value: "007", c_Estado: "MIC", label: "Aporo" },
    { value: "007", c_Estado: "MOR", label: "Cuernavaca" },
    { value: "007", c_Estado: "NAY", label: "Jala" },
    { value: "007", c_Estado: "NLE", label: "Aramberri" },
    { value: "007", c_Estado: "OAX", label: "Asunción Ocotlán" },
    { value: "007", c_Estado: "PUE", label: "Ahuatlán" },
    { value: "007", c_Estado: "QUE", label: "Ezequiel Montes" },
    { value: "007", c_Estado: "ROO", label: "Lázaro Cárdenas" },
    { value: "007", c_Estado: "SIN", label: "Choix" },
    { value: "007", c_Estado: "SLP", label: "Cedral" },
    { value: "007", c_Estado: "SON", label: "Atil" },
    { value: "007", c_Estado: "TAB", label: "Emiliano Zapata" },
    { value: "007", c_Estado: "TAM", label: "Camargo" },
    { value: "007", c_Estado: "TLA", label: "El Carmen Tequexquitla" },
    { value: "007", c_Estado: "VER", label: "Camarón de Tejeda" },
    { value: "007", c_Estado: "YUC", label: "Cacalchén" },
    { value: "007", c_Estado: "ZAC", label: "Concepción del Oro" },
    { value: "008", c_Estado: "AGU", label: "San José de Gracia" },
    { value: "008", c_Estado: "BCS", label: "Los Cabos" },
    { value: "008", c_Estado: "CAM", label: "Tenabo" },
    { value: "008", c_Estado: "COA", label: "Escobedo" },
    { value: "008", c_Estado: "COL", label: "Minatitlán" },
    { value: "008", c_Estado: "CHH", label: "Batopilas" },
    { value: "008", c_Estado: "CHP", label: "Angel Albino Corzo" },
    { value: "008", c_Estado: "DIF", label: "La Magdalena Contreras" },
    { value: "008", c_Estado: "DUR", label: "Guadalupe Victoria" },
    { value: "008", c_Estado: "GRO", label: "Atenango del Río" },
    { value: "008", c_Estado: "GUA", label: "Manuel Doblado" },
    { value: "008", c_Estado: "HID", label: "Apan" },
    { value: "008", c_Estado: "JAL", label: "Arandas" },
    { value: "008", c_Estado: "MEX", label: "Amatepec" },
    { value: "008", c_Estado: "MIC", label: "Aquila" },
    { value: "008", c_Estado: "MOR", label: "Emiliano Zapata" },
    { value: "008", c_Estado: "NAY", label: "Xalisco" },
    { value: "008", c_Estado: "NLE", label: "Bustamante" },
    { value: "008", c_Estado: "OAX", label: "Asunción Tlacolulita" },
    { value: "008", c_Estado: "PUE", label: "Ahuazotepec" },
    { value: "008", c_Estado: "QUE", label: "Huimilpan" },
    { value: "008", c_Estado: "ROO", label: "Solidaridad" },
    { value: "008", c_Estado: "SIN", label: "Elota" },
    { value: "008", c_Estado: "SLP", label: "Cerritos" },
    { value: "008", c_Estado: "SON", label: "Bacadéhuachi" },
    { value: "008", c_Estado: "TAB", label: "Huimanguillo" },
    { value: "008", c_Estado: "TAM", label: "Casas" },
    { value: "008", c_Estado: "TLA", label: "Cuapiaxtla" },
    { value: "008", c_Estado: "VER", label: "Alpatláhuac" },
    { value: "008", c_Estado: "YUC", label: "Calotmul" },
    { value: "008", c_Estado: "ZAC", label: "Cuauhtémoc" },
    { value: "009", c_Estado: "AGU", label: "Tepezalá" },
    { value: "009", c_Estado: "BCS", label: "Loreto" },
    { value: "009", c_Estado: "CAM", label: "Escárcega" },
    { value: "009", c_Estado: "COA", label: "Francisco I. Madero" },
    { value: "009", c_Estado: "COL", label: "Tecomán" },
    { value: "009", c_Estado: "CHH", label: "Bocoyna" },
    { value: "009", c_Estado: "CHP", label: "Arriaga" },
    { value: "009", c_Estado: "DIF", label: "Milpa Alta" },
    { value: "009", c_Estado: "DUR", label: "Guanaceví" },
    { value: "009", c_Estado: "GRO", label: "Atlamajalcingo del Monte" },
    { value: "009", c_Estado: "GUA", label: "Comonfort" },
    { value: "009", c_Estado: "HID", label: "El Arenal" },
    { value: "009", c_Estado: "JAL", label: "El Arenal" },
    { value: "009", c_Estado: "MEX", label: "Amecameca" },
    { value: "009", c_Estado: "MIC", label: "Ario" },
    { value: "009", c_Estado: "MOR", label: "Huitzilac" },
    { value: "009", c_Estado: "NAY", label: "Del Nayar" },
    { value: "009", c_Estado: "NLE", label: "Cadereyta Jiménez" },
    { value: "009", c_Estado: "OAX", label: "Ayotzintepec" },
    { value: "009", c_Estado: "PUE", label: "Ahuehuetitla" },
    { value: "009", c_Estado: "QUE", label: "Jalpan de Serra" },
    { value: "009", c_Estado: "ROO", label: "Tulum" },
    { value: "009", c_Estado: "SIN", label: "Escuinapa" },
    { value: "009", c_Estado: "SLP", label: "Cerro de San Pedro" },
    { value: "009", c_Estado: "SON", label: "Bacanora" },
    { value: "009", c_Estado: "TAB", label: "Jalapa" },
    { value: "009", c_Estado: "TAM", label: "Ciudad Madero" },
    { value: "009", c_Estado: "TLA", label: "Cuaxomulco" },
    { value: "009", c_Estado: "VER", label: "Alto Lucero de Gutiérrez Barrios" },
    { value: "009", c_Estado: "YUC", label: "Cansahcab" },
    { value: "009", c_Estado: "ZAC", label: "Chalchihuites" },
    { value: "010", c_Estado: "AGU", label: "El Llano" },
    { value: "010", c_Estado: "CAM", label: "Calakmul" },
    { value: "010", c_Estado: "COA", label: "Frontera" },
    { value: "010", c_Estado: "COL", label: "Villa de Álvarez" },
    { value: "010", c_Estado: "CHH", label: "Buenaventura" },
    { value: "010", c_Estado: "CHP", label: "Bejucal de Ocampo" },
    { value: "010", c_Estado: "DIF", label: "Álvaro Obregón" },
    { value: "010", c_Estado: "DUR", label: "Hidalgo" },
    { value: "010", c_Estado: "GRO", label: "Atlixtac" },
    { value: "010", c_Estado: "GUA", label: "Coroneo" },
    { value: "010", c_Estado: "HID", label: "Atitalaquia" },
    { value: "010", c_Estado: "JAL", label: "Atemajac de Brizuela" },
    { value: "010", c_Estado: "MEX", label: "Apaxco" },
    { value: "010", c_Estado: "MIC", label: "Arteaga" },
    { value: "010", c_Estado: "MOR", label: "Jantetelco" },
    { value: "010", c_Estado: "NAY", label: "Rosamorada" },
    { value: "010", c_Estado: "NLE", label: "El Carmen" },
    { value: "010", c_Estado: "OAX", label: "El Barrio de la Soledad" },
    { value: "010", c_Estado: "PUE", label: "Ajalpan" },
    { value: "010", c_Estado: "QUE", label: "Landa de Matamoros" },
    { value: "010", c_Estado: "ROO", label: "Bacalar" },
    { value: "010", c_Estado: "SIN", label: "El Fuerte" },
    { value: "010", c_Estado: "SLP", label: "Ciudad del Maíz" },
    { value: "010", c_Estado: "SON", label: "Bacerac" },
    { value: "010", c_Estado: "TAB", label: "Jalpa de Méndez" },
    { value: "010", c_Estado: "TAM", label: "Cruillas" },
    { value: "010", c_Estado: "TLA", label: "Chiautempan" },
    { value: "010", c_Estado: "VER", label: "Altotonga" },
    { value: "010", c_Estado: "YUC", label: "Cantamayec" },
    { value: "010", c_Estado: "ZAC", label: "Fresnillo" },
    { value: "011", c_Estado: "AGU", label: "San Francisco de los Romo" },
    { value: "011", c_Estado: "CAM", label: "Candelaria" },
    { value: "011", c_Estado: "COA", label: "General Cepeda" },
    { value: "011", c_Estado: "CHH", label: "Camargo" },
    { value: "011", c_Estado: "CHP", label: "Bella Vista" },
    { value: "011", c_Estado: "DIF", label: "Tláhuac" },
    { value: "011", c_Estado: "DUR", label: "Indé" },
    { value: "011", c_Estado: "GRO", label: "Atoyac de Álvarez" },
    { value: "011", c_Estado: "GUA", label: "Cortazar" },
    { value: "011", c_Estado: "HID", label: "Atlapexco" },
    { value: "011", c_Estado: "JAL", label: "Atengo" },
    { value: "011", c_Estado: "MEX", label: "Atenco" },
    { value: "011", c_Estado: "MIC", label: "Briseñas" },
    { value: "011", c_Estado: "MOR", label: "Jiutepec" },
    { value: "011", c_Estado: "NAY", label: "Ruíz" },
    { value: "011", c_Estado: "NLE", label: "Cerralvo" },
    { value: "011", c_Estado: "OAX", label: "Calihualá" },
    { value: "011", c_Estado: "PUE", label: "Albino Zertuche" },
    { value: "011", c_Estado: "QUE", label: "El Marqués" },
    { value: "011", c_Estado: "ROO", label: "Puerto Morelos" },
    { value: "011", c_Estado: "SIN", label: "Guasave" },
    { value: "011", c_Estado: "SLP", label: "Ciudad Fernández" },
    { value: "011", c_Estado: "SON", label: "Bacoachi" },
    { value: "011", c_Estado: "TAB", label: "Jonuta" },
    { value: "011", c_Estado: "TAM", label: "Gómez Farías" },
    { value: "011", c_Estado: "TLA", label: "Muñoz de Domingo Arenas" },
    { value: "011", c_Estado: "VER", label: "Alvarado" },
    { value: "011", c_Estado: "YUC", label: "Celestún" },
    { value: "011", c_Estado: "ZAC", label: "Trinidad García de la Cadena" },
    { value: "012", c_Estado: "COA", label: "Guerrero" },
    { value: "012", c_Estado: "CHH", label: "Carichí" },
    { value: "012", c_Estado: "CHP", label: "Berriozábal" },
    { value: "012", c_Estado: "DIF", label: "Tlalpan" },
    { value: "012", c_Estado: "DUR", label: "Lerdo" },
    { value: "012", c_Estado: "GRO", label: "Ayutla de los Libres" },
    { value: "012", c_Estado: "GUA", label: "Cuerámaro" },
    { value: "012", c_Estado: "HID", label: "Atotonilco el Grande" },
    { value: "012", c_Estado: "JAL", label: "Atenguillo" },
    { value: "012", c_Estado: "MEX", label: "Atizapán" },
    { value: "012", c_Estado: "MIC", label: "Buenavista" },
    { value: "012", c_Estado: "MOR", label: "Jojutla" },
    { value: "012", c_Estado: "NAY", label: "San Blas" },
    { value: "012", c_Estado: "NLE", label: "Ciénega de Flores" },
    { value: "012", c_Estado: "OAX", label: "Candelaria Loxicha" },
    { value: "012", c_Estado: "PUE", label: "Aljojuca" },
    { value: "012", c_Estado: "QUE", label: "Pedro Escobedo" },
    { value: "012", c_Estado: "SIN", label: "Mazatlán" },
    { value: "012", c_Estado: "SLP", label: "Tancanhuitz" },
    { value: "012", c_Estado: "SON", label: "Bácum" },
    { value: "012", c_Estado: "TAB", label: "Macuspana" },
    { value: "012", c_Estado: "TAM", label: "González" },
    { value: "012", c_Estado: "TLA", label: "Españita" },
    { value: "012", c_Estado: "VER", label: "Amatitlán" },
    { value: "012", c_Estado: "YUC", label: "Cenotillo" },
    { value: "012", c_Estado: "ZAC", label: "Genaro Codina" },
    { value: "013", c_Estado: "COA", label: "Hidalgo" },
    { value: "013", c_Estado: "CHH", label: "Casas Grandes" },
    { value: "013", c_Estado: "CHP", label: "Bochil" },
    { value: "013", c_Estado: "DIF", label: "Xochimilco" },
    { value: "013", c_Estado: "DUR", label: "Mapimí" },
    { value: "013", c_Estado: "GRO", label: "Azoyú" },
    { value: "013", c_Estado: "GUA", label: "Doctor Mora" },
    { value: "013", c_Estado: "HID", label: "Atotonilco de Tula" },
    { value: "013", c_Estado: "JAL", label: "Atotonilco el Alto" },
    { value: "013", c_Estado: "MEX", label: "Atizapán de Zaragoza" },
    { value: "013", c_Estado: "MIC", label: "Carácuaro" },
    { value: "013", c_Estado: "MOR", label: "Jonacatepec" },
    { value: "013", c_Estado: "NAY", label: "San Pedro Lagunillas" },
    { value: "013", c_Estado: "NLE", label: "China" },
    { value: "013", c_Estado: "OAX", label: "Ciénega de Zimatlán" },
    { value: "013", c_Estado: "PUE", label: "Altepexi" },
    { value: "013", c_Estado: "QUE", label: "Peñamiller" },
    { value: "013", c_Estado: "SIN", label: "Mocorito" },
    { value: "013", c_Estado: "SLP", label: "Ciudad Valles" },
    { value: "013", c_Estado: "SON", label: "Banámichi" },
    { value: "013", c_Estado: "TAB", label: "Nacajuca" },
    { value: "013", c_Estado: "TAM", label: "Güémez" },
    { value: "013", c_Estado: "TLA", label: "Huamantla" },
    { value: "013", c_Estado: "VER", label: "Naranjos Amatlán" },
    { value: "013", c_Estado: "YUC", label: "Conkal" },
    { value: "013", c_Estado: "ZAC", label: "General Enrique Estrada" },
    { value: "014", c_Estado: "COA", label: "Jiménez" },
    { value: "014", c_Estado: "CHH", label: "Coronado" },
    { value: "014", c_Estado: "CHP", label: "El Bosque" },
    { value: "014", c_Estado: "DIF", label: "Benito Juárez" },
    { value: "014", c_Estado: "DUR", label: "Mezquital" },
    { value: "014", c_Estado: "GRO", label: "Benito Juárez" },
    { value: "014", c_Estado: "GUA", label: "Dolores Hidalgo Cuna de la Independencia Nacional" },
    { value: "014", c_Estado: "HID", label: "Calnali" },
    { value: "014", c_Estado: "JAL", label: "Atoyac" },
    { value: "014", c_Estado: "MEX", label: "Atlacomulco" },
    { value: "014", c_Estado: "MIC", label: "Coahuayana" },
    { value: "014", c_Estado: "MOR", label: "Mazatepec" },
    { value: "014", c_Estado: "NAY", label: "Santa María del Oro" },
    { value: "014", c_Estado: "NLE", label: "Doctor Arroyo" },
    { value: "014", c_Estado: "OAX", label: "Ciudad Ixtepec" },
    { value: "014", c_Estado: "PUE", label: "Amixtlán" },
    { value: "014", c_Estado: "QUE", label: "Querétaro" },
    { value: "014", c_Estado: "SIN", label: "Rosario" },
    { value: "014", c_Estado: "SLP", label: "Coxcatlán" },
    { value: "014", c_Estado: "SON", label: "Baviácora" },
    { value: "014", c_Estado: "TAB", label: "Paraíso" },
    { value: "014", c_Estado: "TAM", label: "Guerrero" },
    { value: "014", c_Estado: "TLA", label: "Hueyotlipan" },
    { value: "014", c_Estado: "VER", label: "Amatlán de los Reyes" },
    { value: "014", c_Estado: "YUC", label: "Cuncunul" },
    { value: "014", c_Estado: "ZAC", label: "General Francisco R. Murguía" },
    { value: "015", c_Estado: "COA", label: "Juárez" },
    { value: "015", c_Estado: "CHH", label: "Coyame del Sotol" },
    { value: "015", c_Estado: "CHP", label: "Cacahoatán" },
    { value: "015", c_Estado: "DIF", label: "Cuauhtémoc" },
    { value: "015", c_Estado: "DUR", label: "Nazas" },
    { value: "015", c_Estado: "GRO", label: "Buenavista de Cuéllar" },
    { value: "015", c_Estado: "GUA", label: "Guanajuato" },
    { value: "015", c_Estado: "HID", label: "Cardonal" },
    { value: "015", c_Estado: "JAL", label: "Autlán de Navarro" },
    { value: "015", c_Estado: "MEX", label: "Atlautla" },
    { value: "015", c_Estado: "MIC", label: "Coalcomán de Vázquez Pallares" },
    { value: "015", c_Estado: "MOR", label: "Miacatlán" },
    { value: "015", c_Estado: "NAY", label: "Santiago Ixcuintla" },
    { value: "015", c_Estado: "NLE", label: "Doctor Coss" },
    { value: "015", c_Estado: "OAX", label: "Coatecas Altas" },
    { value: "015", c_Estado: "PUE", label: "Amozoc" },
    { value: "015", c_Estado: "QUE", label: "San Joaquín" },
    { value: "015", c_Estado: "SIN", label: "Salvador Alvarado" },
    { value: "015", c_Estado: "SLP", label: "Charcas" },
    { value: "015", c_Estado: "SON", label: "Bavispe" },
    { value: "015", c_Estado: "TAB", label: "Tacotalpa" },
    { value: "015", c_Estado: "TAM", label: "Gustavo Díaz Ordaz" },
    { value: "015", c_Estado: "TLA", label: "Ixtacuixtla de Mariano Matamoros" },
    { value: "015", c_Estado: "VER", label: "Angel R. Cabada" },
    { value: "015", c_Estado: "YUC", label: "Cuzamá" },
    { value: "015", c_Estado: "ZAC", label: "El Plateado de Joaquín Amaro" },
    { value: "016", c_Estado: "COA", label: "Lamadrid" },
    { value: "016", c_Estado: "CHH", label: "La Cruz" },
    { value: "016", c_Estado: "CHP", label: "Catazajá" },
    { value: "016", c_Estado: "DIF", label: "Miguel Hidalgo" },
    { value: "016", c_Estado: "DUR", label: "Nombre de Dios" },
    { value: "016", c_Estado: "GRO", label: "Coahuayutla de José María Izazaga" },
    { value: "016", c_Estado: "GUA", label: "Huanímaro" },
    { value: "016", c_Estado: "HID", label: "Cuautepec de Hinojosa" },
    { value: "016", c_Estado: "JAL", label: "Ayotlán" },
    { value: "016", c_Estado: "MEX", label: "Axapusco" },
    { value: "016", c_Estado: "MIC", label: "Coeneo" },
    { value: "016", c_Estado: "MOR", label: "Ocuituco" },
    { value: "016", c_Estado: "NAY", label: "Tecuala" },
    { value: "016", c_Estado: "NLE", label: "Doctor González" },
    { value: "016", c_Estado: "OAX", label: "Coicoyán de las Flores" },
    { value: "016", c_Estado: "PUE", label: "Aquixtla" },
    { value: "016", c_Estado: "QUE", label: "San Juan del Río" },
    { value: "016", c_Estado: "SIN", label: "San Ignacio" },
    { value: "016", c_Estado: "SLP", label: "Ebano" },
    { value: "016", c_Estado: "SON", label: "Benjamín Hill" },
    { value: "016", c_Estado: "TAB", label: "Teapa" },
    { value: "016", c_Estado: "TAM", label: "Hidalgo" },
    { value: "016", c_Estado: "TLA", label: "Ixtenco" },
    { value: "016", c_Estado: "VER", label: "La Antigua" },
    { value: "016", c_Estado: "YUC", label: "Chacsinkín" },
    { value: "016", c_Estado: "ZAC", label: "General Pánfilo Natera" },
    { value: "017", c_Estado: "COA", label: "Matamoros" },
    { value: "017", c_Estado: "CHH", label: "Cuauhtémoc" },
    { value: "017", c_Estado: "CHP", label: "Cintalapa" },
    { value: "017", c_Estado: "DIF", label: "Venustiano Carranza" },
    { value: "017", c_Estado: "DUR", label: "Ocampo" },
    { value: "017", c_Estado: "GRO", label: "Cocula" },
    { value: "017", c_Estado: "GUA", label: "Irapuato" },
    { value: "017", c_Estado: "HID", label: "Chapantongo" },
    { value: "017", c_Estado: "JAL", label: "Ayutla" },
    { value: "017", c_Estado: "MEX", label: "Ayapango" },
    { value: "017", c_Estado: "MIC", label: "Contepec" },
    { value: "017", c_Estado: "MOR", label: "Puente de Ixtla" },
    { value: "017", c_Estado: "NAY", label: "Tepic" },
    { value: "017", c_Estado: "NLE", label: "Galeana" },
    { value: "017", c_Estado: "OAX", label: "La Compañía" },
    { value: "017", c_Estado: "PUE", label: "Atempan" },
    { value: "017", c_Estado: "QUE", label: "Tequisquiapan" },
    { value: "017", c_Estado: "SIN", label: "Sinaloa" },
    { value: "017", c_Estado: "SLP", label: "Guadalcázar" },
    { value: "017", c_Estado: "SON", label: "Caborca" },
    { value: "017", c_Estado: "TAB", label: "Tenosique" },
    { value: "017", c_Estado: "TAM", label: "Jaumave" },
    { value: "017", c_Estado: "TLA", label: "Mazatecochco de José María Morelos" },
    { value: "017", c_Estado: "VER", label: "Apazapan" },
    { value: "017", c_Estado: "YUC", label: "Chankom" },
    { value: "017", c_Estado: "ZAC", label: "Guadalupe" },
    { value: "018", c_Estado: "COA", label: "Monclova" },
    { value: "018", c_Estado: "CHH", label: "Cusihuiriachi" },
    { value: "018", c_Estado: "CHP", label: "Coapilla" },
    { value: "018", c_Estado: "DUR", label: "El Oro" },
    { value: "018", c_Estado: "GRO", label: "Copala" },
    { value: "018", c_Estado: "GUA", label: "Jaral del Progreso" },
    { value: "018", c_Estado: "HID", label: "Chapulhuacán" },
    { value: "018", c_Estado: "JAL", label: "La Barca" },
    { value: "018", c_Estado: "MEX", label: "Calimaya" },
    { value: "018", c_Estado: "MIC", label: "Copándaro" },
    { value: "018", c_Estado: "MOR", label: "Temixco" },
    { value: "018", c_Estado: "NAY", label: "Tuxpan" },
    { value: "018", c_Estado: "NLE", label: "García" },
    { value: "018", c_Estado: "OAX", label: "Concepción Buenavista" },
    { value: "018", c_Estado: "PUE", label: "Atexcal" },
    { value: "018", c_Estado: "QUE", label: "Tolimán" },
    { value: "018", c_Estado: "SIN", label: "Navolato" },
    { value: "018", c_Estado: "SLP", label: "Huehuetlán" },
    { value: "018", c_Estado: "SON", label: "Cajeme" },
    { value: "018", c_Estado: "TAM", label: "Jiménez" },
    { value: "018", c_Estado: "TLA", label: "Contla de Juan Cuamatzi" },
    { value: "018", c_Estado: "VER", label: "Aquila" },
    { value: "018", c_Estado: "YUC", label: "Chapab" },
    { value: "018", c_Estado: "ZAC", label: "Huanusco" },
    { value: "019", c_Estado: "COA", label: "Morelos" },
    { value: "019", c_Estado: "CHH", label: "Chihuahua" },
    { value: "019", c_Estado: "CHP", label: "Comitán de Domínguez" },
    { value: "019", c_Estado: "DUR", label: "Otáez" },
    { value: "019", c_Estado: "GRO", label: "Copalillo" },
    { value: "019", c_Estado: "GUA", label: "Jerécuaro" },
    { value: "019", c_Estado: "HID", label: "Chilcuautla" },
    { value: "019", c_Estado: "JAL", label: "Bolaños" },
    { value: "019", c_Estado: "MEX", label: "Capulhuac" },
    { value: "019", c_Estado: "MIC", label: "Cotija" },
    { value: "019", c_Estado: "MOR", label: "Tepalcingo" },
    { value: "019", c_Estado: "NAY", label: "La Yesca" },
    { value: "019", c_Estado: "NLE", label: "San Pedro Garza García" },
    { value: "019", c_Estado: "OAX", label: "Concepción Pápalo" },
    { value: "019", c_Estado: "PUE", label: "Atlixco" },
    { value: "019", c_Estado: "SLP", label: "Lagunillas" },
    { value: "019", c_Estado: "SON", label: "Cananea" },
    { value: "019", c_Estado: "TAM", label: "Llera" },
    { value: "019", c_Estado: "TLA", label: "Tepetitla de Lardizábal" },
    { value: "019", c_Estado: "VER", label: "Astacinga" },
    { value: "019", c_Estado: "YUC", label: "Chemax" },
    { value: "019", c_Estado: "ZAC", label: "Jalpa" },
    { value: "020", c_Estado: "COA", label: "Múzquiz" },
    { value: "020", c_Estado: "CHH", label: "Chínipas" },
    { value: "020", c_Estado: "CHP", label: "La Concordia" },
    { value: "020", c_Estado: "DUR", label: "Pánuco de Coronado" },
    { value: "020", c_Estado: "GRO", label: "Copanatoyac" },
    { value: "020", c_Estado: "GUA", label: "León" },
    { value: "020", c_Estado: "HID", label: "Eloxochitlán" },
    { value: "020", c_Estado: "JAL", label: "Cabo Corrientes" },
    { value: "020", c_Estado: "MEX", label: "Coacalco de Berriozábal" },
    { value: "020", c_Estado: "MIC", label: "Cuitzeo" },
    { value: "020", c_Estado: "MOR", label: "Tepoztlán" },
    { value: "020", c_Estado: "NAY", label: "Bahía de Banderas" },
    { value: "020", c_Estado: "NLE", label: "General Bravo" },
    { value: "020", c_Estado: "OAX", label: "Constancia del Rosario" },
    { value: "020", c_Estado: "PUE", label: "Atoyatempan" },
    { value: "020", c_Estado: "SLP", label: "Matehuala" },
    { value: "020", c_Estado: "SON", label: "Carbó" },
    { value: "020", c_Estado: "TAM", label: "Mainero" },
    { value: "020", c_Estado: "TLA", label: "Sanctórum de Lázaro Cárdenas" },
    { value: "020", c_Estado: "VER", label: "Atlahuilco" },
    { value: "020", c_Estado: "YUC", label: "Chicxulub Pueblo" },
    { value: "020", c_Estado: "ZAC", label: "Jerez" },
    { value: "021", c_Estado: "COA", label: "Nadadores" },
    { value: "021", c_Estado: "CHH", label: "Delicias" },
    { value: "021", c_Estado: "CHP", label: "Copainalá" },
    { value: "021", c_Estado: "DUR", label: "Peñón Blanco" },
    { value: "021", c_Estado: "GRO", label: "Coyuca de Benítez" },
    { value: "021", c_Estado: "GUA", label: "Moroleón" },
    { value: "021", c_Estado: "HID", label: "Emiliano Zapata" },
    { value: "021", c_Estado: "JAL", label: "Casimiro Castillo" },
    { value: "021", c_Estado: "MEX", label: "Coatepec Harinas" },
    { value: "021", c_Estado: "MIC", label: "Charapan" },
    { value: "021", c_Estado: "MOR", label: "Tetecala" },
    { value: "021", c_Estado: "NLE", label: "General Escobedo" },
    { value: "021", c_Estado: "OAX", label: "Cosolapa" },
    { value: "021", c_Estado: "PUE", label: "Atzala" },
    { value: "021", c_Estado: "SLP", label: "Mexquitic de Carmona" },
    { value: "021", c_Estado: "SON", label: "La Colorada" },
    { value: "021", c_Estado: "TAM", label: "El Mante" },
    { value: "021", c_Estado: "TLA", label: "Nanacamilpa de Mariano Arista" },
    { value: "021", c_Estado: "VER", label: "Atoyac" },
    { value: "021", c_Estado: "YUC", label: "Chichimilá" },
    { value: "021", c_Estado: "ZAC", label: "Jiménez del Teul" },
    { value: "022", c_Estado: "COA", label: "Nava" },
    { value: "022", c_Estado: "CHH", label: "Dr. Belisario Domínguez" },
    { value: "022", c_Estado: "CHP", label: "Chalchihuitán" },
    { value: "022", c_Estado: "DUR", label: "Poanas" },
    { value: "022", c_Estado: "GRO", label: "Coyuca de Catalán" },
    { value: "022", c_Estado: "GUA", label: "Ocampo" },
    { value: "022", c_Estado: "HID", label: "Epazoyucan" },
    { value: "022", c_Estado: "JAL", label: "Cihuatlán" },
    { value: "022", c_Estado: "MEX", label: "Cocotitlán" },
    { value: "022", c_Estado: "MIC", label: "Charo" },
    { value: "022", c_Estado: "MOR", label: "Tetela del Volcán" },
    { value: "022", c_Estado: "NLE", label: "General Terán" },
    { value: "022", c_Estado: "OAX", label: "Cosoltepec" },
    { value: "022", c_Estado: "PUE", label: "Atzitzihuacán" },
    { value: "022", c_Estado: "SLP", label: "Moctezuma" },
    { value: "022", c_Estado: "SON", label: "Cucurpe" },
    { value: "022", c_Estado: "TAM", label: "Matamoros" },
    { value: "022", c_Estado: "TLA", label: "Acuamanala de Miguel Hidalgo" },
    { value: "022", c_Estado: "VER", label: "Atzacan" },
    { value: "022", c_Estado: "YUC", label: "Chikindzonot" },
    { value: "022", c_Estado: "ZAC", label: "Juan Aldama" },
    { value: "023", c_Estado: "COA", label: "Ocampo" },
    { value: "023", c_Estado: "CHH", label: "Galeana" },
    { value: "023", c_Estado: "CHP", label: "Chamula" },
    { value: "023", c_Estado: "DUR", label: "Pueblo Nuevo" },
    { value: "023", c_Estado: "GRO", label: "Cuajinicuilapa" },
    { value: "023", c_Estado: "GUA", label: "Pénjamo" },
    { value: "023", c_Estado: "HID", label: "Francisco I. Madero" },
    { value: "023", c_Estado: "JAL", label: "Zapotlán el Grande" },
    { value: "023", c_Estado: "MEX", label: "Coyotepec" },
    { value: "023", c_Estado: "MIC", label: "Chavinda" },
    { value: "023", c_Estado: "MOR", label: "Tlalnepantla" },
    { value: "023", c_Estado: "NLE", label: "General Treviño" },
    { value: "023", c_Estado: "OAX", label: "Cuilápam de Guerrero" },
    { value: "023", c_Estado: "PUE", label: "Atzitzintla" },
    { value: "023", c_Estado: "SLP", label: "Rayón" },
    { value: "023", c_Estado: "SON", label: "Cumpas" },
    { value: "023", c_Estado: "TAM", label: "Méndez" },
    { value: "023", c_Estado: "TLA", label: "Natívitas" },
    { value: "023", c_Estado: "VER", label: "Atzalan" },
    { value: "023", c_Estado: "YUC", label: "Chocholá" },
    { value: "023", c_Estado: "ZAC", label: "Juchipila" },
    { value: "024", c_Estado: "COA", label: "Parras" },
    { value: "024", c_Estado: "CHH", label: "Santa Isabel" },
    { value: "024", c_Estado: "CHP", label: "Chanal" },
    { value: "024", c_Estado: "DUR", label: "Rodeo" },
    { value: "024", c_Estado: "GRO", label: "Cualác" },
    { value: "024", c_Estado: "GUA", label: "Pueblo Nuevo" },
    { value: "024", c_Estado: "HID", label: "Huasca de Ocampo" },
    { value: "024", c_Estado: "JAL", label: "Cocula" },
    { value: "024", c_Estado: "MEX", label: "Cuautitlán" },
    { value: "024", c_Estado: "MIC", label: "Cherán" },
    { value: "024", c_Estado: "MOR", label: "Tlaltizapán de Zapata" },
    { value: "024", c_Estado: "NLE", label: "General Zaragoza" },
    { value: "024", c_Estado: "OAX", label: "Cuyamecalco Villa de Zaragoza" },
    { value: "024", c_Estado: "PUE", label: "Axutla" },
    { value: "024", c_Estado: "SLP", label: "Rioverde" },
    { value: "024", c_Estado: "SON", label: "Divisaderos" },
    { value: "024", c_Estado: "TAM", label: "Mier" },
    { value: "024", c_Estado: "TLA", label: "Panotla" },
    { value: "024", c_Estado: "VER", label: "Tlaltetela" },
    { value: "024", c_Estado: "YUC", label: "Chumayel" },
    { value: "024", c_Estado: "ZAC", label: "Loreto" },
    { value: "025", c_Estado: "COA", label: "Piedras Negras" },
    { value: "025", c_Estado: "CHH", label: "Gómez Farías" },
    { value: "025", c_Estado: "CHP", label: "Chapultenango" },
    { value: "025", c_Estado: "DUR", label: "San Bernardo" },
    { value: "025", c_Estado: "GRO", label: "Cuautepec" },
    { value: "025", c_Estado: "GUA", label: "Purísima del Rincón" },
    { value: "025", c_Estado: "HID", label: "Huautla" },
    { value: "025", c_Estado: "JAL", label: "Colotlán" },
    { value: "025", c_Estado: "MEX", label: "Chalco" },
    { value: "025", c_Estado: "MIC", label: "Chilchota" },
    { value: "025", c_Estado: "MOR", label: "Tlaquiltenango" },
    { value: "025", c_Estado: "NLE", label: "General Zuazua" },
    { value: "025", c_Estado: "OAX", label: "Chahuites" },
    { value: "025", c_Estado: "PUE", label: "Ayotoxco de Guerrero" },
    { value: "025", c_Estado: "SLP", label: "Salinas" },
    { value: "025", c_Estado: "SON", label: "Empalme" },
    { value: "025", c_Estado: "TAM", label: "Miguel Alemán" },
    { value: "025", c_Estado: "TLA", label: "San Pablo del Monte" },
    { value: "025", c_Estado: "VER", label: "Ayahualulco" },
    { value: "025", c_Estado: "YUC", label: "Dzán" },
    { value: "025", c_Estado: "ZAC", label: "Luis Moya" },
    { value: "026", c_Estado: "COA", label: "Progreso" },
    { value: "026", c_Estado: "CHH", label: "Gran Morelos" },
    { value: "026", c_Estado: "CHP", label: "Chenalhó" },
    { value: "026", c_Estado: "DUR", label: "San Dimas" },
    { value: "026", c_Estado: "GRO", label: "Cuetzala del Progreso" },
    { value: "026", c_Estado: "GUA", label: "Romita" },
    { value: "026", c_Estado: "HID", label: "Huazalingo" },
    { value: "026", c_Estado: "JAL", label: "Concepción de Buenos Aires" },
    { value: "026", c_Estado: "MEX", label: "Chapa de Mota" },
    { value: "026", c_Estado: "MIC", label: "Chinicuila" },
    { value: "026", c_Estado: "MOR", label: "Tlayacapan" },
    { value: "026", c_Estado: "NLE", label: "Guadalupe" },
    { value: "026", c_Estado: "OAX", label: "Chalcatongo de Hidalgo" },
    { value: "026", c_Estado: "PUE", label: "Calpan" },
    { value: "026", c_Estado: "SLP", label: "San Antonio" },
    { value: "026", c_Estado: "SON", label: "Etchojoa" },
    { value: "026", c_Estado: "TAM", label: "Miquihuana" },
    { value: "026", c_Estado: "TLA", label: "Santa Cruz Tlaxcala" },
    { value: "026", c_Estado: "VER", label: "Banderilla" },
    { value: "026", c_Estado: "YUC", label: "Dzemul" },
    { value: "026", c_Estado: "ZAC", label: "Mazapil" },
    { value: "027", c_Estado: "COA", label: "Ramos Arizpe" },
    { value: "027", c_Estado: "CHH", label: "Guachochi" },
    { value: "027", c_Estado: "CHP", label: "Chiapa de Corzo" },
    { value: "027", c_Estado: "DUR", label: "San Juan de Guadalupe" },
    { value: "027", c_Estado: "GRO", label: "Cutzamala de Pinzón" },
    { value: "027", c_Estado: "GUA", label: "Salamanca" },
    { value: "027", c_Estado: "HID", label: "Huehuetla" },
    { value: "027", c_Estado: "JAL", label: "Cuautitlán de García Barragán" },
    { value: "027", c_Estado: "MEX", label: "Chapultepec" },
    { value: "027", c_Estado: "MIC", label: "Chucándiro" },
    { value: "027", c_Estado: "MOR", label: "Totolapan" },
    { value: "027", c_Estado: "NLE", label: "Los Herreras" },
    { value: "027", c_Estado: "OAX", label: "Chiquihuitlán de Benito Juárez" },
    { value: "027", c_Estado: "PUE", label: "Caltepec" },
    { value: "027", c_Estado: "SLP", label: "San Ciro de Acosta" },
    { value: "027", c_Estado: "SON", label: "Fronteras" },
    { value: "027", c_Estado: "TAM", label: "Nuevo Laredo" },
    { value: "027", c_Estado: "TLA", label: "Tenancingo" },
    { value: "027", c_Estado: "VER", label: "Benito Juárez" },
    { value: "027", c_Estado: "YUC", label: "Dzidzantún" },
    { value: "027", c_Estado: "ZAC", label: "Melchor Ocampo" },
    { value: "028", c_Estado: "COA", label: "Sabinas" },
    { value: "028", c_Estado: "CHH", label: "Guadalupe" },
    { value: "028", c_Estado: "CHP", label: "Chiapilla" },
    { value: "028", c_Estado: "DUR", label: "San Juan del Río" },
    { value: "028", c_Estado: "GRO", label: "Chilapa de Álvarez" },
    { value: "028", c_Estado: "GUA", label: "Salvatierra" },
    { value: "028", c_Estado: "HID", label: "Huejutla de Reyes" },
    { value: "028", c_Estado: "JAL", label: "Cuautla" },
    { value: "028", c_Estado: "MEX", label: "Chiautla" },
    { value: "028", c_Estado: "MIC", label: "Churintzio" },
    { value: "028", c_Estado: "MOR", label: "Xochitepec" },
    { value: "028", c_Estado: "NLE", label: "Higueras" },
    { value: "028", c_Estado: "OAX", label: "Heroica Ciudad de Ejutla de Crespo" },
    { value: "028", c_Estado: "PUE", label: "Camocuautla" },
    { value: "028", c_Estado: "SLP", label: "San Luis Potosí" },
    { value: "028", c_Estado: "SON", label: "Granados" },
    { value: "028", c_Estado: "TAM", label: "Nuevo Morelos" },
    { value: "028", c_Estado: "TLA", label: "Teolocholco" },
    { value: "028", c_Estado: "VER", label: "Boca del Río" },
    { value: "028", c_Estado: "YUC", label: "Dzilam de Bravo" },
    { value: "028", c_Estado: "ZAC", label: "Mezquital del Oro" },
    { value: "029", c_Estado: "COA", label: "Sacramento" },
    { value: "029", c_Estado: "CHH", label: "Guadalupe y Calvo" },
    { value: "029", c_Estado: "CHP", label: "Chicoasén" },
    { value: "029", c_Estado: "DUR", label: "San Luis del Cordero" },
    { value: "029", c_Estado: "GRO", label: "Chilpancingo de los Bravo" },
    { value: "029", c_Estado: "GUA", label: "San Diego de la Unión" },
    { value: "029", c_Estado: "HID", label: "Huichapan" },
    { value: "029", c_Estado: "JAL", label: "Cuquío" },
    { value: "029", c_Estado: "MEX", label: "Chicoloapan" },
    { value: "029", c_Estado: "MIC", label: "Churumuco" },
    { value: "029", c_Estado: "MOR", label: "Yautepec" },
    { value: "029", c_Estado: "NLE", label: "Hualahuises" },
    { value: "029", c_Estado: "OAX", label: "Eloxochitlán de Flores Magón" },
    { value: "029", c_Estado: "PUE", label: "Caxhuacan" },
    { value: "029", c_Estado: "SLP", label: "San Martín Chalchicuautla" },
    { value: "029", c_Estado: "SON", label: "Guaymas" },
    { value: "029", c_Estado: "TAM", label: "Ocampo" },
    { value: "029", c_Estado: "TLA", label: "Tepeyanco" },
    { value: "029", c_Estado: "VER", label: "Calcahualco" },
    { value: "029", c_Estado: "YUC", label: "Dzilam González" },
    { value: "029", c_Estado: "ZAC", label: "Miguel Auza" },
    { value: "030", c_Estado: "COA", label: "Saltillo" },
    { value: "030", c_Estado: "CHH", label: "Guazapares" },
    { value: "030", c_Estado: "CHP", label: "Chicomuselo" },
    { value: "030", c_Estado: "DUR", label: "San Pedro del Gallo" },
    { value: "030", c_Estado: "GRO", label: "Florencio Villarreal" },
    { value: "030", c_Estado: "GUA", label: "San Felipe" },
    { value: "030", c_Estado: "HID", label: "Ixmiquilpan" },
    { value: "030", c_Estado: "JAL", label: "Chapala" },
    { value: "030", c_Estado: "MEX", label: "Chiconcuac" },
    { value: "030", c_Estado: "MIC", label: "Ecuandureo" },
    { value: "030", c_Estado: "MOR", label: "Yecapixtla" },
    { value: "030", c_Estado: "NLE", label: "Iturbide" },
    { value: "030", c_Estado: "OAX", label: "El Espinal" },
    { value: "030", c_Estado: "PUE", label: "Coatepec" },
    { value: "030", c_Estado: "SLP", label: "San Nicolás Tolentino" },
    { value: "030", c_Estado: "SON", label: "Hermosillo" },
    { value: "030", c_Estado: "TAM", label: "Padilla" },
    { value: "030", c_Estado: "TLA", label: "Terrenate" },
    { value: "030", c_Estado: "VER", label: "Camerino Z. Mendoza" },
    { value: "030", c_Estado: "YUC", label: "Dzitás" },
    { value: "030", c_Estado: "ZAC", label: "Momax" },
    { value: "031", c_Estado: "COA", label: "San Buenaventura" },
    { value: "031", c_Estado: "CHH", label: "Guerrero" },
    { value: "031", c_Estado: "CHP", label: "Chilón" },
    { value: "031", c_Estado: "DUR", label: "Santa Clara" },
    { value: "031", c_Estado: "GRO", label: "General Canuto A. Neri" },
    { value: "031", c_Estado: "GUA", label: "San Francisco del Rincón" },
    { value: "031", c_Estado: "HID", label: "Jacala de Ledezma" },
    { value: "031", c_Estado: "JAL", label: "Chimaltitán" },
    { value: "031", c_Estado: "MEX", label: "Chimalhuacán" },
    { value: "031", c_Estado: "MIC", label: "Epitacio Huerta" },
    { value: "031", c_Estado: "MOR", label: "Zacatepec" },
    { value: "031", c_Estado: "NLE", label: "Juárez" },
    { value: "031", c_Estado: "OAX", label: "Tamazulápam del Espíritu Santo" },
    { value: "031", c_Estado: "PUE", label: "Coatzingo" },
    { value: "031", c_Estado: "SLP", label: "Santa Catarina" },
    { value: "031", c_Estado: "SON", label: "Huachinera" },
    { value: "031", c_Estado: "TAM", label: "Palmillas" },
    { value: "031", c_Estado: "TLA", label: "Tetla de la Solidaridad" },
    { value: "031", c_Estado: "VER", label: "Carrillo Puerto" },
    { value: "031", c_Estado: "YUC", label: "Dzoncauich" },
    { value: "031", c_Estado: "ZAC", label: "Monte Escobedo" },
    { value: "032", c_Estado: "COA", label: "San Juan de Sabinas" },
    { value: "032", c_Estado: "CHH", label: "Hidalgo del Parral" },
    { value: "032", c_Estado: "CHP", label: "Escuintla" },
    { value: "032", c_Estado: "DUR", label: "Santiago Papasquiaro" },
    { value: "032", c_Estado: "GRO", label: "General Heliodoro Castillo" },
    { value: "032", c_Estado: "GUA", label: "San José Iturbide" },
    { value: "032", c_Estado: "HID", label: "Jaltocán" },
    { value: "032", c_Estado: "JAL", label: "Chiquilistlán" },
    { value: "032", c_Estado: "MEX", label: "Donato Guerra" },
    { value: "032", c_Estado: "MIC", label: "Erongarícuaro" },
    { value: "032", c_Estado: "MOR", label: "Zacualpan" },
    { value: "032", c_Estado: "NLE", label: "Lampazos de Naranjo" },
    { value: "032", c_Estado: "OAX", label: "Fresnillo de Trujano" },
    { value: "032", c_Estado: "PUE", label: "Cohetzala" },
    { value: "032", c_Estado: "SLP", label: "Santa María del Río" },
    { value: "032", c_Estado: "SON", label: "Huásabas" },
    { value: "032", c_Estado: "TAM", label: "Reynosa" },
    { value: "032", c_Estado: "TLA", label: "Tetlatlahuca" },
    { value: "032", c_Estado: "VER", label: "Catemaco" },
    { value: "032", c_Estado: "YUC", label: "Espita" },
    { value: "032", c_Estado: "ZAC", label: "Morelos" },
    { value: "033", c_Estado: "COA", label: "San Pedro" },
    { value: "033", c_Estado: "CHH", label: "Huejotitán" },
    { value: "033", c_Estado: "CHP", label: "Francisco León" },
    { value: "033", c_Estado: "DUR", label: "Súchil" },
    { value: "033", c_Estado: "GRO", label: "Huamuxtitlán" },
    { value: "033", c_Estado: "GUA", label: "San Luis de la Paz" },
    { value: "033", c_Estado: "HID", label: "Juárez Hidalgo" },
    { value: "033", c_Estado: "JAL", label: "Degollado" },
    { value: "033", c_Estado: "MEX", label: "Ecatepec de Morelos" },
    { value: "033", c_Estado: "MIC", label: "Gabriel Zamora" },
    { value: "033", c_Estado: "MOR", label: "Temoac" },
    { value: "033", c_Estado: "NLE", label: "Linares" },
    { value: "033", c_Estado: "OAX", label: "Guadalupe Etla" },
    { value: "033", c_Estado: "PUE", label: "Cohuecan" },
    { value: "033", c_Estado: "SLP", label: "Santo Domingo" },
    { value: "033", c_Estado: "SON", label: "Huatabampo" },
    { value: "033", c_Estado: "TAM", label: "Río Bravo" },
    { value: "033", c_Estado: "TLA", label: "Tlaxcala" },
    { value: "033", c_Estado: "VER", label: "Cazones de Herrera" },
    { value: "033", c_Estado: "YUC", label: "Halachó" },
    { value: "033", c_Estado: "ZAC", label: "Moyahua de Estrada" },
    { value: "034", c_Estado: "COA", label: "Sierra Mojada" },
    { value: "034", c_Estado: "CHH", label: "Ignacio Zaragoza" },
    { value: "034", c_Estado: "CHP", label: "Frontera Comalapa" },
    { value: "034", c_Estado: "DUR", label: "Tamazula" },
    { value: "034", c_Estado: "GRO", label: "Huitzuco de los Figueroa" },
    { value: "034", c_Estado: "GUA", label: "Santa Catarina" },
    { value: "034", c_Estado: "HID", label: "Lolotla" },
    { value: "034", c_Estado: "JAL", label: "Ejutla" },
    { value: "034", c_Estado: "MEX", label: "Ecatzingo" },
    { value: "034", c_Estado: "MIC", label: "Hidalgo" },
    { value: "034", c_Estado: "NLE", label: "Marín" },
    { value: "034", c_Estado: "OAX", label: "Guadalupe de Ramírez" },
    { value: "034", c_Estado: "PUE", label: "Coronango" },
    { value: "034", c_Estado: "SLP", label: "San Vicente Tancuayalab" },
    { value: "034", c_Estado: "SON", label: "Huépac" },
    { value: "034", c_Estado: "TAM", label: "San Carlos" },
    { value: "034", c_Estado: "TLA", label: "Tlaxco" },
    { value: "034", c_Estado: "VER", label: "Cerro Azul" },
    { value: "034", c_Estado: "YUC", label: "Hocabá" },
    { value: "034", c_Estado: "ZAC", label: "Nochistlán de Mejía" },
    { value: "035", c_Estado: "COA", label: "Torreón" },
    { value: "035", c_Estado: "CHH", label: "Janos" },
    { value: "035", c_Estado: "CHP", label: "Frontera Hidalgo" },
    { value: "035", c_Estado: "DUR", label: "Tepehuanes" },
    { value: "035", c_Estado: "GRO", label: "Iguala de la Independencia" },
    { value: "035", c_Estado: "GUA", label: "Santa Cruz de Juventino Rosas" },
    { value: "035", c_Estado: "HID", label: "Metepec" },
    { value: "035", c_Estado: "JAL", label: "Encarnación de Díaz" },
    { value: "035", c_Estado: "MEX", label: "Huehuetoca" },
    { value: "035", c_Estado: "MIC", label: "La Huacana" },
    { value: "035", c_Estado: "NLE", label: "Melchor Ocampo" },
    { value: "035", c_Estado: "OAX", label: "Guelatao de Juárez" },
    { value: "035", c_Estado: "PUE", label: "Coxcatlán" },
    { value: "035", c_Estado: "SLP", label: "Soledad de Graciano Sánchez" },
    { value: "035", c_Estado: "SON", label: "Imuris" },
    { value: "035", c_Estado: "TAM", label: "San Fernando" },
    { value: "035", c_Estado: "TLA", label: "Tocatlán" },
    { value: "035", c_Estado: "VER", label: "Citlaltépetl" },
    { value: "035", c_Estado: "YUC", label: "Hoctún" },
    { value: "035", c_Estado: "ZAC", label: "Noria de Ángeles" },
    { value: "036", c_Estado: "COA", label: "Viesca" },
    { value: "036", c_Estado: "CHH", label: "Jiménez" },
    { value: "036", c_Estado: "CHP", label: "La Grandeza" },
    { value: "036", c_Estado: "DUR", label: "Tlahualilo" },
    { value: "036", c_Estado: "GRO", label: "Igualapa" },
    { value: "036", c_Estado: "GUA", label: "Santiago Maravatío" },
    { value: "036", c_Estado: "HID", label: "San Agustín Metzquititlán" },
    { value: "036", c_Estado: "JAL", label: "Etzatlán" },
    { value: "036", c_Estado: "MEX", label: "Hueypoxtla" },
    { value: "036", c_Estado: "MIC", label: "Huandacareo" },
    { value: "036", c_Estado: "NLE", label: "Mier y Noriega" },
    { value: "036", c_Estado: "OAX", label: "Guevea de Humboldt" },
    { value: "036", c_Estado: "PUE", label: "Coyomeapan" },
    { value: "036", c_Estado: "SLP", label: "Tamasopo" },
    { value: "036", c_Estado: "SON", label: "Magdalena" },
    { value: "036", c_Estado: "TAM", label: "San Nicolás" },
    { value: "036", c_Estado: "TLA", label: "Totolac" },
    { value: "036", c_Estado: "VER", label: "Coacoatzintla" },
    { value: "036", c_Estado: "YUC", label: "Homún" },
    { value: "036", c_Estado: "ZAC", label: "Ojocaliente" },
    { value: "037", c_Estado: "COA", label: "Villa Unión" },
    { value: "037", c_Estado: "CHH", label: "Juárez" },
    { value: "037", c_Estado: "CHP", label: "Huehuetán" },
    { value: "037", c_Estado: "DUR", label: "Topia" },
    { value: "037", c_Estado: "GRO", label: "Ixcateopan de Cuauhtémoc" },
    { value: "037", c_Estado: "GUA", label: "Silao de la Victoria" },
    { value: "037", c_Estado: "HID", label: "Metztitlán" },
    { value: "037", c_Estado: "JAL", label: "El Grullo" },
    { value: "037", c_Estado: "MEX", label: "Huixquilucan" },
    { value: "037", c_Estado: "MIC", label: "Huaniqueo" },
    { value: "037", c_Estado: "NLE", label: "Mina" },
    { value: "037", c_Estado: "OAX", label: "Mesones Hidalgo" },
    { value: "037", c_Estado: "PUE", label: "Coyotepec" },
    { value: "037", c_Estado: "SLP", label: "Tamazunchale" },
    { value: "037", c_Estado: "SON", label: "Mazatán" },
    { value: "037", c_Estado: "TAM", label: "Soto la Marina" },
    { value: "037", c_Estado: "TLA", label: "Ziltlaltépec de Trinidad Sánchez Santos" },
    { value: "037", c_Estado: "VER", label: "Coahuitlán" },
    { value: "037", c_Estado: "YUC", label: "Huhí" },
    { value: "037", c_Estado: "ZAC", label: "Pánuco" },
    { value: "038", c_Estado: "COA", label: "Zaragoza" },
    { value: "038", c_Estado: "CHH", label: "Julimes" },
    { value: "038", c_Estado: "CHP", label: "Huixtán" },
    { value: "038", c_Estado: "DUR", label: "Vicente Guerrero" },
    { value: "038", c_Estado: "GRO", label: "Zihuatanejo de Azueta" },
    { value: "038", c_Estado: "GUA", label: "Tarandacuao" },
    { value: "038", c_Estado: "HID", label: "Mineral del Chico" },
    { value: "038", c_Estado: "JAL", label: "Guachinango" },
    { value: "038", c_Estado: "MEX", label: "Isidro Fabela" },
    { value: "038", c_Estado: "MIC", label: "Huetamo" },
    { value: "038", c_Estado: "NLE", label: "Montemorelos" },
    { value: "038", c_Estado: "OAX", label: "Villa Hidalgo" },
    { value: "038", c_Estado: "PUE", label: "Cuapiaxtla de Madero" },
    { value: "038", c_Estado: "SLP", label: "Tampacán" },
    { value: "038", c_Estado: "SON", label: "Moctezuma" },
    { value: "038", c_Estado: "TAM", label: "Tampico" },
    { value: "038", c_Estado: "TLA", label: "Tzompantepec" },
    { value: "038", c_Estado: "VER", label: "Coatepec" },
    { value: "038", c_Estado: "YUC", label: "Hunucmá" },
    { value: "038", c_Estado: "ZAC", label: "Pinos" },
    { value: "039", c_Estado: "CHH", label: "López" },
    { value: "039", c_Estado: "CHP", label: "Huitiupán" },
    { value: "039", c_Estado: "DUR", label: "Nuevo Ideal" },
    { value: "039", c_Estado: "GRO", label: "Juan R. Escudero" },
    { value: "039", c_Estado: "GUA", label: "Tarimoro" },
    { value: "039", c_Estado: "HID", label: "Mineral del Monte" },
    { value: "039", c_Estado: "JAL", label: "Guadalajara" },
    { value: "039", c_Estado: "MEX", label: "Ixtapaluca" },
    { value: "039", c_Estado: "MIC", label: "Huiramba" },
    { value: "039", c_Estado: "NLE", label: "Monterrey" },
    { value: "039", c_Estado: "OAX", label: "Heroica Ciudad de Huajuapan de León" },
    { value: "039", c_Estado: "PUE", label: "Cuautempan" },
    { value: "039", c_Estado: "SLP", label: "Tampamolón Corona" },
    { value: "039", c_Estado: "SON", label: "Naco" },
    { value: "039", c_Estado: "TAM", label: "Tula" },
    { value: "039", c_Estado: "TLA", label: "Xaloztoc" },
    { value: "039", c_Estado: "VER", label: "Coatzacoalcos" },
    { value: "039", c_Estado: "YUC", label: "Ixil" },
    { value: "039", c_Estado: "ZAC", label: "Río Grande" },
    { value: "040", c_Estado: "CHH", label: "Madera" },
    { value: "040", c_Estado: "CHP", label: "Huixtla" },
    { value: "040", c_Estado: "GRO", label: "Leonardo Bravo" },
    { value: "040", c_Estado: "GUA", label: "Tierra Blanca" },
    { value: "040", c_Estado: "HID", label: "La Misión" },
    { value: "040", c_Estado: "JAL", label: "Hostotipaquillo" },
    { value: "040", c_Estado: "MEX", label: "Ixtapan de la Sal" },
    { value: "040", c_Estado: "MIC", label: "Indaparapeo" },
    { value: "040", c_Estado: "NLE", label: "Parás" },
    { value: "040", c_Estado: "OAX", label: "Huautepec" },
    { value: "040", c_Estado: "PUE", label: "Cuautinchán" },
    { value: "040", c_Estado: "SLP", label: "Tamuín" },
    { value: "040", c_Estado: "SON", label: "Nácori Chico" },
    { value: "040", c_Estado: "TAM", label: "Valle Hermoso" },
    { value: "040", c_Estado: "TLA", label: "Xaltocan" },
    { value: "040", c_Estado: "VER", label: "Coatzintla" },
    { value: "040", c_Estado: "YUC", label: "Izamal" },
    { value: "040", c_Estado: "ZAC", label: "Sain Alto" },
    { value: "041", c_Estado: "CHH", label: "Maguarichi" },
    { value: "041", c_Estado: "CHP", label: "La Independencia" },
    { value: "041", c_Estado: "GRO", label: "Malinaltepec" },
    { value: "041", c_Estado: "GUA", label: "Uriangato" },
    { value: "041", c_Estado: "HID", label: "Mixquiahuala de Juárez" },
    { value: "041", c_Estado: "JAL", label: "Huejúcar" },
    { value: "041", c_Estado: "MEX", label: "Ixtapan del Oro" },
    { value: "041", c_Estado: "MIC", label: "Irimbo" },
    { value: "041", c_Estado: "NLE", label: "Pesquería" },
    { value: "041", c_Estado: "OAX", label: "Huautla de Jiménez" },
    { value: "041", c_Estado: "PUE", label: "Cuautlancingo" },
    { value: "041", c_Estado: "SLP", label: "Tanlajás" },
    { value: "041", c_Estado: "SON", label: "Nacozari de García" },
    { value: "041", c_Estado: "TAM", label: "Victoria" },
    { value: "041", c_Estado: "TLA", label: "Papalotla de Xicohténcatl" },
    { value: "041", c_Estado: "VER", label: "Coetzala" },
    { value: "041", c_Estado: "YUC", label: "Kanasín" },
    { value: "041", c_Estado: "ZAC", label: "El Salvador" },
    { value: "042", c_Estado: "CHH", label: "Manuel Benavides" },
    { value: "042", c_Estado: "CHP", label: "Ixhuatán" },
    { value: "042", c_Estado: "GRO", label: "Mártir de Cuilapan" },
    { value: "042", c_Estado: "GUA", label: "Valle de Santiago" },
    { value: "042", c_Estado: "HID", label: "Molango de Escamilla" },
    { value: "042", c_Estado: "JAL", label: "Huejuquilla el Alto" },
    { value: "042", c_Estado: "MEX", label: "Ixtlahuaca" },
    { value: "042", c_Estado: "MIC", label: "Ixtlán" },
    { value: "042", c_Estado: "NLE", label: "Los Ramones" },
    { value: "042", c_Estado: "OAX", label: "Ixtlán de Juárez" },
    { value: "042", c_Estado: "PUE", label: "Cuayuca de Andrade" },
    { value: "042", c_Estado: "SLP", label: "Tanquián de Escobedo" },
    { value: "042", c_Estado: "SON", label: "Navojoa" },
    { value: "042", c_Estado: "TAM", label: "Villagrán" },
    { value: "042", c_Estado: "TLA", label: "Xicohtzinco" },
    { value: "042", c_Estado: "VER", label: "Colipa" },
    { value: "042", c_Estado: "YUC", label: "Kantunil" },
    { value: "042", c_Estado: "ZAC", label: "Sombrerete" },
    { value: "043", c_Estado: "CHH", label: "Matachí" },
    { value: "043", c_Estado: "CHP", label: "Ixtacomitán" },
    { value: "043", c_Estado: "GRO", label: "Metlatónoc" },
    { value: "043", c_Estado: "GUA", label: "Victoria" },
    { value: "043", c_Estado: "HID", label: "Nicolás Flores" },
    { value: "043", c_Estado: "JAL", label: "La Huerta" },
    { value: "043", c_Estado: "MEX", label: "Xalatlaco" },
    { value: "043", c_Estado: "MIC", label: "Jacona" },
    { value: "043", c_Estado: "NLE", label: "Rayones" },
    { value: "043", c_Estado: "OAX", label: "Heroica Ciudad de Juchitán de Zaragoza" },
    { value: "043", c_Estado: "PUE", label: "Cuetzalan del Progreso" },
    { value: "043", c_Estado: "SLP", label: "Tierra Nueva" },
    { value: "043", c_Estado: "SON", label: "Nogales" },
    { value: "043", c_Estado: "TAM", label: "Xicoténcatl" },
    { value: "043", c_Estado: "TLA", label: "Yauhquemehcan" },
    { value: "043", c_Estado: "VER", label: "Comapa" },
    { value: "043", c_Estado: "YUC", label: "Kaua" },
    { value: "043", c_Estado: "ZAC", label: "Susticacán" },
    { value: "044", c_Estado: "CHH", label: "Matamoros" },
    { value: "044", c_Estado: "CHP", label: "Ixtapa" },
    { value: "044", c_Estado: "GRO", label: "Mochitlán" },
    { value: "044", c_Estado: "GUA", label: "Villagrán" },
    { value: "044", c_Estado: "HID", label: "Nopala de Villagrán" },
    { value: "044", c_Estado: "JAL", label: "Ixtlahuacán de los Membrillos" },
    { value: "044", c_Estado: "MEX", label: "Jaltenco" },
    { value: "044", c_Estado: "MIC", label: "Jiménez" },
    { value: "044", c_Estado: "NLE", label: "Sabinas Hidalgo" },
    { value: "044", c_Estado: "OAX", label: "Loma Bonita" },
    { value: "044", c_Estado: "PUE", label: "Cuyoaco" },
    { value: "044", c_Estado: "SLP", label: "Vanegas" },
    { value: "044", c_Estado: "SON", label: "Onavas" },
    { value: "044", c_Estado: "TLA", label: "Zacatelco" },
    { value: "044", c_Estado: "VER", label: "Córdoba" },
    { value: "044", c_Estado: "YUC", label: "Kinchil" },
    { value: "044", c_Estado: "ZAC", label: "Tabasco" },
    { value: "045", c_Estado: "CHH", label: "Meoqui" },
    { value: "045", c_Estado: "CHP", label: "Ixtapangajoya" },
    { value: "045", c_Estado: "GRO", label: "Olinalá" },
    { value: "045", c_Estado: "GUA", label: "Xichú" },
    { value: "045", c_Estado: "HID", label: "Omitlán de Juárez" },
    { value: "045", c_Estado: "JAL", label: "Ixtlahuacán del Río" },
    { value: "045", c_Estado: "MEX", label: "Jilotepec" },
    { value: "045", c_Estado: "MIC", label: "Jiquilpan" },
    { value: "045", c_Estado: "NLE", label: "Salinas Victoria" },
    { value: "045", c_Estado: "OAX", label: "Magdalena Apasco" },
    { value: "045", c_Estado: "PUE", label: "Chalchicomula de Sesma" },
    { value: "045", c_Estado: "SLP", label: "Venado" },
    { value: "045", c_Estado: "SON", label: "Opodepe" },
    { value: "045", c_Estado: "TLA", label: "Benito Juárez" },
    { value: "045", c_Estado: "VER", label: "Cosamaloapan de Carpio" },
    { value: "045", c_Estado: "YUC", label: "Kopomá" },
    { value: "045", c_Estado: "ZAC", label: "Tepechitlán" },
    { value: "046", c_Estado: "CHH", label: "Morelos" },
    { value: "046", c_Estado: "CHP", label: "Jiquipilas" },
    { value: "046", c_Estado: "GRO", label: "Ometepec" },
    { value: "046", c_Estado: "GUA", label: "Yuriria" },
    { value: "046", c_Estado: "HID", label: "San Felipe Orizatlán" },
    { value: "046", c_Estado: "JAL", label: "Jalostotitlán" },
    { value: "046", c_Estado: "MEX", label: "Jilotzingo" },
    { value: "046", c_Estado: "MIC", label: "Juárez" },
    { value: "046", c_Estado: "NLE", label: "San Nicolás de los Garza" },
    { value: "046", c_Estado: "OAX", label: "Magdalena Jaltepec" },
    { value: "046", c_Estado: "PUE", label: "Chapulco" },
    { value: "046", c_Estado: "SLP", label: "Villa de Arriaga" },
    { value: "046", c_Estado: "SON", label: "Oquitoa" },
    { value: "046", c_Estado: "TLA", label: "Emiliano Zapata" },
    { value: "046", c_Estado: "VER", label: "Cosautlán de Carvajal" },
    { value: "046", c_Estado: "YUC", label: "Mama" },
    { value: "046", c_Estado: "ZAC", label: "Tepetongo" },
    { value: "047", c_Estado: "CHH", label: "Moris" },
    { value: "047", c_Estado: "CHP", label: "Jitotol" },
    { value: "047", c_Estado: "GRO", label: "Pedro Ascencio Alquisiras" },
    { value: "047", c_Estado: "HID", label: "Pacula" },
    { value: "047", c_Estado: "JAL", label: "Jamay" },
    { value: "047", c_Estado: "MEX", label: "Jiquipilco" },
    { value: "047", c_Estado: "MIC", label: "Jungapeo" },
    { value: "047", c_Estado: "NLE", label: "Hidalgo" },
    { value: "047", c_Estado: "OAX", label: "Santa Magdalena Jicotlán" },
    { value: "047", c_Estado: "PUE", label: "Chiautla" },
    { value: "047", c_Estado: "SLP", label: "Villa de Guadalupe" },
    { value: "047", c_Estado: "SON", label: "Pitiquito" },
    { value: "047", c_Estado: "TLA", label: "Lázaro Cárdenas" },
    { value: "047", c_Estado: "VER", label: "Coscomatepec" },
    { value: "047", c_Estado: "YUC", label: "Maní" },
    { value: "047", c_Estado: "ZAC", label: "Teúl de González Ortega" },
    { value: "048", c_Estado: "CHH", label: "Namiquipa" },
    { value: "048", c_Estado: "CHP", label: "Juárez" },
    { value: "048", c_Estado: "GRO", label: "Petatlán" },
    { value: "048", c_Estado: "HID", label: "Pachuca de Soto" },
    { value: "048", c_Estado: "JAL", label: "Jesús María" },
    { value: "048", c_Estado: "MEX", label: "Jocotitlán" },
    { value: "048", c_Estado: "MIC", label: "Lagunillas" },
    { value: "048", c_Estado: "NLE", label: "Santa Catarina" },
    { value: "048", c_Estado: "OAX", label: "Magdalena Mixtepec" },
    { value: "048", c_Estado: "PUE", label: "Chiautzingo" },
    { value: "048", c_Estado: "SLP", label: "Villa de la Paz" },
    { value: "048", c_Estado: "SON", label: "Puerto Peñasco" },
    { value: "048", c_Estado: "TLA", label: "La Magdalena Tlaltelulco" },
    { value: "048", c_Estado: "VER", label: "Cosoleacaque" },
    { value: "048", c_Estado: "YUC", label: "Maxcanú" },
    { value: "048", c_Estado: "ZAC", label: "Tlaltenango de Sánchez Román" },
    { value: "049", c_Estado: "CHH", label: "Nonoava" },
    { value: "049", c_Estado: "CHP", label: "Larráinzar" },
    { value: "049", c_Estado: "GRO", label: "Pilcaya" },
    { value: "049", c_Estado: "HID", label: "Pisaflores" },
    { value: "049", c_Estado: "JAL", label: "Jilotlán de los Dolores" },
    { value: "049", c_Estado: "MEX", label: "Joquicingo" },
    { value: "049", c_Estado: "MIC", label: "Madero" },
    { value: "049", c_Estado: "NLE", label: "Santiago" },
    { value: "049", c_Estado: "OAX", label: "Magdalena Ocotlán" },
    { value: "049", c_Estado: "PUE", label: "Chiconcuautla" },
    { value: "049", c_Estado: "SLP", label: "Villa de Ramos" },
    { value: "049", c_Estado: "SON", label: "Quiriego" },
    { value: "049", c_Estado: "TLA", label: "San Damián Texóloc" },
    { value: "049", c_Estado: "VER", label: "Cotaxtla" },
    { value: "049", c_Estado: "YUC", label: "Mayapán" },
    { value: "049", c_Estado: "ZAC", label: "Valparaíso" },
    { value: "050", c_Estado: "CHH", label: "Nuevo Casas Grandes" },
    { value: "050", c_Estado: "CHP", label: "La Libertad" },
    { value: "050", c_Estado: "GRO", label: "Pungarabato" },
    { value: "050", c_Estado: "HID", label: "Progreso de Obregón" },
    { value: "050", c_Estado: "JAL", label: "Jocotepec" },
    { value: "050", c_Estado: "MEX", label: "Juchitepec" },
    { value: "050", c_Estado: "MIC", label: "Maravatío" },
    { value: "050", c_Estado: "NLE", label: "Vallecillo" },
    { value: "050", c_Estado: "OAX", label: "Magdalena Peñasco" },
    { value: "050", c_Estado: "PUE", label: "Chichiquila" },
    { value: "050", c_Estado: "SLP", label: "Villa de Reyes" },
    { value: "050", c_Estado: "SON", label: "Rayón" },
    { value: "050", c_Estado: "TLA", label: "San Francisco Tetlanohcan" },
    { value: "050", c_Estado: "VER", label: "Coxquihui" },
    { value: "050", c_Estado: "YUC", label: "Mérida" },
    { value: "050", c_Estado: "ZAC", label: "Vetagrande" },
    { value: "051", c_Estado: "CHH", label: "Ocampo" },
    { value: "051", c_Estado: "CHP", label: "Mapastepec" },
    { value: "051", c_Estado: "GRO", label: "Quechultenango" },
    { value: "051", c_Estado: "HID", label: "Mineral de la Reforma" },
    { value: "051", c_Estado: "JAL", label: "Juanacatlán" },
    { value: "051", c_Estado: "MEX", label: "Lerma" },
    { value: "051", c_Estado: "MIC", label: "Marcos Castellanos" },
    { value: "051", c_Estado: "NLE", label: "Villaldama" },
    { value: "051", c_Estado: "OAX", label: "Magdalena Teitipac" },
    { value: "051", c_Estado: "PUE", label: "Chietla" },
    { value: "051", c_Estado: "SLP", label: "Villa Hidalgo" },
    { value: "051", c_Estado: "SON", label: "Rosario" },
    { value: "051", c_Estado: "TLA", label: "San Jerónimo Zacualpan" },
    { value: "051", c_Estado: "VER", label: "Coyutla" },
    { value: "051", c_Estado: "YUC", label: "Mocochá" },
    { value: "051", c_Estado: "ZAC", label: "Villa de Cos" },
    { value: "052", c_Estado: "CHH", label: "Ojinaga" },
    { value: "052", c_Estado: "CHP", label: "Las Margaritas" },
    { value: "052", c_Estado: "GRO", label: "San Luis Acatlán" },
    { value: "052", c_Estado: "HID", label: "San Agustín Tlaxiaca" },
    { value: "052", c_Estado: "JAL", label: "Juchitlán" },
    { value: "052", c_Estado: "MEX", label: "Malinalco" },
    { value: "052", c_Estado: "MIC", label: "Lázaro Cárdenas" },
    { value: "052", c_Estado: "OAX", label: "Magdalena Tequisistlán" },
    { value: "052", c_Estado: "PUE", label: "Chigmecatitlán" },
    { value: "052", c_Estado: "SLP", label: "Villa Juárez" },
    { value: "052", c_Estado: "SON", label: "Sahuaripa" },
    { value: "052", c_Estado: "TLA", label: "San José Teacalco" },
    { value: "052", c_Estado: "VER", label: "Cuichapa" },
    { value: "052", c_Estado: "YUC", label: "Motul" },
    { value: "052", c_Estado: "ZAC", label: "Villa García" },
    { value: "053", c_Estado: "CHH", label: "Praxedis G. Guerrero" },
    { value: "053", c_Estado: "CHP", label: "Mazapa de Madero" },
    { value: "053", c_Estado: "GRO", label: "San Marcos" },
    { value: "053", c_Estado: "HID", label: "San Bartolo Tutotepec" },
    { value: "053", c_Estado: "JAL", label: "Lagos de Moreno" },
    { value: "053", c_Estado: "MEX", label: "Melchor Ocampo" },
    { value: "053", c_Estado: "MIC", label: "Morelia" },
    { value: "053", c_Estado: "OAX", label: "Magdalena Tlacotepec" },
    { value: "053", c_Estado: "PUE", label: "Chignahuapan" },
    { value: "053", c_Estado: "SLP", label: "Axtla de Terrazas" },
    { value: "053", c_Estado: "SON", label: "San Felipe de Jesús" },
    { value: "053", c_Estado: "TLA", label: "San Juan Huactzinco" },
    { value: "053", c_Estado: "VER", label: "Cuitláhuac" },
    { value: "053", c_Estado: "YUC", label: "Muna" },
    { value: "053", c_Estado: "ZAC", label: "Villa González Ortega" },
    { value: "054", c_Estado: "CHH", label: "Riva Palacio" },
    { value: "054", c_Estado: "CHP", label: "Mazatán" },
    { value: "054", c_Estado: "GRO", label: "San Miguel Totolapan" },
    { value: "054", c_Estado: "HID", label: "San Salvador" },
    { value: "054", c_Estado: "JAL", label: "El Limón" },
    { value: "054", c_Estado: "MEX", label: "Metepec" },
    { value: "054", c_Estado: "MIC", label: "Morelos" },
    { value: "054", c_Estado: "OAX", label: "Magdalena Zahuatlán" },
    { value: "054", c_Estado: "PUE", label: "Chignautla" },
    { value: "054", c_Estado: "SLP", label: "Xilitla" },
    { value: "054", c_Estado: "SON", label: "San Javier" },
    { value: "054", c_Estado: "TLA", label: "San Lorenzo Axocomanitla" },
    { value: "054", c_Estado: "VER", label: "Chacaltianguis" },
    { value: "054", c_Estado: "YUC", label: "Muxupip" },
    { value: "054", c_Estado: "ZAC", label: "Villa Hidalgo" },
    { value: "055", c_Estado: "CHH", label: "Rosales" },
    { value: "055", c_Estado: "CHP", label: "Metapa" },
    { value: "055", c_Estado: "GRO", label: "Taxco de Alarcón" },
    { value: "055", c_Estado: "HID", label: "Santiago de Anaya" },
    { value: "055", c_Estado: "JAL", label: "Magdalena" },
    { value: "055", c_Estado: "MEX", label: "Mexicaltzingo" },
    { value: "055", c_Estado: "MIC", label: "Múgica" },
    { value: "055", c_Estado: "OAX", label: "Mariscala de Juárez" },
    { value: "055", c_Estado: "PUE", label: "Chila" },
    { value: "055", c_Estado: "SLP", label: "Zaragoza" },
    { value: "055", c_Estado: "SON", label: "San Luis Río Colorado" },
    { value: "055", c_Estado: "TLA", label: "San Lucas Tecopilco" },
    { value: "055", c_Estado: "VER", label: "Chalma" },
    { value: "055", c_Estado: "YUC", label: "Opichén" },
    { value: "055", c_Estado: "ZAC", label: "Villanueva" },
    { value: "056", c_Estado: "CHH", label: "Rosario" },
    { value: "056", c_Estado: "CHP", label: "Mitontic" },
    { value: "056", c_Estado: "GRO", label: "Tecoanapa" },
    { value: "056", c_Estado: "HID", label: "Santiago Tulantepec de Lugo Guerrero" },
    { value: "056", c_Estado: "JAL", label: "Santa María del Oro" },
    { value: "056", c_Estado: "MEX", label: "Morelos" },
    { value: "056", c_Estado: "MIC", label: "Nahuatzen" },
    { value: "056", c_Estado: "OAX", label: "Mártires de Tacubaya" },
    { value: "056", c_Estado: "PUE", label: "Chila de la Sal" },
    { value: "056", c_Estado: "SLP", label: "Villa de Arista" },
    { value: "056", c_Estado: "SON", label: "San Miguel de Horcasitas" },
    { value: "056", c_Estado: "TLA", label: "Santa Ana Nopalucan" },
    { value: "056", c_Estado: "VER", label: "Chiconamel" },
    { value: "056", c_Estado: "YUC", label: "Oxkutzcab" },
    { value: "056", c_Estado: "ZAC", label: "Zacatecas" },
    { value: "057", c_Estado: "CHH", label: "San Francisco de Borja" },
    { value: "057", c_Estado: "CHP", label: "Motozintla" },
    { value: "057", c_Estado: "GRO", label: "Técpan de Galeana" },
    { value: "057", c_Estado: "HID", label: "Singuilucan" },
    { value: "057", c_Estado: "JAL", label: "La Manzanilla de la Paz" },
    { value: "057", c_Estado: "MEX", label: "Naucalpan de Juárez" },
    { value: "057", c_Estado: "MIC", label: "Nocupétaro" },
    { value: "057", c_Estado: "OAX", label: "Matías Romero Avendaño" },
    { value: "057", c_Estado: "PUE", label: "Honey" },
    { value: "057", c_Estado: "SLP", label: "Matlapa" },
    { value: "057", c_Estado: "SON", label: "San Pedro de la Cueva" },
    { value: "057", c_Estado: "TLA", label: "Santa Apolonia Teacalco" },
    { value: "057", c_Estado: "VER", label: "Chiconquiaco" },
    { value: "057", c_Estado: "YUC", label: "Panabá" },
    { value: "057", c_Estado: "ZAC", label: "Trancoso" },
    { value: "058", c_Estado: "CHH", label: "San Francisco de Conchos" },
    { value: "058", c_Estado: "CHP", label: "Nicolás Ruíz" },
    { value: "058", c_Estado: "GRO", label: "Teloloapan" },
    { value: "058", c_Estado: "HID", label: "Tasquillo" },
    { value: "058", c_Estado: "JAL", label: "Mascota" },
    { value: "058", c_Estado: "MEX", label: "Nezahualcóyotl" },
    { value: "058", c_Estado: "MIC", label: "Nuevo Parangaricutiro" },
    { value: "058", c_Estado: "OAX", label: "Mazatlán Villa de Flores" },
    { value: "058", c_Estado: "PUE", label: "Chilchotla" },
    { value: "058", c_Estado: "SLP", label: "El Naranjo" },
    { value: "058", c_Estado: "SON", label: "Santa Ana" },
    { value: "058", c_Estado: "TLA", label: "Santa Catarina Ayometla" },
    { value: "058", c_Estado: "VER", label: "Chicontepec" },
    { value: "058", c_Estado: "YUC", label: "Peto" },
    { value: "058", c_Estado: "ZAC", label: "Santa María de la Paz" },
    { value: "059", c_Estado: "CHH", label: "San Francisco del Oro" },
    { value: "059", c_Estado: "CHP", label: "Ocosingo" },
    { value: "059", c_Estado: "GRO", label: "Tepecoacuilco de Trujano" },
    { value: "059", c_Estado: "HID", label: "Tecozautla" },
    { value: "059", c_Estado: "JAL", label: "Mazamitla" },
    { value: "059", c_Estado: "MEX", label: "Nextlalpan" },
    { value: "059", c_Estado: "MIC", label: "Nuevo Urecho" },
    { value: "059", c_Estado: "OAX", label: "Miahuatlán de Porfirio Díaz" },
    { value: "059", c_Estado: "PUE", label: "Chinantla" },
    { value: "059", c_Estado: "SON", label: "Santa Cruz" },
    { value: "059", c_Estado: "TLA", label: "Santa Cruz Quilehtla" },
    { value: "059", c_Estado: "VER", label: "Chinameca" },
    { value: "059", c_Estado: "YUC", label: "Progreso" },
    { value: "060", c_Estado: "CHH", label: "Santa Bárbara" },
    { value: "060", c_Estado: "CHP", label: "Ocotepec" },
    { value: "060", c_Estado: "GRO", label: "Tetipac" },
    { value: "060", c_Estado: "HID", label: "Tenango de Doria" },
    { value: "060", c_Estado: "JAL", label: "Mexticacán" },
    { value: "060", c_Estado: "MEX", label: "Nicolás Romero" },
    { value: "060", c_Estado: "MIC", label: "Numarán" },
    { value: "060", c_Estado: "OAX", label: "Mixistlán de la Reforma" },
    { value: "060", c_Estado: "PUE", label: "Domingo Arenas" },
    { value: "060", c_Estado: "SON", label: "Sáric" },
    { value: "060", c_Estado: "TLA", label: "Santa Isabel Xiloxoxtla" },
    { value: "060", c_Estado: "VER", label: "Chinampa de Gorostiza" },
    { value: "060", c_Estado: "YUC", label: "Quintana Roo" },
    { value: "061", c_Estado: "CHH", label: "Satevó" },
    { value: "061", c_Estado: "CHP", label: "Ocozocoautla de Espinosa" },
    { value: "061", c_Estado: "GRO", label: "Tixtla de Guerrero" },
    { value: "061", c_Estado: "HID", label: "Tepeapulco" },
    { value: "061", c_Estado: "JAL", label: "Mezquitic" },
    { value: "061", c_Estado: "MEX", label: "Nopaltepec" },
    { value: "061", c_Estado: "MIC", label: "Ocampo" },
    { value: "061", c_Estado: "OAX", label: "Monjas" },
    { value: "061", c_Estado: "PUE", label: "Eloxochitlán" },
    { value: "061", c_Estado: "SON", label: "Soyopa" },
    { value: "061", c_Estado: "VER", label: "Las Choapas" },
    { value: "061", c_Estado: "YUC", label: "Río Lagartos" },
    { value: "062", c_Estado: "CHH", label: "Saucillo" },
    { value: "062", c_Estado: "CHP", label: "Ostuacán" },
    { value: "062", c_Estado: "GRO", label: "Tlacoachistlahuaca" },
    { value: "062", c_Estado: "HID", label: "Tepehuacán de Guerrero" },
    { value: "062", c_Estado: "JAL", label: "Mixtlán" },
    { value: "062", c_Estado: "MEX", label: "Ocoyoacac" },
    { value: "062", c_Estado: "MIC", label: "Pajacuarán" },
    { value: "062", c_Estado: "OAX", label: "Natividad" },
    { value: "062", c_Estado: "PUE", label: "Epatlán" },
    { value: "062", c_Estado: "SON", label: "Suaqui Grande" },
    { value: "062", c_Estado: "VER", label: "Chocamán" },
    { value: "062", c_Estado: "YUC", label: "Sacalum" },
    { value: "063", c_Estado: "CHH", label: "Temósachic" },
    { value: "063", c_Estado: "CHP", label: "Osumacinta" },
    { value: "063", c_Estado: "GRO", label: "Tlacoapa" },
    { value: "063", c_Estado: "HID", label: "Tepeji del Río de Ocampo" },
    { value: "063", c_Estado: "JAL", label: "Ocotlán" },
    { value: "063", c_Estado: "MEX", label: "Ocuilan" },
    { value: "063", c_Estado: "MIC", label: "Panindícuaro" },
    { value: "063", c_Estado: "OAX", label: "Nazareno Etla" },
    { value: "063", c_Estado: "PUE", label: "Esperanza" },
    { value: "063", c_Estado: "SON", label: "Tepache" },
    { value: "063", c_Estado: "VER", label: "Chontla" },
    { value: "063", c_Estado: "YUC", label: "Samahil" },
    { value: "064", c_Estado: "CHH", label: "El Tule" },
    { value: "064", c_Estado: "CHP", label: "Oxchuc" },
    { value: "064", c_Estado: "GRO", label: "Tlalchapa" },
    { value: "064", c_Estado: "HID", label: "Tepetitlán" },
    { value: "064", c_Estado: "JAL", label: "Ojuelos de Jalisco" },
    { value: "064", c_Estado: "MEX", label: "El Oro" },
    { value: "064", c_Estado: "MIC", label: "Parácuaro" },
    { value: "064", c_Estado: "OAX", label: "Nejapa de Madero" },
    { value: "064", c_Estado: "PUE", label: "Francisco Z. Mena" },
    { value: "064", c_Estado: "SON", label: "Trincheras" },
    { value: "064", c_Estado: "VER", label: "Chumatlán" },
    { value: "064", c_Estado: "YUC", label: "Sanahcat" },
    { value: "065", c_Estado: "CHH", label: "Urique" },
    { value: "065", c_Estado: "CHP", label: "Palenque" },
    { value: "065", c_Estado: "GRO", label: "Tlalixtaquilla de Maldonado" },
    { value: "065", c_Estado: "HID", label: "Tetepango" },
    { value: "065", c_Estado: "JAL", label: "Pihuamo" },
    { value: "065", c_Estado: "MEX", label: "Otumba" },
    { value: "065", c_Estado: "MIC", label: "Paracho" },
    { value: "065", c_Estado: "OAX", label: "Ixpantepec Nieves" },
    { value: "065", c_Estado: "PUE", label: "General Felipe Ángeles" },
    { value: "065", c_Estado: "SON", label: "Tubutama" },
    { value: "065", c_Estado: "VER", label: "Emiliano Zapata" },
    { value: "065", c_Estado: "YUC", label: "San Felipe" },
    { value: "066", c_Estado: "CHH", label: "Uruachi" },
    { value: "066", c_Estado: "CHP", label: "Pantelhó" },
    { value: "066", c_Estado: "GRO", label: "Tlapa de Comonfort" },
    { value: "066", c_Estado: "HID", label: "Villa de Tezontepec" },
    { value: "066", c_Estado: "JAL", label: "Poncitlán" },
    { value: "066", c_Estado: "MEX", label: "Otzoloapan" },
    { value: "066", c_Estado: "MIC", label: "Pátzcuaro" },
    { value: "066", c_Estado: "OAX", label: "Santiago Niltepec" },
    { value: "066", c_Estado: "PUE", label: "Guadalupe" },
    { value: "066", c_Estado: "SON", label: "Ures" },
    { value: "066", c_Estado: "VER", label: "Espinal" },
    { value: "066", c_Estado: "YUC", label: "Santa Elena" },
    { value: "067", c_Estado: "CHH", label: "Valle de Zaragoza" },
    { value: "067", c_Estado: "CHP", label: "Pantepec" },
    { value: "067", c_Estado: "GRO", label: "Tlapehuala" },
    { value: "067", c_Estado: "HID", label: "Tezontepec de Aldama" },
    { value: "067", c_Estado: "JAL", label: "Puerto Vallarta" },
    { value: "067", c_Estado: "MEX", label: "Otzolotepec" },
    { value: "067", c_Estado: "MIC", label: "Penjamillo" },
    { value: "067", c_Estado: "OAX", label: "Oaxaca de Juárez" },
    { value: "067", c_Estado: "PUE", label: "Guadalupe Victoria" },
    { value: "067", c_Estado: "SON", label: "Villa Hidalgo" },
    { value: "067", c_Estado: "VER", label: "Filomeno Mata" },
    { value: "067", c_Estado: "YUC", label: "Seyé" },
    { value: "068", c_Estado: "CHP", label: "Pichucalco" },
    { value: "068", c_Estado: "GRO", label: "La Unión de Isidoro Montes de Oca" },
    { value: "068", c_Estado: "HID", label: "Tianguistengo" },
    { value: "068", c_Estado: "JAL", label: "Villa Purificación" },
    { value: "068", c_Estado: "MEX", label: "Ozumba" },
    { value: "068", c_Estado: "MIC", label: "Peribán" },
    { value: "068", c_Estado: "OAX", label: "Ocotlán de Morelos" },
    { value: "068", c_Estado: "PUE", label: "Hermenegildo Galeana" },
    { value: "068", c_Estado: "SON", label: "Villa Pesqueira" },
    { value: "068", c_Estado: "VER", label: "Fortín" },
    { value: "068", c_Estado: "YUC", label: "Sinanché" },
    { value: "069", c_Estado: "CHP", label: "Pijijiapan" },
    { value: "069", c_Estado: "GRO", label: "Xalpatláhuac" },
    { value: "069", c_Estado: "HID", label: "Tizayuca" },
    { value: "069", c_Estado: "JAL", label: "Quitupan" },
    { value: "069", c_Estado: "MEX", label: "Papalotla" },
    { value: "069", c_Estado: "MIC", label: "La Piedad" },
    { value: "069", c_Estado: "OAX", label: "La Pe" },
    { value: "069", c_Estado: "PUE", label: "Huaquechula" },
    { value: "069", c_Estado: "SON", label: "Yécora" },
    { value: "069", c_Estado: "VER", label: "Gutiérrez Zamora" },
    { value: "069", c_Estado: "YUC", label: "Sotuta" },
    { value: "070", c_Estado: "CHP", label: "El Porvenir" },
    { value: "070", c_Estado: "GRO", label: "Xochihuehuetlán" },
    { value: "070", c_Estado: "HID", label: "Tlahuelilpan" },
    { value: "070", c_Estado: "JAL", label: "El Salto" },
    { value: "070", c_Estado: "MEX", label: "La Paz" },
    { value: "070", c_Estado: "MIC", label: "Purépero" },
    { value: "070", c_Estado: "OAX", label: "Pinotepa de Don Luis" },
    { value: "070", c_Estado: "PUE", label: "Huatlatlauca" },
    { value: "070", c_Estado: "SON", label: "General Plutarco Elías Calles" },
    { value: "070", c_Estado: "VER", label: "Hidalgotitlán" },
    { value: "070", c_Estado: "YUC", label: "Sucilá" },
    { value: "071", c_Estado: "CHP", label: "Villa Comaltitlán" },
    { value: "071", c_Estado: "GRO", label: "Xochistlahuaca" },
    { value: "071", c_Estado: "HID", label: "Tlahuiltepa" },
    { value: "071", c_Estado: "JAL", label: "San Cristóbal de la Barranca" },
    { value: "071", c_Estado: "MEX", label: "Polotitlán" },
    { value: "071", c_Estado: "MIC", label: "Puruándiro" },
    { value: "071", c_Estado: "OAX", label: "Pluma Hidalgo" },
    { value: "071", c_Estado: "PUE", label: "Huauchinango" },
    { value: "071", c_Estado: "SON", label: "Benito Juárez" },
    { value: "071", c_Estado: "VER", label: "Huatusco" },
    { value: "071", c_Estado: "YUC", label: "Sudzal" },
    { value: "072", c_Estado: "CHP", label: "Pueblo Nuevo Solistahuacán" },
    { value: "072", c_Estado: "GRO", label: "Zapotitlán Tablas" },
    { value: "072", c_Estado: "HID", label: "Tlanalapa" },
    { value: "072", c_Estado: "JAL", label: "San Diego de Alejandría" },
    { value: "072", c_Estado: "MEX", label: "Rayón" },
    { value: "072", c_Estado: "MIC", label: "Queréndaro" },
    { value: "072", c_Estado: "OAX", label: "San José del Progreso" },
    { value: "072", c_Estado: "PUE", label: "Huehuetla" },
    { value: "072", c_Estado: "SON", label: "San Ignacio Río Muerto" },
    { value: "072", c_Estado: "VER", label: "Huayacocotla" },
    { value: "072", c_Estado: "YUC", label: "Suma" },
    { value: "073", c_Estado: "CHP", label: "Rayón" },
    { value: "073", c_Estado: "GRO", label: "Zirándaro" },
    { value: "073", c_Estado: "HID", label: "Tlanchinol" },
    { value: "073", c_Estado: "JAL", label: "San Juan de los Lagos" },
    { value: "073", c_Estado: "MEX", label: "San Antonio la Isla" },
    { value: "073", c_Estado: "MIC", label: "Quiroga" },
    { value: "073", c_Estado: "OAX", label: "Putla Villa de Guerrero" },
    { value: "073", c_Estado: "PUE", label: "Huehuetlán el Chico" },
    { value: "073", c_Estado: "VER", label: "Hueyapan de Ocampo" },
    { value: "073", c_Estado: "YUC", label: "Tahdziú" },
    { value: "074", c_Estado: "CHP", label: "Reforma" },
    { value: "074", c_Estado: "GRO", label: "Zitlala" },
    { value: "074", c_Estado: "HID", label: "Tlaxcoapan" },
    { value: "074", c_Estado: "JAL", label: "San Julián" },
    { value: "074", c_Estado: "MEX", label: "San Felipe del Progreso" },
    { value: "074", c_Estado: "MIC", label: "Cojumatlán de Régules" },
    { value: "074", c_Estado: "OAX", label: "Santa Catarina Quioquitani" },
    { value: "074", c_Estado: "PUE", label: "Huejotzingo" },
    { value: "074", c_Estado: "VER", label: "Huiloapan de Cuauhtémoc" },
    { value: "074", c_Estado: "YUC", label: "Tahmek" },
    { value: "075", c_Estado: "CHP", label: "Las Rosas" },
    { value: "075", c_Estado: "GRO", label: "Eduardo Neri" },
    { value: "075", c_Estado: "HID", label: "Tolcayuca" },
    { value: "075", c_Estado: "JAL", label: "San Marcos" },
    { value: "075", c_Estado: "MEX", label: "San Martín de las Pirámides" },
    { value: "075", c_Estado: "MIC", label: "Los Reyes" },
    { value: "075", c_Estado: "OAX", label: "Reforma de Pineda" },
    { value: "075", c_Estado: "PUE", label: "Hueyapan" },
    { value: "075", c_Estado: "VER", label: "Ignacio de la Llave" },
    { value: "075", c_Estado: "YUC", label: "Teabo" },
    { value: "076", c_Estado: "CHP", label: "Sabanilla" },
    { value: "076", c_Estado: "GRO", label: "Acatepec" },
    { value: "076", c_Estado: "HID", label: "Tula de Allende" },
    { value: "076", c_Estado: "JAL", label: "San Martín de Bolaños" },
    { value: "076", c_Estado: "MEX", label: "San Mateo Atenco" },
    { value: "076", c_Estado: "MIC", label: "Sahuayo" },
    { value: "076", c_Estado: "OAX", label: "La Reforma" },
    { value: "076", c_Estado: "PUE", label: "Hueytamalco" },
    { value: "076", c_Estado: "VER", label: "Ilamatlán" },
    { value: "076", c_Estado: "YUC", label: "Tecoh" },
    { value: "077", c_Estado: "CHP", label: "Salto de Agua" },
    { value: "077", c_Estado: "GRO", label: "Marquelia" },
    { value: "077", c_Estado: "HID", label: "Tulancingo de Bravo" },
    { value: "077", c_Estado: "JAL", label: "San Martín Hidalgo" },
    { value: "077", c_Estado: "MEX", label: "San Simón de Guerrero" },
    { value: "077", c_Estado: "MIC", label: "San Lucas" },
    { value: "077", c_Estado: "OAX", label: "Reyes Etla" },
    { value: "077", c_Estado: "PUE", label: "Hueytlalpan" },
    { value: "077", c_Estado: "VER", label: "Isla" },
    { value: "077", c_Estado: "YUC", label: "Tekal de Venegas" },
    { value: "078", c_Estado: "CHP", label: "San Cristóbal de las Casas" },
    { value: "078", c_Estado: "GRO", label: "Cochoapa el Grande" },
    { value: "078", c_Estado: "HID", label: "Xochiatipan" },
    { value: "078", c_Estado: "JAL", label: "San Miguel el Alto" },
    { value: "078", c_Estado: "MEX", label: "Santo Tomás" },
    { value: "078", c_Estado: "MIC", label: "Santa Ana Maya" },
    { value: "078", c_Estado: "OAX", label: "Rojas de Cuauhtémoc" },
    { value: "078", c_Estado: "PUE", label: "Huitzilan de Serdán" },
    { value: "078", c_Estado: "VER", label: "Ixcatepec" },
    { value: "078", c_Estado: "YUC", label: "Tekantó" },
    { value: "079", c_Estado: "CHP", label: "San Fernando" },
    { value: "079", c_Estado: "GRO", label: "José Joaquín de Herrera" },
    { value: "079", c_Estado: "HID", label: "Xochicoatlán" },
    { value: "079", c_Estado: "JAL", label: "Gómez Farías" },
    { value: "079", c_Estado: "MEX", label: "Soyaniquilpan de Juárez" },
    { value: "079", c_Estado: "MIC", label: "Salvador Escalante" },
    { value: "079", c_Estado: "OAX", label: "Salina Cruz" },
    { value: "079", c_Estado: "PUE", label: "Huitziltepec" },
    { value: "079", c_Estado: "VER", label: "Ixhuacán de los Reyes" },
    { value: "079", c_Estado: "YUC", label: "Tekax" },
    { value: "080", c_Estado: "CHP", label: "Siltepec" },
    { value: "080", c_Estado: "GRO", label: "Juchitán" },
    { value: "080", c_Estado: "HID", label: "Yahualica" },
    { value: "080", c_Estado: "JAL", label: "San Sebastián del Oeste" },
    { value: "080", c_Estado: "MEX", label: "Sultepec" },
    { value: "080", c_Estado: "MIC", label: "Senguio" },
    { value: "080", c_Estado: "OAX", label: "San Agustín Amatengo" },
    { value: "080", c_Estado: "PUE", label: "Atlequizayan" },
    { value: "080", c_Estado: "VER", label: "Ixhuatlán del Café" },
    { value: "080", c_Estado: "YUC", label: "Tekit" },
    { value: "081", c_Estado: "CHP", label: "Simojovel" },
    { value: "081", c_Estado: "GRO", label: "Iliatenco" },
    { value: "081", c_Estado: "HID", label: "Zacualtipán de Ángeles" },
    { value: "081", c_Estado: "JAL", label: "Santa María de los Ángeles" },
    { value: "081", c_Estado: "MEX", label: "Tecámac" },
    { value: "081", c_Estado: "MIC", label: "Susupuato" },
    { value: "081", c_Estado: "OAX", label: "San Agustín Atenango" },
    { value: "081", c_Estado: "PUE", label: "Ixcamilpa de Guerrero" },
    { value: "081", c_Estado: "VER", label: "Ixhuatlancillo" },
    { value: "081", c_Estado: "YUC", label: "Tekom" },
    { value: "082", c_Estado: "CHP", label: "Sitalá" },
    { value: "082", c_Estado: "HID", label: "Zapotlán de Juárez" },
    { value: "082", c_Estado: "JAL", label: "Sayula" },
    { value: "082", c_Estado: "MEX", label: "Tejupilco" },
    { value: "082", c_Estado: "MIC", label: "Tacámbaro" },
    { value: "082", c_Estado: "OAX", label: "San Agustín Chayuco" },
    { value: "082", c_Estado: "PUE", label: "Ixcaquixtla" },
    { value: "082", c_Estado: "VER", label: "Ixhuatlán del Sureste" },
    { value: "082", c_Estado: "YUC", label: "Telchac Pueblo" },
    { value: "083", c_Estado: "CHP", label: "Socoltenango" },
    { value: "083", c_Estado: "HID", label: "Zempoala" },
    { value: "083", c_Estado: "JAL", label: "Tala" },
    { value: "083", c_Estado: "MEX", label: "Temamatla" },
    { value: "083", c_Estado: "MIC", label: "Tancítaro" },
    { value: "083", c_Estado: "OAX", label: "San Agustín de las Juntas" },
    { value: "083", c_Estado: "PUE", label: "Ixtacamaxtitlán" },
    { value: "083", c_Estado: "VER", label: "Ixhuatlán de Madero" },
    { value: "083", c_Estado: "YUC", label: "Telchac Puerto" },
    { value: "084", c_Estado: "CHP", label: "Solosuchiapa" },
    { value: "084", c_Estado: "HID", label: "Zimapán" },
    { value: "084", c_Estado: "JAL", label: "Talpa de Allende" },
    { value: "084", c_Estado: "MEX", label: "Temascalapa" },
    { value: "084", c_Estado: "MIC", label: "Tangamandapio" },
    { value: "084", c_Estado: "OAX", label: "San Agustín Etla" },
    { value: "084", c_Estado: "PUE", label: "Ixtepec" },
    { value: "084", c_Estado: "VER", label: "Ixmatlahuacan" },
    { value: "084", c_Estado: "YUC", label: "Temax" },
    { value: "085", c_Estado: "CHP", label: "Soyaló" },
    { value: "085", c_Estado: "JAL", label: "Tamazula de Gordiano" },
    { value: "085", c_Estado: "MEX", label: "Temascalcingo" },
    { value: "085", c_Estado: "MIC", label: "Tangancícuaro" },
    { value: "085", c_Estado: "OAX", label: "San Agustín Loxicha" },
    { value: "085", c_Estado: "PUE", label: "Izúcar de Matamoros" },
    { value: "085", c_Estado: "VER", label: "Ixtaczoquitlán" },
    { value: "085", c_Estado: "YUC", label: "Temozón" },
    { value: "086", c_Estado: "CHP", label: "Suchiapa" },
    { value: "086", c_Estado: "JAL", label: "Tapalpa" },
    { value: "086", c_Estado: "MEX", label: "Temascaltepec" },
    { value: "086", c_Estado: "MIC", label: "Tanhuato" },
    { value: "086", c_Estado: "OAX", label: "San Agustín Tlacotepec" },
    { value: "086", c_Estado: "PUE", label: "Jalpan" },
    { value: "086", c_Estado: "VER", label: "Jalacingo" },
    { value: "086", c_Estado: "YUC", label: "Tepakán" },
    { value: "087", c_Estado: "CHP", label: "Suchiate" },
    { value: "087", c_Estado: "JAL", label: "Tecalitlán" },
    { value: "087", c_Estado: "MEX", label: "Temoaya" },
    { value: "087", c_Estado: "MIC", label: "Taretan" },
    { value: "087", c_Estado: "OAX", label: "San Agustín Yatareni" },
    { value: "087", c_Estado: "PUE", label: "Jolalpan" },
    { value: "087", c_Estado: "VER", label: "Xalapa" },
    { value: "087", c_Estado: "YUC", label: "Tetiz" },
    { value: "088", c_Estado: "CHP", label: "Sunuapa" },
    { value: "088", c_Estado: "JAL", label: "Tecolotlán" },
    { value: "088", c_Estado: "MEX", label: "Tenancingo" },
    { value: "088", c_Estado: "MIC", label: "Tarímbaro" },
    { value: "088", c_Estado: "OAX", label: "San Andrés Cabecera Nueva" },
    { value: "088", c_Estado: "PUE", label: "Jonotla" },
    { value: "088", c_Estado: "VER", label: "Jalcomulco" },
    { value: "088", c_Estado: "YUC", label: "Teya" },
    { value: "089", c_Estado: "CHP", label: "Tapachula" },
    { value: "089", c_Estado: "JAL", label: "Techaluta de Montenegro" },
    { value: "089", c_Estado: "MEX", label: "Tenango del Aire" },
    { value: "089", c_Estado: "MIC", label: "Tepalcatepec" },
    { value: "089", c_Estado: "OAX", label: "San Andrés Dinicuiti" },
    { value: "089", c_Estado: "PUE", label: "Jopala" },
    { value: "089", c_Estado: "VER", label: "Jáltipan" },
    { value: "089", c_Estado: "YUC", label: "Ticul" },
    { value: "090", c_Estado: "CHP", label: "Tapalapa" },
    { value: "090", c_Estado: "JAL", label: "Tenamaxtlán" },
    { value: "090", c_Estado: "MEX", label: "Tenango del Valle" },
    { value: "090", c_Estado: "MIC", label: "Tingambato" },
    { value: "090", c_Estado: "OAX", label: "San Andrés Huaxpaltepec" },
    { value: "090", c_Estado: "PUE", label: "Juan C. Bonilla" },
    { value: "090", c_Estado: "VER", label: "Jamapa" },
    { value: "090", c_Estado: "YUC", label: "Timucuy" },
    { value: "091", c_Estado: "CHP", label: "Tapilula" },
    { value: "091", c_Estado: "JAL", label: "Teocaltiche" },
    { value: "091", c_Estado: "MEX", label: "Teoloyucan" },
    { value: "091", c_Estado: "MIC", label: "Tingüindín" },
    { value: "091", c_Estado: "OAX", label: "San Andrés Huayápam" },
    { value: "091", c_Estado: "PUE", label: "Juan Galindo" },
    { value: "091", c_Estado: "VER", label: "Jesús Carranza" },
    { value: "091", c_Estado: "YUC", label: "Tinum" },
    { value: "092", c_Estado: "CHP", label: "Tecpatán" },
    { value: "092", c_Estado: "JAL", label: "Teocuitatlán de Corona" },
    { value: "092", c_Estado: "MEX", label: "Teotihuacán" },
    { value: "092", c_Estado: "MIC", label: "Tiquicheo de Nicolás Romero" },
    { value: "092", c_Estado: "OAX", label: "San Andrés Ixtlahuaca" },
    { value: "092", c_Estado: "PUE", label: "Juan N. Méndez" },
    { value: "092", c_Estado: "VER", label: "Xico" },
    { value: "092", c_Estado: "YUC", label: "Tixcacalcupul" },
    { value: "093", c_Estado: "CHP", label: "Tenejapa" },
    { value: "093", c_Estado: "JAL", label: "Tepatitlán de Morelos" },
    { value: "093", c_Estado: "MEX", label: "Tepetlaoxtoc" },
    { value: "093", c_Estado: "MIC", label: "Tlalpujahua" },
    { value: "093", c_Estado: "OAX", label: "San Andrés Lagunas" },
    { value: "093", c_Estado: "PUE", label: "Lafragua" },
    { value: "093", c_Estado: "VER", label: "Jilotepec" },
    { value: "093", c_Estado: "YUC", label: "Tixkokob" },
    { value: "094", c_Estado: "CHP", label: "Teopisca" },
    { value: "094", c_Estado: "JAL", label: "Tequila" },
    { value: "094", c_Estado: "MEX", label: "Tepetlixpa" },
    { value: "094", c_Estado: "MIC", label: "Tlazazalca" },
    { value: "094", c_Estado: "OAX", label: "San Andrés Nuxiño" },
    { value: "094", c_Estado: "PUE", label: "Libres" },
    { value: "094", c_Estado: "VER", label: "Juan Rodríguez Clara" },
    { value: "094", c_Estado: "YUC", label: "Tixmehuac" },
    { value: "095", c_Estado: "JAL", label: "Teuchitlán" },
    { value: "095", c_Estado: "MEX", label: "Tepotzotlán" },
    { value: "095", c_Estado: "MIC", label: "Tocumbo" },
    { value: "095", c_Estado: "OAX", label: "San Andrés Paxtlán" },
    { value: "095", c_Estado: "PUE", label: "La Magdalena Tlatlauquitepec" },
    { value: "095", c_Estado: "VER", label: "Juchique de Ferrer" },
    { value: "095", c_Estado: "YUC", label: "Tixpéhual" },
    { value: "096", c_Estado: "CHP", label: "Tila" },
    { value: "096", c_Estado: "JAL", label: "Tizapán el Alto" },
    { value: "096", c_Estado: "MEX", label: "Tequixquiac" },
    { value: "096", c_Estado: "MIC", label: "Tumbiscatío" },
    { value: "096", c_Estado: "OAX", label: "San Andrés Sinaxtla" },
    { value: "096", c_Estado: "PUE", label: "Mazapiltepec de Juárez" },
    { value: "096", c_Estado: "VER", label: "Landero y Coss" },
    { value: "096", c_Estado: "YUC", label: "Tizimín" },
    { value: "097", c_Estado: "CHP", label: "Tonalá" },
    { value: "097", c_Estado: "JAL", label: "Tlajomulco de Zúñiga" },
    { value: "097", c_Estado: "MEX", label: "Texcaltitlán" },
    { value: "097", c_Estado: "MIC", label: "Turicato" },
    { value: "097", c_Estado: "OAX", label: "San Andrés Solaga" },
    { value: "097", c_Estado: "PUE", label: "Mixtla" },
    { value: "097", c_Estado: "VER", label: "Lerdo de Tejada" },
    { value: "097", c_Estado: "YUC", label: "Tunkás" },
    { value: "098", c_Estado: "CHP", label: "Totolapa" },
    { value: "098", c_Estado: "JAL", label: "San Pedro Tlaquepaque" },
    { value: "098", c_Estado: "MEX", label: "Texcalyacac" },
    { value: "098", c_Estado: "MIC", label: "Tuxpan" },
    { value: "098", c_Estado: "OAX", label: "San Andrés Teotilálpam" },
    { value: "098", c_Estado: "PUE", label: "Molcaxac" },
    { value: "098", c_Estado: "VER", label: "Magdalena" },
    { value: "098", c_Estado: "YUC", label: "Tzucacab" },
    { value: "099", c_Estado: "CHP", label: "La Trinitaria" },
    { value: "099", c_Estado: "JAL", label: "Tolimán" },
    { value: "099", c_Estado: "MEX", label: "Texcoco" },
    { value: "099", c_Estado: "MIC", label: "Tuzantla" },
    { value: "099", c_Estado: "OAX", label: "San Andrés Tepetlapa" },
    { value: "099", c_Estado: "PUE", label: "Cañada Morelos" },
    { value: "099", c_Estado: "VER", label: "Maltrata" },
    { value: "099", c_Estado: "YUC", label: "Uayma" },
    { value: "100", c_Estado: "CHP", label: "Tumbalá" },
    { value: "100", c_Estado: "JAL", label: "Tomatlán" },
    { value: "100", c_Estado: "MEX", label: "Tezoyuca" },
    { value: "100", c_Estado: "MIC", label: "Tzintzuntzan" },
    { value: "100", c_Estado: "OAX", label: "San Andrés Yaá" },
    { value: "100", c_Estado: "PUE", label: "Naupan" },
    { value: "100", c_Estado: "VER", label: "Manlio Fabio Altamirano" },
    { value: "100", c_Estado: "YUC", label: "Ucú" },
    { value: "101", c_Estado: "CHP", label: "Tuxtla Gutiérrez" },
    { value: "101", c_Estado: "JAL", label: "Tonalá" },
    { value: "101", c_Estado: "MEX", label: "Tianguistenco" },
    { value: "101", c_Estado: "MIC", label: "Tzitzio" },
    { value: "101", c_Estado: "OAX", label: "San Andrés Zabache" },
    { value: "101", c_Estado: "PUE", label: "Nauzontla" },
    { value: "101", c_Estado: "VER", label: "Mariano Escobedo" },
    { value: "101", c_Estado: "YUC", label: "Umán" },
    { value: "102", c_Estado: "CHP", label: "Tuxtla Chico" },
    { value: "102", c_Estado: "JAL", label: "Tonaya" },
    { value: "102", c_Estado: "MEX", label: "Timilpan" },
    { value: "102", c_Estado: "MIC", label: "Uruapan" },
    { value: "102", c_Estado: "OAX", label: "San Andrés Zautla" },
    { value: "102", c_Estado: "PUE", label: "Nealtican" },
    { value: "102", c_Estado: "VER", label: "Martínez de la Torre" },
    { value: "102", c_Estado: "YUC", label: "Valladolid" },
    { value: "103", c_Estado: "CHP", label: "Tuzantán" },
    { value: "103", c_Estado: "JAL", label: "Tonila" },
    { value: "103", c_Estado: "MEX", label: "Tlalmanalco" },
    { value: "103", c_Estado: "MIC", label: "Venustiano Carranza" },
    { value: "103", c_Estado: "OAX", label: "San Antonino Castillo Velasco" },
    { value: "103", c_Estado: "PUE", label: "Nicolás Bravo" },
    { value: "103", c_Estado: "VER", label: "Mecatlán" },
    { value: "103", c_Estado: "YUC", label: "Xocchel" },
    { value: "104", c_Estado: "CHP", label: "Tzimol" },
    { value: "104", c_Estado: "JAL", label: "Totatiche" },
    { value: "104", c_Estado: "MEX", label: "Tlalnepantla de Baz" },
    { value: "104", c_Estado: "MIC", label: "Villamar" },
    { value: "104", c_Estado: "OAX", label: "San Antonino el Alto" },
    { value: "104", c_Estado: "PUE", label: "Nopalucan" },
    { value: "104", c_Estado: "VER", label: "Mecayapan" },
    { value: "104", c_Estado: "YUC", label: "Yaxcabá" },
    { value: "105", c_Estado: "CHP", label: "Unión Juárez" },
    { value: "105", c_Estado: "JAL", label: "Tototlán" },
    { value: "105", c_Estado: "MEX", label: "Tlatlaya" },
    { value: "105", c_Estado: "MIC", label: "Vista Hermosa" },
    { value: "105", c_Estado: "OAX", label: "San Antonino Monte Verde" },
    { value: "105", c_Estado: "PUE", label: "Ocotepec" },
    { value: "105", c_Estado: "VER", label: "Medellín" },
    { value: "105", c_Estado: "YUC", label: "Yaxkukul" },
    { value: "106", c_Estado: "CHP", label: "Venustiano Carranza" },
    { value: "106", c_Estado: "JAL", label: "Tuxcacuesco" },
    { value: "106", c_Estado: "MEX", label: "Toluca" },
    { value: "106", c_Estado: "MIC", label: "Yurécuaro" },
    { value: "106", c_Estado: "OAX", label: "San Antonio Acutla" },
    { value: "106", c_Estado: "PUE", label: "Ocoyucan" },
    { value: "106", c_Estado: "VER", label: "Miahuatlán" },
    { value: "106", c_Estado: "YUC", label: "Yobaín" },
    { value: "107", c_Estado: "CHP", label: "Villa Corzo" },
    { value: "107", c_Estado: "JAL", label: "Tuxcueca" },
    { value: "107", c_Estado: "MEX", label: "Tonatico" },
    { value: "107", c_Estado: "MIC", label: "Zacapu" },
    { value: "107", c_Estado: "OAX", label: "San Antonio de la Cal" },
    { value: "107", c_Estado: "PUE", label: "Olintla" },
    { value: "107", c_Estado: "VER", label: "Las Minas" },
    { value: "108", c_Estado: "CHP", label: "Villaflores" },
    { value: "108", c_Estado: "JAL", label: "Tuxpan" },
    { value: "108", c_Estado: "MEX", label: "Tultepec" },
    { value: "108", c_Estado: "MIC", label: "Zamora" },
    { value: "108", c_Estado: "OAX", label: "San Antonio Huitepec" },
    { value: "108", c_Estado: "PUE", label: "Oriental" },
    { value: "108", c_Estado: "VER", label: "Minatitlán" },
    { value: "109", c_Estado: "CHP", label: "Yajalón" },
    { value: "109", c_Estado: "JAL", label: "Unión de San Antonio" },
    { value: "109", c_Estado: "MEX", label: "Tultitlán" },
    { value: "109", c_Estado: "MIC", label: "Zináparo" },
    { value: "109", c_Estado: "OAX", label: "San Antonio Nanahuatípam" },
    { value: "109", c_Estado: "PUE", label: "Pahuatlán" },
    { value: "109", c_Estado: "VER", label: "Misantla" },
    { value: "110", c_Estado: "CHP", label: "San Lucas" },
    { value: "110", c_Estado: "JAL", label: "Unión de Tula" },
    { value: "110", c_Estado: "MEX", label: "Valle de Bravo" },
    { value: "110", c_Estado: "MIC", label: "Zinapécuaro" },
    { value: "110", c_Estado: "OAX", label: "San Antonio Sinicahua" },
    { value: "110", c_Estado: "PUE", label: "Palmar de Bravo" },
    { value: "110", c_Estado: "VER", label: "Mixtla de Altamirano" },
    { value: "111", c_Estado: "CHP", label: "Zinacantán" },
    { value: "111", c_Estado: "JAL", label: "Valle de Guadalupe" },
    { value: "111", c_Estado: "MEX", label: "Villa de Allende" },
    { value: "111", c_Estado: "MIC", label: "Ziracuaretiro" },
    { value: "111", c_Estado: "OAX", label: "San Antonio Tepetlapa" },
    { value: "111", c_Estado: "PUE", label: "Pantepec" },
    { value: "111", c_Estado: "VER", label: "Moloacán" },
    { value: "112", c_Estado: "CHP", label: "San Juan Cancuc" },
    { value: "112", c_Estado: "JAL", label: "Valle de Juárez" },
    { value: "112", c_Estado: "MEX", label: "Villa del Carbón" },
    { value: "112", c_Estado: "MIC", label: "Zitácuaro" },
    { value: "112", c_Estado: "OAX", label: "San Baltazar Chichicápam" },
    { value: "112", c_Estado: "PUE", label: "Petlalcingo" },
    { value: "112", c_Estado: "VER", label: "Naolinco" },
    { value: "113", c_Estado: "CHP", label: "Aldama" },
    { value: "113", c_Estado: "JAL", label: "San Gabriel" },
    { value: "113", c_Estado: "MEX", label: "Villa Guerrero" },
    { value: "113", c_Estado: "MIC", label: "José Sixto Verduzco" },
    { value: "113", c_Estado: "OAX", label: "San Baltazar Loxicha" },
    { value: "113", c_Estado: "PUE", label: "Piaxtla" },
    { value: "113", c_Estado: "VER", label: "Naranjal" },
    { value: "114", c_Estado: "CHP", label: "Benemérito de las Américas" },
    { value: "114", c_Estado: "JAL", label: "Villa Corona" },
    { value: "114", c_Estado: "MEX", label: "Villa Victoria" },
    { value: "114", c_Estado: "OAX", label: "San Baltazar Yatzachi el Bajo" },
    { value: "114", c_Estado: "PUE", label: "Puebla" },
    { value: "114", c_Estado: "VER", label: "Nautla" },
    { value: "115", c_Estado: "CHP", label: "Maravilla Tenejapa" },
    { value: "115", c_Estado: "JAL", label: "Villa Guerrero" },
    { value: "115", c_Estado: "MEX", label: "Xonacatlán" },
    { value: "115", c_Estado: "OAX", label: "San Bartolo Coyotepec" },
    { value: "115", c_Estado: "PUE", label: "Quecholac" },
    { value: "115", c_Estado: "VER", label: "Nogales" },
    { value: "116", c_Estado: "CHP", label: "Marqués de Comillas" },
    { value: "116", c_Estado: "JAL", label: "Villa Hidalgo" },
    { value: "116", c_Estado: "MEX", label: "Zacazonapan" },
    { value: "116", c_Estado: "OAX", label: "San Bartolomé Ayautla" },
    { value: "116", c_Estado: "PUE", label: "Quimixtlán" },
    { value: "116", c_Estado: "VER", label: "Oluta" },
    { value: "117", c_Estado: "CHP", label: "Montecristo de Guerrero" },
    { value: "117", c_Estado: "JAL", label: "Cañadas de Obregón" },
    { value: "117", c_Estado: "MEX", label: "Zacualpan" },
    { value: "117", c_Estado: "OAX", label: "San Bartolomé Loxicha" },
    { value: "117", c_Estado: "PUE", label: "Rafael Lara Grajales" },
    { value: "117", c_Estado: "VER", label: "Omealca" },
    { value: "118", c_Estado: "CHP", label: "San Andrés Duraznal" },
    { value: "118", c_Estado: "JAL", label: "Yahualica de González Gallo" },
    { value: "118", c_Estado: "MEX", label: "Zinacantepec" },
    { value: "118", c_Estado: "OAX", label: "San Bartolomé Quialana" },
    { value: "118", c_Estado: "PUE", label: "Los Reyes de Juárez" },
    { value: "118", c_Estado: "VER", label: "Orizaba" },
    { value: "119", c_Estado: "CHP", label: "Santiago el Pinar" },
    { value: "119", c_Estado: "JAL", label: "Zacoalco de Torres" },
    { value: "119", c_Estado: "MEX", label: "Zumpahuacán" },
    { value: "119", c_Estado: "OAX", label: "San Bartolomé Yucuañe" },
    { value: "119", c_Estado: "PUE", label: "San Andrés Cholula" },
    { value: "119", c_Estado: "VER", label: "Otatitlán" },
    { value: "120", c_Estado: "JAL", label: "Zapopan" },
    { value: "120", c_Estado: "MEX", label: "Zumpango" },
    { value: "120", c_Estado: "OAX", label: "San Bartolomé Zoogocho" },
    { value: "120", c_Estado: "PUE", label: "San Antonio Cañada" },
    { value: "120", c_Estado: "VER", label: "Oteapan" },
    { value: "121", c_Estado: "JAL", label: "Zapotiltic" },
    { value: "121", c_Estado: "MEX", label: "Cuautitlán Izcalli" },
    { value: "121", c_Estado: "OAX", label: "San Bartolo Soyaltepec" },
    { value: "121", c_Estado: "PUE", label: "San Diego la Mesa Tochimiltzingo" },
    { value: "121", c_Estado: "VER", label: "Ozuluama de Mascareñas" },
    { value: "122", c_Estado: "JAL", label: "Zapotitlán de Vadillo" },
    { value: "122", c_Estado: "MEX", label: "Valle de Chalco Solidaridad" },
    { value: "122", c_Estado: "OAX", label: "San Bartolo Yautepec" },
    { value: "122", c_Estado: "PUE", label: "San Felipe Teotlalcingo" },
    { value: "122", c_Estado: "VER", label: "Pajapan" },
    { value: "123", c_Estado: "JAL", label: "Zapotlán del Rey" },
    { value: "123", c_Estado: "MEX", label: "Luvianos" },
    { value: "123", c_Estado: "OAX", label: "San Bernardo Mixtepec" },
    { value: "123", c_Estado: "PUE", label: "San Felipe Tepatlán" },
    { value: "123", c_Estado: "VER", label: "Pánuco" },
    { value: "124", c_Estado: "JAL", label: "Zapotlanejo" },
    { value: "124", c_Estado: "MEX", label: "San José del Rincón" },
    { value: "124", c_Estado: "OAX", label: "San Blas Atempa" },
    { value: "124", c_Estado: "PUE", label: "San Gabriel Chilac" },
    { value: "124", c_Estado: "VER", label: "Papantla" },
    { value: "125", c_Estado: "JAL", label: "San Ignacio Cerro Gordo" },
    { value: "125", c_Estado: "MEX", label: "Tonanitla" },
    { value: "125", c_Estado: "OAX", label: "San Carlos Yautepec" },
    { value: "125", c_Estado: "PUE", label: "San Gregorio Atzompa" },
    { value: "125", c_Estado: "VER", label: "Paso del Macho" },
    { value: "126", c_Estado: "OAX", label: "San Cristóbal Amatlán" },
    { value: "126", c_Estado: "PUE", label: "San Jerónimo Tecuanipan" },
    { value: "126", c_Estado: "VER", label: "Paso de Ovejas" },
    { value: "127", c_Estado: "OAX", label: "San Cristóbal Amoltepec" },
    { value: "127", c_Estado: "PUE", label: "San Jerónimo Xayacatlán" },
    { value: "127", c_Estado: "VER", label: "La Perla" },
    { value: "128", c_Estado: "OAX", label: "San Cristóbal Lachirioag" },
    { value: "128", c_Estado: "PUE", label: "San José Chiapa" },
    { value: "128", c_Estado: "VER", label: "Perote" },
    { value: "129", c_Estado: "OAX", label: "San Cristóbal Suchixtlahuaca" },
    { value: "129", c_Estado: "PUE", label: "San José Miahuatlán" },
    { value: "129", c_Estado: "VER", label: "Platón Sánchez" },
    { value: "130", c_Estado: "OAX", label: "San Dionisio del Mar" },
    { value: "130", c_Estado: "PUE", label: "San Juan Atenco" },
    { value: "130", c_Estado: "VER", label: "Playa Vicente" },
    { value: "131", c_Estado: "OAX", label: "San Dionisio Ocotepec" },
    { value: "131", c_Estado: "PUE", label: "San Juan Atzompa" },
    { value: "131", c_Estado: "VER", label: "Poza Rica de Hidalgo" },
    { value: "132", c_Estado: "OAX", label: "San Dionisio Ocotlán" },
    { value: "132", c_Estado: "PUE", label: "San Martín Texmelucan" },
    { value: "132", c_Estado: "VER", label: "Las Vigas de Ramírez" },
    { value: "133", c_Estado: "OAX", label: "San Esteban Atatlahuca" },
    { value: "133", c_Estado: "PUE", label: "San Martín Totoltepec" },
    { value: "133", c_Estado: "VER", label: "Pueblo Viejo" },
    { value: "134", c_Estado: "OAX", label: "San Felipe Jalapa de Díaz" },
    { value: "134", c_Estado: "PUE", label: "San Matías Tlalancaleca" },
    { value: "134", c_Estado: "VER", label: "Puente Nacional" },
    { value: "135", c_Estado: "OAX", label: "San Felipe Tejalápam" },
    { value: "135", c_Estado: "PUE", label: "San Miguel Ixitlán" },
    { value: "135", c_Estado: "VER", label: "Rafael Delgado" },
    { value: "136", c_Estado: "OAX", label: "San Felipe Usila" },
    { value: "136", c_Estado: "PUE", label: "San Miguel Xoxtla" },
    { value: "136", c_Estado: "VER", label: "Rafael Lucio" },
    { value: "137", c_Estado: "OAX", label: "San Francisco Cahuacuá" },
    { value: "137", c_Estado: "PUE", label: "San Nicolás Buenos Aires" },
    { value: "137", c_Estado: "VER", label: "Los Reyes" },
    { value: "138", c_Estado: "OAX", label: "San Francisco Cajonos" },
    { value: "138", c_Estado: "PUE", label: "San Nicolás de los Ranchos" },
    { value: "138", c_Estado: "VER", label: "Río Blanco" },
    { value: "139", c_Estado: "OAX", label: "San Francisco Chapulapa" },
    { value: "139", c_Estado: "PUE", label: "San Pablo Anicano" },
    { value: "139", c_Estado: "VER", label: "Saltabarranca" },
    { value: "140", c_Estado: "OAX", label: "San Francisco Chindúa" },
    { value: "140", c_Estado: "PUE", label: "San Pedro Cholula" },
    { value: "140", c_Estado: "VER", label: "San Andrés Tenejapan" },
    { value: "141", c_Estado: "OAX", label: "San Francisco del Mar" },
    { value: "141", c_Estado: "PUE", label: "San Pedro Yeloixtlahuaca" },
    { value: "141", c_Estado: "VER", label: "San Andrés Tuxtla" },
    { value: "142", c_Estado: "OAX", label: "San Francisco Huehuetlán" },
    { value: "142", c_Estado: "PUE", label: "San Salvador el Seco" },
    { value: "142", c_Estado: "VER", label: "San Juan Evangelista" },
    { value: "143", c_Estado: "OAX", label: "San Francisco Ixhuatán" },
    { value: "143", c_Estado: "PUE", label: "San Salvador el Verde" },
    { value: "143", c_Estado: "VER", label: "Santiago Tuxtla" },
    { value: "144", c_Estado: "OAX", label: "San Francisco Jaltepetongo" },
    { value: "144", c_Estado: "PUE", label: "San Salvador Huixcolotla" },
    { value: "144", c_Estado: "VER", label: "Sayula de Alemán" },
    { value: "145", c_Estado: "OAX", label: "San Francisco Lachigoló" },
    { value: "145", c_Estado: "PUE", label: "San Sebastián Tlacotepec" },
    { value: "145", c_Estado: "VER", label: "Soconusco" },
    { value: "146", c_Estado: "OAX", label: "San Francisco Logueche" },
    { value: "146", c_Estado: "PUE", label: "Santa Catarina Tlaltempan" },
    { value: "146", c_Estado: "VER", label: "Sochiapa" },
    { value: "147", c_Estado: "OAX", label: "San Francisco Nuxaño" },
    { value: "147", c_Estado: "PUE", label: "Santa Inés Ahuatempan" },
    { value: "147", c_Estado: "VER", label: "Soledad Atzompa" },
    { value: "148", c_Estado: "OAX", label: "San Francisco Ozolotepec" },
    { value: "148", c_Estado: "PUE", label: "Santa Isabel Cholula" },
    { value: "148", c_Estado: "VER", label: "Soledad de Doblado" },
    { value: "149", c_Estado: "OAX", label: "San Francisco Sola" },
    { value: "149", c_Estado: "PUE", label: "Santiago Miahuatlán" },
    { value: "149", c_Estado: "VER", label: "Soteapan" },
    { value: "150", c_Estado: "OAX", label: "San Francisco Telixtlahuaca" },
    { value: "150", c_Estado: "PUE", label: "Huehuetlán el Grande" },
    { value: "150", c_Estado: "VER", label: "Tamalín" },
    { value: "151", c_Estado: "OAX", label: "San Francisco Teopan" },
    { value: "151", c_Estado: "PUE", label: "Santo Tomás Hueyotlipan" },
    { value: "151", c_Estado: "VER", label: "Tamiahua" },
    { value: "152", c_Estado: "OAX", label: "San Francisco Tlapancingo" },
    { value: "152", c_Estado: "PUE", label: "Soltepec" },
    { value: "152", c_Estado: "VER", label: "Tampico Alto" },
    { value: "153", c_Estado: "OAX", label: "San Gabriel Mixtepec" },
    { value: "153", c_Estado: "PUE", label: "Tecali de Herrera" },
    { value: "153", c_Estado: "VER", label: "Tancoco" },
    { value: "154", c_Estado: "OAX", label: "San Ildefonso Amatlán" },
    { value: "154", c_Estado: "PUE", label: "Tecamachalco" },
    { value: "154", c_Estado: "VER", label: "Tantima" },
    { value: "155", c_Estado: "OAX", label: "San Ildefonso Sola" },
    { value: "155", c_Estado: "PUE", label: "Tecomatlán" },
    { value: "155", c_Estado: "VER", label: "Tantoyuca" },
    { value: "156", c_Estado: "OAX", label: "San Ildefonso Villa Alta" },
    { value: "156", c_Estado: "PUE", label: "Tehuacán" },
    { value: "156", c_Estado: "VER", label: "Tatatila" },
    { value: "157", c_Estado: "OAX", label: "San Jacinto Amilpas" },
    { value: "157", c_Estado: "PUE", label: "Tehuitzingo" },
    { value: "157", c_Estado: "VER", label: "Castillo de Teayo" },
    { value: "158", c_Estado: "OAX", label: "San Jacinto Tlacotepec" },
    { value: "158", c_Estado: "PUE", label: "Tenampulco" },
    { value: "158", c_Estado: "VER", label: "Tecolutla" },
    { value: "159", c_Estado: "OAX", label: "San Jerónimo Coatlán" },
    { value: "159", c_Estado: "PUE", label: "Teopantlán" },
    { value: "159", c_Estado: "VER", label: "Tehuipango" },
    { value: "160", c_Estado: "OAX", label: "San Jerónimo Silacayoapilla" },
    { value: "160", c_Estado: "PUE", label: "Teotlalco" },
    { value: "160", c_Estado: "VER", label: "Álamo Temapache" },
    { value: "161", c_Estado: "OAX", label: "San Jerónimo Sosola" },
    { value: "161", c_Estado: "PUE", label: "Tepanco de López" },
    { value: "161", c_Estado: "VER", label: "Tempoal" },
    { value: "162", c_Estado: "OAX", label: "San Jerónimo Taviche" },
    { value: "162", c_Estado: "PUE", label: "Tepango de Rodríguez" },
    { value: "162", c_Estado: "VER", label: "Tenampa" },
    { value: "163", c_Estado: "OAX", label: "San Jerónimo Tecóatl" },
    { value: "163", c_Estado: "PUE", label: "Tepatlaxco de Hidalgo" },
    { value: "163", c_Estado: "VER", label: "Tenochtitlán" },
    { value: "164", c_Estado: "OAX", label: "San Jorge Nuchita" },
    { value: "164", c_Estado: "PUE", label: "Tepeaca" },
    { value: "164", c_Estado: "VER", label: "Teocelo" },
    { value: "165", c_Estado: "OAX", label: "San José Ayuquila" },
    { value: "165", c_Estado: "PUE", label: "Tepemaxalco" },
    { value: "165", c_Estado: "VER", label: "Tepatlaxco" },
    { value: "166", c_Estado: "OAX", label: "San José Chiltepec" },
    { value: "166", c_Estado: "PUE", label: "Tepeojuma" },
    { value: "166", c_Estado: "VER", label: "Tepetlán" },
    { value: "167", c_Estado: "OAX", label: "San José del Peñasco" },
    { value: "167", c_Estado: "PUE", label: "Tepetzintla" },
    { value: "167", c_Estado: "VER", label: "Tepetzintla" },
    { value: "168", c_Estado: "OAX", label: "San José Estancia Grande" },
    { value: "168", c_Estado: "PUE", label: "Tepexco" },
    { value: "168", c_Estado: "VER", label: "Tequila" },
    { value: "169", c_Estado: "OAX", label: "San José Independencia" },
    { value: "169", c_Estado: "PUE", label: "Tepexi de Rodríguez" },
    { value: "169", c_Estado: "VER", label: "José Azueta" },
    { value: "170", c_Estado: "OAX", label: "San José Lachiguiri" },
    { value: "170", c_Estado: "PUE", label: "Tepeyahualco" },
    { value: "170", c_Estado: "VER", label: "Texcatepec" },
    { value: "171", c_Estado: "OAX", label: "San José Tenango" },
    { value: "171", c_Estado: "PUE", label: "Tepeyahualco de Cuauhtémoc" },
    { value: "171", c_Estado: "VER", label: "Texhuacán" },
    { value: "172", c_Estado: "OAX", label: "San Juan Achiutla" },
    { value: "172", c_Estado: "PUE", label: "Tetela de Ocampo" },
    { value: "172", c_Estado: "VER", label: "Texistepec" },
    { value: "173", c_Estado: "OAX", label: "San Juan Atepec" },
    { value: "173", c_Estado: "PUE", label: "Teteles de Avila Castillo" },
    { value: "173", c_Estado: "VER", label: "Tezonapa" },
    { value: "174", c_Estado: "OAX", label: "Ánimas Trujano" },
    { value: "174", c_Estado: "PUE", label: "Teziutlán" },
    { value: "174", c_Estado: "VER", label: "Tierra Blanca" },
    { value: "175", c_Estado: "OAX", label: "San Juan Bautista Atatlahuca" },
    { value: "175", c_Estado: "PUE", label: "Tianguismanalco" },
    { value: "175", c_Estado: "VER", label: "Tihuatlán" },
    { value: "176", c_Estado: "OAX", label: "San Juan Bautista Coixtlahuaca" },
    { value: "176", c_Estado: "PUE", label: "Tilapa" },
    { value: "176", c_Estado: "VER", label: "Tlacojalpan" },
    { value: "177", c_Estado: "OAX", label: "San Juan Bautista Cuicatlán" },
    { value: "177", c_Estado: "PUE", label: "Tlacotepec de Benito Juárez" },
    { value: "177", c_Estado: "VER", label: "Tlacolulan" },
    { value: "178", c_Estado: "OAX", label: "San Juan Bautista Guelache" },
    { value: "178", c_Estado: "PUE", label: "Tlacuilotepec" },
    { value: "178", c_Estado: "VER", label: "Tlacotalpan" },
    { value: "179", c_Estado: "OAX", label: "San Juan Bautista Jayacatlán" },
    { value: "179", c_Estado: "PUE", label: "Tlachichuca" },
    { value: "179", c_Estado: "VER", label: "Tlacotepec de Mejía" },
    { value: "180", c_Estado: "OAX", label: "San Juan Bautista Lo de Soto" },
    { value: "180", c_Estado: "PUE", label: "Tlahuapan" },
    { value: "180", c_Estado: "VER", label: "Tlachichilco" },
    { value: "181", c_Estado: "OAX", label: "San Juan Bautista Suchitepec" },
    { value: "181", c_Estado: "PUE", label: "Tlaltenango" },
    { value: "181", c_Estado: "VER", label: "Tlalixcoyan" },
    { value: "182", c_Estado: "OAX", label: "San Juan Bautista Tlacoatzintepec" },
    { value: "182", c_Estado: "PUE", label: "Tlanepantla" },
    { value: "182", c_Estado: "VER", label: "Tlalnelhuayocan" },
    { value: "183", c_Estado: "OAX", label: "San Juan Bautista Tlachichilco" },
    { value: "183", c_Estado: "PUE", label: "Tlaola" },
    { value: "183", c_Estado: "VER", label: "Tlapacoyan" },
    { value: "184", c_Estado: "OAX", label: "San Juan Bautista Tuxtepec" },
    { value: "184", c_Estado: "PUE", label: "Tlapacoya" },
    { value: "184", c_Estado: "VER", label: "Tlaquilpa" },
    { value: "185", c_Estado: "OAX", label: "San Juan Cacahuatepec" },
    { value: "185", c_Estado: "PUE", label: "Tlapanalá" },
    { value: "185", c_Estado: "VER", label: "Tlilapan" },
    { value: "186", c_Estado: "OAX", label: "San Juan Cieneguilla" },
    { value: "186", c_Estado: "PUE", label: "Tlatlauquitepec" },
    { value: "186", c_Estado: "VER", label: "Tomatlán" },
    { value: "187", c_Estado: "OAX", label: "San Juan Coatzóspam" },
    { value: "187", c_Estado: "PUE", label: "Tlaxco" },
    { value: "187", c_Estado: "VER", label: "Tonayán" },
    { value: "188", c_Estado: "OAX", label: "San Juan Colorado" },
    { value: "188", c_Estado: "PUE", label: "Tochimilco" },
    { value: "188", c_Estado: "VER", label: "Totutla" },
    { value: "189", c_Estado: "OAX", label: "San Juan Comaltepec" },
    { value: "189", c_Estado: "PUE", label: "Tochtepec" },
    { value: "189", c_Estado: "VER", label: "Tuxpan" },
    { value: "190", c_Estado: "OAX", label: "San Juan Cotzocón" },
    { value: "190", c_Estado: "PUE", label: "Totoltepec de Guerrero" },
    { value: "190", c_Estado: "VER", label: "Tuxtilla" },
    { value: "191", c_Estado: "OAX", label: "San Juan Chicomezúchil" },
    { value: "191", c_Estado: "PUE", label: "Tulcingo" },
    { value: "191", c_Estado: "VER", label: "Ursulo Galván" },
    { value: "192", c_Estado: "OAX", label: "San Juan Chilateca" },
    { value: "192", c_Estado: "PUE", label: "Tuzamapan de Galeana" },
    { value: "192", c_Estado: "VER", label: "Vega de Alatorre" },
    { value: "193", c_Estado: "OAX", label: "San Juan del Estado" },
    { value: "193", c_Estado: "PUE", label: "Tzicatlacoyan" },
    { value: "193", c_Estado: "VER", label: "Veracruz" },
    { value: "194", c_Estado: "OAX", label: "San Juan del Río" },
    { value: "194", c_Estado: "PUE", label: "Venustiano Carranza" },
    { value: "194", c_Estado: "VER", label: "Villa Aldama" },
    { value: "195", c_Estado: "OAX", label: "San Juan Diuxi" },
    { value: "195", c_Estado: "PUE", label: "Vicente Guerrero" },
    { value: "195", c_Estado: "VER", label: "Xoxocotla" },
    { value: "196", c_Estado: "OAX", label: "San Juan Evangelista Analco" },
    { value: "196", c_Estado: "PUE", label: "Xayacatlán de Bravo" },
    { value: "196", c_Estado: "VER", label: "Yanga" },
    { value: "197", c_Estado: "OAX", label: "San Juan Guelavía" },
    { value: "197", c_Estado: "PUE", label: "Xicotepec" },
    { value: "197", c_Estado: "VER", label: "Yecuatla" },
    { value: "198", c_Estado: "OAX", label: "San Juan Guichicovi" },
    { value: "198", c_Estado: "PUE", label: "Xicotlán" },
    { value: "198", c_Estado: "VER", label: "Zacualpan" },
    { value: "199", c_Estado: "OAX", label: "San Juan Ihualtepec" },
    { value: "199", c_Estado: "PUE", label: "Xiutetelco" },
    { value: "199", c_Estado: "VER", label: "Zaragoza" },
    { value: "200", c_Estado: "OAX", label: "San Juan Juquila Mixes" },
    { value: "200", c_Estado: "PUE", label: "Xochiapulco" },
    { value: "200", c_Estado: "VER", label: "Zentla" },
    { value: "201", c_Estado: "OAX", label: "San Juan Juquila Vijanos" },
    { value: "201", c_Estado: "PUE", label: "Xochiltepec" },
    { value: "201", c_Estado: "VER", label: "Zongolica" },
    { value: "202", c_Estado: "OAX", label: "San Juan Lachao" },
    { value: "202", c_Estado: "PUE", label: "Xochitlán de Vicente Suárez" },
    { value: "202", c_Estado: "VER", label: "Zontecomatlán de López y Fuentes" },
    { value: "203", c_Estado: "OAX", label: "San Juan Lachigalla" },
    { value: "203", c_Estado: "PUE", label: "Xochitlán Todos Santos" },
    { value: "203", c_Estado: "VER", label: "Zozocolco de Hidalgo" },
    { value: "204", c_Estado: "OAX", label: "San Juan Lajarcia" },
    { value: "204", c_Estado: "PUE", label: "Yaonáhuac" },
    { value: "204", c_Estado: "VER", label: "Agua Dulce" },
    { value: "205", c_Estado: "OAX", label: "San Juan Lalana" },
    { value: "205", c_Estado: "PUE", label: "Yehualtepec" },
    { value: "205", c_Estado: "VER", label: "El Higo" },
    { value: "206", c_Estado: "OAX", label: "San Juan de los Cués" },
    { value: "206", c_Estado: "PUE", label: "Zacapala" },
    { value: "206", c_Estado: "VER", label: "Nanchital de Lázaro Cárdenas del Río" },
    { value: "207", c_Estado: "OAX", label: "San Juan Mazatlán" },
    { value: "207", c_Estado: "PUE", label: "Zacapoaxtla" },
    { value: "207", c_Estado: "VER", label: "Tres Valles" },
    { value: "208", c_Estado: "OAX", label: "San Juan Mixtepec -Dto. 08 -" },
    { value: "208", c_Estado: "PUE", label: "Zacatlán" },
    { value: "208", c_Estado: "VER", label: "Carlos A. Carrillo" },
    { value: "209", c_Estado: "OAX", label: "San Juan Mixtepec -Dto. 26 -" },
    { value: "209", c_Estado: "PUE", label: "Zapotitlán" },
    { value: "209", c_Estado: "VER", label: "Tatahuicapan de Juárez" },
    { value: "210", c_Estado: "OAX", label: "San Juan Ñumí" },
    { value: "210", c_Estado: "PUE", label: "Zapotitlán de Méndez" },
    { value: "210", c_Estado: "VER", label: "Uxpanapa" },
    { value: "211", c_Estado: "OAX", label: "San Juan Ozolotepec" },
    { value: "211", c_Estado: "PUE", label: "Zaragoza" },
    { value: "211", c_Estado: "VER", label: "San Rafael" },
    { value: "212", c_Estado: "OAX", label: "San Juan Petlapa" },
    { value: "212", c_Estado: "PUE", label: "Zautla" },
    { value: "212", c_Estado: "VER", label: "Santiago Sochiapan" },
    { value: "213", c_Estado: "OAX", label: "San Juan Quiahije" },
    { value: "213", c_Estado: "PUE", label: "Zihuateutla" },
    { value: "214", c_Estado: "OAX", label: "San Juan Quiotepec" },
    { value: "214", c_Estado: "PUE", label: "Zinacatepec" },
    { value: "215", c_Estado: "OAX", label: "San Juan Sayultepec" },
    { value: "215", c_Estado: "PUE", label: "Zongozotla" },
    { value: "216", c_Estado: "OAX", label: "San Juan Tabaá" },
    { value: "216", c_Estado: "PUE", label: "Zoquiapan" },
    { value: "217", c_Estado: "OAX", label: "San Juan Tamazola" },
    { value: "217", c_Estado: "PUE", label: "Zoquitlán" },
    { value: "218", c_Estado: "OAX", label: "San Juan Teita" },
    { value: "219", c_Estado: "OAX", label: "San Juan Teitipac" },
    { value: "220", c_Estado: "OAX", label: "San Juan Tepeuxila" },
    { value: "221", c_Estado: "OAX", label: "San Juan Teposcolula" },
    { value: "222", c_Estado: "OAX", label: "San Juan Yaeé" },
    { value: "223", c_Estado: "OAX", label: "San Juan Yatzona" },
    { value: "224", c_Estado: "OAX", label: "San Juan Yucuita" },
    { value: "225", c_Estado: "OAX", label: "San Lorenzo" },
    { value: "226", c_Estado: "OAX", label: "San Lorenzo Albarradas" },
    { value: "227", c_Estado: "OAX", label: "San Lorenzo Cacaotepec" },
    { value: "228", c_Estado: "OAX", label: "San Lorenzo Cuaunecuiltitla" },
    { value: "229", c_Estado: "OAX", label: "San Lorenzo Texmelúcan" },
    { value: "230", c_Estado: "OAX", label: "San Lorenzo Victoria" },
    { value: "231", c_Estado: "OAX", label: "San Lucas Camotlán" },
    { value: "232", c_Estado: "OAX", label: "San Lucas Ojitlán" },
    { value: "233", c_Estado: "OAX", label: "San Lucas Quiaviní" },
    { value: "234", c_Estado: "OAX", label: "San Lucas Zoquiápam" },
    { value: "235", c_Estado: "OAX", label: "San Luis Amatlán" },
    { value: "236", c_Estado: "OAX", label: "San Marcial Ozolotepec" },
    { value: "237", c_Estado: "OAX", label: "San Marcos Arteaga" },
    { value: "238", c_Estado: "OAX", label: "San Martín de los Cansecos" },
    { value: "239", c_Estado: "OAX", label: "San Martín Huamelúlpam" },
    { value: "240", c_Estado: "OAX", label: "San Martín Itunyoso" },
    { value: "241", c_Estado: "OAX", label: "San Martín Lachilá" },
    { value: "242", c_Estado: "OAX", label: "San Martín Peras" },
    { value: "243", c_Estado: "OAX", label: "San Martín Tilcajete" },
    { value: "244", c_Estado: "OAX", label: "San Martín Toxpalan" },
    { value: "245", c_Estado: "OAX", label: "San Martín Zacatepec" },
    { value: "246", c_Estado: "OAX", label: "San Mateo Cajonos" },
    { value: "247", c_Estado: "OAX", label: "Capulálpam de Méndez" },
    { value: "248", c_Estado: "OAX", label: "San Mateo del Mar" },
    { value: "249", c_Estado: "OAX", label: "San Mateo Yoloxochitlán" },
    { value: "250", c_Estado: "OAX", label: "San Mateo Etlatongo" },
    { value: "251", c_Estado: "OAX", label: "San Mateo Nejápam" },
    { value: "252", c_Estado: "OAX", label: "San Mateo Peñasco" },
    { value: "253", c_Estado: "OAX", label: "San Mateo Piñas" },
    { value: "254", c_Estado: "OAX", label: "San Mateo Río Hondo" },
    { value: "255", c_Estado: "OAX", label: "San Mateo Sindihui" },
    { value: "256", c_Estado: "OAX", label: "San Mateo Tlapiltepec" },
    { value: "257", c_Estado: "OAX", label: "San Melchor Betaza" },
    { value: "258", c_Estado: "OAX", label: "San Miguel Achiutla" },
    { value: "259", c_Estado: "OAX", label: "San Miguel Ahuehuetitlán" },
    { value: "260", c_Estado: "OAX", label: "San Miguel Aloápam" },
    { value: "261", c_Estado: "OAX", label: "San Miguel Amatitlán" },
    { value: "262", c_Estado: "OAX", label: "San Miguel Amatlán" },
    { value: "263", c_Estado: "OAX", label: "San Miguel Coatlán" },
    { value: "264", c_Estado: "OAX", label: "San Miguel Chicahua" },
    { value: "265", c_Estado: "OAX", label: "San Miguel Chimalapa" },
    { value: "266", c_Estado: "OAX", label: "San Miguel del Puerto" },
    { value: "267", c_Estado: "OAX", label: "San Miguel del Río" },
    { value: "268", c_Estado: "OAX", label: "San Miguel Ejutla" },
    { value: "269", c_Estado: "OAX", label: "San Miguel el Grande" },
    { value: "270", c_Estado: "OAX", label: "San Miguel Huautla" },
    { value: "271", c_Estado: "OAX", label: "San Miguel Mixtepec" },
    { value: "272", c_Estado: "OAX", label: "San Miguel Panixtlahuaca" },
    { value: "273", c_Estado: "OAX", label: "San Miguel Peras" },
    { value: "274", c_Estado: "OAX", label: "San Miguel Piedras" },
    { value: "275", c_Estado: "OAX", label: "San Miguel Quetzaltepec" },
    { value: "276", c_Estado: "OAX", label: "San Miguel Santa Flor" },
    { value: "277", c_Estado: "OAX", label: "Villa Sola de Vega" },
    { value: "278", c_Estado: "OAX", label: "San Miguel Soyaltepec" },
    { value: "279", c_Estado: "OAX", label: "San Miguel Suchixtepec" },
    { value: "280", c_Estado: "OAX", label: "Villa Talea de Castro" },
    { value: "281", c_Estado: "OAX", label: "San Miguel Tecomatlán" },
    { value: "282", c_Estado: "OAX", label: "San Miguel Tenango" },
    { value: "283", c_Estado: "OAX", label: "San Miguel Tequixtepec" },
    { value: "284", c_Estado: "OAX", label: "San Miguel Tilquiápam" },
    { value: "285", c_Estado: "OAX", label: "San Miguel Tlacamama" },
    { value: "286", c_Estado: "OAX", label: "San Miguel Tlacotepec" },
    { value: "287", c_Estado: "OAX", label: "San Miguel Tulancingo" },
    { value: "288", c_Estado: "OAX", label: "San Miguel Yotao" },
    { value: "289", c_Estado: "OAX", label: "San Nicolás" },
    { value: "290", c_Estado: "OAX", label: "San Nicolás Hidalgo" },
    { value: "291", c_Estado: "OAX", label: "San Pablo Coatlán" },
    { value: "292", c_Estado: "OAX", label: "San Pablo Cuatro Venados" },
    { value: "293", c_Estado: "OAX", label: "San Pablo Etla" },
    { value: "294", c_Estado: "OAX", label: "San Pablo Huitzo" },
    { value: "295", c_Estado: "OAX", label: "San Pablo Huixtepec" },
    { value: "296", c_Estado: "OAX", label: "San Pablo Macuiltianguis" },
    { value: "297", c_Estado: "OAX", label: "San Pablo Tijaltepec" },
    { value: "298", c_Estado: "OAX", label: "San Pablo Villa de Mitla" },
    { value: "299", c_Estado: "OAX", label: "San Pablo Yaganiza" },
    { value: "300", c_Estado: "OAX", label: "San Pedro Amuzgos" },
    { value: "301", c_Estado: "OAX", label: "San Pedro Apóstol" },
    { value: "302", c_Estado: "OAX", label: "San Pedro Atoyac" },
    { value: "303", c_Estado: "OAX", label: "San Pedro Cajonos" },
    { value: "304", c_Estado: "OAX", label: "San Pedro Coxcaltepec Cántaros" },
    { value: "305", c_Estado: "OAX", label: "San Pedro Comitancillo" },
    { value: "306", c_Estado: "OAX", label: "San Pedro el Alto" },
    { value: "307", c_Estado: "OAX", label: "San Pedro Huamelula" },
    { value: "308", c_Estado: "OAX", label: "San Pedro Huilotepec" },
    { value: "309", c_Estado: "OAX", label: "San Pedro Ixcatlán" },
    { value: "310", c_Estado: "OAX", label: "San Pedro Ixtlahuaca" },
    { value: "311", c_Estado: "OAX", label: "San Pedro Jaltepetongo" },
    { value: "312", c_Estado: "OAX", label: "San Pedro Jicayán" },
    { value: "313", c_Estado: "OAX", label: "San Pedro Jocotipac" },
    { value: "314", c_Estado: "OAX", label: "San Pedro Juchatengo" },
    { value: "315", c_Estado: "OAX", label: "San Pedro Mártir" },
    { value: "316", c_Estado: "OAX", label: "San Pedro Mártir Quiechapa" },
    { value: "317", c_Estado: "OAX", label: "San Pedro Mártir Yucuxaco" },
    { value: "318", c_Estado: "OAX", label: "San Pedro Mixtepec -Dto. 22 -" },
    { value: "319", c_Estado: "OAX", label: "San Pedro Mixtepec -Dto. 26 -" },
    { value: "320", c_Estado: "OAX", label: "San Pedro Molinos" },
    { value: "321", c_Estado: "OAX", label: "San Pedro Nopala" },
    { value: "322", c_Estado: "OAX", label: "San Pedro Ocopetatillo" },
    { value: "323", c_Estado: "OAX", label: "San Pedro Ocotepec" },
    { value: "324", c_Estado: "OAX", label: "San Pedro Pochutla" },
    { value: "325", c_Estado: "OAX", label: "San Pedro Quiatoni" },
    { value: "326", c_Estado: "OAX", label: "San Pedro Sochiápam" },
    { value: "327", c_Estado: "OAX", label: "San Pedro Tapanatepec" },
    { value: "328", c_Estado: "OAX", label: "San Pedro Taviche" },
    { value: "329", c_Estado: "OAX", label: "San Pedro Teozacoalco" },
    { value: "330", c_Estado: "OAX", label: "San Pedro Teutila" },
    { value: "331", c_Estado: "OAX", label: "San Pedro Tidaá" },
    { value: "332", c_Estado: "OAX", label: "San Pedro Topiltepec" },
    { value: "333", c_Estado: "OAX", label: "San Pedro Totolápam" },
    { value: "334", c_Estado: "OAX", label: "Villa de Tututepec de Melchor Ocampo" },
    { value: "335", c_Estado: "OAX", label: "San Pedro Yaneri" },
    { value: "336", c_Estado: "OAX", label: "San Pedro Yólox" },
    { value: "337", c_Estado: "OAX", label: "San Pedro y San Pablo Ayutla" },
    { value: "338", c_Estado: "OAX", label: "Villa de Etla" },
    { value: "339", c_Estado: "OAX", label: "San Pedro y San Pablo Teposcolula" },
    { value: "340", c_Estado: "OAX", label: "San Pedro y San Pablo Tequixtepec" },
    { value: "341", c_Estado: "OAX", label: "San Pedro Yucunama" },
    { value: "342", c_Estado: "OAX", label: "San Raymundo Jalpan" },
    { value: "343", c_Estado: "OAX", label: "San Sebastián Abasolo" },
    { value: "344", c_Estado: "OAX", label: "San Sebastián Coatlán" },
    { value: "345", c_Estado: "OAX", label: "San Sebastián Ixcapa" },
    { value: "346", c_Estado: "OAX", label: "San Sebastián Nicananduta" },
    { value: "347", c_Estado: "OAX", label: "San Sebastián Río Hondo" },
    { value: "348", c_Estado: "OAX", label: "San Sebastián Tecomaxtlahuaca" },
    { value: "349", c_Estado: "OAX", label: "San Sebastián Teitipac" },
    { value: "350", c_Estado: "OAX", label: "San Sebastián Tutla" },
    { value: "351", c_Estado: "OAX", label: "San Simón Almolongas" },
    { value: "352", c_Estado: "OAX", label: "San Simón Zahuatlán" },
    { value: "353", c_Estado: "OAX", label: "Santa Ana" },
    { value: "354", c_Estado: "OAX", label: "Santa Ana Ateixtlahuaca" },
    { value: "355", c_Estado: "OAX", label: "Santa Ana Cuauhtémoc" },
    { value: "356", c_Estado: "OAX", label: "Santa Ana del Valle" },
    { value: "357", c_Estado: "OAX", label: "Santa Ana Tavela" },
    { value: "358", c_Estado: "OAX", label: "Santa Ana Tlapacoyan" },
    { value: "359", c_Estado: "OAX", label: "Santa Ana Yareni" },
    { value: "360", c_Estado: "OAX", label: "Santa Ana Zegache" },
    { value: "361", c_Estado: "OAX", label: "Santa Catalina Quierí" },
    { value: "362", c_Estado: "OAX", label: "Santa Catarina Cuixtla" },
    { value: "363", c_Estado: "OAX", label: "Santa Catarina Ixtepeji" },
    { value: "364", c_Estado: "OAX", label: "Santa Catarina Juquila" },
    { value: "365", c_Estado: "OAX", label: "Santa Catarina Lachatao" },
    { value: "366", c_Estado: "OAX", label: "Santa Catarina Loxicha" },
    { value: "367", c_Estado: "OAX", label: "Santa Catarina Mechoacán" },
    { value: "368", c_Estado: "OAX", label: "Santa Catarina Minas" },
    { value: "369", c_Estado: "OAX", label: "Santa Catarina Quiané" },
    { value: "370", c_Estado: "OAX", label: "Santa Catarina Tayata" },
    { value: "371", c_Estado: "OAX", label: "Santa Catarina Ticuá" },
    { value: "372", c_Estado: "OAX", label: "Santa Catarina Yosonotú" },
    { value: "373", c_Estado: "OAX", label: "Santa Catarina Zapoquila" },
    { value: "374", c_Estado: "OAX", label: "Santa Cruz Acatepec" },
    { value: "375", c_Estado: "OAX", label: "Santa Cruz Amilpas" },
    { value: "376", c_Estado: "OAX", label: "Santa Cruz de Bravo" },
    { value: "377", c_Estado: "OAX", label: "Santa Cruz Itundujia" },
    { value: "378", c_Estado: "OAX", label: "Santa Cruz Mixtepec" },
    { value: "379", c_Estado: "OAX", label: "Santa Cruz Nundaco" },
    { value: "380", c_Estado: "OAX", label: "Santa Cruz Papalutla" },
    { value: "381", c_Estado: "OAX", label: "Santa Cruz Tacache de Mina" },
    { value: "382", c_Estado: "OAX", label: "Santa Cruz Tacahua" },
    { value: "383", c_Estado: "OAX", label: "Santa Cruz Tayata" },
    { value: "384", c_Estado: "OAX", label: "Santa Cruz Xitla" },
    { value: "385", c_Estado: "OAX", label: "Santa Cruz Xoxocotlán" },
    { value: "386", c_Estado: "OAX", label: "Santa Cruz Zenzontepec" },
    { value: "387", c_Estado: "OAX", label: "Santa Gertrudis" },
    { value: "388", c_Estado: "OAX", label: "Santa Inés del Monte" },
    { value: "389", c_Estado: "OAX", label: "Santa Inés Yatzeche" },
    { value: "390", c_Estado: "OAX", label: "Santa Lucía del Camino" },
    { value: "391", c_Estado: "OAX", label: "Santa Lucía Miahuatlán" },
    { value: "392", c_Estado: "OAX", label: "Santa Lucía Monteverde" },
    { value: "393", c_Estado: "OAX", label: "Santa Lucía Ocotlán" },
    { value: "394", c_Estado: "OAX", label: "Santa María Alotepec" },
    { value: "395", c_Estado: "OAX", label: "Santa María Apazco" },
    { value: "396", c_Estado: "OAX", label: "Santa María la Asunción" },
    { value: "397", c_Estado: "OAX", label: "Heroica Ciudad de Tlaxiaco" },
    { value: "398", c_Estado: "OAX", label: "Ayoquezco de Aldama" },
    { value: "399", c_Estado: "OAX", label: "Santa María Atzompa" },
    { value: "400", c_Estado: "OAX", label: "Santa María Camotlán" },
    { value: "401", c_Estado: "OAX", label: "Santa María Colotepec" },
    { value: "402", c_Estado: "OAX", label: "Santa María Cortijo" },
    { value: "403", c_Estado: "OAX", label: "Santa María Coyotepec" },
    { value: "404", c_Estado: "OAX", label: "Santa María Chachoápam" },
    { value: "405", c_Estado: "OAX", label: "Villa de Chilapa de Díaz" },
    { value: "406", c_Estado: "OAX", label: "Santa María Chilchotla" },
    { value: "407", c_Estado: "OAX", label: "Santa María Chimalapa" },
    { value: "408", c_Estado: "OAX", label: "Santa María del Rosario" },
    { value: "409", c_Estado: "OAX", label: "Santa María del Tule" },
    { value: "410", c_Estado: "OAX", label: "Santa María Ecatepec" },
    { value: "411", c_Estado: "OAX", label: "Santa María Guelacé" },
    { value: "412", c_Estado: "OAX", label: "Santa María Guienagati" },
    { value: "413", c_Estado: "OAX", label: "Santa María Huatulco" },
    { value: "414", c_Estado: "OAX", label: "Santa María Huazolotitlán" },
    { value: "415", c_Estado: "OAX", label: "Santa María Ipalapa" },
    { value: "416", c_Estado: "OAX", label: "Santa María Ixcatlán" },
    { value: "417", c_Estado: "OAX", label: "Santa María Jacatepec" },
    { value: "418", c_Estado: "OAX", label: "Santa María Jalapa del Marqués" },
    { value: "419", c_Estado: "OAX", label: "Santa María Jaltianguis" },
    { value: "420", c_Estado: "OAX", label: "Santa María Lachixío" },
    { value: "421", c_Estado: "OAX", label: "Santa María Mixtequilla" },
    { value: "422", c_Estado: "OAX", label: "Santa María Nativitas" },
    { value: "423", c_Estado: "OAX", label: "Santa María Nduayaco" },
    { value: "424", c_Estado: "OAX", label: "Santa María Ozolotepec" },
    { value: "425", c_Estado: "OAX", label: "Santa María Pápalo" },
    { value: "426", c_Estado: "OAX", label: "Santa María Peñoles" },
    { value: "427", c_Estado: "OAX", label: "Santa María Petapa" },
    { value: "428", c_Estado: "OAX", label: "Santa María Quiegolani" },
    { value: "429", c_Estado: "OAX", label: "Santa María Sola" },
    { value: "430", c_Estado: "OAX", label: "Santa María Tataltepec" },
    { value: "431", c_Estado: "OAX", label: "Santa María Tecomavaca" },
    { value: "432", c_Estado: "OAX", label: "Santa María Temaxcalapa" },
    { value: "433", c_Estado: "OAX", label: "Santa María Temaxcaltepec" },
    { value: "434", c_Estado: "OAX", label: "Santa María Teopoxco" },
    { value: "435", c_Estado: "OAX", label: "Santa María Tepantlali" },
    { value: "436", c_Estado: "OAX", label: "Santa María Texcatitlán" },
    { value: "437", c_Estado: "OAX", label: "Santa María Tlahuitoltepec" },
    { value: "438", c_Estado: "OAX", label: "Santa María Tlalixtac" },
    { value: "439", c_Estado: "OAX", label: "Santa María Tonameca" },
    { value: "440", c_Estado: "OAX", label: "Santa María Totolapilla" },
    { value: "441", c_Estado: "OAX", label: "Santa María Xadani" },
    { value: "442", c_Estado: "OAX", label: "Santa María Yalina" },
    { value: "443", c_Estado: "OAX", label: "Santa María Yavesía" },
    { value: "444", c_Estado: "OAX", label: "Santa María Yolotepec" },
    { value: "445", c_Estado: "OAX", label: "Santa María Yosoyúa" },
    { value: "446", c_Estado: "OAX", label: "Santa María Yucuhiti" },
    { value: "447", c_Estado: "OAX", label: "Santa María Zacatepec" },
    { value: "448", c_Estado: "OAX", label: "Santa María Zaniza" },
    { value: "449", c_Estado: "OAX", label: "Santa María Zoquitlán" },
    { value: "450", c_Estado: "OAX", label: "Santiago Amoltepec" },
    { value: "451", c_Estado: "OAX", label: "Santiago Apoala" },
    { value: "452", c_Estado: "OAX", label: "Santiago Apóstol" },
    { value: "453", c_Estado: "OAX", label: "Santiago Astata" },
    { value: "454", c_Estado: "OAX", label: "Santiago Atitlán" },
    { value: "455", c_Estado: "OAX", label: "Santiago Ayuquililla" },
    { value: "456", c_Estado: "OAX", label: "Santiago Cacaloxtepec" },
    { value: "457", c_Estado: "OAX", label: "Santiago Camotlán" },
    { value: "458", c_Estado: "OAX", label: "Santiago Comaltepec" },
    { value: "459", c_Estado: "OAX", label: "Santiago Chazumba" },
    { value: "460", c_Estado: "OAX", label: "Santiago Choápam" },
    { value: "461", c_Estado: "OAX", label: "Santiago del Río" },
    { value: "462", c_Estado: "OAX", label: "Santiago Huajolotitlán" },
    { value: "463", c_Estado: "OAX", label: "Santiago Huauclilla" },
    { value: "464", c_Estado: "OAX", label: "Santiago Ihuitlán Plumas" },
    { value: "465", c_Estado: "OAX", label: "Santiago Ixcuintepec" },
    { value: "466", c_Estado: "OAX", label: "Santiago Ixtayutla" },
    { value: "467", c_Estado: "OAX", label: "Santiago Jamiltepec" },
    { value: "468", c_Estado: "OAX", label: "Santiago Jocotepec" },
    { value: "469", c_Estado: "OAX", label: "Santiago Juxtlahuaca" },
    { value: "470", c_Estado: "OAX", label: "Santiago Lachiguiri" },
    { value: "471", c_Estado: "OAX", label: "Santiago Lalopa" },
    { value: "472", c_Estado: "OAX", label: "Santiago Laollaga" },
    { value: "473", c_Estado: "OAX", label: "Santiago Laxopa" },
    { value: "474", c_Estado: "OAX", label: "Santiago Llano Grande" },
    { value: "475", c_Estado: "OAX", label: "Santiago Matatlán" },
    { value: "476", c_Estado: "OAX", label: "Santiago Miltepec" },
    { value: "477", c_Estado: "OAX", label: "Santiago Minas" },
    { value: "478", c_Estado: "OAX", label: "Santiago Nacaltepec" },
    { value: "479", c_Estado: "OAX", label: "Santiago Nejapilla" },
    { value: "480", c_Estado: "OAX", label: "Santiago Nundiche" },
    { value: "481", c_Estado: "OAX", label: "Santiago Nuyoó" },
    { value: "482", c_Estado: "OAX", label: "Santiago Pinotepa Nacional" },
    { value: "483", c_Estado: "OAX", label: "Santiago Suchilquitongo" },
    { value: "484", c_Estado: "OAX", label: "Santiago Tamazola" },
    { value: "485", c_Estado: "OAX", label: "Santiago Tapextla" },
    { value: "486", c_Estado: "OAX", label: "Villa Tejúpam de la Unión" },
    { value: "487", c_Estado: "OAX", label: "Santiago Tenango" },
    { value: "488", c_Estado: "OAX", label: "Santiago Tepetlapa" },
    { value: "489", c_Estado: "OAX", label: "Santiago Tetepec" },
    { value: "490", c_Estado: "OAX", label: "Santiago Texcalcingo" },
    { value: "491", c_Estado: "OAX", label: "Santiago Textitlán" },
    { value: "492", c_Estado: "OAX", label: "Santiago Tilantongo" },
    { value: "493", c_Estado: "OAX", label: "Santiago Tillo" },
    { value: "494", c_Estado: "OAX", label: "Santiago Tlazoyaltepec" },
    { value: "495", c_Estado: "OAX", label: "Santiago Xanica" },
    { value: "496", c_Estado: "OAX", label: "Santiago Xiacuí" },
    { value: "497", c_Estado: "OAX", label: "Santiago Yaitepec" },
    { value: "498", c_Estado: "OAX", label: "Santiago Yaveo" },
    { value: "499", c_Estado: "OAX", label: "Santiago Yolomécatl" },
    { value: "500", c_Estado: "OAX", label: "Santiago Yosondúa" },
    { value: "501", c_Estado: "OAX", label: "Santiago Yucuyachi" },
    { value: "502", c_Estado: "OAX", label: "Santiago Zacatepec" },
    { value: "503", c_Estado: "OAX", label: "Santiago Zoochila" },
    { value: "504", c_Estado: "OAX", label: "Nuevo Zoquiápam" },
    { value: "505", c_Estado: "OAX", label: "Santo Domingo Ingenio" },
    { value: "506", c_Estado: "OAX", label: "Santo Domingo Albarradas" },
    { value: "507", c_Estado: "OAX", label: "Santo Domingo Armenta" },
    { value: "508", c_Estado: "OAX", label: "Santo Domingo Chihuitán" },
    { value: "509", c_Estado: "OAX", label: "Santo Domingo de Morelos" },
    { value: "510", c_Estado: "OAX", label: "Santo Domingo Ixcatlán" },
    { value: "511", c_Estado: "OAX", label: "Santo Domingo Nuxaá" },
    { value: "512", c_Estado: "OAX", label: "Santo Domingo Ozolotepec" },
    { value: "513", c_Estado: "OAX", label: "Santo Domingo Petapa" },
    { value: "514", c_Estado: "OAX", label: "Santo Domingo Roayaga" },
    { value: "515", c_Estado: "OAX", label: "Santo Domingo Tehuantepec" },
    { value: "516", c_Estado: "OAX", label: "Santo Domingo Teojomulco" },
    { value: "517", c_Estado: "OAX", label: "Santo Domingo Tepuxtepec" },
    { value: "518", c_Estado: "OAX", label: "Santo Domingo Tlatayápam" },
    { value: "519", c_Estado: "OAX", label: "Santo Domingo Tomaltepec" },
    { value: "520", c_Estado: "OAX", label: "Santo Domingo Tonalá" },
    { value: "521", c_Estado: "OAX", label: "Santo Domingo Tonaltepec" },
    { value: "522", c_Estado: "OAX", label: "Santo Domingo Xagacía" },
    { value: "523", c_Estado: "OAX", label: "Santo Domingo Yanhuitlán" },
    { value: "524", c_Estado: "OAX", label: "Santo Domingo Yodohino" },
    { value: "525", c_Estado: "OAX", label: "Santo Domingo Zanatepec" },
    { value: "526", c_Estado: "OAX", label: "Santos Reyes Nopala" },
    { value: "527", c_Estado: "OAX", label: "Santos Reyes Pápalo" },
    { value: "528", c_Estado: "OAX", label: "Santos Reyes Tepejillo" },
    { value: "529", c_Estado: "OAX", label: "Santos Reyes Yucuná" },
    { value: "530", c_Estado: "OAX", label: "Santo Tomás Jalieza" },
    { value: "531", c_Estado: "OAX", label: "Santo Tomás Mazaltepec" },
    { value: "532", c_Estado: "OAX", label: "Santo Tomás Ocotepec" },
    { value: "533", c_Estado: "OAX", label: "Santo Tomás Tamazulapan" },
    { value: "534", c_Estado: "OAX", label: "San Vicente Coatlán" },
    { value: "535", c_Estado: "OAX", label: "San Vicente Lachixío" },
    { value: "536", c_Estado: "OAX", label: "San Vicente Nuñú" },
    { value: "537", c_Estado: "OAX", label: "Silacayoápam" },
    { value: "538", c_Estado: "OAX", label: "Sitio de Xitlapehua" },
    { value: "539", c_Estado: "OAX", label: "Soledad Etla" },
    { value: "540", c_Estado: "OAX", label: "Villa de Tamazulápam del Progreso" },
    { value: "541", c_Estado: "OAX", label: "Tanetze de Zaragoza" },
    { value: "542", c_Estado: "OAX", label: "Taniche" },
    { value: "543", c_Estado: "OAX", label: "Tataltepec de Valdés" },
    { value: "544", c_Estado: "OAX", label: "Teococuilco de Marcos Pérez" },
    { value: "545", c_Estado: "OAX", label: "Teotitlán de Flores Magón" },
    { value: "546", c_Estado: "OAX", label: "Teotitlán del Valle" },
    { value: "547", c_Estado: "OAX", label: "Teotongo" },
    { value: "548", c_Estado: "OAX", label: "Tepelmeme Villa de Morelos" },
    { value: "549", c_Estado: "OAX", label: "Tezoatlán de Segura y Luna" },
    { value: "550", c_Estado: "OAX", label: "San Jerónimo Tlacochahuaya" },
    { value: "551", c_Estado: "OAX", label: "Tlacolula de Matamoros" },
    { value: "552", c_Estado: "OAX", label: "Tlacotepec Plumas" },
    { value: "553", c_Estado: "OAX", label: "Tlalixtac de Cabrera" },
    { value: "554", c_Estado: "OAX", label: "Totontepec Villa de Morelos" },
    { value: "555", c_Estado: "OAX", label: "Trinidad Zaachila" },
    { value: "556", c_Estado: "OAX", label: "La Trinidad Vista Hermosa" },
    { value: "557", c_Estado: "OAX", label: "Unión Hidalgo" },
    { value: "558", c_Estado: "OAX", label: "Valerio Trujano" },
    { value: "559", c_Estado: "OAX", label: "San Juan Bautista Valle Nacional" },
    { value: "560", c_Estado: "OAX", label: "Villa Díaz Ordaz" },
    { value: "561", c_Estado: "OAX", label: "Yaxe" },
    { value: "562", c_Estado: "OAX", label: "Magdalena Yodocono de Porfirio Díaz" },
    { value: "563", c_Estado: "OAX", label: "Yogana" },
    { value: "564", c_Estado: "OAX", label: "Yutanduchi de Guerrero" },
    { value: "565", c_Estado: "OAX", label: "Villa de Zaachila" },
    { value: "566", c_Estado: "OAX", label: "San Mateo Yucutindoo" },
    { value: "567", c_Estado: "OAX", label: "Zapotitlán Lagunas" },
    { value: "568", c_Estado: "OAX", label: "Zapotitlán Palmas" },
    { value: "569", c_Estado: "OAX", label: "Santa Inés de Zaragoza" },
    { value: "570", c_Estado: "OAX", label: "Zimatlán de Álvarez" },
]

export default municipio_data;