import React from "react";
import {
	Card,
	CardBody,Container, Row,Col,CardTitle

} from 'reactstrap';

import { Toast } from 'primereact/toast';
import moment from 'moment';
import Select from 'react-select';
import RutaService  from 'controllers/ruta/rutaservice';
import ClienteService  from 'controllers/cliente/clienteservice';


const options = [];

class CrearReporteFalla extends React.Component {
	constructor(props){
		super(props);
		this.state={

		}
		this.servicioR = new RutaService();;
		this.servicio = new ClienteService();
		this.servicio.getClientes().then(data =>  {
			let arrClientes = [];
			data.forEach(item=>{
				arrClientes.push({value:item.id,label:item.razon_social,rfc:item.rfc,id:item.id});

			});
			this.setState({clientes: arrClientes});
		});

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit(event) {
				event.preventDefault();
				this.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Información guardada correctamente'});
				return;
				 console.log(this.state);
				let that = this;

				let datos ={};
				const data = new FormData();
				datos.broker = this.state.broker;
				datos.cliente_id = this.state.cliente_id;
				datos.contacto1 = this.state.contacto1;
				datos.contacto2 = this.state.contacto2;
				datos.id_ruta = this.state.id_ruta;
				datos.operativo = this.state.operativo;
				datos.planta_filial = this.state.planta_filial;
				datos.rfc = this.state.rfc;
				data.append("ruta",JSON.stringify(datos));
				data.append("contrato",this.state.contrato);
				data.append("asignacion",this.state.asignacion);

				this.servicioR.guardarRuta(data).then(res =>  {
						if(res && res.result == 'OK'){

							that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Información guardada correctamente'});
							setTimeout(function(){
									 	that.props.history.push('/app/clientes/rutas/consulta');
							 }, 500);

						}else if(res && res.result == 'EXISTE'){
							that.growl.show({severity: 'error', summary: 'El Id de ruta '+this.state.id_ruta+' ya se encuentra registrado', detail: 'Ingrese otro código o consulte su información para editarla.'});
						}
				});
	}

	render() {

		return (
			<Card>
			<CardTitle  className="bg-light border-bottom p-3 mb-0">ALTA SEMÁFORO</CardTitle>
				<CardBody>
				<Toast ref={(el) => this.growl = el} />
				<form >
				<Row>
					<Col xs="6">
							<div className="form-group">
								<label></label><br/>
									<input type="text"  value={this.state.unidad} className="form-control"/>
							</div>

					</Col>
					<Col xs="6">
							<div className="form-group">
								<label></label>
								<input type="text"  value={this.state.unidad} className="form-control"/>
							</div>
					</Col>
				</Row>


				<br/><br/><br/>


						<center> <button className="btn btn-success" onClick={this.handleSubmit} >Guardar</button></center>
					</form>
				</CardBody>
			</Card>
		);
	}
}

export default CrearReporteFalla;
