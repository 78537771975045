import React, { Component } from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {MultiSelect} from 'primereact/multiselect';
import CartaPorteService  from 'controllers/carta-porte/carta-porte-service';
import {Button} from 'primereact/button';
import {Sidebar} from 'primereact/sidebar';
import {Dialog} from 'primereact/dialog';
import EditarCartaPorte from './editar';
import {urlPdf} from '../../../constants';

export default class CartaPorteListado extends Component {

    constructor() {
        super();
        this.state = {
            globalFilter: null,filtros:true,
        };
        this.servicioCarta = new CartaPorteService();
        this.eliminar = this.eliminar.bind(this);
        this.editar = this.editar.bind(this);
    }

    componentDidMount() {
      if(this.props.parametros){
        this.servicioCarta.getCartasPortes(this.props.parametros).then(data => this.setState({cars: data}));
      }else {
        this.servicioCarta.getCartasPorte().then(data => this.setState({cars: data}));
      }
    }

    eliminar(column){
      this.setState({visible2:true,selectedItemIndex:column.rowIndex,selectedItem:column.rowData});

    }

    editar(column){
      //this.setState({visible:true,item:column.rowData});
      this.props.history.push({
         pathname:'/app/carta-porte/editar',
         state: column.rowData
       });
    }

    verCartaPorte(column){
      window.open(urlPdf+'carta_porte.php?folio='+column.rowData.folio,"width=200,height=100");

    }

    actionTemplate(rowData, column) {

      // console.log('rowData', rowData);
      return (<div>
        <Button
          type="button" icon="pi pi-pencil" onClick={e=> this.editar(column)}
          className=""/><Button
          type="button" icon="pi pi-trash" onClick={e=> this.eliminar(column)}
           className="p-button-warning"
        /><button
        type="button" className="btn" onClick={e=> this.verCartaPorte(column)}

      >Ver carta porte</button>
      </div>);
    }

    confirmar_borrado(){
      this.setState({visible2:false});
      this.setState({
        cars: this.state.cars.filter((val,i) => i !== this.state.selectedItemIndex)
      });
      let that = this;
      this.servicioCarta.eliminarCartaPorte(this.state.selectedItem.id).then(res =>  {
          if(res && res.result == 'OK'){
            that.props.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Registro borrado'});
          }
      });

    }
    render() {
        const footer = (
          <div>
                <Button label="Sí" className='p-button-warning mr-2' icon="pi pi-check" onClick={this.confirmar_borrado.bind(this)} />
                <Button label="No" className='p-button-warning' icon="pi pi-times" onClick={e=> this.setState({visible2:false})}/>
            </div>

        );
        let header = (
            <div style={{'textAlign':'left'}}>
                <i className="pi pi-search" style={{margin:'4px 4px 0 0'}}></i>
                <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="Filtro" size="50"/>
            </div>
        );

        return (<div>

          <Dialog header="Editar"  visible={this.state.visible} style={{width: '50vw',height:'90vh',overflow:'scroll'}} modal={true} onHide={() => this.setState({visible: false,visible2:false	})}>

            <EditarCartaPorte values={this.state.item} growl={this.props.growl} onSaved={()=> this.setState({visible:false})}/>
          </Dialog>
          <Dialog header="Eliminar"  footer={footer} visible={this.state.visible2} style={{width: '50vw'}} modal={true} onHide={() => this.setState({visible: false,visible2:false	})}>
            ¿Confirma eliminar?
          </Dialog>
          <DataTable value={this.state.cars} paginator={true} rows={10} header={header} globalFilter={this.state.globalFilter}>
              <Column field="folio" header="Folio" filter={this.state.filtros} />
              <Column field="expedicion" header="Expedición" filter={this.state.filtros} />
              <Column field="facturar_a" header="Facturar a" filter={this.state.filtros} />
              <Column field="remitente" header="Remitente" filter={this.state.filtros} />
              <Column field="destinatario" header="Destinatario" filter={this.state.filtros}  />

              <Column field="op" header="Operador" filter={this.state.filtros}  />
              <Column body={this.actionTemplate.bind(this)} header="Opciones" style={{ textAlign: 'center'}} />

          </DataTable>
          </div>
        );
    }
}
