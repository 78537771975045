import React from "react";
import { Card, CardBody, Container, Col, Row, CardTitle } from "reactstrap";
import Select from "react-select";
import UnidadService from "controllers/unidad/unidadService";
import PCalendar from '../../ui-components/calendar';
import moment from "moment";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import Catalogos from "controllers/catalogos/CatalogoService";
import { Checkbox } from "primereact/checkbox";
import { Panel } from 'primereact/panel';


const tipoYear = [
  { value: "1990", label: "1990" },
  { value: "1991", label: "1991" },
  { value: "1992", label: "1992" },
  { value: "1993", label: "1993" },
  { value: "1994", label: "1994" },
  { value: "1995", label: "1995" },
  { value: "1996", label: "1996" },
  { value: "1997", label: "1997" },
  { value: "1998", label: "1998" },
  { value: "1999", label: "1999" },
  { value: "2000", label: "2000" },
  { value: "2001", label: "2001" },
  { value: "2002", label: "2002" },
  { value: "2003", label: "2003" },
  { value: "2004", label: "2004" },
  { value: "2005", label: "2005" },
  { value: "2006", label: "2006" },
  { value: "2007", label: "2007" },
  { value: "2008", label: "2008" },
  { value: "2009", label: "2009" },
  { value: "2010", label: "2010" },
  { value: "2011", label: "2011" },
  { value: "2012", label: "2012" },
  { value: "2013", label: "2013" },
  { value: "2014", label: "2014" },
  { value: "2015", label: "2015" },
  { value: "2016", label: "2016" },
  { value: "2017", label: "2017" },
  { value: "2018", label: "2018" },
  { value: "2019", label: "2019" },
  { value: "2020", label: "2020" },
  { value: "2021", label: "2021" },
  { value: "2022", label: "2022" },
];


class CrearUnidades extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // tipo_unidad_id:"",
      fecha_adquisicion: "",
      numero_factura: "",
      numero_pedimento: "",
      placas: "",
      eco: "",
      // marca_id:"",
      serie_vin: "",
      numero_motor: "",
      numero_baja_placas: "",
      transmision: "",
      eje_delantero: "",
      eje_tracero: "",
      modelo: "",
      year: "",
      rodada_rin: "",
      ratio: "",
      hazmat: false,
      tipo_unidades: []
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getImageInput = this.getImageInput.bind(this);
    this.servicio = new UnidadService();
    this.servicioT = new Catalogos();

    this.servicioT.getTransmisiones().then((data) => {
      let arrTransmisiones = [];
      data.forEach((item) => {
        arrTransmisiones.push({
          value: item.transmision,
          label: item.transmision,
        });
      });
      //	arrTransmisiones = arrTransmisiones.concat(transmision);
      this.setState({ transmisiones: arrTransmisiones });
    });

    this.servicioT.getRatios().then((data) => {
      let arrRatios = [];
      data.forEach((item) => {
        arrRatios.push({ value: item.ratio, label: item.ratio });
      });
      //arrRatios = arrRatios.concat(ratios);
      this.setState({ ratios: arrRatios });
    });

    this.servicioT.getRin().then((data) => {
      let arrRin = [];
      data.forEach((item) => {
        arrRin.push({ value: item.rodada_rin, label: item.rodada_rin });
      });
      //arrRin = arrRin.concat(rodada);
      this.setState({ rodada: arrRin });
    });

    this.servicioT.getMarcaUnidad().then((data) => {
      console.log("getMarcaUnidad", data);
      let arrMarca = [];
      data.forEach((item) => {
        arrMarca.push({ value: item.marca, label: item.marca });
      });
      //arrRin = arrRin.concat(rodada);
      this.setState({ marcas: arrMarca });
    });

    this.servicioT.getTipoUnidad().then((data) => {
      console.log("getTipoUnidad", data);
      let arrTipoUnidad = [];
      data.forEach((item) => {
        arrTipoUnidad.push({ value: item.nombre, label: item.nombre });
      });
      //arrRin = arrRin.concat(rodada);
      this.setState({ tipo_unidades: arrTipoUnidad });
    });
    this.getCommonDataFromChilds = this.getCommonDataFromChilds.bind(this);

  }
  handleSubmit(event) {
    event.preventDefault();

    let datos = {};
    const data = new FormData();
    datos.fecha_adquisicion = this.state.fecha_adquisicion;
    datos.numero_factura = this.state.numero_factura;
    datos.numero_pedimento = this.state.numero_pedimento;
    datos.placas = this.state.placas;
    datos.eco = this.state.eco;
    datos.serie_vin = this.state.serie_vin;
    datos.numero_motor = this.state.numero_motor;
    datos.numero_baja_placas = this.state.numero_baja_placas;
    datos.transmision = this.state.transmision;
    datos.eje_delantero = this.state.eje_delantero;
    datos.eje_tracero = this.state.eje_tracero;
    datos.modelo = this.state.modelo;
    datos.year = this.state.year;
    datos.rodada_rin = this.state.rodada_rin;
    datos.ratio = this.state.ratio;
    datos.tipo_unidad = this.state.tipo_unidad;
    datos.marca = this.state.marca;
    datos.hazmat = this.state.hazmat;

    data.append("unidad", JSON.stringify(datos));
    // let cartasporte = [];
    // cartasporte.push({ factura: this.state.factura });
    // cartasporte.push({ pedimento: this.state.pedimento });
    // cartasporte.push({ tarjeta_circulacion: this.state.tarjeta_circulacion });
    // data.append("cartas_porte", cartasporte);
    data.append("factura", this.state.factura);
    data.append("pedimento", this.state.pedimento);
    data.append("tarjeta_circulacion", this.state.tarjeta_circulacion);

    data.append("verificacion_ecologica", this.state.verificacion_ecologica);
    data.append("verificacion_fisico_mecanica", this.state.verificacion_fisico_mecanica);
    data.append("verificacion_americana", this.state.verificacion_americana);
    data.append("poliza_de_seguro", this.state.poliza_de_seguro);
    data.append("opcion_de_compra", this.state.opcion_de_compra);
    
    for (var value of data.values()) {
      console.log("finalData appended  values", value);
    }

    let that = this;
    let mensajes = [];

    if (!this.state.tipo_unidad) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione el tipo de unidad",
      });
    }
    if (!this.state.fecha_adquisicion && this.state.fecha_adquisicion == "") {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Ingrese la fecha de adquisición",
      });
    }
    // if (!this.state.numero_factura) {
    //   mensajes.push({
    //     severity: "error",
    //     summary: "Información incompleta",
    //     detail: "Ingrese el número factura",
    //   });
    // }
    // if (!this.state.factura) {
    //   mensajes.push({
    //     severity: "error",
    //     summary: "Información incompleta",
    //     detail: "Seleccione el archivo de la factura",
    //   });
    // }
    // if (!this.state.numero_pedimento) {
    //   mensajes.push({
    //     severity: "error",
    //     summary: "Información incompleta",
    //     detail: "Ingrese el número de pedimento",
    //   });
    // }
    if (!this.state.pedimento) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione el archivo del pedimento",
      });
    }
    if (!this.state.eco) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Ingrese el número económico",
      });
    }
    if (!this.state.placas) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Ingrese el número de placas",
      });
    }
    if (!this.state.tarjeta_circulacion) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione el archivo de la tarjerta de circulación",
      });
    }
    if (!this.state.marca) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione la marca de la unidad",
      });
    }
    if (!this.state.year) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione el año ",
      });
    }
    // if (!this.state.transmision) {
    //   mensajes.push({
    //     severity: "error",
    //     summary: "Información incompleta",
    //     detail: "Seleccione el tipo de transmisión",
    //   });
    // }
    // if (!this.state.ratio) {
    //   mensajes.push({
    //     severity: "error",
    //     summary: "Información incompleta",
    //     detail: "Seleccione el valor del ratio",
    //   });
    // }
    if (!this.state.rodada_rin) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione la rodada de rin",
      });
    }
    if (!this.state.serie_vin) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Ingrese el número VIN",
      });
    }

    if (mensajes.length) {
      this.growl.show(mensajes);
    }
    // else {
    //   console.log("unidades crear.js - finalData", data, typeof data);
    // }
    else {
      this.servicio.guardarUnidad(data).then((res) => {
        if (res && res.result == "OK") {
          that.growl.show({
            severity: "success",
            summary: "Operación completada",
            detail: "Información guardada correctamente",
          });
          setTimeout(function () {
            that.props.history.push("/app/unidades/consulta");
          }, 500);
        } else if (res && res.result == "EXISTE") {
          if (res.repetidos.placas) {
            that.growl.show({
              severity: "error",
              summary:
                "Las placas " +
                that.state.placas +
                " ya se encuentra registrada",
              detail:
                "Ingrese otro código o consulte su información para editarla.",
            });
          }
          if (res.repetidos.eco) {
            that.growl.show({
              severity: "error",
              summary:
                "El número económico " +
                that.state.eco +
                " ya se encuentra registrado",
              detail:
                "Ingrese otro código o consulte su información para editarla.",
            });
          }
          if (res.repetidos.serie_vin) {
            that.growl.show({
              severity: "error",
              summary:
                "La serie vin " +
                that.state.serie_vin +
                " ya se encuentra registrada",
              detail:
                "Ingrese otro código o consulte su información para editarla.",
            });
          }
          if (res.repetidos.numero_motor) {
            that.growl.show({
              severity: "error",
              summary:
                "El número de motor " +
                that.state.numero_motor +
                " ya se encuentra registrado",
              detail:
                "Ingrese otro código o consulte su información para editarla.",
            });
          }
        }
      });
    }
  }
  getCommonDataFromChilds(data)
	{
		switch (data.label) {
			case "fecha_adquisicion":
				this.setState({fecha_adquisicion: moment(data.value).format('YYYY-MM-DD').toString()});
				break;
		}
	}

  getImageInput(title,key)
  {
    return (
      <div className="image-group">
        <label className="title">{title}</label>
        <br />
        <div className="form-group">
          {!this.state[key]?.name && 
            <div className="custom-file-upload">
              <label htmlFor={`file-upload-${key}`} className="p-button-warning p-button m-0 w-100">
                <i className="pi pi-cloud-upload"></i> Seleccionar archivo
              </label>
              <input
                id={`file-upload-${key}`}
                type="file"
                style={{ display: "none" }}
                onChange={(e) =>
                  this.setState({ [key]: e.target.files[0] })
                }
                chooseLabel="Seleccionar Archivo"
              />
            </div>
          }
          {this.state[key]?.name && 
            <div className="file-info d-flex justify-content-between">
              <div className="label-info">
                {this.state[key].name}
              </div>
              <div className="action-info">
                <i
                  onClick={() => {
                    this.setState({ [key]: null });
                  }}
                  className="pi pi-trash"
                  style={{ fontSize: "1.5em", cursor: "pointer" }}
                ></i>
                </div>                            
            </div>
          }
        </div>
      </div>
    )
  }
  render() {
    
    return (
      <div className="container">
        <Panel header="Alta De Unidad">
          <Container>
            <Row>
              <Col>
                <Toast ref={(el) => (this.growl = el)} />
                <form onSubmit={this.handleSubmit}>
                <Row>
                    <Col xs="12" md="4">
                      <div className="form-group">
                        <label> Número Económico:</label>
                        <input
                          type="text"
                          name="eco"
                          onChange={(e) =>
                            this.setState({ eco: e.target.value })
                          }
                          className="form-control"
                        />
                      </div>
                    </Col>
                    <Col xs="12" md="4">
                      <div className="form-group">
                        <label> Placas: </label>
                        <input
                          type="text"
                          name="placas"
                          onChange={(e) =>
                            this.setState({ placas: e.target.value })
                          }
                          className="form-control"
                        />
                      </div>
                    </Col>
                    <Col xs="12" md="4">
                      <div className="form-group">
                        <label> Fecha de adquisicón: </label>
                        <br />
                        <PCalendar
                          label={"fecha_adquisicion"}
                          getCommonDataFromChilds={this.getCommonDataFromChilds}
                          showIcon
                          value={this.state.fecha_adquisicion}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="5">
                      <div className="form-group">
                        <label> Tipo de unidad: </label>
                        <div className="form-group">
                          <Dropdown
                            className="w-100"
                            options={this.state.tipo_unidades}
                            editable={true}
                            value={this.state.tipo_unidad}
                            onChange={(e) => this.setState({  tipo_unidad: e.value })}
                            placeholder="Seleccione el tipo"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs="12" md="5">
                      <div className="form-group">
                        <label> Marca: </label>
                        <br />
                        <Dropdown
                          className="w-100"
                          options={this.state.marcas}
                          editable={true}
                          value={this.state.marca}
                          onChange={(e) => this.setState({ marca: e.value })}
                          placeholder="Seleccione una marca"
                        />
                      </div>
                    </Col>
                    <Col xs="12" md="2">
                      <div className="form-group">
                        <label> Is Hazmat ? </label>
                        <br />
                        <div className="">
                          <Checkbox
                            inputId="cb1"
                            checked={this.state.hazmat}
                            onChange={(e) =>
                              this.setState({ hazmat: e.checked })
                            }
                          />
                          <label htmlFor="cb1" className="p-checkbox-label m-0 pl-2">
                            Hazmat
                          </label>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="4">
                      {this.getImageInput("Cargar factura","factura")}
                    </Col>
                    <Col xs="12" md="4">
                      {this.getImageInput("Cargar pedimento","pedimento")}
                    </Col>
                    <Col xs="12" md="4">
                      {this.getImageInput("Tarjeta de circulación","tarjeta_circulacion")}
                    </Col>
                  </Row>
                  
                  <Row>
                    <Col xs="12" md="4">
                      {this.getImageInput("Verificación ecológica","verificacion_ecologica")}
                    </Col>
                    <Col xs="12" md="4">
                      {this.getImageInput("Verificación físico-mecánica","verificacion_fisico_mecanica")}
                    </Col>
                    <Col xs="12" md="4">
                      {this.getImageInput("Verificación Americana","verificacion_americana")}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="6">
                      {this.getImageInput("Póliza de seguro","poliza_de_seguro")}
                    </Col>
                    <Col xs="12" md="6">
                      {this.getImageInput("Opción de compra","opcion_de_compra")}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="4">
                      <div className="form-group">
                        <label> Serie (VIN):</label>
                        <input
                          type="text"
                          name="serie_vin"
                          onChange={(e) =>
                            this.setState({ serie_vin: e.target.value })
                          }
                          className="form-control"
                        />
                      </div>
                    </Col>
                    
                    <Col xs="12" md="4">
                      <div className="form-group">
                        <label> Descripción del catálogo: </label>

                        <input
                          type="text"
                          value={this.state.modelo}
                          onChange={(e) =>
                            this.setState({ modelo: e.target.value })
                          }
                          className="form-control"
                        />
                      </div>
                    </Col>
                    <Col xs="12" md="4">
                      <div>
                        <label> Motor: </label>
                        <input
                          type="text"
                          name="numero_motor"
                          readOnly={this.state.tipo_unidad === "Remolque"}
                          onChange={(e) =>
                            this.setState({ numero_motor: e.target.value })
                          }
                          className="form-control"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="6">
                      <div className="form-group">
                        <label> Año: </label>
                        <Select
                         className="w-100"
                          options={tipoYear}
                          onChange={(e) => this.setState({ year: e.value })}
                          placeholder="Selecciona un año"
                        />
                      </div>
                    </Col>
                    <Col xs="12" md="6">
                      <div className="form-group">
                        <label> Rodada de Rin:</label>
                        <br />
                        <Dropdown
                          className="w-100"
                          options={this.state.rodada}
                          editable={true}
                          value={this.state.rodada_rin}
                          placeholder="Seleccione una rodada"
                          onChange={(e) =>
                            this.setState({ rodada_rin: e.value })
                          }
                        />
                      </div>
                    </Col>

                  </Row>

                  <center>
                    {" "}
                    <button className="btn btn-primary">Guardar</button>
                  </center>
                </form>
              </Col>
            </Row>
          </Container>
      </Panel>
      </div>
    );
  }
}

export default CrearUnidades;
