import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import ClienteService from "controllers/cliente/clienteservice";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import EditarCliente from "./editar";
import { Toast } from "primereact/toast";

export default class ClienteListado extends Component {
  constructor() {
    super();
    this.state = {
      globalFilter: null,
    };
    this.servicioClientes = new ClienteService();
    this.eliminar = this.eliminar.bind(this);
    this.editar = this.editar.bind(this);
  }

  componentDidMount() {
    if (this.props.parametros) {
      this.servicioClientes
        .getFiltroClientes(this.props.parametros)
        .then((data) => this.setState({ cars: data }));
    } else {
      this.servicioClientes
        .getClientes()
        .then((data) => this.setState({ cars: data }));
    }
  }

  eliminar(column, rowData) {
    this.setState({
      visible2: true,
      selectedItemIndex: column.rowIndex,
      selectedItem: rowData,
    });
  }

  editar(rowData) {
    // console.log("column", column);
    // console.log('rowData', rowData);
    //      this.setState({visible:true,item:column.rowData});
    this.props.history.push({
      pathname: "/app/cliente/editar",
      state: rowData,
    });
  }

  confirmar_borrado() {
    this.setState({ visible2: false });
    this.setState({
      cars: this.state.cars.filter(
        (val, i) => i !== this.state.selectedItemIndex
      ),
    });
    let that = this;
    this.servicioClientes
      .eliminarCliente(this.state.selectedItem.id)
      .then((res) => {
        if (res && res.result == "OK") {
          that.growl.show({
            severity: "success",
            summary: "Operación completada",
            detail: "Registro borrado",
          });
        }
      });
  }

  actionTemplate(rowData, column) {
    console.log("column", column);
    return (
      <div>
        <Button
          type="button"
          icon="pi pi-pencil"
          onClick={(e) => this.editar(rowData)}
          className="p-button-warning mr-2"
        />
        <Button
          type="button"
          icon="pi pi-trash"
          onClick={(e) => this.eliminar(column, rowData)}
          className="p-button-warning"
        />
      </div>
    );
  }

  render() {
    const footer = (
      <div>
        <Button
          label="Sí"
          className='p-button-warning mr-2'
          icon="pi pi-check"
          onClick={this.confirmar_borrado.bind(this)}
        />
        <Button
          label="No"
          className='p-button-warning'
          icon="pi pi-times"
          onClick={(e) => this.setState({ visible2: false })}
        />
      </div>
    );
    let header = (
      <div style={{ textAlign: "left" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
        <InputText
          type="search"
          onInput={(e) => this.setState({ globalFilter: e.target.value })}
          placeholder="Global Search"
          size="50"
        />
      </div>
    );

    return (
      <div>
        <Toast ref={(el) => (this.growl = el)} />
        <Dialog
          header="Editar"
          visible={this.state.visible}
          style={{ width: "50vw", height: "90vh", overflow: "scroll" }}
          modal={true}
          onHide={() => this.setState({ visible: false, visible2: false })}
        >
          <EditarCliente
            values={this.state.item}
            growl={this.growl}
            onSaved={() => this.setState({ visible: false })}
          />
        </Dialog>
        <Dialog
          header="Eliminar"
          footer={footer}
          visible={this.state.visible2}
          style={{ width: "50vw" }}
          modal={true}
          onHide={() => this.setState({ visible: false, visible2: false })}
        >
          ¿Confirma eliminar?
        </Dialog>
        <DataTable
          value={this.state.cars}
          paginator={true}
          rows={10}
          header={header}
          globalFilter={this.state.globalFilter}
        >
          <Column field="razon_social" header="Razón social" />
          <Column field="rfc" header="R.F.C." />
         
          <Column field="moneda" header="Moneda" />
          <Column field="calle" header="Calle" />
          <Column field="colonia" header="Colonia" />
          <Column field="municipio" header="Municipio" />
          <Column field="moneda" header="Moneda" />
          <Column
            body={this.actionTemplate.bind(this)}
            header="Opciones"
            style={{ textAlign: "center" }}
          />
        </DataTable>
      </div>
    );
  }
}
