import React from "react";
import {
	Card,
	CardBody,Container, Row,Col,CardTitle

} from 'reactstrap';

import { Toast } from 'primereact/toast';
import moment from 'moment';
import Select from 'react-select';
import ControlSelloService from  'controllers/control-sellos/service';

const options = [];

class CrearReporteFalla extends React.Component {
	constructor(props){
		super(props);
		this.state={

		}
		this.servicio = new ControlSelloService();

		this.handleSubmit = this.handleSubmit.bind(this);
	}




	componentDidMount() {
	  let values = this.props.location.state;
	  console.log(values);
	  if(values){
	    this.setState({
				numero_sello:values.numero_sello,
				tipo:values.tipo,

	      id:values.id,
	      titulo:"EDITAR",
	      sobreescribir: true
	    });
	  }

	}

	handleSubmit(event) {
				event.preventDefault();

				 console.log(this.state);
				let that = this;

				let datos ={};
				const data = new FormData();
				datos.numero_sello = this.state.numero_sello;
				datos.tipo = this.state.tipo;

				data.append("ruta",JSON.stringify(datos));
				data.append("contrato",this.state.contrato);
				data.append("asignacion",this.state.asignacion);


				let funcion = console.log;
				if(this.state.sobreescribir){
					funcion = this.servicio.actualizarSello;
					datos.id = this.state.id;
				}else{
				  funcion = this.servicio.guardarSello;
				}

				funcion(datos).then(res =>  {
						if(res && res.result == 'OK'){

							that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Información guardada correctamente'});
							setTimeout(function(){
									 	that.props.history.push('/app/control-sellos/listado');
							 }, 500);

						}else if(res && res.result == 'EXISTE'){
							that.growl.show({severity: 'error', summary: 'El número de sello  '+this.state.numero_sello+' ya se encuentra registrado', detail: 'Ingrese otro número o consulte su información para editarla.'});
						}
				});
	}

	render() {

		return (
			<Card>
			<CardTitle  className="bg-light border-bottom p-3 mb-0">EDITAR SELLO</CardTitle>
				<CardBody>
				<Toast ref={(el) => this.growl = el} />
				<form >
				<Row>
					<Col xs="6">
							<div className="form-group">
								<label>Número de sello</label><br/>
									<input type="text"  value={this.state.numero_sello}  onChange={e => this.setState({numero_sello : e.target.value})}  className="form-control"/>
							</div>

					</Col>
					<Col xs="6">
							<div className="form-group">
								<label>Tipo</label><br/>
									<input type="text"  value={this.state.tipo}  onChange={e => this.setState({tipo : e.target.value})}  className="form-control"/>
							</div>

					</Col>
				</Row>





				<br/><br/><br/>


						<center> <button className="btn btn-success" onClick={this.handleSubmit} >Guardar</button></center>
					</form>
				</CardBody>
			</Card>
		);
	}
}

export default CrearReporteFalla;
