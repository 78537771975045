import React, {TextInput} from "react";
import {
	Card,
	CardBody,Col, Row, Container,CardTitle,

} from 'reactstrap';
import _ from 'lodash'
import Select from 'react-select';
import ClienteService  from 'controllers/cliente/clienteservice';
import OperadorServices  from 'controllers/operador/operador-service';
import UnidadService  from 'controllers/unidad/unidadService';
import CartaPorteService from 'controllers/carta-porte/carta-porte-service.js';
import EmbarqueService from 'controllers/embarque/embarque-service';
import { Toast } from 'primereact/toast';
import RemolqueServices from 'controllers/remolque/remolqueservice';
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import RutaService  from 'controllers/ruta/rutaservice';
import {Calendar} from 'primereact/calendar';
import moment from 'moment';
import {urlPdf} from '../../../constants';


const options = [
	{value:"1",label:"Flete nacional"},
	{value:"2",label:"Flete exportación"},
	{value:"3",label:"Renta"}
]


const opciones_remitente = [
	{label:"Remitente de prueba 1",value:"Remitente de prueba 1"}
]

const opciones_destinatario = [
	{label:"Destinatario de prueba 1",value:"Destinatario de prueba 1"}
]


const designaciones =[
	{label:"Flete  o traslado de mercancía",value:"Flete  o traslado de mercancía"},
    {label:"Renta de Tracto camión",value:"Renta de Tracto camión"},
    {label:"Renta de Remolque",value:"Renta de Remolque"},
    {label:"Renta de Rabón",value:"Renta de Rabón"},
    {label:"Demoras por  carga y descarga",value:"Demoras por  carga y descarga"},
    {label:"Otros",value:"Otros"}
];




class EditarCartaPorte extends React.Component {

	constructor(props){
			super(props)
			this.servicio = new ClienteService();
			this.servicioU = new UnidadService();
			this.servicioO = new OperadorServices();
			this.servicioR =  new RemolqueServices;
			this.servicioRuta = new RutaService();
			this.servicioEmbarque = new EmbarqueService();

			this.state = {
				clientes:[],
				rfc:"",
				unidades:[],
				embarques:[],
				lectura:false,
				exportacion_ck:true,
				designaciones: [],
				ClaveUnidad:[],
				ClaveUnidad_value:{},
				designaciones_value:{},
				editableImporte: false
			};
			this.servicioCarta = new CartaPorteService();

			this.servicio.getClientes().then(data =>  {
				let arrClientes = [];
				data.forEach(item=>{
					arrClientes.push({value:item.id,label:item.razon_social,rfc:item.rfc});

				});
				this.setState({clientes: arrClientes});
			});

			this.servicioEmbarque.listadoFolios().then(data =>{
				let arrFolios = [];
				data.forEach((item) => {
					arrFolios.push({value:item.id,label:item.folio});
				});
				this.setState({embarques:arrFolios});

			});


			this.servicioU.getUnidades().then(data =>  {
				let arrUnidades = [];
				data.forEach(item=>{
					arrUnidades.push({value:item.id,label:item.placas});

				});
				this.setState({unidades: arrUnidades});
			});

			this.servicioR.getRemolques().then(data =>  {
				let arrRemolques= [];
				data.forEach(item=>{
					arrRemolques.push({value:item.id,label:item.placas});

				});
				this.setState({remolques: arrRemolques});
			});


			this.servicioO.getOperadores().then(data =>  {
				let arrOperadores = [];
				data.forEach(item=>{
					arrOperadores.push({value:item.usuario_id,label:item.nombre});

				});
				this.setState({operadores: arrOperadores});
			});
			this.toggleCheckboxValue = this.toggleCheckboxValue.bind(this);


			this.handleSubmit = this.handleSubmit.bind(this);

		// 	this.servicioCarta.obtenerFolio().then(data=> {
		// 		// console.log(data);
		// 		this.setState({folio:data[0].folio})
		//
		// });
		this.seleccionar_cliente = this.seleccionar_cliente.bind(this);
		this.seleccionar_ruta = this.seleccionar_ruta.bind(this);
		this.seleccionar_version = this.seleccionar_version.bind(this);
		this.asignaCarta = this.asignaCarta.bind(this);
		this.asignaCarta2 = this.asignaCarta2.bind(this);
		this.opciones = this.opciones.bind(this);
		}


		seleccionar_cliente(event){

			this.setState({rutas:[],ruta_id:""});

			this.setState({facturar_a: event.label,rfc: event.rfc})
			this.servicioRuta.getRutas(event.value).then(data =>  {
				let arrRutas = [];
				data.forEach(item=>{
					arrRutas.push({value:item.id,label:item.id_ruta});

				});
				this.setState({rutas: arrRutas});
			});


		}

			seleccionar_ruta(event){
				this.setState({versiones:[],ruta_id:""});
				this.setState({ruta_id: event.value});
				this.servicioRuta.getVersiones(event.value).then(data =>  {
					let arrVersiones = [];

					data.forEach(item=>{
						arrVersiones.push(
							{value:item.id,label:item.version,obj:item});
							if( this.props.values.version_id === item.id){
								this.seleccionar_version({value:item.id,label:item.version,obj:item});
							}
										});

					this.setState({versiones: arrVersiones});
				});



			}
			componentDidUpdate(prevProps){
				if(prevProps.values !== this.props.values){
					if(this.props.values.ClaveUnidad_value)
					{
						var clavun = JSON.parse(this.props.values.ClaveUnidad_value);
						this.SearchClaveUnidad(clavun.label)
					}
					if(this.props.values.designaciones_value)
					{
						var des_val = JSON.parse(this.props.values.designaciones_value)
						this.SearchDesignaciones(des_val.label)
					}
					this.setState({
						id: this.props.values.id,
						cliente_id: this.props.values.cliente_id,
						facturar_a: this.props.values.facturar_a,
						rfc: this.props.values.rfc,
						folio: this.props.values.folio,
						ruta_id: this.props.values.ruta_id,
						version_id: this.props.values.version_id,
						remitente: this.props.values.remitente,
						destinatario: this.props.values.destinatario,
						exportacion_ck: this.props.values.exportacion_ck,
						exportacion: this.props.values.exportacion,
						remision: this.props.values.remision,
						emision: this.props.values.emision,
						material_peligroso: this.props.values.material_peligroso,
						operador: this.props.values.operador,
						operador_id: this.props.values.operador_id,
						unidad: this.props.values.unidad,
						unidad_id: this.props.values.unidad_id,
						remolque: this.props.values.remolque,
						remolque_id: this.props.values.remolque_id,
						fecha_creacion: this.props.values.fecha_creacion,
						fecha_entrega:  this.props.values.fecha_entrega? moment(this.props.values.fecha_entrega, 'YYYY-MM-DD').toDate():null,
						designacion:this.props.values.designacion,
						concepto: this.props.values.concepto,
						caja_id : this.props.values.caja_id,
						stop1:this.props.values.stop1,
						stop2:this.props.values.stop2,
						stop3:this.props.values.stop3,
						stop4:this.props.values.stop4,
						stop5:this.props.values.stop5,
						stop6:this.props.values.stop6,
						importe:this.props.values.importe,
					});
					setTimeout(() => {
						this.setState(
							{
								ClaveUnidad_value:JSON.parse(this.props.values.ClaveUnidad_value),
							designaciones_value:JSON.parse(this.props.values.designaciones_value)})						
					}, 1000);

					this.seleccionar_cliente({rfc:this.props.values.rfc,label: this.props.values.facturar_a,value:this.props.values.cliente_id});
					if(this.props.values.ruta_id){
						this.seleccionar_ruta({value:this.props.values.ruta_id});
					}
				}
			}

			seleccionar_version(event){
				let arrStops = [];
				if(event.obj.direccion_origen)
					arrStops[0]= {label: event.obj.direccion_origen , value:event.obj.direccion_origen };
				if(event.obj.direccion_final  )
					arrStops[11]= {label: event.obj.direccion_final , value: event.obj.direccion_final  };
				if(event.obj.stop1 )
					arrStops[1]= {label: event.obj.stop1 , value:event.obj.stop1 };
				if(event.obj.stop2 )
					arrStops[2]= {label: event.obj.stop2 , value:event.obj.stop2 };
				if(event.obj.stop3 )
					arrStops[3]= {label: event.obj.stop3 , value:event.obj.stop3 };
				if(event.obj.stop4 )
					arrStops[4]= {label: event.obj.stop4 , value:event.obj.stop4 };
				if(event.obj.stop5 )
					arrStops[5]= {label: event.obj.stop5 , value:event.obj.stop5 };
				if(event.obj.stop6 )
					arrStops[6]= {label: event.obj.stop6 , value:event.obj.stop6 };
				if(event.obj.stop7 )
					arrStops[7]= {label: event.obj.stop7 , value:event.obj.stop7 };
				if(event.obj.stop8 )
					arrStops[8]= {label: event.obj.stop8 , value:event.obj.stop8 };
				if(event.obj.stop9 )
					arrStops[9]= {label: event.obj.stop9 , value:event.obj.stop9 };
				if( event.obj.stop10)
					arrStops[10]={label: event.obj.stop10 ,value: event.obj.stop10};
					this.setState({
						stops:arrStops,version_id:event.value,
						_destinatarios:arrStops,
						_remitentes:arrStops
					});
			}

			imprimirCartaPorte(e){
				e.preventDefault();
				window.open( urlPdf + 'carta_porte.php?folio='+this.state.folio,"width=200,height=100");
				//this.setState({visible:false});
			}


		handleSubmit(event) {
					event.preventDefault();
					// console.log(this.state);
					let that = this;
						this.state.fecha_entrega = moment(this.state.fecha_entrega).format('YYYY-MM-DD').toString()

					this.servicioCarta.actualizarCartaPorte(this.state).then(res =>  {
							if(res && res.result == 'OK'){
								that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Información guardada correctamente'});
								//that.props.onSaved();
								setTimeout(()=>{
									this.props.history.push('/app/carta-porte/consulta');
								},500)

							}
					});
		}


			 seleccionar_remitente(e){
				 let opciones  = this.state.stops.filter(option => option.label !== e.value );
				 this.setState({
					 _destinatarios: opciones,
					 remitente: e.value
				 });
			 }

			 seleccionar_destinatario(e){
				 let opciones  = this.state.stops.filter(option => option.label !== e.value );
				 this.setState({
					 _remitentes: opciones,
					 destinatario: e.value
				 });
			 }

			 asignaCarta(e){
				 e.preventDefault();
				 let that = this;
				 this.servicioCarta.verAsignacion(this.state.id).then(data => {
					 if(data && data.result == 'OK' && data.data.length != 0){

					 	that.setState({embarque:data.data[0].embarque});
				 		}
				 });
				 this.setState({visible:true});
			 }

			 asignaCarta2(e){
				 e.preventDefault();
				 let that = this;
				 this.servicioCarta.asignarCartaPorte(this.state.id,this.state.embarque).then(data => {
					 if(data && data.result == 'OK'){
						  that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Carta asignada correctemente'});
					 }
				 });
			 }




	toggleCheckboxValue() {
     this.setState({exportacion_ck: !this.state.exportacion_ck});
		 if(this.state.exportacion_ck){
			 this.setState({exportacion:"Sí"});
		 }else{
			 this.setState({exportacion:"No"});
		 }
   }

	 opciones(){
		 //solo se mostraran las opciones cuenado este en modo lectura
		 if(!this.state.lectura)
		 return (<Col xs="6">
		 <div className="form-group">
			 <label>Cambiar estado</label><br/>

			 <Button  onClick={this.asignaCarta} className="btn btn-danger" label="Asignar" />&nbsp;
			 <button  onClick={e=> this.setState({visible2:true})} className="btn btn-danger" >Cancelar</button>
		 </div>
		 </Col>);
	 }
	 SearchDesignaciones = (e) => {
		this.servicioO.get_designaciones(e).then(res => {
			this.setState({ designaciones: res })
		});
	}
	SearchClaveUnidad = (e) => {
		this.servicioO.get_ClaveUnidad(e).then(res => {
			this.setState({ ClaveUnidad: res })
		});
	}
	changeDesignaciones = (e) => {
        let option_postal_code = _.find(this.state.designaciones, ["value", e.value]);
        this.setState({ designaciones_value: JSON.stringify(option_postal_code) })
	}
	changeClaveUnidad = (e) => {
        let option_postal_code = _.find(this.state.ClaveUnidad, ["value", e.value]);
        this.setState({ ClaveUnidad_value: JSON.stringify(option_postal_code) })
	}
	render() {

		const es = {
			firstDayOfWeek: 1,
			dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
			dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
			dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
			monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
			monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"]
		};
		const footer = (
			<div>
						<Button label="Sí" className='p-button-warning mr-2' icon="pi pi-check"  />
						<Button label="No" className='p-button-warning' icon="pi pi-times" />
				</div>

		);
		return (<div>
				<Toast ref={(el) => this.growl = el} />
				<Dialog header="Asignar"  visible={this.state.visible} style={{width: '50vw',height:'90vh',backgroundColor:'white'}} modal={true} onHide={() => this.setState({visible: false	})}>
					<center>
						<label>Seleccione el embarque al que desea asignar la carta porte</label>
						<Select options={this.state.embarques} value={this.state.embarques.filter(option => option.value === this.state.embarque)} onChange={(e)=> this.setState({embarque:e.value})}  placeholder="Seleccione embarque"  required/><br/>
						<Button  onClick={this.asignaCarta2} className="btn btn-danger" label="Asignar" />&nbsp;
					</center>
				</Dialog>
				<Dialog header="Cancelar"  footer={footer} visible={this.state.visible2} style={{width: '50vw'}} modal={true} onHide={() => this.setState({visible: false,visible2:false	})}>
					¿Confirma cancelar, la carta porte ya no podrá editar?
				</Dialog>
					<form onSubmit={this.handleSubmit}>
				  <Container>
						<Row>
							<Col xs="6">

							<div className="form-group">
							<label> Facturar a:		</label>
							<Select isDisabled={this.state.lectura} options={this.state.clientes} value={this.state.clientes.filter(option => option.label === this.state.facturar_a)} onChange={this.seleccionar_cliente}  placeholder="Seleccione un cliente"  required/>
							</div>
							</Col>
							<Col xs="3">

							<div className="form-group">
							<label> Tipo de emisión:	</label>
							<Select isDisabled={true} value={options.filter(option => option.value === this.state.emision)} options={options}   placeholder="Seleccione emisión"  required/>

							</div>
							</Col>

							<Col xs="3">
							<div className="form-group">
							<label> Folio
								<input type="text" name="folio" value={this.state.folio}  className="form-control" readOnly/>
							</label>
							</div>
							</Col>

						</Row>
						<Row>
							<Col xs="6">
							<div className="form-group">
							<label> R.F.C.:</label>
								<input type="text" name="rfc" value={this.state.rfc} className="form-control" readOnly/>
							</div>
							</Col>
								<Col xs="6">
								<div className="form-group">
								<label> Importe:</label>
									<input type="text" name="importe" value={this.state.importe} className="form-control" readOnly/>
								</div>
								</Col>
						</Row>
						<Row>
								<Col xs="6">
									<div className="form-group">
										<label>Ruta</label>
										<Select isDisabled={this.state.lectura} options={this.state.rutas} value={this.state.rutas?this.state.rutas.filter(option=> option.value === this.state.ruta_id):""} onChange={this.seleccionar_ruta}  placeholder="Elegir ruta"/>
									</div>
								</Col>

							<Col xs="6" >
								<div className="form-group">
									<label>Versión de ruta</label>
									<Select isDisabled={this.state.lectura} options={this.state.versiones}  value={this.state.versiones?this.state.versiones.filter(option=> option.value === this.state.version_id):""}  onChange={this.seleccionar_version }  placeholder="Elegir versión"/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs="6">
							<div className="form-group">
							<label> Remitente:</label>
								<Select isDisabled={this.state.lectura} onChange={e=> this.seleccionar_remitente(e)} options={this.state._remitentes}  value={this.state._remitentes?this.state._remitentes.filter(option => option.label === this.state.remitente ):""} placeholder="Seleccione una planta"  />

							</div>
							</Col>
							<Col xs="6">
							<div className="form-group">
							<label> Destinatario:

							</label>
								<Select isDisabled={this.state.lectura} onChange={e=> this.seleccionar_destinatario(e)}  value={this.state._destinatarios?this.state._destinatarios.filter(option => option.label === this.state.destinatario ):""} options={this.state._destinatarios} placeholder="Seleccione una planta" />
							</div>
							</Col>
						</Row>

						<Row>
						<Col>
						<div className="form-check" >
							 <input type="checkbox" disabled={this.state.lectura}  checked={this.state.exportacion_ck} onChange={this.toggleCheckboxValue} className="form-check-input" id="exportacion"/>
							 <label className="form-check-label">Exportación</label>
						 </div>
						</Col>
						</Row>
						<Row>
						<Col>
							<div className="form-group">
								<input className="form-control" name="direccion" value={this.state.exportacion} readOnly={!this.state.exportacion_ck||this.state.lectura} onChange={e=> this.setState({exportacion: e.target.value})} />
							</div>
						</Col>
						</Row>
						<Row>
						<Col xs="6">
							<div className="form-group">
								<label>Remisión</label>
								<input className="form-control" readOnly={this.state.lectura} name="remision" value={this.state.remision} onChange={e=> this.setState({remision: e.target.value})} />
							</div>
						</Col>
						<Col xs="6">
						 	<div className="form-check" style={{paddingTop:'30px'}} >
							 <input type="checkbox" className="form-check-input" checked={this.state.material_peligroso} disabled={this.state.lectura} onChange={e=> this.setState({material_peligroso: !this.state.material_peligroso})} />
							 <label className="form-check-label">Material o Residuo Peligroso</label>
							</div>
						</Col>
						</Row>
						<Row>
						<Col xs="6">
						<div className="form-group">
						<label> Operador:</label>
							<Select isDisabled={this.state.lectura} options={this.state.operadores} value={this.state.operadores?this.state.operadores.filter(option => option.value === this.state.operador_id):""} placeholder="Seleccione un operador" onChange={e=> this.setState({operador_id: e.value})}/>
						</div>
						</Col>
						<Col xs="6">
						<div className="form-group">
						<label> Unidad:</label>
							<Select isDisabled={this.state.lectura} options={this.state.unidades} value={this.state.unidades?this.state.unidades.filter(option=> option.value === this.state.unidad_id):""} placeholder="Seleccione una unidad" onChange={e=> this.setState({unidad_id: e.value})}/>
						</div>
						</Col>
						</Row>
						<Row>
						<Col xs="6">
						<div className="form-group">
						<label> Remolque:</label>
							<Select isDisabled={this.state.lectura} options={this.state.remolques} value={this.state.remolques?this.state.remolques.filter(option => option.value === this.state.remolque_id ) :""} placeholder="Seleccione un remolque" onChange={e=> this.setState({remolque_id: e.value})}/>
						</div>
						</Col>
						<Col xs="6">
						<div className="form-group">
						<label> Caja Drop:</label>
							<Select isDisabled={this.state.lectura} options={this.state.remolques} value={this.state.remolques?this.state.remolques.filter(option => option.value === this.state.caja_id ) :""} placeholder="Seleccione una caja" onChange={e=> this.setState({caja_id: e.value})}/>
						</div>
						</Col>
						</Row>
						{/* <Row>
						<Col xs="6">
						<div className="form-group">
						<label> Descripción de mercancías:</label>
							<Select isDisabled={this.state.lectura} options={designaciones} value={designaciones.filter(option=> option.value === this.state.designacion)} placeholder="Seleccione una designación" onChange={e=> this.setState({designacion: e.value})}/>
						</div>
						</Col>
						<Col xs="6">
						<div className="form-group">
						<label> Descripción de servicio:</label>
							<input readOnly={this.state.lectura} className="form-control" value={this.state.concepto} placeholder="Concepto o descripción" onChange={e=> this.setState({concepto: e.target.value})}/>
						</div>
						</Col>
						</Row> */}
						<Row>
								<Col xs="6">
									<div className="form-group">
										<label> Descripción de mercancías:</label>
										<Select options={this.state.designaciones}
											onInputChange={(e) => { this.SearchDesignaciones(e) }}
											required="true"
											value={this.state.designaciones_value}
											onChange={(e) => { this.changeDesignaciones(e) }}
											placeholder="Seleccione Codigo postal" />

									</div>
								</Col>
								<Col xs="6">
									<div className="form-group">
										<label> Clave Producto:</label>
										<input type="text" name="rfc" value={this.state.designaciones_value['value']} className="form-control" readOnly />
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs="6">
									<div className="form-group">
										<label> Clave Unidad:</label>
										
										<Select options={this.state.ClaveUnidad}
											onInputChange={(e) => { this.SearchClaveUnidad(e) }}
											required="true"
											value={this.state.ClaveUnidad_value}
											onChange={(e) => { this.changeClaveUnidad(e) }}
											placeholder="Seleccione Clave Unidad" />
									</div>
								</Col>
								<Col xs="6">
									<div className="form-group">
										<label> Nombre Unidad:</label>
										<input type="text" name="rfc" value={this.state.ClaveUnidad_value['value']} className="form-control" readOnly />
									</div>
								</Col>

							</Row>
						<Row>
						<Col xs="6" >
								<div className="form-group">
									<label>Plazo Previsto de Entrega</label><br/>
									<Calendar disabled={this.state.lectura}   dateFormat="dd/mm/yy" value={this.state.fecha_entrega} onChange={(e) => { this.setState({fecha_entrega:  moment(e.value).format('YYYY-MM-DD').toString()}); }} showIcon={true} placeholder="dd/mm/aaaa"/>
								</div>
							</Col>
							<Col xs="6">
								<div className="form-group">

									{this.opciones()}
								</div>
							</Col>
						</Row>
					</Container>
					<center> <button className="btn btn-success" >Guardar</button>      <button  onClick={this.imprimirCartaPorte.bind(this)} className="btn btn-default" >Ver carta porte</button></center>
					</form>
					</div>
		);
	}
}

/*
<Row>
	<Col xs="6">
		<div className="form-group">
			<label><input disabled={this.state.lectura} type="checkbox" onChange={e=> this.setState({ch_stop1: !this.state.ch_stop1})}/>Editar Stop 1</label>
			<Select options={this.state.stops} onChange={e=> this.setState({stop1:e.value})}  value={this.state.stops?this.state.stops.filter(option => option.value=== this.state.stop1):""} isDisabled={!this.state.ch_stop1||this.state.lectura}   placeholder="Seleccione un Stop"/>
		</div>
	</Col>
	<Col xs="6">
		<div className="form-group">
			<label><input disabled={this.state.lectura} type="checkbox" onChange={e=> this.setState({ch_stop2: !this.state.ch_stop2})}/>Editar Stop 2</label>
			<Select options={this.state.stops} onChange={e=> this.setState({stop2:e.value})}  value={this.state.stops?this.state.stops.filter(option => option.value=== this.state.stop2):""}  isDisabled={!this.state.ch_stop2||this.state.lectura}   placeholder="Seleccione un Stop"/>
		</div>
	</Col>
</Row>
<Row>
	<Col xs="6">
		<div className="form-group">
			<label><input disabled={this.state.lectura} type="checkbox" onChange={ e=> this.setState({ch_stop3: !this.state.ch_stop3})}/>Editar Stop 3</label>
			<Select options={this.state.stops} onChange={e=> this.setState({stop3:e.value})}  value={this.state.stops?this.state.stops.filter(option => option.value=== this.state.stop3):""} isDisabled={!this.state.ch_stop3||this.state.lectura}  placeholder="Seleccione un Stop" />
		</div>
	</Col>
	<Col xs="6">
		<div className="form-group">
			<label><input disabled={this.state.lectura} type="checkbox" onChange={e=> this.setState({ch_stop4:!this.state.ch_stop4})}/>Editar Stop 4</label>
			<Select options={this.state.stops} onChange={e=> this.setState({stop4:e.value})}  value={this.state.stops?this.state.stops.filter(option => option.value=== this.state.stop4):""} isDisabled={!this.state.ch_stop4||this.state.lectura}  placeholder="Seleccione un Stop" />
		</div>
	</Col>
</Row>
<Row>
	<Col xs="6">
		<div className="form-group">
			<label><input disabled={this.state.lectura} type="checkbox" onChange={e=> this.setState({ch_stop5: !this.state.ch_stop5})}/>Editar Stop 5</label>
			<Select options={this.state.stops} onChange={e=> this.setState({stop5:e.value})}  value={this.state.stops?this.state.stops.filter(option => option.value=== this.state.stop5):""} isDisabled={!this.state.ch_stop5||this.state.lectura}  placeholder="Seleccione un Stop" />
		</div>
	</Col>
	<Col xs="6">
		<div className="form-group">
			<label><input disabled={this.state.lectura} type="checkbox" onChange={e=> this.setState({ch_stop6:!this.state.ch_stop6})}/>Editar Stop 6</label>
			<Select options={this.state.stops}  onChange={e=> this.setState({stop6	:e.value})}  value={this.state.stops?this.state.stops.filter(option => option.value=== this.state.stop6):""} isDisabled={!this.state.ch_stop6||this.state.lectura}  placeholder="Seleccione un Stop" />
		</div>
	</Col>
</Row>
*/

export default EditarCartaPorte;
