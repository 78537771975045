import React from "react";
import {
	Card,
	CardBody,
	CardTitle
} from 'reactstrap';
import Listado from './programado/listado';
import {Button} from 'primereact/button';


class ReportesFallas extends React.Component {
	agregar(){
	  this.props.history.push({
	   pathname:'/app/mantenimiento/predictivo/programado/agregar'
	 });
	}

	calendario(){
		this.props.history.push({
		 pathname:'/app/mantenimiento/predictivo/programado/calendario'
	 });
	}
	render() {
		return (
			<Card>
				<CardTitle  className="bg-light border-bottom p-3 mb-0">LISTADO DE MANTENIMIENTO PROGRAMADO
					<button className="btn btn-info pull-right" onClick={this.calendario.bind(this)} ><i class="pi pi-calendar"/>Ver calendario</button>
				</CardTitle>
				<CardBody>
				<Button label='Agendar' icon="fa fa-plus" className="pull-right" onClick={this.agregar.bind(this)}/>
				<br/><br/>
					<Listado history={this.props.history} />
				</CardBody>
			</Card>
		);
	}
}

export default ReportesFallas;
