import React from "react";
import {
	Card,
	CardBody,Container, Row,Col,CardTitle

} from 'reactstrap';

import { Toast } from 'primereact/toast';
import moment from 'moment';
import Select from 'react-select';
import ControlSelloService from  'controllers/control-sellos/service';
import EmbarqueService from 'controllers/embarque/embarque-service';

const options = [];

class CrearReporteFalla extends React.Component {
	constructor(props){
		super(props);
		this.state={
			embarques:[]
		}
		this.servicioEmbarque = new EmbarqueService();
		this.servicio = new ControlSelloService();

		this.servicioEmbarque.listadoFolios().then(data =>{
			let arrFolios = [];
			data.forEach((item) => {
				arrFolios.push({value:item.id,label:item.folio});
			});
			this.setState({embarques:arrFolios});

		});


		this.handleSubmit = this.handleSubmit.bind(this);
	}




	componentDidMount() {
	  let values = this.props.location.state;
	  console.log(values);
	  if(values){
	    this.setState({
				sello_original : values.sello_original,
				sello_reemplazo:values.sello_reemplazo,
				embarque:values.embarque,

	      id:values.id,
	      titulo:"EDITAR",
	      sobreescribir: true
	    });
	  }

	}

	handleSubmit(event) {
				event.preventDefault();

				 console.log(this.state);
				let that = this;

				let datos ={};
				const data = new FormData();
				datos.sello_original = this.state.sello_original;
				datos.sello_reemplazo = this.state.sello_reemplazo;
				datos.embarque = this.state.embarque;


				data.append("ruta",JSON.stringify(datos));
				data.append("contrato",this.state.contrato);
				data.append("asignacion",this.state.asignacion);


				let funcion = console.log;
				if(this.state.sobreescribir){
				  funcion = this.servicio.actualizarCambioSello;
				  datos.id = this.state.id;
				}else{
				  funcion = this.servicio.guardarCambioSello;
				}

				funcion(datos).then(res =>  {
						if(res && res.result == 'OK'){

							that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Información guardada correctamente'});
							setTimeout(function(){
									 	that.props.history.push('/app/control-sellos/consulta');
							 }, 500);

						}else if(res && res.result == 'EXISTE'){
							that.growl.show({severity: 'error', summary: 'El número de sello  '+this.state.numero_sello+' ya se encuentra registrado', detail: 'Ingrese otro número o consulte su información para editarla.'});
						}
				});
	}

	render() {

		return (
			<Card>
			<CardTitle  className="bg-light border-bottom p-3 mb-0">EDITAR SELLO</CardTitle>
				<CardBody>
				<Toast ref={(el) => this.growl = el} />
				<form >
				<Row>
					<Col xs="6">
							<div className="form-group">
								<label>Número sello original</label><br/>
									<input type="text"  value={this.state.sello_original}  onChange={e => this.setState({sello_original : e.target.value})}  className="form-control"/>
							</div>

					</Col>
					<Col xs="6">
							<div className="form-group">
								<label>Número sello reemplazo</label><br/>
									<input type="text"  value={this.state.sello_reemplazo}  onChange={e => this.setState({sello_reemplazo : e.target.value})}  className="form-control"/>
							</div>

					</Col>
				<Col xs="6">
					<div className="form-group">
						<label>Embarque</label><br/>

							<Select options={this.state.embarques} value={this.state.embarques.filter(option => option.value == this.state.embarque)} onChange={(e)=> this.setState({embarque:e.value})}  placeholder="Seleccione embarque"  required/><br/>
					</div>
				</Col>
				</Row>





				<br/><br/><br/>


						<center> <button className="btn btn-success" onClick={this.handleSubmit} >Guardar</button></center>
					</form>
				</CardBody>
			</Card>
		);
	}
}

export default CrearReporteFalla;
