import axios from 'axios';
import {url} from '../../constants';
export default class Service{

			getTransmisiones(){
					return 	axios.get(url + 'catalogo/transmisiones')
	                .then(res => res.data);
			}

			getRin(){
					return 	axios.get(url + 'catalogo/rin')
	                .then(res => res.data);
			}

			getMedida(){
					return 	axios.get(url + 'catalogo/medida')
	                .then(res => res.data);
			}
			
			getTipo(){
				return 	axios.get(url + 'catalogo/tipo_piso')
					.then(res => res.data);
			}


			getMarca(){
					return 	axios.get(url + 'catalogo/marca')
	                .then(res => res.data);
			}

			getMarcaUnidad(){
					return 	axios.get(url + 'unidad/marca')
									.then(res => res.data);
			}

			getTipoUnidad(){
				return 	axios.get(url + 'unidad/tipo_unidades')
								.then(res => res.data);
			}


			getRatios(){
					return 	axios.get(url + 'catalogo/ratio')
	                .then(res => res.data);
			}


		 existe(data1,elemento){
				let size = data1.length;
				let contador = 0 ;
				let agregar = true;
				data1.forEach(item => {
					contador ++;
					if(elemento.value == item.value && elemento.label == item.label){
						return false;
					}else{

					}
					if(contador === size){
							return agregar;
					}
				});

			}


			getUnidad(){
				return 	axios.get(url + 'catalogo/unidades')
								.then(res => res.data);
			}

}
