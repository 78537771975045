import React from "react";
import {
	Col,Row
} from 'reactstrap';
import Select from 'react-select';
import LlantaService from 'controllers/llanta/llantaService';
import { Toast } from 'primereact/toast';
import {Dropdown} from 'primereact/dropdown';
import {Button} from 'primereact/button';
import Catalogos from 'controllers/catalogos/CatalogoService';


const motivo_baja =[
	{value:"Daños",label:"Daños"},
	{value:"Extravío",label:"Extravío"},
	{value:"Robo",label:"Robo"}
];

const years = [
		{value:"1990",label:"1990"},
		{value:"1991",label:"1991"},
		{value:"1992",label:"1992"},
		{value:"1993",label:"1993"},
		{value:"1994",label:"1994"},
		{value:"1995",label:"1995"},
		{value:"1996",label:"1996"},
		{value:"1997",label:"1997"},
		{value:"1998",label:"1998"},
		{value:"1999",label:"1999"},
		{value:"2000",label: "2000"},
		{value:"2001",label:"2001"},
		{value:"2002",label:"2002"},
		{value:"2003",label:"2003"},
		{value:"2004",label:"2004"},
		{value:"2005",label:"2005"},
		{value:"2006",label:"2006"},
		{value:"2007",label:"2007"},
		{value:"2008",label:"2008"},
		{value:"2009",label:"2009"},
		{value:"2010",label:"2010"},
		{value:"2011",label:"2011"},
		{value:"2012",label:"2012"},
		{value:"2013",label:"2013"},
		{value:"2014",label:"2014"},
		{value:"2015",label:"2015"},
		{value:"2016",label:"2016"},
		{value:"2017",label:"2017"},
		{value:"2018",label:"2018"},
		{value:"2019",label:"2019"},
		{value:"2020",label:"2020"},
		{value:"2021",label:"2021"},

]
const tipo_piso = [
	 {value: "Direccional" ,label : "Direccional"},
	 {value: "Traccion" ,label : "Traccion"},
	 {value: "XDHTA" ,label : "XDHTA"},
	 {value: "XZE" ,label : "XZE"},
	 {value: "XZY" ,label : "XZY"},
	 {value: "XT1" ,label : "XT1"}
];


const marca_llanta = [
	{value:"Goodyear", label:"Goodyear"},
	{value:"Firestone", label:"Firestone"},
	{value:"Stellmark", label:"Stellmark"},
	{value:"Linlong", label:"Linlong"},
	{value:"Michelin", label:"Michelin"},
	{value:"Remington", label:"Remington"},
	{value:"Yokohama", label:"Yokohama"},
	{value:"Dunlop", label:"Dunlop"},
	{value:"Bridgestone", label:"Bridgestone"},
	{value:"BF Goodrich", label:"Goodrich"},
	{value:"Continental", label:"Continental"},
	{value:"AEOLUS", label:"AEOLUS"},
	{value:"Dayton", label:"Dayton"},
	{value:"General", label:"General"},
	{value:"Kuhmo", label:"Kuhmo"},
	{value:"Toyo", label:"Toyo"},
	{value:"Uniroyal", label:"Uniroyal"},
	{value:"Supercargo", label:"Supercargo"},
	{value:"Green", label:"Green"},
	{value:"Ovilion", label:"Ovilion"},
	{value:"Samson", label:"Samson"},
	{value:"Daymontrail", label:"Daymontrail"}

];

const medidas_llanta = [
	{value: "11R22.5", label :"11R22.5" } ,
	{value: "295/75R22.5", label :"295/75R22.5" } ,
	{value: "275/80R22.5", label :"275/80R22.5" } ,
	{value: "11R24.5", label :"11R24.5" } ,
	{value: "275/80R24.5", label :"275/80R24.5" } ,
	{value: "285/75R24.5	", label :"285/75R24.5	" }
];

class EditarLlanta extends React.Component {
	constructor(props){
		super(props);
		this.servicio = new LlantaService();
		this.state={
			folio:"",
			marca:"",
			medida:"",
			folio_fabricacion:"",
			year:"",
			profundidad:"",
			origen:"",
			tipo:"",
			tipo_piso:"",
			orden_compra:""
		}

		this.servicioT = new Catalogos();

		this.servicioT.getMarca().then(data =>  {
			let arrMarcas = [];
			data.forEach(item=>{
				arrMarcas.push({value:item.marca,label:item.marca});

			});
	//		arrMarcas = arrMarcas.concat(marca_llanta);
			this.setState({marcas: arrMarcas});
		});
		this.servicioT.getMedida().then(data =>  {
			let arr = [];
			data.forEach(item=>{
				arr.push({value:item.medida,label:item.medida});

			});
	//		arr = arr.concat(medidas_llanta);;
			this.setState({medidas: arr});
		});
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick(event){
		event.preventDefault();
		let that = this;
		this.servicio.actualizarLlanta(this.state).then(res =>  {
				if(res && res.result == 'OK'){
					that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Información guardada correctamente'});
					setTimeout(function(){
                        that.props.history.push('/app/unidades/llantas/consultar')
					}, 500)
				}
		});
	}

	componentDidUpdate(prevProps){
		if(prevProps.values !== this.props.values){
			this.setState({
				id:this.props.values.id,
				folio:this.props.values.folio,
				folio_fabricacion:this.props.values.folio_fabricacion,
				marca:this.props.values.marca,
				medida:this.props.values.medida,
				orden_compra:this.props.values.orden_compra,
				origen:this.props.values.origen,
				profundidad:this.props.values.profundidad,
				tipo:this.props.values.tipo,
				tipo_piso:this.props.values.tipo_piso,
				year:this.props.values.year,
				motivo_baja: this.props.values.motivo_baja
			});
		}
	}


	render() {
		return(
					<form  onSubmit={this.handleClick}>
					<Toast ref={(el) => this.growl = el} />

						<Row>
							<Col xs="12" md="6">
								<div className="form-group">
									<label>Folio EXNAC</label>
									<input readOnly value={this.state.folio} onChange={e=>this.setState({folio:e.target.value})} type="text" className="form-control"/>
								</div>
							</Col>
							<Col xs="12" md="6">
								<div className="form-group">
									<label>Folio de fabricación</label>
									<input type="text" className="form-control" value={this.state.folio_fabricacion} onChange={e=>this.setState({folio_fabricacion:e.target.value})}/>
								</div>
							</Col>
							
						</Row>

						<Row>
							<Col xs="12" md="6">
								<div className="form-group">
									<label>Medida</label>
									<Select options={this.state.medidas} value={medidas_llanta.filter(option => option.label === this.state.medida)} onChange={e=>this.setState({medida:e.value})} placeholder="Elegir medida"/>
								</div>
							</Col>
							<Col xs="12" md="6">
								<div className="form-group">
									<label>Marca</label><br/>
									<Dropdown className="w-100" options={this.state.marcas} value={this.state.marca} onChange={e=>this.setState({marca:e.value})} placeholder="Elegir la marca..."/>
								</div>
							</Col>
						</Row>

						<Row>
							<Col xs="12" md="4">
								<div className="form-group">
									<label>Año de fabricación</label>
									<Select options={years} value={years.filter(option => option.label === this.state.year)} placeholder="Elegir Año" onChange={e=>this.setState({year:e.value})}/>
								</div>
							</Col>
							<Col xs="12" md="4">
								<div className="form-group">
									<label>Tipo de piso</label>
									<Select options={tipo_piso} value={tipo_piso.filter(option => option.label === this.state.tipo_piso)} onChange={e=> this.setState({tipo_piso:e.value})} placeholder="Elegir tipo de piso..."/>
								</div>
							</Col>
							<Col xs="12" md="4">
								<div className="form-group">
									<label>Motivo de baja</label>
									<Select options={motivo_baja} value={motivo_baja.filter(option => option.value === this.state.motivo_baja)}  onChange={e=> this.setState({motivo_baja:e.value})} placeholder="Elegir el motivo de baja..."/>
								</div>
							</Col>
							
						</Row>
						
						<Row>
							<Col xs="12" md="6">
								<div className="form-group">
									<label>Profundidad</label>
									<input type="text" className="form-control" value={this.state.profundidad} onChange={e=>this.setState({profundidad:e.target.value})}/>
								</div>
							</Col>
							<Col xs="12" md="6">
								<div className="form-group">
									<label>Número de Orden de Compra</label>
									<input type="text" className="form-control" value={this.state.orden_compra} onChange={e=> this.setState({orden_compra:e.target.value})}/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs="12" md="6">
								<div className="form-group">
									<label>Origen</label><br/>
									<label><input type="radio" checked={"Original" === this.state.origen} name="origen" value="Original" onChange={e=> this.setState({origen:e.target.value})}/> Original</label><br/>
									<label><input type="radio" checked={"Recubierta" === this.state.origen} name="origen" value="Recubierta" onChange={e=> this.setState({origen:e.target.value})}/> Recubierta</label>
								</div>
							</Col>
							<Col xs="12" md="6">
								<div className="form-group">
									<label>Tipo</label><br/>
									<label><input type="radio" checked={"Nueva" === this.state.tipo} name="tipo" value="Nueva" onChange={e=> this.setState({tipo:e.target.value})}/> Nueva</label><br/>
									<label><input type="radio" checked={"Usada" === this.state.tipo} name="tipo" value="Usada" onChange={e=> this.setState({tipo:e.target.value})}/> Usada</label>
								</div>
							</Col>
							<Col xs="12" className="text-center">
								<Button className="p-button-warning"  >Guardar</Button>
							</Col>
						</Row>

					</form>
		);
	}
}

export default EditarLlanta;
