import React, { Component } from "react";
import {
	Card,
	CardBody, Container, Col, Row, CardTitle
} from 'reactstrap';
import Select from 'react-select';
import { Button } from 'primereact/button';
import ClienteService from 'controllers/cliente/clienteservice';
import { Toast } from 'primereact/toast';
import { Fieldset } from 'primereact/fieldset';
import { InputMask } from 'primereact/inputmask';
import { Panel } from 'primereact/panel';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic'

import CustomAddress from "../CustomAddress";
import _ from 'lodash'
const options = [
	{ value: 'MXN', label: 'MXN' },
	{ value: 'USD', label: 'USD' }
]
const NacionalidadOption = [
	{ value: 'Nacional', label: 'Nacional' },
	{ value: 'Extranjero', label: 'Extranjero' }
]
const paises = [
	{ value: 'México', label: 'México' },
	{ value: 'Estados Unidos', label: 'Estados Unidos' }
]

const status = [
	{ value: "1", label: "Activo" },
	{ value: "0", label: "Inactivo" },
];


class CrearClientes extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			razon_social: "",
			rfc: "",
			identidad_tributaria: "",
			moneda: "",
			calle: "",
			colonia: "",
			municipio: "",
			estado: "",
			numero: "",
			nombre_contacto: "",
			telefono_contacto: "",
			email_contacto: "",
			codigo_cliente: "",
			nacionalidad: "",
			municipio_data: [],
			localidad_data: [],
			colonia_data: [],
			localidad: "",
			address_values: {},
		}
		this.handleSubmit = this.handleSubmit.bind(this);
		this.servicio = new ClienteService();

	}

	handleSubmit(event) {
		event.preventDefault();
		// console.log(this.state);
		let that = this;
		let mensajes = [];

		let datos = {};
		const data = {};
		datos.calle = this.state.calle;
		// datos.certificado = this.state.certificado;
		// datos.codigo_cliente = this.state.codigo_cliente;
		// datos.email_contacto = this.state.email_contacto;
		// datos.email_contacto_cuentas = this.state.email_contacto_cuentas;
		// datos.estatus = this.state.estatus;
		// datos.identidad_tributaria = this.state.identidad_tributaria;
		datos.moneda = this.state.moneda;
		datos.colonia = this.state.colonia
		datos.estado = this.state.estado.value
		datos.localidad = this.state.localidad

		datos.municipio = this.state.municipio;
		// datos.nombre_contacto = this.state.nombre_contacto;
		// datos.nombre_contacto_cuentas = this.state.nombre_contacto_cuentas;
		datos.numero = this.state.numero;
		// datos.pagina_web = this.state.pagina_web;
		datos.pais = this.state.pais;
		datos.razon_social = this.state.razon_social;
		datos.rfc = this.state.rfc;
		// datos.telefono_contacto = this.state.telefono_contacto;
		// datos.telefono_contacto_cuentas = this.state.telefono_contacto_cuentas;
		datos.address_values = this.state.address_values;
		console.log("CrearClientes -> handleSubmit -> datos", datos, JSON.stringify(datos))
		data["cliente"]= JSON.stringify(datos);
		if (this.state.archivo_licencia)
			data["archivo_licencia"] = this.state.archivo_licencia;

		if (!this.state.razon_social) {
			mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Escriba la razón social' });
		}
		if (!this.state.rfc) {
			mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Escriba el RFC' });
		}
		if (!this.state.moneda) {
			mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione la moneda' });
		}
		// if (!this.state.identidad_tributaria) {
		// 	mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Ingrese la información de identidad tributaria' });
		// }

		// if (!this.state.codigo_cliente) {
		// 	mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Ingrese el código del cliente' });
		// }

		if (mensajes.length) {
			this.growl.show(mensajes);
		} else {
			this.servicio.guardarCliente(data).then(res => {
				if (res && res.result == 'OK') {
					that.growl.show({ severity: 'success', summary: 'Operación completada', detail: 'Información guardada correctamente' });
					setTimeout(function () {
						that.props.history.push('/app/clientes/consulta');
					}, 500);
				} else if (res && res.result == 'EXISTE') {
					if (res.repetidos.razon_social) {
						that.growl.show({ severity: 'error', summary: 'El cliente ' + that.state.razon_social + ' ya se encuentra registrado', detail: 'Ingrese otro código o consulte su información para editarla.' });
					}
					// if (res.repetidos.codigo_cliente) {
					// 	that.growl.show({ severity: 'error', summary: 'El código de cliente ' + that.state.codigo_cliente + ' ya se encuentra registrado', detail: 'Ingrese otro código o consulte su información para editarla.' });
					// }
				}
			});
		}
	}

	seleccionar_nacionalidad = (event) => {
		if (event.value === "Nacional") {
			this.setState({ identidad_tributaria: "", nacionalidad: event.value, rfc: "" })
		} else {
			this.setState({ identidad_tributaria: "", nacionalidad: event.value, rfc: "XEXX010101001" })
		}
	}

	ReceiveAddress =async (data) => {
        // console.log("CrearClientes -> ReceiveAddress -> data", data)
		this.setState({...this.state, ...data})
		await this.setState({ address_values: JSON.stringify(data) })
	}

	render() {

		return (
			<div className="container">
				<Panel header="Agregar Cliente">
					<Toast ref={(el) => this.growl = el} />
					<Row>
						<Col>
							<form onSubmit={this.handleSubmit}>
								<Row>
									<Col xs="12" md="6">
										<div className="form-group ">
											<label>Nacionalidad</label>
											<Select className="h-45x" options={NacionalidadOption} required="true" onChange={(e) => { this.seleccionar_nacionalidad(e) }} placeholder="Seleccione Nacionalidad" />
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs="12" md="6">
										<div className="form-group">
											<label>Razón social</label>
											<input type="text" maxlength="200" size="200" required value={this.state.razon_social} onChange={(e) => this.setState({ razon_social: e.target.value })} name="razon_social" className="form-control" />
										</div>
									</Col>
									<Col xs="12" md="6">
										<div className="form-group">
											<label>R.F.C.</label>
											<input readOnly={this.state.nacionalidad === "Extranjero"} type="text" maxlength="45" size="45" required value={this.state.rfc} onChange={(e) => this.setState({ rfc: e.target.value })} name="razon_social" className="form-control" />
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs="12" md="6">
										<div className="form-group">
											<label>Moneda</label>
											<Select options={options} required="true" onChange={(e) => this.setState({ moneda: e.value })} placeholder="Seleccione una opción" />
										</div>
									</Col>
									{/* <Col xs="6" >
										<div className="form-group">
											<label>Identidad tributaria</label>
											<input readOnly={this.state.nacionalidad !== "Nacional"} type="text" required maxlength="45" size="45" value={this.state.identidad_tributaria} onChange={(e) => this.setState({ identidad_tributaria: e.target.value })} name="identidad_tributaria" className="form-control" />
										</div>
									</Col> */}
								</Row>
								<Row>
									<Col xs="12" md="6">
										<div className="form-group">
											<label>Calle</label>
											<input type="text" required maxlength="200" size="200" name="calle" value={this.state.calle} onChange={(e) => this.setState({ calle: e.target.value })} className="form-control" />
										</div>
									</Col>
									<Col xs="12" md="6">
										<div className="form-group">
											<label>Número</label>
											<input type="text" required name="numero" maxlength="10" size="10" value={this.state.numero} onChange={(e) => this.setState({ numero: e.target.value })} className="form-control" />
										</div>
									</Col>
								</Row>
								{/* <Address is_SAT_Localidad={"false"} senderAdressToParent={this.ReceiveAddress} /> */}
								<CustomAddress isComisionAOperador={"false"}  senderAdressToParent={this.ReceiveAddress} />
								{/* <Row>
									<Col xs="6">
										<div className="form-group">
											<label>Código de cliente</label>
											<input type="text" name="codigo_cliente" maxlength="20" size="20" required value={this.state.codigo_cliente} onChange={(e) => this.setState({ codigo_cliente: e.target.value })} className="form-control" />
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs="6">
										<div className="form-group">
											<label>Página Web</label>
											<input type="text" name="pagina_web" maxlength="100" size="100" required value={this.state.pagina_web} onChange={(e) => this.setState({ pagina_web: e.target.value })} className="form-control" />
										</div>
									</Col>
									<Col xs="6">
										<div className="form-group">
											<label>Status</label>
											<Select options={status} required="true" onChange={(e) => this.setState({ estatus: e.value })} placeholder="Seleccione una opción" />
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs="6">
										<div className="form-group">
											<label>Validación de certificación</label><br />

											<div className="form-group">
												<br />
												<div className="custom-file-upload">
													<label for="file-upload1" className="custom-file-upload1 btn btn-info">
														<i className="pi pi-cloud-upload"></i> Seleccionar archivo
													</label>
													<input id="file-upload1" type="file" className="btn btn-default" style={{ display: "none" }} onChange={e => this.setState({ archivo_certificacion: e.target.files[0] })} chooseLabel="Seleccionar Archivo" />
												</div>

											</div>

										</div>
									</Col>

									<Col xs="6">
										<div className="form-group">
											<label>Número de certificación</label>
											<input type="text" name="certificado" maxlength="100" size="100" required value={this.state.certificado} onChange={(e) => this.setState({ certificado: e.target.value })} className="form-control" />
										</div>
									</Col>
								</Row> */}
								{/* <Fieldset legend="AREA CORPORATIVA">
									<Row>
										<Col xs="6">
											<div className="form-group">
												<label>Nombre de contacto</label>
												<input type="text" name="nombre_contacto" maxlength="100" size="100" required value={this.state.nombre_contacto} onChange={(e) => this.setState({ nombre_contacto: e.target.value })} className="form-control" />
											</div>
											<div className="form-group">
												<label>Correo de contacto</label>
												<input type="text" name="email_contacto" maxlength="100" size="100" required value={this.state.email_contacto} onChange={(e) => this.setState({ email_contacto: e.target.value })} className="form-control" />
											</div>
										</Col>
										<Col xs="6">
											<div className="form-group">
												<label>Teléfono de contacto</label>
												<InputMask mask="(99) 9999-9999" required value={this.state.telefono_contacto} onChange={(e) => this.setState({ telefono_contacto: e.target.value })} className="form-control" />
											</div>
										</Col>

									</Row>
								</Fieldset> */}



								{/* <Fieldset legend="CUENTAS POR PAGAR">
									<Row>
										<Col xs="6">
											<div className="form-group">
												<label>Nombre de contacto</label>
												<input type="text" name="nombre_contacto_cuentas" maxlength="100" size="100" required value={this.state.nombre_contacto_cuentas} onChange={(e) => this.setState({ nombre_contacto_cuentas: e.target.value })} className="form-control" />
											</div>
											<div className="form-group">
												<label>Correo de contacto</label>
												<input type="text" name="email_contacto_cuentas" maxlength="100" size="100" required value={this.state.email_contacto_cuentas} onChange={(e) => this.setState({ email_contacto_cuentas: e.target.value })} className="form-control" />
											</div>
										</Col>
										<Col xs="6">
											<div className="form-group">
												<label>Teléfono de contacto</label>
												<InputMask mask="(99) 9999-9999" required value={this.state.telefono_contacto_cuentas} onChange={(e) => this.setState({ telefono_contacto_cuentas: e.target.value })} className="form-control" />
											</div>
										</Col>

									</Row>
								</Fieldset> */}

								<center> <button className="btn btn-primary" onClick={this.handleSubmit}>Guardar</button></center>
							</form>
						</Col>
					</Row>
				</Panel>
			</div>
		);
	}
}

export default CrearClientes;
