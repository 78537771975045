import React from "react";
import {Row,Col} from 'reactstrap';
import { Toast } from 'primereact/toast';
import moment from 'moment';
import Select from 'react-select';
import MantenimientoService from  'controllers/mantenimiento/mantenimiento-service';
import UnidadService  from 'controllers/unidad/unidadService';
import {MultiSelect} from 'primereact/multiselect';
import { Panel } from 'primereact/panel';
import PCalendar from '../../../ui-components/calendar';
import {Button} from 'primereact/button';
import {url} from '../../../../constants';

let fileState;

class CrearReporteFalla extends React.Component {
	constructor(props){
		super(props);
		this.state={
			catalogo_actividades:[]
		}
		this.servicioU = new UnidadService();
		this.servicioU.getUnidades().then(data =>  {
			let arrUnidades = [];
			data.forEach(item=>{
				arrUnidades.push({value:item.id,label:item.placas});

			});
			this.setState({unidades: arrUnidades});
		});
		this.servicio = new MantenimientoService();
		this.handleSubmit = this.handleSubmit.bind(this);
		this.getCommonDataFromChilds = this.getCommonDataFromChilds.bind(this);
	}

	getCommonDataFromChilds(data)
	{
		switch (data.label) {
			case "fecha":
				this.setState({fecha: moment(data.value).format('YYYY-MM-DD').toString(),semana:moment(data.value).week()});
				break;
			case "hora":
				this.setState({hora: data.value});
				break;
		}
	}
	componentDidMount() {
		let values = this.props.location.state;
	 if(values){
		fileState = {evidencia:{name:values?.evidencia}}
		 this.setState({
			 unidad: values.unidad,
			 actividades: values.actividades? values.actividades.split`,`.map(x=>+x):[],
			 fecha:  values.fecha?moment(values.fecha,'DD-MM-YYYY').toDate():null,

			 id:values.id,
			 titulo:"EDITAR",
			 sobreescribir: true,
			 ...fileState
		 });
	 }

		this.servicio.listadoCatalogo().then(data => {
			let arrActividades = [];
			data.forEach(item=>{
				arrActividades.push({value:item.id,label:item.name});

			});
			this.setState({catalogo_actividades: arrActividades});
		});
	}

	handleSubmit(event) {
				event.preventDefault();

				 console.log(this.state);
				let that = this;

				let datos ={};
				const data = new FormData();
				datos.unidad = this.state.unidad;
				datos.actividades = this.state.actividades;
				datos.fecha = moment(this.state.fecha).format('YYYY-MM-DD').toString();



				data.append("ruta",JSON.stringify(datos));


				let mensajes  =[];

				if(!this.state.actividades){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Seleccione una actividad'});
				}
				if(!this.state.unidad){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Seleccione una unidad'});
				}
				if(!this.state.fecha){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Ingrese una fecha'});
				}
				if(!this.state.evidencia	){
						//mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Seleccione un archivo de evidencia'});
						data.append("evidencia",this.state.evidencia);
				}

				if(mensajes.length){
					this.growl.show(mensajes);
				}else{

					let funcion = console.log;
					if(this.state.sobreescribir){
					  funcion = this.servicio.actualizarReparacion;
					  datos.id = this.state.id;
					}else{
					  funcion = this.servicio.guardarReparacion;
					}

					funcion(datos).then(res =>  {
							if(res && res.result == 'OK'){

								that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Información guardada correctamente'});
								setTimeout(function(){
										 	that.props.history.push('/app/mantenimiento/correctivo/reparaciones');
								 }, 500);

							}else if(res && res.result == 'EXISTE'){
								that.growl.show({severity: 'error', summary: 'El Id de ruta '+this.state.id_ruta+' ya se encuentra registrado', detail: 'Ingrese otro código o consulte su información para editarla.'});
							}
					});
				}
	}

	getImageInput(title,key)
	{
		return (
		<div className="image-group">
			<label className="title">{title}</label>
			<br />
			<div className="form-group">
			{!this.state[key]?.name && 
				<div className="custom-file-upload">
				<label htmlFor={`file-upload-${key}`} className="p-button-warning p-button m-0 w-100">
					<i className="pi pi-cloud-upload"></i> Seleccionar archivo
				</label>
				<input
					id={`file-upload-${key}`}
					type="file"
					style={{ display: "none" }}
					onChange={(e) =>
					this.setState({ [key]: e.target.files[0] })
					}
					chooseLabel="Seleccionar Archivo"
				/>
				</div>
			}
			{this.state[key]?.name && 
				<div className="file-info d-flex justify-content-between">
				  <div className="label-info">
						{this.state[key].name}
					</div>
			<div className="d-flex">
			{fileState?.[key]?.name && 
					<div className="action-info mr-3">
						{this.state[key].name.includes('.') && 
						<i
						onClick={() => {
							window.open(url+"altadeunidad/"+this.state[key].name,'_blank')
						}}
						className="pi pi-download"
						style={{ fontSize: "1rem", cursor: "pointer" }}
						></i>
						}
					</div>
	        }
				<div className="action-info">
                <i
                  onClick={() => {
                    this.setState({ [key]: null });
					fileState && fileState[key] && (fileState[key].name = null);
				}}
                  className="pi pi-trash"
                  style={{ fontSize: "1.5em", cursor: "pointer" }}
                ></i>
                </div>                                 
				</div>
			</div>
			}
			</div>
		</div>
		)
	}
	render() {

		return (
			<div className="container">
				<Panel header="Editar Reparación">
				<Toast ref={(el) => this.growl = el} />
				<form >
				<Row>
					<Col sm="6">
							<div className="form-group">
								<label>Actividades realizadas</label><br/>
									<MultiSelect options={this.state.catalogo_actividades} style={{width:"100%"}} value={this.state.actividades} onChange={(e) => this.setState({actividades: e.value})} filter={true}/>
							</div>

					</Col>
					<Col sm="6">
							<div className="form-group">
								<label>Unidad</label>
								<Select options={this.state.unidades} value={this.state.unidades?this.state.unidades.filter(option=>option.value==this.state.unidad):""}  onChange={e =>	this.setState({unidad: e.value}) }  placeholder="Elegir unidad"/>

							</div>
					</Col>
				</Row>
				<Row>
					<Col sm="6">
							<div className="form-group">
								<label>Fecha estimada de entrega</label><br/>
								<PCalendar
									label={"fecha"}
									getCommonDataFromChilds={this.getCommonDataFromChilds}
									showIcon
									value={this.state.fecha}
								/>	
							</div>

					</Col>
					<Col sm="6">
							{this.getImageInput("Evidencia (foto-video) de la actividad conluida", "evidencia")}
					</Col>
				</Row>
				<Row>
					<Col xs="12" className="text-center">
						<Button className="p-button-warning" onClick={this.handleSubmit} >Guardar</Button>
					</Col>
				</Row>
					</form>
				</Panel>
			</div>
		);
	}
}

export default CrearReporteFalla;
