import React from 'react';
import { connect } from 'react-redux';
import {
	Navbar,
	NavbarBrand,
	Collapse
} from 'reactstrap';
import { Avatar } from 'primereact/avatar';
import { Menu } from 'primereact/menu';
import { authenticationService } from '../../../jwt/_services';
// import { Icon } from 'primereact/icons';


/*--------------------------------------------------------------------------------*/
/* Import images which are need for the HEADER                                    */
/*--------------------------------------------------------------------------------*/
import logodarktext from '../../../assets/images/_logo_text.png';
import { slice } from 'lodash';


const mapStateToProps = state => ({
	...state
});

let loggedUser;

/*
<NavItem>
	<form className="app-search">
		<input type="text" className="form-control" placeholder="Búsqueda" />
	</form>
</NavItem>
*/
class Header extends React.Component {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.showMobilemenu = this.showMobilemenu.bind(this);
		this.sidebarHandler = this.sidebarHandler.bind(this);
		this.logout = this.logout.bind(this);
		this.state = {
			isOpen: false,
			loggedUserName: 'Admin'
		};
		this.breadcrumbItems = [{ label: 'Computer' }, { label: 'Notebook' }, { label: 'Accessories' }, { label: 'Backpacks' }, { label: 'Item' }];
	    this.breadcrumbHome = { icon: 'pi pi-home', url: 'https://primereact.org' }

		this.menuRef = React.createRef();
		this.menuItems = [
			{
				label: 'Opciones',
				items: [
					{
						label: 'Cambiar la contraseña',
						icon: 'pi pi-lock'
					},
					{
						label: 'Cerrar sesión',
						icon: 'pi pi-sign-out',
						command:()=>{this.logout()}
					}
				]
			}
		];
	}
	/*--------------------------------------------------------------------------------*/
	/*To open NAVBAR in MOBILE VIEW                                                   */
	/*--------------------------------------------------------------------------------*/
	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}
	onMenuBlur(event)
	{
		console.log("sdfsdf",event)		
	}
	logout(){
		authenticationService.logout();
		this.props.history.push('/');
	}
	/*--------------------------------------------------------------------------------*/
	/*To open SIDEBAR-MENU in MOBILE VIEW                                             */
	/*--------------------------------------------------------------------------------*/
	showMobilemenu() {
		document.getElementById('main-wrapper').classList.toggle('show-sidebar');
	}
	sidebarHandler = () => {
		let element = document.getElementById('main-wrapper');
		switch (this.props.settings.activeSidebarType) {
			case 'full':
			case 'iconbar':
				element.classList.toggle('mini-sidebar');
				if (element.classList.contains('mini-sidebar')) {
					element.setAttribute('data-sidebartype', 'mini-sidebar');
				} else {
					element.setAttribute(
						'data-sidebartype',
						this.props.settings.activeSidebarType
					);
				}
				break;

			case 'overlay':
			case 'mini-sidebar':
				element.classList.toggle('full');
				if (element.classList.contains('full')) {
					element.setAttribute('data-sidebartype', 'full');
				} else {
					element.setAttribute(
						'data-sidebartype',
						this.props.settings.activeSidebarType
					);
				}
				break;
			default:
		}
	};
	componentDidMount(){
		loggedUser = JSON.parse(localStorage.getItem('currentUser'));
		this.setState({loggedUserName: loggedUser.username})
	}
	render() {
		const { pathname } = this.props.breadcrumb;
        const parts = pathname.replace('/app/', '').split('/');
		let formattedPathname;
        if(parts[0].length){
		formattedPathname = parts.map((part, index) => {
		const words = part.split('-');
		const capitalizedWords = words.map(
			word => word.charAt(0).toUpperCase() + word.slice(1)
		);

		// Check if it's the last part
		const isLastPart = index === parts.length - 1;

		return (
			<span key={part}>
			{capitalizedWords.join(' ')}
			{!isLastPart && <i className="pi pi-chevron-right p-1" style={{ fontSize: '0.75rem' }}></i>}
			</span>
		);
		});
	}

		return (
			<header className="topbar navbarbg" data-navbarbg={this.props.settings.activeNavbarBg}>
				<Navbar className={"top-navbar " + (this.props.settings.activeNavbarBg === "skin6" ? 'navbar-light' : 'navbar-dark')} expand="md">
					<div className="navbar-header d-md-none mobile-header" id="logobg" data-logobg={this.props.settings.activeLogoBg}>
						{/*--------------------------------------------------------------------------------*/}
						{/* Mobile View Toggler  [visible only after 768px screen]                         */}
						{/*--------------------------------------------------------------------------------*/}
						<span className="nav-toggler d-block d-md-none" onClick={this.showMobilemenu}>
							<i className="pi pi-bars" />
						</span>
						{/*--------------------------------------------------------------------------------*/}
						{/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
						{/*--------------------------------------------------------------------------------*/}
						<NavbarBrand href="/">
							<span className="logo-text">
								<img src={logodarktext} alt="homepage" className="dark-logo" />
							</span>
						</NavbarBrand>
						{/*--------------------------------------------------------------------------------*/}
						{/* Mobile View Toggler  [visible only after 768px screen]                         */}
						{/*--------------------------------------------------------------------------------*/}
						<span className="topbartoggler d-block d-md-none" onClick={this.toggle}>
							<i className="pi pi-cog" />
						</span>
					</div>
					<Collapse className="navbarbg d-none d-md-block" isOpen={this.state.isOpen} navbar data-navbarbg={this.props.settings.activeNavbarBg} >
						<div className='d-flex justify-content-between align-items-center w-100'>
							<div className='left-dflex text-white'>
								<h3 className='m-0'>Panel de administrador</h3>
								<label className='m-0'>
								{formattedPathname}
								</label>
							</div>
							<div className='right-dflex '>
								<Menu  model={this.menuItems} popup ref={this.menuRef} id="popup_menu_right" popupAlignment="right" />
					            <div onClick={(event) => this.menuRef.current.toggle(event)} className='pointer d-flex text-white'>
									<div className='text-right'>
										<p className='m-0 h4 font-weight-bold'>{loggedUser?.name}</p>
										<label className='m-0 text-white-50 font-weight-bold fa-1x'>{loggedUser?.username}</label>
									</div>
									<div className='pl-2'>
										<Avatar label={this.state.loggedUserName.slice(0,1)} size="large" shape="circle" className='text-uppercase' style={{ backgroundColor: '#fff', color: '#2196F3' }} />
										<i className="pi pi-angle-down text-white px-2"></i>
									</div>
								</div>
							</div>
						</div>
					</Collapse>
				</Navbar>
			</header>
		);
	}
}
export default connect(mapStateToProps)(Header);
