import React from "react";
import {
	Card,
	CardBody,Col,Row,Container,CardTitle,

} from 'reactstrap';
import {Button} from 'primereact/button';
import ClienteService  from 'controllers/cliente/clienteservice';
import Select from 'react-select';
import { Panel } from 'primereact/panel';
import { Accordion, AccordionTab } from 'primereact/accordion';
import RutasListado from "./lista-corta";
import RutaService  from 'controllers/ruta/rutaservice';
import { Dropdown } from "primereact/dropdown";


class ConsultaRuta extends React.Component {
	constructor(props){
		super(props);
		this.buscar= this.buscar.bind(this);
		this.verToDo= this.verToDo.bind(this);

		this.state={
			numero_ruta:"",
			cliente:"",
			codigo_cliente:"",
		}

		this.servicio = new ClienteService();
		this.servicio_rutas = new RutaService();

		this.servicio.getClientes().then(data =>  {
			let arrClientes = [];
			data.forEach(item=>{
				arrClientes.push({value:item.id,label:item.razon_social,rfc:item.rfc});
			});
			this.setState({clientes: arrClientes});
		});
	}


	buscar(){
		const parametros = {
			numero_ruta: this.state.numero_ruta,
			cliente: this.state.cliente
		}
		this.handleRutasTable(parametros);
	}

	verToDo(){
		this.setState({
			numero_ruta: "",
			cliente:""
		})
		this.handleRutasTable();
	}


	componentDidMount() {
		 this.handleRutasTable()
	}

	handleRutasTable = (params) =>{

		this.servicio_rutas.filterTodasLasRutas(params).then(data => {
			data.map(data=> {
			  if(data.direccion_origen){
			  data.direccion_origen = this.addre(data.direccion_origen);
			}
			  if(data.direccion_final){
			  data.direccion_final = this.addre(data.direccion_final);
			  }
		  })
			this.setState({rutas: data});
		  });
		}

	addre = (option) =>
		{
		  try {
			return `${JSON.parse(option).value_nombre ?? "nulo"},
			${ JSON.parse(option).estado.label ?? "nulo"},
			${JSON.parse(option).municipio ?? "nulo"} , 
			${JSON.parse(option).localidad ?? "nulo"},
			${JSON.parse(option).codigo_postal ?? "nulo"},
			${JSON.parse(option).colonia ?? "nulo"}`
		  } catch (error) {
			return option;
		  }     
		}


	render() {
		return (
			<div className="container-fluid">
				<Panel header="Consulta De Rutas">
					<>
						<Accordion activeIndex={0} className="mb-3">
							<AccordionTab header="Filtrar">
								<Row>
									<Col xs="12" md="4">
										<div className="form-group">
											<label>Consultar por Número de Ruta ...</label>
											<input type="text" value={this.state.numero_ruta} onChange={e=>this.setState({numero_ruta:e.target.value})} className="form-control"/>
										</div>
									</Col>
									<Col xs="12" md="4">
										<div className="form-group">
											<label className="d-block">Consultar por Nombre de Cliente ...</label>
											{/* <Select options={this.state.clientes} onChange={e=> this.setState({cliente:e.value})} placeholder="Elegir cliente..."/> */}
											<Dropdown className="w-full" value={this.state.cliente} options={this.state.clientes} onChange={e=> this.setState({cliente:e.value})} placeholder="Elegir cliente..."/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col>
										<div className="d-flex justify-content-end"> 
											<Button label="Buscar" onClick={this.buscar} className="p-button-raised p-button-warning mr-3 mt-4" />
											<Button label="Ver todo" onClick={this.verToDo} className="mt-4 p-button-raised p-button-warning" />
										</div>
									</Col>
								</Row>
							</AccordionTab>
						</Accordion>

						<div class="col-12 p-0 main_table">
							<Row className="col">
								<div class="w-100">
									<RutasListado  history={this.props.history} {...this.state} handleRutasTable={this.handleRutasTable} addre={this.addre}/>
								</div>
							</Row>
						</div>
					</>
				</Panel>
			</div>
		);
	}
}

export default ConsultaRuta;
