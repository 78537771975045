import React from "react";
import {
	Card,
	CardBody,

} from 'reactstrap';
import { Map, Marker, Popup, TileLayer,MapContainer } from "react-leaflet";
import { Icon } from "leaflet";

class MailBox extends React.Component {

	render() {
		const position = [51.505, -0.09]
		return (
			<Card>
				<CardBody>
				<MapContainer center={position} zoom={13} scrollWheelZoom={false}>
					<TileLayer
						attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
						url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					/>
					<Marker position={position}>
						<Popup>
							A pretty CSS3 popup. <br /> Easily customizable.
						</Popup>
					</Marker>
				</MapContainer>

				</CardBody>
			</Card>
		);
	}

}

export default MailBox;
