import React from "react";
import {
	Card,
	CardBody,
	CardTitle,
	CardText,
} from 'reactstrap';

import DashboardService from "controllers/Dashboard/dashboardservice";

class Dashboard extends React.Component{
	constructor(props) {
		super(props);
		this.setState({
			clienteCount: 0, 
			unidadCount: 0, 
			operadorCount: 0, 
			embarqueCount: 0
		})
		this.servicioClientes = new DashboardService();
	  }

	componentDidMount(){
		this.servicioClientes.getDashboardCount().then((data)=> {
           this.setState({...data})
		})
	}

	render() {
		return (
		<div class="container-fluid">
		<h3 className="my-4">Bienvenido al Panel de control</h3>
			<div className="d-flex flex-column justify-content-center align-items-center">
				<div className="container">
				<div className="row">
				<div className="col-md-3 mb-3">
					<Card>
					<CardBody>
						<CardTitle>Cantidad de Clientes</CardTitle>
						<CardText>{this.state?.clienteCount}</CardText>
					</CardBody>
					</Card>
				</div>
				<div className="col-md-3 mb-3">
					<Card>
					<CardBody>
						<CardTitle>Cantidad de Unidades</CardTitle>
						<CardText>{this.state?.unidadCount}</CardText>
					</CardBody>
					</Card>
				</div>
				<div className="col-md-3 mb-3">
					<Card>
					<CardBody>
						<CardTitle>Cantidad de Operadores</CardTitle>
						<CardText>{this.state?.operadorCount}</CardText>
					</CardBody>
					</Card>
				</div>
				<div className="col-md-3 mb-3">
					<Card>
					<CardBody>
						<CardTitle>Cantidad de Embarques</CardTitle>
						<CardText>{this.state?.embarqueCount}</CardText>
					</CardBody>
					</Card>
				</div>
				</div>
			</div>
			</div>
		</div>
		);
	}
}

export default Dashboard;