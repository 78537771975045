import React from "react";
import {
	Card,CardTitle,
	CardBody,

} from 'reactstrap';
import Listado from './ordenes-servicio/listado';
import {Button} from 'primereact/button';
import { Link } from "react-router-dom"; 
import { Panel } from 'primereact/panel';
import CheckPermissions from "controllers/permissions/services";

class MantenimientoUnidades extends React.Component {
	constructor(){
		super()
		this.permissionService = new CheckPermissions();
		this.headerTemplate = this.headerTemplate.bind(this);
	}
	agregar(){
		this.props.history.push({
		 pathname:'/app/mantenimiento/correctivo/ordenes-servicio/nueva'
	 });
	}
	headerTemplate()
	{
		return (<div className="p-panel-header flex-wrap  d-flex">
			<div>
				Ordenes de servicio
			</div>
			<div>
			{this.permissionService.checkPermission('mantenimiento_correctivo_ordenes-servicio_nueva') && <Link to="/app/mantenimiento/correctivo/ordenes-servicio/nueva" className="btn btn-primary">
				<i className="pi pi-plus mr-2 v-middle" /> Nueva orden
			</Link>}
			</div>
		</div>);
	}
	render() {
		return (
			<div class="container-fluid">
				<Panel headerTemplate={this.headerTemplate}>
					<Listado history={this.props.history}/>
				</Panel>
			</div>
		);
	}
}

export default MantenimientoUnidades;
