import React from "react";
import {
	Card,
	CardBody,
	CardTitle
} from 'reactstrap';
import Listado from './listado';
import {Button} from 'primereact/button';


class Index extends React.Component {
	agregar(){
	  this.props.history.push({
	   pathname:'/app/empleados/puestos/agregar'
	 });
	}
	render() {
		return (
			<Card>
				<CardTitle  className="bg-light border-bottom p-3 mb-0">Puestos</CardTitle>
				<CardBody>
					<Button label='Agregar puesto' icon="fa fa-plus" className="pull-right" onClick={this.agregar.bind(this)}/><br/><br/>
					<Listado history={this.props.history}/>
				</CardBody>
			</Card>
		);
	}
}

export default Index;
