import React from "react";
import {
	Card,
	CardBody,
	CardTitle
} from 'reactstrap';
import Calendarios from './programado/calendario';
import {Button} from 'primereact/button';


class ReportesFallas extends React.Component {
	agregar(){
	  this.props.history.push({
	   pathname:'/app/mantenimiento/predictivo/programado/agregar'
	 });
	}
	render() {
		return (
			<Card>
				<CardTitle  className="bg-light border-bottom p-3 mb-0">CALENDARIO DE ACTIVIDADES DE MANTENIMIENTO
				</CardTitle>
				<CardBody>
					<Calendarios />
				</CardBody>
			</Card>
		);
	}
}

export default ReportesFallas;
