import React from "react";
import { Row, Col } from "reactstrap";
import Select from "react-select";
import municipio_data from "../../localData/c_Municipio";
import localidad_data from "../../localData/c_Localidad";
import estados_data from "../../localData/c_Estados.json";
import CodigoPostalService from "controllers/Address/addressService";
import { InputNumber } from "primereact/inputnumber";
import _ from "lodash";

class CustomAddress extends React.Component {
  constructor(props) {
    super(props);
    this.servicio = new CodigoPostalService();
    this.state = {
      estados_data: [],
      municipio_data: [],
      localidad_data: [],
      codigo_postal_data: [],
      colonia: "",
      municipio: "",
      localidad: "",
      comision_a_operador: "",
      estado: "",
      estado_value: "Colima",
      codigo_postal: "",
      is_non_location: "",
      is_SAT_Localidad: true,
      value_nombre: "",
      is_nombre: false,
      is_costo_del_stop: false,
      value_costo_del_stop: "",
      requiredField: true,
      stepCount: "true",
      isLocalidadRequired: true,
      isComisionAOperador: true,
    };
  }
  componentDidMount = () => {
    // console.log("isLocalidadRequired", this.props["isLocalidadRequired"]);
    this.setState({ estados_data });

    if (this.props["step"]) {
      this.setState({ stepCount: this.props["step"] });
    }
    if (
      this.props["requiredField"] &&
      this.props["requiredField"] === "false"
    ) {
      this.setState({ requiredField: false });
    }

    if (
      this.props["isLocalidadRequired"] &&
      this.props["isLocalidadRequired"] === "false"
    ) {
      this.setState({ isLocalidadRequired: false });
    }
    if (
      this.props["isComisionAOperador"] &&
      this.props["isComisionAOperador"] === "false"
    ) {
      this.setState({ isComisionAOperador: false });
    }
    if (
      this.props["is_SAT_Localidad"] &&
      this.props["is_SAT_Localidad"] === "false"
    ) {
      this.setState({ is_SAT_Localidad: false });
    }
    if (this.props["is_nombre"] && this.props["is_nombre"] === "true") {
      this.setState({ is_nombre: true });
    }
    if (
      this.props["is_costo_del_stop"] &&
      this.props["is_costo_del_stop"] === "true"
    ) {
      this.setState({ is_costo_del_stop: true });
    }
    if (this.props.values && _.size(this.props.values.address_values)) {
      let pad = JSON.parse(this.props.values.address_values);
      this.setState({ colonia: pad["colonia"] });
      if (
        this.props["is_SAT_Localidad"] &&
        this.props["is_SAT_Localidad"] === "false"
      ) {
        this.setState({ is_non_location: pad["is_non_location"] });
      }
      if (this.props["is_nombre"] && this.props["is_nombre"] === "true") {
        this.setState({ value_nombre: pad["value_nombre"] });
      }
      if (
        this.props["is_costo_del_stop"] &&
        this.props["is_costo_del_stop"] === "true"
      ) {
        this.setState({ value_costo_del_stop: pad["value_costo_del_stop"] });
      }
      if (pad["estado"]) {
        this.changeEstado(pad["estado"]);
      }
      if (pad["municipio"]) {
        this.changeMunicipo(pad["municipio"]);
      }
      if (pad["localidad"]) {
        this.changeLocalidad(pad["localidad"]);
      }
      if (pad["codigo_postal"]) {
        this.changeCodigoPostal(pad["codigo_postal"]);
      }
    }
  };

  changeEstado = async (e) => {
    let localidad = _.filter(localidad_data, { c_Estado: e.c_Estado });
    this.setState({ estado: e, estado_value: e.value }, () =>
      this.send_to_parent_componet()
    );
  };
  changeMunicipo = async (e) => {
    await this.setState({ municipio: e }, () => this.send_to_parent_componet());
  };
  changeLocalidad = (e) => {
    this.setState({ localidad: e }, () => this.send_to_parent_componet());
  };

  changeCodigoPostal = (e) => {
    this.setState({ codigo_postal: e }, () => this.send_to_parent_componet());
  };
  change_nombre = (e) => {
    this.setState({ value_nombre: e }, () => this.send_to_parent_componet());
  };
  changeColonia = (e) => {
    this.setState({ colonia: e }, () => this.send_to_parent_componet());
  };
  change_costo_del_stop = (e) => {
    this.setState({ value_costo_del_stop: e }, () =>
      this.send_to_parent_componet()
    );
  };
  changeComisionAOperador = (e) => {
    this.setState({ comision_a_operador: e }, () =>
      this.send_to_parent_componet()
    );
  };

  send_to_parent_componet() {
    let data = {
      localidad: this.state.localidad,
      colonia: this.state.colonia,
      is_SAT_Localidad: this.state.is_SAT_Localidad,
      is_non_location: this.state.is_non_location,
      value_nombre: this.state.value_nombre,
      is_nombre: this.state.is_nombre,
      municipio: this.state.municipio,
      estado: this.state.estado,
      codigo_postal: this.state.codigo_postal,
      value_costo_del_stop: this.state.value_costo_del_stop,
      is_costo_del_stop: this.state.is_costo_del_stop,
      comision_a_operador: this.state.comision_a_operador,
    };

    if (this.props.step || this.props.step === 0) {
      data["step"] = this.props.step;
    }
    this.props.senderAdressToParent(data);
  }

  render() {
    return (
      <div className="jumbotron p-3 bg-light-info">
        <Row>
          {this.state.is_nombre && (
            <Col xs="12" md="6">
              <div className="form-group">
                <label>Nombre de la planta</label>
                <input
                  type="text"
                  name={`Nombre de la planta_${this.state.stepCount}`}
                  required={this.state.requiredField}
                  value={this.state.value_nombre}
                  onChange={(e) => this.change_nombre(e.target.value)}
                  className="form-control border-secondary"
                  placeholder="Ingresar Nombre de la planta"
                />
              </div>
            </Col>
          )}
          <Col xs="12" md="6">
            <div className="form-group">
              <label>Estado</label>
              <Select
                value={this.state.estado}
                options={this.state.estados_data}
                required={this.state.requiredField}
                onChange={(e) => this.changeEstado(e)}
                placeholder="Seleccione Estado"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="6">
            <div className="form-group">
              <label>Municipio</label>
              <input
                type="text"
                name="Municipio"
                required={this.state.requiredField}
                value={this.state.municipio}
                onChange={(e) => this.changeMunicipo(e.target.value)}
                className="form-control border-secondary"
                placeholder="Ingresar Municipio"
              />
            </div>
          </Col>
          <Col xs="12" md="6">
            <div className="form-group">
              <label>Localidad</label>
              <input
                type="text"
                name="Localidad"
                required={this.state.isLocalidadRequired}
                value={this.state.localidad}
                onChange={(e) => this.changeLocalidad(e.target.value)}
                className="form-control border-secondary"
                placeholder="Ingresar Localidad"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="6">
            <div className="form-group">
              <label>Código postal</label>
              <input
                type="text"
                name="Codigo postal"
                required={this.state.requiredField}
                value={this.state.codigo_postal}
                onChange={(e) => this.changeCodigoPostal(e.target.value)}
                className="form-control border-secondary"
                placeholder="Ingresar Código Postal"
              />
            </div>
          </Col>
          <Col xs="12" md="6">
            <div className="form-group">
              <label>Colonia</label>
              <input
                type="text"
                name="colonia"
                required={this.state.requiredField}
                value={this.state.colonia}
                onChange={(e) => this.changeColonia(e.target.value)}
                className="form-control border-secondary"
                placeholder="Ingresar Colonia"
              />
            </div>
          </Col>
        </Row>
        <Row>
          {this.state.is_costo_del_stop && (
            <Col xs="12" md="6">
              <div className="form-group">
                <label>Costo del Stop</label>
                <br />
                <InputNumber
                  value={this.state.value_nombre}
                  onChange={(e) => this.change_costo_del_stop(e.value)}
                  mode="currency"
                  currency="MXN"
                  locale="es-MX"
                />
              </div>
            </Col>
          )}
          {this.state.isComisionAOperador && (
            <Col xs="12" md="6">
              <div className="form-group">
                <label>Comision a Operador</label>
                <br />
                <InputNumber
                  value={this.state.comision_a_operador}
                  onChange={(e) => this.changeComisionAOperador(e.value)}
                  mode="currency"
                  currency="MXN"
                  locale="es-MX"
                />
              </div>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

export default CustomAddress;
