var localidad_data = [
    { value: "01", c_Estado: "AGU", label: "Aguascalientes" },
    { value: "01", c_Estado: "BCN", label: "Ensenada" },
    { value: "01", c_Estado: "BCS", label: "Ciudad Constitución" },
    { value: "01", c_Estado: "CAM", label: "San Francisco de Campeche" },
    { value: "01", c_Estado: "COA", label: "Ciudad Acuña" },
    { value: "01", c_Estado: "COL", label: "Colima" },
    { value: "01", c_Estado: "CHH", label: "Santa Rosalía de Camargo" },
    { value: "01", c_Estado: "CHP", label: "Comitán de Domínguez" },
    { value: "01", c_Estado: "DIF", label: "Ciudad de México" },
    { value: "01", c_Estado: "DUR", label: "Victoria de Durango" },
    { value: "01", c_Estado: "GRO", label: "Acapulco de Juárez" },
    { value: "01", c_Estado: "GUA", label: "Acámbaro" },
    { value: "01", c_Estado: "HID", label: "Actopan" },
    { value: "01", c_Estado: "JAL", label: "Ameca" },
    { value: "01", c_Estado: "MEX", label: "Ciudad Adolfo López Mateos" },
    { value: "01", c_Estado: "MIC", label: "Apatzingán de la Constitución" },
    { value: "01", c_Estado: "MOR", label: "Cuautla (Cuautla de Morelos)" },
    { value: "01", c_Estado: "NAY", label: "Santiago Ixcuintla" },
    { value: "01", c_Estado: "NLE", label: "Ciudad Apodaca" },
    { value: "01", c_Estado: "OAX", label: "Juchitán (Juchitán de Zaragoza)" },
    { value: "01", c_Estado: "PUE", label: "Atlixco" },
    { value: "01", c_Estado: "QUE", label: "Querétaro" },
    { value: "01", c_Estado: "ROO", label: "Cancún" },
    { value: "01", c_Estado: "SIN", label: "Los Mochis" },
    { value: "01", c_Estado: "SLP", label: "Ciudad Valles" },
    { value: "01", c_Estado: "SON", label: "San Luis Río Colorado" },
    { value: "01", c_Estado: "TAB", label: "Cárdenas" },
    { value: "01", c_Estado: "TAM", label: "Altamira" },
    { value: "01", c_Estado: "TLA", label: "Apizaco" },
    { value: "01", c_Estado: "VER", label: "Acayucan" },
    { value: "01", c_Estado: "YUC", label: "Mérida" },
    { value: "01", c_Estado: "ZAC", label: "Fresnillo" },
    { value: "02", c_Estado: "AGU", label: "Calvillo" },
    { value: "02", c_Estado: "BCN", label: "Mexicali" },
    { value: "02", c_Estado: "BCS", label: "La Paz" },
    { value: "02", c_Estado: "CAM", label: "Ciudad del Carmen" },
    { value: "02", c_Estado: "COA", label: "Frontera" },
    { value: "02", c_Estado: "COL", label: "Manzanillo" },
    { value: "02", c_Estado: "CHH", label: "Chihuahua" },
    { value: "02", c_Estado: "CHP", label: "San Cristóbal de las Casas" },
    { value: "02", c_Estado: "DIF", label: "Ciudad de México" },
    { value: "02", c_Estado: "DUR", label: "Gómez Palacio" },
    { value: "02", c_Estado: "GRO", label: "Chilpancingo de los Bravo" },
    { value: "02", c_Estado: "GUA", label: "San miguel de Allende" },
    { value: "02", c_Estado: "HID", label: "Apan" },
    { value: "02", c_Estado: "JAL", label: "Ciudad Guzmán" },
    { value: "02", c_Estado: "MEX", label: "Chimalhuacán" },
    { value: "02", c_Estado: "MIC", label: "Los Reyes de Salgado" },
    { value: "02", c_Estado: "MOR", label: "Cuernavaca" },
    { value: "02", c_Estado: "NAY", label: "Tepic" },
    { value: "02", c_Estado: "NLE", label: "San Pedro Garza García" },
    { value: "02", c_Estado: "OAX", label: "Oaxaca de Juárez" },
    { value: "02", c_Estado: "PUE", label: "Izúcar de Matamoros" },
    { value: "02", c_Estado: "QUE", label: "San Juan del Rio" },
    { value: "02", c_Estado: "ROO", label: "Cozumel" },
    { value: "02", c_Estado: "SIN", label: "Culiacán Rosales" },
    { value: "02", c_Estado: "SLP", label: "Ébano" },
    { value: "02", c_Estado: "SON", label: "Agua Prieta" },
    { value: "02", c_Estado: "TAB", label: "Villahermosa" },
    { value: "02", c_Estado: "TAM", label: "Ciudad Camargo" },
    { value: "02", c_Estado: "TLA", label: "Villa Vicente Guerrero" },
    { value: "02", c_Estado: "VER", label: "Naranjos" },
    { value: "02", c_Estado: "YUC", label: "Tizimín" },
    { value: "02", c_Estado: "ZAC", label: "Jerez de García Salinas" },
    { value: "03", c_Estado: "AGU", label: "San Francisco de los Romo" },
    { value: "03", c_Estado: "BCN", label: "Tecate" },
    { value: "03", c_Estado: "BCS", label: "Cabo San Lucas" },
    { value: "03", c_Estado: "CAM", label: "Calkini" },
    { value: "03", c_Estado: "COA", label: "Matamoros" },
    { value: "03", c_Estado: "COL", label: "Tecoman" },
    { value: "03", c_Estado: "CHH", label: "Cuauhtémoc" },
    { value: "03", c_Estado: "CHP", label: "Tapachula de Córdova y Ordóñez" },
    { value: "03", c_Estado: "DIF", label: "Ciudad de México" },
    { value: "03", c_Estado: "DUR", label: "Ciudad Lerdo" },
    { value: "03", c_Estado: "GRO", label: "Iguala de la Independencia" },
    { value: "03", c_Estado: "GUA", label: "Celaya" },
    { value: "03", c_Estado: "HID", label: "Pachuca de Soto" },
    { value: "03", c_Estado: "JAL", label: "Guadalajara" },
    { value: "03", c_Estado: "MEX", label: "Coacalco de Berriozabal" },
    { value: "03", c_Estado: "MIC", label: "Ciudad Hidalgo" },
    { value: "03", c_Estado: "MOR", label: "Galeana" },
    { value: "03", c_Estado: "NAY", label: "Tuxpan" },
    { value: "03", c_Estado: "NLE", label: "Ciudad General Escobedo" },
    { value: "03", c_Estado: "OAX", label: "Salina Cruz" },
    { value: "03", c_Estado: "PUE", label: "Puebla (Heroica Puebla)" },
    { value: "03", c_Estado: "ROO", label: "Felipe Carrillo Puerto" },
    { value: "03", c_Estado: "SIN", label: "Escuinapa de Hidalgo" },
    { value: "03", c_Estado: "SLP", label: "Matehuala" },
    { value: "03", c_Estado: "SON", label: "Heroica Caborca" },
    { value: "03", c_Estado: "TAB", label: "Comalcalco" },
    { value: "03", c_Estado: "TAM", label: "Ciudad Madero" },
    { value: "03", c_Estado: "TLA", label: "Tlaxcala (Tlaxcala de Xicotencatl)" },
    { value: "03", c_Estado: "VER", label: "Boca del RÍo" },
    { value: "03", c_Estado: "YUC", label: "Ticul" },
    { value: "03", c_Estado: "ZAC", label: "Zacatecas" },
    { value: "04", c_Estado: "AGU", label: "Jesús María" },
    { value: "04", c_Estado: "BCN", label: "Tijuana" },
    { value: "04", c_Estado: "BCS", label: "San José del Cabo" },
    { value: "04", c_Estado: "CAM", label: "Candelaria" },
    { value: "04", c_Estado: "COA", label: "Monclova" },
    { value: "04", c_Estado: "COL", label: "Ciudad de Villa de Álvarez" },
    { value: "04", c_Estado: "CHH", label: "Delicias" },
    { value: "04", c_Estado: "CHP", label: "Tuxtla Gutiérrez" },
    { value: "04", c_Estado: "DIF", label: "Ciudad de México" },
    { value: "04", c_Estado: "DUR", label: "Santiago Papasquiaro" },
    { value: "04", c_Estado: "GRO", label: "Taxco de Alarcón" },
    { value: "04", c_Estado: "GUA", label: "Cortazar" },
    { value: "04", c_Estado: "HID", label: "Cd. de Fray Bernardino de Sahagún" },
    { value: "04", c_Estado: "JAL", label: "Lagos de Moreno" },
    { value: "04", c_Estado: "MEX", label: "Cuautitlán Izcalli" },
    { value: "04", c_Estado: "MIC", label: "Jacona de Plancarte" },
    { value: "04", c_Estado: "MOR", label: "Jojutla" },
    { value: "04", c_Estado: "NAY", label: "Acaponeta" },
    { value: "04", c_Estado: "NLE", label: "Guadalupe" },
    { value: "04", c_Estado: "OAX", label: "San Juan Bautista Tuxtepec" },
    { value: "04", c_Estado: "PUE", label: "San Martín Texmelucan de Labastida" },
    { value: "04", c_Estado: "QUE", label: "El Pueblito" },
    { value: "04", c_Estado: "ROO", label: "Chetumal" },
    { value: "04", c_Estado: "SIN", label: "Guasave" },
    { value: "04", c_Estado: "SLP", label: "Rioverde" },
    { value: "04", c_Estado: "SON", label: "Ciudad Obregón" },
    { value: "04", c_Estado: "TAB", label: "Emiliano Zapata" },
    { value: "04", c_Estado: "TAM", label: "Ciudad Mante" },
    { value: "04", c_Estado: "TLA", label: "Huamantla" },
    { value: "04", c_Estado: "VER", label: "Coatepec" },
    { value: "04", c_Estado: "YUC", label: "Motul de Carrillo Puerto" },
    { value: "04", c_Estado: "ZAC", label: "Guadalupe" },
    { value: "05", c_Estado: "AGU", label: "Pabellón de Arteaga" },
    { value: "05", c_Estado: "BCN", label: "Playas de Rosarito" },
    { value: "05", c_Estado: "BCS", label: "Loreto" },
    { value: "05", c_Estado: "CAM", label: "Escárcega" },
    { value: "05", c_Estado: "COA", label: "Parras de la Fuente" },
    { value: "05", c_Estado: "COL", label: "Ciudad de Armería" },
    { value: "05", c_Estado: "CHH", label: "Hidalgo del Parral" },
    { value: "05", c_Estado: "CHP", label: "Venustiano Carranza" },
    { value: "05", c_Estado: "DIF", label: "Ciudad de México" },
    { value: "05", c_Estado: "DUR", label: "Canatlán" },
    { value: "05", c_Estado: "GRO", label: "Arcelia" },
    { value: "05", c_Estado: "GUA", label: "Guanajuato" },
    { value: "05", c_Estado: "HID", label: "Tula de Allende" },
    { value: "05", c_Estado: "JAL", label: "Ocotlán" },
    { value: "05", c_Estado: "MEX", label: "Ecatepec de Morelos" },
    { value: "05", c_Estado: "MIC", label: "La piedad de Cabadas" },
    { value: "05", c_Estado: "MOR", label: "Puente de Ixtla" },
    { value: "05", c_Estado: "NAY", label: "Tecuala" },
    { value: "05", c_Estado: "NLE", label: "Linares" },
    { value: "05", c_Estado: "OAX", label: "Matías Romero Avendaño" },
    { value: "05", c_Estado: "PUE", label: "Tehuacan" },
    { value: "05", c_Estado: "ROO", label: "Playa del Carmen" },
    { value: "05", c_Estado: "SIN", label: "Mazatlán" },
    { value: "05", c_Estado: "SLP", label: "San Luis Potosí" },
    { value: "05", c_Estado: "SON", label: "Empalme" },
    { value: "05", c_Estado: "TAB", label: "Jalpa de Méndez" },
    { value: "05", c_Estado: "TAM", label: "Heroica Matamoros" },
    { value: "05", c_Estado: "TLA", label: "Calpulalpan" },
    { value: "05", c_Estado: "VER", label: "Agua dulce" },
    { value: "05", c_Estado: "YUC", label: "Valladolid" },
    { value: "05", c_Estado: "ZAC", label: "Río Grande" },
    { value: "06", c_Estado: "AGU", label: "Rincón de Romos" },
    { value: "06", c_Estado: "BCN", label: "Rodolfo Sánchez T. (Maneadero)" },
    { value: "06", c_Estado: "BCS", label: "Puerto Adolfo López Mateos" },
    { value: "06", c_Estado: "CAM", label: "Sabancuy" },
    { value: "06", c_Estado: "COA", label: "Piedras Negras" },
    { value: "06", c_Estado: "CHH", label: "Juárez" },
    { value: "06", c_Estado: "CHP", label: "Jiquipilas" },
    { value: "06", c_Estado: "DIF", label: "Ciudad de México" },
    { value: "06", c_Estado: "DUR", label: "San Juan del Río del Centauro del Norte" },
    { value: "06", c_Estado: "GRO", label: "Ayutla de los Libres" },
    { value: "06", c_Estado: "GUA", label: "Irapuato" },
    { value: "06", c_Estado: "HID", label: "Tulancingo" },
    { value: "06", c_Estado: "JAL", label: "Puerto Vallarta" },
    { value: "06", c_Estado: "MEX", label: "Huixquilucan de Degollado" },
    { value: "06", c_Estado: "MIC", label: "Morelia" },
    { value: "06", c_Estado: "MOR", label: "Santa Rosa Treinta" },
    { value: "06", c_Estado: "NAY", label: "Compostela" },
    { value: "06", c_Estado: "NLE", label: "Montemorelos" },
    { value: "06", c_Estado: "OAX", label: "Heroica Ciudad de Huajuapan de León" },
    { value: "06", c_Estado: "PUE", label: "Teziutlan" },
    { value: "06", c_Estado: "ROO", label: "Kantunilkín" },
    { value: "06", c_Estado: "SIN", label: "Guamúchil" },
    { value: "06", c_Estado: "SLP", label: "Soledad de Graciano Sánchez" },
    { value: "06", c_Estado: "SON", label: "Heroica Guaymas" },
    { value: "06", c_Estado: "TAM", label: "Nuevo Laredo" },
    { value: "06", c_Estado: "TLA", label: "Chiautempan" },
    { value: "06", c_Estado: "VER", label: "Coatzacoalcos" },
    { value: "06", c_Estado: "YUC", label: "Kanasín" },
    { value: "06", c_Estado: "ZAC", label: "Ciudad Cuauhtémoc" },
    { value: "07", c_Estado: "AGU", label: "Asientos" },
    { value: "07", c_Estado: "BCN", label: "San Felipe" },
    { value: "07", c_Estado: "BCS", label: "Todos Santos" },
    { value: "07", c_Estado: "CAM", label: "Hopelchén" },
    { value: "07", c_Estado: "COA", label: "Sabinas" },
    { value: "07", c_Estado: "CHH", label: "Nuevo Casas Grandes" },
    { value: "07", c_Estado: "CHP", label: "Villaflores" },
    { value: "07", c_Estado: "DIF", label: "Ciudad de México" },
    { value: "07", c_Estado: "DUR", label: "Peñón Blanco" },
    { value: "07", c_Estado: "GRO", label: "Atoyac de Álvarez" },
    { value: "07", c_Estado: "GUA", label: "León de los Aldama" },
    { value: "07", c_Estado: "HID", label: "Zimapan" },
    { value: "07", c_Estado: "JAL", label: "San Juan de los Lagos" },
    { value: "07", c_Estado: "MEX", label: "Los Reyes Acaquilpan (La Paz)" },
    { value: "07", c_Estado: "MIC", label: "Pátzcuaro" },
    { value: "07", c_Estado: "MOR", label: "Zacatepec de Hidalgo" },
    { value: "07", c_Estado: "NAY", label: "Francisco I. Madero (Puga)" },
    { value: "07", c_Estado: "NLE", label: "Monterrey" },
    { value: "07", c_Estado: "OAX", label: "Loma Bonita" },
    { value: "07", c_Estado: "PUE", label: "San Andrés Cholula" },
    { value: "07", c_Estado: "ROO", label: "Isla Mujeres" },
    { value: "07", c_Estado: "SIN", label: "Navolato" },
    { value: "07", c_Estado: "SLP", label: "Charcas" },
    { value: "07", c_Estado: "SON", label: "Hermosillo" },
    { value: "07", c_Estado: "TAB", label: "Macuspana" },
    { value: "07", c_Estado: "TAM", label: "Reynosa" },
    { value: "07", c_Estado: "VER", label: "Córdoba" },
    { value: "07", c_Estado: "ZAC", label: "Ojocaliente" },
    { value: "08", c_Estado: "AGU", label: "Tepezalá" },
    { value: "08", c_Estado: "BCS", label: "Heroica Mulegé" },
    { value: "08", c_Estado: "CAM", label: "Champotón" },
    { value: "08", c_Estado: "COA", label: "Saltillo" },
    { value: "08", c_Estado: "CHP", label: "Las Margaritas" },
    { value: "08", c_Estado: "DIF", label: "Ciudad de México" },
    { value: "08", c_Estado: "DUR", label: "Francisco I. Madero" },
    { value: "08", c_Estado: "GUA", label: "Moroleón" },
    { value: "08", c_Estado: "HID", label: "Huejutla de Reyes" },
    { value: "08", c_Estado: "JAL", label: "Tepatitlán de Morelos" },
    { value: "08", c_Estado: "MEX", label: "Metepec" },
    { value: "08", c_Estado: "MIC", label: "Sahuayo de Morelos" },
    { value: "08", c_Estado: "MOR", label: "Tlaquiltenango" },
    { value: "08", c_Estado: "NAY", label: "Villa Hidalgo (El Nuevo)" },
    { value: "08", c_Estado: "NLE", label: "Ciudad Sabinas Hidalgo" },
    { value: "08", c_Estado: "OAX", label: "Puerto Escondido" },
    { value: "08", c_Estado: "PUE", label: "San Pedro Cholula" },
    { value: "08", c_Estado: "ROO", label: "Bacalar" },
    { value: "08", c_Estado: "SIN", label: "Quila" },
    { value: "08", c_Estado: "SLP", label: "Salinas de Hidalgo" },
    { value: "08", c_Estado: "SON", label: "Huatabampo" },
    { value: "08", c_Estado: "TAB", label: "Tenosique de Pino Suárez" },
    { value: "08", c_Estado: "TAM", label: "Ciudad Río Bravo" },
    { value: "08", c_Estado: "VER", label: "Tres Valles" },
    { value: "08", c_Estado: "ZAC", label: "Villa Hidalgo" },
    { value: "09", c_Estado: "AGU", label: "Cosío" },
    { value: "09", c_Estado: "BCS", label: "Villa Alberto Andrés Alvarad o Arámburo" },
    { value: "09", c_Estado: "CAM", label: "Hecelchakán" },
    { value: "09", c_Estado: "COA", label: "Nueva Rosita" },
    { value: "09", c_Estado: "CHH", label: "Madera" },
    { value: "09", c_Estado: "CHP", label: "Arriaga" },
    { value: "09", c_Estado: "DIF", label: "Ciudad de México" },
    { value: "09", c_Estado: "DUR", label: "Nombre de Dios" },
    { value: "09", c_Estado: "GRO", label: "San Jerónimo de Juárez" },
    { value: "09", c_Estado: "GUA", label: "Salamanca" },
    { value: "09", c_Estado: "HID", label: "Tlaxcoapan" },
    { value: "09", c_Estado: "JAL", label: "Tlaquepaque" },
    { value: "09", c_Estado: "MEX", label: "Naucalpan de Juárez" },
    { value: "09", c_Estado: "MIC", label: "Uruapan" },
    { value: "09", c_Estado: "NAY", label: "Ruiz" },
    { value: "09", c_Estado: "NLE", label: "San Nicolás de los Garza" },
    { value: "09", c_Estado: "OAX", label: "Río Grande o Piedra Parada" },
    { value: "09", c_Estado: "PUE", label: "Huauchinango" },
    { value: "09", c_Estado: "SIN", label: "Ahome" },
    { value: "09", c_Estado: "SLP", label: "Cárdenas" },
    { value: "09", c_Estado: "SON", label: "Navojoa" },
    { value: "09", c_Estado: "TAB", label: "Teapa" },
    { value: "09", c_Estado: "TAM", label: "San Fernando" },
    { value: "09", c_Estado: "VER", label: "Jáltipan de Morelos" },
    { value: "09", c_Estado: "ZAC", label: "Villanueva" },
    { value: "10", c_Estado: "BCS", label: "San Ignacio" },
    { value: "10", c_Estado: "COA", label: "San Pedro" },
    { value: "10", c_Estado: "CHH", label: "Colonia Anáhuac" },
    { value: "10", c_Estado: "CHP", label: "Palenque" },
    { value: "10", c_Estado: "DIF", label: "Ciudad de México" },
    { value: "10", c_Estado: "DUR", label: "Vicente Guerrero" },
    { value: "10", c_Estado: "GRO", label: "Ciudad Apaxtla de Castrejón" },
    { value: "10", c_Estado: "GUA", label: "San Francisco del Rincón" },
    { value: "10", c_Estado: "HID", label: "Tizayuca" },
    { value: "10", c_Estado: "JAL", label: "Zapopan" },
    { value: "10", c_Estado: "MEX", label: "Ciudad Nezahualcoyotl" },
    { value: "10", c_Estado: "MIC", label: "Zacapu" },
    { value: "10", c_Estado: "NAY", label: "San Blas" },
    { value: "10", c_Estado: "NLE", label: "Ciudad Santa Catarina" },
    { value: "10", c_Estado: "PUE", label: "Acatlán de Osorio" },
    { value: "10", c_Estado: "SIN", label: "Higuera de Zaragoza" },
    { value: "10", c_Estado: "SLP", label: "Cerritos" },
    { value: "10", c_Estado: "SON", label: "Heroica Nogales" },
    { value: "10", c_Estado: "TAB", label: "Paraíso" },
    { value: "10", c_Estado: "TAM", label: "Tampico" },
    { value: "10", c_Estado: "VER", label: "Xalapa-Enríquez" },
    { value: "10", c_Estado: "ZAC", label: "Villa de Cos" },
    { value: "11", c_Estado: "BCS", label: "Guerrero Negro" },
    { value: "11", c_Estado: "CAM", label: "Pomuch" },
    { value: "11", c_Estado: "COA", label: "Torreón" },
    { value: "11", c_Estado: "CHH", label: "Juan Aldama" },
    { value: "11", c_Estado: "CHP", label: "Ocosingo" },
    { value: "11", c_Estado: "DIF", label: "Ciudad de México" },
    { value: "11", c_Estado: "DUR", label: "El Salto" },
    { value: "11", c_Estado: "GRO", label: "Ciudad Altamirano" },
    { value: "11", c_Estado: "GUA", label: "Silao" },
    { value: "11", c_Estado: "HID", label: "Santiago Tulantepec" },
    { value: "11", c_Estado: "JAL", label: "Tlajomulco de Zúñiga" },
    { value: "11", c_Estado: "MEX", label: "Villa Nicolás Romero" },
    { value: "11", c_Estado: "MIC", label: "Zamora de Hidalgo" },
    { value: "11", c_Estado: "NAY", label: "Ixtlán del Río" },
    { value: "11", c_Estado: "NLE", label: "Doctor Arroyo" },
    { value: "11", c_Estado: "OAX", label: "Santiago Suchilquitongo" },
    { value: "11", c_Estado: "PUE", label: "Cuautlancingo" },
    { value: "11", c_Estado: "SIN", label: "Choix" },
    { value: "11", c_Estado: "SLP", label: "Tamuin" },
    { value: "11", c_Estado: "SON", label: "Puerto Peñasco" },
    { value: "11", c_Estado: "TAB", label: "Frontera" },
    { value: "11", c_Estado: "TAM", label: "Ciudad Victoria" },
    { value: "11", c_Estado: "VER", label: "Minatitlán" },
    { value: "11", c_Estado: "ZAC", label: "Nochistlán de Mejía" },
    { value: "12", c_Estado: "BCS", label: "Santa Rosalía" },
    { value: "12", c_Estado: "COA", label: "Castaños" },
    { value: "12", c_Estado: "CHH", label: "José Mariano Jiménez" },
    { value: "12", c_Estado: "CHP", label: "Tonalá" },
    { value: "12", c_Estado: "DIF", label: "Ciudad de México" },
    { value: "12", c_Estado: "DUR", label: "Santa María del Oro" },
    { value: "12", c_Estado: "GRO", label: "Buenavista de Cuellar" },
    { value: "12", c_Estado: "GUA", label: "Jerécuaro" },
    { value: "12", c_Estado: "HID", label: "Ixmiquilpan" },
    { value: "12", c_Estado: "JAL", label: "Tonalá" },
    { value: "12", c_Estado: "MEX", label: "Tecamac de Felipe Villanueva" },
    { value: "12", c_Estado: "MIC", label: "Heroica Zitácuaro" },
    { value: "12", c_Estado: "NAY", label: "Bucerías" },
    { value: "12", c_Estado: "NLE", label: "Ciénega de Flores" },
    { value: "12", c_Estado: "OAX", label: "San Felipe Jalapa de Díaz" },
    { value: "12", c_Estado: "PUE", label: "Tepeaca" },
    { value: "12", c_Estado: "SIN", label: "Villa Unión" },
    { value: "12", c_Estado: "SLP", label: "Tamasopo" },
    { value: "12", c_Estado: "SON", label: "Heroica Ciudad de Cananea" },
    { value: "12", c_Estado: "TAB", label: "Cunduacán" },
    { value: "12", c_Estado: "TAM", label: "González" },
    { value: "12", c_Estado: "VER", label: "Orizaba" },
    { value: "12", c_Estado: "ZAC", label: "Víctor Rosales" },
    { value: "13", c_Estado: "COA", label: "Francisco I. Madero (Chávez)" },
    { value: "13", c_Estado: "CHH", label: "Manuel Ojinaga" },
    { value: "13", c_Estado: "CHP", label: "Mapastepec" },
    { value: "13", c_Estado: "DIF", label: "Ciudad de México" },
    { value: "13", c_Estado: "GRO", label: "Cutzamala de Pinzón" },
    { value: "13", c_Estado: "GUA", label: "Santiago Maravatío" },
    { value: "13", c_Estado: "HID", label: "Tepeji del Rio" },
    { value: "13", c_Estado: "JAL", label: "Tuxpan" },
    { value: "13", c_Estado: "MEX", label: "Tepotzotlán" },
    { value: "13", c_Estado: "MIC", label: "Paracho de Verduzco" },
    { value: "13", c_Estado: "NAY", label: "Las Varas" },
    { value: "13", c_Estado: "NLE", label: "Hualahuises" },
    { value: "13", c_Estado: "OAX", label: "Bahias de Huatulco" },
    { value: "13", c_Estado: "PUE", label: "Tecamachalco" },
    { value: "13", c_Estado: "SIN", label: "Sinaloa de Leyva" },
    { value: "13", c_Estado: "SLP", label: "Ciudad del Maíz" },
    { value: "13", c_Estado: "SON", label: "Sonoita" },
    { value: "13", c_Estado: "TAB", label: "Huimanguillo" },
    { value: "13", c_Estado: "TAM", label: "Jaumave" },
    { value: "13", c_Estado: "VER", label: "Papantla de Olarte" },
    { value: "13", c_Estado: "ZAC", label: "Valparaíso" },
    { value: "14", c_Estado: "COA", label: "Cuatro Ciénegas de Carranza" },
    { value: "14", c_Estado: "CHH", label: "Bachíniva" },
    { value: "14", c_Estado: "CHP", label: "Las Rosas" },
    { value: "14", c_Estado: "DIF", label: "Ciudad de México" },
    { value: "14", c_Estado: "GRO", label: "Coyuca de Catalán" },
    { value: "14", c_Estado: "GUA", label: "Romita" },
    { value: "14", c_Estado: "HID", label: "Cruz Azul" },
    { value: "14", c_Estado: "JAL", label: "Tototlán" },
    { value: "14", c_Estado: "MEX", label: "Tlalnepantla de Baz" },
    { value: "14", c_Estado: "MIC", label: "Tangancícuaro de Arista" },
    { value: "14", c_Estado: "NAY", label: "Xalisco" },
    { value: "14", c_Estado: "OAX", label: "Putla Villa de Guerrero" },
    { value: "14", c_Estado: "PUE", label: "Zacatlán" },
    { value: "14", c_Estado: "SIN", label: "Mocorito" },
    { value: "14", c_Estado: "SLP", label: "Cedral" },
    { value: "14", c_Estado: "SON", label: "Magdalena de Kino" },
    { value: "14", c_Estado: "TAM", label: "Ciudad Gustavo Díaz Ordaz" },
    { value: "14", c_Estado: "VER", label: "Poza Rica de Hidalgo" },
    { value: "14", c_Estado: "ZAC", label: "Luis Moya" },
    { value: "15", c_Estado: "COA", label: "Nadadores" },
    { value: "15", c_Estado: "CHH", label: "Saucillo" },
    { value: "15", c_Estado: "CHP", label: "Chiapa de Corzo" },
    { value: "15", c_Estado: "DIF", label: "Ciudad de México" },
    { value: "15", c_Estado: "GRO", label: "Tierra Colorada" },
    { value: "15", c_Estado: "GUA", label: "Tarandacuao" },
    { value: "15", c_Estado: "HID", label: "Tepeapulco" },
    { value: "15", c_Estado: "JAL", label: "San Diego de Alejandría" },
    { value: "15", c_Estado: "MEX", label: "Santa Maria Tultepec" },
    { value: "15", c_Estado: "MIC", label: "Maravatío de Ocampo" },
    { value: "15", c_Estado: "NAY", label: "San pedro Lagunillas" },
    { value: "15", c_Estado: "NLE", label: "Cadereyta Jiménez" },
    { value: "15", c_Estado: "OAX", label: "Cosolapa" },
    { value: "15", c_Estado: "PUE", label: "Xicotepec" },
    { value: "15", c_Estado: "SIN", label: "Angostura" },
    { value: "15", c_Estado: "SLP", label: "Tierra Nueva" },
    { value: "15", c_Estado: "TAM", label: "Estación Manuel (Úrsulo Galván)" },
    { value: "15", c_Estado: "VER", label: "San Andrés Tuxtla" },
    { value: "15", c_Estado: "ZAC", label: "Moyahua de Estrada" },
    { value: "16", c_Estado: "COA", label: "Ramos Arizpe" },
    { value: "16", c_Estado: "CHP", label: "Cacahoatán" },
    { value: "16", c_Estado: "DIF", label: "Ciudad de México" },
    { value: "16", c_Estado: "GRO", label: "Coyuca de Benítez" },
    { value: "16", c_Estado: "GUA", label: "Huanímaro" },
    { value: "16", c_Estado: "JAL", label: "La Resolana" },
    { value: "16", c_Estado: "MEX", label: "Tultitlán de Mariano Escobedo" },
    { value: "16", c_Estado: "MIC", label: "Zinapécuaro de Figueroa" },
    { value: "16", c_Estado: "NAY", label: "La peñita de Jaltemba" },
    { value: "16", c_Estado: "OAX", label: "Tlacolula de Matamoros" },
    { value: "16", c_Estado: "PUE", label: "Ciudad Serdán" },
    { value: "16", c_Estado: "SIN", label: "San Blas" },
    { value: "16", c_Estado: "SLP", label: "Villa de Reyes" },
    { value: "16", c_Estado: "TAM", label: "Xicoténcatl" },
    { value: "16", c_Estado: "VER", label: "Túxpam de Rodríguez Cano" },
    { value: "16", c_Estado: "ZAC", label: "Sombrerete" },
    { value: "17", c_Estado: "COA", label: "Nava" },
    { value: "17", c_Estado: "CHP", label: "Ocozocoautla de Espinosa" },
    { value: "17", c_Estado: "GRO", label: "Olinalá" },
    { value: "17", c_Estado: "GUA", label: "Comonfort" },
    { value: "17", c_Estado: "JAL", label: "Atotonilco el Alto" },
    { value: "17", c_Estado: "MEX", label: "Cuautitlán" },
    { value: "17", c_Estado: "MIC", label: "Puruándiro" },
    { value: "17", c_Estado: "NAY", label: "Jala" },
    { value: "17", c_Estado: "NLE", label: "Santiago" },
    { value: "17", c_Estado: "OAX", label: "San Pablo Villa de Mitla" },
    { value: "17", c_Estado: "PUE", label: "Amozoc" },
    { value: "17", c_Estado: "SIN", label: "La Cruz" },
    { value: "17", c_Estado: "SLP", label: "Fracción el Refugio" },
    { value: "17", c_Estado: "TAM", label: "Ciudad Miguel Alemán" },
    { value: "17", c_Estado: "VER", label: "Veracruz" },
    { value: "17", c_Estado: "ZAC", label: "Jalpa" },
    { value: "18", c_Estado: "COA", label: "Zaragoza" },
    { value: "18", c_Estado: "CHP", label: "Cintalapa de Figueroa" },
    { value: "18", c_Estado: "GRO", label: "Marquelia" },
    { value: "18", c_Estado: "GUA", label: "Uriangato" },
    { value: "18", c_Estado: "JAL", label: "Jalostotitlán" },
    { value: "18", c_Estado: "MEX", label: "Ixtapaluca" },
    { value: "18", c_Estado: "MIC", label: "Yurécuaro" },
    { value: "18", c_Estado: "NAY", label: "Ahuacatlán" },
    { value: "18", c_Estado: "NLE", label: "El cercado" },
    { value: "18", c_Estado: "OAX", label: "Natividad" },
    { value: "18", c_Estado: "SIN", label: "El rosario" },
    { value: "18", c_Estado: "SLP", label: "Tamazunchale" },
    { value: "18", c_Estado: "TAM", label: "Soto la Marina" },
    { value: "18", c_Estado: "VER", label: "Tierra Blanca" },
    { value: "18", c_Estado: "ZAC", label: "Loreto" },
    { value: "19", c_Estado: "COA", label: "San Buenaventura" },
    { value: "19", c_Estado: "CHP", label: "Pichucalco" },
    { value: "19", c_Estado: "GRO", label: "Zumpango del Río" },
    { value: "19", c_Estado: "GUA", label: "Pénjamo" },
    { value: "19", c_Estado: "JAL", label: "Poncitlán" },
    { value: "19", c_Estado: "MEX", label: "Texcoco de Mora" },
    { value: "19", c_Estado: "MIC", label: "Huetamo de Núñez" },
    { value: "19", c_Estado: "OAX", label: "Teotitlán de Flores Magón" },
    { value: "19", c_Estado: "SIN", label: "Estación Naranjo" },
    { value: "19", c_Estado: "SLP", label: "Santa María del Río" },
    { value: "19", c_Estado: "TAM", label: "Ciudad Tula" },
    { value: "19", c_Estado: "VER", label: "Cosamaloapan" },
    { value: "19", c_Estado: "ZAC", label: "Juan Aldama" },
    { value: "20", c_Estado: "COA", label: "Ciudad Melchor Múzquiz" },
    { value: "20", c_Estado: "CHP", label: "Puerto Madero (San Benito)" },
    { value: "20", c_Estado: "GRO", label: "San Luis de la Loma" },
    { value: "20", c_Estado: "GUA", label: "Cuerámaro" },
    { value: "20", c_Estado: "JAL", label: "Arandas" },
    { value: "20", c_Estado: "MEX", label: "Toluca de Lerdo" },
    { value: "20", c_Estado: "MIC", label: "Tacámbaro de Codallos" },
    { value: "20", c_Estado: "NLE", label: "Anáhuac" },
    { value: "20", c_Estado: "OAX", label: "Santa María Huatulco" },
    { value: "20", c_Estado: "SIN", label: "Aguaruto" },
    { value: "20", c_Estado: "SLP", label: "El Naranjo" },
    { value: "20", c_Estado: "TAM", label: "Nueva Ciudad Guerrero" },
    { value: "20", c_Estado: "VER", label: "Carlos A. Carrillo" },
    { value: "21", c_Estado: "COA", label: "Viesca" },
    { value: "21", c_Estado: "CHP", label: "Pijijiapan" },
    { value: "21", c_Estado: "GRO", label: "Petatlán" },
    { value: "21", c_Estado: "GUA", label: "Empalme Escobedo (Escobedo)" },
    { value: "21", c_Estado: "JAL", label: "Talpa de Allende" },
    { value: "21", c_Estado: "MEX", label: "Valle de Chalco Solidaridad" },
    { value: "21", c_Estado: "MIC", label: "Ciudad Lázaro Cárdenas" },
    { value: "21", c_Estado: "NLE", label: "García" },
    { value: "21", c_Estado: "OAX", label: "San Juan Bautista Cuicatlán" },
    { value: "21", c_Estado: "SIN", label: "Cosalá" },
    { value: "21", c_Estado: "TAM", label: "Valle Hermoso" },
    { value: "21", c_Estado: "VER", label: "Pánuco" },
    { value: "22", c_Estado: "COA", label: "Morelos" },
    { value: "22", c_Estado: "CHP", label: "Reforma" },
    { value: "22", c_Estado: "GRO", label: "La Unión" },
    { value: "22", c_Estado: "GUA", label: "San Luis de la Paz" },
    { value: "22", c_Estado: "JAL", label: "Etzatlán" },
    { value: "22", c_Estado: "MEX", label: "Tejupilco de Hidalgo" },
    { value: "22", c_Estado: "MIC", label: "Las Guacamayas" },
    { value: "22", c_Estado: "NLE", label: "Ciudad Benito Juárez" },
    { value: "22", c_Estado: "OAX", label: "Villa Sola de Vega" },
    { value: "22", c_Estado: "SIN", label: "San Ignacio" },
    { value: "22", c_Estado: "VER", label: "Tampico Alto" },
    { value: "23", c_Estado: "COA", label: "Arteaga" },
    { value: "23", c_Estado: "CHP", label: "Huixtla" },
    { value: "23", c_Estado: "GRO", label: "San Luis San Pedro" },
    { value: "23", c_Estado: "GUA", label: "Valle de Santiago" },
    { value: "23", c_Estado: "JAL", label: "Sayula" },
    { value: "23", c_Estado: "MEX", label: "Chalco de Díaz Covarrubias" },
    { value: "23", c_Estado: "MIC", label: "Jiquilpan de Juárez" },
    { value: "23", c_Estado: "OAX", label: "Ocotlán de Morelos" },
    { value: "23", c_Estado: "SIN", label: "Topolobampo" },
    { value: "23", c_Estado: "VER", label: "Tempoal de Sánchez" },
    { value: "24", c_Estado: "COA", label: "Allende" },
    { value: "24", c_Estado: "CHP", label: "Motozintla de Mendoza" },
    { value: "24", c_Estado: "GRO", label: "Teloloapan" },
    { value: "24", c_Estado: "GUA", label: "Abasolo" },
    { value: "24", c_Estado: "JAL", label: "Ahualulco de Mercado" },
    { value: "24", c_Estado: "MEX", label: "Amatepec" },
    { value: "24", c_Estado: "MIC", label: "Tuxpan" },
    { value: "24", c_Estado: "OAX", label: "Villa de Zaachila" },
    { value: "24", c_Estado: "SIN", label: "Lic. Benito Juárez (Campo Gobierno)" },
    { value: "24", c_Estado: "VER", label: "Tantoyuca" },
    { value: "25", c_Estado: "CHP", label: "Acala" },
    { value: "25", c_Estado: "GRO", label: "Técpan de Galeana" },
    { value: "25", c_Estado: "GUA", label: "Rincón de Tamayo" },
    { value: "25", c_Estado: "JAL", label: "Autlán de Navarro" },
    { value: "25", c_Estado: "MIC", label: "Cotija de la Paz" },
    { value: "25", c_Estado: "OAX", label: "Miahuatlán de Porfirio Díaz" },
    { value: "25", c_Estado: "VER", label: "Gutiérrez Zamora" },
    { value: "26", c_Estado: "GRO", label: "Huitzuco" },
    { value: "26", c_Estado: "GUA", label: "Villagrán" },
    { value: "26", c_Estado: "JAL", label: "Magdalena" },
    { value: "26", c_Estado: "MEX", label: "Melchor Ocampo" },
    { value: "26", c_Estado: "MIC", label: "Nueva Italia de Ruiz" },
    { value: "26", c_Estado: "OAX", label: "Unión Hidalgo" },
    { value: "26", c_Estado: "VER", label: "Platón Sánchez" },
    { value: "27", c_Estado: "GRO", label: "Tixtla de Guerrero" },
    { value: "27", c_Estado: "GUA", label: "Yuriria" },
    { value: "27", c_Estado: "JAL", label: "San Julián" },
    { value: "27", c_Estado: "MEX", label: "San Vicente Chicoloapan de Juárez" },
    { value: "27", c_Estado: "MIC", label: "Cuitzeo del Porvenir" },
    { value: "27", c_Estado: "OAX", label: "El Camarón" },
    { value: "27", c_Estado: "VER", label: "Juan Rodríguez Clara" },
    { value: "28", c_Estado: "GRO", label: "Tepecoacuilco de Trujano" },
    { value: "28", c_Estado: "GUA", label: "Apaseo el Grande" },
    { value: "28", c_Estado: "JAL", label: "Cocula" },
    { value: "28", c_Estado: "MEX", label: "Capulhuac" },
    { value: "28", c_Estado: "OAX", label: "San Pedro Mixtepec -Dto. 22-" },
    { value: "28", c_Estado: "VER", label: "Huatusco de Chicuellar" },
    { value: "29", c_Estado: "GRO", label: "San Marcos" },
    { value: "29", c_Estado: "GUA", label: "Purísima de Bustos" },
    { value: "29", c_Estado: "JAL", label: "El Grullo" },
    { value: "29", c_Estado: "MEX", label: "Juchitepec de Mariano Riva Palacio" },
    { value: "29", c_Estado: "OAX", label: "Santa Cruz Itundujia" },
    { value: "29", c_Estado: "VER", label: "Ixtaczoquitlán" },
    { value: "30", c_Estado: "GRO", label: "Azoyú" },
    { value: "30", c_Estado: "GUA", label: "Salvatierra" },
    { value: "30", c_Estado: "JAL", label: "San Miguel el Alto" },
    { value: "30", c_Estado: "MEX", label: "Tequixquiac" },
    { value: "30", c_Estado: "OAX", label: "Chahuites" },
    { value: "30", c_Estado: "VER", label: "Río Blanco" },
    { value: "31", c_Estado: "GRO", label: "Tlapehuala" },
    { value: "31", c_Estado: "GUA", label: "Marfil" },
    { value: "31", c_Estado: "JAL", label: "Tala" },
    { value: "31", c_Estado: "MEX", label: "Xonacatlán" },
    { value: "31", c_Estado: "OAX", label: "Heroica Ciudad de Ejutla de Crespo" },
    { value: "31", c_Estado: "VER", label: "Isla" },
    { value: "32", c_Estado: "GRO", label: "San Luis Acatlán" },
    { value: "32", c_Estado: "GUA", label: "San José Iturbide" },
    { value: "32", c_Estado: "JAL", label: "La Barca" },
    { value: "32", c_Estado: "MEX", label: "San Mateo Atenco" },
    { value: "32", c_Estado: "OAX", label: "San Pedro Tapanatepec" },
    { value: "32", c_Estado: "VER", label: "Cuitláhuac" },
    { value: "33", c_Estado: "GRO", label: "Chilapa de Álvarez" },
    { value: "33", c_Estado: "GUA", label: "Apaseo el Alto" },
    { value: "33", c_Estado: "JAL", label: "Jamay" },
    { value: "33", c_Estado: "OAX", label: "Vicente Camalote" },
    { value: "33", c_Estado: "VER", label: "Fortín de las Flores" },
    { value: "34", c_Estado: "GRO", label: "Tlapa de Comonfort" },
    { value: "34", c_Estado: "GUA", label: "Ciudad Manuel Doblado" },
    { value: "34", c_Estado: "JAL", label: "Yahualica de González Gallo" },
    { value: "34", c_Estado: "OAX", label: "Villa de Tamazulápam del Progreso" },
    { value: "34", c_Estado: "VER", label: "Alvarado" },
    { value: "35", c_Estado: "GRO", label: "Tlalixtaquilla" },
    { value: "35", c_Estado: "GUA", label: "Jaral del Progreso" },
    { value: "35", c_Estado: "JAL", label: "Colotlán" },
    { value: "35", c_Estado: "OAX", label: "San Juan Bautista lo de Soto" },
    { value: "35", c_Estado: "VER", label: "José Cardel" },
    { value: "36", c_Estado: "GRO", label: "Cuajinicuilapa" },
    { value: "36", c_Estado: "GUA", label: "San Diego de la Unión" },
    { value: "36", c_Estado: "JAL", label: "Cihuatlán" },
    { value: "36", c_Estado: "MEX", label: "Chiconcuac" },
    { value: "36", c_Estado: "OAX", label: "San Juan Cacahuatepec" },
    { value: "36", c_Estado: "VER", label: "Banderilla" },
    { value: "37", c_Estado: "GRO", label: "Huamuxtitlan" },
    { value: "37", c_Estado: "GUA", label: "Santa Cruz Juventino Rosas" },
    { value: "37", c_Estado: "JAL", label: "Zapotiltic" },
    { value: "37", c_Estado: "OAX", label: "San Pedro Totolapa" },
    { value: "37", c_Estado: "VER", label: "Paraje Nuevo" },
    { value: "38", c_Estado: "GRO", label: "Cruz Grande" },
    { value: "38", c_Estado: "GUA", label: "Doctor Mora" },
    { value: "38", c_Estado: "JAL", label: "Villa Corona" },
    { value: "38", c_Estado: "OAX", label: "San Miguel el Grande" },
    { value: "38", c_Estado: "VER", label: "Playa Vicente" },
    { value: "39", c_Estado: "GRO", label: "Ocotito" },
    { value: "39", c_Estado: "GUA", label: "Dolores Hgo. Cuna de la Inde p. Nal." },
    { value: "39", c_Estado: "JAL", label: "Teocaltiche" },
    { value: "39", c_Estado: "MEX", label: "Almoloya de Juárez" },
    { value: "39", c_Estado: "OAX", label: "Zimatlán de Álvarez" },
    { value: "39", c_Estado: "VER", label: "Altotonga" },
    { value: "40", c_Estado: "GRO", label: "Copala" },
    { value: "40", c_Estado: "MEX", label: "Ocoyoacac" },
    { value: "40", c_Estado: "OAX", label: "San Pablo Huitzo" },
    { value: "40", c_Estado: "VER", label: "Juan Díaz Covarrubias" },
    { value: "41", c_Estado: "GRO", label: "Zihuatanejo" },
    { value: "41", c_Estado: "JAL", label: "Tequila" },
    { value: "41", c_Estado: "MEX", label: "Zumpango" },
    { value: "41", c_Estado: "OAX", label: "San Francisco Telixtlahuaca" },
    { value: "41", c_Estado: "VER", label: "Cuichapa" },
    { value: "42", c_Estado: "JAL", label: "El Quince (San José el Quince)" },
    { value: "42", c_Estado: "OAX", label: "Mariscala de Juárez" },
    { value: "42", c_Estado: "VER", label: "Santiago Tuxtla" },
    { value: "43", c_Estado: "JAL", label: "San José el Verde (El Verde)" },
    { value: "43", c_Estado: "OAX", label: "Santiago Pinotepa Nacional" },
    { value: "43", c_Estado: "VER", label: "Huayacocotla" },
    { value: "44", c_Estado: "JAL", label: "Jocotepec" },
    { value: "44", c_Estado: "OAX", label: "Santiago Jamiltepec" },
    { value: "44", c_Estado: "VER", label: "Paso de Ovejas" },
    { value: "45", c_Estado: "JAL", label: "Tecalitlán" },
    { value: "45", c_Estado: "OAX", label: "San Pedro Pochutla" },
    { value: "45", c_Estado: "VER", label: "Catemaco" },
    { value: "46", c_Estado: "JAL", label: "Chapala" },
    { value: "46", c_Estado: "OAX", label: "Heroica Ciudad de Tlaxiaco" },
    { value: "46", c_Estado: "VER", label: "Nogales" },
    { value: "47", c_Estado: "JAL", label: "Ajijic" },
    { value: "47", c_Estado: "OAX", label: "San Juan Bautista Valle Nacional" },
    { value: "47", c_Estado: "VER", label: "Las Choapas" },
    { value: "48", c_Estado: "JAL", label: "San Ignacio Cerro Gordo" },
    { value: "48", c_Estado: "OAX", label: "Lagunas" },
    { value: "48", c_Estado: "VER", label: "General Miguel Alemán (Potrero Nuevo)" },
    { value: "49", c_Estado: "JAL", label: "Zacoalco de Torres" },
    { value: "49", c_Estado: "OAX", label: "Ciudad Ixtepec" },
    { value: "49", c_Estado: "VER", label: "Coatzintla" },
    { value: "50", c_Estado: "JAL", label: "Huejuquilla el Alto" },
    { value: "50", c_Estado: "OAX", label: "Santiago Juxtlahuaca" },
    { value: "50", c_Estado: "VER", label: "Ángel R. Cabada" },
    { value: "51", c_Estado: "JAL", label: "Villa Hidalgo" },
    { value: "51", c_Estado: "OAX", label: "San Sebastián Tecomaxtlahuaca" },
    { value: "51", c_Estado: "VER", label: "San Rafael" },
    { value: "52", c_Estado: "JAL", label: "Unión de San Antonio" },
    { value: "52", c_Estado: "OAX", label: "Asunción Nochixtlán" },
    { value: "52", c_Estado: "VER", label: "Tlacojalpan" },
    { value: "53", c_Estado: "JAL", label: "Las Pintitas" },
    { value: "53", c_Estado: "OAX", label: "San Francisco Ixhuatán" },
    { value: "53", c_Estado: "VER", label: "Cosoleacaque" },
    { value: "54", c_Estado: "JAL", label: "Tamazula de Gordiano" },
    { value: "54", c_Estado: "OAX", label: "San Blas Atempa" },
    { value: "54", c_Estado: "VER", label: "Lerdo de Tejada" },
    { value: "55", c_Estado: "JAL", label: "Acatlán de Juárez" },
    { value: "55", c_Estado: "OAX", label: "Santo Domingo Tehuantepec" },
    { value: "55", c_Estado: "VER", label: "Tihuatlán" },
    { value: "56", c_Estado: "JAL", label: "Valle de Guadalupe" },
    { value: "56", c_Estado: "OAX", label: "Cuilápam de Guerrero" },
    { value: "56", c_Estado: "VER", label: "Atoyac" },
    { value: "57", c_Estado: "OAX", label: "El Rosario" },
    { value: "57", c_Estado: "VER", label: "Huiloapan de Cuauhtémoc" },
    { value: "58", c_Estado: "OAX", label: "Santa Lucia del Camino" },
    { value: "58", c_Estado: "VER", label: "Cazones de Herrera" },
    { value: "59", c_Estado: "OAX", label: "San Antonio de la Cal" },
    { value: "59", c_Estado: "VER", label: "Yecuatla" },
    { value: "60", c_Estado: "VER", label: "Soledad de Doblado" },
    { value: "61", c_Estado: "VER", label: "Cerro Azul" },
    { value: "62", c_Estado: "VER", label: "Tezonapa" },
    { value: "66", c_Estado: "VER", label: "Sihuapan" },
    { value: "67", c_Estado: "VER", label: "El Higo" },
    { value: "68", c_Estado: "VER", label: "Paso del Macho" },
    { value: "69", c_Estado: "VER", label: "Tlapacoyan" },
]

export default localidad_data;