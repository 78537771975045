import React from "react";
import {
  FormGroup,
  Input,
  Button,
  Label,
  Form,
  Row,
  Col
} from 'reactstrap';

import { Panel } from 'primereact/panel';
import { Toast } from 'primereact/toast';
import AdminUserService from "controllers/admin-user/service";

const permissions = [
  {
    module: 'tablero',
    label: 'Tablero',
  },
  {
    module: 'usuarios-administradores',
    label: 'Usuarios administradores',
    list:[
      {
        module: 'usuario-administrador_crear',
        label: 'Agregar Usuarios administradores',
      },
      {
        module: 'usuarios-administradores_consulta',
        label: 'Consulta Usuarios administradores',
        child:[
        {
          module: 'usuario-administrador_editar',
          label: 'Editar Usuarios administradores',
        },
        {
          module: 'usuario-administrador_borrar',
          label: 'Borrar Usuarios administradores'
        }
        ]
      },
    ],
  },
  {
    module: 'clientes',
    label: 'Clientes',
    list: [
      {
        module: 'clientes_crear',
        label: 'Agregar Clientes',
      },
      {
        module: 'clientes_consulta',
        label: 'Consulta Clientes',
        child:[
          {
            module: 'cliente_editar',
            label: 'Editar Clientes',
          },
          {
            module: 'cliente_borrar',
            label: 'Borrar Clientes'
          },
        ]
      },
      {
        module: 'rutas_clientes',
        label: 'Rutas Clientes',
        list: [
          {
            module: 'clientes_rutas_id',
            label: 'Agregar Alta de ID',
          },
          {
            module: 'clientes_rutas_version',
            label: 'Agregar Alta de Versiones',
          },
          {
            module: 'clientes_rutas_consulta',
            label: 'Consulta ID',
            child:[
              {
                module: 'ruta_editar',
                label: 'Editar Consulta ID',
              },
              {
                module: 'ruta_borrar',
                label: 'Borrar Consulta ID'
              },
            ]
          }
        ]
      }
    ]
  },
  {
    module: 'unidades',
    label: 'Unidades',
    list: [
      {
        module: 'unidades_crear',
        label: 'Agregar Unidades',
      },
      {
        module: 'unidades_consulta',
        label: 'Consulta Unidades',
        list:[
          {
            module: 'unidad_descargar_informacion',
            label: 'Descargar Información Unidades',
          },
          {
            module: 'unidad_editar',
            label: 'Editar Unidades',
          },
          {
            module: 'unidad_borrar',
            label: 'Borrar Unidades',
          }
        ],
      },
      {
        module: 'llantas_unidades',
        label: 'Llantas Unidades',
        list: [
          {
            module: 'unidades_llantas_alta',
            label: 'Agregar Alta De Llantas',
          },
          {
            module: 'unidades_llantas_asignar',
            label: 'Agregar Asignar Llantas',
          },
          {
            module: 'unidades_llantas_consultar',
            label: 'Consultar Llantas Inventario',
            child:[
              {
                module: 'llanta_descarga',
                label: 'Descarga inventario de llantas',
              },
              {
                module: 'llanta_editar',
                label: 'Editar inventario Llantas',
              },
              {
                module: 'llanta_borrar',
                label: 'Borrar inventario Llantas'
              },
              {
                module: 'llanta_ver_qr',
                label: 'Ver QR inventario Llantas'
              },
            ],
          },
          {
            module: 'unidades_llantas_semaforo_consultar',
            label: 'Semáforo',
          }
        ]
      }
    ]
  },
  {
    module: 'operadores',
    label: 'Operadores',
    list: [
      {
        module: "operadores_crear",
        label: "Agregar Operador",
      },
      {
        module: "operadores_consulta",
        label: "Consulta Operadores",
        child:[
          {
            module: 'operador_descargar',
            label: 'Descargar Operadores Reporte'
          },
          {
            module: 'operador_documents',
            label: 'Ver Documentos Operadores'
          },
          {
            module: 'operador_editar',
            label: 'Editar Operadores',
          },
          {
            module: 'operador_borrar',
            label: 'Borrar Operadores'
          },
        ],
      }
    ]
  },
  {
    module: 'embarques',
    label: 'Embarques',
    list: [
      {
        module: "embarque_nuevo",
        label: "Agregar Embarque",
      },
      {
        module: "embarques_consulta",
        label: "Consulta Embarques",
        child:[
          {
            module: 'embarque_descargar',
            label: 'Descargar Embarques Reporte',
          },
          {
            module: 'embarque_editar',
            label: 'Editar Embarques',
          },
          {
            module: 'embarque_borrar',
            label: 'Borrar Embarques'
          },
          {
            module: 'embarque_abrir_documento',
            label: 'Abrir Documento Embarques'
          },
        ],
      }
    ],
  },
  {
    module: "entrega-inspeccion",
    label: "Entrega inspección",
    list: [
          {
            module: 'entrega-inspeccion_nuevo',
            label: 'Agregar Entrega inspección',
          },
          {
            module: 'entrega-inspeccion_consulta',
            label: 'Consulta Entrega inspección',
            child:[
              {
                module: 'entrega-inspeccion_borrar',
                label: 'Borrar Entrega inspección'
              },
              {
                module: 'entrega-inspeccion_abrir_documento',
                label: 'Abrir Documento Entrega inspección'
              }
            ]
          }
    ]
  },
  {
    module: 'mantenimiento',
    label: 'Mantenimiento',
    list: [
      {
        module: "mantenimiento_catalogo",
        label: "Catálogo de actividades Mantenimiento",
        child: [
          {
            module: 'mantenimiento_catalogo_agregar',
            label: 'Agregar Catálogo de actividades Mantenimiento',
          },
          {
            module: 'mantenimiento_catalogo_editar',
            label: 'Editar Catálogo de actividades Mantenimiento'
          },
          {
            module: 'mantenimiento_catalogo_borrar',
            label: 'Borrar Catálogo de actividades Mantenimiento'
          },
    ]
      },
      {
        module: "mantenimiento_proveedores",
        label: "Catálogo de proveedores Mantenimiento",
        list: [
          {
            module: 'mantenimiento_proveedores_agregar',
            label: 'Agregar Catálogo de proveedores Mantenimiento',
          },
          {
            module: 'mantenimiento_proveedores_editar',
            label: 'Editar Catálogo de proveedores Mantenimiento'
          },
          {
            module: 'mantenimiento_proveedores_borrar',
            label: 'Borrar Catálogo de proveedores Mantenimiento'
          },
        ]
      },
      {
        module: "mantenimiento_componentes",
        label: "Catálogo de componentes Mantenimiento",
        list: [
          {
            module: 'mantenimiento_componentes_agregar',
            label: 'Agregar Catálogo de componentes Mantenimiento',
          },
          {
            module: 'mantenimiento_componentes_editar',
            label: 'Editar Catálogo de componentes Mantenimiento'
          },
          {
            module: 'mantenimiento_componentes_borrar',
            label: 'Borrar Catálogo de componentes Mantenimiento'
          },
        ]
      },
      {
        module: "preventivo_mantenimiento",
        label: "Preventivo Mantenimiento",
        list: [
          {
            module: "mantenimiento_preventivo_fallas",
            label: "Reporte de fallas",
            child: [
              {
                module: 'mantenimiento_preventivo_fallas_nueva',
                label: 'Agregar Reporte de fallas',
              },
              {
                module: 'mantenimiento_preventivo_fallas_editar',
                label: 'Editar Reporte de fallas'
              },
              {
                module: 'mantenimiento_preventivo_fallas_abrir_documento',
                label: 'Abrir Documento Reporte de fallas'
              },
              {
                module: 'mantenimiento_preventivo_fallas_borrar',
                label: 'Borrar Reporte de fallas'
              },
            ]
          },
          {
            module: "mantenimiento_preventivo_ordenes",
            label: "Ordenes de compra",
            child: [
              {
                module: 'mantenimiento_preventivo_ordenes_nueva',
                label: 'Agregar Ordenes de compra',
              },
              {
                module: 'mantenimiento_preventivo_ordenes_editar',
                label: 'Editar Ordenes de compra'
              },
              {
                module: 'mantenimiento_preventivo_ordenes_abrir_documento',
                label: 'Abrir Documento Editar Ordenes de compra'
              },
              {
                module: 'mantenimiento_preventivo_ordenes_borrar',
                label: 'Borrar Ordenes de compra'
              },
            ]
          }
        ]
      },
      {
        module: "correctivo_mantenimiento",
        label: "Correctivo Mantenimiento",
        list: [
          {
            module: "mantenimiento_correctivo_ordenes-servicio",
            label: "Ordenes de servicio",
              child: [
                {
                  module: 'mantenimiento_correctivo_ordenes-servicio_nueva',
                  label: 'Agregar Ordenes de servicio',
                },
                {
                  module: 'mantenimiento_correctivo_ordenes-servicio_editar',
                  label: 'Editar Ordenes de servicio'
                },
                {
                  module: 'mantenimiento_correctivo_ordenes-servicio_abrir_documento',
                  label: 'Abrir Documento Editar Ordenes de servicio'
                },
                {
                  module: 'mantenimiento_correctivo_ordenes-servicio_borrar',
                  label: 'Borrar Ordenes de servicio'
                },
              ]
          },
          {
            module: "mantenimiento_correctivo_reparaciones",
            label: "Reparaciones",
            child: [
              {
                module: 'mantenimiento_correctivo_reparacion_nueva',
                label: 'Agregar Reparaciones',
              },
              {
                module: 'mantenimiento_correctivo_reparacion_editar',
                label: 'Editar Reparaciones'
              },
              {
                module: 'mantenimiento_correctivo_reparacion_borrar',
                label: 'Borrar Reparaciones'
              },
            ]
          }
        ]
      }
    ]
  },
  {
    module: 'nomina',
    label: 'Nómina',
    list: [
      {
        module: "nomina_carga",
        label: "Carga De Nómina",
        child: [
          {
            module: 'nomina_carga_descargar_plantilla',
            label: 'Descargar plantilla Nómina',
          },
          {
            module: 'nomina_carga_subir_nomina',
            label: 'Subir Nómina'
          },
        ]
      },
      {
        module: "nomina_desglose",
        label: "Desglose De Nómina",
        child:[
          {
            module: 'nomina_desglose_descargar',
            label: 'Descargar Desglose De Nómina',
          },
        ]
      }
    ]
  },
  {
    module: 'vigilancia',
    label: 'Vigilancia',
    list: [
      {
        module: "vigilancia_accesos",
        label: "Acceso Vigilancia",
        child: [
          {
            module: 'vigilancia_accesos_agregar',
            label: 'Agregar Acceso Vigilancia',
          },
          {
            module: 'vigilancia_accesos_editar',
            label: 'Editar Acceso Vigilancia'
          },
          {
            module: 'vigilancia_accesos_abrir_documento',
            label: 'Abrir Documento Editar Acceso Vigilancia'
          },
          {
            module: 'vigilancia_accesos_borrar',
            label: 'Borrar Acceso Vigilancia'
          },
        ]
      },
    ]
  },
  {
    module: 'inspeccion',
    label: 'Inspección',
    list: [
      {
        module: "inspeccion_inspeccion-17",
        label: "Agregar Inspección Agrícola",
      },
      {
        module: "inspeccion_inspeccion-7",
        label: "Agregar Inspección 7 puntos",
      },
      {
        module: "inspeccion_checklist-despacho",
        label: "Agregar CheckList Despacho",
      },
      {
        module: "inspeccion_reportes",
        label: "Reportes Inspección",
        child: [
          {
            module: 'inspeccion_reportes_abrir_documento',
            label: 'Abrir Documento Inspeccion Reportes'
          },
          {
            module: 'inspeccion_reportes_borrar',
            label: 'Borrar Inspeccion Reportes'
          },
        ]
      }
    ]
  },
];

class AddEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.servicioClientes = new AdminUserService();
    this.state = {
      permisos: {
        tablero: true,
      }
    };
  }

  componentDidUpdate(prevProps){
		if(prevProps.permisos  !== this.props.permisos){
      const parsePermisos = JSON.parse(this.props?.permisos)
      if(parsePermisos)
      this.setState({permisos: parsePermisos})
		}
	}
  

  handleCheckboxChange = (e, val, listVal, listValChild, nestedListVal, nestedListValChild, childVal) => {
    const { permisos } = this.state;
    const newData = { ...permisos };
    
    
    // Updated the checked status in the state
    if(e.target.checked){
      if (childVal) {
        newData[childVal.module] = e.target.checked;
      } else if (nestedListValChild) {
        newData[nestedListValChild.module] = e.target.checked;
      } else if (nestedListVal) {
        newData[nestedListVal.module] = e.target.checked;
      } else if (listValChild) {
        newData[listValChild.module] = e.target.checked;
      } else if (listVal) {
        newData[listVal.module] = e.target.checked;
      } else {
        newData[val.module] = e.target.checked;
      }
      this.setState({ permisos: newData });
    }else{
      if (childVal) {
        delete newData[childVal.module]
      } else if (nestedListValChild) {
        delete newData[nestedListValChild.module]
      } else if (nestedListVal) {
        nestedListVal.child && nestedListVal.child.map((nestedListValChild)=>{
            delete newData[nestedListValChild?.module]
        })
        delete newData[nestedListVal?.module]
      } else if (listValChild) {
        delete newData[listValChild?.module]
      } else if (listVal) {
         listVal.child && listVal.child.map((listValChild)=>{
              delete newData[listValChild?.module]
          })
          listVal.list && listVal.list.map((nestedListVal)=>{
              delete newData[nestedListVal?.module]
              nestedListVal.child && nestedListVal.child.map((nestedListValChild)=>{
                  delete newData[nestedListValChild?.module]
              })
          })
         delete newData[listVal?.module];
      } else {
        val.child && val.child.map((childVal) =>{
          delete newData[childVal?.module]
        })
        val.list && val.list.map((listVal) =>{
          listVal.child && listVal.child.map((listValChild)=>{
              delete newData[listValChild?.module]
          })
          listVal.list && listVal.list.map((nestedListVal)=>{
              delete newData[nestedListVal?.module]
              nestedListVal.child && nestedListVal.child.map((nestedListValChild)=>{
                  delete newData[nestedListValChild?.module]
              })
          })
           delete newData[listVal?.module];
        })
          delete newData[val?.module];
      }
      this.setState({ permisos: newData });
    }
  };


  render() {
    return (
      <div className="container">
        <Toast ref={(el) => this.growl = el} />
        <Panel header={this.props.title}>
            <Form onSubmit={(e)=> this.props.handleSubmit(e, this.state.permisos)}>
            <Row>
            <Col xs="12" md="6">
              <FormGroup>
                <Label for="nombre">Nombre</Label>
                <Input type="text" name="nombre" id="nombre" value={this.props?.nombre} onChange={this.props.handleChange} required/>
              </FormGroup>
            </Col>
            <Col xs="12" md="6">
              <FormGroup>
                <Label for="nombre_de_usuario">Nombre de Usuario</Label>
                <Input type="text" name="nombre_de_usuario" id="nombre_de_usuario" value={this.props?.nombre_de_usuario} onChange={this.props.handleChange} required/>
              </FormGroup>
            </Col>
            </Row>
            <Row>
            <Col xs="12" md="6">
              <FormGroup>
                <Label for="correo_electronico">Correo Electrónico</Label>
                <Input type="email" name="correo_electronico" id="correo_electronico" value={this.props?.correo_electronico} onChange={this.props.handleChange} required/>
              </FormGroup>
            </Col>
            <Col xs="12" md="6">
              <FormGroup>
                <Label for="contrasena">Contraseña</Label>
                <Input type="text" name="contrasena" id="contrasena" value={this.props?.contrasena} onChange={this.props.handleChange} required/>
              </FormGroup>
            </Col>
            </Row>
            <div className="border border-2" style={{borderColor: 'black'}}>
              <div className="text-center font-weight-bold border-bottom p-2" style={{ background: '#F2F2F2'}}>Permisos</div>
              <div className="container">
                {permissions.map((val, i) => (
                  <div key={i} className="row p-2 border-bottom">
                    <Col sm="6">
                      <FormGroup check>
                        <Label check>
                          <Input type="checkbox"
                          checked ={val.module === "tablero" ? true : this.state?.permisos[val?.module]}
                          onChange={(e) => this.handleCheckboxChange(e, val)}
                          disabled = {val.module === "tablero" ? true : false}
                          />{' '}
                          {val.label}
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      {val.list && val.list.map((listVal, j) => (
                        <div key={`${i}-${j}`}>
                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox"
                               checked ={this.state.permisos[listVal.module]}
                               disabled = {!this.state.permisos[val.module]}
                               onChange={(e) => this.handleCheckboxChange(e, val, listVal)}/>{' '}
                              {listVal.label}
                            </Label>
                          </FormGroup>
                          {listVal.child && listVal.child.map((listValChild, k) => (
                            <div key={`${i}-${j}-${k}`} className="pl-3">
                              <FormGroup check>
                                <Label check>
                                  <Input type="checkbox"
                                   checked ={this.state.permisos[listValChild.module]} 
                                   disabled = {!this.state.permisos[listVal.module]}
                                   onChange={(e) => this.handleCheckboxChange(e, val, listVal, listValChild)}/>{' '}
                                  {listValChild.label}
                                </Label>
                              </FormGroup>
                            </div>
                          ))}
                          {listVal.list && listVal.list.map((nestedListVal, k) => (
                            <div key={`${i}-${j}-nested-${k}`} className="pl-3">
                              <FormGroup check>
                                <Label check>
                                  <Input type="checkbox"
                                   checked ={this.state.permisos[nestedListVal.module]} 
                                   disabled = {!this.state.permisos[listVal.module]}
                                   onChange={(e) => this.handleCheckboxChange(e, val, listVal, null, nestedListVal)}/>{' '}
                                  {nestedListVal.label}
                                </Label>
                              </FormGroup>
                              {nestedListVal.child && nestedListVal.child.map((nestedListValChild, l) => (
                                <div key={`${i}-${j}-nested-${k}-${l}`} className="pl-3">
                                  <FormGroup check>
                                    <Label check>
                                      <Input type="checkbox" 
                                       checked ={this.state.permisos[nestedListValChild.module]}
                                       disabled = {!this.state.permisos[nestedListVal.module]}
                                       onChange={(e) => this.handleCheckboxChange(e, val, listVal, null, nestedListVal, nestedListValChild)}/>{' '}
                                      {nestedListValChild.label}
                                    </Label>
                                  </FormGroup>
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>
                      ))}
                      {val.child && val.child.map((childVal, j) => (
                        <div key={`${i}-child-${j}`}>
                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox"
                              checked ={this.state.permisos[childVal.module]}
                              disabled = {!this.state.permisos[val.module]}
                              onChange={(e) => this.handleCheckboxChange(e, val, null, null, null, null, childVal)}/>{' '}
                              {childVal.label}
                            </Label>
                          </FormGroup>
                        </div>
                      ))}
                    </Col>
                  </div>
                ))}
              </div>
            </div>
              <center style={{padding:'10px'}}> <Button color="primary">Guardar</Button></center>
            </Form>
        </Panel>
      </div>
    );
  }
}

export default AddEditForm;