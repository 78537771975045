import React from "react";
import {
	Card,
	CardBody,Col, Row, Container,CardTitle

} from 'reactstrap';
import {Calendar} from 'primereact/calendar';
import {Button} from 'primereact/button';
import Select from 'react-select';
import  CartaPorteListado from './carta-porte-views.js';
import { Toast } from 'primereact/toast';
import EditarCartaPorte from './editar';
import CartaPorteService  from 'controllers/carta-porte/carta-porte-service';

class EditarPagina extends React.Component {
	constructor(props){
		super(props);
		this.state={};
	}

	componentDidMount(){
		this.setState({
			item : this.props.location.state
		});
	}

	render() {

		return (
			<Card>
				<CardTitle  className="bg-light border-bottom p-3 mb-0"><i className="mdi mdi-file-document-box mr-2"> </i> EDITAR CARTA PORTE <Button style={{float:'right'}} onClick={e=> this.props.history.goBack()}  label="Regresar"/></CardTitle>
				<CardBody  >
				<Toast ref={(el) => this.growl = el} />
					<Row>
						<Col>
							<EditarCartaPorte values={this.state.item} history = {this.props.history} />
						</Col>
					</Row>
				</CardBody>
			</Card>
		);
	}
}

export default EditarPagina;
