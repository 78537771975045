import React from "react";
import {
	Row,Col
} from 'reactstrap';
import { Panel } from 'primereact/panel';

import { Toast } from 'primereact/toast';
import moment from 'moment';
import Select from 'react-select';
import { RadioButton } from 'primereact/radiobutton';
import VigilanciaService from  'controllers/vigilancia/vigilancia-service';
import PCalendar from '../../../ui-components/calendar';
import PTimer from '../../../ui-components/timer';
import {Button} from 'primereact/button';

const marca = [
{value:"Kenworth",label:"Kenworth"},
{value:"Freightliner",label:"Freightliner"},
{value:"International",label:"International"},
{value:"Wabash",label:"Wabash"},
{value:"Great Dane",label:"Great Dabe"},
{value:"Utility",label:"Utility"},
{value:"Stoughton",label:"Stoughton"},
{value:"Hyundai",label:"Hyundai"},
{value:"VW",label:"VW"},
{value:"Audi",label:"Audi"},
{value:"Ford",label:"Ford"}

];



const tipo_unidades = [
	{value:"Tractocamión",label:"Tractocamión"},
	{value:"Remolque 53 pies",label:"Remolque 53 pies"},
    {value:"Remolque 48 pies",label:"Remolque 48 pies"},
    {value:"Camión Rabon",label:"Camión Rabon"},
    {value:"Camión Torton",label:"Camión Torton"},
    {value:"Camión 3.5 ton",label:"Camión 3.5 ton"},
    {value:"Pick up",label:"Pick up"},
    {value:"Vehículo utilitario",label:"Vehículo utilitario"},
    {value:"Motocicleta",label:"Motocicleta"},
    {value:"Dolly",label:"Dolly"}
]

const tipoPersonal = [
	{label:"Empleado",value:"Empleado"},
	{label:"Operador",value:"Operador"},
	{label:"Visita",value:"Visita"},
	{label:"Proveedor",value:"Proveedor"}
]
const tipoIdentificacion = [
	{label:"INE",value:"INE"},
	{label:"Licencia",value:"Licencia"},
	{label:"Otro",value:"Otro"}
];

const options = [];

class RegistrarAcceso extends React.Component {
	constructor(props){
		super(props);
		this.state={
				// hora:new Date(),
		}
		this.servicio = new VigilanciaService();
		this.handleSubmit = this.handleSubmit.bind(this);
		this.getCommonDataFromChilds = this.getCommonDataFromChilds.bind(this);

	}




	componentDidMount() {
	  let values = this.props.location.state;
	  if(values){
	    this.setState({
				fecha:values.fecha,
				tipo:values.tipo,
				hora:values.hora,
				personal:values.personal,
				nombre:values.nombre,
				a_quien_visita:values.a_quien_visita,
				motivo:values.motivo,
				tipo_identificacion:values.tipo_identificacion,
				gafete:values.gafete,
				tipo_vehiculo_p:values.tipo_vehiculo_p,
				marca:values.marca_unidad,
				marca_remolque:values.marca_remolque,
				tipo_vehiculo:values.tipo_vehiculo,
				placas:values.placas,
				eco_unidad:values.eco_unidad,
				eco_remolque:values.eco_remolque,
				estado:values.estado,
				sello:values.sello,
				origen:values.origen,
				destino:values.destino,
				folio:values.folio,
	      id:values.id,
	      titulo:"EDITAR",
	      sobreescribir: true
	    });
	  }

	}

	handleSubmit(event) {
		event.preventDefault();
		let that = this;

		let datos ={};
		const data = new FormData();
		datos.fecha = moment(this.state.fecha).format("YYYY-MM-DD");
		datos.tipo = this.state.tipo;
		datos.hora = (this.state.hora)?this.state.hora:moment(this.state.fecha).format('hh:mm A').toString();
		datos.personal = this.state.personal;
		datos.nombre = this.state.nombre;
		datos.a_quien_visita = this.state.a_quien_visita;
		datos.motivo = this.state.motivo;
		datos.tipo_identificacion = this.state.tipo_identificacion;
		datos.gafete = this.state.gafete;
		datos.tipo_vehiculo_p = this.state.tipo_vehiculo_p;
		datos.marca_unidad = this.state.marca;
		datos.marca_remolque = this.state.marca_remolque;
		datos.tipo_vehiculo = this.state.tipo_vehiculo;
		datos.placas = this.state.placas;
		datos.eco_unidad = this.state.eco_unidad;
		datos.eco_remolque = this.state.eco_remolque;
		datos.estado = this.state.estado;
		datos.sello = this.state.sello;
		datos.origen = this.state.origen;
		datos.destino = this.state.destino;


		// data.append("ruta",JSON.stringify(datos));
		// data.append("contrato",this.state.contrato);
		// data.append("asignacion",this.state.asignacion);


		let funcion = console.log;
		if(this.state.sobreescribir){
			funcion = this.servicio.actualizarReporte;
			datos.id = this.state.id;
		}else{
			funcion = this.servicio.guardarReporteAcceso;
		}

		funcion(datos).then(res =>  {
					if(res && res.result == 'OK'){

						that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Información guardada correctamente'});
						setTimeout(function(){
									that.props.history.push('/app/vigilancia/accesos');
							}, 500);

					}else if(res && res.result == 'EXISTE'){
						that.growl.show({severity: 'error', summary: 'El Id de ruta '+this.state.id_ruta+' ya se encuentra registrado', detail: 'Ingrese otro código o consulte su información para editarla.'});
					}
		});
	}
	getCommonDataFromChilds(data)
	{
		switch (data.label) {
			case "fecha":
				this.setState({fecha: moment(data.value).format('YYYY-MM-DD').toString(),semana:moment(data.value).week()});
				break;
			case "hora":
				this.setState({hora: data.value});
				break;
		}
	}
	render() {
		return (
			<div className="container">
				<Panel header="Agregar A Registro">
					<Toast ref={(el) => this.growl = el} />
					<form >
						<Row>
							<Col sm="4">
								<div className="form-group">
									<label>Folio</label><br/>
									<input type="text" readOnly="true"  value={this.state.folio} className="form-control"/>
								</div>
							</Col>
							<Col sm="4">
								<div className="form-group">
									<label>Fecha</label><br/>
									<PCalendar
										label={"fecha"}
										value={this.state.fecha}
										getCommonDataFromChilds={this.getCommonDataFromChilds}
										showIcon
									/>
								</div>
							</Col>
							<Col sm="4">
								<div className="form-group">
									<label>Hora de inspección</label><br/>
									<PTimer
										label={"hora"}
										value={this.state.hora}
										getCommonDataFromChilds={this.getCommonDataFromChilds}
										showIcon
									/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col md="4">
								<div className="form-group">
									<label>Personal</label><br/>
									<Select options={tipoPersonal} value={tipoPersonal.filter(option => option.value == this.state.personal)} onChange={e =>	this.setState({personal: e.value}) }  placeholder="Elegir tipo de servicio"/>
								</div>
							</Col>
							<Col md="4">
								<div className="form-group">
									<label>Nombre</label><br/>
									<input type="text"  value={this.state.nombre} onChange={e => this.setState({nombre : e.target.value})} className="form-control"/>
								</div>
							</Col>
							<Col md="4">
								<div className="form-group">
									<label className="d-block">Tipo</label>
									<div className="d-flex">
										<div className="d-flex align-items-center mr-3">
											<RadioButton inputId="city1" name="city" value="Entrada" onChange={(e) => this.setState({tipo: e.value})} checked={this.state.tipo === 'Entrada'} />
											<label htmlFor="city1" className="ml-2 mb-0">Entrada</label>
										</div>
										<div className="d-flex align-items-center">
											<RadioButton inputId="city2" name="city" value="Salida" onChange={(e) => this.setState({tipo: e.value})} checked={this.state.tipo === 'Salida'} />
											<label htmlFor="city2" className="ml-2 mb-0">Salida</label>
										</div>
									</div>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs="6">
								<div className="form-group">
									<label>A quién visita</label><br/>
									<input type="text"  value={this.state.a_quien_visita} onChange={e => this.setState({a_quien_visita : e.target.value})} className="form-control"/>
								</div>
							</Col>
							<Col xs="6">
								<div className="form-group">
									<label>Motivo</label><br/>
										<input type="text"  value={this.state.motivo} onChange={e => this.setState({motivo : e.target.value})} className="form-control"/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col sm="6">
								<div className="form-group">
									<label>Tipo identificación</label><br/>
									<Select options={tipoIdentificacion} value={this.state.tipo_identificacion?tipoIdentificacion.filter(option => option.value == this.state.tipo_identificacion ):""}  onChange={e =>	this.setState({tipo_identificacion: e.value}) }  placeholder="Elegir tipo de servicio"/>
								</div>
							</Col>
							<Col sm="6">
								<div className="form-group">
									<label>Núm. Gafete (EXNAC)</label><br/>
										<input type="text"  value={this.state.gafete} onChange={e => this.setState({gafete : e.target.value})} className="form-control"/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col sm="6">
								<div className="form-group">
									<label className="d-block">Tipo Vehículo</label>
									<div className="d-flex">
										<div className="d-flex align-items-center mr-3">
											<RadioButton inputId="city3" name="city2" value="Particular" onChange={(e) => this.setState({tipo_vehiculo_p: e.value})} checked={this.state.tipo_vehiculo_p === 'Particular'} />
											<label htmlFor="city3" className="ml-2 mb-0">Particular</label>
										</div>
										<div className="d-flex align-items-center">
										<RadioButton inputId="city4" name="city2" value="Empresa" onChange={(e) => this.setState({tipo_vehiculo_p: e.value})} checked={this.state.tipo_vehiculo_p === 'Empresa'} />
											<label htmlFor="city4" className="ml-2 mb-0">Empresa</label>
										</div>
									</div>
								</div>
							</Col>

							<Col sm="6">
								<div className="form-group">
									<label>Marca Unidad</label><br/>
									<Select options={marca} value={marca.filter(option => option.value== this.state.marca)} onChange={(e) => this.setState({marca	: e.value})}  placeholder="Seleccione una marca"  />
								</div>
							</Col>
						</Row>
						<Row>
							<Col sm="6">
								<div className="form-group">
									<label>Marca Remolque</label><br/>
									<Select options={marca} value={marca.filter(option => option.value === this.state.marca_remolque)} onChange={(e) => this.setState({marca_remolque	: e.value})}  placeholder="Seleccione una marca"  />
								</div>
							</Col>
							<Col sm="6">
								<div className="form-group">
									<label>Tipo de Vehículo</label><br/>
									<Select options={tipo_unidades} value={tipo_unidades.filter(option => option.value == this.state.tipo_vehiculo)}  onChange={e=> this.setState({tipo_vehiculo:e.value})} placeholder="Seleccione el tipo"  />
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs="6">
								<div className="form-group">
									<label>Placas</label><br/>
									<input type="text"  value={this.state.placas} onChange={e => this.setState({placas : e.target.value})} className="form-control"/>
								</div>
							</Col>

							<Col xs="6">
								<div className="form-group">
									<label>Núm. Eco. (Unidad)</label><br/>
									<input type="text"  value={this.state.eco_unidad} onChange={e => this.setState({eco_unidad : e.target.value})} className="form-control"/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col sm="6">
								<div className="form-group">
									<label>Núm. Eco. (Remolque)</label><br/>
									<input type="text"  value={this.state.eco_remolque} onChange={e => this.setState({eco_remolque : e.target.value})}className="form-control"/>
								</div>

							</Col>
						</Row>
						<Row>
							<Col md="3">
								<div className="form-group">
									<label className="d-block">Estado</label>
									<div className="d-flex">
										<div className="d-flex align-items-center mr-3">
											<RadioButton inputId="city5" name="city3" value="Vacío" onChange={(e) => this.setState({estado: e.value})} checked={this.state.estado === 'Vacío'} />
											<label htmlFor="city5" className="ml-2 mb-0">Vacío</label>
										</div>
										<div className="d-flex align-items-center">
											<RadioButton inputId="city6" name="city3" value="Cargado" onChange={(e) => this.setState({estado: e.value})} checked={this.state.estado	 === 'Cargado'} />
											<label htmlFor="city6" className="ml-2 mb-0">Cargado</label>
										</div>
									</div>
								</div>
							</Col>

							<Col md="3">
								<div className="form-group">
									<label>Núm. Sello</label><br/>
									<input type="text"  value={this.state.sello} onChange={e => this.setState({sello : e.target.value})} className="form-control"/>
								</div>
							</Col>
							<Col md="3">
								<div className="form-group">
									<label>Origen</label><br/>
									<input type="text"  value={this.state.origen} onChange={e => this.setState({origen : e.target.value})} className="form-control"/>
								</div>
							</Col>
							<Col md="3">
								<div className="form-group">
									<label>Destino</label><br/>
									<input type="text"  value={this.state.destino}  onChange={e => this.setState({destino : e.target.value})} className="form-control"/>
								</div>
							</Col>
							<Col xs="12" className="text-center">
								<Button className="p-button-warning" onClick={this.handleSubmit} >Guardar</Button>
							</Col>
						</Row>
					</form>
				</Panel>
			</div>
		);
	}
}

export default RegistrarAcceso;
