import React from "react";
import AdminUserService from "controllers/admin-user/service";
import AddEditForm from "./add-edit-form";
import { Toast } from 'primereact/toast';

class EditarUsuarioAdministrador extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.servicioClientes = new AdminUserService();
    this.handleChange = this.handleChange.bind(this); 
    this.handleSubmit = this.handleSubmit.bind(this);
}

  componentDidMount(){
    this.setState({...this.props.location.state})
  }

  handleChange(e){
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  handleSubmit(e, permissions){
    e.preventDefault();
    let permisos = JSON.stringify(permissions)
    const updatedState = {...this.state, permisos: permisos}
    this.servicioClientes.editAdminUser(updatedState).then((val) => {
      if(val.status) 	this.props.history.push({
        pathname: "/app/usuarios-administradores/consulta"
        });
        if(!val.status && val.result === "duplicate")
        this.growl.show([{ severity: 'error', summary: 'Nombre de usuario duplicado', detail: 'Nombre de Usuario' }]);  
    });
  }

  render() {
    return (
      <div>
        <Toast ref={(el) => this.growl = el} />
        <AddEditForm  
           {...this.state}
           handleSubmit={this.handleSubmit}
           title="Editar Usuario Administrador"
           handleChange={this.handleChange}
        />
      </div>
    );
  }
}

export default EditarUsuarioAdministrador;

