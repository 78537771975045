import React, { Component } from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {MultiSelect} from 'primereact/multiselect';
import EmbarqueService  from 'controllers/embarque/embarque-service';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import EvidenciaListado from './evidencias-views';
import { Toast } from 'primereact/toast';
import {Button as Button2 } from 'reactstrap';

export default class EmbarquePorLiquidarListado extends Component {

    constructor() {
        super();
        this.state = {
            globalFilter: null
        };
        this.service = new EmbarqueService();
        this.eliminar = this.eliminar.bind(this);
        this.editar = this.editar.bind(this);
    }

    componentDidMount() {
      if(this.props.parametros){
          this.service.getFiltroEmbarques(this.props.parametros).then(data => this.setState({embarques: data}));
      }else{
          this.service.getEmbarques().then(data => this.setState({embarques: data}));
      }
    }

    eliminar(column){
      this.setState({visible2:true,selectedItemIndex:column.rowIndex,selectedItem:column.rowData});

    }

    editar(column){
      this.setState({visible:true,item:column.rowData});
    }

    confirmar_borrado(){

      this.setState({visible2:false});
      this.setState({
        embarques: this.state.embarques.filter((val,i) => i !== this.state.selectedItemIndex)
      });

      let that = this;


      this.service.eliminarEmbarque(this.state.selectedItem.id).then(res =>  {
          if(res && res.result == 'OK'){
            that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Registro borrado'});
          }
      });

    }

    actionTemplate(rowData, column) {
      return (<div>
         <Button
          type="button" icon="pi pi-trash" onClick={e=> this.eliminar(column)}
           className="p-button-warning mr-2"
        />
        <Button2 className='p-button-warning' onClick={e=> this.editar(column)}>
          Evidencias
        </Button2>
      </div>);
    }

    render() {
        const footer = (
          <div>
                <Button label="Sí" className='p-button-warning mr-2' icon="pi pi-check" onClick={this.confirmar_borrado.bind(this)} />
                <Button label="No" className='p-button-warning' icon="pi pi-times" onClick={e=> this.setState({visible2:false})}/>
            </div>

        );
        let header = (
            <div style={{'textAlign':'left'}}>
                <i className="pi pi-search" style={{margin:'4px 4px 0 0'}}></i>
                <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="Global Search" size="50"/>
            </div>
        );

        return (<div>
            <Toast ref={(el) => this.growl = el} />
            <Dialog header="Evidencias"  visible={this.state.visible} style={{width: '70vw',height:'90vh',overflow:'scroll',backgroundColor:"#fff"}} modal={true} onHide={() => this.setState({visible: false,visible2:false	})}>
              <EvidenciaListado cartaPorteId={this.state.item?this.state.item.carta_porte_id:""}/>

            </Dialog>
            <Dialog header="Eliminar"  footer={footer} visible={this.state.visible2} style={{width: '50vw'}} modal={true} onHide={() => this.setState({visible: false,visible2:false	})}>
              ¿Confirma eliminar?
            </Dialog>
            <DataTable value={this.state.embarques} paginator={true} rows={10} header={header} globalFilter={this.state.globalFilter}>
                <Column field="folio" header="Folio"   />
                <Column field="planta_origen" header="Planta origen"   />
                <Column field="destino_final" header="Planta destino"   />
                <Column field="fecha_embarque" header="Fecha embarque"   />
                <Column body={this.actionTemplate.bind(this)} header="Opciones" style={{ textAlign: 'center'}} />
            </DataTable>
        </div>);
    }
}
/*  <button
   className="btn btn-success" style={{margin:10}}>Descargar todo</button>*/
