import React from "react";
import {
	Card,
	CardBody,CardTitle

} from 'reactstrap';
import RutasListado from "./rutas-views";

class ResultadoRutas extends React.Component {
	constructor(props){
		super(props);
	}
	render() {
		return (
			<Card>
			<CardTitle  className="bg-light border-bottom p-3 mb-0">CONSULTA DE RUTAS</CardTitle>
				<CardBody>
					<RutasListado parametros={this.props.location.state} history={this.props.history}/>
				</CardBody>
			</Card>
		);
	}
}

export default ResultadoRutas;
