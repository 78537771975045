import React from "react";
import {
	Card,
	CardBody,Col, Row, Container,CardTitle

} from 'reactstrap';
import {Calendar} from 'primereact/calendar';
import {Button} from 'primereact/button';
import ClienteService  from 'controllers/cliente/clienteservice';
import Select from 'react-select';
import  CartaPorteListado from './carta-porte-views.js';
import { Toast } from 'primereact/toast';

class ResultadosCartaPorte extends React.Component {
	constructor(props){
		super(props);
	}

	render() {
		return (
			<Card>
				<CardTitle  className="bg-light border-bottom p-3 mb-0">CONSULTAR CARTA PORTE</CardTitle>
				<CardBody  >
				<Toast ref={(el) => this.growl = el} />
					<Row>
						<Col>
							<CartaPorteListado parametros={this.props.location.state} history={this.props.history} growl={this.growl}/>
						</Col>
					</Row>
				</CardBody>
			</Card>
		);
	}
}

export default ResultadosCartaPorte;
