import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import EditarEmbarque from './editar';
import { Toast } from 'primereact/toast';
import EmbarqueService from 'controllers/embarque/embarque-service';
import find from 'lodash/find'
export default class CobranzaListado extends Component {

    constructor() {
        super();
        this.state = {
            globalFilter: null,
            cobranza: []
        };
        this.service = new EmbarqueService();
        // this.eliminar = this.eliminar.bind(this);
        // this.editar = this.editar.bind(this);
    }

    componentDidMount() {
        this.service.getCobranza().then(data => this.setState({ cobranza: data }));
    }

    eliminar(column) {
        this.setState({ visible2: true, selectedItemIndex: column.rowIndex, selectedItem: column.rowData });
    }

    editar(column) {
        this.setState({ visible: true, item: column.rowData });
        this.props.history.push({
            pathname: '/app/Cobranza/add',
            state: column.rowData
        });
    }


    confirmar_borrado = () => {

        this.setState({ visible2: false });
        this.setState({
            cobranza: this.state.cobranza.filter((val, i) => i !== this.state.selectedItemIndex)
        });
        let that = this;
        this.service.deleteCobranza({id:this.state.selectedItem.id}).then(res => {
            if (res && res.result == 'OK') {
                that.growl.show({ severity: 'success', summary: 'Operación completada', detail: 'Registro borrado' });
            }
        });

    }

    actionTemplate = (rowData, column) => {
        return (<div>
            <Button
                type="button" icon="pi pi-pencil" onClick={e => this.editar(column)}
                className="" /><Button
                type="button" icon="pi pi-trash" onClick={e => this.eliminar(column)}
                className="p-button-warning"
            />
        </div>);
    }
    changeString = (rowData) => {
        let test_data = JSON.parse(rowData['embarques'])['folio']
        return test_data
    }
    changeFindOption = (rowData) => {
        const options = [
            { value: "1", label: "Por liquidar" },
            { value: "2", label: "Liquidado" },
        ]

        let test_data = find(options, ['value', rowData['estatus']])['label']
        return test_data
    }
    render() {
        const footer = (
            <div>
                <Button label="Sí" className='p-button-warning mr-2' icon="pi pi-check" onClick={() => { this.confirmar_borrado() }} />
                <Button label="No" className='p-button-warning' icon="pi pi-times" onClick={e => this.setState({ visible2: false })} />
            </div>
        );

        return (<div>
            <Toast ref={(el) => this.growl = el} />
            <Dialog header="Editar" visible={this.state.visible} style={{ width: '70vw', height: '90vh', overflow: 'scroll' }} modal={true} onHide={() => this.setState({ visible: false, visible2: false })}>
                <EditarEmbarque values={this.state.item} />
            </Dialog>
            <Dialog header="Eliminar" footer={footer} visible={this.state.visible2} style={{ width: '50vw' }} modal={true} onHide={() => this.setState({ visible: false, visible2: false })}>
                ¿Confirma eliminar?
            </Dialog>
            <DataTable value={this.state.cobranza} rows={10}>
                <Column body={this.changeString} header="Embarque" />
                <Column field="numero_de_evidencia" header="Número de evidencia" />
                <Column field="fecha_de_pago_1" header="Fecha de pago 1" />
                <Column field="fecha_de_pago_2" header="Fecha de pago 2" />
                <Column field="fecha_de_pago_final" header="Fecha de pago final" />
                <Column body={this.changeFindOption} header="Estatus" />
                <Column body={this.actionTemplate} header="Opciones" style={{ textAlign: 'center' }} />
            </DataTable>
        </div>);
    }
}
