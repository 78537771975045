import React from "react";
import {
	Card,
	CardBody,
	CardTitle
} from 'reactstrap';
import Listado from './listado';
import {Button} from 'primereact/button';


class Inventario extends React.Component {
	
	render() {
		return (
			<Card>
				<CardTitle  className="bg-light border-bottom p-3 mb-0">LISTADO DE PARADAS REALIZADAS</CardTitle>
				<CardBody>
					<Listado />
				</CardBody>
			</Card>
		);
	}
}

export default Inventario;
