import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { urlPdf } from "../../../../constants";
import img1 from "../../../../assets/images/user.png";
import Service from "controllers/nomina/calculo";
import Select from "react-select";
function range(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => {
      return { label: start + idx, value: start + idx };
    });
}

function current_week_number() {
  let currentDate = new Date();
  let startDate = new Date(currentDate.getFullYear(), 0, 1);
  var days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
  var weekNumber = Math.ceil((currentDate.getDay() + 1 + days) / 7);
  return weekNumber;
}
export default class Listado extends Component {
  constructor() {
    super();
    this.state = {
      globalFilter: null,
      visible: false,
      visible2: false,
      expandedRows: null,
      weeks: range(1, 53),
      week_number: current_week_number(),
    };
    this.servicio = new Service();
    this.eliminar = this.eliminar.bind(this);
    this.editar = this.editar.bind(this);
  }

  componentDidMount() {
    this.servicio.listadoCalculo().then((data) => {
      this.setState({ cars: data });
      setTimeout(() => {
        console.log("data  ", data, "state.cars", this.state.cars);
      }, 5000);
    });
  }

  eliminar(column) {
    this.setState({
      visible2: true,
      selectedItemIndex: column.rowIndex,
      selectedItem: column.rowData,
    });
  }
  change_week_number = (week_number) => {
    this.setState({ week_number });
    this.servicio
      .listadoCalculo(week_number)
      .then((data) => this.setState({ cars: data }));
  };

  editar(column) {
    //this.setState({visible:true,item:column.rowData});
    this.props.history.push({
      pathname: "/app/operador/editar",
      state: column.rowData,
    });
  }

  confirmar_borrado() {
    this.setState({ visible2: false });
    this.setState({
      cars: this.state.cars.filter(
        (val, i) => i !== this.state.selectedItemIndex
      ),
    });
    let that = this;
    this.servicio.eliminarCalculo(this.state.selectedItem.id).then((res) => {
      if (res && res.result == "OK") {
        that.growl.show({
          severity: "success",
          summary: "Operación completada",
          detail: "Registro borrado",
        });
      }
    });
  }

  actionTemplate(rowData, column) {
    /*

      <Button
        type="button" icon="pi pi-pencil" onClick={e=> this.editar(column)}
        className=""/>
    */
    return (
      <div>
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={(e) =>
            window.open(
              urlPdf + "DESGLOSE/reporte.php?id=" + column.rowData.id,
              "width=200,height=100"
            )
          }
          className="p-button-margin"
          tooltip="Abrir documento"
          tooltipOptions={{ position: 'top' }}
        />
        {/* <Button
        type="button" icon="pi pi-trash" onClick={e => this.eliminar(column)}
        className="p-button-warning"
      /> */}
      </div>
    );
  }

  rowExpansionTemplate(data) {
    return (
      <div className="orders-subtable">
        <h5>Conceptos del empleado: {data.nombre_empleado}</h5>
        <DataTable value={data.calculo}>
          <Column
            field="concepto_detalle.nombre"
            header="Concepto"
            sortable
          ></Column>
          <Column
            field="concepto_detalle.categoria"
            header="Categoria"
            sortable
          ></Column>
          <Column field="concepto_detalle.tipo" header="Tipo" sortable></Column>
          {/* <Column field="fecha" header="Fecha" sortable></Column> */}

          <Column field="alias" header="Alias" sortable></Column>
          <Column field="monto" header="Monto" sortable></Column>
        </DataTable>
      </div>
    );
  }
  imageBodyTemplate(rowData) {
    return (
      <img
        src={img1}
        alt={rowData.image}
        height="20"
        width="20"
        className="product-image"
      />
    );
  }
  IngresosBodyTemplate(rowData) {
    return `${
      '$' + Number(rowData.total_add_value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') +
      0//Number(rowData.total_embarque_ruta_vresion)
    }`;
  }
  currencyFormatSub = (rowData) => {
    return '$' + rowData.total_sub_value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  currencyFormatTotal = (rowData) => {
    return '$' + rowData.total.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  render() {
    console.log(this.state.week_number);
    const footer = (
      <div>
        <Button
          label="Sí"
          className='p-button-warning mr-2'
          icon="pi pi-check"
          onClick={this.confirmar_borrado.bind(this)}
        />
        <Button
          label="No"
          className='p-button-warning'
          icon="pi pi-times"
          onClick={(e) => this.setState({ visible2: false })}
        />
      </div>
    );


    return (
      <div>
        <Toast ref={(el) => (this.growl = el)} />
        <Dialog
          header="Eliminar"
          footer={footer}
          visible={this.state.visible2}
          style={{ width: "50vw" }}
          modal={true}
          onHide={() => this.setState({ visible: false, visible2: false })}
        >
          ¿Confirma eliminar?
        </Dialog>
        <div className="mb-4">
          <div>Seleccionar semana</div>
          <div className="w-25">
            <Select
              value={{
                value: this.state.week_number,
                label: this.state.week_number,
              }}
              options={this.state.weeks}
              onChange={(e) => {
                this.change_week_number(e.value);
              }}
              placeholder="Seleccionar semana"
            ></Select>
          </div>
        </div>
        <DataTable
          value={this.state.cars}
          rows={10}
          expandedRows={this.state.expandedRows}
          onRowToggle={(e) => this.setState({ expandedRows: e.data })}
          rowExpansionTemplate={this.rowExpansionTemplate}
          paginator={true}
          row={10}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        >
          <Column field="operador_name" header="Nombre" />
          <Column
            field="week_number"
            headerStyle={{ width: "6rem" }}
            header="Semana"
          />
          <Column
            body={this.IngresosBodyTemplate}
            header="Ingresos"
          
          />
          <Column body={this.currencyFormatSub}  header="Egresos" />
          <Column body={this.currencyFormatTotal}  header="Total" />

          <Column
            body={this.actionTemplate.bind(this)}
            header="Opciones"
            style={{ textAlign: "center" }}
          />
        </DataTable>
      </div>
    );
  }
}
/*
   <Column header="Foto" headerStyle={{ width: '4rem'}}  body={this.imageBodyTemplate} />
*/
