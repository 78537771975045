import React from "react";
import {
	Card,
	CardBody,
	CardTitle
} from 'reactstrap';
import Listado from './predictivos/listado';
import {Button} from 'primereact/button';


class Inventario extends React.Component {
	agregar(){
	  this.props.history.push({
	   pathname:'/app/mantenimiento/predictivo/predictivos/agregar'
	 });
	}
	render() {
		return (
			<Card>
				<CardTitle  className="bg-light border-bottom p-3 mb-0">LISTADO DE PREDICTIVOS</CardTitle>
				<CardBody>
					<Button label='Agregar predictivo' icon="fa fa-plus" className="pull-right" onClick={this.agregar.bind(this)}/><br/><br/>
					<Listado history ={this.props.history}/>
				</CardBody>
			</Card>
		);
	}
}

export default Inventario;
