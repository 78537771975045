import React from "react";
import {
	Card,
	CardBody,Container, Row,Col,CardTitle

} from 'reactstrap';

import { Toast } from 'primereact/toast';
import moment from 'moment';
import Select from 'react-select';
import ParadasAutorizadasService from  'controllers/paradas-autorizadas/service';

const options = [];

class CrearReporteFalla extends React.Component {
	constructor(props){
		super(props);
		this.state={

		}
		this.servicio = new ParadasAutorizadasService();

		this.handleSubmit = this.handleSubmit.bind(this);
	}




	componentDidMount() {
	  let values = this.props.location.state;
	  console.log(values);
	  if(values){
	    this.setState({
				nombre:values.nombre,
				direccion:values.direccion,
				descripcion:values.descripcion,
				latitud:values.latitud,
				longitud:values.longitud,

	      id:values.id,
	      titulo:"EDITAR",
	      sobreescribir: true
	    });
	  }

	}

	handleSubmit(event) {
				event.preventDefault();

				 console.log(this.state);
				let that = this;

				let datos ={};
				const data = new FormData();
				datos.nombre = this.state.nombre;
				datos.direccion = this.state.direccion;
				datos.descripcion = this.state.descripcion;
				datos.latitud = this.state.latitud;
				datos.longitud = this.state.longitud;


				data.append("ruta",JSON.stringify(datos));
				data.append("contrato",this.state.contrato);
				data.append("asignacion",this.state.asignacion);


				let funcion = console.log;
				if(this.state.sobreescribir){
				  funcion = this.servicio.actualizarParada;
				  datos.id = this.state.id;
				}else{
				  funcion = this.servicio.guardarParada;
				}

				funcion(datos).then(res =>  {
						if(res && res.result == 'OK'){

							that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Información guardada correctamente'});
							setTimeout(function(){
									 	that.props.history.push('/app/paradas-autorizadas/catalogo');
							 }, 500);

						}else if(res && res.result == 'EXISTE'){
							that.growl.show({severity: 'error', summary: 'El Id de ruta '+this.state.id_ruta+' ya se encuentra registrado', detail: 'Ingrese otro código o consulte su información para editarla.'});
						}
				});
	}

	render() {

		return (
			<Card>
			<CardTitle  className="bg-light border-bottom p-3 mb-0">EDITAR PARADA</CardTitle>
				<CardBody>
				<Toast ref={(el) => this.growl = el} />
				<form >
				<Row>
					<Col xs="6">
							<div className="form-group">
								<label>Nombre</label><br/>
									<input type="text"  value={this.state.nombre}  onChange={e => this.setState({nombre : e.target.value})}  className="form-control"/>
							</div>

					</Col>
					<Col xs="6">
							<div className="form-group">
								<label>Dirección</label><br/>
									<input type="text"  value={this.state.direccion}  onChange={e => this.setState({direccion : e.target.value})}  className="form-control"/>
							</div>

					</Col>
					<Col xs="12">
							<div className="form-group">
								<label>Descripcion</label>
								<input type="text"  value={this.state.descripcion} onChange={e => this.setState({descripcion : e.target.value})}  className="form-control"/>
							</div>
					</Col>
				</Row>
				




				<br/><br/><br/>


						<center> <button className="btn btn-success" onClick={this.handleSubmit} >Guardar</button></center>
					</form>
				</CardBody>
			</Card>
		);
	}
}

export default CrearReporteFalla;
